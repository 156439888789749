<template>
  <DrToolbarFilterSearch
    :model-value="search"
    @update:model-value="emit('update:search', $event)"
  />

  <div :class="$style.filters">
    <div :class="$style.filtersList">
      <DrToolbarFilterSelect
        v-model="localFilters.type_id"
        label="Type"
        :options="typeOptions"
        @update:model-value="updateField('type_id')"
      >
        <template #icon="{ option }">
          <DrIcon
            size="sm"
            name="exclamation-triangle"
            :style="{ color: option.color }"
          />
        </template>
      </DrToolbarFilterSelect>

      <DrToolbarFilterSelect
        v-model="localFilters.severity"
        label="Severity"
        :options="severityOptions"
        @update:model-value="updateField('severity')"
      />

      <DrToolbarFilterSelect
        v-model="localFilters.likelihood"
        label="Likelihood"
        :options="likelihoodOptions"
        @update:model-value="updateField('likelihood')"
      />

      <DrToolbarFilterSelect
        v-model="localFilters.status_id"
        label="Status"
        :options="statusOptions"
        @update:model-value="updateField('status_id')"
      >
        <template #icon="{ option }">
          <DrIconStatus :color="option.color" />
        </template>
      </DrToolbarFilterSelect>

      <DrToolbarFilterTree
        v-model="assigneesModelValue"
        label="Assignee"
        :tree="usersTree"
        :favorites="assigneesFavorites"
        :is-pending="!isMembersAndGroupsLoaded"
      >
        <template #tree-item-prefix="{ item, className }">
          <DrAvatar
            :name="item.name"
            :identifier="(item as UsersTreeItem).email"
            :url="(item as UsersTreeItem).avatar"
            :class="className"
          />
        </template>
        <template #favorite-item-prefix="{ item, className }">
          <DrAvatar
            :name="item.name"
            :identifier="(item as UsersTreeItem).email"
            :url="(item as UsersTreeItem).avatar"
            :class="className"
          />
        </template>
      </DrToolbarFilterTree>
    </div>
  </div>

  <slot name="append" />
</template>
<script setup lang="ts">
import { cloneDeep } from "lodash-es";
import { computed, reactive, watch } from "vue";
import { useI18n } from "vue-i18n";
import { DrAvatar } from "@shared/ui/dr-avatar";
import { DrIcon } from "@shared/ui/dr-icon";
import DrIconStatus from "@shared/ui/dr-icon-status";
import {
  DrToolbarFilterSearch,
  DrToolbarFilterSelect,
  DrToolbarFilterTree,
} from "@shared/ui/dr-toolbar";

import { USER_DATA } from "@app/setups";
import { DrStore } from "@app/vue";
import {
  FindingLikelihoodList,
  FindingSeverityList,
} from "@drVue/api-service/modules/findings/types";
import { pinia } from "@drVue/store/pinia";
import { useFindingsStatusesStore } from "@drVue/store/pinia/pipeline/findings-statuses";
import { useFindingsTypesStore } from "@drVue/store/pinia/pipeline/findings-types";

import type {
  FindingsToolbarFiltersModel,
  FindingsToolbarFiltersUpdate,
} from "./types";
import type { Group } from "@drVue/store/modules/room/groups/GroupsApiService";
import type { RoomMember } from "@drVue/store/modules/room/members/RoomMembersApiService";
import type { StatusColor } from "@shared/ui/dr-icon-status";
import type { SelectOptionItem } from "@shared/ui/dr-popups";
import type { TreeItem } from "@shared/ui/dr-tree";

type SelectOptionItemWithColor = SelectOptionItem & { color: StatusColor };

interface Props {
  search: string;
  filters: FindingsToolbarFiltersModel;
}
const props = defineProps<Props>();

const emit = defineEmits<{
  (event: "update:filter", payload: FindingsToolbarFiltersUpdate): void;
  (event: "update:search", payload: string): void;
}>();

const { t } = useI18n();

const findingsStatusesStore = useFindingsStatusesStore(pinia);
const findingsTypesStore = useFindingsTypesStore(pinia);

const localFilters = reactive<FindingsToolbarFiltersModel>({
  type_id: [],
  status_id: [],
  severity: [],
  likelihood: [],
  assignees: [],
});
watch(
  () => props.filters,
  (value: FindingsToolbarFiltersModel) => {
    Object.assign(localFilters, cloneDeep(value));
  },
  {
    deep: true,
    immediate: true,
  },
);

const typeOptions = computed<SelectOptionItemWithColor[]>(() => {
  return findingsTypesStore.list.map(({ id, name, color }) => ({
    name,
    id,
    color,
    isSelected: localFilters.type_id.includes(id),
  }));
});
const severityOptions = computed<SelectOptionItem[]>(() => {
  return FindingSeverityList.map((severity) => ({
    id: severity.value,
    name: severity.label,
    isSelected: localFilters.severity.includes(severity.value),
  }));
});
const likelihoodOptions = computed<SelectOptionItem[]>(() => {
  return FindingLikelihoodList.map((likelihood) => ({
    id: likelihood.value,
    name: likelihood.label,
    isSelected: localFilters.likelihood.includes(likelihood.value),
  }));
});
const statusOptions = computed<SelectOptionItemWithColor[]>(() => {
  return findingsStatusesStore.list.map(({ id, name, color }) => ({
    id,
    name,
    color,
    isSelected: localFilters.status_id.includes(id),
  }));
});

const isMembersAndGroupsLoaded = computed(() => {
  const { groups, members } = DrStore.state.room;

  return (
    members.isLoading === false &&
    members.isError === false &&
    groups.isLoading === false &&
    groups.isError === false
  );
});
const groupsList = computed(() => DrStore.state.room.groups.pgroupsList);
const getUsersOfGroup = (group: Group) => {
  return DrStore.state.room.members.membersList.reduce(
    (acc: RoomMember[], m: RoomMember) => {
      const isActiveUser = !m.pending;
      const isMemberOfTheGroup = m.pgroup.id === group.id;

      if (isActiveUser && isMemberOfTheGroup) {
        acc.push(m);
      }

      return acc;
    },
    [],
  );
};

type UsersTreeGroup = TreeItem<
  void,
  {
    avatar: string;
    email: string;
  }
>;
type UsersTreeItem = TreeItem<{
  avatar: string;
  email: string;
}>;
const usersTree = computed<UsersTreeGroup[]>(() => {
  return groupsList.value.reduce((acc: UsersTreeGroup[], g: Group) => {
    const groupMembers = getUsersOfGroup(g).map((u) => {
      return {
        id: `member_${u.uid}`,
        name: u.name,
        email: u.email,
        avatar: u.avatar.reduced || "",
      };
    });

    if (!groupMembers.length) return acc;

    const group = {
      id: `group_${g.id}`,
      name: g.name,
      children: groupMembers,
    };

    acc.push(group);

    return acc;
  }, []);
});
const assigneesFavorites = computed<UsersTreeItem[]>(() => {
  const uid = DrStore.state.room.members.members[USER_DATA.id]?.uid;
  if (!uid) return [];

  return [
    {
      id: `member_${uid}`,
      name: t("requests.assigned_to_me"),
      avatar: USER_DATA.profile.avatar.thumbnail || "",
      email: USER_DATA.email,
    },
  ];
});
const assigneesModelValue = computed({
  get() {
    return localFilters.assignees.map(
      (assigneeId: string) => `member_${assigneeId}`,
    ) as `member_${string}`[];
  },
  set(checkedIds: `member_${string}`[]) {
    const parsedMembersIds = checkedIds.map((memberId: string) =>
      memberId.replace("member_", ""),
    );

    localFilters.assignees = parsedMembersIds;
    updateField("assignees");
  },
});

const updateField = (field: keyof FindingsToolbarFiltersModel) => {
  emit("update:filter", {
    field,
    value: cloneDeep(localFilters[field]).sort(),
  });
};
</script>

<style lang="scss" module>
@use "@app/styles/scss/spacing";

.filters {
  position: relative;
  height: 36px;
  padding: 2px;
  overflow: hidden;
}

.filtersList {
  display: flex;
  flex-wrap: wrap;
  gap: spacing.$xs;
}
</style>
