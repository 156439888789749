<template>
  <DrVxeGridDragNDrop
    :class="$style.wrapper"
    row-id-prefix="fldr"
    @drop="(item, files) => $emit('files-dropped', item as Folder, files)"
    @drop-default="$emit('files-dropped', undefined, $event)"
    @drag-over="$emit('drag-notify', $event as Folder | undefined, true)"
    @drag-leave="$emit('drag-notify', undefined, false)"
  >
    <template #default="{ gridRefSetter }">
      <DrNewLoader v-show="loading" overlay />

      <DrVxeGrid
        outlined
        :checkbox-config="{
          highlight: true,
          range: true,
          checkField: '_isChecked',
        }"
        :columns="tableColumns.columns"
        :data="docItems"
        :menu-config="{
          body: { options: folderMenu },
          visibleMethod:
            visibleMethod as unknown as VisibleMethod<VxeTableDataRow>,
          className: $style.contextMenu,
        }"
        :row-config="{
          isCurrent: true,
          height: searchQuery && !isArchive ? 100 : undefined,
          keyField: 'uid',
        }"
        :row-class-name="getRowClassName"
        :sort-config="{ trigger: 'cell', defaultSort }"
        :set-grid-ref="(value) => setGridRef(value) && gridRefSetter(value)"
        @menu-click="handleMenuClick"
        @cell-click="emitCellClick"
        @checkbox-all="emitCheckboxAll"
        @checkbox-change="emitCheckbox"
        @resizable-change="onColumnResized"
        @cell-menu="handleMenuShow"
      >
        <template #empty />
      </DrVxeGrid>

      <DocumentsTableEmpty
        v-if="!loading && docItems.length === 0"
        :search-text="searchQuery"
        :has-filters="hasFilters"
        :is-bookmarks="isBookmarks"
        :is-recent="isRecent"
        :is-archive="isArchive"
        :class="$style.emptyContainer"
        @click:reset-search="$emit('reset-search')"
        @click:reset-filters="$emit('reset-filters')"
      >
        <template v-if="selectedFolder?.edit && !isArchive" #create>
          <ElButton type="primary" @click="fileUploadInput?.click()">
            {{ $t("shared.upload") }}
          </ElButton>

          <input
            ref="fileUploadInput"
            type="file"
            multiple
            hidden
            :class="$style.fileUploadInput"
            @input="handleFileUpload"
          />
        </template>
      </DocumentsTableEmpty>
    </template>
  </DrVxeGridDragNDrop>
</template>

<script setup lang="ts">
import { ElButton } from "element-plus";
import { cloneDeep } from "lodash-es";
import { computed, nextTick, onBeforeMount, ref, watch } from "vue";
import DrVxeGrid from "@shared/ui/dr-vxe-grid";

import { ROOM_DATA } from "@app/setups";
import { documentViewUrl } from "@app/setups/room-urls";
import { DrStore } from "@app/vue";
import DrNewLoader from "@app/vue/shared/ui/dr-loader/DrNewLoader.vue";
import DrVxeGridDragNDrop from "@app/vue/shared/ui/dr-vxe-grid/DrVxeGridDragNDrop.vue";
import { saveColumnsConfig } from "@drVue/components/room/documents/utils";
import {
  isDocument,
  isFolder,
} from "@drVue/store/modules/room/documents/DocumentsApiService";
import { usePageNavigationBus } from "../ai/usePageNavigationBus";
import DocumentsTableEmpty from "./DocumentsTableEmpty.vue";
import TableColumns from "./tableColumns";
import { type FolderMenuItem, useFolderMenu } from "./use/useFolderMenu";
import {
  useFolderBulkMenuBus,
  useFolderMenuActiveBus,
  useFolderMenuBus,
  useFolderMenuPersistedBus,
} from "./use/useFolderMenuBus";

import type { UploadItem } from "@app/vue/utils/extractFiles";
import type {
  Document,
  Folder,
} from "@drVue/store/modules/room/documents/DocumentsApiService";
import type { Dictionary } from "@drVue/types";
import type { CustomViewColumn } from "@setups/types";
import type {
  VxeGridDefines,
  VxeGridInstance,
  VxeTableDataRow,
  VxeTableDefines,
  VxeTablePropTypes,
} from "vxe-table";

interface Props {
  docItems: (Folder | Document)[];
  selectedFolder: Folder | undefined;
  loading?: boolean;
  hasError?: boolean;
  tasksService: any;
  categoriesService: any;
  sortConfig: VxeTablePropTypes.SortConfigDefaultSort;
  searchQuery?: string;
  isRecent?: boolean;
  isBookmarks?: boolean;
  isArchive?: boolean;
  hasFilters?: boolean;
}

interface Emits {
  (e: "checkbox", row: any, event: any): void;
  (e: "checkbox-all"): void;
  (e: "files-dropped", folder: Folder | undefined, files: UploadItem[]): void;
  (e: "drag-notify", folder: Folder | undefined, isDragging: boolean): void;
  (e: "reset-search"): void;
  (e: "reset-filters"): void;

  (e: "menu-view-doc", params: Document): void;
  (e: "menu-open-folder", params: Folder): void;
  (e: "menu-download", params: Document | Folder): void;
  (e: "menu-edit-custom-data", params: Document | Folder): void;
  (e: "menu-activity", params: Document): void;
}

const props = defineProps<Props>();

const emit = defineEmits<Emits>();

const fileUploadInput = ref<HTMLInputElement | undefined>();
const isMenuPersisted = ref(false);

const gridRef = ref<VxeGridInstance | undefined>();
const setGridRef = (ref: VxeGridInstance) => (gridRef.value = ref);

const { folderMenu, bookmarkItem } = useFolderMenu({
  view: (document) => emit("menu-view-doc", document),
  open: (folder) => emit("menu-open-folder", folder),
  download: (value) => emit("menu-download", value),
  editCustomData: (value) => emit("menu-edit-custom-data", value),
  activity: (value) => emit("menu-activity", value),
});
const folderMenuBus = useFolderMenuBus();
const folderMenuActiveBus = useFolderMenuActiveBus();
const folderMenuPersistedBus = useFolderMenuPersistedBus();
const pageNavigationBus = usePageNavigationBus();
const folderBulkMenuBus = useFolderBulkMenuBus();

const isMenuVisible = computed<boolean>(
  () =>
    !!gridRef.value?.getRefMaps().refTable.value?.reactData.ctxMenuStore
      .visible,
);

const updateCurrentRow = (row: Folder | Document | null) => {
  gridRef.value?.setCurrentRow(row);
};

watch(isMenuVisible, (value) => {
  if (!value) updateCurrentRow(null);
});

folderMenuActiveBus.on((event, payload) => {
  if (!payload) updateCurrentRow(null);
});

folderMenuPersistedBus.on((event, value: boolean | undefined) => {
  isMenuPersisted.value = value ?? false;
});

pageNavigationBus.on((_event, payload) => {
  if (!payload) throw new Error("Payload is required");

  window.open(
    documentViewUrl(ROOM_DATA.url, payload.docId, undefined, payload.page),
    "_blank",
  );
});

const resetCheckboxAll = () => {
  gridRef.value?.setAllCheckboxRow(false);
};

folderBulkMenuBus.on(resetCheckboxAll);

const handleMenuShow = (value: Params) => {
  updateCurrentRow(value.row ?? null);
};

const handleMenuClick = (
  params: VxeGridDefines.MenuClickEventParams<Folder | Document>,
) => {
  params.menu.track?.(params.row);

  if (params.menu.menuComponent) {
    folderMenuBus.emit("menu:show", {
      reference: params.cell,
      params: {
        folders: [],
        documents: [],
        [isFolder(params.row) ? "folders" : "documents"]: [params.row],
      },
      menuComponent: params.menu.menuComponent,
      placement: "left",
    });

    nextTick().then(() => updateCurrentRow(params.row));
  } else {
    params.menu.action?.(params.row);
  }
};

type VisibleMethod<Item> = Required<
  VxeTablePropTypes.MenuConfig<Item>
>["visibleMethod"];

type Params = Parameters<VisibleMethod<Folder | Document>>[0];

type Options = VxeTableDefines.MenuFirstOption & FolderMenuItem;

const visibleMethod = (
  params: Params & {
    options: Options[][];
  },
): boolean => {
  if (!params.row || isMenuPersisted.value || props.isArchive) return false;

  params.options.forEach((category) => {
    category.forEach((menu) => {
      menu.visible =
        (menu.action !== undefined || menu.menuComponent !== undefined) &&
        menu.getIsVisible(params.row);
    });
  });

  return true;
};

const columnsToRemove = props.isArchive ? ["_bookmark"] : [];
const tableColumns = ref(new TableColumns(DrStore, columnsToRemove));
const defaultSort = ref<VxeTablePropTypes.SortConfigDefaultSort | undefined>(
  cloneDeep(props.sortConfig),
);
const skipCellClickFor = ref<Dictionary<boolean>>({
  id: true,
  _checkbox: true,
  _menu: true,
  _requests: true,
});

onBeforeMount(() => {
  tableColumns.value.tasksService = props.tasksService;
  tableColumns.value.categoriesService = props.categoriesService;
});

const getRowClassName = ({ row }: Params): string => {
  if (row && isFolder(row)) {
    return `dr-row-folder dr-row-folder-id-${row.id}`;
  }

  return "";
};

const emitCheckbox = (params: Params & { $event: PointerEvent }) => {
  emit("checkbox", params.row, params.$event);
};

const emitCheckboxAll = () => {
  emit("checkbox-all");
};

const emitCellClick = ({ row, column }: Params) => {
  if (!row || !column) return;

  if (skipCellClickFor.value[column.property]) {
    return;
  }

  if (column.property === "_bookmark") {
    bookmarkItem(row);
    return;
  }

  if (column.type === "expand") return;

  if (isFolder(row)) {
    emit("menu-open-folder", row);
    return;
  }

  if (isDocument(row)) {
    emit("menu-view-doc", row);
    return;
  }
};

const onColumnResized = ({
  column,
}: {
  column: VxeTableDefines.ColumnInfo;
}) => {
  const update: CustomViewColumn = {
    field: column.field,
    width: column.resizeWidth,
  };

  saveColumnsConfig(DrStore, update);
};

type FileEventTarget = EventTarget & { files: UploadItem[] };

const handleFileUpload = (event: Event) => {
  emit(
    "files-dropped",
    props.selectedFolder,
    Array.from((event.target as FileEventTarget).files),
  );
};
</script>

<style lang="scss" module>
@use "@app/styles/scss/vue-common/_vxe-table-menu.scss";
@use "@app/styles/scss/colors";

.wrapper {
  height: 100%;
  position: relative;
  z-index: 0;
}

.emptyContainer {
  border: 1px solid colors.$pr-150;
  border-radius: 7px;
}

.fileUploadInput[type="file"] {
  visibility: hidden;
  display: none;
}
</style>
