<template>
  <DrDrawer
    size="large"
    fixed-footer
    title="New finding"
    submit-btn="Create finding"
    :shown="shown"
    :is-submitting="isFormSubmitting || isDataPending"
    @submit="handleSubmit"
    @close="checkAndHandleClose"
    @cancel="handleCancel"
  >
    <DrDynamicFormClassic
      ref="formRef"
      :schema="findingFormSchema"
      :entity="findingFormEntity"
      :errors="findingFormErrors"
      @update="handleUpdateFindingForm"
      @submit="handleSubmit"
    >
      <template #ties_to="{ editProps, error, dataTestId }">
        <ElFormItem
          :error="error"
          style="grid-column: 1 / -1"
          :data-testid="dataTestId"
        >
          <template #label>
            <FindingTiesToSelect :edit-props="editProps">
              <template #reference="{ show }">
                <DrFormItemLabelPlus title="Ties to" @plus="show" />
              </template>
            </FindingTiesToSelect>
          </template>

          <FindingTiesToList
            :items="tiesToItems"
            @remove="(item) => handleRemoveTiesToItem(item, editProps.veeField)"
          />

          <div
            v-if="isAdministrator && !tiesToItems.length"
            :class="$style.tiesToHint"
          >
            <span>
              <DrIcon name="lock" size="sm" :class="$style.iconLock" />
            </span>
            <span>
              Untied findings will be only visible to members of the
              Administrators group.
            </span>
          </div>
        </ElFormItem>
      </template>

      <template #assignees="{ editProps, error, dataTestId }">
        <ElFormItem
          :error="error"
          style="grid-column: 1 / -1"
          :data-testid="dataTestId"
        >
          <template #label>
            <ParticipantsEdit
              :edit-props="editProps"
              :filter-group="filterAssigneeGroup"
            >
              <template #reference="{ show }">
                <DrFormItemLabelPlus title="Assignees" @plus="show" />
              </template>
            </ParticipantsEdit>
          </template>

          <FindingParticipantsList
            :items="findingFormEntity.assignees"
            @remove="handleRemoveAssigneesItem"
          />
        </ElFormItem>
      </template>
    </DrDynamicFormClassic>

    <ElAlert
      v-if="findingFormErrors.non_field_errors"
      type="error"
      :title="findingFormErrors.non_field_errors"
    />
  </DrDrawer>
</template>

<script lang="ts" setup>
import { ElMessageBox } from "element-plus";
import { cloneDeep, isEqual, remove, set } from "lodash-es";
import { computed, reactive, ref, useCssModule, watch } from "vue";
import { useI18n } from "vue-i18n";
import { DrDrawer } from "@shared/ui/dr-drawer";
import { DrDynamicFormClassic } from "@shared/ui/dr-dynamic-form";
import {
  mapCustomFieldToSchema,
  validateString,
} from "@shared/ui/dr-dynamic-form/utils";
import { DrFormItemLabelPlus } from "@shared/ui/dr-form";
import { useFormHelper } from "@shared/ui/dr-form";
import { DrIcon } from "@shared/ui/dr-icon";

import { ROOM_DATA, ROOM_MEMBER_DATA } from "@setups/data";
import { insightTrack, RoomFindingsCreationEvent } from "@app/insight";
import {
  FindingLikelihoodList,
  FindingSeverityList,
} from "@drVue/api-service/modules/findings/types";
import { serializeCustomData } from "@drVue/api-service/parse";
import { $notifyDanger, $notifyWarning } from "@drVue/common";
import { FieldSchemaType } from "@drVue/components/client-dashboard/dynamic-form/types";
import { ParticipantsEdit } from "@drVue/components/room/tasks/shared/widgets/participants";
import DrStore from "@drVue/store";
import { pinia } from "@drVue/store/pinia";
import { useFindingsStatusesStore } from "@drVue/store/pinia/pipeline/findings-statuses";
import { useFindingsTypesStore } from "@drVue/store/pinia/pipeline/findings-types";
import { useCategoriesStore } from "@drVue/store/pinia/room/categories";
import { useFindingsStore } from "@drVue/store/pinia/room/findings";
import { useTasksStore } from "@drVue/store/pinia/room/tasks";
import FindingParticipantsList from "./components/FindingParticipantsList.vue";
import FindingTiesToList from "./components/FindingTiesToList.vue";
import FindingTiesToSelect from "./components/FindingTiesToSelect.vue";
import { getRichTextEmptyData } from "./utils";

import type { FindingDetails } from "./types";
import type { FieldItem } from "@drVue/api-service/client-dashboard";
import type { CustomDataType } from "@drVue/store/modules/client-dashboard/fields/types";
import type { Group } from "@drVue/store/modules/room/groups/GroupsApiService";
import type {
  Finding,
  FindingCreatePayload,
} from "@drVue/store/pinia/room/findings";
import type {
  FieldProps,
  FieldSchema,
  FormSchema,
} from "@shared/ui/dr-dynamic-form/types";

type LocalFindingDetails = Pick<
  FindingDetails,
  | "type_id"
  | "ties_to"
  | "title"
  | "severity"
  | "likelihood"
  | "assignees"
  | "status_id"
  | "custom_data"
> & {
  description: FindingDetails["description"] | null;
  mitigation_plan: FindingDetails["mitigation_plan"] | null;
  actual_mitigation: FindingDetails["actual_mitigation"] | null;
};

export interface FindingsCreatePanelOpenParams {
  categoryUid?: FindingDetails["ties_to"]["categories"][number]["category_uid"];
  taskUid?: FindingDetails["ties_to"]["tasks"][number]["task_uid"];
}

const shown = ref(false);
const categoryUid =
  ref<FindingsCreatePanelOpenParams["categoryUid"]>(undefined);
const taskUid = ref<FindingsCreatePanelOpenParams["taskUid"]>(undefined);

const handleOpen = (params?: FindingsCreatePanelOpenParams) => {
  if (params && params.categoryUid) {
    categoryUid.value = params.categoryUid;
  }

  if (params && params.taskUid) {
    taskUid.value = params.taskUid;
  }

  shown.value = true;
};

const $style = useCssModule();
const { t } = useI18n();

const isAdministrator = !!ROOM_MEMBER_DATA?.group?.is_administrator;

const findingsStore = useFindingsStore(pinia);
const findingsStatusesStore = useFindingsStatusesStore(pinia);
const findingsTypesStore = useFindingsTypesStore(pinia);
const categoriesStore = useCategoriesStore(pinia);
const tasksStore = useTasksStore(pinia);

const formRef = ref<InstanceType<typeof DrDynamicFormClassic> | null>(null);

const {
  formErrors: findingFormErrors,
  hookFormSubmitPromise,
  isFormSubmitting,
  resetErrors,
  resetError,
} = useFormHelper<Finding>();

const isDataPending = computed(
  () => findingsStatusesStore.isLoading || findingsTypesStore.isLoading,
);

type SelectExtra = {
  select_options?: ReadonlyArray<{ label: string; value?: string | number }>;
  autofocus?: boolean | number;
};

const isFindingsCustomFieldsEnabled = ROOM_DATA.enableFindingCustomFields;

const customFields = computed<FieldItem[]>(() => {
  return DrStore.getters["clientDashboard/customFields/byObjectType"](
    "finding",
  );
});

const findingsCustomFieldsSchema = computed<FieldSchema[]>(() =>
  isFindingsCustomFieldsEnabled
    ? customFields.value.map(mapCustomFieldToSchema)
    : [],
);

const findingFormSchema = computed<FormSchema<SelectExtra>>(() => {
  return [
    {
      type: FieldSchemaType.Select,
      prop: "type_id",
      label: "Type",
      placeholder: "Select type",
      extra: {
        select_options: findingsTypesStore.list.map((type) => ({
          label: type.name,
          value: type.id,
        })),
      },
      rules: validateString().required("Type is required"),
      required: true,
    },
    {
      type: FieldSchemaType.Text,
      prop: "title",
      label: "Title",
      placeholder: "Specify title",
      rules: validateString().required("Title is required"),
      required: true,
      extra: {
        autofocus: 300,
      },
    },
    {
      type: FieldSchemaType.Select,
      prop: "ties_to",
      label: "Ties to",
    },
    {
      type: FieldSchemaType.Richtext,
      prop: "description",
      label: "Description",
      placeholder: "Write description",
    },
    {
      type: FieldSchemaType.Select,
      prop: "severity",
      label: "Severity",
      placeholder: "Specify severity",
      extra: {
        select_options: FindingSeverityList,
      },
    },
    {
      type: FieldSchemaType.Select,
      prop: "likelihood",
      label: "Likelihood",
      placeholder: "Specify likelihood",
      extra: {
        select_options: FindingLikelihoodList,
      },
    },
    {
      type: FieldSchemaType.Select,
      prop: "assignees",
      label: "Assignees",
    },
    ...findingsCustomFieldsSchema.value,
  ];
});

const findingEmptyEntity: LocalFindingDetails = {
  type_id: "",
  ties_to: {
    categories: [],
    tasks: [],
  },
  title: "",
  description: null,
  mitigation_plan: null,
  actual_mitigation: null,
  severity: null,
  likelihood: null,
  assignees: [],
  status_id: "",
  custom_data: {},
};

const findingFormEntity = reactive<LocalFindingDetails>(
  cloneDeep(findingEmptyEntity),
);

const openStatuses = computed(() =>
  findingsStatusesStore.list.filter((status) => status.type === "open"),
);

const tiesToItems = computed(() => [
  ...findingFormEntity.ties_to.tasks.slice(0),
  ...findingFormEntity.ties_to.categories.slice(0),
]);

watch(
  () => shown.value,
  (isShown) => {
    if (isShown) {
      insightTrack(RoomFindingsCreationEvent.ModalOpened);
    }
  },
);

watch(
  () => openStatuses.value.length,
  (value) => {
    if (value) {
      findingEmptyEntity.status_id = openStatuses.value[0]!.id;
      findingFormEntity.status_id = openStatuses.value[0]!.id;
    }
  },
  {
    immediate: true,
  },
);

watch(
  () => findingsTypesStore.list.length,
  (value) => {
    if (value) {
      const firstType = [...findingsTypesStore.list].sort((a, b) =>
        a.name.localeCompare(b.name),
      )[0];

      findingEmptyEntity.type_id = firstType.id;
      findingFormEntity.type_id = firstType.id;
    }
  },
  {
    immediate: true,
  },
);

watch(
  () => categoryUid.value,
  (category_uid) => {
    if (category_uid) {
      findingEmptyEntity.ties_to.categories = [{ category_uid }];
      findingFormEntity.ties_to.categories = [{ category_uid }];
    } else {
      findingEmptyEntity.ties_to.categories = [];
      findingFormEntity.ties_to.categories = [];
    }
  },
  {
    immediate: true,
  },
);

watch(
  () => taskUid.value,
  (task_uid) => {
    if (task_uid) {
      findingEmptyEntity.ties_to.tasks = [{ task_uid }];
      findingFormEntity.ties_to.tasks = [{ task_uid }];
    } else {
      findingEmptyEntity.ties_to.tasks = [];
      findingFormEntity.ties_to.tasks = [];
    }
  },
  {
    immediate: true,
  },
);

const handleUpdateFindingForm = ({
  field,
  value,
}: {
  field: string;
  value: any;
}) => {
  resetError(field);
  set(findingFormEntity, field, value);
  if (field === "ties_to") {
    checkAssigneesGroupPermissions();
  }
};

const handleRemoveTiesToItem = (
  item:
    | LocalFindingDetails["ties_to"]["tasks"][number]
    | LocalFindingDetails["ties_to"]["categories"][number],
  veeField: FieldProps["veeField"],
) => {
  const { tasks, categories } = findingFormEntity.ties_to;
  const update = { tasks, categories };

  if ("task_uid" in item) {
    remove(update.tasks, (t) => t.task_uid === item.task_uid);
  }

  if ("category_uid" in item) {
    remove(update.categories, (c) => c.category_uid === item.category_uid);
  }

  veeField.onChange(update);
};

const tiesToCategoriesIds = computed(() =>
  findingFormEntity.ties_to.categories
    .map((cat) => categoriesStore.categoriesByUid[cat.category_uid]?.id)
    .filter(Boolean),
);

const tiesToRequestCategoriesIds = computed(() =>
  findingFormEntity.ties_to.tasks
    .map((task) => tasksStore.tasksByUid[task.task_uid]?.category_id)
    .filter(Boolean),
);

const filterAssigneeGroup = (group: Group) => {
  const allowByCategories = tiesToCategoriesIds.value.some((categoryId) =>
    group.viewable_categories_ids.includes(categoryId),
  );
  const allowByTasks = tiesToRequestCategoriesIds.value.some((categoryId) =>
    group.viewable_categories_ids.includes(categoryId),
  );

  if (group.is_finding_managers) {
    if (tiesToCategoriesIds.value.length && allowByCategories) {
      return true;
    }
    if (tiesToRequestCategoriesIds.value.length && allowByTasks) {
      return true;
    }
    if (
      !tiesToCategoriesIds.value.length &&
      !tiesToRequestCategoriesIds.value.length
    ) {
      return true;
    }
  }
  return false;
};

const checkAssigneesGroupPermissions = () => {
  const assignees = findingFormEntity.assignees.map(
    (assignee) => DrStore.state.room.members.membersByUid[assignee.user_id],
  );

  const assigneesGroups = Object.values(
    assignees.reduce<Record<Group["id"], Group>>((acc, assignee) => {
      const group = DrStore.state.room.groups.pgroups[assignee.pgroup.id];
      if (group && !acc[group.id]) {
        acc[group.id] = group;
      }
      return acc;
    }, {}),
  );

  const allowedGroupsIds = assigneesGroups
    .filter(filterAssigneeGroup)
    .map((group) => group.id);

  if (assigneesGroups.length > allowedGroupsIds.length) {
    findingFormEntity.assignees = assignees
      .filter((assignee) => allowedGroupsIds.includes(assignee.pgroup.id))
      .map((assignee) => ({ user_id: assignee.uid }));

    $notifyWarning(t("findings.assignees_filtered_out_by_perms"));
  }
};

const handleRemoveAssigneesItem = (
  item: LocalFindingDetails["assignees"][number],
) => {
  findingFormEntity.assignees = findingFormEntity.assignees.filter(
    (assignee) => assignee.user_id !== item.user_id,
  );
};

const checkAndHandleClose = () => {
  if (isEqual(findingFormEntity, findingEmptyEntity)) {
    handleClose();
  } else {
    ElMessageBox.confirm(
      "You have unsaved changes made to the new finding form. Are you sure you wish to discard these changes?",
      "Unsaved changes",
      {
        confirmButtonText: "Discard",
        cancelButtonText: "Cancel",
        customClass: $style.warnMessageBox,
        showClose: false,
      },
    ).then(handleCancel);
  }
};

const handleClose = () => {
  shown.value = false;
  categoryUid.value = undefined;
  taskUid.value = undefined;
};

// reset form on "discard"
const handleCancel = () => {
  handleClose();
  resetErrors();
  Object.assign(findingFormEntity, cloneDeep(findingEmptyEntity));
  formRef.value?.reset();
};

const handleSubmit = async () => {
  if (!formRef.value) return;

  resetErrors();
  const isValid = await formRef.value.validate();

  if (isValid) {
    const {
      type_id,
      ties_to: { categories, tasks },
      title,
      description,
      mitigation_plan,
      actual_mitigation,
      severity,
      likelihood,
      status_id,
      assignees,
    } = findingFormEntity;

    const custom_data = cloneDeep(findingFormEntity.custom_data);
    serializeCustomData(custom_data as CustomDataType, customFields.value);

    const newFindingEntity: FindingCreatePayload = cloneDeep({
      type_id,
      categories,
      tasks,
      title,
      description: description || getRichTextEmptyData(),
      mitigation_plan: mitigation_plan || getRichTextEmptyData(),
      actual_mitigation: actual_mitigation || getRichTextEmptyData(),
      severity,
      likelihood,
      status_id,
      custom_data,
    });

    if (!status_id) {
      $notifyDanger("Failed to set correct status for new finding.");
      return;
    }

    await hookFormSubmitPromise(
      findingsStore.create(newFindingEntity).then(async (newFinding) => {
        if (assignees.length) {
          hookFormSubmitPromise(
            findingsStore.updateAssignees(newFinding.id, cloneDeep(assignees)),
          );
        }
      }),
    );

    insightTrack(RoomFindingsCreationEvent.Created, {
      ties_to_with_category: categories.length ? "true" : "false",
      ties_to_with_request: tasks.length ? "true" : "false",
    });

    Object.assign(findingFormEntity, cloneDeep(findingEmptyEntity));

    handleClose();
  }
};

defineExpose({
  open: handleOpen,
  close: handleClose,
});
</script>

<style lang="scss" module>
@use "@app/styles/scss/colors";
@use "@app/styles/scss/spacing";
@use "@app/styles/scss/typography";

.warnMessageBox {
  &:global(.el-message-box) {
    max-width: 448px;
    border-left: 2px solid colors.$ad-10;
    padding: spacing.$l spacing.$xl spacing.$xl;
  }

  :global {
    .el-message-box__header {
      padding: 0 0 spacing.$xs;
    }

    .el-message-box__title {
      color: colors.$pr-900;
      font: typography.$title_bold;
      border-top: none;
      border-bottom: none;
    }

    .el-message-box__content {
      padding: 0 0 spacing.$xl;
      color: colors.$pr-900;
      font: typography.$body_regular;
    }

    .el-message-box__btns {
      padding: 0;

      .el-button.el-button--primary {
        --el-button-bg-color: #{colors.$ad-10};
        --el-button-border-color: #{colors.$ad-10};
        --el-button-hover-bg-color: #{colors.$ad-10_5};
        --el-button-hover-border-color: #{colors.$ad-10_5};
        --el-button-active-bg-color: #{colors.$ad-10_5};
        --el-button-active-border-color: #{colors.$ad-10_5};
        --el-button-outline-color: transparent;
      }
    }
  }
}

.tiesToHint {
  margin-top: spacing.$xxs;
  padding-top: spacing.$xs;
  display: flex;
  gap: spacing.$xs;
  border-top: solid 1px colors.$pr-150;
  color: colors.$pr-900;
  font-size: 13px;
  line-height: spacing.$xl;
}

.iconLock {
  color: colors.$pr-500;
}
</style>
