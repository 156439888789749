<template>
  <!-- @vue-expect-error -->
  <AuthorizeHtmlForm
    ref="authorizeForm"
    :endpoint-params="data.authorize_endpoint_params"
    :endpoint-url="authorizeUrl"
  />
  <div v-loading="isSubmitting">
    <div :class="[$style.itemFitted, $style.title]">
      <b>
        {{ data.application?.name }}
      </b>
      wants to access your
      {{ websiteName }} Account
    </div>
    <br />
    <div :class="$style.itemFitted">
      <!-- @vue-expect-error -->
      <ScopesList :scopes="data.scopes" />
      <hr />
      <div v-if="!hideClientSelection">
        <ElForm
          ref="dataForm"
          :model="formModel"
          :rules="formRules"
          label-position="top"
        >
          <ElFormItem
            label="Choose organization"
            prop="dr_org_user_id"
            :error="getBackendError('dr_org_user_id')"
          >
            <!-- @vue-expect-error -->
            <ElSelect
              v-model="formModel.dr_org_user_id"
              placeholder="Select organization"
              class="el-select--full-width"
              :clearable="true"
            >
              <ElOption
                v-for="org in data.org_memberships"
                :key="org.dr_org_user_id"
                :label="org.client_name"
                :value="org.dr_org_user_id"
              />
            </ElSelect>
          </ElFormItem>
        </ElForm>
      </div>
    </div>
    <div :class="[$style.itemFitted, $style.buttons]">
      <ElButton @click="submit(false)">Cancel</ElButton>
      <ElButton type="primary" @click="submit(true)">Allow</ElButton>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import DrForm from "@shared/ui/dr-form";

import { APP_SETTINGS, USER_DATA } from "@setups/data";
import { Urls } from "@setups/index";
import AuthorizeHtmlForm from "./AuthorizeHtmlForm.vue";
import ScopesList from "./ScopesList.vue";

import type { OAuth2AuthorizeContext } from "../OAuth2AuthorizePageData";
import type { FormRules } from "element-plus";
import type { PropType } from "vue";

interface FormModel {
  dr_org_user_id: string | null;
}
interface Data {
  authorizeUrl: string;
  websiteName: string;
  formModel: FormModel;
  formRules: FormRules;
}

export default defineComponent({
  components: {
    ScopesList,
    AuthorizeHtmlForm,
  },
  extends: DrForm,
  props: {
    data: {
      type: Object as PropType<OAuth2AuthorizeContext>,
      required: true,
    },
  },
  data(): Data {
    return {
      authorizeUrl: Urls["oauth2_server:authorize"](),
      websiteName: APP_SETTINGS.WEBSITE.NAME,
      formModel: { dr_org_user_id: null },
      formRules: {
        dr_org_user_id: [
          {
            required: true,
            message: "Organization is required.",
          },
        ],
      },
    };
  },
  computed: {
    hideClientSelection(): boolean {
      // user might be a member of multiple clients, but not all of them have access to application
      // we need to show client select even in such case (to make sure that user select client explicitly)
      return (
        USER_DATA.org_memberships.length === 1 &&
        this.data.org_memberships?.length === 1
      );
    },
  },
  beforeMount() {
    this.formModel.dr_org_user_id = this.hideClientSelection
      ? this.data.org_memberships?.[0].dr_org_user_id || null
      : null;

    this.setBackendErrors(this.data.form_errors || {});
  },
  methods: {
    async submit(allow: boolean) {
      const dataForm = this.$refs.dataForm as InstanceType<
        typeof AuthorizeHtmlForm
      >;
      if (dataForm && allow) {
        // @ts-expect-error: ...
        const isValid = await dataForm.validate();
        if (!isValid) {
          return;
        }
      }
      this.isSubmitting = true;
      const authorizeForm = this.$refs.authorizeForm as InstanceType<
        typeof AuthorizeHtmlForm
      >;
      // @ts-expect-error: ...
      authorizeForm.submitForm(allow, this.formModel.dr_org_user_id);
    },
  },
});
</script>

<style lang="scss" module>
@use "@app/styles/scss/colors" as colors;
@use "@app/styles/scss/typography.scss" as typo;

.itemFitted {
  padding: 0 30px;
}

.title {
  font-size: typo.$font-size-xx-large;
}
.buttons {
  display: flex;
  justify-content: center;
  gap: 30px;
}
</style>
