<template>
  <EditFieldContainerWithButtons
    :is-form-submitting="editProps.isFormSubmitting"
    @save="() => editProps.submitField()"
    @discard="() => editProps.quitEditModeConfirm()"
  >
    <ElInput
      ref="textareaRef"
      :class="$style.scrollbar"
      type="textarea"
      :rows="3"
      :validate-event="false"
      :model-value="editProps.value"
      :placeholder="editProps.schema.placeholder"
      v-bind="editProps.veeField"
    />
  </EditFieldContainerWithButtons>
</template>

<script setup lang="ts">
import { ElInput } from "element-plus";
import { onMounted, ref } from "vue";

import EditFieldContainerWithButtons from "./EditFieldContainerWithButtons.vue";

import type { EditFieldProps } from "../types";

interface Props {
  editProps: EditFieldProps;
}

defineProps<Props>();

const textareaRef = ref<InstanceType<typeof ElInput> | null>(null);
onMounted(() => {
  textareaRef.value?.focus();
});
</script>

<style lang="scss" module>
@use "@app/styles/scss/colors";

.scrollbar {
  :global(textarea::-webkit-scrollbar) {
    width: 10px;
  }
  :global(textarea::-webkit-scrollbar-track) {
    border-radius: 10px;
    box-shadow: inset 0 0 10px 10px colors.$pr-200;
    border: solid 3px transparent;
  }
  :global(textarea::-webkit-scrollbar-thumb) {
    border-radius: 10px;
    box-shadow: inset 0 0 10px 10px colors.$pr-400;
    border: solid 3px transparent;
  }
}
</style>
