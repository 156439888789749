import { ApiService } from "@app/vue/api-service";
import { t } from "@app/vue/i18n";

import type { IconName } from "@app/vue/shared/ui/dr-icon/icons";
import type {
  Document,
  Folder,
} from "@app/vue/store/modules/room/documents/DocumentsApiService";
import type { Group } from "@app/vue/store/modules/room/groups/GroupsApiService";

export interface FilePermissions {
  view: boolean;
  download_watermarked: boolean;
  download_original: boolean;
  edit: boolean;
}

export interface FolderPermissionsUpdate extends FilePermissions {
  folder_id: number;
}

export interface DocumentPermissionsUpdate extends FilePermissions {
  document_id: number;
}

export enum FilePermissionKey {
  VIEW = 0,
  DOWNLOAD_WATERMARKED = 1,
  DOWNLOAD_ORIGINAL = 2,
  EDIT = 3,
}

export const filePermissionKeyList: FilePermissionKey[] = [
  FilePermissionKey.VIEW,
  FilePermissionKey.DOWNLOAD_WATERMARKED,
  FilePermissionKey.DOWNLOAD_ORIGINAL,
  FilePermissionKey.EDIT,
];

export const filePermissionKeyFieldMap: Record<
  FilePermissionKey,
  keyof FilePermissions
> = {
  [FilePermissionKey.VIEW]: "view",
  [FilePermissionKey.DOWNLOAD_WATERMARKED]: "download_watermarked",
  [FilePermissionKey.DOWNLOAD_ORIGINAL]: "download_original",
  [FilePermissionKey.EDIT]: "edit",
};

export const filePermissionKeyIconMap: Record<FilePermissionKey, IconName> = {
  [FilePermissionKey.VIEW]: "eye",
  [FilePermissionKey.DOWNLOAD_WATERMARKED]: "file-download",
  [FilePermissionKey.DOWNLOAD_ORIGINAL]: "download",
  [FilePermissionKey.EDIT]: "edit",
};

export const filePermissionKeyIconDisabledMap: Partial<
  Record<FilePermissionKey, IconName>
> = {
  [FilePermissionKey.VIEW]: "eye-slash",
};

export const filePermissionKeyCaptionMap: Record<FilePermissionKey, string> = {
  [FilePermissionKey.VIEW]: t("permissions.file.caption.view"),
  [FilePermissionKey.DOWNLOAD_WATERMARKED]: t(
    "permissions.file.caption.download_watermarked",
  ),
  [FilePermissionKey.DOWNLOAD_ORIGINAL]: t(
    "permissions.file.caption.download_original",
  ),
  [FilePermissionKey.EDIT]: t("permissions.file.caption.edit"),
};

export const filePermissionKeyInfoTitleMap: Record<FilePermissionKey, string> =
  {
    [FilePermissionKey.VIEW]: t("permissions.file.info.view.title"),
    [FilePermissionKey.DOWNLOAD_WATERMARKED]: t(
      "permissions.file.info.download_watermarked.title",
    ),
    [FilePermissionKey.DOWNLOAD_ORIGINAL]: t(
      "permissions.file.info.download_original.title",
    ),
    [FilePermissionKey.EDIT]: t("permissions.file.info.edit.title"),
  };

export const filePermissionKeyInfoDescriptionMap: Record<
  FilePermissionKey,
  string
> = {
  [FilePermissionKey.VIEW]: t("permissions.file.info.view.description"),
  [FilePermissionKey.DOWNLOAD_WATERMARKED]: t(
    "permissions.file.info.download_watermarked.description",
  ),
  [FilePermissionKey.DOWNLOAD_ORIGINAL]: t(
    "permissions.file.info.download_original.description",
  ),
  [FilePermissionKey.EDIT]: t("permissions.file.info.edit.description"),
};

export const filePermissionKeyInfoCaptionMap: Partial<
  Record<FilePermissionKey, string>
> = {
  [FilePermissionKey.EDIT]: t("permissions.file.info.edit.caption"),
};

export type FilePermissionsShort = Record<FilePermissionKey, boolean> &
  [boolean, boolean, boolean, boolean];

export type FilePermissionsData = {
  folders: Record<Group["id"], Record<Folder["id"], FilePermissionsShort>>;
  documents: Record<Group["id"], Record<Document["id"], FilePermissionsShort>>;
};

export type FilePermissionsResponse = {
  items: {
    folders: Folder[];
    documents: Document[];
  };
  permissions: FilePermissionsData;
};

export const getFilePermissions = (
  value: FilePermissionsShort,
): FilePermissions => {
  const result = {} as FilePermissions;

  value.forEach((item: boolean, key: FilePermissionKey) => {
    result[filePermissionKeyFieldMap[key]] = item;
  });

  return result;
};

export class FilePermissionsApi extends ApiService {
  getPermissions(permissionGroupId?: number) {
    let url;
    if (permissionGroupId) {
      url = this.Urls["api:room:pgroup_items"](permissionGroupId);
    } else {
      url = this.Urls["api:room:pgroup_list_items"]();
    }
    return this.get<FilePermissionsResponse>(url);
  }

  updatePermissions(
    permissionGroupId: number,
    folders: FolderPermissionsUpdate[],
    files: DocumentPermissionsUpdate[],
  ) {
    const url =
      this.Urls["api:room:pgroup_change_file_permissions"](permissionGroupId);
    return this.post<void>(url, { folders, files });
  }
}
