import type { DrVxeTableColumn } from "@drVue/components/types";
import type { RoomActions } from "@drVue/store/modules/room/groups/GroupsActions";
import type { Group } from "@drVue/store/modules/room/groups/GroupsApiService";
import type {
  Category,
  CategoryNavTreeItem,
} from "@drVue/store/pinia/room/categories/categories";

type GroupsRequestsTableRow = CategoryNavTreeItem;

export type { Group, GroupsRequestsTableRow };
export const MEMBER_TABLE_FIELDS = ["name", "order"] satisfies ReadonlyArray<
  keyof GroupsRequestsTableRow
>;
export type GroupsRequestsTableField = (typeof MEMBER_TABLE_FIELDS)[number];

export interface GroupsRequestsTableColumn
  extends DrVxeTableColumn<GroupsRequestsTableRow> {
  field: GroupsRequestsTableField | `group_${Group["id"]}`;
}

export type GroupCategories = RoomActions["categories"];

export enum GROUP_TO_CATEGORY_ACCESS {
  UNSELECTED = 0,
  PARTIAL = 1,
  SELECTED = 2,
}
export type GroupToCategoriesAccessMap = Record<
  Category["id"] | "root",
  GROUP_TO_CATEGORY_ACCESS
>;
export type GroupsToCategoriesAccessMap = Record<
  Group["id"],
  GroupToCategoriesAccessMap
>;
