<template>
  <div :class="$style.container">
    <div :class="$style.title">
      <span>{{ t("table.fixed") }}</span>
    </div>
    <Draggable
      v-model="fixedCols"
      item-key="field"
      group="columns"
      handle=".fa-grip-vertical"
      :disabled="isSaving"
      @update:model-value="updatesColumnsDeferred"
    >
      <template #item="{ element: column }">
        <ColumnCustomizationFixed
          :key="column.field"
          :column="column"
          :is-disabled="isSaving"
          @update-visible="(value) => setIsVisible(column, value)"
        />
      </template>
    </Draggable>
    <div :class="$style.title">
      <span>{{ t("table.scrolls") }}</span>
    </div>
    <Draggable
      v-model="unfixedCols"
      item-key="field"
      group="columns"
      handle=".fa-grip-vertical"
      :disabled="isSaving"
      @update:model-value="updatesColumnsDeferred"
    >
      <template #item="{ element: column }">
        <ColumnCustomizationFixed
          :column="column"
          :is-disabled="isSaving"
          @update-visible="(value) => setIsVisible(column, value)"
        />
      </template>
    </Draggable>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { useI18n } from "vue-i18n";
import Draggable from "vuedraggable";

import {
  insightTrack,
  TasksTableLayoutSettingsTrackingEvent,
} from "@app/insight";
import ColumnCustomizationFixed from "./ColumnCustomizationFixed.vue";

import type { DrVxeTableColumn } from "@drVue/components/types";
import type { CustomViewColumn } from "@setups/types";
import type { PropType } from "vue";

interface Data {
  isSaving: boolean;
  fixedCols: DrVxeTableColumn[];
  unfixedCols: DrVxeTableColumn[];
}

let timeoutId: number | undefined;

export default defineComponent({
  name: "TableCustomize",
  components: {
    Draggable,
    ColumnCustomizationFixed,
  },
  props: {
    columns: {
      required: true,
      type: Array as PropType<DrVxeTableColumn<any>[]>,
    },
  },
  emits: {
    update(payload: CustomViewColumn[]) {
      return !!payload.length;
    },
  },
  setup() {
    const { t } = useI18n();
    return { t };
  },
  data(): Data {
    return {
      isSaving: false,
      fixedCols: this.columns.filter((c) => c.title && c.fixed),
      unfixedCols: this.columns.filter((c) => c.title && !c.fixed),
    };
  },
  watch: {
    columns() {
      this.fixedCols = this.columns.filter((c) => c.title && c.fixed);
      this.unfixedCols = this.columns.filter((c) => c.title && !c.fixed);
    },
  },
  mounted() {
    insightTrack(TasksTableLayoutSettingsTrackingEvent.LayoutSettingsOpened);
  },
  methods: {
    updatesColumnsDeferred() {
      clearTimeout(timeoutId);
      timeoutId = window.setTimeout(() => {
        this.fixedCols.forEach((c) => (c.fixed = "left"));
        this.unfixedCols.forEach((c) => (c.fixed = null));

        const updates = this.fixedCols
          .concat(this.unfixedCols)
          .map<CustomViewColumn>((c, i) => ({
            field: c.field,
            fixed: c.fixed ? "left" : undefined,
            order: i,
          }));

        this.updateColumns(updates);
      });
    },
    setIsVisible(column: DrVxeTableColumn, isVisible: boolean) {
      this.updateColumns([{ field: column.field, hidden: !isVisible }]);
    },
    updateColumns(updatedColumns: CustomViewColumn[]) {
      this.track(updatedColumns);
      this.$emit("update", updatedColumns);
    },
    track(updatedColumns: CustomViewColumn[]) {
      const hasOrderUpdates = updatedColumns.some((c) =>
        Object.prototype.hasOwnProperty.call(c, "order"),
      );
      if (hasOrderUpdates) {
        insightTrack(
          TasksTableLayoutSettingsTrackingEvent.LayoutColumnsReordered,
        );
      }

      const hasVisibilityUpdates = updatedColumns.some((c) =>
        Object.prototype.hasOwnProperty.call(c, "hidden"),
      );
      if (hasVisibilityUpdates) {
        insightTrack(TasksTableLayoutSettingsTrackingEvent.LayoutColumnToggled);
      }
    },
  },
});
</script>

<style lang="scss" module>
@use "@app/styles/scss/colors";
@use "@app/styles/scss/spacing";
@use "@app/styles/scss/typography" as typo;

.container {
  display: flex;
  flex-direction: column;
  gap: spacing.$xs;
}

.title {
  display: flex;
  align-items: center;
  gap: spacing.$xs;
  font: typo.$body_regular;
  color: colors.$pr-900;

  &:after {
    content: "";
    display: block;
    height: 1px;
    flex: 1;
    border-top: solid 1px #cfd7e2;
  }
}
</style>
