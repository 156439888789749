<template>
  <DrPopup ref="popupRef" paddingless :disabled="isReadOnly">
    <template #reference>
      <div
        v-if="!isReadOnly"
        :class="$style.wrapper"
        @click.capture.stop="openPopup"
      >
        <div
          :class="{
            [$style.content]: true,
            [$style.content_isEmpty]: !attachments.length,
          }"
        >
          <DrIcon size="sm" :class="$style.icon" name="paperclip" />
          <span v-if="attachments.length" :class="$style.label">
            {{ attachments.length }}
          </span>
          <DrIcon
            v-else
            size="xs"
            name="plus"
            :class="[$style.icon, $style.plusSign]"
          />
        </div>
      </div>

      <div v-else :class="$style.wrapper" />
    </template>
    <template #default>
      <AttachmentsPopupContent
        v-if="!isInBrowser"
        :items="attachments"
        :allow-attach-new-files="allowAttachNewFiles"
        :disabled="isReadOnly"
        @link-file="switchToBrowser"
      />

      <TreeBrowser
        v-else
        v-model:selected-node-ids="attachmentUids"
        is-multiple
        :width="POPUP_SIZES.wideTree.width"
        :height="POPUP_SIZES.wideTree.height"
        :is-loading="isTreeLoading"
        :tree="documentsStore.attachmentsTree"
        @save="handleRoomBrowserSubmit"
        @cancel="handleCloseRoomBrowser"
        @create-node="handleCreateFolder"
      >
        <template #tree-item="{ item }">
          <div :class="$style.treeItemContainer">
            <DrIcon
              v-if="(item as Partial<Folder>).type === 'folder'"
              :class="$style.folderIcon"
              name="folder"
            />
            <i
              v-else
              class="mimetypes"
              :class="[
                $style.fileIcon,
                getIconClass((item as Partial<Document>).mimetype),
              ]"
            />
            <span>{{ (item as unknown as Document).name }}</span>
          </div>
        </template>
      </TreeBrowser>
    </template>
  </DrPopup>
</template>

<script setup lang="ts">
import { difference } from "lodash-es";
import { computed, ref, unref } from "vue";
import { useStore } from "vuex";
import { POPUP_SIZES } from "@shared/ui/constants";
import DrIcon from "@shared/ui/dr-icon";
import { DrPopup } from "@shared/ui/dr-popups";

import getIconClass from "@app/common/mimetype";
import { insightTrack, TaskDetailsTrackingEvent } from "@app/insight";
import { useDocumentsStore } from "@app/vue/store/pinia/room/documents/documents";
import { useTasksStore } from "@app/vue/store/pinia/room/tasks";
import AttachmentsPopupContent from "@drVue/components/room/tasks/Attachments/AttachmentsPopupContent.vue";
import { TreeBrowser } from "@drVue/components/room/tasks/shared/tree-browser";
import {
  type Document,
  DocumentsApiService,
  type Folder,
} from "@drVue/store/modules/room/documents/DocumentsApiService";

import type { NodeId } from "@drVue/components/room/tasks/shared/tree-browser/types";
import type { RequestsItem } from "@drVue/components/room/tasks/TasksTable/types";
import type { DocsItem } from "@drVue/store/modules/room/documents/DocumentsApiService";
import type { RootState } from "@drVue/store/state";
import type { VxeTableConstructor, VxeTablePrivateMethods } from "vxe-table";

interface Props {
  allowAttachNewFiles: boolean;
  table: VxeTableConstructor<RequestsItem> &
    VxeTablePrivateMethods<RequestsItem>;
  taskId: number;
  isReadOnly?: boolean;
}

const props = defineProps<Props>();

const store = useStore<RootState>();

const docsApi = new DocumentsApiService();

const tasksStore = useTasksStore();
const documentsStore = useDocumentsStore();

const isTreeLoading = ref(false);
const popupRef = ref<InstanceType<typeof DrPopup> | null>(null);

const isInBrowser = ref(false);
const switchToBrowser = () => {
  isInBrowser.value = true;
};

const openPopup = () => {
  unref(popupRef)?.show();
  if (!attachments.value.length) switchToBrowser();
};

const attachments = computed(() => {
  const rel = store.state.room.tasksRelated;

  const filesIds = rel.taskDocs[props.taskId] || [];
  const foldersIds = rel.taskFolders[props.taskId] || [];

  const files = filesIds.map<DocsItem>(
    ({ document_id }) => documentsStore.fileByIdMap[document_id],
  );
  const folders = foldersIds.map<DocsItem>(
    ({ folder_id }) => documentsStore.folderByIdMap[folder_id],
  );

  return files.concat(folders).sort((a, b) => a.treePosition - b.treePosition);
});

const attachmentUids = computed<NodeId[]>(() =>
  attachments.value.map(({ uid }) => uid),
);

const handleCloseRoomBrowser = () => {
  popupRef.value?.hide();
};

const handleUpdateDocuments = async (
  add: { document_id: string }[],
  remove: { document_id: string }[],
) => {
  await tasksStore.updateDocuments(props.taskId, add, remove);
  if (add.length) {
    insightTrack(TaskDetailsTrackingEvent.AttachmentAdded, {
      type: "documents",
    });
  }
  if (remove.length) {
    insightTrack(TaskDetailsTrackingEvent.AttachmentRemoved, {
      type: "documents",
    });
  }
};

const handleRoomBrowserSubmit = async (payload: NodeId[]) => {
  isTreeLoading.value = true;
  const add = difference(payload, attachmentUids.value);
  const remove = difference(attachmentUids.value, payload);
  const addDocs = add.filter((uid) => uid.toString().startsWith("doc"));
  const addFolders = add.filter((uid) => uid.toString().startsWith("fldr"));
  const removeDocs = remove.filter((uid) => uid.toString().startsWith("doc"));
  const removeFolders = remove.filter((uid) =>
    uid.toString().startsWith("fldr"),
  );
  if (addDocs.length || removeDocs.length) {
    await handleUpdateDocuments(
      addDocs.map((uid) => ({ document_id: uid.toString() })),
      removeDocs.map((uid) => ({ document_id: uid.toString() })),
    );
  }
  if (addFolders.length || removeFolders.length) {
    await handleUpdateFolders(
      addFolders.map((uid) => ({ folder_id: uid.toString() })),
      removeFolders.map((uid) => ({ folder_id: uid.toString() })),
    );
  }
  isTreeLoading.value = false;
  popupRef.value?.hide();
  return;
};

const handleUpdateFolders = async (
  add: { folder_id: string }[],
  remove: { folder_id: string }[],
) => {
  await tasksStore.updateFolders(props.taskId, add, remove);
  if (add.length) {
    insightTrack(TaskDetailsTrackingEvent.AttachmentAdded, {
      type: "folders",
    });
  }
  if (remove.length) {
    insightTrack(TaskDetailsTrackingEvent.AttachmentRemoved, {
      type: "folders",
    });
  }
};

const handleCreateFolder = async ({
  parentId,
  name,
}: {
  parentId: NodeId;
  name: string;
}) => {
  const parent = documentsStore.treeRaw?.Folders.find(
    (item) => item.uid === parentId,
  );
  if (!parent) return;
  isTreeLoading.value = true;
  await docsApi.createFolder(parent.id, name);
  await documentsStore.syncTree();
  isTreeLoading.value = false;
};
</script>

<style module lang="scss">
@use "@app/styles/scss/colors";

.wrapper {
  width: 100%;
  height: 100%;
  &:hover {
    .content {
      opacity: 1;
    }
  }
}

.content {
  color: colors.$pr-500;
  padding: 4px 6px;
  border-radius: 6px;
  transition: all 0.2s ease-in-out;
  border: 1px solid transparent;
  display: inline-flex;
  align-items: center;
  min-width: 100%;
  margin: auto;

  &:hover {
    border: 1px solid colors.$sc-400;
    .icon {
      color: colors.$sc-600;
    }
  }
}

.content_isEmpty {
  opacity: 0;
}

.plusSign {
  margin-left: 2px;
}

.label {
  line-height: 14px;
  margin-left: 4px;
  color: colors.$pr-900;
}

.treeItemContainer {
  display: grid;
  grid-template-columns: auto 1fr;
  grid-column-gap: 6px;
  align-items: center;
}

.folderIcon {
  color: colors.$pr-400;
}

.fileIcon {
  scale: 0.7;
}
</style>
