<template>
  <DrDrawer
    :shown="isVisible"
    :title="$t('room_updates.new_update')"
    :submit="$t('room_updates.compare_rooms')"
    :disallow-submit="isFormSubmitting"
    @submit="handleFormSubmit"
    @close="close"
  >
    <template #description>
      {{ $t("room_updates.new_description") }}
      <br />
      {{ $t("room_updates.new_note") }}
    </template>

    <DrDynamicFormClassic
      ref="batchFormRef"
      :schema="batchSchema"
      :entity="batch"
      :errors="batchFormErrors"
      @update="handleFormUpdate"
      @submit="handleFormSubmit"
    />
  </DrDrawer>
</template>

<script setup lang="ts">
import { computed, ref, useTemplateRef } from "vue";
import { useI18n } from "vue-i18n";
import { DrDrawer } from "@shared/ui/dr-drawer";
import { DrDynamicFormClassic } from "@shared/ui/dr-dynamic-form";

import { DrStore } from "@app/vue";
import { $notifyDanger, $notifySuccess } from "@app/vue/common";
import { FieldSchemaType } from "@app/vue/components/client-dashboard/dynamic-form/types";
import { useFormHelper } from "@app/vue/shared/ui/dr-form";
import { type CreateBatchPayload, MultiRoomUpdateService } from "./api";

import type { Room } from "@app/vue/store/modules/client-dashboard/deals/types";

interface Emits {
  (event: "created", callback: () => void): void;
}

const emit = defineEmits<Emits>();

const { t } = useI18n();

const api = new MultiRoomUpdateService();

const isVisible = ref(false);

const batchFormRef = useTemplateRef("batchFormRef");

const batch = ref<CreateBatchPayload>({
  src_room_id: "",
  trg_room_ids: [],
  options: {},
});

const batchSchema = computed(() => {
  const rooms: Room[] = DrStore.getters[
    "clientDashboard/deals/getRooms"
  ].filter((r: Room) => r.access.administrator && !r.is_archived);

  return [
    {
      type: FieldSchemaType.Select,
      prop: "src_room_id",
      label: t("room_updates.source_room"),
      extra: {
        select_options: rooms.map((r) => ({
          label: r.title,
          value: r.uid,
        })),
      },
    },
    {
      type: FieldSchemaType.Select,
      prop: "trg_room_ids",
      label: t("room_updates.target_rooms"),
      extra: {
        multiple: true,
        select_options: rooms.map((r) => ({
          label: r.title,
          value: r.uid,
        })),
      },
    },
  ];
});

const handleFormUpdate = (payload: { field: string; value: any }) => {
  const field = payload.field as keyof CreateBatchPayload;
  batch.value[field] = payload.value;
};

const {
  formErrors: batchFormErrors,
  hookFormSubmitPromise,
  isFormSubmitting,
  resetErrors,
  resetError,
} = useFormHelper<CreateBatchPayload>();

const handleFormSubmit = () => {
  resetErrors();

  batchFormRef.value?.validate().then((isValid) => {
    if (!isValid) return;

    hookFormSubmitPromise(
      api.createBatch(batch.value).then(
        () => {
          $notifySuccess(t("room_updates.update_created"));

          emit("created", () => {
            isVisible.value = false;
          });
        },
        () => {
          $notifyDanger(t("room_updates.failed_to_create"));
        },
      ),
    );
  });
};

const open = () => {
  isVisible.value = true;
};

const close = () => {
  isVisible.value = false;
};

defineExpose({
  open,
  close,
});
</script>

<style module lang="scss"></style>
