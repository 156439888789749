<template>
  <DrVxeGrid
    :key="group?.id ?? 'all'"
    :class="{ [$style.table]: true, [$style.table_isSingleGroup]: !!group }"
    :outlined="!!group"
    :row-config="{
      useKey: true,
      keyField: 'id',
      isHover: true,
      height: 48,
    }"
    :tree-config="{
      indent: 18,
      expandAll: false,
    }"
    :sort-config="tableSortConfig"
    :columns="tableColumns.columns"
    :data="items"
    @resizable-change="handleColumnSizeChanged"
  />
  <DrNewLoader v-show="isProcessing" overlay />
</template>

<script setup lang="ts">
import { cloneDeep, orderBy } from "lodash-es";
import { computed } from "vue";
import { DrNewLoader } from "@shared/ui/dr-loader";
import DrVxeGrid from "@shared/ui/dr-vxe-grid";
import { useStorage } from "@vueuse/core";

import DrStore from "@drVue/store";
import { pinia } from "@drVue/store/pinia";
import { useCategoriesStore } from "@drVue/store/pinia/room/categories";
import { GROUP_TO_CATEGORY_ACCESS } from "../types";
import allGroupsTableColumns from "./allGroupsTableColumns";
import singleGroupTableColumns from "./singleGroupTableColumns";

import type { GroupsActions } from "../../types";
import type {
  Group,
  GroupCategories,
  GroupsRequestsTableField,
  GroupsRequestsTableRow,
  GroupsToCategoriesAccessMap,
  GroupToCategoriesAccessMap,
} from "../types";
import type { CustomViewColumn } from "@setups/types";
import type { VxeTableDefines, VxeTablePropTypes } from "vxe-table";

type StoredSettingsColumn = CustomViewColumn;
type StoredSettings = Record<"name", StoredSettingsColumn>;

const GROUPS_REQUESTS_TABLE_STORED_SETTINGS_KEY =
  "dr:groups_requests_table_stored_settings";

interface Props {
  items: GroupsRequestsTableRow[];
  actions: GroupsActions;
  isProcessing: boolean;
  group?: Group;
}

interface Emits {
  (event: "update-actions", actions: GroupsActions): void;
  (
    event: "add-category",
    reference: HTMLElement,
    parentId: GroupCategories[number]["parent_id"],
  ): void;
}

const { items, actions, group } = defineProps<Props>();
const emit = defineEmits<Emits>();

const sortTableData = (
  data: GroupsRequestsTableRow[],
  field?: GroupsRequestsTableField,
  order?: VxeTablePropTypes.SortOrder,
): GroupsRequestsTableRow[] => {
  const sortField: GroupsRequestsTableField = field || "order";
  const sortOrder = order || "asc";

  let list = [...data];

  list = orderBy(list, [sortField], [sortOrder]);

  list.forEach((item) => {
    if (item.children?.length) {
      item = {
        ...item,
        children: sortTableData(item.children, sortField, order),
      };
    }
    return item;
  });

  return list;
};

const tableSortConfig: VxeTablePropTypes.SortConfig<GroupsRequestsTableRow> = {
  defaultSort: {
    field: "order",
    order: "asc",
  },
  trigger: "cell",
  sortMethod: ({ data, sortList }) => {
    const sortItem = sortList[0];
    const { field, order } = sortItem;

    return sortTableData(data, field as GroupsRequestsTableField, order);
  },
};

const generateSettingsColumn = (key: GroupsRequestsTableField) => ({
  field: key,
  width: undefined,
  order: undefined,
  hidden: false,
});
const columnsStoredSettings = useStorage<StoredSettings>(
  GROUPS_REQUESTS_TABLE_STORED_SETTINGS_KEY,
  {
    name: generateSettingsColumn("name"),
  },
  localStorage,
  { mergeDefaults: true },
);

const handleColumnSizeChanged = (
  params: VxeTableDefines.ResizableChangeEventParams,
) => {
  const field = params.column.field as GroupsRequestsTableField;
  if (field !== "name") return;
  columnsStoredSettings.value[field].width = params.resizeWidth;
};

const columnsItems = computed(() => {
  return [columnsStoredSettings.value.name];
});

const toggleCategorySelection = (
  categoryId: GroupsRequestsTableRow["id"] | "root",
  isSelected: boolean,
  categories: GroupCategories,
): GroupCategories => {
  if (categoryId === "root") {
    categories.forEach((cat) => (cat.allowed = isSelected));
    return categories;
  }

  const category = categoriesStore.categories[categoryId];
  const ancestorsIds = categoriesStore
    .getCategoryParents(category.id)
    .map((cat) => cat.id);

  categories.forEach((cat) => {
    // select/deselect category and all sub-categories
    if (cat.id === categoryId) {
      cat.allowed = isSelected;
    } else if (category.descendants.includes(cat.id)) {
      cat.allowed = isSelected;
    }

    // select all parents
    if (isSelected && ancestorsIds.includes(cat.id)) {
      cat.allowed = true;
    }
  });
  return categories;
};

const changeAccessHandler = async (
  group: Group,
  categoryId: GroupsRequestsTableRow["id"],
  newAccess: boolean,
) => {
  const payload = cloneDeep(actions);
  payload[group.id].categories = toggleCategorySelection(
    categoryId,
    newAccess,
    payload[group.id].categories,
  );
  emit("update-actions", payload);
};

const groupsColumns = computed(() =>
  DrStore.state.room.groups.pgroupsList.slice().sort((groupA, groupB) => {
    if (groupA.builtin_admin_pgroup) return -1;
    if (groupB.builtin_admin_pgroup) return 1;
    if (groupA.is_administrator) return -1;
    if (groupB.is_administrator) return 1;
    return 0;
  }),
);

const categoriesStore = useCategoriesStore(pinia);
categoriesStore.load();

const accessMap = computed(() => {
  const allIds = categoriesStore.categoriesList.map((category) => category.id);
  const rootIds = categoriesStore.rootCategories.map((category) => category.id);

  return Object.keys(actions).reduce<GroupsToCategoriesAccessMap>(
    (accessMap, groupId) => {
      const gId = groupId as unknown as Group["id"];
      const categories = actions[gId].categories;
      const groupState = categories.reduce<GroupToCategoriesAccessMap>(
        (categoriesMap, category) => {
          categoriesMap[category.id] = category.allowed
            ? GROUP_TO_CATEGORY_ACCESS.SELECTED
            : GROUP_TO_CATEGORY_ACCESS.UNSELECTED;
          return categoriesMap;
        },
        { root: GROUP_TO_CATEGORY_ACCESS.UNSELECTED },
      );

      for (const catId of allIds) {
        const catInfo = categoriesStore.categories[catId];
        /** @note below handling only for category with access */
        if (catInfo && groupState[catId]) {
          const descendantsIds = catInfo.descendants.filter(
            (id) => id !== catInfo.id,
          );
          if (!descendantsIds.length) continue;

          const allChildSelected = descendantsIds.every(
            (descId) =>
              groupState[descId] === GROUP_TO_CATEGORY_ACCESS.SELECTED,
          );

          if (!allChildSelected) {
            groupState[catId] = GROUP_TO_CATEGORY_ACCESS.PARTIAL;
          }
        }
      }

      const allSelected = rootIds.every(
        (id) => groupState[id] === GROUP_TO_CATEGORY_ACCESS.SELECTED,
      );

      if (allSelected) {
        groupState.root = GROUP_TO_CATEGORY_ACCESS.SELECTED;
      } else {
        const someSelected = rootIds.some(
          (id) => groupState[id] !== GROUP_TO_CATEGORY_ACCESS.UNSELECTED,
        );

        if (someSelected) groupState.root = GROUP_TO_CATEGORY_ACCESS.PARTIAL;
      }

      accessMap[gId] = groupState;
      return accessMap;
    },
    {},
  );
});

const groupAccessMap = computed(() => {
  if (group) {
    return accessMap.value[group.id];
  }
  const firstGroupId: Group["id"] = Number(Object.keys(accessMap.value)[0]);
  return accessMap.value[firstGroupId];
});

const handleAddCategory = (
  reference: HTMLElement,
  parentId: GroupCategories[number]["parent_id"],
) => {
  emit("add-category", reference, parentId);
};

const tableColumns = computed(() =>
  group
    ? new singleGroupTableColumns(group, groupAccessMap, {
        onChange: changeAccessHandler,
        onAddCategory: handleAddCategory,
      })
    : new allGroupsTableColumns(columnsItems, groupsColumns, accessMap, {
        onChange: changeAccessHandler,
      }),
);
</script>

<style lang="scss" module>
.table {
  :global {
    .vxe-table.border--full {
      .vxe-table--header-wrapper,
      .vxe-header--column {
        background: none !important;
      }

      .vxe-body--column:last-child:not(:first-child) {
        background-size:
          1px 100%,
          100% 1px;
      }
    }
  }
}

.table_isSingleGroup {
  height: 536px;

  :global {
    .vxe-table .vxe-cell--sort-vertical-layout {
      height: 1.8em;
    }
  }
}

.table:not(.table_isSingleGroup) {
  :global {
    .vxe-table.border--full {
      .vxe-header--column {
        vertical-align: bottom;

        .vxe-cell {
          padding: 0 !important;
        }
      }
    }
  }
}
</style>
