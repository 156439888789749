// https://github.com/shawnmclean/Idle.js
import Idle from "./idle";
import { generateUUID } from "./vue/common";

(function () {
  "use strict";

  angular
    .module("dealroom.time-tracking", ["dealroom.config"])
    .service("AppVersionService", AppVersionService)
    .factory("TrackActivity", [
      "$interval",
      "$http",
      "$rootScope",
      "URLS",
      "AppVersionService",
      "AppDebug",
      TrackActivityFactory,
    ])
    .factory("AppDebug", AppDebugFactory);

  function AppVersionService() {
    var lastVersion,
      versionChanged = false;

    function setVersion(newVersion) {
      if (newVersion !== lastVersion && lastVersion) {
        versionChanged = true;
      }

      lastVersion = newVersion;
    }

    function isVersionChanged() {
      return versionChanged;
    }

    return {
      setVersion: setVersion,
      isVersionChanged: isVersionChanged,
    };
  }

  function AppDebugFactory() {
    // Constants and state
    var updateRate = 21 * 60; // 21 minutes in seconds
    var lastUpdated = 0;
    var tabOpenedAt = new Date().getTime();
    var dataUpdateCount = 0;
    var tabUUID = generateUUID();

    function openDuration() {
      return Math.round((new Date().getTime() - tabOpenedAt) / 1000);
    }

    function bytesToMb(val) {
      return Math.round(val / 1024 / 1024);
    }

    function memoryUsage() {
      if (window.performance && window.performance.memory) {
        return {
          totalJSHeapSize: bytesToMb(window.performance.memory.totalJSHeapSize),
          usedJSHeapSize: bytesToMb(window.performance.memory.usedJSHeapSize),
        };
      }

      return {};
    }

    function needUpdate() {
      if (openDuration() < 5) return false;
      return (new Date().getTime() - lastUpdated) / 1000 > updateRate;
    }

    function getDebugData() {
      if (!needUpdate()) return;

      lastUpdated = new Date().getTime();

      return {
        tab_uuid: tabUUID,
        tab_opened_duration: openDuration(), // seconds
        data_updated_count: dataUpdateCount,
        memory: memoryUsage(),
      };
    }

    function increaseDataUpdateCount() {
      dataUpdateCount += 1;
    }

    return {
      getDebugData: getDebugData,
      increaseDataUpdateCount: increaseDataUpdateCount,
    };
  }

  function TrackActivityFactory(
    $interval,
    $http,
    $rootScope,
    URLS,
    AppVersionService,
    AppDebug,
  ) {
    // Default settings
    var object = undefined; // can be like {"type": "task", "id": 777}
    var trackingInterval = 15; // send ping request every X seconds
    var inactivityTimeout = 30; // stop sending pings after X seconds of inactivity

    var idle = new Idle({
      awayTimeout: inactivityTimeout * 1000,
    }).start();

    var tracker;

    function onAwayBack() {
      startTracking();
      $rootScope.$emit("track-activity:become-active");
    }

    function ping() {
      var data = {
        debug: AppDebug.getDebugData(),
      };

      if (object) data["object"] = object;

      $http.post(URLS["api:room:activity_ping"](), data).then(function (resp) {
        AppVersionService.setVersion(resp.data["app_version"]);
      });
    }

    function stopTracking() {
      if (tracker) {
        $interval.cancel(tracker);
        tracker = undefined;
      }

      Idle.onAwayBack.unsubscribe(onAwayBack);
    }

    function startTracking() {
      stopTracking();
      ping();

      // start pings every trackingInterval
      tracker = $interval(
        () => {
          idle.checkAway();

          if (Idle.isAway) {
            // User is inactive, stop sending pings and wait until they return
            stopTracking();
            Idle.onAwayBack.subscribe(onAwayBack);
          } else {
            // User is active
            ping();
          }
        },
        trackingInterval * 1000,
        0,
        false,
      );
    }

    function setNewObject(newObject) {
      object = newObject;
      if (tracker) ping();
    }

    return {
      isActive: () => !Idle.isAway,
      setObject: setNewObject,
      stopTracking: stopTracking,
      startTracking: startTracking,
    };
  }
})();
