<template>
  <div :class="$style.nav">
    <RoomTasksTabsNav
      v-if="ROOM_DATA.enableListsTabs"
      :active-tab="activeTab"
    />

    <RoomHeaderButton
      v-for="tab in tabs"
      :key="tab.url"
      :tab="tab"
      :active="tab.id === activeTab"
    />
  </div>
</template>

<script setup lang="ts">
import { computed } from "vue";

import { ROOM_DATA } from "@app/setups";
import { pinia } from "@app/vue/store/pinia";
import { useCategoriesStore } from "@app/vue/store/pinia/room/categories";
import RoomHeaderButton from "./RoomHeaderButton.vue";
import RoomTasksTabsNav from "./RoomTasksTabsNav.vue";
import { getActiveTabId, getBaseRoomNavTabs } from "./utils";

const categoriesStore = useCategoriesStore(pinia);

const tabs = computed(() => getBaseRoomNavTabs());

// TODO: delete ! after fixing the types
const activeTab = computed(() => getActiveTabId(categoriesStore)!);
</script>

<style lang="scss" module>
@use "@app/styles/scss/_colors";
@use "@app/styles/scss/typography" as typo;

.nav {
  display: flex;
  gap: 20px;
  height: 100%;
  align-items: center;
}
</style>
