<template>
  <TablePageLayout
    :title="
      group
        ? $t('permissions.file.page_title_selected_group')
        : $t('permissions.file.page_title_all_groups')
    "
    :search="searchPhrase"
    @search="handleSearch"
  >
    <template #subtitle>
      <FilePermissionInfo />
    </template>

    <FilePermissionsTable :group="group" :search="searchPhrase" />
  </TablePageLayout>
</template>

<script setup lang="ts">
import { ref } from "vue";

import TablePageLayout from "../TablePageLayout.vue";
import FilePermissionInfo from "./components/FilePermissionInfo.vue";
import FilePermissionsTable from "./table/FilePermissionsTable.vue";

import type { Group } from "@app/vue/store/modules/room/groups/GroupsApiService";

interface Props {
  group: Group | null;
}

defineProps<Props>();

const searchPhrase = ref("");

const handleSearch = (phrase: string) => {
  searchPhrase.value = phrase.trim();
};
</script>

<style lang="scss" module>
.buttonIcon {
  margin-left: 4px;
}
</style>
