<template>
  <DrPopup
    ref="popupRef"
    paddingless
    @show="focusSearchField"
    @hide="$emit('hide')"
  >
    <template #default="{ hide }">
      <DrPanelWrapper :height="512" :width="462" :title="labels.title">
        <template #title-action>
          <div :class="$style.titleType">
            {{ t("requests.add_selected_users_to") }}
            <ElDropdown
              trigger="click"
              :teleported="false"
              @command="handleChangeType"
            >
              <span>
                <span :class="$style.menu">
                  {{ labels.type }}
                </span>
                <DrIcon name="caret-down" size="sm" :class="$style.menuIcon" />
              </span>
              <template #dropdown>
                <ElDropdownMenu>
                  <ElDropdownItem
                    v-for="item in typeMenu"
                    :key="item.value"
                    :command="item.value"
                  >
                    {{ item.label }}
                  </ElDropdownItem>
                </ElDropdownMenu>
              </template>
            </ElDropdown>
          </div>
        </template>

        <template #subtitle>
          <ElInput
            ref="searchFieldRef"
            v-model="searchFieldText"
            clearable
            :placeholder="t('shared.search_dots')"
          >
            <template #prefix>
              <DrIcon size="sm" name="search" />
            </template>
          </ElInput>
        </template>

        <template #default>
          <DrTree
            show-checkbox
            check-on-click-node
            only-leaf-value
            default-expand-all
            show-children-on-filtered-parent
            :data="tree"
            :filter-text="searchFieldText"
            :is-pending="isPending"
            v-model="localValueTree"
            @reset-filter="searchFieldText = ''"
          >
            <template #item-prefix="{ item, className, node }">
              <DrAvatar
                v-if="node.isLeaf"
                :name="item.name"
                :identifier="(item as UsersTreeItem).email"
                :url="(item as UsersTreeItem).avatar"
                :class="className"
              />
            </template>
          </DrTree>
        </template>

        <template #footer-left>
          <ElCheckbox v-model="rewriteCheckbox">
            {{ labels.overwriteText }}
          </ElCheckbox>
        </template>

        <template #footer-right>
          <ElButton size="small" @click="hide">
            {{ t("shared.cancel") }}
          </ElButton>
          <ElButton
            size="small"
            type="primary"
            :disabled="!isValueChanged && !rewriteCheckbox"
            @click="submitModelValue(hide)"
          >
            {{ labels.submitBtnText }}
          </ElButton>
        </template>
      </DrPanelWrapper>
    </template>
  </DrPopup>
</template>

<script setup lang="ts">
import { xor } from "lodash-es";
import { computed, ref } from "vue";
import { useI18n } from "vue-i18n";
import { DrAvatar } from "@shared/ui/dr-avatar";
import { DrIcon } from "@shared/ui/dr-icon";
import { DrPanelWrapper } from "@shared/ui/dr-panels";
import { DrPopup } from "@shared/ui/dr-popups";
import { DrTree } from "@shared/ui/dr-tree";

import { insightTrack, RoomRequestsBulkEvent } from "@app/insight";

import type { TreeItem } from "@shared/ui/dr-tree";

type UsersTreeItem = TreeItem<{
  avatar: string;
  email: string;
}>;

type AssigneType = "assignees" | "followers" | "reviewers";

export interface BulkUpdateAssigneesPayload {
  value: TreeItem["id"][];
  rewrite: boolean;
  type: AssigneType;
}

interface Props {
  tree: TreeItem[];
  /** Preselected items */
  modelValue?: TreeItem["id"][];
  /** Data is loading/processing/preparing */
  isPending?: boolean;
}

interface Emits {
  (event: "submit", payload: BulkUpdateAssigneesPayload): void;
  (e: "hide"): void;
}

const props = withDefaults(defineProps<Props>(), {
  modelValue: () => [],
  isPending: false,
});

const emit = defineEmits<Emits>();

const { t } = useI18n();

const popupRef = ref<InstanceType<typeof DrPopup>>();

const collectIds = (item: TreeItem): TreeItem["id"][] => {
  if (item.children && item.children.length) {
    const subItemIds = item.children.map(collectIds).flat();
    return [item.id].concat(subItemIds);
  }
  return [item.id];
};

const searchFieldRef = ref<HTMLInputElement | null>(null);
const searchFieldText = ref("");
const rewriteCheckbox = ref(false);
const selectedType = ref<AssigneType>("assignees");

const typeMenu: { value: AssigneType; label: string }[] = [
  {
    value: "assignees",
    label: t("shared.assignees"),
  },
  {
    value: "reviewers",
    label: t("shared.reviewers"),
  },
  {
    value: "followers",
    label: t("shared.followers"),
  },
];

const handleChangeType = (type: AssigneType) => {
  selectedType.value = type;
};

const labels = computed(() => {
  switch (selectedType.value) {
    case "reviewers":
      return {
        title: t("requests.set_reviwers"),
        type: t("shared.reviewers"),
        overwriteText: t("requests.rewrite_reviewers"),
        submitBtnText: t("requests.update_reviewers"),
      };
    case "followers":
      return {
        title: t("requests.set_followers"),
        type: t("shared.followers"),
        overwriteText: t("requests.rewrite_followers"),
        submitBtnText: t("requests.update_followers"),
      };
    default:
      return {
        title: t("requests.set_assignees"),
        type: t("shared.assignees"),
        overwriteText: t("requests.rewrite_assignees"),
        submitBtnText: t("shared.assign"),
      };
  }
});

const localValueTree = ref<TreeItem["id"][]>(props.modelValue.slice());

const isValueChanged = computed(
  () => !!xor([...localValueTree.value], props.modelValue).length,
);

const focusSearchField = () => {
  searchFieldRef.value?.focus();
};

const submitModelValue = (hidePopup?: () => void) => {
  if (isValueChanged.value || rewriteCheckbox.value) {
    emit("submit", {
      value: [...localValueTree.value],
      rewrite: rewriteCheckbox.value,
      type: selectedType.value,
    });

    insightTrack(RoomRequestsBulkEvent.AssigneesSet, {
      type: selectedType.value,
      number: String(localValueTree.value.length),
      overwrite: rewriteCheckbox.value ? "yes" : "no",
    });
  }

  hidePopup?.();
};

const show = (reference: HTMLElement) => {
  popupRef.value?.show(reference);
};

defineExpose({ show });
</script>

<style module lang="scss">
@use "@app/styles/scss/colors";
@use "@app/styles/scss/typography" as typo;

.titleType {
  white-space: nowrap;
  font: typo.$caption_regular;
}

.menu {
  font: typo.$caption_regular;
  text-decoration: underline;
  text-transform: lowercase;
}

.menuIcon {
  color: colors.$pr-400;
}
</style>
