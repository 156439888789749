<template>
  <ElDialog
    v-model="isModalVisible"
    append-to-body
    :modal-class="$style.modal"
    :show-close="false"
    :before-close="beforeClose"
  >
    <template #header="{ close: headerClose, titleId }">
      <h4 :id="titleId" :class="$style.title">
        {{ t("requests.import_requests") }}
      </h4>
      <button :class="$style.closeButton" @click="headerClose">
        <DrIcon name="remove" />
      </button>
    </template>

    <div :class="$style.body">
      <div :class="$style.section">
        <div :class="$style.sectionText">
          <div :class="$style.order">1</div>
          <b>{{ t("requests.import_step_1.download_our_template") }}</b>
          {{ t("requests.import_step_1.to_orgaine_your_requests") }}
        </div>
        <ElButton
          type="primary"
          :disabled="isFormSubmitting"
          @click="downloadTemplate"
        >
          {{ t("requests.download_template") }}
        </ElButton>
        <a
          ref="templateLinkRef"
          :href="downloadTemplateUrl"
          style="display: none"
        />
      </div>

      <hr :class="$style.divider" />

      <div :class="$style.section">
        <div :class="$style.sectionText">
          <div :class="$style.order">2</div>
          <b>{{ t("requests.import_step_2.upload_your_xlsx") }}</b>
          {{ t("requests.import_step_2.to_orgaine_your_requests") }}
        </div>
        <ElButton
          type="primary"
          :plain="!isFormSubmitting"
          :disabled="isFormSubmitting"
          @click="browseComputer"
        >
          {{ t("shared.browse_computer") }}
        </ElButton>
      </div>

      <div :class="$style.dropZone">
        <DrDropZone
          :default-label="[t('requests.or_upload_by_dragging')]"
          :uploading-label="t('requests.importing_requests')"
          :is-uploading="isFormSubmitting"
          @upload="checkAndSaveFile"
        >
          <div v-if="fileRef" :class="$style.selectedFile">
            <i class="mimetypes ico-spreadsheet" />
            <div :class="$style.selectedFileName">
              {{ fileRef.name }}
            </div>
            <DrIcon
              name="remove-l"
              size="sm"
              :class="$style.selectedFileCloser"
              @click="clearFile"
            />
          </div>
        </DrDropZone>
      </div>
    </div>

    <template #footer>
      <ElButton
        type="primary"
        :disabled="!fileRef || isFormSubmitting"
        @click="uploadFile"
      >
        {{ t("shared.import") }}
      </ElButton>
    </template>

    <ElDialog
      v-model="isResultShown"
      append-to-body
      :modal-class="$style.modal"
      :show-close="false"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      :align-center="!resultLog.length"
    >
      <template #header="{ titleId }">
        <h4 :id="titleId" :class="$style.title">{{ resultTitle }}</h4>
        <button :class="$style.closeButton" @click="close">
          <DrIcon name="remove" />
        </button>
      </template>

      <div v-if="resultLog.length" :class="$style.resultBody">
        <p>{{ t("requests.errors_occured_during_import") }}</p>
        <ul>
          <li v-for="msg in resultLog" :key="msg">
            {{ msg }}
          </li>
        </ul>
      </div>

      <template #footer>
        <ElButton type="primary" @click="close">Ok</ElButton>
      </template>
    </ElDialog>
  </ElDialog>
</template>

<script setup lang="ts">
import { ref } from "vue";
import { useI18n } from "vue-i18n";
import * as Sentry from "@sentry/browser";
import { DrDropZone } from "@shared/ui/dr-drop-zone";
import { useFormHelper } from "@shared/ui/dr-form";
import { DrIcon } from "@shared/ui/dr-icon";

import { Urls } from "@setups/urls";
import { insightTrack, RoomTasksImportExportEvent } from "@app/insight";
import { $notifyDanger, $notifySuccess } from "@drVue/common";
import { pinia } from "@drVue/store/pinia";
import {
  type TasksImportResult,
  useTasksStore,
} from "@drVue/store/pinia/room/tasks";
import { openFilesDialog } from "@drVue/utils/filesDialog";

import type { UploadItem } from "@drVue/utils/useDropZone";

const { t } = useI18n();

const downloadTemplateUrl = Urls["api:room:tasks_import_example"]();

const tasksStore = useTasksStore(pinia);

const { hookFormSubmitPromise, isFormSubmitting } =
  useFormHelper<TasksImportResult>();

const isModalVisible = ref(false);
const templateLinkRef = ref<HTMLAnchorElement>();
const fileRef = ref<File>();
const resultTitle = ref<string>("");
const resultLog = ref<string[]>([]);
const isResultShown = ref(false);

const open = () => {
  resultTitle.value = "";
  resultLog.value = [];
  isModalVisible.value = true;
  clearFile();
};
const close = () => {
  isResultShown.value = false;
  isModalVisible.value = false;
};

const downloadTemplate = () => templateLinkRef.value?.click();

const browseComputer = () => {
  openFilesDialog(["xls"]).then(
    (files) => {
      if (files[0] instanceof File) {
        checkAndSaveFile(files[0]);
      }
    },
    () => {},
  );
};

const beforeClose = (done: () => void) => {
  if (!isFormSubmitting.value) {
    done();
  }
};

const clearFile = () => {
  fileRef.value = undefined;
};

const checkAndSaveFile = (fileToCheck?: File | UploadItem[]) => {
  const file = Array.isArray(fileToCheck) ? fileToCheck[0] : fileToCheck;
  if (!file || !(file instanceof File) || !file.name.endsWith(".xlsx")) {
    $notifyDanger(t("requests.upload_unsupported_file_format"));
    return;
  }

  fileRef.value = file;
};

const uploadFile = async () => {
  if (isFormSubmitting.value || !fileRef.value) {
    return;
  }

  hookFormSubmitPromise(tasksStore.importFromExcelFile(fileRef.value)).then(
    (result) => {
      if (result.status === "success") {
        resultTitle.value = t("requests.all_requests_were_imported");
      } else if (result.status === "partial") {
        resultTitle.value = t("requests.some_requests_were_imported");
      } else if (result.status === "failed") {
        resultTitle.value = t("requests.no_requests_were_imported");
      }

      if (result.log_messages) {
        resultLog.value = result.log_messages.map((msg) => msg.text);
      }

      if (result.status === "success") {
        $notifySuccess(resultTitle.value);
      } else {
        isResultShown.value = true;
      }
      isModalVisible.value = false;

      if (["success", "partial"].includes(result.status)) {
        insightTrack(RoomTasksImportExportEvent.Imported);
      }
    },
    (err) => {
      let knownError;
      if (err.status === 400) {
        if (err.data.file) {
          knownError = err.data.file;
        }
      } else if (err.status === 413) {
        knownError = t("requests.file_is_too_large");
      }

      resultTitle.value = knownError
        ? t("requests.upload_expected_error", { expected_error: knownError })
        : t("requests.upload_unexpected_error");

      isResultShown.value = true;
      isModalVisible.value = false;

      Sentry.captureEvent({
        message: "Requests import error",
        extra: { response: err },
        level: "error",
      });
    },
  );
};

defineExpose({
  open,
  close,
});
</script>

<style lang="scss" module>
@use "@app/styles/scss/colors";
@use "@app/styles/scss/spacing";
@use "@app/styles/scss/typography" as typo;

.modal :global {
  .el-dialog {
    width: 660px;
    padding: spacing.$l spacing.$xl spacing.$xl spacing.$xl;
  }

  .el-dialog__header {
    padding: 0;
  }
}

.title {
  margin: 0;
  padding-bottom: 18px;
  font: typo.$title_bold;
  color: colors.$pr-900;
}

.closeButton {
  position: absolute;
  top: 4px;
  right: 4px;
  z-index: 10;
  width: 20px;
  height: 20px;
  padding: 0;
  color: colors.$pr-350;
  background-color: transparent;
  border: none;
  cursor: pointer;
  transition: all 0.2s ease;

  &:hover {
    color: colors.$pr-400;
  }
}

.body {
  padding-left: spacing.$xxl;
}

.section {
  display: grid;
  grid-template-columns: auto min-content;
  gap: 25px;
  align-items: center;
  font: typo.$body_regular;
  color: colors.$pr-900;

  b {
    font-weight: 700;
  }
}

.sectionText {
  position: relative;
}

.order {
  position: absolute;
  left: -32px;
  top: 0;
  width: 22px;
  height: 22px;
  text-align: center;
  line-height: 20px;
  border: solid 1px colors.$pr-150;
  border-radius: 50%;
  color: colors.$pr-500;
}

.divider {
  color: colors.$pr-150;
  margin: spacing.$s 0;
}

.dropZone {
  margin-top: spacing.$m;
}

.resultBody {
  border-top: solid 1px colors.$pr-150;

  p {
    margin: spacing.$m 0 spacing.$xs;
    font: typo.$caption_semibold;
  }

  ul {
    padding-left: 0;
    height: 194px;
    overflow: auto;
    font: typo.$caption_regular;
  }
}

.selectedFile {
  display: inline-flex;
  align-items: center;
  gap: spacing.$xs;
  color: colors.$pr-900;
}

.selectedFileName {
  font: typo.$body_regular;
  padding-right: spacing.$xs;
  border-right: solid 1px colors.$pr-300;
  line-height: 18px;
}

.selectedFileCloser {
  cursor: pointer;
}
</style>
