import { isTask } from "@app/ng/tasks/services/helpers/getItemType";
import { ROOM_DATA } from "@app/setups";
import { t } from "@app/vue/i18n";

import type { VxeTableDefines, VxeTablePropTypes } from "vxe-table";

export const TaskContextAction = {
  FOLLOW: "follow",
  COPY_URL: "copy-url",
  ADD_BELOW: "add-request-below",
  MOVE_TO_TOP: "move-to-top",
  MOVE: "move",
  DUPLICATE: "duplicate",
  RESTORE: "restore",
  DELETE: "delete",
} as const;
export type TaskContextActionCode =
  (typeof TaskContextAction)[keyof typeof TaskContextAction];

const TaskContextActionLabels: Record<TaskContextActionCode, string> = {
  [TaskContextAction.FOLLOW]: t("shared.follow"),
  [TaskContextAction.COPY_URL]: t("shared.copy_url"),
  [TaskContextAction.ADD_BELOW]: t("requests.add_request_below"),
  [TaskContextAction.MOVE_TO_TOP]: t("requests.move_to_top"),
  [TaskContextAction.MOVE]: t("shared.move"),
  [TaskContextAction.DUPLICATE]: t("shared.duplicate"),
  [TaskContextAction.RESTORE]: t("shared.restore"),
  [TaskContextAction.DELETE]: t("shared.delete"),
};

// Transforms enumerable member to a display name
function makeMenuItem(
  code: TaskContextActionCode,
  inTheArchive: boolean,
): VxeTableDefines.MenuFirstOption {
  const name = TaskContextActionLabels[code];
  return {
    name,
    code,
    visible: CONFIG[code](inTheArchive),
    prefixConfig: { className: "hidden" },
    suffixConfig: { className: "hidden" },
  };
}

const CONFIG = {
  [TaskContextAction.FOLLOW]: (inTheArchive: boolean) => !inTheArchive,
  [TaskContextAction.COPY_URL]: (inTheArchive: boolean) => !inTheArchive,
  [TaskContextAction.ADD_BELOW]: (inTheArchive: boolean) => !inTheArchive,
  [TaskContextAction.MOVE_TO_TOP]: (inTheArchive: boolean) => !inTheArchive,
  [TaskContextAction.MOVE]: (inTheArchive: boolean) => !inTheArchive,
  [TaskContextAction.DUPLICATE]: (inTheArchive: boolean) => !inTheArchive,
  [TaskContextAction.RESTORE]: (inTheArchive: boolean) => inTheArchive,
  [TaskContextAction.DELETE]: (inTheArchive: boolean) => !inTheArchive,
};

export const makeTableMenu = ({
  inTheArchive = false,
}: {
  inTheArchive: boolean;
}): VxeTablePropTypes.MenuConfig => {
  const options: VxeTableDefines.MenuFirstOption[][] = [
    [
      makeMenuItem(TaskContextAction.FOLLOW, inTheArchive),
      makeMenuItem(TaskContextAction.COPY_URL, inTheArchive),
    ],
    [],
  ];

  if (ROOM_DATA?.userPermissions?.canCreateTasks) {
    options[1].push(makeMenuItem(TaskContextAction.ADD_BELOW, inTheArchive));
  }

  options[1].push(makeMenuItem(TaskContextAction.MOVE, inTheArchive));
  options[1].push(makeMenuItem(TaskContextAction.MOVE_TO_TOP, inTheArchive));

  if (ROOM_DATA?.userPermissions?.canCreateTasks) {
    options[1].push(makeMenuItem(TaskContextAction.DUPLICATE, inTheArchive));
  }

  if (ROOM_DATA?.userPermissions?.canDeleteTasks) {
    options.push([
      makeMenuItem(TaskContextAction.RESTORE, inTheArchive),
      makeMenuItem(TaskContextAction.DELETE, inTheArchive),
    ]);
  }

  return {
    body: { options },
    visibleMethod(params) {
      const { options, row } = params;

      // Prevent context menu from showing on non-task (category) rows
      if (!isTask(row)) {
        (params as unknown as { $event: PointerEvent }).$event.preventDefault();
        return false;
      }

      options.forEach((list) => {
        list.forEach((item) => {
          if (!item.code) return;

          const configKey = item.code as TaskContextActionCode;
          item.visible = CONFIG[configKey](row.is_archived);
        });
      });
      return true;
    },
  };
};
