import { ApiService } from "@drVue/api-service";

import type { RoomMember } from "@drVue/store/modules/room/members/RoomMembersApiService";
import type { Category } from "@drVue/store/pinia/room/categories/api";
import type { Dictionary } from "@drVue/types";

export enum PermissionMode {
  InheritAlways = "inherit_always",
  InheritNoConflict = "inherit_no_conflict",
  DenyAll = "deny_all",
}

export interface Group {
  id: number;
  name: string;
  room_id: number;
  is_archived: boolean;
  symmetrical_view: boolean;
  viewable_categories_ids: Category["id"][];
  builtin_admin_pgroup: boolean;
  is_administrator: boolean;
  can_task_manage: boolean;
  default_permissions_mode: PermissionMode;
  category_default_permissions_mode: PermissionMode;
  is_finding_managers: boolean;

  members: RoomMember[];
  pendingMembers: RoomMember[];
  activeMembers: RoomMember[];
}

export class GroupsApiService extends ApiService {
  public async getAllGroups() {
    const url = this.Urls["api:room:pgroup_root"]();
    return this.get<Group[]>(url);
  }

  public async editGroup(permissionId: number, data: any) {
    const url = this.Urls["api:room:pgroup_edit"](permissionId);
    return this.put(url, data);
  }

  public async copyGroup(permissionId: number, data: any) {
    const url = this.Urls["api:room:pgroup_copy"](permissionId);
    return this.post(url, data);
  }

  public async createGroup(data: any) {
    const url = this.Urls["api:room:pgroup_root"]();
    return this.post(url, data);
  }

  public async getItems(permissionId: number) {
    let url;
    if (permissionId) {
      url = this.Urls["api:room:pgroup_items"](permissionId);
    } else {
      url = this.Urls["api:room:pgroup_list_items"]();
    }
    return this.get(url);
  }

  public async updatePermissions(
    pgroupId: number,
    folders_updates: any,
    files_updates: any,
  ) {
    const url = this.Urls["api:room:pgroup_change_file_permissions"](pgroupId);
    const data = {
      folders: folders_updates,
      files: files_updates,
    };
    return this.post(url, data);
  }

  public async getActions(pgroupId?: number): Promise<Dictionary<any>> {
    let url;
    if (pgroupId) {
      url = this.Urls["api:room:pgroup_actions"](pgroupId);
    } else {
      url = this.Urls["api:room:pgroup_list_actions"]();
    }
    return this.get(url);
  }

  public async updateActions(pgroupId: number, data: any): Promise<any> {
    const url = this.Urls["api:room:pgroup_actions"](pgroupId);
    return this.post(url, data);
  }
}
