import { keyBy } from "lodash-es";
import { h } from "vue";
import { type CheckboxParams, DrVxeCheckboxCell } from "@shared/ui/dr-vxe-grid";
import { TableInlineEditor } from "@shared/ui/table-inline-editor";

import { t } from "@drVue/i18n";
import { TableColumnsBase } from "@drVue/TableColumnsBase";
import RestoreCell from "./cells/GroupsArchiveTableRestoreCell.vue";

import type { GroupsArchiveTableColumn, GroupsArchiveTableRow } from "../types";
import type { CustomViewColumn } from "@setups/types";
import type { ComputedRef } from "vue";

export default class TableColumns extends TableColumnsBase<GroupsArchiveTableRow> {
  constructor(private readonly viewColumns: ComputedRef<CustomViewColumn[]>) {
    super();
  }

  /** just to fix requires of inlineEditor in TableColumnsBase */
  inlineEditor = new TableInlineEditor(
    async (
      _changes: Partial<GroupsArchiveTableRow>,
      _group: GroupsArchiveTableRow,
    ) => {
      return Promise.resolve();
    },
    "id",
  );

  getUserColumns(): Record<string, CustomViewColumn> {
    const columns = this.viewColumns.value;
    return columns ? keyBy(columns, "field") : {};
  }

  getTableColumns(): GroupsArchiveTableColumn[] {
    return [this.checkboxColumn(), this.nameColumn(), this.restoreColumn()];
  }

  private checkboxColumn(): GroupsArchiveTableColumn {
    return {
      field: "_checkbox",
      type: "checkbox",
      title: "",
      fixed: "left",
      resizable: false,
      width: 35,
      showHeaderOverflow: false,
      className: "dr-vxe-column--centered",
      headerClassName: "dr-vxe-column--centered",
      sortable: false,
      slots: {
        checkbox: (params) => {
          const checkboxParams = params as unknown as CheckboxParams;
          return [h(DrVxeCheckboxCell, { params: checkboxParams })];
        },
      },
    };
  }

  private nameColumn(): GroupsArchiveTableColumn {
    return {
      field: "name",
      title: t("permissions.group"),
      resizable: false,
      sortable: true,
      showOverflow: "ellipsis",
    };
  }

  private restoreColumn(): GroupsArchiveTableColumn {
    return {
      field: "_menu",
      width: 40,
      fixed: "right",
      resizable: false,
      sortable: false,
      slots: {
        default: ({ row }) => h(RestoreCell, { groupId: row.id }),
      },
    };
  }
}
