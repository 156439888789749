<template>
  <div>
    <div>
      <ElCheckbox
        :value="state.selected"
        class="groups-control__checkbox"
        @change="toggleGroup"
      >
        {{ group.name }}
      </ElCheckbox>
    </div>

    <div v-if="state.selected" class="groups-control__options">
      <!-- @vue-expect-error -->
      <ElCheckbox
        v-model="isAllMembersSelected"
        :disabled="members.length === 0"
        class="groups-control__checkbox"
        :indeterminate="isAllMembersSelected === null"
      >
        Copy members
        <span v-if="members.length"> ({{ members.length }}) </span>
        <span v-else> (Empty group) </span>
      </ElCheckbox>
      <span class="groups-control__label">
        <i
          v-if="members.length > 0"
          class="fas fa-chevron-up groups-control__chevron"
          :class="{ 'fa-rotate-180': !isCopyExpanded }"
          @click.stop="isCopyExpanded = !isCopyExpanded"
        />
      </span>
      <div v-if="isCopyExpanded">
        <div
          v-for="member of members"
          :key="member.id"
          class="groups-control-users"
        >
          <div>
            <ElCheckbox
              :model-value="isMemberSelected(member.id)"
              @change="(value) => toggleMember(member.id, value)"
            >
              <UserInformation
                :user="member"
                :is-link="false"
                :show-tooltip="false"
                size="24px"
                class="cursor-pointer"
              />
            </ElCheckbox>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { cloneDeep } from "lodash-es";
import { defineComponent } from "vue";
import { UserInformation } from "@shared/ui/users";

import type { GroupCopyState } from "./types";
import type { RoomGroup } from "@drVue/store/modules/client-dashboard/deals/types";
import type { OrgUser } from "@drVue/store/modules/client-dashboard/org-users/types";
import type { CheckboxValueType } from "element-plus";
import type { PropType } from "vue";

interface Data {
  isCopyExpanded: boolean;
}

export default defineComponent({
  name: "Group",
  components: {
    UserInformation,
  },
  props: {
    group: { required: true, type: Object as PropType<RoomGroup> },
    state: { required: true, type: Object as PropType<GroupCopyState> },
    members: { required: true, type: Array as PropType<OrgUser[]> },
  },
  emits: ["update-members", "toggle-group"],
  data(): Data {
    return {
      isCopyExpanded: false,
    };
  },
  computed: {
    isAllMembersSelected: {
      get: function (): boolean | null {
        if (this.state.members.length === this.members.length) {
          return true;
        }
        if (this.state.members.length > 0) {
          return null;
        }
        return false;
      },
      set: function (val: boolean | null) {
        const isAllSelected = !!val;
        let members: string[] = [];
        if (isAllSelected) {
          members = this.members.map((m) => m.id);
        }
        this.emitMembersUpdate(members);
      },
    },
  },
  methods: {
    isMemberSelected(userId: string) {
      return this.state.members.includes(userId);
    },
    emitMembersUpdate(members: string[]) {
      this.$emit("update-members", members);
    },
    toggleGroup(value: CheckboxValueType) {
      this.$emit("toggle-group", value);
    },
    toggleMember(userId: string, isSelected: CheckboxValueType) {
      const members = cloneDeep(this.state.members);
      const index = members.indexOf(userId);
      if (isSelected) {
        if (index === -1) {
          members.push(userId);
        }
      } else {
        if (index > -1) {
          members.splice(index, 1);
        }
      }
      this.emitMembersUpdate(members);
    },
  },
});
</script>
