const EXT_MIMETYPES = {
  doc: ".doc,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document",
  xls: "application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  pdf: ".pdf,application/pdf",
  audio: "audio/*",
  image: "image/*",
  video: "video/*",
  png: ".png",
} as const;

type AcceptFileTypes = keyof typeof EXT_MIMETYPES;

export function openFilesDialog(
  filesType: AcceptFileTypes[] | "*" = "*",
  miltiple = false,
  foldersOnly = false,
) {
  return new Promise<File[]>((resolve, reject) => {
    function onChange() {
      input.removeEventListener("cancel", onCancel);
      resolve(Array.from(input.files ?? []));
    }

    function onCancel() {
      input.removeEventListener("change", onChange);
      reject();
    }

    const input = document.createElement("input");
    input.setAttribute("type", "file");

    if (filesType !== "*") {
      input.setAttribute(
        "accept",
        filesType.map((ext) => EXT_MIMETYPES[ext]).join(","),
      );
    }
    if (foldersOnly) {
      input.setAttribute("webkitdirectory", "");
    }
    if (miltiple) {
      input.setAttribute("multiple", "");
    }

    input.addEventListener("cancel", onCancel, { once: true });
    input.addEventListener("change", onChange, { once: true });

    input.click();
  });
}
