import { keyBy } from "lodash-es";
import { h } from "vue";
import { TableInlineEditor } from "@shared/ui/table-inline-editor";
import { UserEmail, UserOfficeNumber } from "@shared/ui/users";

import { ROOM_DATA } from "@setups/data";
import { $notifyDanger, $notifySuccess } from "@drVue/common";
import { FieldSchemaType } from "@drVue/components/client-dashboard/dynamic-form/types";
import { t } from "@drVue/i18n";
import DrStore from "@drVue/store";
import { TableColumnsBase } from "@drVue/TableColumnsBase";
import { GroupEdit, GroupView } from "./cells/group";
import DeleteCell from "./cells/MembersTableDeleteCell.vue";
import NameCell from "./cells/MembersTableNameCell.vue";

import type { MembersTableColumn, MembersTableRow } from "../types";
import type { AddMembersCheckResponse } from "@drVue/store/modules/room/members/RoomMembersApiService";
import type { CustomViewColumn } from "@setups/types";
import type { ComputedRef } from "vue";

export default class TableColumns extends TableColumnsBase<MembersTableRow> {
  constructor(private readonly viewColumns: ComputedRef<CustomViewColumn[]>) {
    super();
  }

  inlineEditor = new TableInlineEditor(
    async (
      changes: { ["pgroup.id"]: MembersTableRow["pgroup"]["id"] },
      member: MembersTableRow,
    ) => {
      const email = member.email;
      const group =
        DrStore.state.room.groups.pgroups[changes["pgroup.id"]].name;

      const checkResult: AddMembersCheckResponse = await DrStore.dispatch(
        "room/members/addMembersCheck",
        {
          pgroupId: changes["pgroup.id"],
          data: {
            members: [{ email: member.email }],
          },
        },
      );
      if (!checkResult.moved.includes(member.email)) {
        $notifyDanger(t("permissions.move_members.failed", { email, group }));
        return Promise.resolve();
      }

      await DrStore.dispatch("room/members/addMembers", {
        pgroupId: changes["pgroup.id"],
        data: {
          members: [{ email: member.email }],
        },
      });
      $notifySuccess(t("permissions.move_members.success", { email, group }));
      return DrStore.dispatch("room/members/load");
    },
    "id",
  );

  getUserColumns(): Record<string, CustomViewColumn> {
    const columns = this.viewColumns.value;
    return columns ? keyBy(columns, "field") : {};
  }

  getTableColumns(): MembersTableColumn[] {
    return [
      this.nameColumn(),
      this.emailColumn(),
      this.phoneColumn(),
      this.companyColumn(),
      this.roleColumn(),
      this.groupColumn(),
      this.delteColumn(),
    ];
  }

  private nameColumn(): MembersTableColumn {
    return {
      field: "name",
      title: t("permissions.fields.name"),
      minWidth: 140,
      width: 312,
      fixed: "left",
      sortable: true,
      showOverflow: "ellipsis",
      slots: {
        default: ({ row }) => h(NameCell, { userUid: row.uid }),
      },
    };
  }

  private emailColumn(): MembersTableColumn {
    return {
      sortable: true,
      field: "email",
      title: t("permissions.fields.email"),
      minWidth: 150,
      width: 150,
      slots: {
        default: ({ row }) => {
          /** @note for inactive members (invites have no name), "email" is shown in the "name" column */
          if (row.name) {
            return [h(UserEmail, { email: row.email, isIconShowed: false })];
          }
          return "";
        },
      },
    };
  }

  private phoneColumn(): MembersTableColumn {
    return {
      sortable: true,
      field: "office_number",
      title: t("permissions.fields.phone"),
      minWidth: 150,
      width: 150,
      slots: {
        default: ({ row }) => {
          return [
            h(UserOfficeNumber, {
              officeNumber: row.office_number || "",
            }),
          ];
        },
      },
    };
  }

  private companyColumn(): MembersTableColumn {
    return {
      sortable: true,
      field: "company",
      title: t("permissions.fields.company"),
      minWidth: 150,
      width: 150,
    };
  }

  private roleColumn(): MembersTableColumn {
    return {
      sortable: true,
      field: "title",
      title: t("permissions.fields.title"),
      minWidth: 150,
      width: 150,
    };
  }

  private groupColumn(): MembersTableColumn {
    return {
      sortable: true,
      field: "pgroup",
      title: "Group",
      minWidth: 150,
      width: 150,
      slots: {
        default: (params) => {
          const isRoomOwner =
            "room_owner" in params.row && params.row.room_owner;
          const isReadOnly =
            isRoomOwner || !ROOM_DATA.userPermissions.administrator;
          const displayValue = isRoomOwner
            ? t("permissions.invite_members.room_owner")
            : params.row.pgroup?.name || "";

          const schema = {
            type: FieldSchemaType.Custom,
            prop: "pgroup.id",
            editComponent: GroupEdit,
            viewComponent: GroupView,
            isReadOnly,
            extra: {
              displayValue,
            },
          };
          return this.inlineEditor.renderInlineField(schema, params);
        },
      },
    };
  }

  private delteColumn(): MembersTableColumn {
    return {
      field: "_menu",
      width: 40,
      fixed: "right",
      resizable: false,
      sortable: false,
      slots: {
        default: ({ row }) => h(DeleteCell, { userUid: row.uid }),
      },
    };
  }
}
