<template>
  <div :class="$style.container">
    <DrLoader v-if="categoriesStore.isLoading" overlay />

    <DrNavTree
      title="By worklists"
      disallow-add
      :tree="treeData"
      :favorites="treeFavorites"
      :current-id="treeCurrentId"
      :footer="treeFooter"
      @select="handleCategorySelect"
      @click-footer="showArchive"
    />
  </div>
</template>

<script setup lang="ts">
import { computed } from "vue";
import { useI18n } from "vue-i18n";
import { type IconName } from "@shared/ui/dr-icon";
import { DrNewLoader as DrLoader } from "@shared/ui/dr-loader";
import { DrNavTree } from "@shared/ui/dr-nav";
import { useBrowserLocation } from "@vueuse/core";

import { ROOM_DATA } from "@setups/data";
import { pinia } from "@drVue/store/pinia";
import { useCategoriesStore } from "@drVue/store/pinia/room/categories";
import { useFindingsStore } from "@drVue/store/pinia/room/findings";
import { useTasksStore } from "@drVue/store/pinia/room/tasks";
import {
  FINDING_ROOT_CATEGORY_KEY,
  FINDING_UNTIED_CATEGORY_KEY,
} from "./types";

import type { Category } from "@drVue/store/pinia/room/categories/api";
import type { NavTreeItem } from "@shared/ui/dr-nav";

type CategoryId =
  | Category["id"]
  | typeof FINDING_UNTIED_CATEGORY_KEY
  | typeof FINDING_ROOT_CATEGORY_KEY;
type CategoryIdOrNull = CategoryId | null;

interface Props {
  /** Selected category */
  categoryId: CategoryIdOrNull;
}

interface Emits {
  (event: "select", id: CategoryId): void;
  (event: "show-archive"): void;
}

const props = defineProps<Props>();
const emit = defineEmits<Emits>();

const { t } = useI18n();
const IS_ADMIN = ROOM_DATA.userPermissions.administrator;

const categoriesStore = useCategoriesStore(pinia);
const findingsStore = useFindingsStore(pinia);
const tasksStore = useTasksStore(pinia);

const $location = useBrowserLocation();
const isArchive = computed(
  () => $location.value.hash?.startsWith("#/findings/archive") ?? false,
);

const findingsStateByCategories = computed(() => {
  const state: Record<
    CategoryId,
    {
      total: number;
      resolved: number;
    }
  > = {
    [FINDING_ROOT_CATEGORY_KEY]: {
      total: findingsStore.list.length,
      resolved: findingsStore.list.filter((item) => item.isResolved).length,
    },
    [FINDING_UNTIED_CATEGORY_KEY]: { total: 0, resolved: 0 },
  };

  findingsStore.list.forEach((findingItem) => {
    const itemCategoryWithParents = new Set<Category["id"]>();

    for (const item of findingItem.categories) {
      const category = categoriesStore.categoriesByUid[item.category_uid];
      if (category) {
        itemCategoryWithParents.add(category.id);
        categoriesStore
          .getCategoryParents(category.id)
          .forEach((parentCat) => itemCategoryWithParents.add(parentCat.id));
      }
    }

    for (const item of findingItem.tasks) {
      const task = tasksStore.tasksByUid[item.task_uid];
      const category = task
        ? categoriesStore.categories[task.category_id]
        : null;
      if (category) {
        itemCategoryWithParents.add(category.id);
        categoriesStore
          .getCategoryParents(category.id)
          .forEach((parentCat) => itemCategoryWithParents.add(parentCat.id));
      }
    }

    itemCategoryWithParents.forEach((catId) => {
      const base = state[catId] ?? {
        total: 0,
        resolved: 0,
      };

      state[catId] = {
        total: base.total + 1,
        resolved: base.resolved + (findingItem.isResolved ? 1 : 0),
      };
    });

    if (!findingItem.categories.length && !findingItem.tasks.length) {
      state[FINDING_UNTIED_CATEGORY_KEY] = {
        total: state[FINDING_UNTIED_CATEGORY_KEY].total + 1,
        resolved:
          state[FINDING_UNTIED_CATEGORY_KEY].resolved +
          (findingItem.isResolved ? 1 : 0),
      };
    }
  });

  return state;
});

const formatCategory = (
  cat: Category,
): NavTreeItem<Pick<Category, "order">> => {
  const treeItem: NavTreeItem<Pick<Category, "order">> = {
    id: cat.id,
    name: cat.name,
    order: cat.order,
    children: cat.categories.map(formatCategory),
  };

  const { resolved, total } = findingsStateByCategories.value[cat.id] || {
    total: 0,
    resolved: 0,
  };
  treeItem.counter = `${resolved}/${total}`;

  return treeItem;
};

const treeData = computed<NavTreeItem<{ order: number }>[]>(() =>
  categoriesStore.rootCategories.map(formatCategory),
);
const treeFavorites = computed(() => {
  const { resolved, total } =
    findingsStateByCategories.value[FINDING_ROOT_CATEGORY_KEY];
  const favoriteItems = [
    {
      id: FINDING_ROOT_CATEGORY_KEY,
      name: "All Findings",
      counter: `${resolved}/${total}`,
      info: "",
    },
  ];

  if (IS_ADMIN) {
    const { resolved, total } =
      findingsStateByCategories.value[FINDING_UNTIED_CATEGORY_KEY];
    favoriteItems.push({
      id: FINDING_UNTIED_CATEGORY_KEY,
      name: "Untied Findings",
      counter: `${resolved}/${total}`,
      info: `All findings not tied to any request
       or category will be placed here
       and are only visible to the
       Administrators of this room.`,
    });
  }

  return favoriteItems;
});

const treeFooter = computed(() => {
  if (!IS_ADMIN) return undefined;

  return {
    text: t("shared.archive"),
    icon: "trash" as IconName,
    isActive: isArchive.value,
  };
});

const treeCurrentId = computed<NavTreeItem["id"]>(() => {
  if (isArchive.value) return "";

  return props.categoryId || FINDING_ROOT_CATEGORY_KEY;
});

const handleCategorySelect = (itemId: NavTreeItem["id"]) => {
  const typedItemId = itemId as CategoryId;
  emit("select", typedItemId);
};

const showArchive = () => {
  emit("show-archive");
};

categoriesStore.load();
</script>

<style module lang="scss">
@use "@app/styles/scss/colors";
@use "@app/styles/scss/spacing";
@use "@app/styles/scss/typography";
@use "@app/styles/scss/values";

.container {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.footer {
  flex: 0 0 auto;
  border-top: solid 1px colors.$pr-200;
  padding: spacing.$m;
}

.linkBtn:global(.el-button.is-link) {
  --el-button-text-color: #{colors.$pr-800};
  --el-button-icon-color: #{colors.$pr-400};
  --el-button-hover-link-text-color: #{colors.$sc-600};
  --el-button-outline-color: transparent;

  > span {
    display: inline-block;
    position: relative;
    overflow: hidden;

    &:after {
      content: "";
      position: absolute;
      bottom: 3px;
      left: 0;
      width: 100%;
      height: 1px;
      background-color: currentColor;
      opacity: 0;
      transition:
        opacity 200ms,
        transform 200ms;
      transform: translate3d(-100%, 0, 0);
    }
  }
}

.linkBtn_isActive:global(.el-button.is-link) {
  --el-button-text-color: #{colors.$sc-600};
  --el-button-icon-color: #{colors.$sc-600};
}

.linkBtn:global(.el-button.is-link):hover,
.linkBtn_isActive:global(.el-button.is-link) {
  > span:after {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}
</style>
