<template>
  <div :class="$style.content">
    <div v-for="row in summaryData" :key="row.fieldId">
      <div :class="$style.rowTitle">
        {{ row.fieldLabel }}
      </div>
      <div :class="$style.rowText">
        {{ row.text }}
      </div>
    </div>

    <div v-if="!summaryData.length && !isLoading && !loadingErrorMessage">
      No data
    </div>

    <DrLoader v-if="isLoading" />

    <ElAlert
      v-if="loadingErrorMessage"
      type="error"
      :title="loadingErrorMessage"
      :closable="false"
    />
  </div>
</template>

<script lang="ts" setup>
import { computed, ref, watch } from "vue";
import DrLoader from "@shared/ui/dr-loader";

import { useAiReportsStore } from "@drVue/components/room/ai/AiReportsStore";
import { AiReportsApiService } from "@drVue/components/room/ai/api";
import { pinia } from "@drVue/store/pinia";

import type {
  AiDocReportSummary,
  AiReport,
  AiReportField,
} from "@drVue/components/room/ai/types";

interface Props {
  reportTypeId: AiReport["id"];
}
const props = defineProps<Props>();

const reportsSummary = ref<AiDocReportSummary[] | null>(null);
const aiReportsStore = useAiReportsStore(pinia);

const api = new AiReportsApiService();
const isLoading = ref(false);
const loadingErrorMessage = ref("");

export interface SummaryRow {
  fieldId: AiReportField["id"];
  fieldLabel: string;
  text: string;
}

const fetchSummary = () => {
  isLoading.value = true;
  loadingErrorMessage.value = "";

  api.loadDocReportSummary(props.reportTypeId).then(
    (data) => {
      reportsSummary.value = data;
      isLoading.value = false;
    },
    (errorResp) => {
      const errorMsg =
        errorResp.response?.data?.non_field_errors?.[0] ||
        "Failed to load reports summary";
      loadingErrorMessage.value = errorMsg;
      isLoading.value = false;
    },
  );
};

watch(
  () => props.reportTypeId,
  () => {
    fetchSummary();
  },
  { immediate: true },
);

const fieldsMap = computed(() => {
  const reportType = aiReportsStore.dict[props.reportTypeId];
  if (!reportType) return {};

  return reportType.fields.reduce<Record<AiReportField["id"], AiReportField>>(
    (acc, field) => {
      acc[field.id] = field;
      return acc;
    },
    {},
  );
});

const summaryData = computed((): SummaryRow[] => {
  if (!reportsSummary.value || !fieldsMap.value || isLoading.value) {
    return [];
  }

  return reportsSummary.value.map((summary) => ({
    fieldId: summary.report_type_template_field_id,
    fieldLabel: fieldsMap.value[summary.report_type_template_field_id].label,
    text: summary.text,
  }));
});
</script>

<style module lang="scss">
@use "@app/styles/scss/colors";
@use "@app/styles/scss/values";
@use "@app/styles/scss/spacing";
@use "@app/styles/scss/typography";

.content {
  width: 100%;
  height: 100%;
  overflow: auto;
}

.rowTitle {
  font: typography.$body_semibold;
}
.rowText {
  font: typography.$body_regular;
  white-space: pre-wrap;
  margin-bottom: spacing.$xl;
}
</style>
