import type { MANAGERS } from "./managers";
import type { FilePermissions } from "@app/vue/store/pinia/room/filePermissions/FilePermissionsApi";

export interface DocumentSibling {
  id: number;
  name: string;
  index: string;
  tree_index: string;
  mimetype: string;
  link: string;
}

interface DocumentParentFolder {
  id: number;
  name: string;
  tree_index: string;
}

export interface DocumentVersionInfo {
  id: number;
  mimetype: string;
  size: number;
  is_primary: boolean;
  date_created: Date;
}

export interface DocumentViewerInfo {
  viewer: keyof typeof MANAGERS;
  id: number;
  uid: string;
  name: string;
  index: string;
  mimetype: string;
  size: number;
  permission: FilePermissions;
  siblings: DocumentSibling[];
  /**
   * Provided only if the user has edit permissions for the document
   */
  version_id?: number;
  /**
   * Provided only if the user has edit permissions for the document
   */
  versions?: DocumentVersionInfo[];
  folder: DocumentParentFolder;
  is_primary: boolean;
  bookmarked: boolean;
  wopi_access_token?: string;
  wopi_storage_url?: string;
  is_archived?: boolean;
  content_access_token?: string;
}
export enum SidebarSection {
  INFORMATION,
  RELATED_REQUESTS,
  VERSIONS,
  REDACTION,
}
