<template>
  <!-- @vue-expect-error -->
  <DrDatepicker
    ref="pickerRef"
    type="date"
    clearable
    style="width: 100%"
    popper-class="dr-popper--inline-control"
    :size="size!"
    :validate-event="false"
    :placeholder="editProps.schema.placeholder"
    :model-value="modelValue"
    @visible-change="onVisibleChange"
    @update:model-value="onUpdate"
  />
</template>

<script setup lang="ts">
import { onMounted, ref, unref } from "vue";
import { DrDatepicker } from "@shared/ui/dr-datepicker";

import { DateType, deserializeDrDate } from "@drVue/api-service/parse";
import { FieldSchemaType } from "@drVue/components/client-dashboard/dynamic-form/types";

import type { EditFieldProps } from "@drVue/components/client-dashboard/dynamic-form/Fields/types";
import type CommonPicker from "element-plus/lib/components/time-picker/src/common/picker.vue";

const props = withDefaults(defineProps<Props>(), { size: "default" });

interface Props {
  editProps: EditFieldProps<Date | string | null>;
  size?: "default" | "small" | "large";
}

interface Emits {
  (e: "change"): void;
}

const emit = defineEmits<Emits>();

const pickerRef = ref<InstanceType<typeof CommonPicker> | null>(null);

const dateType: DateType =
  props.editProps.schema.type === FieldSchemaType.Date
    ? DateType.Date
    : DateType.DateTime;

const value =
  typeof props.editProps.value === "string"
    ? deserializeDrDate(dateType, props.editProps.value)
    : props.editProps.value;

const initialValue = ref(value);
const modelValue = ref(value);

const onUpdate = (value: Date | null) => {
  modelValue.value = value;
  props.editProps.veeField.onChange(value);
  emit("change");
};

const onVisibleChange = (isVisible: boolean) => {
  if (!isVisible) {
    const initial = unref(initialValue);
    const model = unref(modelValue);

    if (model && initial && model.getTime() === initial.getTime()) {
      props.editProps.quitEditMode();
      return;
    }

    props.editProps.submitField();
    emit("change");
  }
};

onMounted(() => {
  pickerRef.value?.handleOpen();
  pickerRef.value?.focus();
});
</script>
