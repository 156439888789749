<template>
  <DrNavTree
    ref="navTreeRef"
    :title="$t('data_room.folders')"
    :add-tooltip="$t('data_room.add_folder')"
    :root-title="documentsStore.rootFolder?.name"
    :tree="folderTree"
    :is-processing="!documentsStore.rootFolder"
    :current-id="currentTreeNode"
    :init-tree-props="{ children: 'folders' }"
    :favorites="shownFavorites"
    :disallow-add="!documentsStore.rootFolder?.edit"
    :tree-class="$style.tree"
    :footer="folderTreeFooter"
    :no-data-text="$t('data_room.no_folders_here_yet')"
    always-show-tree
    no-flat-tree
    trigger-action-slot-by-contextmenu
    @select="selectFolder($event as number)"
    @add="addFolder"
    @click-footer="$emit('click-archive')"
  >
    <template #root-prefix>
      <div
        :class="{
          [$style.iconFolder]: true,
          [$style.iconFolder_isSelected]:
            documentsStore.rootFolder?.id === selectedFolderId,
        }"
      >
        <DrIcon name="folder-open" />
      </div>
    </template>

    <template v-if="documentsStore.rootFolder" #root-action>
      <DocumentsFolderTreeMenu
        :folder="documentsStore.rootFolder"
        :disabled="isMenuPersisted"
        @update:persisted="isMenuPersisted = $event"
        @expand-folder="navTreeRef?.expandNode()"
        @collapse-folder="navTreeRef?.collapseNode()"
        @menu-open-folder="$emit('menu-open-folder', $event)"
        @menu-download="$emit('menu-download', $event)"
        @menu-edit-custom-data="$emit('menu-edit-custom-data', $event)"
        @update-items-list="$emit('update-items-list')"
      />
    </template>

    <template #item-prefix="{ item, node }">
      <div
        :class="{
          [$style.iconFolder]: true,
          [$style.iconFolder_isSelected]: item.id === selectedFolderId,
        }"
      >
        <DrIcon
          :name="
            node.expanded && item.children?.length !== 0
              ? 'folder-open'
              : 'folder'
          "
        />
      </div>
    </template>

    <template #item-action="{ item, setActive }">
      <DocumentsFolderTreeMenu
        :folder="documentsStore.folderByIdMap[item.id as number]"
        :disabled="isMenuPersisted"
        @expand-folder="navTreeRef?.expandNode(item.id)"
        @collapse-folder="navTreeRef?.collapseNode(item.id)"
        @update:active="setActive(item.id, $event)"
        @menu-open-folder="$emit('menu-open-folder', $event)"
        @menu-download="$emit('menu-download', $event)"
        @menu-edit-custom-data="$emit('menu-edit-custom-data', $event)"
        @update-items-list="$emit('update-items-list')"
      />
    </template>
  </DrNavTree>

  <FolderMenuPlacer />
</template>

<script lang="ts" setup>
import {
  type ComponentInstance,
  computed,
  markRaw,
  onBeforeMount,
  ref,
} from "vue";
import { useI18n } from "vue-i18n";
import { type IconName } from "@shared/ui/dr-icon";

import { ROOM_MEMBER_DATA } from "@setups/data";
import { AiAccess } from "@setups/enums";
import { APP_SETTINGS, ROOM_DATA } from "@app/setups";
import { useDocumentsStore } from "@app/vue/store/pinia/room/documents/documents";
import { DrIcon } from "@drVue/shared/ui/dr-icon";
import {
  DrNavTree,
  type NavTreeFavoritesItem,
  type NavTreeItem,
} from "@drVue/shared/ui/dr-nav";
import DocumentsFolderTreeMenu from "./components/DocumentsFolderTreeMenu.vue";
import FolderMenuNewFolder from "./components/FolderMenuNewFolder.vue";
import FolderMenuPlacer from "./components/FolderMenuPlacer.vue";
import IconAiAnalysis from "./svg/IconAiAnalysis.vue";
import {
  useFolderMenuBus,
  useFolderMenuPersistedBus,
} from "./use/useFolderMenuBus";

import type { Folder } from "@app/vue/store/modules/room/documents/DocumentsApiService";

const { t } = useI18n();

const IS_DEALROOM = [
  "dev-dealroom",
  "staging-dealroom",
  "production-dealroom",
].includes(APP_SETTINGS.WEBSITE.ENV);

const HAS_AI_ACCESS =
  ROOM_DATA.isAiEnabled && ROOM_MEMBER_DATA.group.ai_access === AiAccess.BASIC;

const favorites: (NavTreeFavoritesItem & { visible: boolean })[] = [
  {
    id: "ai_reports",
    name: t("shared.ai_analysis"),
    iconComponent: markRaw(IconAiAnalysis),
    // AI has to be accessible or user has to be an admin (to show banner)
    visible:
      IS_DEALROOM && (HAS_AI_ACCESS || ROOM_MEMBER_DATA.group.is_administrator),
    divided: true,
  },
  { id: "recent", name: t("data_room.recent_files"), icon: "clock", visible: true },
  { id: "bookmarks", name: t("data_room.bookmarks"), icon: "bookmark", visible: true },
] as const;

const shownFavorites = computed(() => favorites.filter((item) => item.visible));

const IS_ADMIN = ROOM_DATA.userPermissions.administrator;

type CurrentNode =
  | number
  | "root"
  | "archive"
  | (typeof favorites)[number]["id"];

interface Props {
  selectedFolderId?: number;
  isRecent?: boolean;
  isBookmarks?: boolean;
  isAiReports?: boolean;
  isArchive?: boolean;
}

interface Events {
  (e: "click-recent"): void;
  (e: "click-bookmarks"): void;
  (e: "click-archive"): void;
  (e: "click-ai-reports"): void;
  (e: "update-items-list"): void;

  (e: "menu-open-folder", params: Folder): void;
  (e: "menu-download", params: Folder): void;
  (e: "menu-edit-custom-data", params: Folder): void;
}

const props = defineProps<Props>();

const emit = defineEmits<Events>();

const isMenuPersisted = ref(false);
const documentsStore = useDocumentsStore();
const folderMenuPersistedBus = useFolderMenuPersistedBus();
const folderMenuBus = useFolderMenuBus();

folderMenuPersistedBus.on((event, value: boolean | undefined) => {
  isMenuPersisted.value = value ?? false;
});

const navTreeRef = ref<ComponentInstance<typeof DrNavTree> | undefined>();

const getNavTreeItem = (folder: Folder): NavTreeItem => {
  return {
    id: folder.id,
    name: folder.tree_index + ". " + folder.name,
    children:
      documentsStore.foldersByIdMap[folder.id].map(getNavTreeItem) ?? [],
  };
};

const folderTree = computed<NavTreeItem[]>(() => {
  if (!documentsStore.rootFolder) return [];

  const tree = getNavTreeItem(documentsStore.rootFolder);

  return tree.children ?? [];
});

const folderTreeFooter = computed(() => {
  if (!IS_ADMIN) return undefined;

  return {
    text: t("shared.trash"),
    icon: "trash" as IconName,
    isActive: props.isArchive,
  };
});

const currentTreeNode = computed<CurrentNode>(() => {
  if (props.isRecent) return "recent";
  if (props.isBookmarks) return "bookmarks";
  if (props.isBookmarks) return "bookmarks";
  if (props.isAiReports) return "ai_reports";
  if (props.isArchive) return "archive";

  if (props.selectedFolderId === documentsStore.rootFolder?.id) {
    return "root";
  }

  return props.selectedFolderId ?? "root";
});

const selectFolder = (id: CurrentNode) => {
  if (typeof id === "string") {
    switch (id) {
      case "recent":
        emit("click-recent");
        break;
      case "bookmarks":
        emit("click-bookmarks");
        break;
      case "ai_reports":
        emit("click-ai-reports");
        break;
      case "root":
        if (documentsStore.rootFolder) {
          emit("menu-open-folder", documentsStore.rootFolder);
        }
        break;
    }

    return;
  }

  emit("menu-open-folder", documentsStore.folderByIdMap[id]);
};

const addFolder = (reference: HTMLElement): void => {
  const folder = documentsStore.rootFolder;

  if (!folder) return;

  folderMenuBus.emit("menu:show", {
    reference,
    params: {
      folders: [folder],
      documents: [],
    },
    menuComponent: markRaw(FolderMenuNewFolder),
  });
};

onBeforeMount(() => {
  documentsStore.syncTree();
});
</script>

<style module lang="scss">
@use "@app/styles/scss/colors";

.tree {
  overflow-y: auto;
}

.iconFolder {
  color: colors.$pr-400;
  font-size: 16px;
  display: flex;
  align-items: center;
  margin-right: 4px;

  &.iconFolder_isSelected {
    color: colors.$pr-900;
  }
}
</style>
