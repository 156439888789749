<template>
  <FolderMenuDropdown :reference="reference" @close="$emit('close')">
    <FolderMenuWrapper
      :loading="isFormSubmitting"
      :submin-text="$t('shared.create')"
      @click:cancel="$emit('close')"
      @click:submit="submit"
    >
      <DrDynamicFormClassic
        ref="form"
        :entity="model"
        :schema="nameSchema"
        :errors="formErrors"
        :disabled="isFormSubmitting"
        @update="model[$event.field as keyof typeof model] = $event.value"
        @submit="submit"
      />
    </FolderMenuWrapper>
  </FolderMenuDropdown>
</template>

<script lang="ts" setup>
import { type ComponentInstance, computed, reactive, ref } from "vue";
import { useI18n } from "vue-i18n";

import { $notifySuccess } from "@app/vue/common";
import { FieldSchemaType } from "@app/vue/components/client-dashboard/dynamic-form/types";
import { DrDynamicFormClassic } from "@app/vue/shared/ui/dr-dynamic-form";
import { validateString } from "@app/vue/shared/ui/dr-dynamic-form/utils";
import { useFormHelper } from "@app/vue/shared/ui/dr-form";
import { DocumentsApiService } from "@app/vue/store/modules/room/documents/DocumentsApiService";
import { useDocumentsStore } from "@app/vue/store/pinia/room/documents/documents";
import FolderMenuDropdown from "./FolderMenuDropdown.vue";
import FolderMenuWrapper from "./FolderMenuWrapper.vue";

import type { FolderMenuParams } from "../utils";
import type { FormSchema } from "@app/vue/shared/ui/dr-dynamic-form/types";

interface Props {
  params: FolderMenuParams;
  reference: HTMLElement;
}

interface Emits {
  (e: "close"): void;
  (e: "update:persisted", value: boolean): void;
}

const props = defineProps<Props>();

const emit = defineEmits<Emits>();

const { t } = useI18n();

const documentsStore = useDocumentsStore();

const form = ref<ComponentInstance<typeof DrDynamicFormClassic> | undefined>();

const model = reactive({
  name: "",
});

const { formErrors, hookFormSubmitPromise, isFormSubmitting } =
  useFormHelper<typeof model>();

const nameSchema = computed(
  (): FormSchema => [
    {
      type: FieldSchemaType.Text,
      isReadOnly: isFormSubmitting.value,
      prop: "name",
      label: t("data_room.new_folder.name.label"),
      placeholder: t("data_room.new_folder.name.placeholder"),
      rules: validateString().required(t("form.may_not_be_blank")),
      extra: {
        autofocus: 200,
      },
    },
  ],
);

const api = new DocumentsApiService();

const submit = async () => {
  if (isFormSubmitting.value) return;

  const result = await form.value?.validate().catch((error) => error);

  if (result !== true) return;

  emit("update:persisted", true);

  hookFormSubmitPromise(
    api.createFolder(props.params.folders[0].id, model.name),
    t("data_room.new_folder.failed"),
  )
    .then(() => {
      documentsStore.syncTree();

      $notifySuccess(t("data_room.new_folder.success"));

      emit("close");
    })
    .finally(() => {
      emit("update:persisted", false);
    });
};
</script>
