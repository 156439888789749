<template>
  <pre v-if="!permissionsModel">{{ $t("upload_dialog.select_a_folder") }}</pre>
  <DrVxeGrid
    v-else
    :class="$style.checkboxJustifyStart"
    :data="tableData"
    :columns="tableColumns.columns"
    :height="294"
    :checkbox-config="{
      visibleMethod: ({ row }) => row.has_access_to_folder,
      showHeader: isSelectAllCheckboxVisible,
    }"
    @checkbox-change="onCheckboxChange"
    @checkbox-all="onCheckboxAll"
  >
    <template #empty>{{ $t("upload_dialog.no_available_contacts") }}</template>
  </DrVxeGrid>
</template>

<script setup lang="ts">
import { computed } from "vue";
import DrVxeGrid from "@shared/ui/dr-vxe-grid";

import { DrStore } from "@app/vue";
import TableColumns from "./tableColumns";
import { useUploadPermissionsBus } from "./usePermissionsBus";

import type { PermissionTableRow, RowPermissionsKey } from "../types";
import type { UploadPermissions } from "@app/vue/store/modules/room/documents/DocumentsApiService";
import type { VxeGridEvents } from "vxe-table";

const tableColumns = new TableColumns();

const permissionsModel = defineModel<UploadPermissions | null>("permissions");
const groupIdsToNotifyModel = defineModel<number[]>("groupIdsToNotify");

const PERMS_INDEX: Record<RowPermissionsKey, number> = {
  can_view: 0,
  can_download_watermaked: 1,
  can_download_original: 2,
  can_edit: 3,
};

const uploadPermissionBus = useUploadPermissionsBus();
uploadPermissionBus.on((_event, payload) => {
  if (!permissionsModel.value) return;
  if (!payload?.groupId) return;

  const perms: [boolean, boolean, boolean, boolean] = [
    ...permissionsModel.value.default_permissions[payload.groupId],
  ];

  const index = PERMS_INDEX[payload.permission];
  if (payload.value) {
    for (let i = 0; i <= index; i++) perms[i] = true;
  } else {
    for (let i = index; i < perms.length; i++) perms[i] = false;
  }

  // Update the model
  permissionsModel.value.default_permissions[payload.groupId] = perms;
});

const tableData = computed<PermissionTableRow[]>(() => {
  if (!permissionsModel.value) return [];

  const groupIds = Object.keys(permissionsModel.value.default_permissions).map(
    parseFloat,
  );

  const adminGroup = DrStore.state.room.groups.pgroupsList.find(
    (group) => group.is_administrator,
  );

  const rows: PermissionTableRow[] = [];
  if (adminGroup) {
    rows.push({
      group_id: adminGroup.id,
      group_name: adminGroup.name,
      has_access_to_folder: true,
      are_permissions_editable: false,
      permissions: {
        can_view: true,
        can_download_watermaked: true,
        can_download_original: true,
        can_edit: true,
      },
      notify: false,
    });
  }

  return groupIds.reduce<PermissionTableRow[]>((acc, groupId) => {
    if (!permissionsModel.value) return acc;

    const [can_view, can_download_watermaked, can_download_original, can_edit] =
      permissionsModel.value.default_permissions[groupId];

    const group = DrStore.state.room.groups.pgroups[groupId];

    acc.push({
      group_id: groupId,
      group_name: group.name,
      has_access_to_folder: permissionsModel.value.folder_viewable[groupId],
      are_permissions_editable: true,
      permissions: {
        can_download_original,
        can_download_watermaked,
        can_edit,
        can_view,
      },
      notify: false,
    });

    return acc;
  }, rows);
});

const isSelectAllCheckboxVisible = computed<boolean>(() => {
  if (!tableData.value || !tableData.value.length) return false;
  return tableData.value.some((row) => row.has_access_to_folder);
});

const onCheckboxChange: VxeGridEvents.CheckboxChange<PermissionTableRow> = ({
  checked,
  row,
}) => {
  if (!groupIdsToNotifyModel.value) return;

  if (checked) {
    const i = groupIdsToNotifyModel.value.indexOf(row.group_id);
    if (i === -1) groupIdsToNotifyModel.value.push(row.group_id);
  } else {
    const i = groupIdsToNotifyModel.value.indexOf(row.group_id);
    if (i !== -1) groupIdsToNotifyModel.value.splice(i, 1);
  }
};

const onCheckboxAll: VxeGridEvents.CheckboxAll<PermissionTableRow> = ({
  checked,
}) => {
  if (!groupIdsToNotifyModel.value) return;

  if (checked) {
    groupIdsToNotifyModel.value = tableData.value.reduce<number[]>(
      (acc, row) => {
        if (row.has_access_to_folder) acc.push(row.group_id);
        return acc;
      },
      [],
    );
  } else {
    groupIdsToNotifyModel.value = [];
  }
};
</script>

<style lang="scss" module>
.checkboxJustifyStart {
  :global {
    .vxe-cell--checkbox {
      justify-content: flex-start;
    }
  }
}
</style>
