<script lang="ts">
import { fromZonedTime } from "date-fns-tz";
import { ElDatePicker } from "element-plus";
import { timePickerDefaultProps } from "element-plus/es/components/time-picker/src/common/props";
import { cloneDeep } from "lodash-es";
import { defineComponent, h, ref } from "vue";

import { USER_DATA } from "@setups/data";
import { DateType, deserializeDrDate } from "@drVue/api-service/parse";
import { convertPtzToBtz } from "@drVue/common";

import type { IDatePickerType } from "element-plus/lib/components/date-picker/src/date-picker.type";
import type CommonPicker from "element-plus/lib/components/time-picker/src/common/picker.vue";
import type { PropType } from "vue";

export function convertBtzToPtz(val: number | string | Date): Date {
  // Timestamp case.
  if (typeof val === "number" && Number.isFinite(val)) {
    val = new Date(val);
  }

  // String case. Parse only if it is a valid string.
  if (typeof val === "string" && val !== "") {
    return deserializeDrDate(DateType.Date, val);
  }

  if (val instanceof Date) {
    return fromZonedTime(val, USER_DATA.profile.timezone);
  }

  throw new Error("Can not convert a value to BTZ date.");
}

const RANGE_TYPES = ["datetimerange", "daterange", "monthrange"];

export default defineComponent({
  name: "DrDatePicker",
  props: {
    ...timePickerDefaultProps,
    type: {
      type: String as PropType<IDatePickerType>,
      default: "date",
    },
  },
  emits: ["update:modelValue"],
  setup(props, { emit, expose }) {
    // We expose an object here and will replace its contents with actual
    // methods of ElDatePicker when it will be mounted and ref function will be
    // called.
    //
    // We definitely must find another approach to 'extend' 3-rd party
    // components.
    const pickerRef = ref<InstanceType<typeof CommonPicker> | null>(null);
    const exposed = {
      focus: () => {
        pickerRef.value?.focus();
      },
      handleOpen: () => {
        pickerRef.value?.handleOpen();
      },
      handleClose: () => {
        pickerRef.value?.handleClose();
      },
    };
    expose(exposed);

    return () => {
      let modelValue;

      if (props.modelValue) {
        if (Array.isArray(props.modelValue)) {
          modelValue = props.modelValue.map((d) => convertPtzToBtz(d));
        } else {
          modelValue = convertPtzToBtz(props.modelValue);
        }
      }

      // https://vuejs.org/guide/extras/render-function.html#v-model
      return h(ElDatePicker as any, {
        ...props,
        ref: (ref: any) => (pickerRef.value = ref),
        modelValue,
        "onUpdate:modelValue": (value: any) => {
          /**
           * Case: field was cleared.
           * @remark Weird <el-calendar> behavior has been detected:
           *         if the output type is "range", then when clearing the field,
           *         we get just 1 value - null,
           *         although we obviously expected an array with 2 values - [null, null].
           */
          if (value === null) {
            const isRangeValue = RANGE_TYPES.includes(props.type);
            emit("update:modelValue", isRangeValue ? [null, null] : null);
            return;
          }

          if (Array.isArray(value)) {
            let handledValue = cloneDeep(value);

            if (props.type === "daterange") {
              // set left border of range to start of day
              handledValue[0].setHours(0, 0, 0, 0);
              // set right border of range to end of day
              handledValue[1].setHours(23, 59, 59, 999);
            }

            handledValue = handledValue.map((d) => convertBtzToPtz(d));

            emit("update:modelValue", handledValue);
          } else {
            emit("update:modelValue", convertBtzToPtz(value));
          }
        },
      });
    };
  },
});
</script>
