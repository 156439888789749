
RoomDocumentsArchiveListController.$inject = [
  "$scope",
  "$uibModal",
  "$stateParams",
  "drMimetype",
  "RoomConfig",
  "AlertService",
  "DocumentsApiService",
  "DocumentsService",
  "TasksService",
  "CategoriesService",
];

export default function RoomDocumentsArchiveListController(
  $scope,
  $uibModal,
  $stateParams,
  drMimetype,
  RoomConfig,
  AlertService,
  DocumentsApiService,
  DocumentsService,
  TasksService,
  CategoriesService,
) {
  var self = this;
  self.Documents = DocumentsService;

  var docsCtrl = $scope.docsCtrl;
  docsCtrl.selectedFolderId = undefined;
  docsCtrl.recent = false;
  docsCtrl.bookmarks = false;
  docsCtrl.archive = $stateParams.archive ?? false;

  self.resetSearchQuery = resetSearchQuery;
  self.loading = true;
  self.error = false;
  self.dataService = undefined;
  self.searchQuery = undefined;
  self.allItems = undefined;

  self.Tasks = TasksService;
  self.Categories = CategoriesService;

  self.hasSelected = false;

  self.toggleItemSelected = function (item, $event) {
    self.dataService.toggleItemSelected(item, $event);
  };

  self.toggleSelectAllCheckbox = function () {
    self.dataService.update();
  };

  function resetSearchQuery() {
    self.searchQuery = undefined;
  }

  self.updateHasSelected = function (value) {
    self.hasSelected = value;
  };
}
