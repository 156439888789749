<template>
  <!-- @vue-expect-error -->
  <ParticipantsEdit :edit-props="editPropsWithUids">
    <template #reference="{ show }">
      <div
        ref="triggerRef"
        :class="$style.triggerRef"
        @click="handleTriggerClick(show)"
      >
        <div v-if="!editProps.value.length" :class="$style.action">
          <DrIcon size="sm" name="user" />
          <DrIcon size="xs" name="plus" />
        </div>
        <div v-else :class="$style.avatarStackWrapper">
          <DrAvatarStack :avatars="reviewerAvatars">
            <DrSvgIcon v-if="!!editProps.value.length" :name="iconName" />
          </DrAvatarStack>
        </div>
        <span v-if="reviewers.length === 1">{{ reviewerNames }}</span>
      </div>
    </template>
  </ParticipantsEdit>
</template>

<script setup lang="ts">
import { differenceBy } from "lodash-es";
import { computed, onMounted, ref } from "vue";
import { DrAvatarStack } from "@shared/ui/dr-avatar";
import DrIcon from "@shared/ui/dr-icon";
import { DrSvgIcon } from "@shared/ui/dr-svg-icon";

import { insightTrack, TasksTableTrackingEvent } from "@app/insight";
import { DrStore } from "@app/vue";
import { ParticipantsEdit } from "@drVue/components/room/tasks/shared/widgets/participants";
import { useTasksStore } from "@drVue/store/pinia/room/tasks";

import type { EditFieldProps } from "@drVue/components/client-dashboard/dynamic-form/Fields/types";
import type { ParticipantUserV1, Task } from "@drVue/store/pinia/room/tasks";

interface Props {
  editProps: EditFieldProps<
    ParticipantUserV1[],
    Task,
    { actionLabel: string; isReviewComplete: boolean }
  >;
}

const props = defineProps<Props>();

const taskStore = useTasksStore();

const reviewers = computed(() =>
  props.editProps.value.map(({ user_id }) => {
    const user = DrStore.state.room.members.members[user_id];
    return { ...user, user_id: user.uid };
  }),
);

const editPropsWithUids = computed(() => ({
  ...props.editProps,
  value: reviewers.value,
  veeField: {
    ...props.editProps.veeField,
    onChange: async (add: { user_id: string }[]) => {
      const remove = differenceBy(reviewers.value, add, "user_id");
      await taskStore.updateReviewers(props.editProps.entity.id, add, remove);
      insightTrack(TasksTableTrackingEvent.ReviewerSet);
    },
  },
}));

const reviewerAvatars = computed(() =>
  reviewers.value.map(({ name, avatar }) => ({
    name,
    url: avatar.thumbnail,
  })),
);

const reviewerNames = computed(() =>
  reviewers.value
    .map(({ name }) => {
      const [firstName, lastName] = name.split(" ");
      return `${firstName[0]}. ${lastName}`;
    })
    .join(", "),
);

const iconName = computed(() =>
  props.editProps.schema.extra?.isReviewComplete
    ? "square-check"
    : "triangle-exclamation",
);

const isMounted = ref(false);

const handleTriggerClick = (popupShowFn: () => void) => {
  if (isMounted.value) {
    props.editProps.quitEditMode();
  } else {
    popupShowFn();
  }
};

const triggerRef = ref<InstanceType<typeof HTMLDivElement> | null>(null);

onMounted(() => {
  triggerRef.value?.click();
  isMounted.value = true;
});
</script>

<style module lang="scss">
@use "@app/styles/scss/colors";

.triggerRef {
  display: flex;
  align-items: center;
  border-radius: 6px;
  border: 1px solid colors.$sc-400;
  height: 32px;
  padding: 2px 6px;
  user-select: none;
  width: 100%;
}

.action {
  color: colors.$sc-600;
}

.avatarStackWrapper {
  width: 30px;
  display: inherit;
  align-items: inherit;
}
</style>
