import "angular-tablesort";
import "./ng/date-fns";
import "./components/dr-table-sort/dr-table-sort.directive.js";

import { parseISO, sub } from "date-fns";

import { APP_SETTINGS, MANAGEMENT_DATA, USER_DATA } from "@setups/data";
import { OrganizationLookerAccess } from "@setups/enums";
import { OpenIDProviders } from "@drVue/api-service/client-dashboard";
import VueAuditLogTable from "@drVue/components/management/AuditLogTable.vue";
import VueAddAdminToOrgDialog from "@drVue/components/management/clients/AddAdminToOrgDialog.vue";
import VueAiUsageDialog from "@drVue/components/management/clients/AiUsageDialog.vue";
import VueBulkChangeOwnerDialog from "@drVue/components/management/clients/BulkChangeOwnerDialog.vue";
import VueDeleteClientDialog from "@drVue/components/management/clients/DeleteClientDialog.vue";
import VueMgmtClientsTable from "@drVue/components/management/ClientsTable";
import VueNewTrialClient from "@drVue/components/management/NewTrialClient.vue";
import VueOverUsageTable from "@drVue/components/management/OverUsageTable.vue";
import VueMgmtRoomsTable from "@drVue/components/management/RoomsTable";
import VueLazyMgmtRoomsTable from "@drVue/components/management/RoomsTable/LazyMgmtRoomsTable.vue";
import VueMfaRecoveryCodesDialog from "@drVue/components/management/users/MFARecovery/MfaRecoveryCodesDialog.vue";
import VueMgmtUsersTable from "@drVue/components/management/UsersTable";
import VueLazyUsersMgmtTable from "@drVue/components/management/UsersTable/LazyUsersMgmtTable.vue";
import { drUserTime } from "@drVue/filters/drUserTime";
import management_billingUsageTableHtml from "./templates/components/management/billing-usage-table.html?raw";
import management_roomsTableHtml from "./templates/components/management/rooms-table.html?raw";
import management_usersTableHtml from "./templates/components/management/users-table.html?raw";
import templates_confirmDialogHtml from "./templates/confirm-dialog.html?raw";
import management_auditHtml from "./templates/management/audit.html?raw";
import management_clientsDetailHtml from "./templates/management/clients.detail.html?raw";
import management_clientsHtml from "./templates/management/clients.html?raw";
import management_copyInviteLinkDialogHtml from "./templates/management/copy-invite-link-dialog.html?raw";
import management_invitesHtml from "./templates/management/invites.html?raw";
import management_overUsageHtml from "./templates/management/over-usage.html?raw";
import management_queuesHtml from "./templates/management/queues.html?raw";
import management_roomsDetailHtml from "./templates/management/rooms.detail.html?raw";
import management_roomsHtml from "./templates/management/rooms.html?raw";
import management_sendInviteModalHtml from "./templates/management/send-invite-modal.html?raw";
import management_usersDetailHtml from "./templates/management/users.detail.html?raw";
import management_usersHtml from "./templates/management/users.html?raw";
import SubscriptionInfo from "./vue/components/management/subscription-info";

(function () {
  "use strict";
  ClientDetailController.$inject = [
    "$scope",
    "$state",
    "$stateParams",
    "$http",
    "$uibModal",
    "$q",
    "URLS",
    "RaiseServerValidationErrors",
    "ServersInfoService",
    "AlertService",
    "UsersService",
    "ClientsService",
    "WEBSITE",
    "PaymentTypes",
    "OpenIDProvidersService",
  ];
  ClientsListController.$inject = [
    "$scope",
    "$filter",
    "ClientsService",
    "postAndGetFile",
    "URLS",
  ];
  QueuesController.$inject = [
    "$http",
    "$uibModal",
    "URLS",
    "AlertService",
    "ServersInfoService",
  ];
  InvitesListController.$inject = [
    "$scope",
    "$http",
    "$uibModal",
    "$filter",
    "URLS",
    "AlertService",
  ];
  UserDetailController.$inject = ["$stateParams", "UsersService"];
  UserListController.$inject = ["UsersService", "$stateParams"];
  RoomDetailController.$inject = [
    "$stateParams",
    "$http",
    "$state",
    "$uibModal",
    "URLS",
    "RoomsService",
    "UsersService",
    "WEBSITE",
    "ClientsService",
    "PaymentTypes",
    "AlertService",
    "RaiseServerValidationErrors",
  ];
  RoomListController.$inject = ["$rootScope", "$state", "RoomsService"];
  ClientsService.$inject = ["$http", "$q", "URLS", "AlertService"];
  ServersInfoService.$inject = ["$http", "$q", "URLS", "AlertService"];
  UsersService.$inject = ["$http", "$q", "URLS", "AlertService"];
  RoomsService.$inject = ["$http", "$q", "URLS", "AlertService"];
  initData.$inject = [
    "$rootScope",
    "$state",
    "NG_APP",
    "RoomConfig",
    "WEBSITE",
  ];
  routes.$inject = ["$stateProvider", "$urlRouterProvider", "NG_APP"];
  const trialPeriod = 14; // TODO: use from settings

  function getShowSendInvoiceBillingMethod() {
    return (
      APP_SETTINGS.WEBSITE.IS_DEALROOM ||
      ["admin", "ilgiz", "ftvkun", "kans", "julia"].some((e) =>
        USER_DATA.email.startsWith(e),
      )
    );
  }

  function formatTrialEndDate(trial_end_date) {
    if (trial_end_date) {
      return [
        trial_end_date.getFullYear(),
        trial_end_date.getMonth() + 1,
        trial_end_date.getDate(),
      ].join("-");
    }
    return trial_end_date;
  }

  angular
    .module("dealroom.management", [
      "ngVue",
      "dealroom.sentry",
      "ngMessages",
      "ui.bootstrap",
      "ui.router",
      "dealroom.date-fns",
      "tableSort",
      "angular.filter",
      "vs-repeat",

      "dealroom.table-sort.directive",
      "dealroom.common",
      "dealroom.config",
      "dealroom.room",
    ])
    .config(routes)
    .run(initData)
    .service("RoomsService", RoomsService)
    .service("UsersService", UsersService)
    .service("ServersInfoService", ServersInfoService)
    .service("ClientsService", ClientsService)
    .controller("RoomListController", RoomListController)
    .controller("RoomDetailController", RoomDetailController)
    .controller("UserListController", UserListController)
    .controller("UserDetailController", UserDetailController)
    .controller("InvitesListController", InvitesListController)
    .controller("QueuesController", QueuesController)
    .controller("ClientsListController", ClientsListController)
    .controller("ClientDetailController", ClientDetailController)
    .controller("DummyManagementController", DummyManagementController)
    .component("drRoomsTable", drRoomsTable())
    .component("drUsersTable", drUsersTable())
    .component("drClientUsageTable", drClientUsageTable())
    .value("VueMgmtSubscriptionInfo", SubscriptionInfo)
    .value("VueAuditLogTable", VueAuditLogTable)
    .value("VueMgmtOverUsageTable", VueOverUsageTable)
    .value("VueMfaRecoveryCodesDialog", VueMfaRecoveryCodesDialog)
    .value("VueNewTrialClient", VueNewTrialClient)
    .value("VueMgmtRoomsTable", VueMgmtRoomsTable)
    .value("VueLazyMgmtRoomsTable", VueLazyMgmtRoomsTable)
    .value("VueMgmtUsersTable", VueMgmtUsersTable)
    .value("VueLazyUsersMgmtTable", VueLazyUsersMgmtTable)
    .value("VueMgmtClientsTable", VueMgmtClientsTable)
    .value("VueBulkChangeOwnerDialog", VueBulkChangeOwnerDialog)
    .value("VueAddAdminToOrgDialog", VueAddAdminToOrgDialog)
    .value("VueDeleteClientDialog", VueDeleteClientDialog)
    .value("VueAiUsageDialog", VueAiUsageDialog);

  function routes($stateProvider, $urlRouterProvider, NG_APP) {
    if (NG_APP !== "dealroom.management") {
      return;
    }

    $urlRouterProvider.otherwise(function ($injector) {
      var $state = $injector.get("$state");
      if ($state.current.name === "") {
        // check current state empty, because some old non-angular ulrs may break routing
        $state.go(MANAGEMENT_DATA.is_superuser ? "clients.list" : "users.list");
      }
    });
    $stateProvider
      .state("users", {
        url: "/users",
        template: "<ui-view></ui-view>",
      })
      .state("users.list", {
        url: "/list",
        template: management_usersHtml,
        controller: "UserListController",
        controllerAs: "ctrl",
      })
      .state("users.detail", {
        url: "/{userId:int}",
        template: management_usersDetailHtml,
        controller: "UserDetailController",
        controllerAs: "ctrl",
      });

    if (!MANAGEMENT_DATA.is_superuser) {
      // remaining states are only available for superusers
      return;
    }

    $stateProvider
      .state("rooms", {
        url: "/rooms",
        template: "<ui-view></ui-view>",
        controller: function () {},
      })
      .state("rooms.list", {
        url: "/list",
        template: management_roomsHtml,
        controller: "RoomListController",
        controllerAs: "ctrl",
      })
      .state("rooms.detail", {
        url: "/{roomId:int}",
        template: management_roomsDetailHtml,
        controller: "RoomDetailController",
        controllerAs: "ctrl",
      })
      .state("invites", {
        url: "/invites",
        template: management_invitesHtml,
        controller: "InvitesListController",
        controllerAs: "ctrl",
      })
      .state("queues", {
        url: "/queues",
        template: management_queuesHtml,
        controller: "QueuesController",
        controllerAs: "ctrl",
      })
      .state("clients", {
        url: "/clients",
        template: "<ui-view></ui-view>",
      })
      .state("clients.list", {
        url: "/clients",
        template: management_clientsHtml,
        controller: "ClientsListController",
        controllerAs: "ctrl",
      })
      .state("clients.detail", {
        url: "/{clientId:int}",
        template: management_clientsDetailHtml,
        controller: "ClientDetailController",
        controllerAs: "ctrl",
      })
      .state("clients.create", {
        url: "/create",
        params: { create: true },
        template: management_clientsDetailHtml,
        controller: "ClientDetailController",
        controllerAs: "ctrl",
      })
      .state("audit", {
        url: "/audit",
        template: management_auditHtml,
        controller: "DummyManagementController",
        controllerAs: "$ctrl",
      })
      .state("over-usage", {
        url: "/over-usage",
        template: management_overUsageHtml,
        controller: "DummyManagementController",
        controllerAs: "$ctrl",
      });
  } // End routes

  function ServersInfoService($http, $q, URLS, AlertService) {
    var servers;

    function filterServers(type) {
      return angular
        .copy(servers)
        .filter((server) => !type || server.type === type);
    }

    function loadServers(type) {
      if (servers) {
        return $q.when(filterServers(type));
      }
      var url = URLS["root-api:management:servers-info"]();
      return $http.get(url).then(
        function successCallback(response) {
          servers = response.data;
          return filterServers(type);
        },
        function errorCallback(error) {
          AlertService.danger("Failed to load servers information");
        },
      );
    }

    return {
      get: loadServers,
    };
  }

  function initData($rootScope, $state, NG_APP, RoomConfig, WEBSITE) {
    if (NG_APP === "dealroom.management") {
      RoomConfig.title = WEBSITE.NAME + " Management"; //require for recent rooms dropdown
      $rootScope.hideHeader = true;
      $rootScope.$state = $state;
      $rootScope.isManagement = true; // need for room creation
      $rootScope.MANAGEMENT_DATA = MANAGEMENT_DATA;
    }
  } // End initData

  function drRoomsTable() {
    return {
      template: management_roomsTableHtml,
      bindings: {
        rooms: "<",
        lazy: "<",
        extraColumns: "<?",
        tableHeight: "<?",
      },
      controller: function () {
        var self = this;
        self.filters = {
          activeStatus: "active",
          paymentType: undefined,
        };
        self.hasActiveFilters = hasActiveFilters;
        self.clearFilterSelection = clearFilterSelection;

        function hasActiveFilters(filterName) {
          for (filterName in self.filters) {
            if (
              Object.prototype.hasOwnProperty.call(self.filters, filterName) &&
              self.filters[filterName]
            ) {
              return true;
            }
          }
          return false;
        }

        function clearFilterSelection(filterName) {
          if (filterName) {
            self.filters[filterName] = undefined;
          } else {
            self.filters = {};
          }
        }
      },
    };
  }

  function drUsersTable() {
    return {
      template: management_usersTableHtml,
      bindings: {
        users: "=",
        extraColumns: "=?",
      },
      controller: function () {
        var self = this;
        self.filters = {
          searchText: undefined,
        };
      },
    };
  }

  /* end drUsersTable */

  function RoomsService($http, $q, URLS, AlertService) {
    var service = {
      getRoomMembers: getRoomMembers,
      detail: getRoomDetail,
      update: updateRoom,
      delete: deleteRoom,
      restore: restoreRoom,
      storage: getStorage,
    };
    return service;

    function getRoomDetail(roomId) {
      var url = URLS["root-api:management:rooms-detail"](roomId);
      return $http.get(url).then(
        function successCallback(response) {
          var room = response.data;
          if (room.trial_end_date) {
            room.trial_end_date = new Date(room.trial_end_date);
          }
          return { data: room };
        },
        function errorCallback() {
          AlertService.danger("Failed to retrieve room data from the server.");
          return $q.reject();
        },
      );
    } // End getRoomDetail

    function getRoomMembers(roomId) {
      const url = URLS["root-api:management:rooms-detail-members"](roomId);

      return $http.get(url).then(
        function successCallback(response) {
          return response.data;
        },
        function errorCallback(err) {
          AlertService.danger(
            "Failed to retrieve members data from the server.",
          );

          return $q.reject(err);
        },
      );
    }

    function updateRoom(room) {
      var url = URLS["root-api:management:rooms-detail"](room.id);
      return $http.post(url, room).then(
        function successCallback(response) {
          AlertService.success("Room details have been updated.");
          return response;
        },
        function errorCallback(errorResp) {
          AlertService.danger("Failed to update room data.");
          return $q.reject(errorResp);
        },
      );
    } // End updateRoom

    function deleteRoom(room) {
      const url = URLS["root-api:management:rooms-detail"](room.id);
      return $http.delete(url, room).then(
        function successCallback(response) {
          AlertService.success("Room has been deleted.");
          return response;
        },
        function errorCallback() {
          AlertService.danger("Failed to delete room.");
          return $q.reject();
        },
      );
    }

    function restoreRoom(room) {
      const url = URLS["root-api:management:rooms-detail-restore"](room.id);
      return $http.post(url, room).then(
        function successCallback(response) {
          AlertService.success("Room has been restored.");
          return response;
        },
        function errorCallback() {
          AlertService.danger("Failed to restore room.");
          return $q.reject();
        },
      );
    }

    function getStorage(roomId) {
      var url = URLS["root-api:management:rooms-storage"](roomId);
      return $http.get(url).then(
        function successCallback(response) {
          return response.data;
        },
        function errorCallback() {
          AlertService.danger(
            "Failed to retrieve room storage from the server.",
          );

          return $q.reject();
        },
      );
    }
  } // End RoomsService

  function UsersService($http, $q, URLS, AlertService) {
    var service = {
      getUserRooms: getUserRooms,
      detail: getUserDetail,
      update: updateUser,
      resetPassword: resetPassword,
      resetLoginAttempts: resetLoginAttempts,
      toggleUserActive: toggleUserActive,
    };

    return service;

    function getUserDetail(userId) {
      var url = URLS["root-api:management:users-detail"](userId);

      return $http.get(url).then(
        function successCallback(response) {
          var user = response.data;
          return { data: user };
        },
        function errorCallback() {
          AlertService.danger("Failed to retrieve user data from the server.");
          return $q.reject();
        },
      );
    } // End getUserDetail

    function getUserRooms(userId) {
      var url = URLS["root-api:management:users-detail-rooms"](userId);

      return $http.get(url).then(
        function successCallback(response) {
          return response.data;
        },
        function errorCallback() {
          AlertService.danger(
            "Failed to retrieve user rooms data from the server.",
          );

          return $q.reject();
        },
      );
    }

    function updateUser(user) {
      var url = URLS["root-api:management:users-detail-update"](user.id);
      return $http.post(url, user).then(
        function successCallback(response) {
          AlertService.success("User details have been updated.");
          return response;
        },
        function errorCallback() {
          AlertService.danger("Failed to update user " + user.email + ".");
          return $q.reject();
        },
      );
    } // End updateUser

    function resetPassword(user) {
      var url = URLS["api:auth:password_reset"]();
      var msg = ' for user "' + user.profile.name + '"';
      return $http.post(url, { email: user.email }).then(
        function successCallback(response) {
          AlertService.success("Password" + msg + " has been reset.");
        },
        function errorCallback(error) {
          AlertService.danger("Failed to reset password" + msg + ".");
        },
      );
    } // End resetPassword

    function resetLoginAttempts(user) {
      var url = URLS["root-api:management:reset-login-attempts"](user.id);
      return $http.post(url).then(
        function successCallback() {
          user.login_attempts = undefined;
          AlertService.success("Attempts have been reset.");
        },
        function errorCallback() {
          AlertService.danger("Failed to reset attempts.");
        },
      );
    } // End resetLoginAttempts

    function toggleUserActive(user) {
      user.is_active = !user.is_active;
      return service.update(user);
    }
  } // End UsersService

  function RoomListController($rootScope, $state, RoomsService) {
    this.Rooms = RoomsService;
  }

  function RoomDetailController(
    $stateParams,
    $http,
    $state,
    $uibModal,
    URLS,
    RoomsService,
    UsersService,
    WEBSITE,
    ClientsService,
    PaymentTypes,
    AlertService,
    RaiseServerValidationErrors,
  ) {
    var self = this;
    var roomId = $stateParams.roomId;
    self.showRoomEdit = false;
    self.Rooms = RoomsService;
    self.Users = UsersService;
    self.Clients = ClientsService;
    self.save = save;
    self.deletePermanently = deletePermanently;
    self.toggleTrial = toggleTrial;
    self.toggleArchive = toggleArchive;
    self.toggleApplyReportsOnDocumentUpload =
      toggleApplyReportsOnDocumentUpload;
    self.toggleDomainMode = toggleDomainMode;
    self.toggleAlpha = toggleAlpha;
    self.calculateStorage = calculateStorage;
    self.hasActiveStripeSubscription = hasActiveStripeSubscription;
    self.isLockedOnTrialEnd = isLockedOnTrialEnd;
    self.PaymentTypes = PaymentTypes;
    self.showSendInvoiceBillingMethod = getShowSendInvoiceBillingMethod();

    self.members = undefined;
    self.loadMembersData = loadMembersData;
    self.isMembersDataLoading = false;

    self.canRoomBeRestored = canRoomBeRestored;
    self.restore = restore;

    function loadRoomDetail() {
      RoomsService.detail(roomId).then(function successCallback(response) {
        self.room = response.data;
      });
    }

    self.$onInit = function () {
      loadRoomDetail();
    };

    function loadMembersData() {
      if (self.isMembersDataLoading) {
        return;
      }
      self.isMembersDataLoading = true;
      RoomsService.getRoomMembers(roomId)
        .then((members) => {
          self.members = members;
        })
        .finally(() => {
          self.isMembersDataLoading = false;
        });
    }

    var defaultTrialEnd = new Date();
    defaultTrialEnd.setDate(defaultTrialEnd.getDate() + trialPeriod);

    function isLockedOnTrialEnd() {
      if (self.room && self.room.payment_type) {
        return ["stripe_client", "stripe_room"].includes(
          self.room.payment_type,
        );
      }
    }

    function save() {
      if (self.form.$invalid) return;
      var trial_end_date = self.room.trial_end_date;
      self.room.trial_end_date = formatTrialEndDate(trial_end_date);

      RoomsService.update(self.room)
        .then((resp) => {
          $state.go("rooms.detail", { roomId: resp.data.id }, { reload: true });
        })
        .catch((resp) => {
          RaiseServerValidationErrors(resp.data, self.form);
        })
        .finally(function () {
          self.form.$setUntouched();
          self.form.$setPristine();
          if (trial_end_date) {
            self.room.trial_end_date = trial_end_date;
          }
        });
    }

    function deletePermanently() {
      $uibModal.open({
        template: templates_confirmDialogHtml,
        controller: [
          "$scope",
          "$uibModalInstance",
          function ($scope, $uibModalInstance) {
            this.modalOptions = {
              headerText: "Delete room permanently",
              actionButtonText: "Delete",
              bodyText: `Are you sure you want to permanently delete room '${self.room.title}'?`,
            };

            this.submit = function () {
              RoomsService.delete(self.room)
                .then(() => {
                  location.reload();
                })
                .finally($uibModalInstance.dismiss);
            };
          },
        ],
        controllerAs: "ctrl",
      });
    }

    function canRoomBeRestored() {
      const daysToKeepDeletedRoom = 14;
      return (
        parseISO(self.room.date_removed) >
        sub(new Date(), { days: daysToKeepDeletedRoom })
      );
    }

    function restore() {
      $uibModal.open({
        template: templates_confirmDialogHtml,
        controller: [
          "$scope",
          "$uibModalInstance",
          function ($scope, $uibModalInstance) {
            this.modalOptions = {
              headerText: "Restore room deleted permanently",
              actionButtonText: "Restore",
              bodyText: `Are you sure you want to restore permanently deleted room '${self.room.title}'?`,
            };

            this.submit = function () {
              RoomsService.restore(self.room)
                .then(() => {
                  location.reload();
                })
                .finally($uibModalInstance.dismiss);
            };
          },
        ],
        controllerAs: "ctrl",
      });
    }

    function toggleTrial() {
      self.room.trial_end_date = self.room.trial_end_date
        ? null
        : defaultTrialEnd;
      self.form.$setDirty();
    }

    function toggleArchive() {
      self.room.is_archived = !self.room.is_archived;
      self.form.$setDirty();
    }

    function toggleApplyReportsOnDocumentUpload() {
      self.room.apply_reports_on_document_upload =
        !self.room.apply_reports_on_document_upload;
      self.form.$setDirty();
    }

    let prevDomain = "";
    function toggleDomainMode() {
      if (self.room.domain) {
        prevDomain = self.room.domain;
        self.room.domain = null;
      } else {
        self.room.domain = prevDomain || self.room.inactive_domains[0] || "";
      }
      self.form.$setDirty();
    }

    function toggleAlpha() {
      self.room.is_alpha = !self.room.is_alpha;
      self.form.$setDirty();
    }

    function calculateStorage() {
      self.storageCalculating = true;
      RoomsService.storage(self.room.id)
        .then((storage) => (self.room.storage = storage))
        .finally(() => (self.storageCalculating = false));
    }

    function hasActiveStripeSubscription(room) {
      return (
        room &&
        room.billing_info &&
        room.billing_info.subscription &&
        room.billing_info.subscription.status &&
        room.billing_info.subscription.status !== "canceled"
      );
    }

    self.newSubTrialHours = 0;
    self.newSubCoupon = "";
    self.activateStripeSubscription = function (
      subTitle,
      trialHours,
      coupon_id,
      invoiceBilling,
    ) {
      if (self.activatingSubscription) {
        return;
      }
      self.activatingSubscription = true;
      const url = URLS[
        "root-api:management:rooms-activate-stripe-subscription"
      ](self.room.id);
      return $http
        .post(url, {
          sub_title: subTitle,
          trial_hours: trialHours,
          collection_method: invoiceBilling,
          coupon_id: coupon_id,
        })
        .then(
          function (resp) {
            window.location.reload();
          },
          function (errorResp) {
            const msg = errorResp.data.detail || errorResp.data;
            AlertService.danger(
              "Failed to activate stripe subscription: " + msg,
              5000,
            );
          },
        );
    };
    self.enableAndInitializeSynergies = function () {
      const confirmed = confirm(
        "Are you sure you want to enable and initialize predefined synergies?",
      );
      if (!confirmed) {
        return;
      }

      const url = URLS["root-api:management:rooms-detail-init-synergies"](
        self.room.id,
      );
      return $http.post(url).then(
        function (resp) {
          window.location.reload();
        },
        function (errorResp) {
          const msg = errorResp.data.detail || errorResp.data;
          AlertService.danger(
            "Failed to enable and initialize synergies: " + msg,
            5000,
          );
        },
      );
    };
  } // End RoomDetailController

  function UserListController(UsersService, $stateParams) {}

  function UserDetailController($stateParams, UsersService) {
    var ctrl = this;
    var userId = $stateParams.userId;
    ctrl.Users = UsersService;
    ctrl.rooms = undefined;
    ctrl.save = save;
    ctrl.loadRoomsData = loadRoomsData;
    ctrl.isRoomsDataLoading = false;

    function loadUserDetail() {
      UsersService.detail(userId).then(function successCallback(response) {
        ctrl.user = response.data;
      });
    }

    ctrl.$onInit = function () {
      loadUserDetail();
    };

    function loadRoomsData() {
      if (ctrl.isRoomsDataLoading) {
        return;
      }
      ctrl.isRoomsDataLoading = true;
      UsersService.getUserRooms(userId)
        .then((rooms) => {
          rooms.owned_active_count = rooms.owned.filter(function (room) {
            return !room.is_archived;
          }).length;
          rooms.member_active_count = rooms.member.filter(function (room) {
            return !room.is_archived;
          }).length;
          ctrl.rooms = rooms;
        })
        .finally(() => {
          ctrl.isRoomsDataLoading = false;
        });
    }

    function save() {
      if (ctrl.form.$invalid) return;

      UsersService.update(ctrl.user).finally(function () {
        ctrl.form.$setUntouched();
        ctrl.form.$setPristine();
      });
    }
  } // End UserDetailController

  function InvitesListController(
    $scope,
    $http,
    $uibModal,
    $filter,
    URLS,
    AlertService,
  ) {
    var self = this;
    self.InviteManagementService = {
      loading: true,
      error: false,
      resend: function (invite) {
        var url = URLS["root-api:management:invites-resend"](invite.id);
        return $http.post(url).then(
          function successCallback(response) {
            AlertService.success(
              "Invite to " + invite.email + " has been resent.",
            );

            invite.id = response.data.id;
            invite.date = new Date().toISOString();
          },
          function errorCallback(error) {
            AlertService.danger(
              "Failed to resend invite to " + invite.email + ".",
            );
          },
        );
      },
    };

    function loadInvites() {
      var url = URLS["root-api:management:invites-list"]();
      self.InviteManagementService.loading = true;
      $http
        .get(url)
        .then(
          function successCallback(response) {
            self.InviteManagementService.invites = response.data;
            self.filterInvites();
          },
          function errorCallback(error) {
            self.InviteManagementService.error = true;
            AlertService.danger(
              "Failed to retrieve invites list from the server.",
            );
          },
        )
        .finally(function () {
          self.InviteManagementService.loading = false;
        });
    }
    loadInvites();

    self.sendSelfSignUpInvite = function () {
      $uibModal.open({
        template: management_sendInviteModalHtml,
        controllerAs: "ctrl",
        controller: [
          "$uibModalInstance",
          function ($uibModalInstance) {
            const self = this;
            self.email = "";
            self.submit = function () {
              var url = URLS["root-api:management:invites-list"]();
              $http.post(url, { email: self.email }).then(
                function successCallback(response) {
                  AlertService.success(`Invited ${self.email}`);
                  loadInvites();
                  $uibModalInstance.dismiss();
                },
                function errorCallback(error) {
                  self.createNewForm.$setPristine();
                  AlertService.danger("Failed to send invite");
                },
              );
            };
          },
        ],
      });
    };

    self.order = {
      by: "email",
      reversed: false,
    };
    self.filters = {
      showNotActive: false,
      searchText: undefined,
    };
    $scope.$watch(
      "ctrl.filters",
      function () {
        self.filterInvites();
      },
      true,
    );

    self.setOrder = function (order) {
      self.order = order;
    };

    self.filterInvites = function () {
      var invites = self.InviteManagementService.invites;
      if (!invites) return;
      if (!self.filters.showNotActive) {
        invites = invites.filter(function (invite) {
          return invite.is_active_pgroup;
        });
      }
      if (self.filters.showOnlySelfSignUp) {
        invites = invites.filter(function (invite) {
          return !invite.group;
        });
      }
      if (self.filters.searchText) {
        invites = $filter("filter")(invites, self.filters.searchText);
      }
      self.filteredInvites = invites;
    };

    self.openLinkModal = function (invite) {
      var modal = $uibModal.open({
        template: management_copyInviteLinkDialogHtml,
        controllerAs: "$ctrl",
        controller: [
          "$scope",
          "$http",
          "URLS",
          "AlertService",
          function ($scope, $http, URLS, AlertService) {
            const $ctrl = this;
            $ctrl.invite = invite;
            $ctrl.isLoading = false;
            $ctrl.link = undefined;
            $ctrl.$onInit = function () {
              loadLink();
            };

            function loadLink() {
              $ctrl.isLoading = true;
              const url = URLS["root-api:management:invites-detail-link"](
                invite.id,
              );

              $http
                .get(url)
                .then(
                  (resp) => {
                    $ctrl.link = resp.data.register_url;
                  },
                  () => {
                    AlertService.danger("Failed to load invite link");
                  },
                )
                .finally(() => {
                  $ctrl.isLoading = false;
                });
            }
          },
        ],
      });
    };
  } // End InvitesListController

  function QueuesController(
    $http,
    $uibModal,
    URLS,
    AlertService,
    ServersInfoService,
  ) {
    var self = this;
    self.purgeQueue = purgeQueue;
    self.flowerTaskLink = flowerTaskLink;
    self.updateInfo = updateInfo;

    self.servers = [];
    self.selectedServer = "";
    self.flowerUrl = flowerUrl;
    ServersInfoService.get().then(function (servers) {
      self.servers = servers;
    });

    function flowerUrl(server) {
      return "//" + location.host + "/management/flower/" + server + "/";
    }

    function injectServerToUrl(url) {
      var prefix = "/api/management/queues/";
      return url.replace(prefix, prefix + self.selectedServer + "/");
    }

    function updateInfo() {
      var server = self.selectedServer;
      self.loading = true;
      self.failed = false;
      var url = injectServerToUrl(URLS["root-api:management:queues-list"]());
      $http.get(url).then(
        function successCallback(response) {
          if (server !== self.selectedServer) return;
          self.info = response.data;
          self.loading = false;
          self.failed = false;
        },
        function errorCallback(error) {
          if (server !== self.selectedServer) return;
          self.loading = false;
          self.failed = true;
          AlertService.danger("Failed to load tasks queues information.");
        },
      );
    }

    function purgeQueue(queue) {
      $uibModal.open({
        template: templates_confirmDialogHtml,
        controller: [
          "$scope",
          "$uibModalInstance",
          function ($scope, $uibModalInstance) {
            this.modalOptions = {
              headerText: "Purge",
              actionButtonText: "Purge",
              bodyText:
                "Are you sure you want to purge PENDING tasks from queue " +
                queue +
                " ?",
            };

            this.submit = function () {
              var url = injectServerToUrl(
                URLS["root-api:management:purge-queue"](queue),
              );

              $http
                .post(url)
                .then(
                  function successCallback(response) {
                    AlertService.success(
                      "Removed " + response.data.size + " tasks",
                    );

                    updateInfo();
                  },
                  function errorCallback(error) {
                    AlertService.danger(
                      "Failed to load tasks queues information.",
                    );
                  },
                )
                .finally($uibModalInstance.dismiss);
            };
          },
        ],
        controllerAs: "ctrl",
      });
    }

    function flowerTaskLink(task) {
      return flowerUrl(self.selectedServer) + "task/" + task.id;
    }
  }

  function ClientsListController(
    $scope,
    $filter,
    ClientsService,
    postAndGetFile,
    URLS,
  ) {
    var self = this;
    self.ClientsService = ClientsService;
    ClientsService.load();

    self.filteredClients = [];
    self.order = {
      by: "name",
      reversed: false,
    };
    self.setOrder = function (order) {
      self.order = order;
    };
    self.filters = {
      searchText: undefined,
      showHidden: false,
    };

    $scope.$watch(
      "ctrl.filters",
      function () {
        self.filterClients();
      },
      true,
    );

    $scope.$watch("ctrl.ClientsService.clientsList", function () {
      self.filterClients();
    });

    self.filterClients = function () {
      var clients = self.ClientsService.clientsList;
      if (!clients) return;
      clients = clients.filter(function (client) {
        return self.filters.showArchived || !client.is_archived;
      });
      const searchText = self.filters.searchText?.toLowerCase();
      if (searchText) {
        clients = clients.filter((client) => {
          return (
            client.private_name.toLowerCase().includes(searchText) ||
            client.public_name.toLowerCase().includes(searchText)
          );
        });
      }
      self.filteredClients = clients;
    };
  }
  function ClientsService($http, $q, URLS, AlertService) {
    var service = {
      clients: undefined,
      clientsList: undefined,
      loading: false,
      error: false,
      load: loadClients,
      checkLoaded: checkLoaded,
      loadDetails: getClientDetail,
      getClientMembers: getClientMembers,
      updateClientMember: updateClientMember,
    };
    return service;

    function checkLoaded() {
      if (service.clients) return;
      service.load();
    }

    function getClientDetail(clientId) {
      const url = URLS["root-api:management:clients-detail"](clientId);

      return $http.get(url).catch((errorResp) => {
        AlertService.danger("Failed to retrieve client data from the server.");
        return $q.reject(errorResp);
      });
    }

    function getClientMembers(clientId) {
      const url =
        URLS["root-api:management:clients-detail-members-list"](clientId);

      return $http.get(url).then(
        function successCallback(response) {
          return response.data;
        },
        function errorCallback(err) {
          AlertService.danger(
            "Failed to retrieve members data from the server.",
          );

          return $q.reject(err);
        },
      );
    }

    function updateClientMember(clientId, orgMemberId, payload) {
      const url = URLS["root-api:management:clients-detail-members-detail"](
        clientId,
        orgMemberId,
      );

      return $http.put(url, payload).then(
        function successCallback(response) {
          return response.data;
        },
        function errorCallback(err) {
          AlertService.danger("Failed to update client member detail.");
          return $q.reject(err);
        },
      );
    }

    function loadClients() {
      const url = URLS["root-api:management:clients-list"]();
      $http
        .get(url)
        .then(
          function successCallback(response) {
            service.clientsList = response.data;
            service.clients = service.clientsList.reduce((res, cl) => {
              res[cl.id] = cl;
              return res;
            }, {});
            service.error = false;
          },
          function errorCallback(error) {
            service.error = false;
            AlertService.danger(
              "Failed to retrieve clients list from the server.",
            );

            $q.reject(error);
          },
        )
        .finally(function () {
          service.loading = false;
        });
    }
  }

  function ClientDetailController(
    $scope,
    $state,
    $stateParams,
    $http,
    $uibModal,
    $q,
    URLS,
    RaiseServerValidationErrors,
    ServersInfoService,
    AlertService,
    UsersService,
    ClientsService,
    WEBSITE,
    PaymentTypes,
    OpenIDProvidersService,
  ) {
    var self = this;
    self.loading = false;
    self.error = false;
    self.clientData = undefined;
    self.client = undefined;
    self.editForm = undefined;
    self.submitEditForm = undefined;
    self.clientID = !$stateParams.create && $stateParams.clientId;
    self.PaymentTypes = PaymentTypes;
    self.OrganizationLookerAccess = OrganizationLookerAccess;
    self.loadClient = loadClient;
    self.loadMembersData = loadMembersData;
    self.clientPaymentTypes = ["stripe_client", "pipeline_only"];
    self.showSendInvoiceBillingMethod = getShowSendInvoiceBillingMethod();
    self.isSharedStorageDeprecated = false;

    ServersInfoService.get("storage").then(function (servers) {
      self.storage_servers = servers;
      self.isSharedStorageDeprecated =
        servers.some((server) => server.storage === "shared");
    });

    if ($stateParams.create) {
      self.clientData = { members: [], enabled_openid_providers: [] };
      self.submitEditForm = createClient;
    } else {
      loadClient();
      self.submitEditForm = updateClient;
    }

    function submitClientData(clientID) {
      const url = clientID
        ? URLS["root-api:management:clients-detail"](self.clientID)
        : URLS["root-api:management:clients-list"]();
      if (self.editForm.$valid) {
        self.editForm.$setSubmitted();
        const trial_end_date = self.clientData.trial_end_date;
        self.clientData.trial_end_date = formatTrialEndDate(trial_end_date);

        return $http.post(url, self.clientData).then(
          function (resp) {
            $state.go(
              "clients.detail",
              { clientId: resp.data.id },
              { reload: true },
            );
          },
          function (errorResp) {
            self.editForm.$setPristine();
            self.clientData.trial_end_date = trial_end_date;
            RaiseServerValidationErrors(errorResp.data, self.editForm);
          },
        );
      }
    }

    function createClient() {
      submitClientData();
    }

    function updateClient() {
      submitClientData(self.clientID);
    }

    function loadClient() {
      ClientsService.loadDetails(self.clientID)
        .then(
          function successCallback(response) {
            self.client = response.data;
            self.clientData = angular.copy(self.client);
            if (self.clientData.trial_end_date) {
              self.clientData.trial_end_date = new Date(
                self.clientData.trial_end_date,
              );
            }
            self.error = false;
            updateOpenIdProviders();
          },
          function errorCallback(error) {
            self.error = true;
          },
        )
        .finally(function () {
          self.loading = false;
        });
    }

    self.isMembersDataLoading = false;
    function loadMembersData() {
      if (self.isMembersDataLoading) {
        return;
      }
      self.isMembersDataLoading = true;
      ClientsService.getClientMembers(self.clientID)
        .then((members) => {
          self.client.members = members;
          self.clientData.members = angular.copy(members);
        })
        .finally(() => {
          self.isMembersDataLoading = false;
        });
    }

    self.toggleUserHasBillingAccess = function (orgMember) {
      const toggled_has_billing_access = !orgMember.has_billing_access;
      ClientsService.updateClientMember(self.clientID, orgMember.id, {
        has_billing_access: toggled_has_billing_access,
      }).then(() => {
        orgMember.has_billing_access = toggled_has_billing_access;
      });
    };

    self.creatingCustomer = false;
    self.createStripeCustomer = function (email) {
      if (self.creatingCustomer) {
        return;
      }
      self.creatingCustomer = true;
      const url = URLS["root-api:management:client-stripe-customer"](
        self.clientID,
      );

      return $http.post(url, { email: email }).then(
        function (resp) {
          window.location.reload();
        },
        function (errorResp) {
          const msg = errorResp.data.detail || errorResp.data;
          AlertService.danger("Failed to create stripe customer: " + msg, 5000);
        },
      );
    };

    self.activatingSubscription = false;
    $scope.newSubTrialDays = 0;
    self.activateStripeSubscription = function (
      subTitle,
      trialHours,
      coupon_id,
      invoiceBilling,
    ) {
      if (self.activatingSubscription) {
        return;
      }
      self.activatingSubscription = true;
      const url = URLS[
        "root-api:management:client-activate-stripe-subscription"
      ](self.clientID);
      return $http
        .post(url, {
          sub_title: subTitle,
          trial_hours: trialHours,
          collection_method: invoiceBilling,
          coupon_id: coupon_id,
        })
        .then(
          function (resp) {
            window.location.reload();
          },
          function (errorResp) {
            const msg = errorResp.data.detail || errorResp.data;
            AlertService.danger(
              "Failed to activate stripe subscription: " + msg,
              5000,
            );
          },
        );
    };

    self.openIdProviders = OpenIDProvidersService.providers
      // TODO(ftvkun): rename OpenIDProvidersService to SSOProvidersService
      //               rename client.enabled_openid_providers to client.enabled_sso_providers
      // disable obsolete openid providers; allow only SAML integration in mgmt panel
      .filter((p) => p.id === OpenIDProviders.Saml)
      .map((p) => ({
        ...p,
        checked: false,
        enabled: false,
      }));

    function updateOpenIdProviders() {
      self.openIdProviders.forEach((p) => {
        p.enabled = !!self.client.enabled_openid_providers.find(
          (pId) => p.id === pId,
        );

        p.checked = !!self.clientData.enabled_openid_providers.find(
          (pId) => p.id === pId,
        );
      });
    }

    self.toggleOpenIdProvider = function (provider) {
      const pos = self.clientData.enabled_openid_providers.findIndex(
        (p) => p === provider.id,
      );

      if (pos !== -1) {
        self.clientData.enabled_openid_providers.splice(pos, 1);
      } else {
        self.clientData.enabled_openid_providers.push(provider.id);
      }
    };

    self.enabledOpenIdProviders = function () {
      return self.openIdProviders.filter((p) => p.enabled);
    };

    self.toggleTrial = function () {
      const defaultTrialEnd = new Date();
      defaultTrialEnd.setDate(defaultTrialEnd.getDate() + trialPeriod);

      self.clientData.trial_end_date = self.clientData.trial_end_date
        ? null
        : defaultTrialEnd;
      self.editForm.$setDirty();
    };

    // temp looker stuff
    self.isSyncingLookerModel = false;
    self.syncLookerModel = function () {
      if (self.isSyncingLookerModel) {
        return;
      }
      self.isSyncingLookerModel = true;
      const url = URLS["root-api:management:client-looker-update-model"](
        self.clientID,
      );

      return $http
        .post(url, {})
        .then(
          () => {
            AlertService.success("Client looker model is updated");
          },
          (errorResp) => {
            AlertService.danger("Failed to update client looker model.");
            return $q.reject(errorResp);
          },
        )
        .finally(() => {
          self.isSyncingLookerModel = false;
        });
    };
  }

  function drClientUsageTable() {
    return {
      template: management_billingUsageTableHtml,
      bindings: {
        clientId: "<",
      },
      controller: [
        "$scope",
        "$http",
        "$timeout",
        "URLS",
        function ($scope, $http, $timeout, URLS) {
          const $ctrl = this;
          $ctrl.isLoading = false;
          $ctrl.isLoadingError = false;
          $ctrl.usage = undefined;
          $ctrl.order = {
            by: "date",
            reversed: true,
          };

          $ctrl.initialDateRangeValue = [
            sub(new Date(), { days: 7 }),
            new Date(),
          ];

          $ctrl.onDateRangeChanged = function (range) {
            loadUsage(range[0], range[1]);
          };

          $ctrl.setOrder = function (order) {
            $ctrl.order = order;
          };

          $ctrl.queryParamsLoading = undefined;
          $ctrl.$onInit = function () {
            $ctrl.onDateRangeChanged($ctrl.initialDateRangeValue);
          };

          function loadUsage(start, end) {
            const queryParams = {
              from_date: start ? drUserTime(start, "yyyy-MM-dd") : null,
              to_date: end ? drUserTime(end, "yyyy-MM-dd") : undefined,
            };
            $ctrl.queryParamsLoading = queryParams;
            $ctrl.isLoading = true;
            $ctrl.isLoadingError = false;
            const url = URLS["root-api:management:client-billing-info"](
              $ctrl.clientId,
            );

            return $http.get(url, { params: queryParams }).then(
              (resp) => {
                if ($ctrl.queryParamsLoading !== queryParams) return;
                $ctrl.isLoading = false;
                $ctrl.isLoadingError = false;
                $ctrl.usage = resp.data.usage;
                $ctrl.totalPrice = resp.data.total_price;
              },
              (errorResp) => {
                if ($ctrl.queryParamsLoading !== queryParams) return;
                $ctrl.isLoading = false;
                $ctrl.isLoadingError = true;
              },
            );
          }
        },
      ],
    };
  }

  function DummyManagementController() {}
})();
