<template>
  <FolderMenuDropdown
    :reference="reference"
    :placement="placement"
    @close="$emit('close')"
  >
    <FolderTree
      :disabled-node-keys="disabledNodeKeys"
      :submit-text="$t('shared.move')"
      :class="$style.folderMenu"
      :autofocus="200"
      :width="POPUP_SIZES.folderMenu.width"
      :height="POPUP_SIZES.folderMenu.height"
      @cancel="$emit('close')"
      @update:model-value="submit"
      @update:persisted="$emit('update:persisted', $event)"
    />
  </FolderMenuDropdown>
</template>

<script lang="ts" setup>
import { type ComponentInstance, computed, ref } from "vue";
import { useI18n } from "vue-i18n";

import { $notifyDanger, $notifySuccess } from "@app/vue/common";
import { POPUP_SIZES } from "@app/vue/shared/ui/constants";
import {
  DocumentsApiService,
  isFolder,
} from "@app/vue/store/modules/room/documents/DocumentsApiService";
import { useDocumentsStore } from "@app/vue/store/pinia/room/documents/documents";
import { type FolderMenuParams, getDocumentsSelectedItemsText } from "../utils";
import FolderMenuDropdown from "./FolderMenuDropdown.vue";
import FolderTree from "./FolderTree.vue";

import type { ElPopover } from "element-plus";

interface Props {
  params: FolderMenuParams;
  reference: HTMLElement;
  placement?: ComponentInstance<typeof ElPopover>["placement"];
}

interface Emits {
  (e: "close"): void;
  (e: "update:persisted", value: boolean): void;
}

const props = defineProps<Props>();

const emit = defineEmits<Emits>();

const { t } = useI18n();

const documentsStore = useDocumentsStore();

const loading = ref(false);
const disabledNodeKeys = computed(() => {
  return props.params.folders.flatMap((item) => [
    item.id,
    ...documentsStore
      .getDescendants(item)
      .filter(isFolder)
      .map((inner) => inner.id),
  ]);
});

const api = new DocumentsApiService();

const submit = (destinationFolderId: number | undefined): void => {
  if (!destinationFolderId || loading.value) return;

  const selectedItemsText = getDocumentsSelectedItemsText(props.params);

  loading.value = true;

  emit("update:persisted", true);

  api
    .moveFoldersAndDocuments(
      destinationFolderId,
      props.params.folders.map((item) => item.id),
      props.params.documents.map((item) => item.id),
    )
    .then(() => {
      documentsStore.syncTree();

      $notifySuccess(
        t(
          "data_room.move.success",
          { selected_items: selectedItemsText },
          props.params.documents.length + props.params.folders.length,
        ),
      );

      emit("close");
    })
    .catch((error) => {
      $notifyDanger(
        error.response.data?.join("\n") ??
          t("data_room.move.failed", { selected_items: selectedItemsText }),
      );
    })
    .finally(() => {
      loading.value = false;

      emit("update:persisted", false);
    });
};
</script>

<style lang="scss" module>
.folderMenu {
  :global(.el-button + .el-button) {
    margin-left: 8px;
  }
}
</style>
