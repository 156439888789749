<template>
  <!-- @vue-skip -->
  <RoomHeaderButton
    v-if="tabs[0]"
    :tab="tabs[0]"
    :active="tabs[0].id === activeTab"
  />
  <div v-if="secondTab" :class="$style.secondListWrapper">
    <RoomHeaderButton
      v-if="secondTab"
      :tab="secondTab"
      :active="secondTab.id === activeTab"
    />
    <!-- @vue-expect-error -->
    <ElDropdown v-if="dropdownTabs.length" trigger="click">
      <DrIcon name="caret-down" size="sm" />
      <template #dropdown>
        <ElDropdownMenu>
          <ElDropdownItem
            v-for="tab in dropdownTabs"
            :key="tab.id"
            :command="tab.url"
          >
            <a :href="tab.url" :class="$style.dropdownLinkWrapper">
              {{ tab.label }}
            </a>
          </ElDropdownItem>
        </ElDropdownMenu>
      </template>
    </ElDropdown>
  </div>
</template>

<script setup lang="ts">
import { computed } from "vue";
import { useI18n } from "vue-i18n";
import DrIcon from "@shared/ui/dr-icon";

import { useCategoriesStore } from "@drVue/store/pinia/room/categories";
import RoomHeaderButton from "./RoomHeaderButton.vue";
import { getTasksListTabsNav } from "./utils";

const props = defineProps<{
  activeTab: string;
}>();

const { t } = useI18n();

const categoriesStore = useCategoriesStore();
const tabs = computed(() => getTasksListTabsNav(categoriesStore));

const secondTab = computed(() => {
  return (
    tabs.value?.slice(1).find((t) => t.id === props.activeTab) ||
    tabs.value?.[1]
  );
});

const dropdownTabs = computed(() => {
  return tabs.value?.slice(1).filter((t) => t.id !== secondTab.value?.id);
});
</script>

<style lang="scss" module>
@use "@app/styles/scss/_colors";
@use "@app/styles/scss/typography" as typo;

.secondListWrapper {
  height: 100%;
  display: flex;
  align-items: center;
  gap: 5px;
}
.dropdownLinkWrapper {
  margin: 0 -15px;
  padding: 0 15px;
  max-width: 150px;
  width: 150px;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
