<template>
  <div :class="$style.container">
    <DrIcon
      size="sm"
      :class="$style.icon"
      :title="t('requests.go_to_previous')"
      name="chevron-double-left"
      @click="$emit('navBack')"
    />
    <DrIcon
      size="sm"
      :class="$style.icon"
      :title="t('requests.go_to_next')"
      name="chevron-double-right"
      @click="$emit('navForward')"
    />
    <DrIcon
      v-if="canFollow"
      size="sm"
      name="star"
      :title="isFollowing ? t('shared.follow') : t('shared.unfollow')"
      :class="{
        [$style.icon]: true,
        [$style.iconStar]: true,
        [$style.iconStar_isFollowing]: isFollowing,
      }"
      @click="isFollowing ? $emit('unfollow') : $emit('follow')"
    />
    <DrIcon
      v-if="canRestore"
      size="sm"
      name="trash-restore"
      :title="t('shared.restore')"
      :class="$style.icon"
      @click="$emit('restore')"
    />
    <DrIcon
      v-if="canDelete"
      size="sm"
      name="trash"
      :title="t('shared.archive')"
      :class="$style.icon"
      @click="$emit('delete')"
    />
  </div>
</template>

<script setup lang="ts">
import { computed } from "vue";
import { useI18n } from "vue-i18n";
import DrIcon from "@shared/ui/dr-icon";

import { ROOM_DATA } from "@setups/data";

interface Props {
  isFollowing: boolean;
  isArchived: boolean;
}

interface Emits {
  (e: "follow"): void;
  (e: "unfollow"): void;
  (e: "navForward"): void;
  (e: "navBack"): void;
  (e: "move"): void;
  (e: "delete"): void;
  (e: "restore"): void;
}

const props = defineProps<Props>();

defineEmits<Emits>();

const { t } = useI18n();

const canFollow = computed(
  () => ROOM_DATA.userPermissions.canManageTasks && !props.isArchived,
);

const canDelete = computed(
  () => ROOM_DATA.userPermissions.canDeleteTasks && !props.isArchived,
);

const canRestore = computed(
  () =>
    ROOM_DATA.userPermissions.administrator &&
    // `canDeleteTasks` will be true if `userPermissions.administrator` is true,
    // but I want to add it anyway to make it clearer.
    ROOM_DATA.userPermissions.canDeleteTasks &&
    props.isArchived,
);
</script>

<style module lang="scss">
@use "@app/styles/scss/colors";
@use "@app/styles/scss/typography";

.container {
  display: flex;
  align-items: center;
  justify-content: right;
}

.icon {
  width: 24px;
  height: 24px;
  cursor: pointer;
  color: colors.$pr-400;
  border: solid 2px transparent;
  border-radius: 6px;
  transition: color 0.1s ease-out;

  &:hover {
    color: colors.$pr-500;
  }

  &:active {
    color: colors.$pr-600;
  }
}

.iconStar {
  &:hover {
    color: colors.$sc-600;
  }

  &:active {
    color: colors.$sc-700;
  }

  &:focus {
    border-color: colors.$sc-400;
  }
}

.iconStar_isFollowing {
  color: colors.$sc-600;
}
</style>
