import { h } from "vue";
import { UserEmail, UserInformation, UserOfficeNumber } from "@shared/ui/users";

import DrStore from "@drVue/store";
import { getSortValue } from "@drVue/store/modules/client-dashboard/deals/utils";
import ActionsCell from "./cells/ActionsCell.vue";
import RoomsCell from "./cells/RoomsCell.vue";

import type { FieldItem } from "@drVue/api-service/client-dashboard";
import type { UserViewModel } from "@drVue/components/client-dashboard/users/UsersTable/types";
import type { DrVxeTableColumn } from "@drVue/components/types";

export default class TableColumns {
  constructor() {}

  get customFields(): FieldItem[] {
    return DrStore.getters["clientDashboard/customFields/byObjectType"]("user");
  }

  get columns(): DrVxeTableColumn[] {
    return [
      this.name(),
      this.rooms(),
      this.company(),
      this.title(),
      this.email(),
      this.officeNumber(),
      ...this.customFields.map((field) => this.customFieldColumn(field)),
      this.revokeAccessCell(),
    ];
  }

  private name(): DrVxeTableColumn {
    return {
      sortable: true,
      field: "user.name",
      title: "Name",
      fixed: "left",
      className: "dr-users-table__user-name-cell",
      minWidth: 180,
      slots: {
        default: ({ row }: { row: any }) => {
          row = row as UserViewModel;
          return [
            h(UserInformation, {
              user: row.user,
              isLink: true,
              showTooltip: true,
              size: "28px",
            }),
          ];
        },
      },
    };
  }

  private rooms(): DrVxeTableColumn {
    return {
      sortable: true,
      field: "user.rooms",
      title: "Rooms & Deals",
      minWidth: 150,
      width: 150,
      sortBy: ({ row }) => row.membershipsInfo.length + row.contactsInfo.length,
      slots: {
        default: ({ row }: { row: any }) => {
          row = row as UserViewModel;
          return [
            h(RoomsCell, {
              memberships: row.membershipsInfo,
              contacts: row.contactsInfo,
            }),
          ];
        },
      },
    };
  }

  private company(): DrVxeTableColumn {
    return {
      sortable: true,
      field: "user.company",
      title: "Company",
      minWidth: 150,
      width: 150,
    };
  }

  private title(): DrVxeTableColumn {
    return {
      sortable: true,
      field: "user.title",
      title: "Title",
      minWidth: 150,
      width: 150,
    };
  }

  private email(): DrVxeTableColumn {
    return {
      sortable: true,
      field: "user.email",
      title: "Email",
      minWidth: 150,
      width: 150,
      slots: {
        default: ({ row }: { row: any }) => {
          row = row as UserViewModel;
          return [h(UserEmail, { email: row.user.email, isIconShowed: false })];
        },
      },
    };
  }

  private officeNumber(): DrVxeTableColumn {
    return {
      sortable: true,
      field: "user.office_number",
      title: "Phone",
      minWidth: 150,
      width: 150,
      slots: {
        default: ({ row }: { row: any }) => {
          row = row as UserViewModel;
          return [
            h(UserOfficeNumber, {
              officeNumber: row.user.office_number || "",
            }),
          ];
        },
      },
    };
  }

  private revokeAccessCell(): DrVxeTableColumn {
    return {
      sortable: false,
      resizable: false,
      align: "center",
      field: "revoke_access_action",
      title: "",
      width: 100,
      slots: {
        default: ({ row }: { row: any }) => {
          row = row as UserViewModel;
          return [
            h(ActionsCell, {
              user: row.user,
              memberships: row.membershipsInfo,
            }),
          ];
        },
      },
    };
  }

  private customFieldColumn(field: FieldItem): DrVxeTableColumn {
    return {
      sortable: true,
      field: field.key,
      title: field.label,
      width: 140,
      sortBy: ({ row }) => getSortValue(field, row.custom_data[field.key]),
      slots: {
        default: ({ row }: { row: any }) => {
          row = row as UserViewModel;
          return [h("span", {}, row.customData[field.key])];
        },
      },
    };
  }
}
