<template>
  <ElInput
    v-model="innerValue"
    :placeholder="t('shared.search_dots')"
    size="default"
    clearable
    :disabled="disabled"
    :class="$style.input"
    @input="debouncedEmit"
  >
    <template #prefix>
      <DrIcon name="search" size="sm" />
    </template>
  </ElInput>
</template>

<script lang="ts" setup>
import { debounce } from "lodash-es";
import { ref, watch } from "vue";
import { useI18n } from "vue-i18n";

import DrIcon from "../dr-icon";

interface Props {
  /** Search phrase value */
  modelValue: string;
  /** Disabled state */
  disabled?: boolean;
}

const props = withDefaults(defineProps<Props>(), {
  disabled: false,
});

const emit = defineEmits<{
  (event: "update:modelValue", value: Props["modelValue"]): void;
}>();

const { t } = useI18n();

const innerValue = ref(props.modelValue);

const debouncedEmit = debounce(() => {
  emit("update:modelValue", innerValue.value);
}, 400);

watch(
  () => props.modelValue,
  (value: Props["modelValue"]) => {
    if (innerValue.value !== value) {
      innerValue.value = value;
    }
  },
);
</script>

<style lang="scss" module>
.input:global(.el-input) {
  width: 200px;
}
</style>
