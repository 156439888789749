<template>
  <ElDialog
    v-if="isVisible"
    v-model="isVisible"
    :show-close="false"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    :fullscreen="true"
  >
    <div class="text-center">
      <div v-if="isCompleted">
        <h3>Copy is complete! You may now access either room.</h3>
        <ElButton type="primary" @click="closeDialog()">Access room</ElButton>
      </div>

      <div v-else-if="isFailed">
        <h3 v-if="lastCopyError">
          {{ lastCopyError }}
        </h3>
        <h3>Room copy has failed.</h3>
        <h4>
          Please try again or contact
          <a :href="`mailto:${contactEmail}`" target="_blank">
            {{ contactEmail }}
          </a>
          for help
        </h4>
      </div>

      <div v-else>
        <h3>Copying room is in progress.</h3>
        <h4>Please wait to access either room until copying is complete.</h4>
        <DrLoader />
      </div>
    </div>
  </ElDialog>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import DrLoader from "@shared/ui/dr-loader";

import { APP_SETTINGS, ROOM_DATA } from "@setups/data";
import {
  AsyncOperationStatuses,
  UserAsyncOperationApiService,
  UserLogLevel,
} from "@drVue/store/modules/room/user-async-operation/AsyncOperationApiService";

import type { UserAsyncOperationResult } from "@drVue/store/modules/room/user-async-operation/AsyncOperationApiService";

export async function pollAsyncOperation(
  taskId: string,
): Promise<UserAsyncOperationResult> {
  const apiService = new UserAsyncOperationApiService();
  const maxFailedCount = 20;
  const pollInterval = 5 * 1000; // ms

  let failedCount = 0;

  const pollFunction = async function (
    resolve: (result: UserAsyncOperationResult) => void,
    reject: (reason: UserAsyncOperationResult | unknown) => void,
  ) {
    let data: UserAsyncOperationResult;
    try {
      data = await apiService.getDetails(taskId);

      if (data.status == AsyncOperationStatuses.Success) return resolve(data);
      if (data.status == AsyncOperationStatuses.Fail) return reject(data);

      failedCount = 0;
    } catch (err) {
      failedCount += 1;

      if (failedCount > maxFailedCount) return reject(err);
    }

    setTimeout(pollFunction, pollInterval, resolve, reject);
  };

  return new Promise<UserAsyncOperationResult>(pollFunction);
}

interface Data {
  isVisible: boolean;
  isCompleted: boolean;
  isFailed: boolean;
  lastCopyError: string;
  contactEmail: any;
}

export default defineComponent({
  name: "RoomCopyProgressDialog",
  components: { DrLoader },
  data(): Data {
    return {
      isVisible: !!ROOM_DATA.copyOperationUID,
      isCompleted: false,
      isFailed: false,
      contactEmail: APP_SETTINGS.CONTACT_EMAIL,
      lastCopyError: "",
    };
  },
  mounted() {
    if (ROOM_DATA.copyOperationUID) {
      pollAsyncOperation(ROOM_DATA.copyOperationUID).then(
        () => {
          this.isCompleted = true;
        },
        (data: UserAsyncOperationResult) => {
          this.isFailed = true;
          this.lastCopyError =
            data.log
              .slice()
              .reverse()
              .find((li) => li.level == UserLogLevel.Error)?.message ?? "";
        },
      );
    }
  },
  methods: {
    closeDialog() {
      window.location.reload();
    },
  },
});
</script>
