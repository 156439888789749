import { DrEvent } from "./common/event";

export default class Idle {
  static isAway = false;

  static awayTimeout = 3000;
  static awayTimestamp = 0;
  static awayTimeoutId: number | null = null;

  static onAway = new DrEvent();
  static onAwayBack = new DrEvent();
  static onVisible = new DrEvent();
  static onHidden = new DrEvent();

  private listener: (() => any) | null = null;

  constructor(options: any) {
    const activity: any = this as any;
    const activeMethod: any = function () {
      return activity.onActive();
    };

    if (options) {
      Idle.awayTimeout = parseInt(options.awayTimeout, 10);

      if (options.onAway) Idle.onAway.subscribe(options.onAway);
      if (options.onAwayBack) Idle.onAway.subscribe(options.onAwayBack);
      if (options.onVisible) Idle.onAway.subscribe(options.onVisible);
      if (options.onHidden) Idle.onAway.subscribe(options.onHidden);
    }

    /* passiveSupported detection */
    let passiveSupported = false;

    try {
      window.addEventListener(
        "test",
        () => {},
        Object.defineProperty({}, "passive", {
          get: function () {
            passiveSupported = true;
          },
        }),
      );
    } catch (err) {
      // passive listener support
    }
    const useCapture = passiveSupported ? { passive: true } : false;

    window.addEventListener("click", activeMethod, useCapture);
    window.addEventListener("mousemove", activeMethod, useCapture);
    window.addEventListener("mouseenter", activeMethod, useCapture);
    window.addEventListener("keydown", activeMethod, useCapture);
    window.addEventListener("scroll", activeMethod, useCapture);
    window.addEventListener("mousewheel", activeMethod, useCapture);
  }

  onActive() {
    Idle.awayTimestamp = new Date().getTime() + Idle.awayTimeout;

    if (Idle.isAway) {
      Idle.onAwayBack.fire();
      this.start();
    }

    Idle.isAway = false;

    return true;
  }

  start() {
    const that: any = this;

    if (!this.listener) {
      this.listener = function () {
        return that.handleVisibilityChange();
      };
      document.addEventListener("visibilitychange", this.listener, false);
      document.addEventListener("webkitvisibilitychange", this.listener, false);
      document.addEventListener("msvisibilitychange", this.listener, false);
    }

    Idle.awayTimestamp = new Date().getTime() + Idle.awayTimeout;
    if (Idle.awayTimeoutId !== null) {
      clearTimeout(Idle.awayTimeoutId);
    }

    Idle.awayTimeoutId = window.setTimeout(function () {
      return that.checkAway();
    }, Idle.awayTimeout + 100);

    return this;
  }

  stop() {
    if (Idle.awayTimeoutId !== null) {
      clearTimeout(Idle.awayTimeoutId);
    }

    if (this.listener !== null) {
      document.removeEventListener("visibilitychange", this.listener);
      document.removeEventListener("webkitvisibilitychange", this.listener);
      document.removeEventListener("msvisibilitychange", this.listener);
      this.listener = null;
    }

    return this;
  }

  checkAway() {
    const t: number = new Date().getTime();
    if (t < Idle.awayTimestamp) {
      Idle.isAway = false;
      Idle.awayTimeoutId = window.setTimeout(
        () => this.checkAway(),
        Idle.awayTimestamp - t + 100,
      );

      return;
    }

    if (Idle.awayTimeoutId !== null) {
      clearTimeout(Idle.awayTimeoutId);
    }

    Idle.isAway = true;
    return Idle.onAway.fire();
  }

  handleVisibilityChange() {
    const doc = document as any;
    if (doc.hidden || doc.msHidden || doc.webkitHidden) {
      Idle.onHidden.fire();
    } else {
      Idle.onVisible.fire();
    }
  }
}
