import type { Folder } from "@drVue/store/modules/room/documents/DocumentsApiService";
import type { Dictionary } from "@drVue/types";

export default function restoreExpandedFoldersState(
  newFolders: Dictionary<Folder & { expanded: boolean }>,
  oldFolders: Dictionary<Folder & { expanded: boolean }>,
) {
  for (const key of Object.keys(oldFolders)) {
    if (newFolders[key]) {
      newFolders[key].expanded = oldFolders[key].expanded;
    }
  }
}
