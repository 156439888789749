<template>
  <FolderMenuDropdown
    :reference="reference"
    :placement="placement"
    @close="$emit('close')"
  >
    <TasksTreeBrowserSelect
      :width="POPUP_SIZES.folderMenu.width"
      :height="POPUP_SIZES.folderMenu.height"
      :submit-text="$t('data_room.attach_to_request.button')"
      single-value
      check-strictly
      only-task-value
      expand-on-click-node
      :autofocus="200"
      :loading="isSubmitting"
      :class="$style.folderMenu"
      @update:model-value="submit"
      @reset="$emit('close')"
    />
  </FolderMenuDropdown>
</template>

<script lang="ts" setup>
import { type ComponentInstance, ref } from "vue";
import { useI18n } from "vue-i18n";

import { $notifyDanger, $notifySuccess } from "@app/vue/common";
import { useTasksStore } from "@app/vue/store/pinia/room/tasks/tasks";
import { POPUP_SIZES } from "@drVue/shared/ui/constants";
import TasksTreeBrowserSelect from "../../tasks/shared/tasks-tree-browser/TasksTreeBrowserSelect.vue";
import { type FolderMenuParams, getDocumentsSelectedItemsText } from "../utils";
import FolderMenuDropdown from "./FolderMenuDropdown.vue";

import type { ElPopover } from "element-plus";

interface Props {
  params: FolderMenuParams;
  reference: HTMLElement;
  placement?: ComponentInstance<typeof ElPopover>["placement"];
}

interface Emits {
  (e: "close"): void;
  (e: "update:persisted", value: boolean): void;
}

const props = defineProps<Props>();

const emit = defineEmits<Emits>();

const { t } = useI18n();

const isSubmitting = ref(false);

const tasksStore = useTasksStore();

const submit = (value: string[]): void => {
  if (isSubmitting.value) return;

  const taskId = tasksStore.tasksByUid[value[0]]?.id;

  if (!taskId) return;

  const selectedItemsText = getDocumentsSelectedItemsText(props.params);

  isSubmitting.value = true;

  emit("update:persisted", true);

  const promiseFolders = props.params.folders.length
    ? tasksStore.updateFolders(
        taskId,
        props.params.folders.map((item) => ({ folder_id: item.uid })),
        [],
      )
    : Promise.resolve();

  const promiseDocuments = props.params.documents.length
    ? tasksStore.updateDocuments(
        taskId,
        props.params.documents.map((item) => ({ document_id: item.uid })),
        [],
      )
    : Promise.resolve();

  Promise.all([promiseFolders, promiseDocuments])
    .then(() => {
      $notifySuccess(
        t(
          "data_room.attach_to_request.success",
          {
            selected_items: selectedItemsText,
          },
          props.params.documents.length + props.params.folders.length,
        ),
      );

      emit("close");
    })
    .catch((error) => {
      $notifyDanger(
        error.response.data?.join("\n") ??
          t("data_room.attach_to_request.failed", {
            selected_items: selectedItemsText,
          }),
      );
    })
    .finally(() => {
      isSubmitting.value = false;

      emit("update:persisted", false);
    });
};
</script>

<style lang="scss" module>
.folderMenu {
  :global(.el-button + .el-button) {
    margin-left: 8px;
  }
}
</style>
