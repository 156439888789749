import { t } from "@app/vue/i18n";
import { ApiService } from "@drVue/api-service";

export interface Download {
  task_id: string;
  status: DownloadStatus;
  log: string[];
  progress: { total: number; processed: number };
  date_created: Date;
  need_email: boolean;
}

export enum DownloadStatus {
  Pending = "pending",
  Processing = "processing",
  Success = "success",
  Fail = "fail",
}

export const downloadStatusTextMap: Record<DownloadStatus, string> = {
  [DownloadStatus.Pending]: t("download_status_dialog.status_text.pending"),
  [DownloadStatus.Processing]: t("download_status_dialog.status_text.processing"),
  [DownloadStatus.Success]: t("download_status_dialog.status_text.success"),
  [DownloadStatus.Fail]: t("download_status_dialog.status_text.fail"),
}

export class DownloadsApiService extends ApiService {
  public getDownloads(): Promise<Download[]> {
    const url = this.Urls["api:room:documents_download_bulk"]();
    return this.get(url);
  }

  public setNeedEmail(taskId: string, needEmail: boolean): Promise<Download[]> {
    const url = this.Urls["api:room:documents_download_bulk_task"](taskId);
    return this.post(url, { need_email: needEmail });
  }
}
