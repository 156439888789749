import { cloneDeep } from "lodash-es";
import { ref } from "vue";

import { CustomViewObjectTypes } from "@setups/types";
import { DEFAULT_FINDING_VIEW } from "@drVue/store/modules/common/custom-views/default";
import { useFindingsStore } from "@drVue/store/pinia/room/findings";
import { FINDING_ROOT_CATEGORY_KEY } from "./types";

import type { FindingCategoryId } from "./types";
import type { RootState } from "@drVue/store/state";
import type {
  CustomView,
  CustomViewColumn,
  TableLayoutSort,
} from "@setups/types";
import type { Node as ProsemirrorNode } from "@tiptap/pm/model";
import type { Store } from "vuex";

// TODO: refactor, mostly copy pasted from frontend/app/vue/components/room/documents/utils.ts:5

function updateColumns(
  columns: CustomViewColumn[],
  updates: CustomViewColumn[],
) {
  updates.forEach((update) => {
    const col = columns.find((c) => c.field === update.field);
    if (col) {
      const i = columns.indexOf(col);
      columns.splice(i, 1);
    }
    columns.push({
      ...(col || {}),
      ...update,
    });
  });
}

function saveView($store: Store<RootState>, view: CustomView) {
  // from findings interface only personal views can be saved
  const toSaveView = cloneDeep(view);

  const method = view.slug && view.is_personal ? "update" : "create";
  toSaveView.is_personal = true;
  $store.dispatch(`common/customViews/${method}`, toSaveView);
}

export function saveColumnsConfig(
  $store: Store<RootState>,
  updates: CustomViewColumn[],
) {
  const view = $store.getters["common/customViews/defaultView"](
    CustomViewObjectTypes.Finding,
  );

  updateColumns(view.settings.columns, updates);
  saveView($store, view);
}

export function saveSortConfig(
  $store: Store<RootState>,
  sort?: TableLayoutSort,
) {
  const view = $store.getters["common/customViews/defaultView"](
    CustomViewObjectTypes.Finding,
  );

  view.settings.tableLayoutSort = sort;
  saveView($store, view);
}

function getOrgDefaultView($store: Store<RootState>) {
  const views: CustomView[] = $store.getters["common/customViews/byObjectType"](
    CustomViewObjectTypes.Finding,
  );
  const orgView = views.filter((v) => !v.is_personal)[0];
  if (orgView) {
    return orgView;
  }
  return DEFAULT_FINDING_VIEW;
}

export function resetColumnsConfig($store: Store<RootState>) {
  const currentView: CustomView = $store.getters[
    "common/customViews/defaultView"
  ](CustomViewObjectTypes.Finding);
  const defaultView = getOrgDefaultView($store);
  currentView.settings.columns = cloneDeep(defaultView.settings.columns ?? []);
  saveView($store, currentView);
}

export const getRichTextEmptyData = () =>
  ({
    type: "doc",
    content: [],
  }) as unknown as ProsemirrorNode;

export const attachDocumentsToFinding = (
  findingId: string,
  folderUIDs: { folder_uid: string }[],
  documentUIDs: { document_uid: string }[],
) => {
  const findingsStore = useFindingsStore();

  if (folderUIDs.length) {
    findingsStore.addFolders(
      findingId,
      folderUIDs.map((f) => ({ folder_id: f.folder_uid })),
    );
  }

  if (documentUIDs.length) {
    findingsStore.addDocuments(
      findingId,
      documentUIDs.map((d) => ({ document_id: d.document_uid })),
    );
  }
};

export const selectedCategoryId = ref<FindingCategoryId>(
  FINDING_ROOT_CATEGORY_KEY,
);
