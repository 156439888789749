import { useDocumentsStore } from "@app/vue/store/pinia/room/documents/documents";
import documents_folderSelectHtml from "../../../templates/components/documents/folder-select.html?raw";
import { DocumentsTreeService } from "../services/DocumentsTreeService.ts";

export default function drFolderSelect() {
  // NB: allow to select only folders with edit permission
  return {
    require: {
      ngModelCtrl: "ngModel",
    },
    bindings: {
      ngModel: "<",
      showFolderCreate: "<?",
      getHint: "<?",
      isAllowed: "<?",
    },
    template: documents_folderSelectHtml,
    controller: [
      "DocumentsService",
      function (DocumentsService) {
        const documentsStore = useDocumentsStore();
        const $ctrl = this;
        $ctrl.$onInit = function () {
          $ctrl.service = new DocumentsTreeService();
          DocumentsTreeService.setRootFolder(documentsStore.rootFolder);

          $ctrl.showFolderCreate = $ctrl.showFolderCreate || false;
          $ctrl.isDropdownOpen = false;

          $ctrl.ngModelCtrl.$render = function () {
            $ctrl.folderId = $ctrl.ngModelCtrl.$viewValue;
            $ctrl.service.expandFolder(
              documentsStore.folderByIdMap[$ctrl.folderId],
            );
          };

          $ctrl.ngModelCtrl.$validators.disabled = function (modelValue) {
            var folder = documentsStore.folderByIdMap[modelValue];
            return modelValue && !$ctrl.isDisabled(folder);
          };
        };

        $ctrl.$onDestroy = function () {
          $ctrl.service.destroy();
          $ctrl.service = null;
        };

        $ctrl.createFolder = function (destFolder) {
          $ctrl.isDropdownOpen = false;
          DocumentsService.createNewFolder(destFolder.id).then(
            function (newFolder) {
              var folder = documentsStore.folderByIdMap[newFolder.id];
              $ctrl.service.expandFolder(folder);
              $ctrl.select(folder, true);
            },
          );
        };

        $ctrl.isDisabled = function (folder) {
          return !folder.edit || ($ctrl.isAllowed && !$ctrl.isAllowed(folder));
        };

        $ctrl.select = function (folder, keepDropdownOpen) {
          if (!$ctrl.isDisabled(folder)) {
            $ctrl.folderId = folder.id;
            $ctrl.ngModelCtrl.$setViewValue($ctrl.folderId);
          } else if (keepDropdownOpen === undefined) {
            keepDropdownOpen = true;
          }
          $ctrl.isDropdownOpen = keepDropdownOpen;
        };

        $ctrl.isActive = function (folder) {
          return folder.id === $ctrl.folderId;
        };
      },
    ],
  };
}
