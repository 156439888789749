<template>
  <div>
    <div :class="{ content: RoomConfig.id }">
      <ElRow
        type="flex"
        justify="space-between"
        class="dash-container__toolbar dash-container__toolbar--space-between"
      >
        <ElCol :span="20">
          <div>
            <ElButton
              type="primary"
              :disabled="isLoading"
              @click="markAllAsRead"
            >
              <template #icon>
                <i class="fa fa-calendar-plus-o fa-lg" />
              </template>
              {{ $t("notifications.mark_as_read") }}
            </ElButton>
            <ElButton
              v-if="!RoomConfig.id || RoomConfig.userPermissions.administrator"
              @click="postAnnouncement"
            >
              <template #icon>
                <i class="fa fa-bullhorn fa-lg" />
              </template>
              {{ $t("notifications.create_announcement") }}
            </ElButton>
          </div>
        </ElCol>
        <ElCol :span="4">
          <RoomSelectSimple v-model="selectedRoom" />
        </ElCol>
      </ElRow>

      <div class="notifications-list notifications-list--dashboard">
        <NotificationsList :notifications="notifications" />

        <!-- No Notifications -->
        <div
          v-show="!isLoading && !hasMore && notifications.length == 0"
          class="empty-container content__padding-left"
        >
          <div class="inner-content text-center">
            <div class="icon-box">
              <i class="fa fa-bell fa-4x" />
            </div>
            <h2>{{ $t("notifications.empty") }}</h2>
          </div>
        </div>
        <!-- /No Notifications -->

        <button
          v-show="hasMore || isLoading"
          class="btn btn-transparent btn-block notification-load-more-btn"
          :disabled="isLoading"
          @click="loadNotifications(false)"
        >
          <span v-if="isLoading">
            <DrLoader />
          </span>
          <span v-else>{{ $t("notifications.load_more") }}</span>
        </button>
      </div>
    </div>

    <PostAnnouncement
      ref="postAnnouncementModal"
      @announcement-posted="loadNotifications(true)"
    />
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import DrLoader from "@shared/ui/dr-loader";

import { ROOM_DATA } from "@setups/data";
import { NotificationsHtmlHelper } from "@drVue/components/client-dashboard/notifications/Notification/html-helper";
import NotificationsList from "@drVue/components/client-dashboard/notifications/NotificationsList.vue";
import PostAnnouncement from "@drVue/components/client-dashboard/notifications/PostAnnouncement";
import RoomSelectSimple from "@drVue/components/client-dashboard/room-select/RoomSelectSimple.vue";

import type { RoomListItem } from "@drVue/api-service/common/rooms";

interface Data {
  selectedRoom: RoomListItem | null;
  htmlHelper: any;
}

export default defineComponent({
  name: "NotificationsModule",
  components: {
    PostAnnouncement,
    NotificationsList,
    DrLoader,
    RoomSelectSimple,
  },
  data(): Data {
    return {
      selectedRoom: null,
      htmlHelper: new NotificationsHtmlHelper(),
    };
  },
  computed: {
    selectedRoomId(): any {
      return this.selectedRoom ? this.selectedRoom.id : undefined;
    },
    RoomConfig(): any {
      return ROOM_DATA;
    },
    notifications(): any[] {
      return this.$store.state.common.notifications.notifications;
    },
    hasMore(): any {
      return this.$store.getters["common/notifications/hasMore"]();
    },
    isLoading(): any {
      return this.$store.getters["common/notifications/isLoading"]();
    },
  },
  watch: {
    selectedRoom() {
      this.loadNotifications(true);
    },
  },
  beforeMount() {
    this.loadNotifications(true);
  },
  methods: {
    loadNotifications(reloadAll: boolean = false) {
      this.$store.dispatch("common/notifications/loadNotifications", {
        reloadAll,
        roomId: this.selectedRoomId,
      });
    },
    markAllAsRead() {
      this.$store.dispatch("common/notifications/markAllAsRead").then(() => {
        this.htmlHelper.markAllAsRead(this.$el);
      });
    },
    postAnnouncement() {
      (this.$refs.postAnnouncementModal as any).open();
    },
  },
});
</script>
