<template>
  <ElPopover
    v-if="$slots.reference || virtualRef"
    ref="refPopover"
    v-model:visible="isOpened"
    effect="light"
    :width="width"
    :teleported="teleported"
    :placement="placement"
    :trigger="trigger"
    :popper-class="{
      [$style.popper]: true,
      [$style.popper_isPaddingless]: paddingless,
      [$style.popper_isReferenceHidden]: !$slots.reference,
    }"
    :disabled="disabled"
    :show-arrow="!hideArrow"
    :persistent="persistent"
    :virtual-ref="virtualRef"
    :transition="transition"
    @show="$emit('show')"
    @hide="$emit('hide')"
    @after-leave="handleHidden"
  >
    <slot name="default" v-bind="{ hide }" />

    <template #reference>
      <slot name="reference" v-bind="{ isOpened, show }" />
    </template>
  </ElPopover>
</template>

<script setup lang="ts">
/**
 * The popup component.
 *
 * It's based on element-plus component according to the requirements of our style guide.
 * @reference https://element-plus.org/en-US/component/popover.html
 */

import { ElPopover } from "element-plus";
import { nextTick, ref } from "vue";

import type { Placement } from "element-plus";

interface Props {
  placement?: Placement;
  trigger?: "click" | "focus" | "hover" | "contextmenu";
  disabled?: boolean;
  hideArrow?: boolean;
  paddingless?: boolean;
  persistent?: boolean;
  teleported?: boolean;
  width?: string | number;
  transition?: string;
}

withDefaults(defineProps<Props>(), {
  placement: "auto",
  trigger: "click",
  disabled: false,
  hideArrow: false,
  paddingless: false,
  persistent: false, // Do not render popup content if it's not shown (to improve performance).
  teleported: true,
  width: "auto",
  transition: undefined,
});

const emit = defineEmits(["show", "hide"]);

const refPopover = ref<InstanceType<typeof ElPopover>>();
const virtualRef = ref<HTMLElement>();
const isOpened = ref(false);
const allowToOpen = ref(true);

const show = async (reference?: HTMLElement) => {
  if (isOpened.value || !allowToOpen.value) return;

  allowToOpen.value = false;

  if (reference) {
    virtualRef.value = reference;
    await nextTick();
  }

  isOpened.value = true;
};

const hide = () => (isOpened.value = false);
const handleHidden = () => {
  virtualRef.value = undefined;
  allowToOpen.value = true;
};

defineExpose({
  show,
  hide,
});
</script>

<style lang="scss" module>
@use "@app/styles/scss/colors";

.popper:global(.el-popover.el-popper) {
  background: #fff;
  border: 1px solid colors.$pr-300;
  box-shadow: var(--dr-box-shadow-light);
  border-radius: 8px;
}

.popper_isPaddingless:global(.el-popover.el-popper) {
  padding: unset !important;
}

.popper_isReferenceHidden:global {
  &.el-popper[data-popper-reference-hidden="true"] {
    display: none;
  }
}
</style>
