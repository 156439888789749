import { formatISO } from "date-fns";

import DOCUMENTS_COLUMNS from "./columns-documents";
import TASKS_ACTIVITY_COLUMNS from "./columns-tasks-activity";
import TASKS_PROGRESS_COLUMNS from "./columns-tasks-progress";
import USERS_COLUMNS from "./columns-users";

angular
  .module("dealroom.analytics.service.export.excel", [
    "dealroom.dashboard-services",

    "dealroom.analytics.service.data",

    "dealroom.common",
    "dealroom.task",
    "dealroom.members",
    "dealroom.documents",
  ])
  .service("AnalyticsExportExcelService", AnalyticsExportExcelService)
  .service("AuditLogExportService", AuditLogExportService);

AuditLogExportService.$inject = [
  "$http",
  "$timeout",
  "$window",
  "URLS",
  "AlertService",
];
function AuditLogExportService($http, $timeout, $window, URLS, AlertService) {
  const self = {
    exporting: false,
    exportToExcel: exportToExcel,
    cancelExport: cancelExport,
  };

  const cancelToken = { cancelled: false };

  return self;

  async function sleep(ms) {
    return new Promise((resolve) => $timeout(resolve, ms));
  }

  async function waitUntilReady(taskId) {
    const POLL_INTERVAL = 1000; // ms
    const statusUrl = URLS["api:room:audit-export-check-status"](taskId);

    while (true) {
      const status = (await $http.get(statusUrl)).data?.status;
      if (!angular.isDefined(status)) throw new Error("unknown status");
      if (status === "success") {
        return;
      }
      if (status === "fail") {
        throw new Error("export failed");
      }
      if (cancelToken.cancelled) {
        throw new Error("export cancelled");
      }
      await sleep(POLL_INTERVAL);
    }
  }

  function cancelExport() {
    cancelToken.cancelled = true;
    self.exporting = false;
  }

  async function exportToExcel(filters) {
    if (self.exporting) return;
    const initializeUrl = URLS["api:room:audit-export-initialize"]();
    try {
      self.exporting = true;
      cancelToken.cancelled = false;
      // todo(task_body): remove this when we get rid of task_body verb on BE completely
      if (filters.action === "task_description") {
        filters.action = "task_description,task_body";
      }
      const initResponse = await $http.post(initializeUrl, filters);
      const taskId = initResponse.data.task_id;
      await waitUntilReady(taskId);
      // ensure we're downloading file in a digest cycle
      $timeout(() => {
        self.exporting = false;
        const resultUrl = URLS["api:room:audit-export-download"](taskId);
        $window.open(resultUrl, "_self");
        AlertService.info("File has been downloaded.");
      });
    } catch (err) {
      if (cancelToken.cancelled) return;
      AlertService.danger("Failed to create audit log XLSX file.");
      throw err;
    } finally {
      self.exporting = false;
    }
  }
}

AnalyticsExportExcelService.$inject = [
  "postAndGetFile",
  "URLS",
  "RoomConfig",
  "DocumentsService",
  "TasksService",
  "CategoriesService",
  "MembersService",
  "PermissionsService",
  "ActivityGroupPermissionsVisibleService",
  "AnalyticsActivityDataService",
  "ActivityFilterDatesService",
  "ActivityVisibleCategoriesService",
];
function AnalyticsExportExcelService(
  postAndGetFile,
  URLS,
  RoomConfig,
  DocumentsService,
  TasksService,
  CategoriesService,
  MembersService,
  PermissionsService,
  ActivityGroupPermissionsVisibleService,
  AnalyticsActivityDataService,
  ActivityFilterDatesService,
  ActivityVisibleCategoriesService,
) {
  let excelDataContext;

  const excelReports = {
    documentsAnalytics: makeDocumentsActivityReport,
    tasksAnalytics: makeTasksActivityReport,
    tasksProgress: makeTasksProgressReport,
    usersAnalytics: makeUsersActivityReport,
  };

  return {
    setExcelDataContext: setExcelDataContext,
    exportToExcel: exportToExcel,
  };

  function exportToExcel(type) {
    if (!excelDataContext) {
      //todo: disable export ability while data is loading
      return;
    }
    const report = excelReports[type](excelDataContext);
    postAndGetFile(URLS["api:export_xlsx"](), report);
  }

  function setExcelDataContext(dataContext) {
    excelDataContext = dataContext;
  }

  function getExcelExportDetails(includeTasksInfo) {
    let details = [
      {
        label: "Exported By",
        value: `${RoomConfig.currentUser.name} <${RoomConfig.currentUser.email}>`,
      },
      { label: "Exported On", value: formatISO(new Date()) },
      {
        label: "Dates Range",
        value: ActivityFilterDatesService.getSelectedRangeAsString(),
      },
      {
        label: "Groups",
        value:
          ActivityGroupPermissionsVisibleService.getSelectedGroupsAsString(),
      },
    ];
    if (includeTasksInfo) {
      details = details.concat([
        {
          label: "Lists",
          value:
            ActivityVisibleCategoriesService.getSelectedListsRangeAsString(),
        },
        {
          label: "Priority",
          value:
            ActivityVisibleCategoriesService.getSelectedPriorityRangeAsString(),
        },
        {
          label: "Statuses",
          value:
            ActivityVisibleCategoriesService.getSelectedStatusesRangeAsString(),
        },
      ]);
    }

    if (!AnalyticsActivityDataService.isLoaded) {
      details.push({ label: "Partial Data", value: "" });
    }
    return details;
  }

  function makeDocumentsActivityReport(dataContext) {
    function getExcelItem(treeItem) {
      let docObj;
      if (treeItem.type === "document") {
        docObj = DocumentsService.Files[treeItem.id];
      } else {
        docObj = DocumentsService.Folders[treeItem.id];
      }
      //todo: prolly this should be in DocumentsService.processDocuments, but
      //MembersService may not be loaded yet while processDocuments is running
      const user = MembersService.members[docObj.user_id];
      return {
        index: docObj.treeIndex || docObj.tree_index,
        date_created: {
          type: "datetime",
          value: docObj.dateCreated || docObj.date_created,
        },
        treePosition: docObj.treePosition,
        name: docObj.name,
        type: treeItem.type === "document" ? "Document" : "Folder",
        user: (user && user.name) || "Anonymous",
      };
    }

    function getItems(dataContext) {
      const items = [];
      buildStatTree(
        dataContext,
        items,
        { document: true, folder: true },
        getExcelItem,
      );
      items.sort((a, b) => a.treePosition - b.treePosition);
      //remove treePosition to avoid redundant traffic
      items.forEach((i) => {
        delete i.treePosition;
      });
      return items;
    }

    return {
      filename: RoomConfig.slug + "-index-activity.xlsx",
      sheetname: "Index Activity",
      columns: DOCUMENTS_COLUMNS,
      items: getItems(dataContext),
      details: getExcelExportDetails(false),
    };
  }

  function makeTasksActivityReport(dataContext) {
    return {
      filename: RoomConfig.slug + "-requests-activity.xlsx",
      sheetname: "Requests Activity",
      columns: TASKS_ACTIVITY_COLUMNS,
      items: getItems(dataContext),
      details: getExcelExportDetails(true),
    };

    function getExcelItem(treeItem) {
      const excelItem = {};
      if (treeItem.type === "category") {
        const category = CategoriesService.categories[treeItem.id];
        excelItem["name"] = category.name;
        excelItem["type"] = "Category";
        excelItem["category_fullpath"] = category.parent
          ? category.parent.full_path
          : null;
        excelItem["categoryOrder"] = category.treePosition;
        excelItem["taskOrder"] = 0;
      } else {
        const task = TasksService.tasks[treeItem.id];
        excelItem["name"] = task.title;
        excelItem["type"] = "Request";
        const category = CategoriesService.categories[task.category_id];
        excelItem["category_fullpath"] = category.full_path;
        excelItem["categoryOrder"] = category.treePosition;
        excelItem["taskOrder"] = task.order;
      }
      return excelItem;
    }

    function getItems(dataContext) {
      const items = [];
      buildStatTree(
        dataContext,
        items,
        { category: true, task: true },
        getExcelItem,
      );
      items.sort((a, b) => {
        if (a.categoryOrder !== b.categoryOrder)
          return a.categoryOrder - b.categoryOrder;
        return a.taskOrder - b.taskOrder;
      });
      items.forEach((i) => {
        delete i.categoryOrder;
        delete i.taskOrder;
      });
      return items;
    }
  }

  function makeTasksProgressReport(dataContext) {
    return {
      filename: RoomConfig.slug + "-requests-progress.xlsx",
      sheetname: "Requests Progress",
      columns: TASKS_PROGRESS_COLUMNS,
      items: getItems(dataContext),
      details: getExcelExportDetails(true),
    };

    function getExcelItem(treeItem) {
      const task = TasksService.tasks[treeItem.id];
      const category = CategoriesService.categories[task.category_id];
      return {
        key: task.key,
        name: task.title,
        category_fullpath: category.full_path,
      };
    }

    function getItems(dataContext) {
      const items = [];
      buildStatTree(dataContext, items, { task: true }, getExcelItem);
      items.sort((a, b) => -(a["task_viewed:total"] - b["task_viewed:total"]));
      return items;
    }
  }

  function makeUsersActivityReport(dataContext) {
    const roomHasTasks = RoomConfig.userPermissions.viewTasks;
    const tasksDepended = ["Requests Viewed"];
    const report = {
      filename: RoomConfig.slug + "-top-active-users.xlsx",
      sheetname: "Top Active Users",
      columns: USERS_COLUMNS.filter(
        ({ title }) => roomHasTasks || !tasksDepended.includes(title),
      ),
      details: getExcelExportDetails(roomHasTasks),
    };
    report.items = getItems(dataContext);
    return report;

    function getInviteItems() {
      function getNullDataObject() {
        const dataIndexes = [];
        function visitColumn(c) {
          if (c.data_index) dataIndexes.push(c.data_index);
          if (c.columns) c.columns.forEach(visitColumn);
        }
        report.columns.forEach(visitColumn);
        return dataIndexes.reduce((obj, key) => {
          obj[key] = null;
          return obj;
        }, {});
      }
      const nullObj = getNullDataObject();

      function getExcelItem(invite) {
        const inviteItem = angular.copy(nullObj);
        $.extend(inviteItem, {
          user_name: invite.email,
          group_name: invite.pgroup.name,
          type: "Invite",
        });
        return inviteItem;
      }
      const visiblePgroupsIds =
        ActivityGroupPermissionsVisibleService.visiblePgroupIds;
      const visibleInvites = MembersService.invitesList.filter(
        (i) => visiblePgroupsIds.indexOf(i.pgroup.id) !== -1,
      );
      return visibleInvites.map(getExcelItem);
    }

    function getStatsTreeItems(statsTree) {
      function getExcelItem(treeItem) {
        let group, user, company;
        if (treeItem.type === "group") {
          user = null;
          group = PermissionsService.pgroups[treeItem.id];
          company = null;
        } else {
          user = MembersService.members[treeItem.id];
          group = user.pgroup;
          company = user.company;
        }
        return {
          group_name: group.name,
          user_name: (user && user.name) || "",
          company_name: company || "",
          type: treeItem.type === "group" ? "Group" : "User",
        };
      }
      const items = [];
      buildStatTree(
        dataContext,
        items,
        { user: true, group: true },
        getExcelItem,
      );
      return items;
    }

    function getItems(dataContext) {
      let items = getStatsTreeItems(dataContext);
      items = items.concat(getInviteItems());
      items.sort((a, b) => {
        if (a.group_name !== b.group_name)
          return a.group_name.localeCompare(b.group_name);
        return a.user_name.localeCompare(b.user_name);
      });
      return items;
    }
  }
}

function _visitStatTreeItem(treeItem, result, types, visitFunc) {
  if (!types[treeItem.type]) {
    return;
  }

  const visitedItem = visitFunc(treeItem);
  if (visitedItem) {
    // grab calculated aggs
    const itemWithAggs = treeItem.cells.reduce((aggs, a) => {
      aggs[a.key] = a.value;
      return aggs;
    }, visitedItem);
    result.push(itemWithAggs);
  }
  if (treeItem.getSubRows) {
    treeItem
      .getSubRows()
      .forEach((subItem) =>
        _visitStatTreeItem(subItem, result, types, visitFunc),
      );
  }
}

function buildStatTree(treeItems, result, types, visitFunc) {
  treeItems.forEach((item) =>
    _visitStatTreeItem(item, result, types, visitFunc),
  );
}
