import { t } from "@app/vue/i18n";
import documents_downloadDisallowedInfoHtml from "../../../templates/documents/download-disallowed-info.html?raw";

export default function drDownloadDisallowedInfo() {
  return {
    bindings: {
      btnText: "@",
      dropdownClass: "@",
      type: "@",
    },
    controller: function () {
      const $ctrl = this;
      $ctrl.errorMessages = {
        disabled: t("data_room.download.error_messages.disabled"),
        bulkDisallowed: t("data_room.download.error_messages.bulk_disallowed"),
        disallowed: t("data_room.download.error_messages.disallowed"),
      };
    },
    template: documents_downloadDisallowedInfoHtml,
  };
}
