<template>
  <div :class="$style.container">
    <template v-if="member">
      <DrAvatar
        :name="member.name"
        :identifier="member.email"
        :url="member.avatar.thumbnail"
      />
      <span>{{ member.name }}</span>
    </template>
    <template v-else-if="invite">
      <DrIcon name="envelope" :class="$style.mailIcon" />
      <span>{{ invite.email }}</span>
      <div
        v-if="allowInvite"
        :class="{
          [$style.resend]: true,
          [$style.resend_isInactive]: disallowResend,
          [$style.resend_isPending]: inviteReseinding,
        }"
        @click="resend"
      >
        <DrIcon size="sm" name="rotate-right" />

        <DrTooltip
          placement="bottom"
          :disabled="!disallowResend"
          :content="$t('permissions.invite_members.resend_invite_restriction')"
        >
          <span>{{ resendText }}</span>
        </DrTooltip>
      </div>
    </template>
  </div>
</template>

<script setup lang="ts">
import { computed } from "vue";
import { useI18n } from "vue-i18n";
import { DrAvatar } from "@shared/ui/dr-avatar";
import { DrIcon } from "@shared/ui/dr-icon";
import { DrTooltip } from "@shared/ui/dr-tooltip";
import { useStorage } from "@vueuse/core";

import { ROOM_DATA } from "@setups/data";
import { DrStore } from "@app/vue";
import { DateType, serializeDrDate } from "@drVue/api-service/parse";

interface Props {
  userUid: string;
}

const { userUid } = defineProps<Props>();

const { t } = useI18n();
const userPerms = ROOM_DATA.userPermissions;

const today = serializeDrDate(DateType.Date, new Date());
const RESEND_STATE_KEY = `dr:resend_invite_for_${userUid}__${today}`;

const disallowResend = useStorage<boolean>(RESEND_STATE_KEY, false);

const member = computed(
  () => DrStore.state.room.members.membersByUid[userUid] || null,
);
const invite = computed(
  () => DrStore.state.room.members.invitesByUid[userUid] || null,
);

const allowInvite = computed(() => {
  if (!invite.value) return false;

  if (userPerms.administrator) return true;

  if (invite.value.pgroup.id !== userPerms.id) return false;

  return userPerms.invite && invite.value.pgroup.id === userPerms.id;
});

const inviteReseinding = computed(() =>
  invite.value
    ? DrStore.state.room.members.inviteResending[invite.value.id]
    : false,
);

const resendText = computed(() =>
  inviteReseinding.value
    ? t("permissions.invite_members.resend_invite_active")
    : t("permissions.invite_members.resend_invite"),
);

const resend = () => {
  if (!invite.value || disallowResend.value || inviteReseinding.value) return;

  DrStore.dispatch("room/members/resendInvite", {
    invite: invite.value,
  }).then(() => {
    disallowResend.value = true;
  });
};
</script>

<style module lang="scss">
@use "@app/styles/scss/colors";
@use "@app/styles/scss/spacing";
@use "@app/styles/scss/typography" as typo;

.container {
  display: inline-flex;
  align-items: center;
  gap: spacing.$xs;
  font: typo.$body_regular;
  color: colors.$pr-900;
}

.mailIcon {
  background-color: colors.$pr-150;
  color: colors.$pr-500;
  border-radius: 50%;
  --font-size: 12px;
}

.resend {
  display: inline-flex;
  gap: spacing.$xxs;
  align-items: center;
  color: colors.$sc-600;
}

.resend_isInactive,
.resend_isPending {
  cursor: not-allowed;
  color: colors.$pr-350;
}

@keyframes dots {
  0% {
    content: "";
  }
  20% {
    content: ".";
  }
  50% {
    content: "..";
  }
  80% {
    content: "...";
  }
}

.resend_isPending span:after {
  content: "";
  display: inline;
  animation: dots 1.4s steps(3, end) infinite;
}
</style>
