<template>
  <DrPopup placement="bottom" width="300" persistent>
    <template #reference>
      <ElButton>
        <template #icon>
          <DrIcon name="info-circle" />
        </template>
        {{ $t("shared.upload") }}
      </ElButton>
    </template>

    <div>
      <ElAlert :closable="false" type="warning" :class="$style.alert">
        {{ $t("upload_button.alert") }}
      </ElAlert>

      <div v-if="!hasAllowedFolders">
        {{ $t("upload_button.not_permitted") }}
      </div>

      <template v-else>
        <div>{{ $t("upload_button.allowed_in_folders") }}</div>
        <ElScrollbar :max-height="190" v-if="allowedFolders.length">
          <a
            v-for="folder in allowedFolders"
            :key="folder.id"
            :class="$style.folder"
            :href="folderUrl(ROOM_DATA.url, folder.id)"
          >
            <DrIcon name="folder" />
            {{ folder.name }}
          </a>
        </ElScrollbar>
      </template>
    </div>
  </DrPopup>
</template>

<script setup lang="ts">
import { computed } from "vue";
import DrIcon from "@shared/ui/dr-icon";
import { DrPopup } from "@shared/ui/dr-popups";

import { ROOM_DATA } from "@setups/data";
import { folderUrl } from "@setups/room-urls";
import { useDocumentsStore } from "@app/vue/store/pinia/room/documents/documents";

const documentsStore = useDocumentsStore();

// TODO: receive this via props when this button will not be used on Angular's side
const allowedFolders = computed(
  () => documentsStore.treeRaw?.Folders.filter((folder) => folder.edit) ?? [],
);

const hasAllowedFolders = computed(() => allowedFolders.value.length > 0);
</script>

<style lang="scss" module>
.alert {
  margin-bottom: 15px;
}

.folder {
  display: block;
  text-decoration: none;
}
</style>
