<template>
  <DrPopup ref="popupRef" paddingless @hide="$emit('hide')">
    <div v-if="isDynamicDateMode" :class="$style.ddContainer">
      <DynamicDateDialog
        @submit="handleSubmitDynamicDate"
        @set-static="handleSubmitStaticDate"
        @cancel="isDynamicDateMode = false"
      />
    </div>
    <template v-else>
      <DrDatePickerInline @update:model-value="handleSubmitStaticDate" />
      <div :class="$style.actionContainer">
        <ElButton
          :class="$style.setDynamicDateBtn"
          @click="isDynamicDateMode = true"
        >
          <template #icon>
            <DrIcon size="sm" name="link" />
          </template>
          {{ t("requests.set_dyn_start_date") }}
        </ElButton>
      </div>
    </template>
  </DrPopup>
</template>

<script setup lang="ts">
import { ref } from "vue";
import { useI18n } from "vue-i18n";
import { DrDatePickerInline } from "@shared/ui/dr-datepicker";
import DrIcon from "@shared/ui/dr-icon";
import { DrPopup } from "@shared/ui/dr-popups";

import { insightTrack, RoomRequestsBulkEvent } from "@app/insight";
import DynamicDateDialog from "@drVue/components/room/tasks/shared/DynamicDateDialog.vue";

import type { Task, TaskDynamicDateConf } from "@drVue/store/pinia/room/tasks";

interface Events {
  (e: "update", payload: Partial<Task>): void;
  (e: "hide"): void;
}

const emit = defineEmits<Events>();

const { t } = useI18n();

const popupRef = ref<InstanceType<typeof DrPopup>>();

const isDynamicDateMode = ref(false);

const handleSubmitDynamicDate = (dynamicStartDate: TaskDynamicDateConf) => {
  emit("update", { dynamic_start_date: dynamicStartDate });
  popupRef.value?.hide();

  insightTrack(RoomRequestsBulkEvent.StartDateDynamic);
};

const handleSubmitStaticDate = (startDate?: Date) => {
  emit("update", {
    start_date: startDate,
    dynamic_start_date: null!,
    is_start_date_dynamic: false,
  });
  popupRef.value?.hide();

  insightTrack(RoomRequestsBulkEvent.StartDateStatic);
};

const show = (reference: HTMLElement) => {
  popupRef.value?.show(reference);
};

defineExpose({ show });
</script>

<style module lang="scss">
.ddContainer {
  padding-bottom: 10px;
}

.actionContainer {
  margin: 0 10px 10px 10px;
}

.setDynamicDateBtn {
  width: 100%;
}
</style>
