<template>
  <span class="user-information truncate">
    <div class="user-information__icon">
      <!-- @vue-expect-error -->
      <UserAvatar :user="user" :size="size" :font-size="avatarFontSize" />
    </div>

    <div class="user-information__detail truncate">
      <DrTruncatedTextTooltip
        :content="user.name || user.email"
        :disabled="!showTooltip"
      >
        <span class="user-information__name" :class="{ 'truncate-ml': mlName }">
          {{ user.name || user.email }}
        </span>
      </DrTruncatedTextTooltip>
    </div>

    <ElButton
      v-if="isInvite"
      type="text"
      size="small"
      :disabled="isInviteResending"
      class="user-information__resend"
      @click="resendInvite"
    >
      {{ isInviteResending ? "Resending" : "Resend" }}
      <i v-if="isInviteResending" class="fa fa-refresh fa-spin" />
    </ElButton>
  </span>
</template>

<script lang="ts">
import axios from "axios";
import { defineComponent } from "vue";
import { DrTruncatedTextTooltip } from "@shared/ui/dr-tooltip";
import { UserInformation } from "@shared/ui/users";

import { $notifyDanger, $notifySuccess } from "@drVue/common";
import UserAvatar from "../user-avatar";

import type { AxiosErrorJSON } from "@drVue/types";
import type { PropType } from "vue";

interface Data {
  isInviteResending: boolean;
}

export default defineComponent({
  name: "UserInvite",
  components: {
    DrTruncatedTextTooltip,
    UserAvatar,
  },
  mixins: [UserInformation],
  props: {
    memberId: { type: Number as PropType<number>, required: true },
  },
  data(): Data {
    return {
      isInviteResending: false,
    };
  },
  methods: {
    async resendInvite(e: MouseEvent) {
      e.preventDefault();
      e.stopPropagation();

      if (this.isInviteResending) return;

      this.isInviteResending = true;
      try {
        await this.$store.dispatch(
          "clientDashboard/members/resendInvite",
          this.memberId,
        );
        $notifySuccess("Invite has been sent");
      } catch (e) {
        let msg = "Failed to resend invite";
        if (axios.isAxiosError(e)) {
          const json = e.toJSON() as AxiosErrorJSON;
          if (json.status === 404) {
            msg = "The invite is stale, please refresh the page";
          }
        }
        $notifyDanger(msg);
      } finally {
        this.isInviteResending = false;
      }
    },
  },
});
</script>
