import { ElMessageBox } from "element-plus";
import { markRaw, type Raw } from "vue";

import { insightTrack, RoomDataroomMenuEvent } from "@app/insight";
import { APP_SETTINGS, ROOM_DATA } from "@app/setups";
import { ROOM_MEMBER_DATA } from "@app/setups/data";
import { AiAccess } from "@app/setups/enums";
import { $notifyDanger, $notifySuccess } from "@app/vue/common";
import { t } from "@app/vue/i18n";
import {
  type Document,
  DocumentsApiService,
  type Folder,
  isDocument,
  isFolder,
} from "@app/vue/store/modules/room/documents/DocumentsApiService";
import { useDocumentsStore } from "@app/vue/store/pinia/room/documents/documents";
import FolderMenuAiDialog from "../components/FolderMenuAiDialog.vue";
import FolderMenuAttachToRequest from "../components/FolderMenuAttachToRequest.vue";
import FolderMenuChangeIndex from "../components/FolderMenuChangeIndex.vue";
import FolderMenuCopy from "../components/FolderMenuCopy.vue";
import FolderMenuMove from "../components/FolderMenuMove.vue";
import FolderMenuNewFolder from "../components/FolderMenuNewFolder.vue";
import FolderMenuRename from "../components/FolderMenuRename.vue";
import {
  type FolderMenuParams,
  getDocumentsCountText,
  getDocumentsSelectedItemsText,
} from "../utils";
import {
  type MenuComponent,
  useFolderMenuPersistedBus,
} from "./useFolderMenuBus";

import type { VxeTableDefines } from "vxe-table";

export type FolderMenuItem = {
  name: string;
  action?:
    | ((value: Folder | Document) => void)
    | ((value: Folder) => void)
    | ((value: Document) => void);
  menuComponent?: Raw<MenuComponent>;
  getIsVisible: (value: Folder | Document) => boolean;
  track?: (value: Folder | Document) => void;
  prefixConfig?: VxeTableDefines.MenuFirstOption["prefixConfig"];
  suffixConfig?: VxeTableDefines.MenuFirstOption["suffixConfig"];
};

const getIsNotRoot = (
  value: Folder | Document,
): value is Folder & Required<Pick<Folder, "parent_id">> =>
  isFolder(value) && value.parent_id !== null;

const api = new DocumentsApiService();

export const useFolderMenu = (
  actions: {
    view?: (file: Document) => void;
    open?: (folder: Folder) => void;
    download?: (value: Document | Folder) => void;
    editCustomData?: (value: Document | Folder) => void;
    expandFolder?: () => void;
    collapseFolder?: () => void;
    activity?: (value: Document) => void;
  } = {},
) => {
  const documentsStore = useDocumentsStore();
  const folderMenuPersistedBus = useFolderMenuPersistedBus();

  const getHasChildren = (value: Folder | Document): boolean =>
    isFolder(value) &&
    (documentsStore.treeRaw?.Folders.some(
      (folder) => folder.parent_id === value.id,
    ) ??
      false);

  const bookmarkItem = async (value: Document | Folder) => {
    await documentsStore.toggleBookmark(value);
  };

  const deleteItem = (value: Document | Folder) => {
    folderMenuPersistedBus.emit("menu:persisted", true);

    const isDocumentValue = isDocument(value);

    documentsStore
      .archiveItems({
        folders: isDocumentValue ? [] : [value],
        documents: isDocumentValue ? [value] : [],
      })
      .finally(() => {
        folderMenuPersistedBus.emit("menu:persisted", false);
      });
  };

  const exportIndexesPdf = async (value: Folder) => {
    await api.exportIndexes({ fmt: "pdf", folder_id: value.id });
  };

  const exportIndexesXlsx = async (value: Folder) => {
    await api.exportIndexes({ fmt: "xlsx", folder_id: value.id });
  };

  const restoreItems = (params: FolderMenuParams) => {
    const selectedItemsText = getDocumentsSelectedItemsText(params);

    return new Promise<void>((resolve) => {
      ElMessageBox({
        title: t("data_room.restore.title", {count_text: getDocumentsCountText(params)}),
        message: t("data_room.restore.message", {selected_items: selectedItemsText}),
        confirmButtonText: t("shared.restore"),
        showCancelButton: true,
        icon: undefined,
        callback: async (action: string) => {
          if (action === "confirm") {
            await api
              .restoreItems({
                document_ids: params.documents.map((item) => item.id),
                folder_ids: params.folders.map((item) => item.id),
              })
              .then((response) => {
                documentsStore.syncTree();

                $notifySuccess(t(
                  "data_room.restore.success",
                  {selected_items: selectedItemsText},
                  params.documents.length + params.folders.length,
                ));

                return response;
              })
              .catch(() => {
                $notifyDanger(t("data_room.restore.failed", {selected_items: selectedItemsText}));
              });
          }

          resolve();
        },
      });
    });
  };

  const deleteItems = (params: FolderMenuParams) => {
    const selectedItemsText = getDocumentsSelectedItemsText(params);

    return new Promise<void>((resolve) => {
      ElMessageBox({
        title: t("data_room.delete_permanently.title", {count_text: getDocumentsCountText(params)}),
        message: t("data_room.delete_permanently.message", {selected_items: selectedItemsText}),
        confirmButtonText: t("shared.delete"),
        customClass: "el-message-box--warning",
        showCancelButton: true,
        icon: undefined,
        callback: async (action: string) => {
          if (action === "confirm") {
            await api
              .deleteItems({
                document_ids: params.documents.map((item) => item.id),
                folder_ids: params.folders.map((item) => item.id),
              })
              .then((response) => {
                $notifySuccess(t(
                  "data_room.delete_permanently.success",
                  {selected_items: selectedItemsText},
                  params.documents.length + params.folders.length,
                ));

                return response;
              })
              .catch(() => {
                $notifyDanger(t("data_room.delete_permanently.failed", {selected_items: selectedItemsText}));
              });
          }

          resolve();
        },
      });
    });
  };

  const folderMenu: FolderMenuItem[][] = [
    [
      {
        name: t("shared.view"),
        action: actions?.view,
        getIsVisible: isDocument,
      },
      {
        name: t("data_room.analyse_with_ai.button"),
        menuComponent: markRaw(FolderMenuAiDialog),
        getIsVisible: (value) =>
          isDocument(value) &&
          value.processing?.is_viewable === true &&
          ROOM_DATA.isAiEnabled &&
          ROOM_MEMBER_DATA.group.ai_access === AiAccess.BASIC,
        track: () => {
          insightTrack(RoomDataroomMenuEvent.AiDialogOpen, { source: "menu" });
        },
      },
      {
        name: t("shared.open"),
        action: actions?.open,
        getIsVisible: isFolder,
      },
      {
        name: t("shared.download"),
        action: actions?.download,
        getIsVisible: (value) =>
          value.download &&
          (isDocument(value) ||
            (ROOM_DATA.enableBulkDownload &&
              ROOM_DATA.userPermissions.administrator)),
      },
      {
        name: t("data_room.bookmark_items.bookmark"),
        action: bookmarkItem,
        getIsVisible: (value) =>
          (getIsNotRoot(value) || isDocument(value)) && !value.bookmarked,
        track: (value) => {
          insightTrack(RoomDataroomMenuEvent.Bookmark, {
            item: isFolder(value) ? "folder" : "document",
          });
        },
      },
      {
        name: t("data_room.bookmark_items.unbookmark"),
        action: bookmarkItem,
        getIsVisible: (value) =>
          (getIsNotRoot(value) || isDocument(value)) && !!value.bookmarked,
        track: (value) => {
          insightTrack(RoomDataroomMenuEvent.Unbookmark, {
            item: isFolder(value) ? "folder" : "document",
          });
        },
      },
      {
        name: t("shared.attach_to_request"),
        menuComponent: markRaw(FolderMenuAttachToRequest),
        getIsVisible: (value) =>
          (getIsNotRoot(value) || isDocument(value)) &&
          ROOM_DATA.userPermissions.viewTasks &&
          ROOM_DATA.userPermissions.canManageTasks,
        track: (value) => {
          insightTrack(RoomDataroomMenuEvent.AttachToRequest, {
            item: isFolder(value) ? "folder" : "document",
          });
        },
      },
    ],
    [
      {
        name: t("data_room.new_folder.name.label"),
        menuComponent: markRaw(FolderMenuNewFolder),
        getIsVisible: (value) => isFolder(value) && value.edit,
        track: (value) => {
          insightTrack(RoomDataroomMenuEvent.NewFolder, {
            item: "folder",
          });
        },
      },
      {
        name: t("shared.rename"),
        menuComponent: markRaw(FolderMenuRename),
        getIsVisible: (value) =>
          (getIsNotRoot(value) || isDocument(value)) && value.edit,
        track: (value) => {
          insightTrack(RoomDataroomMenuEvent.Rename, {
            item: isFolder(value) ? "folder" : "document",
          });
        },
      },
      {
        name: t("data_room.change_index.modal_title"),
        menuComponent: markRaw(FolderMenuChangeIndex),
        getIsVisible: (value) =>
          getIsNotRoot(value)
            ? documentsStore.folderByIdMap[value.parent_id as number].edit
            : value.edit,
        track: (value) => {
          insightTrack(RoomDataroomMenuEvent.IndexChange, {
            item: isFolder(value) ? "folder" : "document",
          });
        },
      },
      {
        name: t("data_room.edit_custom_data.button"),
        action: actions?.editCustomData,
        getIsVisible: (value) => value.edit && APP_SETTINGS.WEBSITE.IS_DEALROOM,
      },
    ],
    [
      {
        name: t("shared.move"),
        menuComponent: markRaw(FolderMenuMove),
        getIsVisible: (value) =>
          (getIsNotRoot(value) || isDocument(value)) && value.edit,
        track: (value) => {
          insightTrack(RoomDataroomMenuEvent.Move, {
            item: isFolder(value) ? "folder" : "document",
          });
        },
      },
      {
        name: t("shared.copy"),
        menuComponent: markRaw(FolderMenuCopy),
        getIsVisible: (value) =>
          (getIsNotRoot(value) || isDocument(value)) && value.edit,
        track: (value) => {
          insightTrack(RoomDataroomMenuEvent.Copy, {
            item: isFolder(value) ? "folder" : "document",
          });
        },
      },
      {
        name: t("shared.delete"),
        action: deleteItem,
        getIsVisible: (value) =>
          (getIsNotRoot(value) || isDocument(value)) && value.edit,
        track: (value) => {
          insightTrack(RoomDataroomMenuEvent.Remove, {
            item: isFolder(value) ? "folder" : "document",
          });
        },
      },
    ],
    [
      {
        name: t("data_room.expand_all_folders"),
        action: actions?.expandFolder,
        getIsVisible: getHasChildren,
      },
      {
        name: t("data_room.collapse_all_folders"),
        action: actions?.collapseFolder,
        getIsVisible: getHasChildren,
      },
      {
        name: t("data_room.export_indexes_to_pdf"),
        action: exportIndexesPdf,
        getIsVisible: getHasChildren,
        track: () => insightTrack(RoomDataroomMenuEvent.ExportIndexPdf),
      },
      {
        name: t("data_room.export_indexes_to_xlsx"),
        action: exportIndexesXlsx,
        getIsVisible: getHasChildren,
        track: () => insightTrack(RoomDataroomMenuEvent.ExportIndexXlsx),
      },
      {
        name: t("data_room.activity"),
        action: actions?.activity,
        getIsVisible: (value) =>
          isDocument(value) && ROOM_DATA.userPermissions.administrator,
      },
    ],
  ];

  folderMenu.forEach((group) => {
    group.forEach((item) => {
      item.prefixConfig = { className: "hidden" };
      item.suffixConfig = { className: "hidden" };
    });
  });

  return {
    folderMenu,
    bookmarkItem,
    exportIndexesPdf,
    exportIndexesXlsx,
    restoreItems,
    deleteItems,
  };
};
