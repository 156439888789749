<template>
  <ElForm
    ref="messageFormRef"
    label-position="top"
    require-asterisk-position="right"
    :model="messageFormModel"
    :rules="formRules"
    :disabled="isDisabled || isFormSubmitting"
    @submit="submit(messageFormRef)"
  >
    <ElFormItem
      :error="formErrors.message"
      prop="message"
      required
      :class="$style.noMarginBottom"
    >
      <ElInput
        ref="messageInputRef"
        v-model="messageFormModel.message"
        type="textarea"
        @keydown.enter.prevent="handleKeydown"
        placeholder="Write your question ..."
      />
    </ElFormItem>

    <div :class="$style.buttons">
      <ElButton @click="$emit('show-templates')">
        <template #icon>
          <DrIcon size="sm" name="bookmark-o" />
        </template>
        Saved questions
      </ElButton>

      <div>
        <ElButton :disabled="isDisabled" @click="clear">Cancel</ElButton>
        <ElButton
          type="primary"
          :loading="isFormSubmitting"
          @click="submit(messageFormRef)"
        >
          Submit
        </ElButton>
      </div>
    </div>

    <ElAlert
      v-if="formErrors.non_field_errors"
      type="error"
      :title="formErrors.non_field_errors"
    />
  </ElForm>
</template>

<script lang="ts" setup>
import { reactive, ref } from "vue";
import { useFormHelper } from "@shared/ui/dr-form";
import { DrIcon } from "@shared/ui/dr-icon";

import { APP_SETTINGS, USER_DATA } from "@app/setups";
import { loadFromLocalStorage } from "@drVue/common";
import { DocVerQaApiService } from "./api";

import type { DocVerQA } from "./types";
import type { ElInput, FormInstance, FormRules } from "element-plus";

interface Props {
  docUid: string;
  isDisabled?: boolean;
}

interface Events {
  (e: "newQA", payload: { qa: DocVerQA }): void;
  (e: "show-templates"): void;
}

const props = defineProps<Props>();
const emit = defineEmits<Events>();

interface MessageFormModel {
  message: string;
}

const isAiDevModeEnabled =
  (loadFromLocalStorage("dr:enable_ai_dev_mode") ?? false) &&
  (APP_SETTINGS.DEBUG || USER_DATA.email.endsWith("@dealroom.net"));

const messageInputRef = ref<InstanceType<typeof ElInput>>();
const messageFormRef = ref<FormInstance>();
const messageFormModel = reactive<MessageFormModel>({
  message: "",
});
const formRules = reactive<FormRules>({
  message: [
    {
      required: true,
      message: "Message is required.",
    },
  ],
});

const api = new DocVerQaApiService();
const { formErrors, hookFormSubmitPromise, isFormSubmitting, resetErrors } =
  useFormHelper<MessageFormModel>();

interface NewQaRequest {
  question: string;
}

const post = async () => {
  const req: NewQaRequest = {
    question: messageFormModel.message,
  };

  return await hookFormSubmitPromise(
    api.newQA(props.docUid, req).then((newQA) => {
      emit("newQA", { qa: newQA });
      clear();
    }),
  );
};

const submit = async (formEl: FormInstance | undefined) => {
  if (!formEl) return;
  await formEl.validate((valid) => {
    if (valid) {
      post();
    }
  });
};

const handleKeydown = (e: Event | KeyboardEvent) => {
  if (e instanceof KeyboardEvent && e.shiftKey) {
    messageFormModel.message += "\n";

    setTimeout(() => {
      if (!messageInputRef.value?.textarea) return;
      const textarea = messageInputRef.value.textarea;

      const isScrollNeeded = textarea.scrollHeight > textarea.clientHeight;
      if (isScrollNeeded) {
        textarea.scrollTop = Number.MAX_SAFE_INTEGER;
      }
    });
  } else {
    submit(messageFormRef.value);
  }
};

const clear = () => {
  resetErrors();
  messageFormRef.value?.resetFields(["message"]);
  messageFormModel.message = "";
};
</script>

<style lang="scss" module>
@use "@app/styles/scss/colors";
@use "@app/styles/scss/spacing";
@use "@app/styles/scss/typography";

.buttons {
  display: flex;
  justify-content: space-between;
  padding-top: spacing.$xs;
  width: 100%;
}

.noMarginBottom {
  margin-bottom: 0 !important;
}
</style>
