<template>
  <div
    :class="{ [$style.container]: true, [$style.container__column]: column }"
    @mouseleave="hover = null"
  >
    <FilePermissionItem
      v-for="permission in filePermissionKeyList"
      :key="permission"
      :permission="permission"
      :enabled="
        hover === null
          ? modelValue[permission]
          : permission <= (modelValue[permission] ? hover - 1 : hover)
      "
      :propagated="propagatedList.includes(permission)"
      :error-message="
        permission === FilePermissionKey.DOWNLOAD_WATERMARKED
          ? errorMessage
          : undefined
      "
      :large="column"
      :disabled="disabled"
      @mouseover="hover = permission"
      @click="
        updateModelValue(modelValue[permission] ? permission - 1 : permission)
      "
    />
  </div>
</template>

<script lang="ts" setup>
import { ref } from "vue";

import {
  FilePermissionKey,
  filePermissionKeyList,
  type FilePermissionsShort,
} from "@app/vue/store/pinia/room/filePermissions/FilePermissionsApi";
import FilePermissionItem from "./FilePermissionItem.vue";

interface Props {
  modelValue: FilePermissionsShort;
  propagatedList: FilePermissionKey[];
  errorMessage?: string;
  column?: boolean;
  disabled: boolean;
}

interface Emit {
  (e: "update:model-value", value: FilePermissionsShort): void;
}

defineProps<Props>();

const emit = defineEmits<Emit>();

const hover = ref<FilePermissionKey | null>(null);

const updateModelValue = (permission: FilePermissionKey): void => {
  const result: FilePermissionsShort = [false, false, false, false];

  for (let key: FilePermissionKey = 0; key <= permission; key++) {
    result[key] = true;
  }

  emit("update:model-value", result);
};
</script>

<style lang="scss" module>
@use "@app/styles/scss/colors";
@use "@app/styles/scss/spacing";

.container {
  display: flex;

  &.container__column {
    flex-direction: column;
  }
}
</style>
