<template>
  <ElForm
    ref="formRef"
    :model="formData"
    :rules="formRules"
    :disabled="isSubmitting"
    label-position="top"
    data-testid="add-user-form"
    @submit="next"
  >
    <ElFormItem
      prop="emails"
      class="el-form-item--label-full-width"
      :class="$style.drAddFrom"
    >
      <template #label="">
        Emails:
        <div :class="$style.addFromGroupLink">
          <AddFromGroupLink v-if="isAddFromGroupVisible" @add="addEmails" />
        </div>
      </template>
      <DrEmailInput
        ref="emailInputRef"
        v-model="formData.emails"
        :users="users"
      />
    </ElFormItem>

    <ElFormItem required>
      <template #label>Select rooms:</template>
      <div :class="$style.searchIpuntContainer">
        <SearchInput
          v-model="roomGroupsStore.filterText"
          :class="$style.searchInput"
        />
        <RoomsGroupsControl />
      </div>
    </ElFormItem>

    <ElFormItem label="Message:" prop="message">
      <ElInput
        v-model="formData.message"
        placeholder="Optional message that will be sent to invitees"
        type="textarea"
      />
    </ElFormItem>

    <div class="text-right">
      <ElButton type="primary" :loading="isSubmitting" @click="next">
        Next
      </ElButton>
    </div>
  </ElForm>

  <ElDialog
    v-model="isInviteDialogVisible"
    :close-on-click-modal="!isInviting && !isChecking"
    :close-on-press-escape="!isInviting && !isChecking"
    :show-close="!isInviting && !isChecking"
  >
    <template #title>
      <div :class="$style.dialogTitle">Review updates</div>
    </template>
    <ElScrollbar :max-height="300">
      <div
        v-for="room in selectedRooms"
        :key="room.id"
        :class="$style.checkResult"
      >
        <div :class="$style.roomTitle">
          <RoomLogo :logo-url="room.logo" :title="room.title" />
          {{ room.title }}
        </div>
        <div v-if="!inviteCheckResults.get(room.id)">
          Check is in progress, please wait...
        </div>
        <InviteCheckStatus
          v-else
          :result="inviteCheckResults.get(room.id)!.response"
        />
      </div>
    </ElScrollbar>
    <template #footer>
      <ElButton
        @click="isInviteDialogVisible = false"
        :disabled="isChecking || isInviting"
      >
        Close
      </ElButton>
      <ElButton
        type="primary"
        :disabled="isChecking || isInviting"
        @click="invite"
      >
        {{
          isChecking
            ? "Checking..."
            : isInviting
              ? "Inviting..."
              : "Invite members"
        }}
      </ElButton>
    </template>
  </ElDialog>
</template>

<script setup lang="ts">
import { ElScrollbar } from "element-plus";
import { differenceWith } from "lodash-es";
import { computed, reactive, ref } from "vue";
import DrEmailInput from "@shared/ui/dr-email-input/DrEmailInput.vue";
import SearchInput from "@shared/ui/search-input";

import { ORG_MEMBER_DATA } from "@setups/data";
import { DashboardPeopleTableEvent, insightTrack } from "@app/insight";
import { pinia } from "@app/vue/store/pinia";
import {
  type InviteRequest,
  type InviteResponse,
  UsersApiService,
} from "@drVue/api-service/client-dashboard/users";
import { $notifyDanger, $notifyInfo, $notifySuccess } from "@drVue/common";
import RoomLogo from "@drVue/components/client-dashboard/rooms/RoomLogo.vue";
import AddFromGroupLink from "@drVue/components/client-dashboard/users/AddUserModal/AddFromGroupLink.vue";
import InviteCheckStatus from "./InviteCheckStatus.vue";
import RoomsGroupsControl from "./RoomsTable/RoomsGroupsControl.vue";
import { useRoomGroupsStore } from "./RoomsTable/store";

import type { Room } from "@drVue/store/modules/client-dashboard/deals/types";
import type { OrgUser } from "@drVue/store/modules/client-dashboard/org-users/types";
import type { Email } from "@shared/ui/dr-email-input/DrEmailInput.vue";
import type { InternalRuleItem } from "async-validator";
import type { ElForm, FormItemRule } from "element-plus";

interface AddUserModalFormData {
  emails: Email[];
  message: string;
}

interface Props {
  rooms: Room[];
  users: OrgUser[];
}

const props = defineProps<Props>();
const emit = defineEmits<{
  (e: "close"): void;
}>();

const formRef = ref<InstanceType<typeof ElForm> | null>(null);
const emailInputRef = ref<InstanceType<typeof DrEmailInput> | null>(null);

const api = new UsersApiService();
const roomGroupsStore = useRoomGroupsStore(pinia);

const isAddFromGroupVisible = !!ORG_MEMBER_DATA?.client.enable_dashboard;
const isSubmitting = ref(false);
const isChecking = ref(false);
const isInviting = ref(false);
const isInviteDialogVisible = ref(false);
const inviteCheckResults = ref<
  Map<number, { groupId: number; response: InviteResponse }>
>(new Map());

const roomById = computed(() =>
  props.rooms.reduce<Record<number, Room>>((acc, room) => {
    acc[room.id] = room;
    return acc;
  }, {}),
);

const selectedRooms = computed(() =>
  Array.from(roomGroupsStore.selectedGroups.keys()).map(
    (roomId) => roomById.value[roomId],
  ),
);

const formData = reactive<AddUserModalFormData>({
  emails: [],
  message: "",
});

type Arrayable<T> = T | T[];

const formRules: Record<string, Arrayable<FormItemRule>> = {
  emails: [
    {
      required: true,
      message: "Please enter at least one email",
      trigger: "change",
    },
    {
      validator: (
        _rule: InternalRuleItem,
        value: Email[],
        callback: (e?: Error) => void,
      ) => {
        if (value.some((e) => !e.isValid)) {
          callback(new Error("Please check email addresses"));
        } else {
          callback();
        }
      },
      trigger: "change",
    },
  ],
  message: [{ max: 2048, message: "Message is too long" }],
};

const addEmails = (emails: string[]) => {
  if (emails.length === 0) $notifyInfo("The group was empty, no one was added");

  // Deduplicate.
  const previousLength = emails.length;
  emails = differenceWith(
    emails,
    formData.emails,
    (left: string, right: Email) => left === right.address,
  );

  if (previousLength !== emails.length) {
    $notifyInfo("Some duplicate emails were filtered out");
  }

  formData.emails = formData.emails.concat(
    emails.map((e) => ({ address: e, isValid: true })),
  );
};

const getInviteRequest = (): InviteRequest => {
  return {
    members: formData.emails.map((e: Email) => ({
      email: e.address,
    })),
    message: formData.message || undefined,
  };
};

const inviteToMultipleRooms = async () => {
  const entries = Array.from(roomGroupsStore.selectedGroups.entries());
  if (entries.length === 0) return;

  isInviteDialogVisible.value = true;
  inviteCheckResults.value = new Map();

  isChecking.value = true;

  const inviteReq = getInviteRequest();
  for (const [roomId, group] of entries) {
    await api.inviteCheck(group.id, inviteReq).then(
      (r) => {
        inviteCheckResults.value.set(roomId, {
          groupId: group.id,
          response: r,
        });
      },
      () => {
        const room = roomById.value[roomId];
        $notifyDanger(
          `Failed to check invite status for ${group.name} group (${room.title} room)`,
        );
      },
    );
  }

  isChecking.value = false;
};

const invite = async () => {
  const inviteReq = getInviteRequest();

  let hasErrors = false;

  const inviteEntries = Array.from(inviteCheckResults.value.entries());

  insightTrack(DashboardPeopleTableEvent.BulkInvite, {
    rooms_count: inviteEntries.length.toString(),
    members_count: inviteReq.members.length.toString(),
  });

  isInviting.value = true;
  for (const [roomId, { groupId, response }] of inviteEntries) {
    if (!response.moved.length && !response.invited.length) continue;

    await api.invite(groupId, inviteReq).catch(() => {
      const room = roomById.value[roomId];

      hasErrors = true;
      $notifyDanger(
        `Failed to invite users to ${response.group_name} group (${room.title} room)`,
      );
    });
  }

  isInviteDialogVisible.value = false;
  emit("close");

  if (hasErrors) {
    $notifyDanger("Failed to invite users to some rooms");
  } else {
    $notifySuccess("Users invited successfully");
  }

  setTimeout(() => {
    isInviting.value = false;
  }, 300);
};

const next = () => {
  // performAddTags is just a method that is being "proxied" in the following order:
  // DrEmailInput -> DrTagsInput -> VueTagsInput.performAddTags(...)
  // The method allows us to call "parsing logic" manually.
  // The internal addTag method is asynchronously updates the DOM.
  // That means that we will validate an empty input if we call it right after performAddTags.
  // That is why we use setTimeout here - to wait for the real DOM updates.
  // Read more: https://stackoverflow.com/a/47636157
  const $emailInput = emailInputRef.value!;
  $emailInput.performAddTags();

  setTimeout(() => {
    formRef.value?.validate((valid: boolean) => {
      if (valid) inviteToMultipleRooms();
    });
  });
};

const resetForm = () => {
  formData.emails = [];
  formData.message = "";

  inviteCheckResults.value = new Map();

  isChecking.value = false;
  isInviting.value = false;

  roomGroupsStore.unselectAll();
};

defineExpose({
  resetForm,
});
</script>

<style lang="scss" module>
@use "@app/styles/scss/colors";
@use "@app/styles/scss/typography" as typo;

.drAddFrom {
  position: relative;

  :deep(.el-button) {
    position: absolute;
    right: 3px;
    top: -6px;
  }
}

.addFromGroupLink {
  position: absolute;
  right: 0;
  top: 0;
}

.checkResult {
  margin-bottom: 15px;
  padding-bottom: 15px;
  border-bottom: 1px solid colors.$pr-200;
}

.roomTitle {
  display: flex;
  align-items: center;
  gap: 10px;
  font-size: 32px;
  font-weight: 500;
  font: typo.$subtitle_semibold;
}

.dialogTitle {
  font: typo.$title_bold;
}

.searchIpuntContainer {
  width: 100%;
}

.searchInput {
  margin-bottom: 15px;
}
</style>
