import { useDocumentsStore } from "@app/vue/store/pinia/room/documents/documents";
import tableHtml from "../table.html?raw";

DocumentsDatagrid.$inject = ["DocumentsService"];

import CELLS from "./cells";

angular
  .module("dealroom.analytics.datagrid-table.documents", [
    "dealroom.analytics.datagrid-table",
    "dealroom.documents",
  ])
  .component("drDocumentsDatagrid", {
    bindings: { cls: "@" },
    template: tableHtml,
    controller: DocumentsDatagrid,
  });

function DocumentsDatagrid(DocumentsService) {
  const $ctrl = this;

  $ctrl.cells = CELLS;
  $ctrl.activityRowCells = ["timestamp", "verb", "user", "group"];

  $ctrl.filterBy = "documents";
  $ctrl.tableUid = "DocumentsDatagrid";
  $ctrl.nestByArray = ["folder", "document"];

  $ctrl.getRootItems = getRootItems;
  $ctrl.getRowFromItem = getRowFromItem;

  $ctrl.verbsForFilter = true; // will use only verbs from CELLS
  $ctrl.subParentFieldName = "folders";
  $ctrl.defaultSort = { key: "order", order: "asc" };

  const documentsStore = useDocumentsStore();

  function getRootItems() {
    if (!documentsStore.treeRaw) return [];
    return documentsStore.rootFolder.items;
  }

  function getRowFromItem(item) {
    if (item.type === "folder") return folderRow(item);
    if (item.type === "file") return docRow(item);
    return console.error("GetRowFromItem: Unknown type", item);

    function docRow(doc) {
      const row = {
        type: "document",
        id: doc.id,
        sortName: doc.name,
        // parentId: doc.folder_id,
      };
      return row;
    }

    function folderRow(folder) {
      const row = {
        type: "folder",
        id: folder.id,
        sortName: folder.name,
      };
      if (folder.items.length > 0) {
        row.getOrderedChilds = function (folder) {
          return folder.items;
        };
      }
      return row;
    }
  }
}
