<template>
  <ElDialog
    v-model="isDialogVisible"
    :title="$t('download_status_dialog.processing_errors.header')"
    width="600px"
    :append-to-body="true"
    @close="hideDialog"
  >
    <div class="errors-dialog">
      <div v-if="!taskErrors">{{ $t("download_status_dialog.processing_errors.empty") }}</div>
      <div v-else>
        <I18nT keypath="download_status_dialog.processing_errors.description.text" tag="p">
          <template #link>
            <a :href="getDownloadUrl(taskId)">{{ $t("download_status_dialog.processing_errors.description.link") }}</a>
          </template>
        </I18nT>
        <div class="errors-dialog__errors">
          <div
            v-for="e in taskErrors"
            :key="e"
            class="errors-dialog__errors-item"
          >
            {{ e }}
          </div>
        </div>
      </div>
    </div>
  </ElDialog>
</template>

<script lang="ts">
import { defineComponent } from "vue";

import { Urls } from "@setups/urls";

import type { Download } from "@drVue/store/modules/room/downloads/DownloadsApiService";

interface Data {
  taskId: string;
  isDialogVisible: boolean;
}

export default defineComponent({
  name: "RoomDownloadDialog",
  data(): Data {
    return {
      taskId: "",
      isDialogVisible: false,
    };
  },
  computed: {
    taskErrors(): any {
      const task = this.$store.state.room.downloads.downloads.find(
        (d: Download) => d.task_id === this.taskId,
      );

      return task ? task.log : null;
    },
  },
  methods: {
    getDownloadUrl(taskId: string) {
      return Urls["api:room:documents_download_bulk_archive"](taskId);
    },
    showDialog(taskId: string) {
      this.taskId = taskId;
      this.isDialogVisible = true;
    },
    hideDialog() {
      this.taskId = "";
      this.isDialogVisible = false;
    },
  },
});
</script>
