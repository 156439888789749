<template>
  <div v-if="isParentRow(row)" :class="$style.roomRow">
    <b class="semibold">{{ row.label }}</b>
    <a @click.prevent="openSeeUpdateLogsPanel(row.batchId, row.id)">{{
      $t("room_updates.see_log")
    }}</a>
  </div>
  <div v-else-if="isChildRow(row)">
    <AddRoomMemberRow
      v-if="row.change.op === 'add_room_member'"
      :change="row.change"
      :is-successful="row.isSuccessful"
    />
    <CreateGroupRow
      v-else-if="row.change.op === 'create_group'"
      :change="row.change"
      :is-successful="row.isSuccessful"
    />
    <CreateTaskRow
      v-else-if="row.change.op === 'create_task'"
      :change="row.change"
      :is-successful="row.isSuccessful"
    />
    <div v-else>ERROR!</div>
  </div>
  <div v-else>ERROR!</div>
</template>

<script setup lang="ts">
import { inject } from "vue";

import AddRoomMemberRow from "./AddRoomMemberRow.vue";
import CreateGroupRow from "./CreateGroupRow.vue";
import CreateTaskRow from "./CreateTaskRow.vue";
import { type ChangeRow, isChildRow, isParentRow } from "./tableColumns";
import { seeUpdateLogsByRoomRefKey } from "./types";

import type { RoomUpdateBatch } from "./api";
import type { Room } from "@app/vue/store/modules/client-dashboard/deals/types";

interface Props {
  row: ChangeRow;
}

defineProps<Props>();

const seeUpdateLogsByRoomPanelRef = inject(seeUpdateLogsByRoomRefKey);

const openSeeUpdateLogsPanel = (
  batchId: RoomUpdateBatch["id"],
  roomId: Room["uid"],
) => {
  seeUpdateLogsByRoomPanelRef?.value?.open(batchId, roomId);
};
</script>

<style module lang="scss">
@use "@app/styles/scss/colors";

.roomRow {
  display: flex;
  justify-content: space-between;
}
</style>
