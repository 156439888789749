<template>
  <ElInput
    v-model="search"
    :placeholder="t('shared.search_dots')"
    clearable
    :class="$style.searchInput"
  />

  <PhaseField />

  <Component
    :is="fieldComp.component"
    v-for="fieldComp in fieldsComponents"
    :key="fieldComp.field.key"
    :field="fieldComp.field"
  />

  <ElButton
    text
    :type="isExpanded ? 'primary' : ''"
    :bg="isExpanded"
    :class="$style.btnAllFilters"
    @click="$emit('expand')"
    data-testid="deal-dashboard-toolbar-all-filters-button"
  >
    <i class="fas fa-filter" />
    <span>{{ t("filters.all_filters") }}</span>
  </ElButton>

  <ElButton
    v-if="hasActiveFilters"
    text
    @click="clearFiltersFields"
    data-testid="deal-dashboard-toolbar-reset-all-button"
  >
    <i class="far fa-redo-alt" />
    <span>{{ t("filters.reset_all") }}</span>
  </ElButton>

  <div :class="$style.total">{{ filteredDealsCount }}</div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { useI18n } from "vue-i18n";

import { FieldItemTypes } from "@drVue/api-service/client-dashboard";
import {
  getCustomFieldWithoutDisabledOptions,
  markDisabledCustomFieldOptions,
} from "@drVue/components/client-dashboard/deals/utils";
import $utils from "@drVue/utils";
import DateField from "./ToolbarElements/DateField.vue";
import NumberField from "./ToolbarElements/NumberField.vue";
import PhaseField from "./ToolbarElements/PhaseField.vue";
import SelectField from "./ToolbarElements/SelectField.vue";
import TextField from "./ToolbarElements/TextField.vue";

import type { FieldItem } from "@drVue/api-service/client-dashboard";
import type { PropType } from "vue";

type FilterFieldComponent =
  | typeof TextField
  | typeof SelectField
  | typeof NumberField
  | typeof DateField;

const COMPONENTS: Record<FieldItemTypes, FilterFieldComponent> = {
  [FieldItemTypes.Text]: TextField,
  [FieldItemTypes.Select]: SelectField,
  [FieldItemTypes.MultiSelect]: SelectField,
  [FieldItemTypes.Number]: NumberField,
  [FieldItemTypes.Date]: DateField,
};

export default defineComponent({
  name: "FilterBtn",
  components: {
    PhaseField,
    TextField,
    SelectField,
    NumberField,
    DateField,
  },
  props: {
    isExpanded: { required: true, type: Boolean as PropType<boolean> },
  },
  emits: ["expand"],
  setup() {
    const { t } = useI18n();
    return { t };
  },
  computed: {
    fieldsComponents(): any {
      const fields = markDisabledCustomFieldOptions(
        this.$store.getters["clientDashboard/customFields/byObjectType"](
          "deal",
        ),
      )
        .slice(0, 3)
        .map(getCustomFieldWithoutDisabledOptions);

      return fields.map((field: FieldItem) => ({
        field,
        component: this.getComponent(field.field_type),
      }));
    },
    filtersCount(): number {
      return this.$store.getters["clientDashboard/filters/dealsFilterActive"];
    },
    hasActiveFilters(): boolean {
      return this.filtersCount > 0;
    },
    btnLabel(): string {
      const count = this.filtersCount;
      if (count) {
        return `${count}`;
      }
      return "";
    },
    search: {
      get: function (): string {
        return this.$store.state.common.customViews.current.filters.search;
      },
      set: function (query: string) {
        this.$store.commit("common/customViews/setCurrentFiltersSearch", query);
      },
    },
    filteredDealsCount(): string {
      const count =
        this.$store.getters["clientDashboard/filteredDealsData"].length;
      /** @todo: add formatting for output as "1,234 rooms" */
      return `${count} ${$utils.text.pluralize("deal", count)}`;
    },
  },
  methods: {
    getComponent(customFieldType: FieldItemTypes): FilterFieldComponent {
      return COMPONENTS[customFieldType];
    },
    clearFiltersFields() {
      this.$store.commit("common/customViews/clearCurrentFiltersFields");
    },
  },
});
</script>

<style lang="scss" module>
@use "@app/styles/scss/colors";
@use "@app/styles/scss/typography" as typo;

.searchInput {
  min-width: 80px;
  max-width: 200px;
  transition: width ease 200ms;
  will-change: width;

  &:focus-within {
    width: 200px;
  }
}

.btnAllFilters {
  --el-color-primary: #{colors.$sc-600};

  :global {
    [class*="el-icon"],
    [class*="fa-"] {
      --el-button-icon-active-color: #{colors.$sc-600};
      --el-button-icon-hover-color: #{colors.$sc-600};
    }
  }
}

.total {
  display: grid;
  grid-auto-flow: column;
  align-items: center;
  gap: 16px;
  font: typo.$body_regular;
  color: colors.$pr-500;
  white-space: nowrap;

  &:before {
    content: "";
    display: inline-block;
    height: 14px;
    border-left: solid 1px colors.$pr-300;
  }
}
</style>
