<template>
  <ElPopover
    ref="popoverRef"
    width="397"
    trigger="click"
    popper-class="el-popover--no-margin"
  >
    <DrDatePicker
      :model-value="fieldValue"
      type="daterange"
      start-placeholder="From"
      end-placeholder="To"
      align="center"
      :teleported="false"
      @update:model-value="handleUpdateValue"
    />

    <template #reference>
      <DrToolbarFilterButton
        :label="field.label"
        :is-active="isActive"
        @clear="fieldValue = undefined"
      />
    </template>
  </ElPopover>
</template>

<script setup lang="ts">
import { ElPopover } from "element-plus";
import { computed, ref } from "vue";
import DrDatePicker from "@shared/ui/dr-datepicker/DrDatepicker.vue";
import { DrToolbarFilterButton } from "@shared/ui/dr-toolbar";

import DrStore from "@drVue/store";

import type { FieldItem } from "@drVue/api-service/client-dashboard";
import type { CustomView } from "@setups/types";

interface Props {
  field: FieldItem;
}

const { field } = defineProps<Props>();

const popoverRef = ref<InstanceType<typeof ElPopover>>();
const isActive = computed(() => !!fieldValue.value);

const fieldValue = computed({
  get() {
    const current: CustomView = DrStore.state.common.customViews.current;
    return current.filters.fields[field.key]?.value;
  },
  set(value: any) {
    DrStore.commit("common/customViews/setCurrentFilterValue", {
      field: field.key,
      value,
      op: "RANGE",
    });
  },
});

const handleUpdateValue = (value: [Date, Date]) => {
  fieldValue.value = value;
  popoverRef.value?.hide();
};
</script>
