<template>
  <DrPopup placement="bottom">
    <DrFormWrapper :width="POPUP_SIZES.form.width">
      <ElFormItem :label="t('table.columns')" :class="$style.columnsHeader">
        <ElScrollbar max-height="300px">
          <TableCustomizeFixed
            v-if="hasFixed"
            :columns="columns"
            @update="$emit('update', $event)"
          />

          <TableCustomize
            v-else
            :columns="columns"
            @update="$emit('update', $event)"
          />
        </ElScrollbar>
      </ElFormItem>

      <div v-if="hasReset" :class="$style.columnsFooter">
        <ElButton text size="small" @click="$emit('reset')">
          <template #icon>
            <DrIcon name="redo" size="sm" />
          </template>
          {{ t("shared.reset") }}
        </ElButton>
      </div>
    </DrFormWrapper>

    <template #reference>
      <div>
        <DrTooltip :content="t('table.adjust_view')">
          <ElButton :class="$style.iconBtn">
            <DrIcon name="columns" />
          </ElButton>
        </DrTooltip>
      </div>
    </template>
  </DrPopup>
</template>

<script setup lang="ts" generic="Data">
import { useI18n } from "vue-i18n";
import { POPUP_SIZES } from "@shared/ui/constants";
import { DrFormWrapper } from "@shared/ui/dr-form";
import { DrIcon } from "@shared/ui/dr-icon";
import { DrPopup } from "@shared/ui/dr-popups";
import TableCustomize from "@shared/ui/table-customize/TableCustomize.vue";
import TableCustomizeFixed from "@shared/ui/table-customize/TableCustomizeFixed.vue";

import DrTooltip from "../dr-tooltip/DrTooltip.vue";

import type { DrVxeTableColumn } from "@drVue/components/types";
import type { CustomViewColumn } from "@setups/types";

interface Props {
  columns: DrVxeTableColumn<Data>[];
  hasFixed?: boolean;
  hasReset?: boolean;
}

interface Emits {
  (e: "update", value: CustomViewColumn[]): void;
  (e: "reset"): void;
}

defineProps<Props>();

defineEmits<Emits>();

const { t } = useI18n();
</script>

<style module lang="scss">
@use "@app/styles/scss/colors";
@use "@app/styles/scss/spacing";
@use "@app/styles/scss/typography" as typo;

.iconBtn:global(.el-button) {
  padding-left: 6px;
  padding-right: 6px;
  margin-left: unset;
  width: 32px;
}

.columnsHeader {
  margin-bottom: 0;

  :global(.el-form-item__label) {
    font: typo.$body_semibold;
    color: colors.$pr-900;
  }
}

.columnsFooter {
  display: flex;
  align-items: center;
  gap: spacing.$xl;

  :global(.el-button) {
    --el-button-text-color: #{colors.$pr-500};
    font: typo.$body_regular;

    &:hover {
      color: var(--el-button-hover-text-color);
    }
  }
}
</style>
