<template>
  <TreeBrowser
    :selected-node-ids="modelValue ? [modelValue] : []"
    :tree="documentsStore.foldersTree"
    :is-loading="loading || isFormSubmitting"
    :disabled="disabled"
    :submit-text="submitText"
    :autofocus="autofocus"
    :default-expanded-keys="documentsStore.foldersTree.map((item) => item.id)"
    :disabled-node-keys="disabledNodeKeys"
    disallow-reset
    @save="$emit('update:model-value', $event[0] as number)"
    @cancel="$emit('cancel')"
    @create-node="createFolder"
  >
    <template #tree-item="{ item, node, localValue }">
      <div
        :class="{
          [$style.title]: true,
          [$style.title_isDisabled]: item.disabled,
          [$style.title_isSelected]: localValue.includes(item.id),
        }"
      >
        <DrIcon
          :name="
            node.expanded && item.children?.length !== 0
              ? 'folder-open'
              : 'folder'
          "
          :class="{
            [$style.iconFolder]: true,
          }"
        />
        {{ item.name }}
      </div>
    </template>
  </TreeBrowser>
</template>

<script lang="ts" setup>
import { useI18n } from "vue-i18n";

import { $notifyDanger, $notifySuccess } from "@app/vue/common";
import { useFormHelper } from "@app/vue/shared/ui/dr-form";
import { DocumentsApiService } from "@app/vue/store/modules/room/documents/DocumentsApiService";
import { useDocumentsStore } from "@app/vue/store/pinia/room/documents/documents";
import { DrIcon } from "@drVue/shared/ui/dr-icon";
import TreeBrowser from "../../tasks/shared/tree-browser/TreeBrowser.vue";

import type { NodeId } from "../../tasks/shared/tree-browser/types";

interface Props {
  modelValue?: number | undefined;
  disabled?: boolean;
  loading?: boolean;
  disabledNodeKeys?: number[];
  submitText?: string;
  autofocus?: boolean | number;
}

interface Emits {
  (e: "update:model-value", value: number | undefined): void;
  (e: "cancel"): void;
  (e: "update:persisted", value: boolean): void;
}

defineProps<Props>();

const emit = defineEmits<Emits>();

const {t} = useI18n()

const documentsStore = useDocumentsStore();

const api = new DocumentsApiService();

const { formErrors, hookFormSubmitPromise, isFormSubmitting } = useFormHelper<{
  name: string;
}>();

const createFolder = (value: { parentId: NodeId; name: string }): void => {
  if (isFormSubmitting.value || typeof value.parentId !== "number") return;

  emit("update:persisted", true);

  hookFormSubmitPromise(
    api.createFolder(value.parentId, value.name),
    t("data_room.new_folder.failed"),
  )
    .then(() => {
      documentsStore.syncTree();

      $notifySuccess(t('data_room.new_folder.success'));
    })
    .catch(() => {
      if (formErrors.value) {
        $notifyDanger(formErrors.value.name ?? t("data_room.new_folder.failed"));
      }
    })
    .finally(() => {
      emit("update:persisted", false);
    });
};
</script>

<style module lang="scss">
@use "@app/styles/scss/colors";

.title {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  &.title_isDisabled {
    color: colors.$pr-400;
    cursor: not-allowed;

    .iconFolder {
      color: colors.$pr-300;
    }
  }

  &.title_isSelected {
    color: colors.$sc-600;
    font-weight: 500;

    .iconFolder {
      color: colors.$sc-600;
    }
  }
}

.iconFolder {
  color: colors.$pr-400;
}
</style>
