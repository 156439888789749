<template>
  <div v-if="hasRequestedTheLink" class="auth-form">
    <div class="auth-form__title">
      <i class="far fa-check-circle color-green" />
      {{ t("auth.check_email") }}
    </div>
    {{ t("auth.password_reset_instructions") }}
    <b>{{ email }}</b>
    <br />
    <br />
    {{ t("auth.password_reset_no_email_received") }}
    <br />
    <br />
    <div class="text-center">
      <RouterLink class="auth-form__forgot" to="/auth">
        <u>{{ t("auth.back_to_login") }}</u>
      </RouterLink>
    </div>
  </div>

  <div v-else class="auth-form">
    <div class="auth-form__title text-center">
      {{ t("auth.password_recovery_title") }}
    </div>

    <ElAlert
      v-if="getBackendError('non_field_errors')"
      class="auth-form__alert"
      :title="getBackendError('non_field_errors')"
      type="error"
      show-icon
      :closable="false"
    />

    <ElForm @submit.prevent="reset">
      <ElFormItem :error="getBackendError('email')">
        <ElInput
          v-model="email"
          :disabled="isSubmitting"
          :placeholder="t('auth.email')"
          size="large"
        >
          <template #prepend>
            <i class="fal fa-envelope" />
          </template>
        </ElInput>
      </ElFormItem>

      <ElButton
        type="primary"
        native-type="submit"
        class="auth-form__submit"
        size="large"
        :loading="isSubmitting"
        :disabled="isSubmitting"
      >
        {{ t("auth.request_reset_link") }}
      </ElButton>

      <div v-if="!isSubmitting" class="text-center">
        <br />
        <RouterLink class="auth-form__forgot" to="/auth">
          <u>{{ t("auth.back_to_login") }}</u>
        </RouterLink>
      </div>
    </ElForm>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { useI18n } from "vue-i18n";
import DrForm from "@shared/ui/dr-form";

import { AuthApiService } from "@drVue/components/auth/AuthApiService";

interface Data {
  api: AuthApiService;
  email: string;
  hasRequestedTheLink: boolean;
}

export default defineComponent({
  name: "Reset",
  extends: DrForm,
  setup() {
    const { t } = useI18n();
    return { t };
  },
  data(): Data {
    return {
      api: new AuthApiService(),
      email: "",
      hasRequestedTheLink: false,
    };
  },
  methods: {
    reset() {
      const resetPromise = this.api.reset({ email: this.email });
      this.submitPromise(resetPromise).then(
        (r) => (this.hasRequestedTheLink = true),
      );
    },
  },
});
</script>
