<template>
  <DrLayoutContent>
    <template #nav>
      <DrViewBar :items="views" @select="selectView" />
    </template>

    <template #toolbar>
      <!-- @vue-expect-error -->
      <UserRoomTasksFilter
        :model-value="toolbarValue"
        :number-of-tasks="filteredTasks.length"
        in-one-line
        @update:filter="handleToolbarFilterUpdate"
        @update:search="handleToolbarSearchUpdate"
      />
    </template>

    <template #toolbar-right>
      <DrPopup>
        <DrFormWrapper :width="POPUP_SIZES.form.width">
          <ElFormItem label="Columns">
            <DrSorter
              :items="sorterItems"
              @update-order="handleColumnsReordering"
              @update-visible="handleColumnsReordering"
            />
          </ElFormItem>
        </DrFormWrapper>

        <template #reference>
          <ElButton icon-right>
            Adjust View
            <template #icon>
              <DrIcon name="columns" size="sm" />
            </template>
          </ElButton>
        </template>
      </DrPopup>

      <ElButton
        type="primary"
        :disabled="exportDisabled"
        @click="exportToExcel"
      >
        Export to Excel
      </ElButton>
    </template>

    <DrOverlayEmpty
      v-if="noDisplayData.active"
      icon="tasks"
      :title="noDisplayData.title"
      :subtitle="noDisplayData.subtitle"
    >
      <template #action>
        <ElButton v-if="noDisplayData.btnClearSearch" @click="searchText = ''">
          Clear search query
        </ElButton>
        <ElButton
          v-if="noDisplayData.btnClearFilters"
          @click="currentFilter = {}"
        >
          Reset filters
        </ElButton>
      </template>
    </DrOverlayEmpty>
    <DrVxeGrid
      :data="searchedTasks"
      :columns="tableColumns"
      :sort-config="tableSortConfig"
      @resizable-change="handleColumnSizeChanged"
      @sort-change="handleColumnSortChanged"
    >
      <template #empty>No requests found</template>
    </DrVxeGrid>

    <DrNewLoader v-show="isLoading" overlay />
  </DrLayoutContent>
</template>

<script lang="ts" setup>
import { cloneDeep } from "lodash-es";
import { intersection } from "lodash-es";
import { isEqual } from "lodash-es";
import { orderBy } from "lodash-es";
import { computed, onBeforeMount, reactive, ref, watch } from "vue";
import { useStore } from "vuex";
import { POPUP_SIZES } from "@shared/ui/constants";
import { DrFormWrapper } from "@shared/ui/dr-form";
import DrIcon from "@shared/ui/dr-icon";
import { DrLayoutContent } from "@shared/ui/dr-layouts";
import { DrNewLoader } from "@shared/ui/dr-loader";
import { DrOverlayEmpty } from "@shared/ui/dr-overlay";
import { DrPopup } from "@shared/ui/dr-popups";
import { DrSorter } from "@shared/ui/dr-sorter";
import { DrViewBar } from "@shared/ui/dr-view-bar";
import DrVxeGrid from "@shared/ui/dr-vxe-grid";

import {
  $notifyDanger,
  isStringContains,
  loadFromLocalStorage,
  saveToLocalStorage,
} from "@drVue/common";
import UserRoomTasksFilter from "@drVue/components/client-dashboard/users/UserDetails/RoomTasks/Filter/UserRoomTasksFilter.vue";
import { useOrgStore } from "@drVue/store/modules/common/organization/store";
import { pinia } from "@drVue/store/pinia";
import { useYourWorkRoomTasksStore } from "@drVue/store/pinia/your-work/room-tasks";
import TableColumns from "./tableColumns";

import type { UserTaskTableColumn } from "./tableColumns";
import type { UserTaskInfo, UserTaskRole, UserTaskTableField } from "./types";
import type { TaskStatus } from "@drVue/store/modules/room/tasks-statuses/types";
import type { CustomViewColumn, CustomViewsFilterFields } from "@setups/types";
import type { SorterItem } from "@shared/ui/dr-sorter";
import type { Ref } from "vue";
import type { VxeTableDefines, VxeTablePropTypes } from "vxe-table";

/** @todo: move to store */
type TaskView = {
  id: string;
  name: string;
  filter: CustomViewsFilterFields<UserTaskInfo, (number | string | boolean)[]>;
};
type ToolbarFilters = {
  priorities: string[];
  rooms: number[];
  statuses: number[];
  roles: string[];
  due_date: number[];
};
type ToolbarValue = ToolbarFilters & { search: string };
type ToolbarFilterUpdatePayload = {
  field: keyof ToolbarFilters;
  value: ToolbarFilters[keyof ToolbarFilters] | Date[];
};
/**
 * Value matching function:
 * "eq"  - exact matching
 * "in"  - the value being tested must be in the list of allowed values
 * "bt"  - the value being tested must be within the specified range
 *         (between start and end value)
 */
type FilterValueMatchingFunction = "eq" | "in" | "bt";

type SortOrder = "asc" | "desc";
type StoredSettingsColumn = CustomViewColumn & {
  sort?: SortOrder;
};
type StoredSettings = Record<UserTaskTableField, StoredSettingsColumn>;

const TASKS_TABLE_STORED_SETTINGS_KEY =
  "dr:your_work_tasks_table_stored_settings";

const $store = useStore();
const $storeOrganization = useOrgStore(pinia);
const $storeYourWork = useYourWorkRoomTasksStore(pinia);

const columnsConfig = new TableColumns();

const generateSettingsColumn = (key: UserTaskTableField) => ({
  field: key,
  width: undefined,
  order: undefined,
  hidden: false,
});

const loadedSettings = loadFromLocalStorage<StoredSettings>(
  TASKS_TABLE_STORED_SETTINGS_KEY,
);
const columnsStoredSettings = reactive<StoredSettings>(
  loadedSettings || {
    key: generateSettingsColumn("key"),
    title: generateSettingsColumn("title"),
    status: generateSettingsColumn("status"),
    priority: generateSettingsColumn("priority"),
    category: generateSettingsColumn("category"),
    due_date: generateSettingsColumn("due_date"),
    room: generateSettingsColumn("room"),
  },
);
const saveStoredSettings = () => {
  saveToLocalStorage(TASKS_TABLE_STORED_SETTINGS_KEY, columnsStoredSettings);
};

const sortTableData = (
  data: UserTaskInfo[],
  field: UserTaskTableField,
  order: SortOrder | null,
) => {
  let list = [...data];

  if (order === "asc" || order === "desc") {
    const lastDateValue =
      new Date(3000, 0, 1).getTime() * (order === "asc" ? 1 : -1);

    switch (field) {
      case "title":
      case "priority":
        list = orderBy(list, [field], [order]);
        break;
      case "key":
        list = orderBy(
          list,
          [(task) => +task.key.replace(/^\D+/g, "")],
          [order],
        );
        break;
      case "due_date":
        list = orderBy(
          list,
          // to sort tasks without "due date" always below
          [(task) => task.due_date?.getTime() ?? lastDateValue],
          [order],
        );
        break;
      case "status":
        list = orderBy(list, ["status.name"], [order]);
        break;
      case "category":
        list = orderBy(list, ["category.url"], [order]);
        break;
      case "room":
        list = orderBy(list, ["room.title"], [order]);
        break;
    }
  }

  return list;
};

const tableSortConfig: VxeTablePropTypes.SortConfig<UserTaskInfo> = {
  defaultSort: {
    field: "due_date",
    order: "asc",
  },
  trigger: "cell",
  sortMethod: ({ data, sortList }) => {
    const sortItem = sortList[0];
    const { field, order } = sortItem;

    return sortTableData(
      data as UserTaskInfo[],
      field as UserTaskTableField,
      // @ts-expect-error: ...
      order,
    );
  },
};
let currentTableSortedField: UserTaskTableField = "due_date";

const columnsItems = computed(() => {
  return columnsConfig.columns
    .map((column, index) => {
      const fieldSettings = columnsStoredSettings[column.field];

      return {
        id: column.field,
        order: fieldSettings?.order ?? index,
        fixed: !!column.fixed,
        visible: fieldSettings ? !fieldSettings.hidden : true,
        title: column.title || "",
      };
    })
    .sort((a, b) => a.order - b.order);
});
const sorterItems = computed(() => {
  return columnsItems.value.filter((c) => !!c.title);
});
const tableColumns = computed(() => {
  return columnsItems.value.map((columnItem) => {
    const column =
      columnsConfig.columns.find(
        (configItem) => configItem.field === columnItem.id,
      ) || {};

    return {
      ...column,
      ...columnItem,
    } as unknown as UserTaskTableColumn;
  });
});

const searchText = ref("");
const currentFilter: Ref<TaskView["filter"]> = ref({});

const tasks = computed<UserTaskInfo[]>(() => {
  return $storeYourWork.list.map((task) => {
    const taskStatus = getStatusById(task.status_id);
    const taskRoom = $storeOrganization.getRoomById(task.room_id);
    const roles: UserTaskRole[] = [];

    if (task.is_assignee_group || task.is_assignee_user) {
      roles.push("assignee");
    }
    if (task.is_follower_user) {
      roles.push("follower");
    }
    if (task.is_reviewer_user) {
      roles.push("reviewer");
    }

    const categoryUrl = `${taskRoom?.url}#/tasks/list/${
      task.categories[task.categories.length - 1]!.id
    }`;

    return {
      id: task.id,
      url: `${taskRoom?.url}#/tasks/${task.key}`,
      title: task.title,
      key: task.key,
      priority: task.priority,
      status: {
        id: task.status_id,
        name: taskStatus?.name || "---",
        color: (taskStatus?.color as `#${string}`) || "open",
        type: taskStatus?.type || "open",
      },
      category: {
        path: task.categories.map((cat) => cat.name),
        url: categoryUrl,
      },
      due_date: task.due_date,
      room: {
        id: task.room_id,
        title: taskRoom?.title || "Unknown room",
        logo: taskRoom?.logo || "",
        url: taskRoom?.url || "",
      },
      roles,
      is_reviewed: task.is_reviewed,
    };
  });
});
const isDataLoading = computed(() => $storeYourWork.isLoading);
watch(
  () => $storeYourWork.isLoadError,
  (error) => {
    if (error) {
      $notifyDanger("Failed to load user tasks information");
    }
  },
);

/** temp mapping */
const mapToolbarFieldToTaskField: Record<
  ToolbarFilterUpdatePayload["field"],
  keyof UserTaskInfo
> = {
  priorities: "priority",
  rooms: "room",
  statuses: "status",
  roles: "roles",
  due_date: "due_date",
};

const mapTaskFieldToMatchingFunction: {
  [k in keyof UserTaskInfo]?: FilterValueMatchingFunction;
} = {
  priority: "eq",
  room: "eq",
  status: "eq",
  roles: "in",
  due_date: "bt",
};

const extractToolbarFieldValueFromFilter = (field: keyof ToolbarFilters) => {
  const taskField = mapToolbarFieldToTaskField[field];
  return currentFilter.value[taskField]?.value ?? [];
};
const toolbarValue = computed(() => {
  return {
    search: searchText.value,
    priorities: extractToolbarFieldValueFromFilter("priorities"),
    rooms: extractToolbarFieldValueFromFilter("rooms"),
    statuses: extractToolbarFieldValueFromFilter("statuses"),
    roles: extractToolbarFieldValueFromFilter("roles"),
    due_date: extractToolbarFieldValueFromFilter("due_date"),
  } as ToolbarValue;
});

const isLoading = computed(() => {
  return (
    isDataLoading.value || $store.state.room.tasksStatuses.isLoading === true
  );
});

const predefinedViews = computed<TaskView[]>(() => {
  const statuses = $store.state.room.tasksStatuses.list as TaskStatus[];

  return [
    {
      id: "todo",
      name: "To do",
      filter: {
        roles: {
          op: "in",
          value: ["assignee"],
        },
        status: {
          op: "eq",
          value: statuses
            .filter((status) => status.type !== "resolved")
            .map((status) => status.id)
            .sort(),
        },
      },
    },
    {
      id: "need_review",
      name: "Need review",
      filter: {
        roles: {
          op: "in",
          value: ["reviewer"],
        },
        is_reviewed: {
          op: "eq",
          value: [false],
        },
      },
    },
    {
      id: "following",
      name: "Following",
      filter: {
        roles: {
          op: "in",
          value: ["follower"],
        },
      },
    },
    {
      id: "done",
      name: "Done",
      filter: {
        roles: {
          op: "in",
          value: ["assignee"],
        },
        status: {
          op: "eq",
          value: statuses
            .filter((status) => status.type === "resolved")
            .map((status) => status.id)
            .sort(),
        },
      },
    },
    {
      id: "all",
      name: "All requests",
      filter: {},
    },
  ];
});

const views = computed(() =>
  predefinedViews.value.map((item) => {
    const filteredTasks = getTasksByFilter(item.filter);

    return {
      ...item,
      counter: isLoading.value ? "" : `${filteredTasks.length}`,
      isActive: isLoading.value ? false : item.id === currentViewId.value,
    };
  }),
);

const currentViewId = computed(() => {
  return (
    predefinedViews.value.find((view) =>
      isEqual(view.filter, currentFilter.value),
    )?.id || ""
  );
});

const filteredTasks = computed(() => getTasksByFilter(currentFilter.value));
const searchedTasks = computed(() => {
  if (searchText.value) {
    const isKeyVisible = !columnsStoredSettings.key.hidden;
    const isTitleVisible = !columnsStoredSettings.title.hidden;

    return filteredTasks.value.filter((task) => {
      if (!isKeyVisible && !isTitleVisible) {
        return true;
      }
      return (
        (isKeyVisible && isStringContains(searchText.value, task.key)) ||
        (isTitleVisible && isStringContains(searchText.value, task.title))
      );
    });
  }

  return filteredTasks.value;
});

const hasActiveFilter = computed(
  () => !!Object.keys(currentFilter.value).length,
);

const noDisplayData = computed(() => {
  const data = {
    active: false,
    title: "",
    subtitle: "",
    btnClearSearch: false,
    btnClearFilters: false,
  };

  if (isLoading.value) {
    return data;
  }

  if (!tasks.value.length) {
    data.active = true;
    data.title = "You’re all done!";
    data.subtitle =
      "Seems like there are no room requests found or no room requests to work on";
    return data;
  }

  if (!searchedTasks.value.length) {
    data.active = true;

    if (hasActiveFilter.value) {
      data.title = "No room requests were found matching your criterias.";
      data.btnClearFilters = true;
      data.btnClearSearch = !!searchText.value;
    } else {
      data.title = `No results for "${searchText.value}".`;
      data.btnClearSearch = true;
    }
  }

  return data;
});

const exportDisabled = computed(
  () => isLoading.value || !searchedTasks.value.length,
);

const getStatusById = $store.getters["room/tasksStatuses/byId"];

const selectView = (id: string) => {
  const view = predefinedViews.value.find((view) => view.id === id);

  if (view) {
    currentFilter.value = cloneDeep(view.filter);
  }
};

const checkStatuses = async () => {
  if (!$store.state.room.tasksStatuses.list.length) {
    await $store.dispatch("room/tasksStatuses/syncAll");
  }
};

/**
 * We assume as a rule that the value in "object" properties lies in the "id" field
 */
const getTasksByFilter = (viewFilter: TaskView["filter"]): UserTaskInfo[] => {
  if (!Object.keys(viewFilter).length) {
    return tasks.value;
  }

  return tasks.value.filter((taskItem) => {
    return Object.entries(viewFilter).every(
      ([field, { op, value: filterFieldValue }]) => {
        let taskFieldValue = taskItem[field as keyof UserTaskInfo];

        if (taskFieldValue instanceof Date) {
          taskFieldValue = taskFieldValue.getTime();
        }

        if (
          taskFieldValue &&
          typeof taskFieldValue === "object" &&
          "id" in taskFieldValue
        ) {
          taskFieldValue = taskFieldValue.id;
        }

        if (Array.isArray(taskFieldValue)) {
          if (op === "in") {
            return !!intersection(taskFieldValue, filterFieldValue).length;
          }
          return (
            intersection(taskFieldValue, filterFieldValue).length ===
            filterFieldValue.length
          );
        } else {
          if (op === "bt") {
            if (typeof taskFieldValue === "number") {
              return (
                taskFieldValue >= (filterFieldValue[0] as number) &&
                taskFieldValue <= (filterFieldValue[1] as number)
              );
            }
            return false;
          }

          /** just for type correctness, there shouldn't be such a case */
          if (typeof taskFieldValue === "object") {
            return false;
          }

          return filterFieldValue.includes(taskFieldValue);
        }
      },
    );
  });
};

const handleToolbarSearchUpdate = (searchValue: string) => {
  searchText.value = searchValue;
};

const handleToolbarFilterUpdate = (fieldValue: ToolbarFilterUpdatePayload) => {
  const taskField = mapToolbarFieldToTaskField[fieldValue.field];

  if (!fieldValue.value.length && currentFilter.value[taskField]) {
    delete currentFilter.value[taskField];
    return;
  }

  const parsedFieldValue = fieldValue.value.map((valueItem) => {
    if (valueItem instanceof Date) {
      return valueItem.getTime();
    }
    return valueItem;
  });

  currentFilter.value[taskField] = {
    op: mapTaskFieldToMatchingFunction[taskField] || "eq",
    value: parsedFieldValue,
  };
};

const handleColumnsReordering = (updatedColumns: SorterItem[]) => {
  updatedColumns.forEach((column) => {
    columnsStoredSettings[column.id as UserTaskTableField].order = column.order;
    columnsStoredSettings[column.id as UserTaskTableField].hidden =
      !column.visible;
  });

  saveStoredSettings();
};
const handleColumnSortChanged = (
  params: VxeTableDefines.SortChangeEventParams,
) => {
  columnsStoredSettings[params.field as UserTaskTableField].sort =
    params.order || undefined;

  currentTableSortedField = params.field as UserTaskTableField;

  saveStoredSettings();
};
const handleColumnSizeChanged = (
  params: VxeTableDefines.ResizableChangeEventParams,
) => {
  columnsStoredSettings[params.column.field as UserTaskTableField].width =
    params.resizeWidth;

  saveStoredSettings();
};

const exportToExcel = async () => {
  const sortedData = sortTableData(
    searchedTasks.value,
    currentTableSortedField,
    columnsStoredSettings[currentTableSortedField].sort ?? null,
  );
  const tasksIds = sortedData.map((t) => t.id);
  $storeYourWork.exportToExcel(tasksIds);
};

onBeforeMount(async () => {
  await checkStatuses();
  selectView("todo");
  await $storeOrganization.getRooms();
  $storeYourWork.load();
});
</script>
