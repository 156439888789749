<template>
  <DrLayoutContent>
    <template #nav>
      <DrViewBar :items="views" @select="selectView" />
    </template>

    <template #toolbar>
      <YourWorkFindingsToolbarFilters
        :search="toolbarSearch"
        :filters="toolbarFilters"
        :count="findingsSearched.length"
        @update:filter="handleToolbarFilterUpdate"
        @update:search="handleToolbarSearchUpdate"
      >
        <template #append>
          <ElButton
            icon-right
            :disabled="!hasActiveFilter"
            :class="$style.textBtn"
            @click="currentViewFilter = {}"
          >
            Reset all
            <template #icon>
              <DrIcon name="redo" size="sm" />
            </template>
          </ElButton>
        </template>
      </YourWorkFindingsToolbarFilters>
    </template>

    <template #toolbar-right>
      <DrPopup>
        <div :class="$style.columnsHeader">
          <span>{{ t("table.columns") }}</span>
          <ElButton text size="small" @click="handleColumnConfigReset">
            <template #icon>
              <DrIcon name="redo" size="sm" />
            </template>
            {{ t("shared.reset") }}
          </ElButton>
        </div>

        <DrFormWrapper :width="POPUP_SIZES.form.width">
          <ElScrollbar max-height="400px">
            <TableCustomizeFixed
              :columns="tableColumns"
              @update="handleColumnConfigCustomize"
            />
          </ElScrollbar>
        </DrFormWrapper>

        <template #reference>
          <ElButton icon-right>
            {{ t("table.adjust_view") }}
            <template #icon>
              <DrIcon name="columns" size="sm" />
            </template>
          </ElButton>
        </template>
      </DrPopup>
    </template>

    <template #default>
      <DrOverlayEmpty
        v-if="noDisplayData.active"
        icon="tasks"
        :title="noDisplayData.title"
      >
        <template #action>
          <ElButton
            v-if="noDisplayData.btnClearSearch"
            @click="toolbarSearch = ''"
          >
            Clear search query
          </ElButton>
          <ElButton
            v-if="noDisplayData.btnClearFilters"
            @click="currentViewFilter = {}"
          >
            Reset filters
          </ElButton>
        </template>
      </DrOverlayEmpty>

      <DrVxeGrid
        :row-config="{
          useKey: true,
          keyField: 'id',
          height: 38,
        }"
        :columns="tableColumns"
        :data="findingsSearched"
        :sort-config="tableSortConfig"
        @resizable-change="handleColumnSizeChanged"
        @sort-change="handleColumnSortChanged"
      >
        <template #empty>{{ "" }}</template>
      </DrVxeGrid>

      <DrNewLoader v-show="isLoading" overlay />
    </template>
  </DrLayoutContent>
</template>

<script lang="ts" setup>
import { cloneDeep } from "lodash-es";
import { intersection } from "lodash-es";
import { isEqual } from "lodash-es";
import { orderBy } from "lodash-es";
import { computed, ref, watch } from "vue";
import { useI18n } from "vue-i18n";
import { POPUP_SIZES } from "@shared/ui/constants";
import { DrFormWrapper } from "@shared/ui/dr-form";
import { DrIcon } from "@shared/ui/dr-icon";
import { DrLayoutContent } from "@shared/ui/dr-layouts";
import { DrNewLoader } from "@shared/ui/dr-loader";
import { DrOverlayEmpty } from "@shared/ui/dr-overlay";
import { DrPopup } from "@shared/ui/dr-popups";
import { DrViewBar } from "@shared/ui/dr-view-bar";
import DrVxeGrid from "@shared/ui/dr-vxe-grid";
import TableCustomizeFixed from "@shared/ui/table-customize/TableCustomizeFixed.vue";
import { useStorage } from "@vueuse/core";

import { ORG_MEMBER_DATA } from "@setups/data";
import { Document, flexSearchAllResultsArray } from "@app/flex";
import { FindingLikelihoodDict } from "@drVue/api-service/modules/findings/types";
import { FindingSeverityDict } from "@drVue/api-service/modules/findings/types";
import { $notifyDanger } from "@drVue/common";
import { useOrgStore } from "@drVue/store/modules/common/organization/store";
import { pinia } from "@drVue/store/pinia";
import { useFindingsStatusesStore } from "@drVue/store/pinia/pipeline/findings-statuses";
import { useFindingsTypesStore } from "@drVue/store/pinia/pipeline/findings-types";
import { useYourWorkFindingsStore } from "@drVue/store/pinia/your-work/room-findings";
import TableColumns from "./tableColumns";
import { mapYourWorkFindingsFieldToMatchingFunction } from "./types";
import { ParticipantsType } from "./types";
import YourWorkFindingsToolbarFilters from "./YourWorkFindingsToolbarFilters.vue";

import type {
  YourWorkFindingsCustomViewsFilters,
  YourWorkFindingsFilters,
  YourWorkFindingsTableField,
  YourWorkFindingsTableRow,
  YourWorkFindingsToolbarFiltersUpdate,
} from "./types";
import type { CustomViewColumn } from "@setups/types";
import type { Ref } from "vue";
import type { VxeTableDefines, VxeTablePropTypes } from "vxe-table";

type FindingsTableView = {
  id: string;
  name: string;
  filter: YourWorkFindingsCustomViewsFilters;
};
type SortOrder = "asc" | "desc";
type StoredSettingsColumn = CustomViewColumn & {
  sort?: SortOrder;
};
type StoredSettings = Record<YourWorkFindingsTableField, StoredSettingsColumn>;

const { t } = useI18n();

const generateSettingsColumn = (key: YourWorkFindingsTableField) => ({
  field: key,
  width: undefined,
  order: undefined,
  hidden: false,
});

const UserId = ORG_MEMBER_DATA.user_id;
const STORED_COLUMNS_SETTINGS_KEY =
  "dr:your_work_findings_table_stored_columns_settings";
const STORED_COLUMNS_CURRENT_SORTED_KEY =
  "dr:your_work_findings_table_stored_sorted_field";
const STORED_SELECTED_VIEW_KEY = "dr:your_work_findings_stored_view_key";

const getEmptyColumnsSettings = () => {
  return {
    key: generateSettingsColumn("key"),
    type_id: generateSettingsColumn("type_id"),
    title: generateSettingsColumn("title"),
    status_id: generateSettingsColumn("status_id"),
    severity: generateSettingsColumn("severity"),
    likelihood: generateSettingsColumn("likelihood"),
    room: generateSettingsColumn("room"),
  };
};

const columnsStoredSettings = useStorage<StoredSettings>(
  STORED_COLUMNS_SETTINGS_KEY,
  getEmptyColumnsSettings(),
);
const currentTableSortedField = useStorage<YourWorkFindingsTableField>(
  STORED_COLUMNS_CURRENT_SORTED_KEY,
  "key",
);

const storeYourWorkFindings = useYourWorkFindingsStore(pinia);
const storeOrganization = useOrgStore(pinia);
const findingsStatusesStore = useFindingsStatusesStore(pinia);
const findingsTypesStore = useFindingsTypesStore(pinia);

const toolbarSearch = ref("");

const toolbarFilters = computed(() => {
  return {
    room_id: extractToolbarFieldValueFromFilter("room_id"),
    type_id: extractToolbarFieldValueFromFilter("type_id"),
    severity: extractToolbarFieldValueFromFilter("severity"),
    likelihood: extractToolbarFieldValueFromFilter("likelihood"),
    status_id: extractToolbarFieldValueFromFilter("status_id"),
    roles: extractToolbarFieldValueFromFilter("roles"),
  } as YourWorkFindingsFilters;
});

const findingsData = computed<YourWorkFindingsTableRow[]>(() => {
  return storeYourWorkFindings.list.map((finding) => {
    const roomData = storeOrganization.getRoomByUid(finding.room_id);
    const room = roomData
      ? roomData
      : { title: "unknown room", url: "", logo: "" };

    const roles: ParticipantsType[] = [];

    if (finding.assignees.find((item) => item.user_id === UserId)) {
      roles.push(ParticipantsType.Assignee);
    }
    if (finding.followers.find((item) => item.user_id === UserId)) {
      roles.push(ParticipantsType.Follower);
    }
    if (finding.added_by_id === UserId) {
      roles.push(ParticipantsType.Owner);
    }

    return {
      ...finding,
      room,
      roles,
    };
  });
});

const findingsDataMap = computed(() => {
  return findingsData.value.reduce(
    (acc, finding) => {
      acc[finding.id] = finding;
      return acc;
    },
    {} as Record<YourWorkFindingsTableRow["id"], YourWorkFindingsTableRow>,
  );
});

const findingsFiltered = computed(() => {
  return getFindingsByFilter(currentViewFilter.value);
});

const findingsSearched = computed(() => {
  const isTitleVisible = !columnsStoredSettings.value.title.hidden;

  if (toolbarSearch.value && isTitleVisible) {
    const flexIndex = new Document({
      document: {
        id: "id",
        index: [
          {
            field: "title",
            charset: "latin:advanced",
            tokenize: "full",
          },
          {
            field: "room:title",
            charset: "latin:advanced",
            tokenize: "full",
          },
        ],
      },
    });

    findingsFiltered.value.forEach((finding) => flexIndex.add(finding));

    const foundSortedIds = flexSearchAllResultsArray(
      flexIndex,
      toolbarSearch.value,
    );

    return foundSortedIds.map((id) => findingsDataMap.value[id]);
  }

  return findingsFiltered.value;
});

const getFindingsByFilter = (
  viewFilter: FindingsTableView["filter"],
): YourWorkFindingsTableRow[] => {
  if (!Object.keys(viewFilter).length) {
    return findingsData.value;
  }

  return findingsData.value.filter((item) => {
    return Object.entries(viewFilter).every(
      ([field, { op, value: filterFieldValue }]) => {
        let findingFieldValue: any =
          item[field as keyof YourWorkFindingsTableRow];

        if (findingFieldValue instanceof Date) {
          findingFieldValue = findingFieldValue.getTime();
        }

        if (
          findingFieldValue &&
          typeof findingFieldValue === "object" &&
          "id" in findingFieldValue
        ) {
          findingFieldValue = findingFieldValue.id;
        }

        if (Array.isArray(findingFieldValue)) {
          if (op === "in") {
            return !!intersection(findingFieldValue, filterFieldValue).length;
          }
          return (
            intersection(findingFieldValue, filterFieldValue).length ===
            filterFieldValue.length
          );
        } else {
          if (op === "bt") {
            if (
              typeof findingFieldValue === "number" &&
              typeof filterFieldValue[0] === "number" &&
              typeof filterFieldValue[1] === "number"
            ) {
              return (
                findingFieldValue >= filterFieldValue[0] &&
                findingFieldValue <= filterFieldValue[1]
              );
            }
            return false;
          }

          /** just for type correctness, there shouldn't be such a case */
          if (typeof findingFieldValue === "object") {
            return false;
          }

          return (filterFieldValue as string[]).includes(findingFieldValue);
        }
      },
    );
  });
};

const extractToolbarFieldValueFromFilter = (
  field: keyof YourWorkFindingsFilters,
) => {
  return currentViewFilter.value[field]?.value ?? [];
};

const handleToolbarSearchUpdate = (searchValue: string) => {
  toolbarSearch.value = searchValue;
};

const handleToolbarFilterUpdate = (
  payload: YourWorkFindingsToolbarFiltersUpdate,
) => {
  if (!payload.value.length && currentViewFilter.value[payload.field]) {
    delete currentViewFilter.value[payload.field];
    return;
  }

  currentViewFilter.value[payload.field] = {
    op: mapYourWorkFindingsFieldToMatchingFunction[payload.field] || "eq",
    value: payload.value,
  };
};

const statusesToDo = computed(() => {
  return findingsStatusesStore.list
    .filter((status) => status.type !== "closed")
    .map((status) => status.id)
    .sort();
});
const statusesClosed = computed(() => {
  return findingsStatusesStore.list
    .filter((status) => status.type === "closed")
    .map((status) => status.id)
    .sort();
});

const predefinedViews = computed<FindingsTableView[]>(() => [
  {
    id: "todo",
    name: "To do",
    filter: {
      status_id: {
        op: "in",
        value: statusesToDo.value,
      },
    },
  },
  {
    id: "closed",
    name: "Closed",
    filter: {
      status_id: {
        op: "in",
        value: statusesClosed.value,
      },
    },
  },
  {
    id: "identified",
    name: "My Submissions",
    filter: {
      roles: {
        op: "eq",
        value: [ParticipantsType.Owner],
      },
    },
  },
  {
    id: "all",
    name: "All findings",
    filter: {},
  },
]);

const currentViewFilter: Ref<FindingsTableView["filter"]> = ref({});

const currentSelectedView = useStorage<FindingsTableView["id"]>(
  STORED_SELECTED_VIEW_KEY,
  "todo",
);
const currentViewId = computed(() => {
  return (
    predefinedViews.value.find((view) =>
      isEqual(view.filter, currentViewFilter.value),
    )?.id || ""
  );
});

const isLoading = computed(() => storeYourWorkFindings.isLoading);

const views = computed(() =>
  predefinedViews.value.map((item) => {
    const filteredItems = getFindingsByFilter(item.filter);

    return {
      ...item,
      counter: isLoading.value ? "" : `${filteredItems.length}`,
      isActive: isLoading.value ? false : item.id === currentViewId.value,
    };
  }),
);

const selectView = (id: FindingsTableView["id"]) => {
  const view = predefinedViews.value.find((view) => view.id === id);

  if (view) {
    currentViewFilter.value = cloneDeep(view.filter);
    currentSelectedView.value = view.id;
  }
};

const hasActiveFilter = computed(
  () => !!Object.keys(currentViewFilter.value).length,
);

const noDisplayData = computed(() => {
  const data = {
    active: false,
    title: "",
    btnClearSearch: false,
    btnClearFilters: false,
  };

  if (isLoading.value) {
    return data;
  }

  if (!findingsData.value.length) {
    data.active = true;
    data.title = "No findings were found.";
    return data;
  }

  if (!findingsSearched.value.length) {
    data.active = true;

    if (hasActiveFilter.value) {
      data.title = "No findings were found matching your criterias.";
      data.btnClearFilters = true;
      data.btnClearSearch = !!toolbarSearch.value;
    } else {
      data.title = `No results for "${toolbarSearch.value}".`;
      data.btnClearSearch = true;
    }
  }

  return data;
});

const sortTableData = (
  data: YourWorkFindingsTableRow[],
  field: YourWorkFindingsTableField,
  order: SortOrder | null,
) => {
  let list = [...data];

  if (order === "asc" || order === "desc") {
    // to sort finding "string" field without value always below
    const lastStringValue = order === "asc" ? "zzzz" : "AAAA";

    switch (field) {
      case "key":
      case "title":
        list = orderBy(list, [field], [order]);
        break;

      case "type_id":
        list = orderBy(
          list,
          [
            (finding) =>
              findingsTypesStore.dict[finding.type_id]?.name ?? lastStringValue,
          ],
          [order],
        );
        break;

      case "status_id":
        list = orderBy(
          list,
          [
            (finding) =>
              findingsStatusesStore.dict[finding.status_id]?.name ??
              lastStringValue,
          ],
          [order],
        );
        break;

      case "severity":
        list = orderBy(
          list,
          [
            (finding) =>
              FindingSeverityDict[finding.severity || ""]?.label ??
              lastStringValue,
          ],
          [order],
        );
        break;

      case "likelihood":
        list = orderBy(
          list,
          [
            (finding) =>
              FindingLikelihoodDict[finding.likelihood || ""]?.label ??
              lastStringValue,
          ],
          [order],
        );
        break;

      case "room":
        list = orderBy(list, [(finding) => finding.room.title], [order]);
        break;
    }
  }

  return list;
};

const tableSortConfig = computed<
  VxeTablePropTypes.SortConfig<YourWorkFindingsTableRow>
>(() => ({
  defaultSort: {
    field: currentTableSortedField.value,
    order:
      columnsStoredSettings.value[currentTableSortedField.value].sort ?? "asc",
  },
  trigger: "cell",
  sortMethod: ({ data, sortList }) => {
    const sortItem = sortList[0];
    const { field, order } = sortItem;

    return sortTableData(
      data as YourWorkFindingsTableRow[],
      field as YourWorkFindingsTableField,
      order || null,
    );
  },
}));

const tableColumns = computed(
  () => new TableColumns(columnsStoredSettings.value).columns,
);

const handleColumnSortChanged = (
  params: VxeTableDefines.SortChangeEventParams,
) => {
  columnsStoredSettings.value[params.field as YourWorkFindingsTableField].sort =
    params.order || undefined;

  currentTableSortedField.value = params.field as YourWorkFindingsTableField;
};

const handleColumnSizeChanged = (
  params: VxeTableDefines.ResizableChangeEventParams,
) => {
  columnsStoredSettings.value[
    params.column.field as YourWorkFindingsTableField
  ].width = params.resizeWidth;
};

const handleColumnConfigReset = () => {
  columnsStoredSettings.value = getEmptyColumnsSettings();
  currentTableSortedField.value = "key";
};

const handleColumnConfigCustomize = (updates: CustomViewColumn[]) => {
  updates.forEach((column) => {
    const field = column.field as YourWorkFindingsTableField;
    const data = columnsStoredSettings.value[field];
    columnsStoredSettings.value[field] = {
      ...data,
      ...column,
    };
  });
};

watch(
  () => storeYourWorkFindings.isLoadError,
  (error) => {
    if (error) {
      $notifyDanger("Failed to load findings information.");
    }
  },
);

(async () => {
  await Promise.all([
    storeYourWorkFindings.load(),
    findingsStatusesStore.load(),
    findingsTypesStore.load(),
  ]);
  selectView(currentSelectedView.value);
})();
</script>

<style lang="scss" module>
@use "@app/styles/scss/colors";
@use "@app/styles/scss/spacing";
@use "@app/styles/scss/typography" as typo;

.textBtn:global(.el-button) {
  --el-button-bg-color: transparent;
  --el-button-border-color: transparent;
  --el-button-disabled-bg-color: transparent;
  --el-button-disabled-border-color: transparent;
}

.columnsHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: spacing.$s;
  font: typo.$body_semibold;
  color: colors.$pr-900;

  :global(.el-button) {
    --el-button-text-color: #{colors.$pr-500};
    font: typo.$body_regular;

    &:hover {
      color: var(--el-button-hover-text-color);
    }
  }
}
</style>
