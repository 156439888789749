import "./common/password-validator";

import SynergiesPage from "@drVue/components/room/synergies/Synergies.vue";
import SynergiesSettingsPage from "@drVue/components/room/synergies/SynergiesSettingsPage.vue";
import { useDocumentsStore } from "@drVue/store/pinia/room/documents/documents";
import templates_confirmDialogHtml from "./templates/confirm-dialog.html?raw";
import settings_roomArchiveModalHtml from "./templates/settings/room.archive-modal.html?raw";
import settings_roomRemoveModalHtml from "./templates/settings/room.remove-modal.html?raw";
import settings_roomStorageModalHtml from "./templates/settings/room.storage-modal.html?raw";
import settings_taskListKeysHtml from "./templates/settings/task-list-keys.html?raw";

(function () {
  "use strict";
  TaskListKeyModalController.$inject = [
    "$uibModalInstance",
    "$http",
    "URLS",
    "RoomConfig",
    "AlertService",
    "CategoriesService",
    "RoomDataService",
  ];
  RoomSettingsController.$inject = [
    "$scope",
    "$uibModal",
    "$q",
    "$window",
    "SettingsAlertServices",
    "RoomApiService",
    "RaiseServerValidationErrors",
    "RoomConfig",
  ];
  SettingsController.$inject = [
    "$scope",
    "$uibModal",
    "$window",
    "$state",
    "$location",
    "$http",
    "URLS",
    "AlertService",
    "RoomConfig",
    "SettingsAlertServices",
    "RoomApiService",
    "ORG_MEMBER_DATA",
  ];
  RoomTemplateController.$inject = [
    "RoomTemplateService",
    "TasksService",
    "CategoriesService",
    "RoomConfig",
    "drLabelKey",
  ];
  SlackSettingsController.$inject = [
    "URLS",
    "$http",
    "$uibModal",
    "AlertService",
  ];
  SettingsAlertServices.$inject = ["AlertService"];
  angular
    .module("dealroom.settings", [
      "ngMessages",
      "ngFileUpload",
      "angular.filter",
      "ui.router",
      "ui.bootstrap",
      "monospaced.elastic",
      "dealroom.common",
      "dealroom.common.password",
      "dealroom.config",
      "dealroom.members",
      "dealroom.billing",
    ])
    .factory("SettingsAlertServices", SettingsAlertServices)
    .controller("SlackSettingsController", SlackSettingsController)
    .controller("RoomTemplateController", RoomTemplateController)
    .controller("SettingsController", SettingsController)
    .controller("RoomSettingsController", RoomSettingsController)
    .directive("choiceSelect", choiceSelect)
    .value("VueSynergiesPage", SynergiesPage)
    .value("VueSynergiesSettingsPage", SynergiesSettingsPage);

  function SettingsController(
    $scope,
    $uibModal,
    $window,
    $state,
    $location,
    $http,
    URLS,
    AlertService,
    RoomConfig,
    SettingsAlertServices,
    RoomApiService,
    ORG_MEMBER_DATA,
  ) {
    if ($state.current.name == "settings") {
      $state.go("settings.room");
    }

    if ($location.search().new_email) {
      if ($location.search().key_experid) {
        SettingsAlertServices.warning("email_key_experid");
      } else {
        SettingsAlertServices.success("email_confirm");
      }
      $location.search({}); // clear search data, to prevent showing this message again after page reloading
    }

    $scope.$state = $state;
    $scope.RoomConfig = RoomConfig;

    $scope.openReindexModal = function () {
      var modalOptions = {
        headerText: "Reset Indexes",
        actionButtonText: "Reset",
        bodyText: "Are you sure you want to reset room indexes?",
      };

      $uibModal.open({
        template: templates_confirmDialogHtml,
        controllerAs: "ctrl",
        controller: [
          "$scope",
          "$uibModalInstance",
          function ($scope, $uibModalInstance) {
            this.modalOptions = modalOptions;
            this.submit = function () {
              RoomApiService.reindex().then(
                function () {
                  SettingsAlertServices.success("reindex");
                },
                function () {
                  SettingsAlertServices.danger("reindex");
                },
              );

              $uibModalInstance.dismiss();
            };
          },
        ],
      });
    };

    function getRedirectLocationOnRoomClose() {
      const host = location.host.split(".").splice(1).join(".");
      if (ORG_MEMBER_DATA.client?.enable_dashboard) {
        return `//${host}/dashboard/${ORG_MEMBER_DATA.client.id}`;
      } else {
        return `//${host}${URLS["account"]()}`;
      }
    }

    $scope.openArchiveModal = function () {
      var modalInstance = $uibModal.open({
        template: settings_roomArchiveModalHtml,
        controller: [
          "$scope",
          "RoomConfig",
          "PaymentTypes",
          function ($scope, RoomConfig, PaymentTypes) {
            $scope.RoomConfig = RoomConfig;
            $scope.PaymentTypes = PaymentTypes;
            $scope.archive = function () {
              $scope.$close();
            };
          },
        ],
      });
      modalInstance.result.then(function successCallback() {
        RoomApiService.update({ room: { is_archived: true } }).then(
          function successCallback() {
            $window.location.href = getRedirectLocationOnRoomClose();
          },
          function errorCallback(response) {
            SettingsAlertServices.danger("close");
          },
        );
      });
    };

    $scope.openRemoveModal = function () {
      var modalInstance = $uibModal.open({
        template: settings_roomRemoveModalHtml,
        controller: [
          "$scope",
          "RoomConfig",
          "PaymentTypes",
          function ($scope, RoomConfig, PaymentTypes) {
            $scope.RoomConfig = RoomConfig;
            $scope.PaymentTypes = PaymentTypes;
            var title = RoomConfig.title;
            $scope.wrongtitle = false;
            $scope.removeTitle = "";

            $scope.remove = function () {
              $scope.form.$setPristine();
              if ($scope.removeTitle !== title) {
                $scope.wrongtitle = true;
                return;
              }
              $scope.$close();
            };
          },
        ],
      });
      modalInstance.result.then(function successCallback() {
        const successText = `Room ${$scope.RoomConfig.title} has been deleted`;
        RoomApiService.delete().then(
          function successCallback() {
            $window.location.href = getRedirectLocationOnRoomClose();
            AlertService.success(successText);
          },
          function errorCallback(response) {
            SettingsAlertServices.danger("delete");
          },
        );
      });
    };

    $scope.openTaskListKeyModal = function () {
      $uibModal.open({
        template: settings_taskListKeysHtml,
        controllerAs: "$ctrl",
        controller: TaskListKeyModalController,
        windowClass: "toggle-list-keys-modal",
      });
    };

    $scope.openStorageModal = function () {
      var modalInstance = $uibModal.open({
        template: settings_roomStorageModalHtml,
        controller: [
          "$scope",
          "$uibModalInstance",
          "RoomApiService",
          "AlertService",
          function ($scope, $uibModalInstance, RoomApiService, AlertService) {
            RoomApiService.get_storage().then(
              function successCallback(response) {
                $scope.storage = response.data;
              },
              function errorCallback() {
                $uibModalInstance.close();
                AlertService.danger(
                  "Failed to get used storage from the server.",
                );
              },
            );
          },
        ],
      });
    };
  }

  function RoomSettingsController(
    $scope,
    $uibModal,
    $q,
    $window,
    SettingsAlertServices,
    RoomApiService,
    RaiseServerValidationErrors,
    RoomConfig,
  ) {
    $scope.RoomConfig = RoomConfig;
    RoomApiService.detail().then(successCallback, errorCallback);
    $scope.logo = undefined;
    $scope.newlogo = undefined;
    $scope.watemarkOpacityOptions = [
      { value: 10, label: "10%" },
      { value: 30, label: "30%" },
      { value: 60, label: "60%" },
      { value: 100, label: "100%" },
    ];

    $scope.watermarkPositionOptions = [
      { value: "fill", label: "Fill" },
      { value: "header_footer", label: "Header & Footer" },
    ];

    function successCallback(response) {
      var data = response.data;
      if ($scope.roomSettingsData) {
        SettingsAlertServices.success("room");
      }
      $scope.roomSettingsData = angular.copy(data);
      $scope.logo = data.room.logo;
      RoomConfig.userPermissions.defaultComments =
        $scope.roomSettingsData.admin_default_comments;
      RoomConfig.showDocsCounter = $scope.roomSettingsData.show_docs_counter;
      RoomConfig.enableBulkDownload =
        $scope.roomSettingsData.enable_bulk_download;
      RoomConfig.editCommentsByMembers =
        $scope.roomSettingsData.edit_comments_by_members;
      // user can change tab by that time and $scope.roomSettingsForm will be undefined
      if ($scope.roomSettingsForm) $scope.roomSettingsForm.$setPristine();
      if (
        RoomConfig.bgColor !== $scope.roomSettingsData.bg_color ||
        RoomConfig.title !== $scope.roomSettingsData.room.title
      ) {
        // simply reload page to apply changes:
        // - bgColor is rendered in Django
        // - title is used by Vue component
        $window.location.reload();
      }
    }

    function errorCallback(response) {
      RaiseServerValidationErrors(response.data, $scope.roomSettingsForm);
      SettingsAlertServices.danger("room");
      // user can change tab by that time and $scope.roomSettingsForm will be undefined
      if ($scope.roomSettingsForm) $scope.roomSettingsForm.$setPristine();
    }

    $scope.roomProfileUpdate = function () {
      const data = angular.copy($scope.roomSettingsData);

      if ($scope.roomSettingsForm.$invalid) {
        return;
      }

      let logoUpdate;
      if ($scope.logo === "" && $scope.newlogo === "") {
        logoUpdate = RoomApiService.deleteLogo().then(
          () => ($scope.newlogo = undefined),
          () => SettingsAlertServices.danger("logo"),
        );
      } else if ($scope.newlogo && $scope.newlogo !== data.room.logo) {
        logoUpdate = RoomApiService.updateLogo($scope.newlogo).catch(() => {
          $scope.newlogo = undefined;
          SettingsAlertServices.danger("logo");
        });
      }

      delete data["room"]["logo"];

      $scope.roomSettingsForm.$setSubmitted();

      $q.when(logoUpdate).then(() =>
        RoomApiService.update(data).then(
          (response) => {
            $scope.newlogo = undefined;
            successCallback(response);
          },
          (response) => errorCallback(response),
        ),
      );
    };

    $scope.clearLogo = function () {
      $scope.logo = $scope.newlogo = "";
    };

    $scope.watermarkOptionsValid = function () {
      if (!$scope.roomSettingsData) {
        return false; // loading
      }

      if ($scope.roomSettingsData.watermark_type.active === "dynamic") {
        return (
          $scope.roomSettingsData.watermark_extra_ip ||
          $scope.roomSettingsData.watermark_extra_username ||
          $scope.roomSettingsData.watermark_extra_email ||
          $scope.roomSettingsData.watermark_extra_datetime
        );
      }
      return true;
    };
  }

  function SlackSettingsController(URLS, $http, $uibModal, AlertService) {
    var ctrl = this;

    ctrl.loading = true;
    ctrl.removeApp = removeApp;
    ctrl.toggleNotifications = toggleNotifications;
    updateInfo();

    function removeApp(app) {
      var modalOptions = {
        headerText: "Remove Slack integration",
        actionButtonText: "Remove",
        bodyText: `Are you sure you want to disconnect Slack integration in ${app.incoming_webhook_channel}?`,
      };

      $uibModal.open({
        template: templates_confirmDialogHtml,
        controllerAs: "ctrl",
        controller: [
          "$scope",
          "$uibModalInstance",
          function ($scope, $uibModalInstance) {
            this.modalOptions = modalOptions;

            this.submit = function () {
              $http
                .delete(URLS["api:room:slack-app"](app.id))
                .then(function (response) {
                  updateInfo();
                })
                .catch(function () {
                  AlertService.danger("Failed to remove Slack app");
                });
              $uibModalInstance.dismiss();
            };
          },
        ],
      });
    }

    function toggleNotifications(app) {
      var originalValue = app.notifications_enabled;
      app.notifications_enabled = !app.notifications_enabled;
      $http
        .put(URLS["api:room:slack-app"](app.id), {
          notifications_enabled: app.notifications_enabled,
        })
        .then(function (response) {})
        .catch(function () {
          app.notifications_enabled = originalValue;
          AlertService.danger("Failed to remove Slack app");
        });
    }

    function updateInfo() {
      ctrl.loading = true;
      $http
        .get(URLS["api:room:slack-info"]())
        .then(function (response) {
          ctrl.apps = response.data.apps;
          ctrl.registration_url = response.data.registration_url;
        })
        .catch(function () {
          AlertService.danger("Failed to get Slack settings");
        })
        .finally(function () {
          ctrl.loading = false;
        });
    }
  }

  function RoomTemplateController(
    RoomTemplateService,
    TasksService,
    CategoriesService,
    RoomConfig,
    drLabelKey,
  ) {
    var ctrl = this;
    ctrl.ROOM_TEMPLATE_EXAMPLE_URL =
      RoomTemplateService.ROOM_TEMPLATE_EXAMPLE_URL;
    ctrl.importTemplate = importTemplate;
    ctrl.exportTemplate = exportTemplate;
    ctrl.formData = {};

    ctrl.importResult = undefined;
    ctrl.isProgress = undefined;

    ctrl.isTrialRoom = RoomConfig.isTrialRoom;
    ctrl.canViewTasks = RoomConfig.userPermissions.viewTasks;

    const documentsStore = useDocumentsStore();

    function importTemplate() {
      ctrl.isProgress = true;
      RoomTemplateService.importTemplate(ctrl.formData.file)
        .then(function (importResult) {
          ctrl.importResult = importResult;
          documentsStore.syncTree();
          TasksService.loadTasks();
          CategoriesService.loadCategories();
          drLabelKey.syncAll();
        })
        .finally(function () {
          ctrl.isProgress = false;
        });
    }

    function exportTemplate() {
      RoomTemplateService.exportTemplate();
    }
  }

  function SettingsAlertServices(AlertService) {
    var services = {
      success: success,
      danger: danger,
      warning: warning,
    };
    var msg = {
      success: {
        user: "User settings have been successfully changed.",
        avatar: "Avatar has been updated.",
        security: "Password has been successfully changed.",
        notification: "Email notifications has been changed.",
        email: "Please, check your inbox and confirm email changing",
        email_confirm: "Email has been changed.",

        room: "Room settings have been successfully changed.",
        logo: "Room logo has been updated.",
        watermark: "Room watermark has been changed.",
        nda: "Room nda has been changed.",

        reindex: "Documents indexes were reseted.",
      },
      danger: {
        user: "Failed to update user settings.",
        avatar: "Failed to update profile photo.",
        security: "Failed to update password.",
        notification: "Failed to update email notifications.",
        email: "Failed to update email",

        room: "Failed to update room settings.",
        logo: "Failed to update room logo.",
        watermark: "Failed to update room watermark.",
        nda: "Failed to update room nda.",

        reindex: "Failed to reset documents indexes.",
        close: "Failed to close the room.",
        delete: "Failed to delete the room.",
      },
      warning: {
        email_key_experid:
          "Your email change confirmation key is expired. Please, request another one.",
      },
    };
    return services;

    function success(name) {
      var txt = msg.success[name];
      AlertService.success(txt);
    }

    function danger(name) {
      var txt = msg.danger[name];
      AlertService.danger(txt);
    }

    function warning(name) {
      var txt = msg.warning[name];
      AlertService.warning(txt);
    }
  }

  function choiceSelect() {
    return {
      restrict: "E",
      replace: true,
      transclude: true,
      template:
        '<select class="form-control"' +
        'ng-model="model">' +
        '<option ng-repeat="ch in options"' +
        'value="{{ch[0]}}">{{ch[1]}}</option>' +
        "</select>",
      scope: {
        model: "=model",
        options: "=options",
      },
    };
  }
  function TaskListKeyModalController(
    $uibModalInstance,
    $http,
    URLS,
    RoomConfig,
    AlertService,
    CategoriesService,
    RoomDataService,
  ) {
    const $ctrl = this;
    $ctrl.RoomConfig = RoomConfig;
    $ctrl.Categories = CategoriesService;
    $ctrl.submit = submit;
    $ctrl.updateDuplicateKeys = updateDuplicateKeys;
    $ctrl.isDuplicate = isDuplicate;
    $ctrl.hasDuplicates = hasDuplicates;
    let disallowedKeys = {};

    $ctrl.listKeyPattern = /^[a-zA-Z]{2,6}$/;
    $ctrl.lists = undefined;

    loadListsInfo();

    function loadListsInfo() {
      const url = URLS["api:room:activity_categories_info"]();
      return $http.get(url).then(
        (resp) => {
          $ctrl.lists = resp.data.filter((cat) => !cat.parent_id);
          updateDuplicateKeys();
        },
        (errorResp) => {
          AlertService.danger("Failed to load lists data.");
        },
      );
    }

    function isDuplicate(list) {
      return list.key && disallowedKeys[list.id].includes(list.key);
    }

    function hasDuplicates() {
      return $ctrl.lists && $ctrl.lists.some((list) => isDuplicate(list));
    }

    function updateDuplicateKeys() {
      disallowedKeys = {};
      for (const list of $ctrl.lists) {
        disallowedKeys[list.id] = $ctrl.lists
          .filter((v) => v.id !== list.id && v.key)
          .map((v) => v.key);
      }
    }

    function submit() {
      if ($ctrl.listKeysForm && $ctrl.listKeysForm.$submitted) {
        return;
      }
      const data = {
        enable: !RoomConfig.enableTaskListKey,
        list_keys: $ctrl.lists.reduce((keys, list) => {
          keys[list.id] = list.key;
          return keys;
        }, {}),
      };
      if ($ctrl.listKeysForm) {
        $ctrl.listKeysForm.$setSubmitted();
      }
      return $http.post(URLS["api:room:toggle-list-keys"](), data).then(
        (resp) => {
          RoomConfig.enableTaskListKey = resp.data.enable_task_list_key;
          RoomDataService.updateData();
          $uibModalInstance.close();
        },
        (errorResp) => {
          if ($ctrl.listKeysForm) {
            $ctrl.listKeysForm.$setPristine();
          }
          AlertService.danger("Failed to change list key.");
        },
      );
    }
  }
})();
