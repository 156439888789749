import "./points";
import "./paths";


angular
  .module("dealroom.analytics.linechart.lines.nodes", [
    "dealroom.analytics.linechart.lines.points",
    "dealroom.analytics.linechart.lines.paths",
  ])
  .service("AnalyticsLinechartNodes", Factory);

Factory.$inject = [
  "AnalyticsLinechartPointsFactory",
  "AnalyticsLinechartPathFactory",
];
function Factory(
  AnalyticsLinechartPointsFactory,
  AnalyticsLinechartPathFactory,
) {
  return function ({ height, r, shadowId }) {
    const points = AnalyticsLinechartPointsFactory(height, r, shadowId);
    const paths = AnalyticsLinechartPathFactory(height, r);

    return {
      updateYs,
      updateNodes,
      remove,
    };

    function updateYs(parent, visibleKeys) {
      // raise
      paths.updateYs(parent);
      points.updateYs(parent, visibleKeys);
    }

    function updateNodes(parent, visibleKeys, xExtent) {
      // flat and raise
      paths.update(parent, xExtent);
      points.update(parent, visibleKeys, xExtent);
    }

    function remove(parent) {
      _remove(points);
      _remove(paths);

      function _remove(childs) {
        childs.remove(parent, function () {
          if (parent.node().childElementCount === 0) {
            parent.remove();
          }
        });
      }
    }
  };
}
