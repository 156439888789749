<template>
  <DrTooltip
    v-if="view === 'timeline'"
    :content="t('requests.scroll_to_today')"
    placement="bottom"
    :show-after="1500"
  >
    <ElButton :class="$style.iconBtn" @click="$emit('scroll-to-now')">
      {{ t("time.today") }}
    </ElButton>
  </DrTooltip>

  <DrToolbarButtonCustomize
    v-if="view === 'timeline'"
    :columns="ganttColumnConfigs"
    has-reset
    @update="onGanttCustomize"
    @reset="onGanttReset"
  />

  <ElDropdown
    v-if="view === 'timeline'"
    trigger="click"
    @command="handleChangeZoom"
  >
    <ElButton :class="$style.iconBtn">
      <DrIcon name="search-plus" />
    </ElButton>
    <template #dropdown>
      <ElDropdownMenu>
        <ElDropdownItem
          v-for="zoomItem in zoomOptions"
          :key="zoomItem.id"
          :command="zoomItem.id"
          :class="{ [$style.menuItem_isActive]: zoomItem.id === zoom }"
        >
          {{ zoomItem.name }}
        </ElDropdownItem>
      </ElDropdownMenu>
    </template>
  </ElDropdown>

  <ElDropdown
    v-if="view !== 'timeline'"
    trigger="click"
    @command="handleExportMenu"
  >
    <ElButton :class="[$style.iconBtn, $style.iconBtnSmall]">
      <DrIcon name="ellipsis-h" size="lg" />
    </ElButton>
    <template #dropdown>
      <ElDropdownMenu>
        <ElDropdownItem v-if="canCreateTasks" command="importTasks">
          {{ t("shared.import") }}
        </ElDropdownItem>
        <ElDropdownItem v-if="!isTrialRoom" command="exportTasksPdf">
          {{ t("requests.export_to_pdf") }}
        </ElDropdownItem>
        <ElDropdownItem v-if="!isTrialRoom" command="exportTasksExcel">
          {{ t("requests.export_to_excel") }}
        </ElDropdownItem>
      </ElDropdownMenu>
    </template>
  </ElDropdown>

  <DrPopup v-if="view === 'timeline'" placement="bottom" transition=" ">
    <template #reference>
      <ElButton style="width: 34px">
        <template #icon>
          <DrIcon name="cogwheel" />
        </template>
      </ElButton>
    </template>
    <template #default>
      <div>
        <DrPopupSelectOptions
          :title="t('shared.options')"
          filterable
          :items="ganttOptions"
          @select="(option) => toggleGanttOption(option)"
        />
      </div>
    </template>
  </DrPopup>

  <DrToolbarButtonCustomize
    v-if="view !== 'timeline' && columns?.length"
    :columns="columns"
    has-fixed
    has-reset
    @update="onTableCustomize"
    @reset="onTableReset"
  />

  <ElButton
    v-if="canCreateTasks"
    type="primary"
    @click="$emit('create-request')"
  >
    {{ t("requests.new_request") }}
  </ElButton>

  <TasksImportModal ref="importModalRef" />
</template>

<script setup lang="ts" generic="R">
import { ref } from "vue";
import { useI18n } from "vue-i18n";
import { DrIcon } from "@shared/ui/dr-icon";
import { DrPopup, DrPopupSelectOptions } from "@shared/ui/dr-popups";
import DrTooltip from "@shared/ui/dr-tooltip/DrTooltip.vue";

import { ROOM_DATA } from "@setups/data";
import { CustomViewObjectTypes, ZOOM } from "@setups/types";
import {
  insightTrack,
  RoomTasksImportExportEvent,
  TasksTimelineTrackingEvent,
} from "@app/insight";
import DrToolbarButtonCustomize from "@app/vue/shared/ui/dr-toolbar/DrToolbarButtonCustomize.vue";
import {
  getIsAutoSchedulingEnabled,
  getIsCriticalPathEnabled,
  saveZoomLevel,
} from "@drVue/components/room/tasks/TaskTimelinePage/ganttState";
import DrStore from "@drVue/store";
import { pinia } from "@drVue/store/pinia";
import { useTasksStore } from "@drVue/store/pinia/room/tasks";
import { useTasksTableStore } from "@drVue/store/pinia/room/tasksTable/tasksTable";
import useGanttColumnConfigs, {
  resetTaskTimelineView,
} from "../TaskTimelinePage/useGanttColumnConfigs";
import { saveTaskTimelineView } from "../TaskTimelinePage/useGanttColumnConfigs";
import TasksImportModal from "./TasksImportModal.vue";
import { resetColumnsConfig, saveColumnsConfig } from "./viewUtils";

import type {
  GanttOptionId,
  GanttOptionSelectItem,
} from "@drVue/components/room/tasks/TaskOverviewPage/types";
import type { DrVxeTableColumn } from "@drVue/components/types";
import type { CustomViewColumn, ZoomLevel } from "@setups/types";

const { t } = useI18n();

const tasksStore = useTasksStore(pinia);
const tasksTableStore = useTasksTableStore(pinia);
const canCreateTasks = !!ROOM_DATA?.userPermissions?.canCreateTasks;
const isTrialRoom = !!ROOM_DATA?.isTrialRoom;

interface Props {
  zoom?: ZoomLevel;
  columns?: DrVxeTableColumn<R>[];
  view?: "overview" | "timeline";
}

withDefaults(defineProps<Props>(), {
  zoom: ZOOM.Months,
  columns: undefined,
  view: "overview",
});

const emit = defineEmits<{
  (event: "cols-visibility-change", isVisible: boolean): void;
  (
    event: "gantt-option-change",
    modeId: GanttOptionId,
    isEnabled: boolean,
  ): void;
  (event: "scroll-to-now"): void;
  (event: "zoom-change", zoom: ZoomLevel): void;
  (event: "create-request"): void;
}>();

const zoomOptions = [
  {
    id: ZOOM.Days,
    name: t("time.days"),
  },
  {
    id: ZOOM.Weeks,
    name: t("time.weeks"),
  },
  {
    id: ZOOM.Months,
    name: t("time.months"),
  },
  {
    id: ZOOM.Quarters,
    name: t("time.quarters"),
  },
  {
    id: ZOOM.Years,
    name: t("time.years"),
  },
];

const ganttOptions = ref<GanttOptionSelectItem[]>([
  {
    id: "isGanttAutoSchedulingEnabled",
    name: t("requests.auto_scheduling"),
    isSelected: getIsAutoSchedulingEnabled(),
  },
  {
    id: "isGanttCriticalPathEnabled",
    name: t("requests.show_critical_path"),
    isSelected: getIsCriticalPathEnabled(),
  },
]);

const toggleGanttOption = (modeId: GanttOptionId) => {
  const mode = ganttOptions.value.find((item) => item.id === modeId);
  if (!mode) return;

  mode.isSelected = !mode.isSelected;

  emit("gantt-option-change", mode.id, mode.isSelected);
};

const ganttColumnConfigs = useGanttColumnConfigs();

const onGanttCustomize = (updates: CustomViewColumn[]) => {
  saveTaskTimelineView(DrStore, updates);
  insightTrack(TasksTimelineTrackingEvent.ColumnsCustomized);
};

const onGanttReset = () => {
  resetTaskTimelineView(DrStore);
};

const onTableCustomize = (updates: CustomViewColumn[]) => {
  saveColumnsConfig(DrStore, updates, CustomViewObjectTypes.Task);
};

const onTableReset = () => {
  resetColumnsConfig(DrStore, CustomViewObjectTypes.Task);
};

const exportTasks = async (format: "pdf" | "xlsx") => {
  const taskUids = tasksTableStore.items
    .filter((item) => "status_id" in item) // filter out category item
    .map((t) => t.uid);
  if (!taskUids.length) return;

  await tasksStore.exportTasks(taskUids, format);
  insightTrack(RoomTasksImportExportEvent.Exported, {
    format,
    tasks: "all",
  });
};

const importModalRef = ref<InstanceType<typeof TasksImportModal>>();

const handleExportMenu = (action: string) => {
  switch (action) {
    case "exportTasksPdf":
      exportTasks("pdf");
      break;
    case "exportTasksExcel":
      exportTasks("xlsx");
      break;
    case "importTasks":
      importModalRef.value?.open();
  }
};

const handleChangeZoom = (zoom: ZoomLevel) => {
  insightTrack("room.tasks.gantt.zoomed", {
    zoom,
  });
  saveZoomLevel(zoom);
  emit("zoom-change", zoom);
};
</script>

<style module lang="scss">
@use "@app/styles/scss/colors";
@use "@app/styles/scss/spacing";
@use "@app/styles/scss/typography" as typo;

.iconBtn:global(.el-button) {
  padding-left: 6px;
  padding-right: 6px;
}

.iconBtnSmall {
  width: 32px;
}

.menuItem_isActive:global(.el-dropdown-menu__item) {
  background-color: colors.$sc-100;
  color: colors.$sc-600;
  font-weight: 500;
}

.columnsHeader {
  margin-bottom: 0;

  :global(.el-form-item__label) {
    font: typo.$body_semibold;
    color: colors.$pr-900;
  }
}

.columnsFooter {
  display: flex;
  align-items: center;
  gap: spacing.$xl;

  :global(.el-button) {
    --el-button-text-color: #{colors.$pr-500};
    font: typo.$body_regular;

    &:hover {
      color: var(--el-button-hover-text-color);
    }
  }
}
</style>
