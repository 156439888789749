<template>
  <TablePageLayout
    :title="pageTitle"
    :search="searchPhrase"
    :action="toolbarAction"
    :flex-body="!!group"
    @search="handleSearch"
    @action="handleAction"
  >
    <DrOverlayEmpty
      v-if="noDisplayData.active"
      icon="bars"
      :title="noDisplayData.title"
      :subtitle="noDisplayData.subtitle"
    >
      <template #action>
        <ElButton
          v-if="noDisplayData.btnClearSearch"
          @click="searchPhrase = ''"
        >
          {{ $t("filters.clear_search_query") }}
        </ElButton>
      </template>
    </DrOverlayEmpty>

    <!-- All groups view -->
    <template v-if="!group">
      <GroupsRequestsTable
        :items="filteredCategories"
        :actions="actions"
        :is-processing="isProcessing"
        @update-actions="$emit('update-actions', $event)"
      />
    </template>
    <!-- Selected group view -->
    <template v-else>
      <GroupsRequestsTable
        :items="filteredCategories"
        :actions="actions"
        :is-processing="isProcessing"
        :group="group"
        @update-actions="$emit('update-actions', $event)"
        @add-category="$emit('add-category', $event)"
      />
    </template>
  </TablePageLayout>
</template>

<script setup lang="ts">
import fuzzaldrinPlus from "fuzzaldrin-plus";
import { computed, ref } from "vue";
import { useI18n } from "vue-i18n";
import { DrOverlayEmpty } from "@shared/ui/dr-overlay";

import { pinia } from "@drVue/store/pinia";
import { useCategoriesStore } from "@drVue/store/pinia/room/categories";
import TablePageLayout from "../TablePageLayout.vue";
import GroupsRequestsTable from "./table/GroupsRequestsTable.vue";

import type { PageToolbarAction } from "../TablePageLayout.vue";
import type { Category, GroupsActions } from "../types";
import type { Group } from "./types";
import type { CategoryNavTreeItem } from "@drVue/store/pinia/room/categories/categories";

interface Props {
  group: Group | null;
  actions: GroupsActions;
  isProcessing: boolean;
}

interface Emits {
  (event: "update-actions", actions: GroupsActions): void;
  (
    event: "add-category",
    reference: HTMLElement,
    parent_id?: Category["parent_id"],
  ): void;
}

const { actions, group } = defineProps<Props>();
const emit = defineEmits<Emits>();

const { t } = useI18n();
const categoriesStore = useCategoriesStore(pinia);

const searchPhrase = ref("");

const toolbarAction = computed(() => {
  const action: PageToolbarAction = {
    label: t("permissions.requests.add_worklist"),
    type: "default",
    icon: "plus",
    menu: [],
  };

  return action;
});

const pageTitle = computed(() =>
  group
    ? t("permissions.requests.page_title_selected_group")
    : t("permissions.requests.page_title_all_groups"),
);

const noCategories = computed(() => !categoriesStore.categoriesList.length);

const noDisplayData = computed(() => {
  const data = {
    active: false,
    title: "",
    subtitle: "",
    btnClearSearch: false,
  };

  if (noCategories.value) {
    data.active = true;
    data.title = t("permissions.requests.no_worklists");
    data.subtitle = t("permissions.requests.no_worklists_desc");
  } else if (!filteredCategories.value.length) {
    data.active = true;
    data.title = t("shared.no_results_for", { query: searchPhrase.value });
    data.btnClearSearch = true;
  }

  return data;
});

const handleSearch = (phrase: string) => {
  searchPhrase.value = phrase.trim();
};

const filterCategories = (categories: CategoryNavTreeItem[], query: string) => {
  return categories.reduce<CategoryNavTreeItem[]>((acc, cat) => {
    const children = cat.children ? filterCategories(cat.children, query) : [];

    const score = fuzzaldrinPlus.score(cat.name, query);
    if (score > 0 || children.length) {
      acc.push({
        ...cat,
        children,
      });
    }
    return acc;
  }, []);
};

const filteredCategories = computed(() => {
  const query = searchPhrase.value.toLowerCase();
  if (query) {
    return filterCategories(categoriesStore.categoriesNavTree, query);
  }

  return categoriesStore.categoriesNavTree;
});

const handleAction = (reference: HTMLElement) => {
  emit("add-category", reference);
};
</script>
