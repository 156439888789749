<template>
  <DrPanelWrapper>
    <template #title>
      <ElButton size="small" :class="$style.closeBtn" @click="$emit('close')">
        <DrIcon name="cross" size="sm" />
      </ElButton>
      <span>{{ t("shared.filters") }}</span>
    </template>

    <template #title-action>
      <ElButton
        icon-right
        size="small"
        :disabled="!tasksFilterService.isActive()"
        :class="$style.textBtn"
        @click="tasksFilterService.clearListFilters()"
      >
        {{ t("filters.reset_all") }}
        <template #icon>
          <DrIcon name="redo" size="sm" />
        </template>
      </ElButton>
    </template>

    <template #subtitle>
      <ElInput
        ref="searchFiltersFieldRef"
        v-model="searchFiltersText"
        :placeholder="t('filters.search_filters_dots')"
      >
        <template #prefix>
          <DrIcon size="sm" name="search" />
        </template>
      </ElInput>
    </template>

    <DrFormWrapper>
      <ElFormItem
        v-if="canManageTasks && shownFiltersMap.assignee"
        :label="t('shared.assignee')"
      >
        <UsersTreeSelect
          v-model="tasksFilterService.filters.assignees"
          :is-loaded="isMembersAndGroupsLoaded"
          :members-list="membersList"
          :groups-list="groupsList"
          :get-users-of-group="getUsersOfGroup"
        />
      </ElFormItem>

      <ElFormItem v-if="canManageTasks && shownFiltersMap.show_unassigned">
        <ElCheckbox v-model="tasksFilterService.filters.showUnassigned" border>
          {{ t("requests.show_unassigned") }}
        </ElCheckbox>
      </ElFormItem>

      <ElFormItem
        v-if="canManageTasks && shownFiltersMap.reviewer"
        :label="t('shared.reviewer')"
      >
        <UsersTreeSelect
          v-model="tasksFilterService.filters.reviewers"
          :is-loaded="isMembersAndGroupsLoaded"
          :members-list="membersList"
          :groups-list="groupsList"
          :get-users-of-group="getUsersOfGroup"
        />
      </ElFormItem>

      <ElFormItem
        v-if="canManageTasks && shownFiltersMap.creator"
        :label="t('shared.creator')"
      >
        <UsersTreeSelect
          v-model="tasksFilterService.filters.creators"
          :is-loaded="isMembersAndGroupsLoaded"
          :members-list="membersList"
          :groups-list="groupsList"
          :get-users-of-group="getUsersOfGroup"
        />
      </ElFormItem>

      <ElFormItem
        v-if="canManageTasks && shownFiltersMap.follower"
        :label="t('shared.follower')"
      >
        <UsersTreeSelect
          v-model="tasksFilterService.filters.followers"
          :is-loaded="isMembersAndGroupsLoaded"
          :members-list="membersList"
          :groups-list="groupsList"
          :get-users-of-group="getUsersOfGroup"
        />
      </ElFormItem>

      <ElFormItem v-if="shownFiltersMap.status" :label="t('shared.status')">
        <ElSelect
          v-model="tasksFilterService.filters.statuses"
          multiple
          clearable
          filterable
        >
          <ElOption
            v-for="s in statuses"
            :key="s.id"
            :label="s.name"
            :value="s.id"
          />
        </ElSelect>
      </ElFormItem>

      <ElFormItem
        v-if="canManageTasks && shownFiltersMap.priority"
        :label="t('shared.priority')"
      >
        <ElSelect
          v-model="tasksFilterService.filters.priorities"
          multiple
          clearable
          filterable
        >
          <ElOption
            v-for="p in priorities"
            :key="p.value"
            :label="p.label"
            :value="p.value"
          />
        </ElSelect>
      </ElFormItem>

      <ElFormItem
        v-if="canManageTasks && shownFiltersMap.label"
        :label="t('shared.label')"
      >
        <ElSelect
          v-model="tasksFilterService.filters.labels"
          multiple
          clearable
          filterable
        >
          <ElOption
            v-for="l in labels"
            :key="l.id"
            :label="l.name"
            :value="l.id"
          />
        </ElSelect>
      </ElFormItem>

      <ElFormItem
        v-if="canManageTasks && shownFiltersMap.updated"
        :label="t('shared.updated')"
      >
        <DrDatepicker
          v-model="tasksFilterService.filters.updatedRange"
          type="daterange"
          unlink-panels
          clearable
          :picker-options="pickerOptions"
        />
      </ElFormItem>

      <ElFormItem
        v-if="canManageTasks && shownFiltersMap.due_date"
        :label="t('shared.due_date')"
      >
        <DrDatepicker
          v-model="tasksFilterService.filters.dueDateRange"
          type="daterange"
          unlink-panels
          clearable
          :picker-options="pickerOptions"
        />
      </ElFormItem>

      <ElFormItem v-if="canManageTasks && shownFiltersMap.show_overdue">
        <ElCheckbox v-model="tasksFilterService.filters.showOverdueOnly" border>
          {{ t("requests.show_overdue_only") }}
        </ElCheckbox>
      </ElFormItem>

      <ElFormItem v-if="canManageTasks && shownFiltersMap.show_unreviewed">
        <ElCheckbox v-model="tasksFilterService.filters.needReviewOnly" border>
          {{ t("requests.show_only_unreviewed") }}
        </ElCheckbox>
      </ElFormItem>

      <ElFormItem
        v-for="field in filteredCustomFields"
        :key="field.id"
        :label="field.label"
      >
        <DrDatepicker
          v-if="field.field_type === FieldItemTypes.Date"
          type="daterange"
          start-placeholder="From"
          end-placeholder="To"
          align="center"
          :model-value="getCustomFieldValue(field.key)"
          @update:model-value="
            (value: any) => setCustomFieldValue(field.key, value, 'RANGE')
          "
        />

        <ElSelect
          v-else-if="
            field.field_type === FieldItemTypes.Select ||
            field.field_type === FieldItemTypes.MultiSelect
          "
          clearable
          multiple
          placeholder=""
          class="el-select--full-width"
          :model-value="getCustomFieldValue(field.key)"
          @change="
            (value: any) =>
              setCustomFieldValue(
                field.key,
                value,
                field.field_type === FieldItemTypes.Select ? 'ANY' : 'ALL',
              )
          "
        >
          <template v-if="'options' in field.extra">
            <ElTooltip
              v-for="item in field.extra.options"
              :key="item"
              :content="item"
              :show-after="400"
            >
              <ElOption
                :class="$style.dropdownItem"
                :label="item"
                :value="item"
              />
            </ElTooltip>
          </template>
        </ElSelect>

        <ElInput
          v-else-if="field.field_type === FieldItemTypes.Number"
          clearable
          type="number"
          :model-value="getCustomFieldValue(field.key) as number"
          @input="(value) => setCustomFieldValue(field.key, value, 'EQ')"
          @wheel.prevent
        />

        <ElAutocomplete
          v-else
          clearable
          class="el-autocomplete--full-width"
          :label="field.key"
          :fetch-suggestions="
            (query, cb) => getTextFieldAutoComplete(query, cb, field.key)
          "
          :trigger-on-focus="false"
          :debounce="0"
          :model-value="getCustomFieldValue(field.key) as string"
          @update:model-value="
            (value) => setCustomFieldValue(field.key, value, 'CONTAINS')
          "
        >
          <template #default="{ item }">
            <DrTruncatedTextTooltip :content="item.value">
              <div :class="$style.dropdownItem">
                {{ item.value }}
              </div>
            </DrTruncatedTextTooltip>
          </template>
        </ElAutocomplete>
      </ElFormItem>
    </DrFormWrapper>
  </DrPanelWrapper>
</template>

<script lang="ts" setup>
import { endOfDay, startOfDay } from "date-fns";
import { uniqBy } from "lodash-es";
import { computed, onMounted, ref } from "vue";
import { useI18n } from "vue-i18n";
import { DrDatepicker } from "@shared/ui/dr-datepicker";
import { DrFormWrapper } from "@shared/ui/dr-form";
import DrIcon from "@shared/ui/dr-icon";
import { DrPanelWrapper } from "@shared/ui/dr-panels";
import { DrTruncatedTextTooltip } from "@shared/ui/dr-tooltip";

import { ROOM_DATA, ROOM_MEMBER_DATA } from "@setups/data";
import { TaskFieldAccessType } from "@setups/enums";
import { CustomViewObjectTypes } from "@setups/types";
import { isTask } from "@app/ng/tasks/services/helpers/getItemType";
import tasksFilterService from "@app/ng/tasks/services/ts/TasksFilterService";
import UsersTreeSelect from "@app/vue/components/room/UsersTreeSelect.vue";
import { FieldItemTypes } from "@drVue/api-service/client-dashboard";
import { isStringContains } from "@drVue/common";
import DrStore from "@drVue/store";
import { pinia } from "@drVue/store/pinia";
import { useTasksLabelsStore } from "@drVue/store/pinia/room/tasksLabels/tasksLabels";
import { useTasksTableStore } from "@drVue/store/pinia/room/tasksTable/tasksTable";

import type {
  CustomData,
  CustomDataType,
} from "@app/ng/tasks/services/ts/types/Filters";
import type { FieldItem } from "@drVue/api-service/client-dashboard";
import type { RoomGroup } from "@drVue/store/modules/client-dashboard/deals/types";
import type { RoomMember } from "@drVue/store/modules/room/members/RoomMembersApiService";
import type { TaskLabel } from "@drVue/store/pinia/room/tasksLabels/tasksLabelApi";

defineEmits(["close"]);

const { t } = useI18n();

const tasksLabels = useTasksLabelsStore(pinia);
const tasksTableStore = useTasksTableStore(pinia);

const canManageTasks = ROOM_DATA.userPermissions.canManageTasks;
const pickerOptions = {
  shortcuts: [
    {
      text: "Today",
      onClick: (picker: any) => last(picker, 0),
    },
    {
      text: "Yesterday",
      onClick: (picker: any) => last(picker, 1, 1),
    },
    {
      text: "Last week",
      onClick: (picker: any) => last(picker, 6),
    },
    {
      text: "Last month",
      onClick: (picker: any) => last(picker, 29),
    },
    {
      text: "Last 3 months",
      onClick: (picker: any) => last(picker, 89),
    },
  ],
};
const priorities = [
  {
    label: "Low",
    value: "low",
  },
  {
    label: "Medium",
    value: "medium",
  },
  {
    label: "High",
    value: "high",
  },
];
const searchFiltersText = ref("");
const searchFiltersFieldRef = ref<HTMLInputElement | null>(null);

const access =
  ROOM_MEMBER_DATA.group.task_custom_fields_access ||
  TaskFieldAccessType.NoAccess;
const allowShowCustomFields = [
  TaskFieldAccessType.View,
  TaskFieldAccessType.Edit,
].includes(access);

const taskCustomFields = computed((): FieldItem[] => {
  if (!allowShowCustomFields) {
    return [];
  }

  return DrStore.getters["clientDashboard/customFields/byObjectType"](
    CustomViewObjectTypes.Task,
  );
});

const accessStartDueDatesFields =
  (ROOM_MEMBER_DATA.group.task_start_and_due_dates_access ||
    TaskFieldAccessType.NoAccess) !== TaskFieldAccessType.NoAccess;

const shownFiltersMap = computed(() => {
  const search = searchFiltersText.value.trim().toLowerCase();
  const skip = !search;

  return {
    assignee: skip || "assignee".includes(search),
    reviewer: skip || "reviewer".includes(search),
    creator: skip || "creator".includes(search),
    follower: skip || "follower".includes(search),
    status: skip || "status".includes(search),
    priority: skip || "priority".includes(search),
    label: skip || "label".includes(search),
    updated: skip || "updated".includes(search),
    due_date:
      accessStartDueDatesFields && (skip || "due date".includes(search)),
    show_overdue:
      accessStartDueDatesFields &&
      (skip || "show overdue only".includes(search)),
    show_unreviewed: skip || "show only unreviewed".includes(search),
    show_unassigned: skip || "show unassigned".includes(search),
  };
});
const labels = computed<TaskLabel[]>(() => {
  return tasksLabels.list;
});
const statuses = computed(() => {
  return DrStore.state.room.tasksStatuses.list;
});
const membersList = computed<RoomMember[]>(() => {
  return DrStore.state.room.members.membersList;
});
const groupsList = computed<RoomGroup[]>(() => {
  return DrStore.state.room.groups.pgroupsList;
});
const isMembersAndGroupsLoaded = computed(() => {
  const m = DrStore.state.room.members;
  const g = DrStore.state.room.groups;

  return (
    m.isLoading === false &&
    m.isError === false &&
    g.isLoading === false &&
    g.isError === false
  );
});

const getUsersOfGroup = (group: RoomGroup) => {
  return membersList.value.reduce((acc: RoomMember[], m: RoomMember) => {
    if (!m.pending && m.pgroup.id === group.id) acc.push(m);

    return acc;
  }, []);
};
const last = (
  picker: any,
  subtractFromStart: number,
  subtractFromEnd: number = 0,
) => {
  const end = endOfDay(new Date());
  const start = startOfDay(new Date());

  end.setTime(end.getTime() - 3600 * 1000 * 24 * subtractFromEnd);
  start.setTime(start.getTime() - 3600 * 1000 * 24 * subtractFromStart);

  picker.$emit("pick", [start, end]);
};

const filteredCustomFields = computed(() => {
  const search = searchFiltersText.value.trim().toLowerCase();
  const skip = !search;

  return taskCustomFields.value.filter(
    (field) => skip || field.label.toLowerCase().includes(search),
  );
});

const setCustomFieldValue = (
  fieldId: string,
  value: any,
  op: CustomDataType["op"] = "EQ",
) => {
  if ((Array.isArray(value) && !value[0]) || !value) {
    delete (tasksFilterService.filters.custom_data as CustomData)[fieldId];
  } else {
    (tasksFilterService.filters.custom_data as CustomData)[fieldId] = {
      op,
      value,
    };
  }
};

const getCustomFieldValue = (fieldId: string) => {
  return (tasksFilterService.filters.custom_data as CustomData)[fieldId]?.value;
};

type SuggestionsItem = { value: string };
const getTextFieldAutoComplete = (
  query: string,
  cb: (results: Record<string, any>[]) => void,
  fieldKey: string,
) => {
  const itemsData = tasksTableStore.items.reduce((acc, item) => {
    if (isTask(item)) {
      const value = (item.custom_data || {})[fieldKey];
      if (value && value !== "-" && isStringContains(query, value)) {
        acc.push({ value });
      }
    }
    return acc;
  }, [] as SuggestionsItem[]);

  cb(uniqBy(itemsData, (r: SuggestionsItem) => r.value));
};

onMounted(() => {
  /**
   * It is required to use this timeout:
   * 1) in parent TasksOverviewContent we use DrLayoutContent
   *    and render this component in the "aside" slot;
   * 2) since with the "destroy-aside-on-close" option enabled,
   *    the contents of the slot are mounted immediately
   *    at the moment the "show-aside" property is set
   *    (i.e. even before the panel has completely moved out);
   * 3) then setting the focus to a field that is still out of view
   *    creates some kind of "glitch" effect
   *    (the appearance of the panel is jerky and even moves the table over the left edge).
   *
   * 4) Therefore, when we set the focus after the full appearance of the "aside",
   *    then everything is fine.
   *
   * @note aside panel transition duration = 300ms.
   */
  setTimeout(() => {
    searchFiltersFieldRef.value?.focus();
  }, 350);
});
</script>

<style lang="scss" module>
@use "@app/styles/scss/colors";
@use "@app/styles/scss/spacing";
@use "@app/styles/scss/typography" as typo;

.textBtn:global(.el-button) {
  --el-button-bg-color: transparent;
  --el-button-border-color: transparent;
  --el-button-disabled-bg-color: transparent;
  --el-button-disabled-border-color: transparent;
}

.closeBtn:global(.el-button) {
  width: 24px;
  height: 24px;
  margin-right: 10px;
  border-radius: 6px;
}
</style>
