<template>
  <DatePickerWithSlot
    :value="dateString"
    :date-value="modelValue || ''"
    :small="small"
    @submit="handleSubmit"
    @delete="() => handleSubmit(null)"
  >
    <template #default>
      <DynamicDateDialog
        v-if="isDynamicDateMode"
        :is-active="editProps.entity.is_start_date_dynamic"
        :delta-days="editProps.entity.dynamic_start_date?.delta_days"
        :deal-field-key="editProps.entity.dynamic_start_date?.deal_field_key"
        :is-processing="isDynamicDateProcessing"
        @submit="handleSubmitDynamicDate"
        @set-static="handleSetStaticDate"
        @cancel="isDynamicDateMode = false"
      />
    </template>
    <template v-if="isAbleToEditDynamicDates" #action>
      <ElButton
        v-if="!isDynamicDateMode"
        :class="$style.setDynamicDateBtn"
        @click="isDynamicDateMode = true"
      >
        <template #icon>
          <DrIcon size="sm" name="link" />
        </template>
        {{ t("requests.set_dyn_start_date") }}
      </ElButton>
    </template>
  </DatePickerWithSlot>
</template>

<script setup lang="ts">
import { ElButton } from "element-plus";
import { computed, ref, watch } from "vue";
import { convertBtzToPtz } from "@shared/ui/dr-datepicker/DrDatepicker.vue";
import DrIcon from "@shared/ui/dr-icon";

import {
  DynamicDatesTrackingEvent,
  DynamicDatesWidget,
  insightTrack,
} from "@app/insight";
import { ORG_MEMBER_DATA } from "@app/setups";
import { t } from "@app/vue/i18n";
import { $notifyDanger } from "@drVue/common";
import { getMilestoneLabel } from "@drVue/components/room/tasks/shared/widgets/utils";
import { drUserTime } from "@drVue/filters/drUserTime";
import { useTasksStore } from "@drVue/store/pinia/room/tasks";
import DatePickerWithSlot from "../../DatePickerWithSlot.vue";
import DynamicDateDialog from "../../DynamicDateDialog.vue";

import type { EditFieldProps } from "@drVue/components/client-dashboard/dynamic-form/Fields/types";
import type { TaskDynamicDateConf } from "@drVue/store/pinia/room/tasks";

const tasksStore = useTasksStore();

interface Props {
  editProps: EditFieldProps;
  small?: boolean;
}
const props = withDefaults(defineProps<Props>(), {
  small: false,
});

interface Emits {
  (e: "change"): void;
}
const emit = defineEmits<Emits>();

const value =
  props.editProps.value instanceof Date
    ? new Date(props.editProps.value)
    : null;

const modelValue = ref(value);

const isAdmin = computed(() => ORG_MEMBER_DATA.group?.is_administrator);

const isMilestoneWithoutDate = computed(
  () =>
    props.editProps.value === null &&
    props.editProps.entity.is_start_date_dynamic,
);

const dateString = computed(() => {
  if (isMilestoneWithoutDate.value) {
    if (!isAdmin.value) return null;
    const deltaDays = props.editProps.entity.dynamic_start_date?.delta_days;
    const daysCount = Math.abs(deltaDays);
    const timeMarker = deltaDays > 0 ? "after" : "before";
    const milestoneLabel = getMilestoneLabel(
      props.editProps.entity.dynamic_start_date.deal_field_key,
    );
    return `${daysCount} ${timeMarker} ${milestoneLabel}`;
  } else if (props.editProps.value !== null) {
    return drUserTime(props.editProps.value, "iso-date");
  }
  return props.editProps.value;
});

async function handleSubmit(date: Date | null) {
  try {
    if (
      date === null &&
      props.editProps.entity.is_start_date_dynamic &&
      isMilestoneWithoutDate.value
    ) {
      await handleSetStaticDate();
    } else {
      if (modelValue.value === date) return; // No-change scenario
      if (date !== null) date = convertBtzToPtz(date);
      modelValue.value = date;
      props.editProps.veeField.onChange(date);
      props.editProps.submitField();
    }
    emit("change");
  } catch {
    $notifyDanger("Something went wrong while modifying start date");
  }
}

async function handleSubmitDynamicDate(
  dynamic_start_date: TaskDynamicDateConf,
) {
  try {
    isDynamicDateProcessing.value = true;
    const taskId = props.editProps.entity.uid;
    await tasksStore.patchTaskV2(taskId, { dynamic_start_date });
    props.editProps.quitEditMode();
  } catch (err) {
    $notifyDanger((err as Error).message);
  } finally {
    isDynamicDateProcessing.value = false;
    insightTrack(DynamicDatesTrackingEvent.StartDateCreated, {
      widget: DynamicDatesWidget.TaskTable,
    });
  }
}

async function handleSetStaticDate() {
  try {
    isDynamicDateProcessing.value = true;
    const taskId = props.editProps.entity.uid;
    await tasksStore.patchTaskV2(taskId, { dynamic_start_date: null! });
    props.editProps.quitEditMode();
  } catch (err) {
    $notifyDanger((err as Error).message);
  } finally {
    isDynamicDateProcessing.value = false;
    insightTrack(DynamicDatesTrackingEvent.StartDateRemoved, {
      widget: DynamicDatesWidget.TaskTable,
    });
  }
}

const isDynamicDateMode = ref(false);
const isDynamicDateProcessing = ref(false);

// To edit Dynamic Dates, means that the user can modify the dynamic date parameters:
//
// 1. He can choose to set a static date or to set a dynamic date.
// 2. He can choose to set a milestone.
// 3. He can choose to set a number of days before or after the milestone.
const isAbleToEditDynamicDates = computed(
  (): boolean => ORG_MEMBER_DATA.group?.is_administrator ?? false,
);

// 1. If isDynamicDateMode is true, then the user is editing the dynamic date parameters.
// 2. If isDynamicDateMode is false, then the user is editing the static date.
//
// Point 2 is only possible if a user has `canManageTasks` permission.
// If `canManageTasks` is false, then the user will not see this component at
// all, as hide the right side of the Request Details modal where this component
// is rendered.
watch(
  () =>
    props.editProps.entity.is_start_date_dynamic &&
    isAbleToEditDynamicDates.value,
  (value) => {
    isDynamicDateMode.value = value;
  },
  { immediate: true },
);
</script>

<style module lang="scss">
.setDynamicDateBtn {
  width: 100%;
}
</style>
