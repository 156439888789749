import type { ChangeObjType, ChangeOperation } from "./api";

export type ChangeKey = string;

export const getChangeKey = (ch: ChangeOperation): ChangeKey => {
  switch (ch.op) {
    case "create_task":
      return `${ch.op}:${ch.task.type}:${ch.task.key}`;
    case "create_group":
      return `${ch.op}:${ch.group.type}:${ch.group.key}`;
    case "add_room_member":
      return `${ch.op}:${ch.group.type}:${ch.group.key}:${ch.member.type}:${ch.member.key}`;
    default:
      const _throw: never = ch;
  }

  throw new Error(
    `Unhandled change operation type in getChangeKey: ${JSON.stringify(ch)}`,
  );
};

export const getChangeType = (ch: ChangeOperation): ChangeObjType => {
  switch (ch.op) {
    case "create_task":
      return ch.task.type;
    case "create_group":
      return ch.group.type;
    case "add_room_member":
      return ch.member.type;
    default:
      const _throw: never = ch;
  }

  throw new Error(
    `Unhandled change operation type in getChangeKey: ${JSON.stringify(ch)}`,
  );
};
