<template>
  <DrVxeGrid
    :data="roomGroupsStore.rooms"
    :columns="tableColumns.columns"
    :height="300"
    :checkbox-config="{ checkField: '_isChecked' }"
    @cell-click="onCellClick"
    @checkbox-change="onCheckboxChange"
    @checkbox-all="onCheckboxAll"
  >
    <template #empty>No available rooms found</template>
  </DrVxeGrid>
</template>

<script setup lang="ts">
import DrVxeGrid from "@shared/ui/dr-vxe-grid/DrVxeGrid.vue";

import { pinia } from "@app/vue/store/pinia";
import { type SelectableRoom, useRoomGroupsStore } from "./store";
import TableColumns from "./tableColumns";

import type { VxeTableEvents } from "vxe-table";

const roomGroupsStore = useRoomGroupsStore(pinia);

const tableColumns = new TableColumns();

const onCheckboxChange: VxeTableEvents.CheckboxChange<SelectableRoom> = ({
  row,
  checked,
}) => {
  if (checked) roomGroupsStore.selectRoomGroup(row.id, row.groups[0]);
  else roomGroupsStore.unselectRoomGroup(row.id);
};

const onCheckboxAll: VxeTableEvents.CheckboxAll = ({ checked }) => {
  if (checked) roomGroupsStore.selectAll();
  else roomGroupsStore.unselectAll();
};

const onCellClick: VxeTableEvents.CellClick<SelectableRoom> = ({
  row,
  column,
}) => {
  if (column.field !== "_isChecked" && column.field !== "title") return;

  roomGroupsStore.toggleRoomGroup(row.id, row.groups[0]);
};
</script>

<style module lang="scss"></style>
