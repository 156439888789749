import { reviewersContains } from "@app/ng/tasks/services/ts/predicates/helpers/reviewersContains";

import type { TaskPredicate } from "@app/ng/tasks/services/ts/types/TaskPredicate";

export const reviewers: TaskPredicate = (task, filters) => {
  if (!task.reviewers) return true;

  if (filters.showNoReviewers) return task.reviewers.length === 0;

  const reviewersIds = filters.reviewers;
  const needReviewOnly = filters.needReviewOnly;

  if (reviewersIds.length === 0) {
    return needReviewOnly ? task.reviewers.some((r) => !r.mark_complete) : true;
  }

  return reviewersContains(task.reviewers, reviewersIds, needReviewOnly);
};
