import { h } from "vue";

import AppliedLabelCell from "./AppliedLabelCell.vue";
import ReviewLabelCell from "./ReviewLabelCell.vue";

import type { ChangeOperation, RoomUpdateBatch } from "./api";
import type { DrVxeTableColumn } from "@app/vue/components/types";

export interface ParentChangeRow {
  id: string;
  label: string;
  batchId: RoomUpdateBatch["id"];
  children: ChildChangeRow[];
}

export interface ChildChangeRow {
  id: string;
  change: ChangeOperation;
  isSuccessful?: boolean;
}

export type ChangeRow = ParentChangeRow | ChildChangeRow;

export function isParentRow(row: ChangeRow): row is ParentChangeRow {
  return "children" in row && Array.isArray(row.children);
}

export function isChildRow(row: ChangeRow): row is ChildChangeRow {
  return (
    "change" in row && row.change !== null && typeof row.change === "object"
  );
}

export class TableColumns {
  constructor() {}

  get columns(): DrVxeTableColumn<ChangeRow>[] {
    return [this.checkboxColumn(), this.labelColumn()];
  }

  private checkboxColumn(): DrVxeTableColumn<ChangeRow> {
    return {
      type: "checkbox",
      title: "",
      field: "label",
      resizable: false,
      treeNode: true,
      width: 60,
    };
  }

  private labelColumn(): DrVxeTableColumn<ChangeRow> {
    return {
      field: "label",
      slots: {
        default: ({ row }) => [h(ReviewLabelCell, { row })],
      },
    };
  }
}

export class TableColumnsApplied {
  constructor() {}

  get columns(): DrVxeTableColumn<ChangeRow>[] {
    return [this.labelColumn()];
  }

  private labelColumn(): DrVxeTableColumn<ChangeRow> {
    return {
      field: "label",
      treeNode: true,
      slots: {
        default: ({ row }) => [h(AppliedLabelCell, { row })],
      },
    };
  }
}
