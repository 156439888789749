<template>
  <a
    :class="$style.link"
    :href="folderUrl(ROOM_DATA.url, folder.id)"
    target="_blank"
  >
    <DrIcon name="folder" size="sm" :class="$style.icon" />
    <div class="truncate">{{ folder.tree_index }} {{ folder.name }}</div>
  </a>
</template>

<script setup lang="ts">
import { computed } from "vue";
import DrIcon from "@shared/ui/dr-icon/DrIcon.vue";

import { folderUrl } from "@setups/room-urls";
import { ROOM_DATA } from "@app/setups";
import { useDocumentsStore } from "@app/vue/store/pinia/room/documents/documents";

import type { Folder } from "@drVue/store/modules/room/documents/DocumentsApiService";

interface Props {
  folderId: number;
}

const props = defineProps<Props>();

const documentsStore = useDocumentsStore();

const folder = computed<Folder>(
  () => documentsStore.folderByIdMap[props.folderId],
);
</script>

<style module lang="scss">
.link {
  width: 100%;
  height: 100%;
  display: inline-flex;
  align-items: center;
  color: unset !important;
  text-decoration: none !important;
}

.icon {
  margin: 0 8px 0 6px;
}
</style>
