<template>
  <div
    ref="containerRef"
    :class="{
      [$style.container]: true,
      [$style.container_isExpandable]: !hoverMode,
      [$style.container_isCollapsed]: !hoverMode && !isPanelExpanded,
      [$style.container_isExpanded]: !hoverMode && isPanelExpanded,
      [$style.container_isMounted]: isMounted,
    }"
  >
    <div
      :class="{
        [$style.panel]: true,
        [$style.nav]: true,
        [$style.panel_isFloating]: hoverMode,
        [$style.panel_isExpanded]: hoverMode && isPanelExpanded,
        [$style.panel_isCollapsed]: hoverMode && !isPanelExpanded,
        [$style.panel_isMounted]: isMounted,
      }"
    >
      <DrMainMenuLogo
        :website-logo="websiteLogo"
        :is-expanded="isMenuItemExpanded"
        :class="$style.navHeader"
        @toggle="toggle"
      />

      <div :class="$style.navOrganization">
        <DrPopup :disabled="!sortedOrganizations.length">
          <template #reference="{ isOpened }">
            <DrMainMenuItem
              drop-cap-as-icon
              show-tooltip
              :text="currentOrganization"
              :is-expanded="isMenuItemExpanded"
              data-testid="dashboard-organization-dropdown"
            >
              <template #right>
                <DrIcon
                  name="caret-down"
                  size="sm"
                  :class="{
                    [$style.caret]: true,
                    [$style.caret_isUp]: isOpened,
                  }"
                />
              </template>
            </DrMainMenuItem>
          </template>

          <template #default="{ hide }">
            <DrPopupSelectOptions
              :title="t('nav.select_org')"
              filterable
              :items="sortedOrganizations"
              @select="($event) => (hide(), selectOrganization($event))"
              data-testid="dashboard-sorted-org-items"
            />
          </template>
        </DrPopup>
      </div>

      <DrMainMenuBillingWarning
        :billing-info="billingInfo"
        :website-name="websiteName"
        :is-expanded="isMenuItemExpanded"
        @billing-activate="emit('billing-activate')"
      />

      <DrMainMenuList v-if="itemsTop.length" :class="$style.navTop">
        <DrMainMenuItem
          v-for="item in itemsTop"
          :key="item.id"
          show-tooltip
          :text="item.name"
          :icon="item.icon"
          :is-expanded="isMenuItemExpanded"
          :is-active="item.isActive"
          :is-beta="item.isBeta"
          :disabled="isNavTopDisabled"
          :href="item.href"
          :has-news="item.hasNews"
          @select="(newTab) => selectItem(item, newTab)"
        />
      </DrMainMenuList>

      <div
        ref="roomsContainerRef"
        :class="$style.navRooms"
        data-testid="nav-rooms"
      >
        <DrMainMenuList
          v-if="!!displayedRooms.length || allowRoomCreate"
          :header="t('shared.rooms')"
          :show-add-btn="allowRoomCreate"
          :is-expanded="isMenuItemExpanded"
          @add="addRoom"
        >
          <DrMainMenuItem
            v-for="room in displayedRooms"
            :key="room.id"
            show-tooltip
            :text="room.name"
            :is-expanded="isMenuItemExpanded"
            :is-active="room.isActive"
            :href="room.url"
            @select="selectRoom(room)"
          >
            <template #icon>
              <DrLogo
                :url="room.logo"
                :name="room.name"
                :class="$style.itemLogo"
              />
            </template>
          </DrMainMenuItem>

          <template v-if="isRoomsPanelAllowed" #action>
            <DrMainMenuItem
              :text="t('nav.see_all_rooms')"
              :is-expanded="isMenuItemExpanded"
              @select="showRoomsPanel"
            >
              <div :class="$style.btnText">{{ t("nav.see_all_rooms") }}</div>
            </DrMainMenuItem>
          </template>
        </DrMainMenuList>
      </div>

      <DrMainMenuList
        v-if="itemsBottom.length"
        :class="$style.navBottom"
        data-testid="nav-bottom"
      >
        <DrMainMenuItem
          v-for="item in itemsBottom"
          :key="item.id"
          show-tooltip
          :text="item.name"
          :icon="item.icon"
          :is-expanded="isMenuItemExpanded"
          :is-active="item.isActive"
          :is-beta="item.isBeta"
          :href="item.href"
          :has-news="item.hasNews"
          @select="selectItem(item)"
        />
      </DrMainMenuList>

      <div :class="$style.navFooter">
        <DrPopup>
          <template #reference="{ isOpened }">
            <DrMainMenuItem
              :is-expanded="isMenuItemExpanded"
              :class="$style.account"
              :readonly="!isModalMenuAccountAllowed"
            >
              <template #icon>
                <DrAvatar
                  :identifier="user.id"
                  :name="user.name"
                  :url="user.avatar"
                />
              </template>
              <template #text>
                <span :class="$style.accountName">
                  {{ user.name }}
                </span>
              </template>
              <template #right>
                <DrIcon
                  v-if="isModalMenuAccountAllowed"
                  name="caret-down"
                  size="sm"
                  :class="{
                    [$style.caret]: true,
                    [$style.caret_isUp]: isOpened,
                  }"
                />
              </template>
            </DrMainMenuItem>
          </template>

          <template #default="{ hide }">
            <DrPopupSelectOptions
              type="icons"
              :title="t('shared.account')"
              :items="itemsAccount"
              @select="($event) => (hide(), selectAccountItem($event))"
            />
          </template>
        </DrPopup>
      </div>

      <DrMainMenuRooms
        :visible="roomsPanelShown"
        :rooms="rooms"
        :show-add-btn="allowRoomCreate"
        @select="selectRoom"
        @hide="hideRoomsPanel"
        @add="addRoom"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed, onMounted, reactive, ref, watch } from "vue";
import { useI18n } from "vue-i18n";
import {
  onClickOutside,
  useElementHover,
  useResizeObserver,
} from "@vueuse/core";

import { DrAvatar } from "../dr-avatar";
import DrIcon from "../dr-icon";
import DrLogo from "../dr-logo";
import { DrPopup, DrPopupSelectOptions } from "../dr-popups";
import DrMainMenuBillingWarning from "./DrMainMenuBillingWarning.vue";
import DrMainMenuItem from "./DrMainMenuItem.vue";
import DrMainMenuList from "./DrMainMenuList.vue";
import DrMainMenuLogo from "./DrMainMenuLogo.vue";
import DrMainMenuRooms from "./DrMainMenuRooms.vue";

import type {
  MainMenuBillingInfo,
  MainMenuItem,
  MainMenuOrganization,
  MainMenuRoom,
  MainMenuUser,
} from "./types";
import type { SelectOptionItem } from "@shared/ui/dr-popups";

interface Props {
  websiteLogo?: string;
  websiteName?: string;
  isExpanded?: boolean;
  hoverMode?: boolean;
  items?: MainMenuItem[];
  rooms?: MainMenuRoom[];
  billingInfo?: MainMenuBillingInfo;
  user?: MainMenuUser;
  organizations?: MainMenuOrganization[];
  allowRoomCreate?: boolean;
}
const props = withDefaults(defineProps<Props>(), {
  websiteLogo: "",
  websiteName: "",
  isExpanded: false,
  hoverMode: false,
  items: () => [],
  rooms: () => [],
  billingInfo: () => ({
    isAccessible: true,
    isRoomBilling: false,
    billingObjectId: null,
    trialDaysLeft: null,
    hasBillingAccess: false,
    supportEmail: "",
    subscriptionWarning: null,
  }),
  user: () => ({
    id: "",
    name: "",
    avatar: "",
  }),
  organizations: () => [],
  allowRoomCreate: false,
});

const emit = defineEmits<{
  /** Emit the menu was expanded */
  (event: "expand"): void;
  /** Emit the menu was collapsed */
  (event: "collapse"): void;
  /** Emit the id of the menu item that the user has choosen */
  (event: "select-item", id: MainMenuItem["id"], newTab: boolean): void;
  /** Emit the id of the room that the user has choosen */
  (event: "select-room", id: MainMenuRoom["id"]): void;
  /** Emit the id of the organization that the user has choosen */
  (event: "select-organization", id: MainMenuOrganization["id"]): void;
  /** User request to open billing page  */
  (event: "billing-activate"): void;
  /** Emit request to add new room */
  (event: "add-room"): void;
}>();

const MENU_ITEM_HEIGHT = 36; // 32px height + gap 4px
const ROOMS_LIST_HEADER_AND_FOOTER = 2.5 * MENU_ITEM_HEIGHT;

const { t } = useI18n();

// template refs
const containerRef = ref();
const roomsContainerRef = ref<HTMLDivElement | null>(null);

// data
const panel = reactive({
  expand: props.isExpanded,
  pending: false, // when transitioning
});
const roomsPanelShown = ref(false);
const isMounted = ref(false);
const roomsPreviewCount = ref(4);

// computed
const isPanelExpanded = computed(() => panel.expand);
const isMenuItemExpanded = computed(() => panel.expand && !panel.pending);
const currentOrganization = computed(
  () => props.organizations.find((o) => o.isActive)?.name || "",
);
const sortedOrganizations = computed<SelectOptionItem[]>(() =>
  props.organizations
    .slice()
    .sort((orgA, orgB) => orgA.name.localeCompare(orgB.name))
    .map((org) => ({ ...org, isSelected: org.isActive })),
);
const itemsTop = computed(() => props.items.filter((i) => i.place === "top"));
const itemsBottom = computed(() =>
  props.items.filter((i) => i.place === "bottom"),
);
const itemsAccount = computed<SelectOptionItem[]>(() =>
  props.items
    .filter((i) => i.place === "account")
    .map((item) => ({ ...item, isSelected: item.isActive })),
);
const isModalMenuAccountAllowed = computed(() => !!itemsAccount.value.length);
const displayedRooms = computed(() =>
  props.rooms.slice(0, roomsPreviewCount.value),
);
const isRoomsPanelAllowed = computed(
  () => props.rooms.length > roomsPreviewCount.value,
);
const isNavTopDisabled = computed(
  () => !props.billingInfo.isAccessible && !props.rooms.find((r) => r.isActive),
);

// methods
const expand = () => {
  if (panel.expand) return;
  panel.pending = true;
  panel.expand = true;
  setTimeout(() => {
    panel.pending = false;
  }, 100);
};
const collapse = () => {
  if (!panel.expand) return;
  panel.expand = false;
};
const toggle = () => {
  if (panel.expand) {
    collapse();
  } else {
    expand();
  }
};
const showRoomsPanel = () => {
  roomsPanelShown.value = true;
};
const hideRoomsPanel = () => {
  roomsPanelShown.value = false;
};
const selectItem = (item: MainMenuItem, newTab = false) => {
  if (!item.isActive) {
    emit("select-item", item.id, newTab);

    if (props.hoverMode) {
      collapse();
    }
  }
};
const selectAccountItem = (itemId: SelectOptionItem["id"]) => {
  const item = props.items.find((item) => item.id === itemId);
  if (item) {
    selectItem(item, false);
  }
};
const addRoom = () => {
  if (roomsPanelShown.value) {
    hideRoomsPanel();
  }
  emit("add-room");
};
const selectRoom = (room: MainMenuRoom) => {
  if (!room.isActive) {
    emit("select-room", room.id);
    if (roomsPanelShown.value) {
      hideRoomsPanel();
    }
    if (props.hoverMode) {
      collapse();
    }
  }
};
const selectOrganization = (organizationId: SelectOptionItem["id"]) => {
  const organization = props.organizations.find(
    (org) => org.id === organizationId,
  );
  if (organization && !organization.isActive) {
    emit("select-organization", organization.id);

    if (props.hoverMode) {
      collapse();
    }
  }
};

// watchers, hooks
watch(
  () => isPanelExpanded.value,
  (newValue) => {
    if (newValue) {
      emit("expand");
    } else {
      emit("collapse");
    }
  },
);

if (props.hoverMode) {
  const isHovered = useElementHover(containerRef);
  watch(
    () => isHovered.value,
    (newValue) => {
      if (newValue && !panel.expand && !panel.pending) {
        expand();
      }
    },
  );
}

onClickOutside(containerRef, () => {
  if (roomsPanelShown.value) {
    hideRoomsPanel();
  }
  if (props.hoverMode) {
    collapse();
  }
});

useResizeObserver(roomsContainerRef, (entries) => {
  const entry = entries[0];
  const { height: containerHeight } = entry.contentRect;

  roomsPreviewCount.value = Math.floor(
    (containerHeight - ROOMS_LIST_HEADER_AND_FOOTER) / MENU_ITEM_HEIGHT,
  );
});

onMounted(() => {
  // for prevent initial expand/collapse panel animation
  setTimeout(() => {
    isMounted.value = true;
  }, 500);
});

defineExpose({
  expand,
  collapse,
});
</script>

<style lang="scss" module>
@use "@app/styles/scss/colors";
@use "@app/styles/scss/typography" as typo;

$panel-min-height: 650px;
$panel-width: 48px;
$panel-expanded-width: 228px;
$panel-zindex: 1000;
$space: 8px;
$space-sm: 4px;
$space-lg: 12px;
$item-size: 32px;

$animate-duration: 150ms;
$animate-function: ease-out;

@mixin transition {
  transition-timing-function: $animate-function;
  transition-duration: $animate-duration;
}

@keyframes expandPanel {
  from {
    width: $panel-width;
  }

  to {
    width: $panel-expanded-width;
  }
}
@keyframes collapsePanel {
  from {
    width: $panel-expanded-width;
  }

  to {
    width: $panel-width;
  }
}

.container {
  position: relative;
  width: $panel-width;
  height: 100%;
  z-index: $panel-zindex;
  overflow: auto;
}

.container_isExpandable {
  will-change: width;
  animation-duration: 0ms;
  animation-timing-function: $animate-function;
  animation-fill-mode: forwards;

  &.container_isMounted {
    animation-duration: $animate-duration;
  }
}
.container_isExpanded {
  animation-name: expandPanel;
}
.container_isCollapsed {
  animation-name: collapsePanel;
}

.panel {
  height: 100%;
  width: 100%;
  background-color: colors.$sc-1000;
}
.panel_isFloating {
  position: absolute;
  top: 0;
  left: 0;
  width: $panel-width;
  z-index: $panel-zindex;
  overflow: hidden;
  will-change: width;
  animation-duration: 0ms;
  animation-timing-function: $animate-function;
  animation-fill-mode: forwards;

  &.panel_isMounted {
    animation-duration: $animate-duration;
  }
}
.panel_isExpanded {
  animation-name: expandPanel;
}
.panel_isCollapsed {
  animation-name: collapsePanel;
}

/**
  The logo component has standard sizes of md=24px and sm=18px,
  but the menu needs 20px, so we scale it.
 */
.itemLogo {
  transform-origin: 50%;
  transform: scale(0.835);
}

.btnText {
  width: 100%;
  height: $item-size;
  font: typo.$caption_regular;
  line-height: $item-size;
  text-align: center;
  color: colors.$pr-400;

  &:hover {
    color: inherit;
  }
}

.caret {
  @include transition;
  color: colors.$pr-600;
  transform: rotateX(0deg);
  transition-property: transform;
}
.caret_isUp {
  color: #fff;
  transform: rotateX(180deg);
}

.account {
  height: auto;
  padding: calc($space-lg - 1px) $space $space-lg;
  border-radius: 0;

  &:hover .caret {
    color: #fff;
  }
}

.accountName {
  display: inline-block;
  vertical-align: middle;
  max-width: 100%;
  padding-left: $space-sm;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  font: typo.$body_medium;
  color: #fff;
}

.nav {
  display: grid;
  grid-auto-flow: row;
  grid-template-rows: min-content min-content min-content min-content 1fr min-content min-content;
  grid-template-areas:
    "header"
    "organization"
    "billingWarning"
    "top"
    "rooms"
    "bottom"
    "footer";
  min-height: $panel-min-height;
}
.navHeader {
  grid-area: header;
}
.navOrganization {
  @include transition;
  position: relative;
  z-index: 100;
  grid-area: organization;
  padding: 0 $space $space-sm;
}
.navBillingWarning {
  grid-area: billingWarning;
}
.navTop {
  grid-area: top;
  padding-bottom: 32px;

  @media (max-height: $panel-min-height) {
    padding-bottom: $space;
  }
}
.navRooms {
  grid-area: rooms;
  overflow: hidden;
}
.navBottom {
  padding-top: 42px;
  grid-area: bottom;

  @media (max-height: $panel-min-height) {
    padding-top: $space;
  }
}
.navFooter {
  position: relative;
  z-index: 100;
  grid-area: footer;
  margin-top: 13px;
  border-top: solid 1px rgb(colors.$sc-50, 12%);
}
</style>
