<template>
  <div :class="$style.wrapper">
    <button :class="$style.button" @click="isOpen = !isOpen">
      <div>
        {{
          isOpen
            ? $t("permissions.file.info.hide")
            : $t("permissions.file.info.show")
        }}
      </div>

      <DrIcon
        name="caret-down"
        :class="{
          [$style.buttonIcon]: true,
          [$style.buttonIcon__open]: isOpen,
        }"
      />
    </button>

    <div v-show="isOpen" :class="$style.container">
      <div
        v-for="permission in filePermissionKeyList"
        :key="permission"
        :class="$style.card"
      >
        <DrIcon
          :name="filePermissionKeyIconMap[permission]"
          size="sm"
          :class="$style.icon"
        />

        <div>
          <div :class="$style.title">
            {{ filePermissionKeyInfoTitleMap[permission] }}
            <span
              v-if="filePermissionKeyInfoCaptionMap[permission]"
              :class="$style.titleCaption"
              >{{ filePermissionKeyInfoCaptionMap[permission] }}</span
            >
          </div>

          <div :class="$style.description">
            {{ filePermissionKeyInfoDescriptionMap[permission] }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref } from "vue";

import DrIcon from "@app/vue/shared/ui/dr-icon/DrIcon.vue";
import {
  filePermissionKeyIconMap,
  filePermissionKeyInfoCaptionMap,
  filePermissionKeyInfoDescriptionMap,
  filePermissionKeyInfoTitleMap,
  filePermissionKeyList,
} from "@app/vue/store/pinia/room/filePermissions/FilePermissionsApi";

const isOpen = ref(true);
</script>

<style lang="scss" module>
@use "@app/styles/scss/colors";

.wrapper {
  background: colors.$pr-50;
  border: colors.$pr-150 solid 1px;
  border-radius: 8px;
  padding: 4px 12px;
  margin: 12px 0 16px;
}

.button {
  background: unset;
  border: unset;
  border-radius: unset;
  text-align: unset;
  padding: 4px 0;
  font-size: 14px;
  font-weight: 500;
  line-height: 24px;
  color: colors.$pr-600;
  display: grid;
  grid-template-columns: 132px auto;
  align-items: center;
  white-space: nowrap;
  width: 100%;
}

.buttonIcon {
  color: colors.$pr-400;

  &.buttonIcon__open {
    transform: rotate(180deg);
  }
}

.container {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 16px;
  border-top: colors.$pr-150 solid 1px;
  padding: 8px 0;
}

.card {
  display: grid;
  grid-template-columns: 24px 1fr;
}

.icon {
  color: colors.$pr-400;
  margin-top: 3px;
}

.title {
  font-size: 14px;
  font-weight: 500;
  line-height: 24px;
  color: colors.$pr-900;
}

.titleCaption {
  font-weight: 400;
  color: colors.$pr-500;
}

.description {
  font-size: 13px;
  font-weight: 400;
  line-height: 20px;
  color: colors.$pr-500;
}
</style>
