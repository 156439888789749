<template>
  <DrDrawer
    :shown="isShown"
    :title="$t('data_room.change_index.modal_title')"
    size="xl"
    no-padding
    @cancel="close"
    @close="close"
    @submit="submitFieldsAndSave"
    @closed="$emit('close')"
  >
    <div :class="$style.container">
      <FolderBreadcrumbs :folder="folderParent" />

      <div :class="$style.table">
        <div :class="[$style.item, $style.header]">
          <div />
          <div>{{ $t("data_room.change_index.old_index") }}</div>
          <div>{{ $t("data_room.change_index.new_index") }}</div>
          <div>{{ $t("shared.title") }}</div>
        </div>

        <Draggable
          :model-value="foldersAndDocumentsSorted"
          item-key="id"
          :class="$style.draggable"
          :options="sortableOptions"
          :handle="`.${$style.draggableHandle}`"
          @change="hadnleDrop"
        >
          <template #item="{ element: item }">
            <div
              :class="{
                [$style.item]: true,
                [$style.row]: true,
                [$style.item_hasChanges]: item.index !== entity[item.uid],
                [$style.item_isDisabled]: !item.edit,
                [$style.draggableItem]: item.edit,
              }"
            >
              <div :class="$style.draggableHandle">
                <DrIcon name="grip-vertical" size="sm" />
              </div>

              <div>
                {{ item.tree_index }}
              </div>

              <div>
                <DynamicField
                  :ref="
                    (ref) =>
                      ref &&
                      fields.push(ref as ComponentInstance<typeof DynamicField>)
                  "
                  :entity="entity"
                  :schema="getFieldSchema(item)"
                  :submit-fn="
                    (value) =>
                      updateIndex(item.uid, Number.parseInt(value[item.uid]))
                  "
                  mode="table"
                  @request-edit="
                    quitEditFields();
                    $event();
                  "
                />
              </div>

              <div>
                <AttachmentCard :item="item" />
              </div>
            </div>
          </template>
        </Draggable>
      </div>
    </div>

    <template #controls>
      <ElButton :disabled="isFormSubmitting" @click="resetOrder">
        {{ $t("data_room.change_index.reset_order") }}
      </ElButton>

      <ElButton
        :disabled="isFormSubmitting"
        :class="$style.lastButton"
        @click="orderByName"
      >
        {{ $t("data_room.change_index.order_by_name") }}
      </ElButton>
    </template>
  </DrDrawer>
</template>

<script lang="ts" setup>
import { ElButton } from "element-plus";
import {
  type ComponentInstance,
  computed,
  onMounted,
  ref,
  useCssModule,
} from "vue";
import { useI18n } from "vue-i18n";
import Draggable from "vuedraggable";

import { insightTrack, RoomDataroomMenuEvent } from "@app/insight";
import { $notifySuccess, $notifyWarning, delayedCall } from "@app/vue/common";
import DrDrawer from "@app/vue/shared/ui/dr-drawer/DrDrawer.vue";
import { validateNumber } from "@app/vue/shared/ui/dr-dynamic-form/utils";
import { useFormHelper } from "@app/vue/shared/ui/dr-form/useFormHelper";
import DrIcon from "@app/vue/shared/ui/dr-icon/DrIcon.vue";
import {
  type Document,
  DocumentsApiService,
  type Folder,
} from "@app/vue/store/modules/room/documents/DocumentsApiService";
import { useDocumentsStore } from "@app/vue/store/pinia/room/documents/documents";
import DynamicField from "@drVue/components/client-dashboard/dynamic-form/DynamicField.vue";
import {
  type FieldSchema,
  FieldSchemaType,
} from "@drVue/components/client-dashboard/dynamic-form/types";
import AttachmentCard from "../../tasks/TaskDetails/lists/attachments/AttachmentCard.vue";
import FolderBreadcrumbs from "./FolderBreadcrumbs.vue";

import type { FolderMenuParams } from "../utils";

interface Props {
  params: FolderMenuParams;
}

interface Emits {
  (e: "close"): void;
  (e: "update:persisted", value: boolean): void;
}

type Entity = Record<string, number>;

const props = defineProps<Props>();

const emit = defineEmits<Emits>();

const $style = useCssModule();

const { t } = useI18n();

const sortableOptions = {
  filter: $style.draggableItem,
  preventOnFilter: false,
};

const documentsStore = useDocumentsStore();

const isShown = ref(true);

const fields = ref<ComponentInstance<typeof DynamicField>[]>([]);

const folderParent = computed<Folder>(() => {
  if (props.params.folders[0]) {
    return props.params.folders[0].parent_id
      ? documentsStore.folderByIdMap[props.params.folders[0].parent_id]
      : props.params.folders[0];
  } else {
    return documentsStore.folderByIdMap[props.params.documents[0].folder_id];
  }
});

const foldersAndDocuments = computed<(Folder | Document)[]>(() => {
  if (!documentsStore.treeRaw) return [];

  return documentsStore.folderItemsByIdMap[folderParent.value.id];
});

const readonlyIndexList = computed<number[]>(() => {
  return foldersAndDocuments.value.reduce<number[]>((result, item) => {
    if (!item.edit) result.push(item.index);
    return result;
  }, []);
});

const getEntity = (): Entity => {
  return foldersAndDocuments.value.reduce<Entity>((result, item) => {
    result[item.uid] = item.index;
    return result;
  }, {});
};

const entity = ref(getEntity());

const foldersAndDocumentsSorted = computed<(Folder | Document)[]>(() => {
  return foldersAndDocuments.value
    .slice()
    .sort((a, b) => entity.value[a.uid] - entity.value[b.uid]);
});

const moveSurroundings = (
  entityValue: Entity,
  index: number,
  step: 1 | -1,
): void => {
  const indexMap = Object.fromEntries(
    Object.entries(entityValue).map(([key, value]) => [value, key]),
  );

  let uid = indexMap[index];

  while (uid) {
    index = index + step;
    while (readonlyIndexList.value.includes(index)) {
      index = index + step;
    }
    entityValue[uid] = index;
    uid = indexMap[index];
  }
};

const { hookFormSubmitPromise, isFormSubmitting } = useFormHelper<Entity>();

const updateIndex = async (uid: string, newIndex: number) => {
  if (readonlyIndexList.value.includes(newIndex)) {
    $notifyWarning(t("data_room.change_index.index_disabled"));
    return;
  }

  const oldIndex = entity.value[uid];

  if (oldIndex === newIndex) return;

  const newEntity = { ...entity.value };
  delete newEntity[uid];

  if (Object.values(newEntity).includes(newIndex)) {
    if (newIndex > oldIndex) {
      moveSurroundings(newEntity, newIndex, -1);
    }

    if (newIndex < oldIndex) {
      moveSurroundings(newEntity, newIndex, 1);
    }
  }

  newEntity[uid] = newIndex;

  entity.value = newEntity;
};

const quitEditFields = (): void => {
  fields.value.forEach((field) => field.quitEditMode());
};

const hadnleDrop = (event: {
  moved: { element: Folder | Document; newIndex: number; oldIndex: number };
}) => {
  quitEditFields();

  const currentEntity = foldersAndDocumentsSorted.value[event.moved.newIndex];

  if (currentEntity) {
    updateIndex(event.moved.element.uid, entity.value[currentEntity.uid]);
  } else {
    updateIndex(event.moved.element.uid, event.moved.newIndex + 1);
  }
};

const orderByName = () => {
  quitEditFields();

  const newEntity: Entity = {};

  let index = 0;

  foldersAndDocuments.value
    .slice()
    .sort((a, b) => a.name.localeCompare(b.name))
    .forEach((item) => {
      if (!item.edit) {
        newEntity[item.uid] = item.index;
        return;
      }

      index++;

      while (readonlyIndexList.value.includes(index)) {
        index++;
      }

      newEntity[item.uid] = index;
    });

  entity.value = newEntity;

  insightTrack(RoomDataroomMenuEvent.IndexResetByName);
};

const resetOrder = () => {
  quitEditFields();

  const newEntity: Entity = {};

  let index = 0;

  foldersAndDocuments.value
    .slice()
    .sort((a, b) => a.index - b.index)
    .forEach((item) => {
      if (!item.edit) {
        newEntity[item.uid] = item.index;
        return;
      }

      index++;

      while (readonlyIndexList.value.includes(index)) {
        index++;
      }

      newEntity[item.uid] = index;
    });

  entity.value = newEntity;

  insightTrack(RoomDataroomMenuEvent.IndexResetByOldIndex);
};

const api = new DocumentsApiService();

const save = () => {
  if (isFormSubmitting.value) return;

  emit("update:persisted", true);

  const documents: Parameters<typeof api.bulkEditIndex>[1] = [];
  const folders: Parameters<typeof api.bulkEditIndex>[2] = [];

  foldersAndDocuments.value.forEach((item) => {
    if (!item.edit || entity.value[item.uid] === item.index) return;

    if (item.uid.startsWith("fldr")) {
      folders.push({
        id: item.id,
        index: entity.value[item.uid],
      });
    }

    if (item.uid.startsWith("doc")) {
      documents.push({
        id: item.id,
        index: entity.value[item.uid],
      });
    }
  });

  if (!documents.length && !folders.length) {
    emit("close");
    return;
  }

  hookFormSubmitPromise(
    api.bulkEditIndex(folderParent.value.id, documents, folders),
    t("data_room.change_index.failed"),
  )
    .then(() => {
      documentsStore.syncTree();

      $notifySuccess(t("data_room.change_index.success"));

      emit("close");
    })
    .finally(() => {
      emit("update:persisted", false);
    });
};

const submitFieldsAndSave = () => {
  Promise.all(fields.value.map((field) => field.submitValidatedField())).then(
    save,
    () => {},
  );
};

const getFieldSchema = (item: Folder | Document): FieldSchema => {
  const prefix = item.tree_index.split(".").slice(0, -1).join(".");

  return {
    type: FieldSchemaType.Number,
    prop: item.uid,
    extra: {
      symbol: prefix ? prefix + "." : "",
      digit_grouping: "space",
    },
    rules: validateNumber()
      .required(t("form.value_is_required"))
      .min(1, t("form.over_number", { over: 0 })),
    isReadOnly: !item.edit,
  };
};

const close = () => {
  isShown.value = false;
};

onMounted(() => {
  delayedCall(() => {
    if (props.params.folders[0] === folderParent.value) {
      fields.value[0]?.enterEditMode();
      return;
    }

    const index = foldersAndDocumentsSorted.value.findIndex(
      (item) =>
        item.uid ===
        (props.params.folders[0]?.uid ?? props.params.documents[0]?.uid),
    );

    if (index !== -1) fields.value[index]?.enterEditMode();
  }, 200);
});

defineExpose({
  close,
});
</script>

<style lang="scss" module>
@use "@app/styles/scss/colors";
@use "@app/styles/scss/spacing";

.container {
  display: grid;
  height: 100%;
  align-content: flex-start;
  grid-template-rows: auto 1fr;
  padding: spacing.$xl spacing.$xl 0;
}

.table {
  display: grid;
  grid-template-columns: 32px 148px 148px 1fr;
  align-content: flex-start;
  border: colors.$pr-200 solid 1px;
  border-radius: 8px;
  margin-top: 16px;
  overflow-x: hidden;
  overflow-y: auto;
  isolation: isolate;
  overscroll-behavior: contain;
}

.item {
  display: grid;
  grid-template-columns: subgrid;
  grid-column: span 4;

  &:not(:last-child) {
    border-bottom: 1px solid colors.$pr-100;
  }

  & > * {
    display: grid;
    align-items: center;
    padding: 0px 8px;

    &:not(:first-child) {
      border-left: 1px solid colors.$pr-100;
    }
  }
}

.item_hasChanges {
  background-color: colors.$pr-50;
}

.item_isDisabled {
  cursor: not-allowed;
  opacity: 0.5;
}

.row {
  height: 48px;
}

.header {
  font-size: 13px;
  font-weight: 600;
  line-height: 32px;
  color: colors.$pr-900;
  position: sticky;
  top: 0;
  background-color: white;
  z-index: 1;
}

.lastButton {
  margin-right: auto;
}

.draggable {
  display: grid;
  grid-template-columns: subgrid;
  grid-column: span 4;
}

.draggableItem {
}

.draggableHandle {
  cursor: grab;
  color: colors.$pr-400;
}
</style>
