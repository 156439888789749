<template>
  <div class="container-fluid">
    <!-- eslint-disable-next-line vue/no-v-html -->
    <a class="dealroom-logo dealroom-logo--white" href="/" v-html="svg" />
    <div id="section-content" class="section-content">
      <RouterView />
    </div>
    <div class="auth-privacy-links">
      <a href="/privacy" target="_blank">{{ t("shared.privacy_policy") }}</a>
      <a href="/terms" target="_blank">{{ t("shared.terms_of_use") }}</a>
    </div>
  </div>
</template>

<script lang="ts">
import axios from "axios";
import { defineComponent } from "vue";
import { useI18n } from "vue-i18n";

import { APP_SETTINGS } from "@setups/data";

const http = axios.create({
  baseURL: location.origin,
  headers: {
    Accept: "text/html,application/xhtml+xml,application/xml",
  },
  withCredentials: false,
});

interface Data {
  svg: string;
}

export default defineComponent({
  name: "App",
  setup() {
    const { t } = useI18n();
    return { t };
  },
  data(): Data {
    return {
      svg: "",
    };
  },
  beforeMount() {
    http.get(APP_SETTINGS.WEBSITE.LOGO_SVG).then((r) => (this.svg = r.data));
  },
});
</script>
