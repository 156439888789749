import type { Node as ProsemirrorNode } from "@tiptap/pm/model";

type EntityType = "task" | "finding";

export function getCommentStorageKey(
  entityType: EntityType,
  entityId: number | string,
) {
  return `dr:comment_${entityType}_${entityId}`;
}

export const storeCommentDraft = (key: string, text: ProsemirrorNode) => {
  if (text) localStorage.setItem(key, JSON.stringify(text));
  else localStorage.removeItem(key);
};

export const removeCommentDraft = (key: string) => {
  localStorage.removeItem(key);
};

export const loadCommentDraft = (key: string): ProsemirrorNode | null => {
  const rawValue = localStorage.getItem(key);
  if (rawValue) {
    try {
      return JSON.parse(rawValue) as ProsemirrorNode;
    } catch (e) {
      return null;
    }
  }

  return null;
};
