<template>
  <ul :class="$style.list">
    <li
      v-for="version in versions"
      :key="version.id"
      @click="openDocumentVersion(version.id)"
    >
      {{ drUserTime(version.date_created, "full-dt") }}
      <span v-if="version.is_primary" :class="$style.primaryTextLabel">
        {{ $t("file_viewer.sidebar.versions.version_default") }}
      </span>
      <span
        v-if="version.id === showingVersionId && !version.is_primary"
        :class="$style.primaryTextLabel"
      >
      {{ $t("file_viewer.sidebar.versions.version_showing") }}
      </span>
    </li>
  </ul>
</template>

<script setup lang="ts">
import { drUserTime } from "@drVue/filters/drUserTime";

import type { DocumentVersionInfo } from "../types";

defineProps<{
  versions: DocumentVersionInfo[];
  showingVersionId?: number;
}>();

function openDocumentVersion(versionId: number) {
  const url = `//${location.host}${location.pathname}?version=${versionId}`;
  window.open(url, "_self");
}
</script>

<style module lang="scss">
@use "@app/styles/scss/_colors";

.list {
  li {
    margin: 6px 0;
    cursor: pointer;
  }
}

.primaryTextLabel {
  color: colors.$pr-500;
}
</style>
