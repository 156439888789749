<template>
  <DrPopup placement="bottom" transition=" ">
    <template #reference>
      <DrToolbarFilterButton
        :label="label"
        :counter="modelValue.length"
        :is-active="isActive"
        @clear="clearFilter"
      />
    </template>

    <template #default>
      <DrPopupSelectOptions
        filterable
        :type="$slots.icon ? 'iconsWithCheckbox' : 'checkboxRight'"
        :items="options"
        @select="toggleOption"
      >
        <template #icon="{ option }">
          <slot name="icon" v-bind="{ option }" />
        </template>
      </DrPopupSelectOptions>
    </template>
  </DrPopup>
</template>

<script lang="ts" setup generic="T extends SelectOptionItem">
import { pull } from "lodash-es";
import { computed } from "vue";

import { DrPopup, DrPopupSelectOptions } from "../dr-popups";
import DrToolbarFilterButton from "./DrToolbarFilterButton.vue";

import type { SelectOptionItem } from "../dr-popups";

interface Props {
  modelValue: T["id"][];
  label: string;
  options: T[];
}
const props = defineProps<Props>();

const emit = defineEmits<{
  (event: "update:modelValue", selected: Props["modelValue"]): void;
}>();

const isActive = computed(() => !!props.modelValue.length);

const toggleOption = (optionId: T["id"]) => {
  const values = [...(props.modelValue || [])];

  if (props.modelValue.includes(optionId)) {
    pull(values, optionId);
  } else {
    values.push(optionId);
  }
  emit("update:modelValue", values);
};

const clearFilter = () => {
  emit("update:modelValue", []);
};
</script>
