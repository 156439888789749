<template>
  <ElPopover
    :visible="visible"
    :width="240"
    :height="200"
    :popper-class="[$style.popper]"
    placement="bottom"
  >
    <template #reference>
      <FilePermissionItem
        :permission="currentPermission"
        :enabled="modelValue[FilePermissionKey.VIEW]"
        :propagated="propagatedList.includes(currentPermission)"
        :disabled="disabled"
        silent
        with-padding
        @click="visible = true"
      />
    </template>

    <div ref="container">
      <FilePermission
        :model-value="modelValue"
        :propagated-list="propagatedList"
        :error-message="errorMessage"
        :disabled="disabled"
        column
        @update:model-value="
          $emit('update:model-value', $event);
          visible = false;
        "
      />
    </div>
  </ElPopover>
</template>

<script lang="ts" setup>
import { computed, ref, useTemplateRef } from "vue";
import { onClickOutside } from "@vueuse/core";

import { type FilePermissionsShort } from "@app/vue/store/pinia/room/filePermissions/FilePermissionsApi";
import { FilePermissionKey } from "@app/vue/store/pinia/room/filePermissions/FilePermissionsApi";
import FilePermission from "./FilePermission.vue";
import FilePermissionItem from "./FilePermissionItem.vue";

interface Props {
  modelValue: FilePermissionsShort;
  propagatedList: FilePermissionKey[];
  errorMessage?: string;
  disabled: boolean;
}

interface Emit {
  (e: "update:model-value", value: FilePermissionsShort): void;
}

const props = defineProps<Props>();

defineEmits<Emit>();

const refContainer = useTemplateRef<HTMLDivElement>("container");

const visible = ref(false);

const currentPermission = computed<FilePermissionKey>(() => {
  const permission = props.modelValue.lastIndexOf(true);

  return permission === -1 ? FilePermissionKey.VIEW : permission;
});

onClickOutside(refContainer, () => {
  visible.value = false;
});
</script>

<style lang="scss" module>
@use "@app/styles/scss/colors";
@use "@app/styles/scss/spacing";

.popper {
  padding: spacing.$s !important;
}
</style>
