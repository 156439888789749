RoomTaskController.$inject = ["$timeout", "$state", "TasksService"];

import { isTask } from "@app/ng/tasks/services/helpers/getItemType";
import tasksFilterService from "@app/ng/tasks/services/ts/TasksFilterService";

export default function RoomTaskController($timeout, $state, TasksService) {
  var self = this;

  self.$state = $state;
  self.TasksService = TasksService;

  tasksFilterService.setOrder({ by: "order", reversed: false });

  self.openTaskDetails = (row) => {
    if (isTask(row)) self.TasksService.openTaskDetails(row.key);
  };

  $timeout(() => {
    if ($state.$current.name === "tasks") $state.go("tasks.list");
  });
}
