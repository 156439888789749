import { keyBy } from "lodash-es";
import { orderBy } from "lodash-es";

import type { DealsState } from "./DealsState";
import type { Deal, Room, RoomGroup } from "./types";
import type { OrgUser } from "@drVue/store/modules/client-dashboard/org-users/types";
import type { RootState } from "@drVue/store/state";
import type { Dictionary } from "@drVue/types";
import type { GetterTree } from "vuex";

export interface DealsGetterTree extends GetterTree<DealsState, RootState> {
  getDealById(state: DealsState): (dealId: number) => Deal | undefined;
  getDealByUid(state: DealsState): (dealUid: string) => Deal | undefined;
  getDealByRoomId(state: DealsState): (roomId: number) => Deal | undefined;
  getDealsByPhase(state: DealsState): (phaseId: number | null) => Deal[];
  getRoomById(state: DealsState): (roomId: number) => Room | undefined;
  getRoomByUid(state: DealsState): (roomUid: string) => Room | undefined;
  getRooms(state: DealsState): Room[];
  getRoomsOfOrgUser: (
    state: DealsState,
    getters: any,
  ) => (orgUserId: string) => Dictionary<Room[]>;
  getRoomGroupOfOrgUser: (
    state: DealsState,
    getters: any,
  ) => (roomId: number, orgUserId: string) => RoomGroup | undefined;
  getDealsOfContact: (
    state: DealsState,
    getters: any,
  ) => (orgUserId: string) => Room[];
  getMapForActiveRoomsOfOrgUser(
    state: DealsState,
    getters: any,
  ): Dictionary<Room[]>;
  getMapForDealsOfContact(state: DealsState, getters: any): Dictionary<Deal[]>;
  getMapForUsersIdsOfGroup(
    state: DealsState,
    getters: any,
  ): Dictionary<string[]>;
  getUsersOfGroup: (
    state: DealsState,
    getters: any,
    rootState: RootState,
  ) => (groupId: number) => OrgUser[];
  getContactsOfDeal: (
    state: DealsState,
    getters: any,
  ) => (dealId: number) => OrgUser[];
}

export const dealsGetters: DealsGetterTree = {
  getDealById:
    (state) =>
    (dealId: number): Deal | undefined => {
      return state.items.find((d) => d.id === dealId);
    },

  getDealByUid:
    (state: DealsState) =>
    (dealUid: string): Deal | undefined => {
      return state.items.find((d) => d.uid === dealUid);
    },

  getDealByRoomId:
    (state) =>
    (roomId: number): Deal | undefined => {
      return state.items.find((d) => d.room?.id === roomId);
    },

  getDealsByPhase:
    (state) =>
    (phaseId: number | null): Deal[] => {
      return state.items.filter((d) => d.phase === phaseId);
    },

  getRoomById:
    (state) =>
    (roomId: number): Room | undefined => {
      return state.items.find((d) => d.room?.id === roomId)?.room;
    },

  getRoomByUid:
    (state) =>
    (roomUid: string): Room | undefined => {
      return state.items.find((d) => d.room?.uid === roomUid)?.room;
    },

  getRooms: (state) => {
    return state.items.filter((d) => d.room).map((d) => d.room!);
  },

  getDealsMap: (state) => {
    return new Map(state.items.map((i) => [i.uid, i]));
  },

  getRoomsOfOrgUser: (state, getters) => (orgUserId: string) => {
    const roomsOfOrgUser = getters.getMapForActiveRoomsOfOrgUser;
    return roomsOfOrgUser[orgUserId] || [];
  },

  getRoomGroupOfOrgUser:
    (state, getters) => (roomId: number, orgUserId: string) => {
      const room = getters.getRoomById(roomId) as Room;
      if (!room) return undefined;

      return room.groups.find((g) =>
        room.members.find(
          (m) => m.user_id === orgUserId && m.group_id === g.id,
        ),
      );
    },

  getDealsOfContact:
    (state: DealsState, getters: any) => (orgUserId: string) => {
      const dealsByContactId = getters.getMapForDealsOfContact;
      return dealsByContactId[orgUserId] || [];
    },

  getMapForActiveRoomsOfOrgUser: (state, getters) => {
    const roomsOfOrgUser: Dictionary<Room[]> = {};
    const rooms = (getters.getRooms as Room[]).filter((r) => !r.is_archived);

    for (let i = 0; i < rooms.length; i++) {
      const room = rooms[i];
      if (!room.members) continue;

      for (let j = 0; j < room.members.length; j++) {
        const user = room.members[j];
        const userId = user.user_id;

        if (roomsOfOrgUser[userId]) {
          roomsOfOrgUser[userId].push(room);
        } else {
          roomsOfOrgUser[userId] = [room];
        }
      }
    }

    return roomsOfOrgUser;
  },

  getMapForUsersIdsOfGroup: (state, getters) => {
    const rooms = getters.getRooms as Room[];
    const usersIdsOfGroup: Dictionary<string[]> = {};
    for (const room of rooms) {
      for (const group of room.groups) {
        usersIdsOfGroup[group.id] = [];
      }

      for (const member of room.members) {
        usersIdsOfGroup[member.group_id].push(member.user_id);
      }
    }

    return usersIdsOfGroup;
  },

  getMapForDealsOfContact: (state) => {
    const dealsByContactId: Dictionary<Deal[]> = {};

    for (const deal of state.items) {
      for (const contactId of deal.contacts) {
        if (dealsByContactId[contactId]) {
          dealsByContactId[contactId].push(deal);
        } else {
          dealsByContactId[contactId] = [deal];
        }
      }
    }

    return dealsByContactId;
  },

  getUsersOfGroup:
    (state, localGetters, rootState) =>
    (groupId: number): OrgUser[] => {
      const usersIdsOfGroupMap = localGetters.getMapForUsersIdsOfGroup;
      const usersIdsOfGroup = usersIdsOfGroupMap[groupId];
      const usersByIdMap = keyBy(
        rootState.clientDashboard.orgUsers.items,
        "id",
      );

      const groupUsers = usersIdsOfGroup.map(
        (userId: number) => usersByIdMap[userId],
      );

      return orderBy(groupUsers, ["pending", "name"], ["desc", "asc"]);
    },

  getContactsOfDeal:
    (state, localGetters) =>
    (dealId: number): OrgUser[] => {
      const deal = localGetters.getDealById(dealId);
      return deal.contacts || [];
    },
};
