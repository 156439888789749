<template>
  <div>
    <DrTooltip :content="caption" :placement="large ? 'left' : 'top'">
      <button
        :class="{
          [$style.button]: true,
          [$style.button__enabled]: enabled,
          [$style.button__withPadding]: withPadding,
          [$style.button__large]: large,
          [$style.button__disabled]: disabled,
        }"
        :disabled="disabled"
        @click="!disabled && $emit('click', $event)"
      >
        <div :class="$style.iconContainer">
          <DrIcon
            :name="
              (!enabled
                ? filePermissionKeyIconDisabledMap[permission]
                : undefined) ?? filePermissionKeyIconMap[permission]
            "
            size="sm"
          />

          <DrIcon
            v-if="propagated"
            name="minus-square"
            size="xs"
            :class="$style.iconPropagated"
          />

          <DrIcon
            v-if="errorMessage"
            name="exclamation-circle"
            size="xs"
            :class="$style.iconError"
          />
        </div>

        <div v-if="large">
          {{ filePermissionKeyCaptionMap[permission] }}
        </div>
      </button>
    </DrTooltip>
  </div>
</template>

<script setup lang="ts">
import { computed } from "vue";
import { useI18n } from "vue-i18n";

import DrIcon from "@app/vue/shared/ui/dr-icon/DrIcon.vue";
import DrTooltip from "@app/vue/shared/ui/dr-tooltip/DrTooltip.vue";
import {
  type FilePermissionKey,
  filePermissionKeyCaptionMap,
  filePermissionKeyIconDisabledMap,
  filePermissionKeyIconMap,
} from "@app/vue/store/pinia/room/filePermissions/FilePermissionsApi";

interface Props {
  permission: FilePermissionKey;
  enabled: boolean;
  propagated?: boolean;
  errorMessage?: string;
  large?: boolean;
  silent?: boolean;
  withPadding?: boolean;
  disabled: boolean;
}

interface Emits {
  (e: "click", value: MouseEvent): void;
}

const props = defineProps<Props>();

defineEmits<Emits>();

const { t } = useI18n();

const caption = computed<string | undefined>(() => {
  if (props.silent) return undefined;

  if (props.errorMessage) return props.errorMessage;

  if (props.propagated) return t("permissions.file.error_propagated");

  if (props.large) return undefined;

  return filePermissionKeyCaptionMap[props.permission];
});
</script>

<style lang="scss" module>
@use "@app/styles/scss/colors";
@use "@app/styles/scss/spacing";
@use "@app/styles/scss/values";

.button {
  background: unset;
  border: unset;
  border-radius: unset;
  text-align: unset;
  display: flex;
  gap: 8px;
  color: colors.$pr-300;
  width: 100%;
  align-items: center;

  &.button__enabled {
    color: colors.$sc-600;
  }

  &.button__withPadding {
    padding: spacing.$xxs spacing.$s;
  }

  &.button__large {
    padding: spacing.$xxs;
    height: values.$base-input-height;
    border-radius: values.$base-border-radius;

    &:hover {
      background-color: colors.$pr-100;
    }
  }

  &.button__disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
}

.iconContainer {
  position: relative;
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.iconPropagated {
  position: absolute;
  top: 0;
  right: 0;
  color: colors.$ad-10_5;
  background: white;
  height: 12px;
  width: 12px;
  border-radius: 2px;
}

.iconError {
  position: absolute;
  bottom: 0;
  right: 0;
  color: colors.$ad-10_5;
  background: white;
  height: 13px;
  width: 13px;
  border-radius: 13px;
}
</style>
