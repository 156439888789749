import { $notifyDanger, $notifySuccess } from "@drVue/common";
import { t } from "@drVue/i18n";
import { GroupsApiService } from "./GroupsApiService";

import type { Group } from "./GroupsApiService";
import type { GroupsState } from "./GroupsState";
import type { AiAccess, TaskFieldAccessType } from "@app/setups/enums";
import type { BaseCategory } from "@app/vue/store/pinia/room/categories";
import type { SetStatePayload } from "@drVue/store/modules/room/groups/GroupsMutations";
import type { RootState } from "@drVue/store/state";
import type { Dictionary } from "@drVue/types";
import type { ActionContext, ActionTree } from "vuex";

export type Context = ActionContext<GroupsState, RootState>;

export type RoomActions = {
  categories: (BaseCategory & { allowed: boolean })[];

  default_permissions_mode: unknown;
  category_default_permissions_mode: unknown;
  need_comments_approve: boolean;
  hide_group_members: boolean;
  synergies_access: boolean;
  synergy_tracking_fields_access: boolean;
  task_start_and_due_dates_access: TaskFieldAccessType;

  groups: unknown;

  canCreateTasks: boolean;
  canDeleteTasks: boolean;
  taskCustomFieldsAccess: TaskFieldAccessType;

  canManageTasks: boolean;
  canManageFindings: boolean;
  canManageGroups: boolean;
  hideUploadDate: boolean;
  ai_access: AiAccess;
  isAdministrator: boolean;
};

const api = new GroupsApiService();

// TODO: Strongly typed Promises.
export interface GroupsActions extends ActionTree<GroupsState, RootState> {
  create(context: Context, data: any): Promise<any>;
  edit(
    context: Context,
    payload: { pgroupId: number; data: object },
  ): Promise<any>;
  delete(context: Context, pgroupId: number): Promise<any>;
  copy(
    context: Context,
    payload: { pgroupId: number; data: object },
  ): Promise<any>;
  restore(context: Context, pgroups: Group[]): Promise<any>;
  updateActions(
    context: Context,
    payload: {
      pgroupId: number;
      actions: Dictionary<any>;
      skipSuccessMsg: boolean;
      skipReloadActions?: boolean;
    },
  ): Promise<any>;
  getActions(context: Context, pgroupId?: number): Promise<any>;
  fillPGroupsActions(context: Context): Promise<any>;
  load(context: Context, skipErrorAlert: boolean): Promise<any>;
}

export const groupsActions: GroupsActions = {
  async create({ commit, dispatch }, data) {
    return await api.createGroup(data);
  },

  async edit({ state, commit, dispatch }, payload) {
    return await api.editGroup(payload.pgroupId, payload.data);
  },

  async delete({ state, commit, dispatch }, pgroupId) {
    await api.editGroup(pgroupId, { is_archived: true });
    await Promise.all([
      dispatch("room/groups/load", false, { root: true }),
      dispatch("room/members/load", false, { root: true }),
    ]);
  },

  async copy({ state, commit, dispatch }, payload) {
    return await api.copyGroup(payload.pgroupId, payload.data);
  },

  async restore({ state, commit, dispatch }, pgroups) {
    const promises: Promise<any>[] = pgroups.map((pg) =>
      api.editGroup(pg.id, { is_archived: false }),
    );

    return Promise.all(promises).then(
      () =>
        Promise.all([
          dispatch("room/groups/load", false, { root: true }),
          dispatch("room/groups/getActions", false, { root: true }),
          dispatch("room/members/load", false, { root: true }),
        ]),
      () => $notifyDanger(t("permissions.groups_restore_failed")),
    );
  },

  async updateActions(
    { state, commit, dispatch },
    { pgroupId, actions, skipSuccessMsg, skipReloadActions },
  ) {
    function getAllowedIds(arr: any[]) {
      return arr.reduce(function (bucket, item) {
        if (item.allowed) {
          bucket.push(item.id);
        }

        return bucket;
      }, []);
    }

    const data = {
      categories: getAllowedIds(actions.categories),
      members: getAllowedIds(actions.groups),
      show_upload_date: !actions.hideUploadDate,
      is_administrator: actions.isAdministrator,

      default_permissions_mode: actions.default_permissions_mode,
      category_default_permissions_mode:
        actions.category_default_permissions_mode,
      need_comments_approve: actions.need_comments_approve,
      hide_group_members: actions.hide_group_members,
      is_task_managers: actions.canManageTasks,
      is_finding_managers: actions.canManageFindings,
      can_invite_to_group: actions.canManageGroups,
      can_create_tasks: actions.canCreateTasks,
      can_delete_tasks: actions.canDeleteTasks,
      task_custom_fields_access: actions.taskCustomFieldsAccess,
      synergies_access: actions.synergies_access,
      synergy_tracking_fields_access: actions.synergy_tracking_fields_access,
      task_start_and_due_dates_access: actions.task_start_and_due_dates_access,
      ai_access: actions.ai_access,
    };

    try {
      await api.updateActions(pgroupId, data);

      if (!skipSuccessMsg) {
        $notifySuccess(t("permissions.group_actions_updated"));
      }

      if (!skipReloadActions) {
        return await dispatch("getActions");
      }
      /**
       * It is assumed that this case is when it is necessary to execute
       * several dispatches of this action with different data and then
       * independently update the data through dispatch("getActions").
       *
       * return `state.pgroupsActions` just for type consistency.
       */
      return state.pgroupsActions;
    } catch (e) {
      $notifyDanger(t("permissions.group_actions_update_failed"));
    }
  },

  async getActions({ commit }, pgroupId) {
    try {
      const dataGroups = await api.getActions(pgroupId);
      const result: Dictionary<object> = {};

      for (const dataGroupId of Object.keys(dataGroups)) {
        const data = dataGroups[dataGroupId];
        const actions: RoomActions = {} as RoomActions;

        actions.categories = data.categories;
        actions.groups = data.members;

        actions.canCreateTasks = data.can_create_tasks;
        actions.canDeleteTasks = data.can_delete_tasks;
        actions.taskCustomFieldsAccess = data.task_custom_fields_access;

        actions.canManageTasks = data.is_task_managers;
        actions.canManageFindings = data.is_finding_managers;
        actions.canManageGroups = data.can_invite_to_group;
        actions.hideUploadDate = !data.show_upload_date;
        actions.isAdministrator = data.is_administrator;
        actions.task_start_and_due_dates_access =
          data.task_start_and_due_dates_access;
        actions.ai_access = data.ai_access;

        (
          [
            "category_default_permissions_mode",
            "default_permissions_mode",
            "hide_group_members",
            "need_comments_approve",
            "synergies_access",
            "synergy_tracking_fields_access",
          ] as const
        ).forEach((key) => (actions[key] = data[key]));

        result[dataGroupId] = actions;
      }

      commit("setPgroupsActions", result);
      return result;
    } catch (e) {
      $notifyDanger("Failed to get allowed actions.");
    }
  },

  fillPGroupsActions({ state, commit, dispatch }) {
    if (!Object.keys(state.pgroupsActions).length) {
      return dispatch("getActions");
    }
    return Promise.resolve();
  },

  load({ state, rootState, commit }, skipErrorAlert) {
    if (state.isLoading && state.updatePromise !== null) {
      return state.updatePromise;
    }

    commit("setIsError", false);
    commit("setIsLoading", true);

    const promise = api.getAllGroups().then(
      (allGroups) => {
        const pgroups: Dictionary<Group> = {};
        const pgroupsList: Group[] = [];
        const archivedPgroupsList: Group[] = [];

        allGroups.forEach(function (pgroup) {
          if (pgroup.is_archived) {
            archivedPgroupsList.push(pgroup);
          } else {
            pgroupsList.push(pgroup);
          }
          pgroups[pgroup.id] = pgroup;
        });

        pgroupsList.sort((a, b) => a.id - b.id);

        commit("setState", {
          pgroups,
          pgroupsList,
          archivedPgroupsList,
        } as SetStatePayload);
        commit("setIsLoading", false);

        return allGroups;
      },
      (error) => {
        if (!skipErrorAlert) {
          $notifyDanger("Failed to load members list.");
        }

        commit("setIsError", true);
        commit("setIsLoading", false);

        return Promise.reject(error);
      },
    );

    commit("setPromise", promise);

    return promise;
  },
};
