<template>
  <DrToolbarFilterTree
    v-model="modelValue"
    :is-confirmable="editProps.schema.extra?.isConfirmable ?? true"
    popup-trigger="focus"
    :tree="usersTree"
    :favorites="editProps.schema.extra?.favorites || []"
    :is-pending="!isMembersAndGroupsLoaded"
  >
    <template #reference="{ show }">
      <slot name="reference" v-bind="{ show }" />
    </template>

    <template #tree-item-prefix="{ item, className }">
      <DrAvatar
        :name="item.name"
        :url="(item as UsersTreeItem).avatar"
        :class="className"
      />
    </template>
    <template #favorite-item-prefix="{ item, className }">
      <DrAvatar
        :name="item.name"
        :url="(item as UsersTreeItem).avatar"
        :class="className"
      />
    </template>
  </DrToolbarFilterTree>
</template>

<script lang="ts" setup>
import { get } from "lodash-es";
import { computed } from "vue";
import { DrAvatar } from "@shared/ui/dr-avatar";
import { DrToolbarFilterTree } from "@shared/ui/dr-toolbar";

import { DrStore } from "@app/vue";

import type { ParticipantsControlProps } from "./types";
import type { Group } from "@drVue/store/modules/room/groups/GroupsApiService";
import type { RoomMember } from "@drVue/store/modules/room/members/RoomMembersApiService";
import type { TreeItem } from "@shared/ui/dr-tree";

interface Props {
  editProps: ParticipantsControlProps["editProps"];
  filterGroup?: (group: Group) => boolean;
  filterMember?: (member: RoomMember) => boolean;
}
const {
  editProps,
  filterGroup = () => true,
  filterMember = () => true,
} = defineProps<Props>();

const M_PREFIX = "member_";
const G_PREFIX = "group_";

const modelValue = computed({
  get() {
    return editProps.value.map(
      (participant) => `${M_PREFIX}${participant.user_id}`,
    ) as `${typeof M_PREFIX}${string}`[];
  },
  set(checkedIds: `${typeof M_PREFIX}${string}`[]) {
    const parsedMembersIds = checkedIds.map((memberId: string) =>
      memberId.replace(M_PREFIX, ""),
    );

    editProps.veeField.onChange(
      parsedMembersIds.map((user_id) => ({ user_id })),
    );
  },
});

const isMembersAndGroupsLoaded = computed(() => {
  const { groups, members } = DrStore.state.room;

  return (
    members.isLoading === false &&
    members.isError === false &&
    groups.isLoading === false &&
    groups.isError === false
  );
});
const groupsList = computed(() => {
  const categoryId = get(editProps.schema, "extra.categoryId") ?? null;
  let groups = DrStore.state.room.groups.pgroupsList;

  if (categoryId) {
    groups = groups.filter((g: Group) => {
      const canViewCategory = g.viewable_categories_ids.includes(categoryId);

      return g.can_task_manage && canViewCategory;
    });
  }

  return groups.filter(filterGroup);
});
const getUsersOfGroup = (group: Group) => {
  return DrStore.state.room.members.membersList
    .reduce((acc: RoomMember[], m: RoomMember) => {
      const isActiveUser = !m.pending;
      const isMemberOfTheGroup = m.pgroup.id === group.id;

      if (isActiveUser && isMemberOfTheGroup) {
        acc.push(m);
      }

      return acc;
    }, [])
    .filter(filterMember);
};

type UsersTreeGroup = TreeItem<
  void,
  {
    avatar: string;
    email: string;
  }
>;

type UsersTreeItem = TreeItem<{
  avatar: string;
  email: string;
}>;

const usersTree = computed<UsersTreeGroup[]>(() => {
  return groupsList.value.reduce((acc: UsersTreeGroup[], g: Group) => {
    const groupMembers = getUsersOfGroup(g).map((u) => {
      return {
        id: `${M_PREFIX}${u.uid}`,
        name: u.name,
        email: u.email,
        avatar: u.avatar.reduced || "",
      };
    });

    if (!groupMembers.length) return acc;

    const group = {
      id: `${G_PREFIX}${g.id}`,
      name: g.name,
      children: groupMembers,
    };

    acc.push(group);

    return acc;
  }, []);
});
</script>
