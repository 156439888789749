<template>
  <ElInput
    ref="inputRef"
    :placeholder="t('shared.search_dots')"
    :model-value="modelValue"
    :disabled="disabled"
    @update:model-value="$emit('update:modelValue', $event)"
  >
    <template #prefix>
      <DrIcon size="sm" name="search" />
    </template>
  </ElInput>
</template>

<script setup lang="ts">
import { ElInput } from "element-plus";
import { onMounted, ref, watch } from "vue";
import { useI18n } from "vue-i18n";
import { DrIcon } from "@shared/ui/dr-icon";

import { delayedCall } from "@app/vue/common";

interface Props {
  disabled: boolean;
  modelValue: string;
  autofocus?: boolean | number;
}

const props = defineProps<Props>();

interface Emits {
  (e: "update:modelValue", value: string): void;
}

const emit = defineEmits<Emits>();

const { t } = useI18n();

const inputRef = ref<InstanceType<typeof ElInput> | null>(null);

const focusSearchField = () => {
  inputRef.value?.focus();
};

watch(
  () => props.disabled,
  () => {
    if (props.disabled) {
      emit("update:modelValue", "");
    }
  },
);

onMounted(() => {
  if (props.autofocus) {
    delayedCall(focusSearchField, props.autofocus);
  }
});
</script>

<style scoped lang="scss"></style>
