<template>
  <DrPopup ref="popupRef" paddingless @hide="$emit('hide')">
    <template #default="{ hide }">
      <DrPanelWrapper :title="t('requests.set_status')" :width="432">
        <template #default>
          <div :class="$style.optionsWrapper">
            <DrPopupSelectOptions
              type="iconsWithCheckbox"
              :items="options"
              @select="toggleStatus"
            >
              <template #icon="{ option }">
                <DrIconStatus
                  :color="option.color as TaskStatus['color']"
                  size="sm"
                />
              </template>
            </DrPopupSelectOptions>
          </div>
        </template>

        <template #footer-right>
          <ElButton size="small" @click="hide">
            {{ t("shared.cancel") }}
          </ElButton>
          <ElButton
            size="small"
            type="primary"
            :disabled="!selectedStatus"
            @click="submitModelValue(hide)"
          >
            {{ t("shared.save") }}
          </ElButton>
        </template>
      </DrPanelWrapper>
    </template>
  </DrPopup>
</template>

<script setup lang="ts">
import { capitalize, computed, ref, watch } from "vue";
import { useI18n } from "vue-i18n";
import DrIconStatus from "@shared/ui/dr-icon-status";
import { DrPanelWrapper } from "@shared/ui/dr-panels";
import {
  DrPopup,
  DrPopupSelectOptions,
  type SelectOptionItem,
} from "@shared/ui/dr-popups";

import { insightTrack, RoomRequestsBulkEvent } from "@app/insight";

import type { TaskStatus } from "@drVue/store/modules/room/tasks-statuses/types";

interface Props {
  statuses: TaskStatus[];
  selected?: TaskStatus["id"];
}

interface Emits {
  (event: "submit", payload: TaskStatus): void;
  (e: "hide"): void;
}

const props = defineProps<Props>();
const emit = defineEmits<Emits>();

const { t } = useI18n();

const popupRef = ref<InstanceType<typeof DrPopup>>();

const selectedStatus = ref<TaskStatus | undefined>();

const options = computed<
  (SelectOptionItem<TaskStatus["id"]> & { color: TaskStatus["color"] })[]
>(() => {
  return props.statuses.map((status) => ({
    id: status.id,
    name: capitalize(status.name),
    color: status.color,
    isSelected: status.id === selectedStatus.value?.id,
  }));
});

const toggleStatus = (optionId: TaskStatus["id"]) => {
  selectedStatus.value = props.statuses.find(
    (status) => status.id === optionId,
  );
};

const submitModelValue = (hidePopup: () => void) => {
  emit("submit", selectedStatus.value!);
  hidePopup();
  insightTrack(RoomRequestsBulkEvent.StatusUpdate);
};

watch(
  () => props.selected,
  (value) => {
    selectedStatus.value = props.statuses.find((status) => status.id === value);
  },
  {
    immediate: true,
  },
);

const show = (reference: HTMLElement) => {
  popupRef.value?.show(reference);
};

defineExpose({ show });
</script>

<style lang="scss" module>
@use "@app/styles/scss/spacing";

.optionsWrapper {
  position: relative;
  width: 408px;
  padding: spacing.$s;
}
</style>
