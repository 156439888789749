import { $notifyDanger } from "@drVue/common";
import CustomViewApiService from "./api";
import { serializeView } from "./utils.ts";

import type { State } from "./state";
import type { CustomView, RootState } from "./types";
import type { ActionContext, ActionTree } from "vuex";

type IContext = ActionContext<State, RootState>;

interface CustomViewActionTree extends ActionTree<State, RootState> {
  resetCurrentView(context: IContext): Promise<void>;
  load(context: IContext): Promise<void>;
  create(context: IContext, data: Partial<CustomView>): Promise<CustomView>;
  update(context: IContext, customView: CustomView): Promise<CustomView>;
  delete(context: IContext, deleteView: CustomView): Promise<void>;
}

const api = new CustomViewApiService();

export const actions: CustomViewActionTree = {
  async resetCurrentView({ state, getters, commit }) {
    const view = getters.bySlug(state.current.slug);
    commit("setCurrent", view);
  },

  async load({ commit }) {
    commit("setIsError", false);
    commit("setIsLoading", true);
    try {
      const items = await api.list();
      commit("set", items);
    } catch (err) {
      commit("setIsError", true);
      throw err;
    } finally {
      commit("setIsLoading", false);
    }
  },

  async create({ commit }, data) {
    try {
      const serialized = serializeView(data);
      const newDealsView = await api.create(serialized);
      commit("upsert", newDealsView);
      return newDealsView;
    } catch (err) {
      $notifyDanger("Failed to create the custom view");
      throw err;
    }
  },

  async update({ commit }, customView) {
    try {
      const serialized = serializeView(customView);
      const updatedView = await api.update(serialized);
      commit("upsert", updatedView);
      return updatedView;
    } catch (err) {
      $notifyDanger("Failed to update the custom view");
      throw err;
    }
  },

  async delete({ commit }, customView) {
    try {
      await api.destroy(customView);
      commit("delete", customView);
    } catch (err) {
      $notifyDanger("Failed to delete the custom view");
      throw err;
    }
  },
};
