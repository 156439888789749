<template>
  <!-- @vue-expect-error: siblings and BaseViewManager.search -->
  <Topbar
    :document-info="DOCUMENT_INFO"
    :siblings="siblings"
    @search="(t) => manager.search(t)"
    v-model="activeSidebar"
  />

  <div
    :class="{
      [$style.contentGrid]: true,
      [$style.contentGrid_isInfoExpanded]: isInfoExpanded,
      [$style.contentGrid_isChatExpanded]: isChatExpanded,
    }"
  >
    <div :class="$style.contentGridViewer">
      <ElButton
        v-if="isInfoExpanded || isChatExpanded"
        :class="$style.sidebarToggle"
        @click="activeSidebar = null"
      >
        <DrIcon name="cross" />
      </ElButton>

      <div id="viewerContainer" :class="$style.content" />

      <div :class="$style.navigationContainer">
        <Navigation :manager="manager" />
      </div>
    </div>

    <div v-if="activeSidebar" :class="$style.contentGridSidebar">
      <SidebarChat
        v-if="isAiEnabled && activeSidebar === 'ai'"
        :doc-info="DOCUMENT_INFO"
      />
      <Sidebar
        v-if="activeSidebar === 'info'"
        :current-section="currentSidebarSection"
        :document-info="DOCUMENT_INFO"
        :manager="manager"
        @expand-section="expandSection"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed, onMounted, ref } from "vue";
import DrIcon from "@shared/ui/dr-icon";

import { parseDjDataJson, ROOM_DATA, ROOM_MEMBER_DATA } from "@setups/data";
import { AiAccess } from "@app/setups/enums";
import { usePageNavigationBus } from "../ai/usePageNavigationBus";
import { MANAGERS } from "./managers";
import Navigation from "./Navigation.vue";
import Sidebar from "./Sidebar.vue";
import SidebarChat from "./SidebarChat.vue";
import Topbar from "./Topbar.vue";
import { SidebarSection } from "./types";

import type { BaseViewerManager } from "./managers/base";
import type { DocumentViewerInfo } from "./types";

const emit = defineEmits(["before-initialize"]);
const pageNavigationBus = usePageNavigationBus();

const isAiEnabled =
  ROOM_DATA.isAiEnabled && ROOM_MEMBER_DATA.group.ai_access === AiAccess.BASIC;

const DOCUMENT_INFO: DocumentViewerInfo = parseDjDataJson("document-info");
const pageParam = new URL(window.location.href).searchParams.get("page");
const initialPage = pageParam ? parseInt(pageParam) : null;

const activeSidebar = ref<null | "ai" | "info">(null);

const beforeViewerInitializeCb = () => {
  emit("before-initialize");
};

const manager: BaseViewerManager = new MANAGERS[DOCUMENT_INFO.viewer]!(
  "viewerContainer",
  DOCUMENT_INFO,
  beforeViewerInitializeCb,
  initialPage,
);

pageNavigationBus.on((_event, payload) => {
  if (!payload) throw new Error("Payload is required");

  manager.goToPage(payload.page);
});

onMounted(() => {
  manager.initialize();
});

const isChatExpanded = computed(() => activeSidebar.value === "ai");
const isInfoExpanded = computed(() => activeSidebar.value === "info");

const currentSidebarSection = ref(SidebarSection.INFORMATION);

const expandSection = (section: SidebarSection) => {
  currentSidebarSection.value = section;
};

const siblings = computed(() => {
  return DOCUMENT_INFO.siblings.map(({ name, mimetype, id }) => ({
    id,
    name,
    mimetype,
    link: `${ROOM_DATA.url}/documents/${id}/view`,
  }));
});
</script>

<style module lang="scss">
@use "sass:math";
@use "@app/styles/scss/_colors";
@use "./vars";

.sidebarToggle {
  position: absolute;
  width: 32px;
  height: 32px;
  font-size: 9.6px;
  display: flex;
  align-items: center;
  justify-content: center;
  right: 12px;
  top: 12px;
}

.content {
  background: colors.$pr-200;
  height: calc(100vh - #{vars.$topbar-height});
  overflow: hidden;
}

.navigationContainer {
  position: absolute;
  bottom: 80px;
  opacity: 0;
  display: flex;
  justify-content: center;
  transition: opacity 0.15s ease-in-out;
  left: 50%;
  transform: translateX(-50%);
  transition-delay: 0.5s; // do not immediate hide/show on hover
}

.contentGrid {
  display: grid;
  grid-template-columns: 100%;
  position: relative;

  &:hover {
    .navigationContainer {
      opacity: 1;
    }
  }
}

.contentGrid_isInfoExpanded {
  $sidebar-width: #{vars.$sidebar-width};
  grid-template-columns: calc(100% - $sidebar-width) $sidebar-width;
}

.contentGrid_isChatExpanded {
  $sidebar-width: min(50%, #{vars.$chat-max-width});
  grid-template-columns: calc(100% - $sidebar-width) min(50%, $sidebar-width);
}

.contentGridViewer {
  position: relative;
  height: calc(100vh - #{vars.$topbar-height});
}

.contentGridSidebar {
  height: calc(100vh - #{vars.$topbar-height});
}
</style>
