TaskArchivedListController.$inject = [
  "$scope",
  "$timeout",
  "$q",
  "TasksService",
  "ArchivedCategoriesService",
  "TasksFilterService",
];

export default function TaskArchivedListController(
  $scope,
  $timeout,
  $q,
  TasksService,
  ArchivedCategoriesService,
  TasksFilterService,
) {
  // TasksService and ArchivedCategoriesService are proxies to Pinia stores,
  // there no way for Angular to know when they are ready and call the $digest.
  // Therefore, we are using $q.all() that will call the $digest internally when
  // the both services will be ready.
  $q.all([TasksService.loadArchivedTasks(), ArchivedCategoriesService.load()]);

  TasksFilterService.filters.categories = [];

  // filter by number in archive, default sorting by order does not make sense in archived docs
  var originalOrder = angular.copy(TasksFilterService.order);
  TasksFilterService.setOrder({ by: "key", reversed: true });
  $scope.$on("$stateChangeStart", function () {
    TasksFilterService.setOrder(originalOrder);
  });
}
