<template>
  <DrPopup
    class="popover"
    :visible="visibility"
    :persistent="false"
    :teleported="true"
    :width="250"
    popper-class="el-popover--no-padding"
    @show="onPopupShow"
  >
    <div :class="$style.container">
      <!-- @vue-expect-error -->
      <ElInput
        ref="textareaRef"
        :class="$style.scrollbar"
        type="textarea"
        :rows="3"
        :validate-event="false"
        :model-value="editProps.value"
        :placeholder="editProps.schema.placeholder"
        v-bind="editProps.veeField"
        @keydown="submitIfCtrlEnter"
      />
      <div :class="$style.buttons">
        <ElButton
          size="small"
          title="cancel"
          @click="() => editProps.quitEditModeConfirm()"
        >
          Cancel
        </ElButton>
        <ElButton size="small" type="primary" @click="editProps.submitField">
          Save
        </ElButton>
      </div>
    </div>
    <template #reference>
      <TextViewContent
        :value="initialValue"
        @click="() => editProps.quitEditModeConfirm()"
      />
    </template>
  </DrPopup>
</template>

<script setup lang="ts">
import { ElInput } from "element-plus";
import { ref } from "vue";
import { DrPopup } from "@shared/ui/dr-popups";

import { useVisibility } from "@drVue/components/room/tasks/TasksTable/cells/useVisibilty";
import TextViewContent from "../View/TextViewContent.vue";

import type { EditFieldProps } from "@drVue/components/client-dashboard/dynamic-form/Fields/types";

interface Props {
  editProps: EditFieldProps<string>;
}

const props = defineProps<Props>();

const visibility = useVisibility();

const initialValue = props.editProps.value;

const submitIfCtrlEnter = (e: KeyboardEvent) => {
  if (e.ctrlKey && e.key === "Enter") {
    props.editProps.submitField();
  }
};

const textareaRef = ref<InstanceType<typeof ElInput> | null>(null);
const onPopupShow = () => {
  textareaRef.value?.focus();
};
</script>

<style lang="scss" module>
@use "@app/styles/scss/colors";

.container {
  padding: 8px;
  display: flex;
  flex-direction: column;
}

.buttons {
  margin-top: 8px;
  margin-left: auto;
}

.scrollbar {
  :global(textarea::-webkit-scrollbar) {
    width: 10px;
  }
  :global(textarea::-webkit-scrollbar-track) {
    border-radius: 10px;
    box-shadow: inset 0 0 10px 10px colors.$pr-200;
    border: solid 3px transparent;
  }
  :global(textarea::-webkit-scrollbar-thumb) {
    border-radius: 10px;
    box-shadow: inset 0 0 10px 10px colors.$pr-400;
    border: solid 3px transparent;
  }
}
</style>
