<template>
  <DrDrawer
    size="large"
    fixed-footer
    :title="titleText"
    :submit-btn="submitBtnText"
    :shown="shown"
    :disallow-submit="groupDataNotChanged || operandErrors.size > 0"
    :is-submitting="isFormSubmitting"
    @submit="handleSubmit"
    @close="handleClose"
    @cancel="handleCancel"
  >
    <DrFormWrapper
      :model="groupFormModel"
      :rules="formRules"
      :get-form-ref="getFormRef"
    >
      <ElAlert
        v-if="formErrors.non_field_errors"
        type="error"
        :title="formErrors.non_field_errors"
      />
      <ElFormItem label="Name" required prop="name" :error="formErrors.name">
        <ElInput
          v-model="groupFormModel.name"
          placeholder="Provide a name for the permission set"
          :disabled="isGroupChangeRestricted"
        />
      </ElFormItem>
      <ElCheckbox
        v-model="groupFormModel.is_administrator"
        label="Grant Super Admin access"
        :disabled="isGroupChangeRestricted"
      />

      <DrFormBlock title="See deals" divide>
        <DrRadioStack
          v-model="groupFormModel.deals_access_type"
          :items="dealAccessTypes"
          :disabled="groupFormModel.is_administrator"
        />
        <DealsScopeCriteria
          v-show="
            groupFormModel.deals_access_type ===
            OrganizationGroupDealAccessType.ScopeCriteria
          "
          :value-errors="operandErrors"
          :model-value="groupFormModel.deals_scope_criteria ?? undefined"
          @update:model-value="handleUpdateCriteria"
        />
      </DrFormBlock>

      <DrFormBlock title="Edit & manage deal’s data" divide>
        <template #title-right>
          <ElSwitch
            v-model="groupFormModel.can_edit_deals"
            :disabled="groupFormModel.is_administrator"
            style="height: 24px"
          />
        </template>
        <template #description>
          Allow users to edit deal details, add notes, manage phases and
          contacts, and attach documents.
        </template>
      </DrFormBlock>

      <DrFormBlock title="Create deals">
        <template #title-right>
          <ElSwitch
            v-model="groupFormModel.can_create_deals"
            :disabled="
              groupFormModel.is_administrator || !groupFormModel.can_edit_deals
            "
            style="height: 24px"
          />
        </template>
        <template #description>
          Allow users to create new deals (requires "Edit & manage deal’s data"
          permission).
        </template>
      </DrFormBlock>

      <DrFormBlock title="Create rooms">
        <template #title-right>
          <ElSwitch
            v-model="groupFormModel.can_create_rooms"
            :disabled="
              groupFormModel.is_administrator ||
              !groupFormModel.can_create_deals
            "
            style="height: 24px"
          />
        </template>
        <template #description>
          Allow users to create new rooms (requires "Create deals" permission).
          <br />
          When creating new rooms, users automatically become admins of these
          rooms.
        </template>
      </DrFormBlock>
    </DrFormWrapper>
  </DrDrawer>
</template>

<script lang="ts" setup>
import { isEqual } from "lodash-es";
import { computed, reactive, ref, watch } from "vue";
import { DrDrawer } from "@shared/ui/dr-drawer";
import { DrFormBlock, DrFormWrapper, useFormHelper } from "@shared/ui/dr-form";
import { DrRadioStack } from "@shared/ui/dr-radio";

import { OrganizationGroupDealAccessType } from "@setups/enums";
import { DealsScopeCriteriaFormEvent, insightTrack } from "@app/insight";
import { pinia } from "@drVue/store/pinia";
import { useOrganizationGroups } from "@drVue/store/pinia/pipeline/org-groups";
import DealsScopeCriteria from "./DealScopeCriteria/DealsScopeCriteria.vue";

import type { OrganizationGroup } from "@drVue/store/pinia/pipeline/org-groups";
import type { DealScopeCriteria } from "@setups/data";
import type { FormInstance, FormRules } from "@shared/ui/dr-form";

type LocalOrganizationGroup = Omit<OrganizationGroup, "id" | "uid">;

const DEFAULT_GROUP_ACCESS_TYPE = OrganizationGroupDealAccessType.ScopeCriteria;

interface Props {
  shown: boolean;
  groupId: OrganizationGroup["id"] | null;
}

const props = defineProps<Props>();

interface Emits {
  (event: "update:shown", isShown: boolean): void;
}

const emit = defineEmits<Emits>();

const dealAccessTypes = computed(() => {
  const types = [
    {
      id: OrganizationGroupDealAccessType.All,
      name: "All deals",
      description: "",
    },
  ];
  if (
    groupData.value?.deals_access_type ==
    OrganizationGroupDealAccessType.RoomAdmin
  ) {
    // we show this option only for groups that already have this access type
    // for new groups, we don't show this option, as it's deprecated
    types.push({
      id: OrganizationGroupDealAccessType.RoomAdmin,
      name: "Deals with admin access to corresponding rooms (deprecated)",
      description:
        "Users will be able to see and access deals in the pipeline if they are admins of the respective deals' rooms.",
    });
  }
  types.push({
    id: OrganizationGroupDealAccessType.ScopeCriteria,
    name: "Deals matching the following criteria",
    description:
      "Users will be able to see and access deals in the pipeline if they match the following criteria",
  });
  return types;
});

const orgGroupsStore = useOrganizationGroups(pinia);

const titleText = computed(() =>
  props.groupId ? "Edit permission set" : "New permission set",
);
const submitBtnText = computed(() => (props.groupId ? "Save" : "Create"));
const groupData = computed<OrganizationGroup | null>(() => {
  if (props.groupId) {
    return orgGroupsStore.dict[props.groupId] || null;
  }
  return null;
});

const isGroupChangeRestricted = computed(() => {
  return groupData.value?.name === "Administrator";
});

const getLocalGroupData = (
  group?: OrganizationGroup | null,
): LocalOrganizationGroup => {
  return {
    name: group ? group.name : "",
    is_administrator: group ? group.is_administrator : false,
    deals_access_type: group
      ? group.deals_access_type
      : DEFAULT_GROUP_ACCESS_TYPE,
    can_create_deals: group ? group.can_create_deals : false,
    can_edit_deals: group ? group.can_edit_deals : false,
    can_create_rooms: group ? group.can_create_rooms : false,
    deals_scope_criteria: group ? group.deals_scope_criteria : null,
  };
};
const groupFormModel = reactive<LocalOrganizationGroup>(getLocalGroupData());
watch(
  () => groupData.value,
  (value) => {
    Object.assign(groupFormModel, getLocalGroupData(value));
  },
);
watch(
  () => groupFormModel.is_administrator,
  (val) => {
    if (val) {
      groupFormModel.can_create_deals = true;
      groupFormModel.can_edit_deals = true;
      groupFormModel.can_create_rooms = true;
      groupFormModel.deals_access_type = OrganizationGroupDealAccessType.All;
      groupFormModel.deals_scope_criteria = null;
    }
  },
);
watch(
  () => groupFormModel.can_edit_deals,
  (val) => {
    if (!val) {
      groupFormModel.can_create_deals = false;
    }
  },
);
watch(
  () => groupFormModel.can_create_deals,
  (val) => {
    if (!val) {
      groupFormModel.can_create_rooms = false;
    }
  },
);
watch(
  () => groupFormModel.deals_access_type,
  (val: OrganizationGroupDealAccessType) => {
    if (val === OrganizationGroupDealAccessType.ScopeCriteria) {
      groupFormModel.deals_scope_criteria =
        groupData.value?.deals_scope_criteria ?? null;
    } else {
      groupFormModel.deals_scope_criteria = null;
    }
  },
);

const formRef = ref<FormInstance>();
const getFormRef = (form: FormInstance) => {
  formRef.value = form;
};

const formRules = reactive<FormRules>({
  name: [
    {
      required: true,
      trigger: "blur",
      message: "Permission set name is required.",
    },
    {
      min: 4,
      message: "Ensure permission set name has at least 4 characters.",
    },
  ],
});

const { formErrors, hookFormSubmitPromise, isFormSubmitting, resetErrors } =
  useFormHelper<LocalOrganizationGroup>();

const groupDataNotChanged = computed(() =>
  isEqual(groupFormModel, getLocalGroupData(groupData.value)),
);

const handleSubmit = () => {
  if (!formRef.value) return;

  const hasPhaseInScope = !!groupFormModel.deals_scope_criteria?.operands.find(
    ({ field_key }) => field_key === "phase",
  );
  if (hasPhaseInScope) insightTrack(DealsScopeCriteriaFormEvent.PhaseSelected);

  const hasCustomFieldInScope =
    !!groupFormModel.deals_scope_criteria?.operands.find(
      ({ field_key }) => field_key !== "phase",
    );
  if (hasCustomFieldInScope)
    insightTrack(DealsScopeCriteriaFormEvent.CustomFieldSelected);

  resetErrors();

  formRef.value.validate(async (valid) => {
    if (valid) {
      if (groupData.value) {
        await hookFormSubmitPromise(
          orgGroupsStore.update(groupData.value!.id, groupFormModel)!,
        );
      } else {
        await hookFormSubmitPromise(orgGroupsStore.create(groupFormModel)!);
        Object.assign(groupFormModel, getLocalGroupData());
      }

      handleClose();
    }
  });
};

const handleCancel = () => {
  Object.assign(groupFormModel, getLocalGroupData(groupData.value));
  handleClose();
};
const handleClose = () => {
  resetErrors();
  emit("update:shown", false);
};

const operandErrors = ref<Map<number, string>>(new Map());

const handleUpdateCriteria = ({ op_type, operands }: DealScopeCriteria) => {
  operands.forEach(({ values }, i) => {
    if (!values.length) {
      operandErrors.value.set(i, "This list may not be empty.");
    } else {
      operandErrors.value.delete(i);
    }
  });
  groupFormModel.deals_scope_criteria = { op_type, operands };
};
</script>
