<template>
  <div class="auth-form">
    <div class="auth-form__title text-center">Single Sign On</div>
    <div class="auth-form__description text-center">
      {{
        t("auth.sso_description", { website_name: APP_SETTINGS.WEBSITE.NAME })
      }}
    </div>

    <ElForm
      ref="ssoFormRef"
      label-position="top"
      require-asterisk-position="right"
      :model="ssoFormModel"
      :rules="formRules"
      :disabled="isFormSubmitting"
      @submit.prevent="submit(ssoFormRef)"
    >
      <ElFormItem :error="formErrors.email" prop="email" required>
        <ElInput
          v-model="ssoFormModel.email"
          autocomplete="username"
          :placeholder="t('auth.work_email')"
          size="large"
        >
          <template #prepend>
            <i class="fal fa-envelope" />
          </template>
        </ElInput>
      </ElFormItem>

      <ElAlert
        v-if="formErrors.non_field_errors"
        class="auth-form__alert"
        :title="formErrors.non_field_errors"
        type="error"
        show-icon
        :closable="false"
      />

      <div class="text-right">
        <ElButton
          type="primary"
          native-type="submit"
          class="auth-form__submit"
          size="large"
          :loading="isFormSubmitting"
        >
          {{ t("auth.log_in") }}
        </ElButton>
      </div>
    </ElForm>
    <div class="text-center">
      <RouterLink
        v-if="!isFormSubmitting"
        class="auth-form__sign-in-with-email"
        to="/auth"
      >
        <u>{{ t("auth.sign_in_without_sso") }}</u>
      </RouterLink>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { reactive, ref } from "vue";
import { useI18n } from "vue-i18n";
import { useFormHelper } from "@shared/ui/dr-form";

import { APP_SETTINGS } from "@setups/data";
import { $notifyDanger } from "@drVue/common";
import { AuthApiService } from "@drVue/components/auth/AuthApiService";

import type { FormInstance, FormRules } from "element-plus";

interface SsoLoginModel {
  email: string;
}

const { t } = useI18n();

const ssoFormRef = ref<FormInstance>();

const ssoFormModel = reactive<SsoLoginModel>({
  email: "",
});

const formRules = reactive<FormRules>({
  email: [
    {
      type: "email",
      required: true,
      message: t("form.is_required", { field: "Email" }),
    },
  ],
});

const { formErrors, hookFormSubmitPromise, isFormSubmitting } =
  useFormHelper<SsoLoginModel>();

const api = new AuthApiService();

const submit = async (formEl: FormInstance | undefined) => {
  if (!formEl) return;
  await formEl.validate((valid) => {
    if (!valid) {
      return;
    }

    hookFormSubmitPromise(
      api.sign_in_sso_by_email({ email: ssoFormModel.email }),
    ).then(
      ({ data }) => {
        window.location.href = data.redirect_url;
      },
      () => $notifyDanger(t("auth.failed_to_log_in_with_ssо")),
    );
  });
};
</script>
