<template>
  <div class="phase-cell">
    <div v-if="row[field]" style="line-height: 1.2" class="phase-cell__data">
      <div>{{ date }}</div>
      <div v-if="duration === 0 || duration" style="font-size: 10px">
        <b v-if="duration >= 0" class="text-muted">
          {{ label }}: {{ duration }} {{ duration === 1 ? "day" : "days" }}
        </b>
        <b v-if="duration < 0" class="text-danger">
          <template v-if="field === 'actual_start_date'">
            <span v-if="row.active">The date is later than now</span>
            <span v-else>
              The date is later than the phase "{{ row.next_phase_name }}" start
            </span>
          </template>
          <template v-if="field === 'planned_start_date'">
            <span>
              The date is later than the phase "{{ row.next_phase_name }}" start
            </span>
          </template>
        </b>
      </div>
    </div>
    <div class="phase-cell__actions">
      <i
        class="far fa-edit"
        @click="editRowEvent(row)"
        data-testid="deal-phase-date-edit-icon"
      />
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

import { drUserTime } from "@drVue/filters/drUserTime";

import type { PhasesTableRow } from "@drVue/components/client-dashboard/deals/PhasesTable/models";
import type { PropType } from "vue";

export default defineComponent({
  name: "DateCell",
  props: {
    row: { required: true, type: Object as PropType<any> },
    field: { required: true, type: String as PropType<string> },
    label: { required: true, type: String as PropType<string> },
    duration: {
      required: false,
      default: null,
      type: Number as PropType<number | null>,
    },
    table: { required: true, type: Object as PropType<any> },
  },
  computed: {
    date() {
      return drUserTime(this.row[this.field], "full-date");
    },
  },
  methods: {
    editRowEvent(row: PhasesTableRow) {
      this.table.setActiveRow(row);
    },
  },
});
</script>
