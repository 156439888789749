<template>
  <DrItemList :items="followersV2">
    <template #header>
      <ParticipantsEdit :edit-props="editProps">
        <template #reference="{ show }">
          <DrItemListHeader
            :title="t('shared.followers')"
            :light-weight="!followerIds.length"
            :allow-action="!viewOnly"
            @action="show"
          />
        </template>
      </ParticipantsEdit>
    </template>
    <template #item="{ item }">
      <FollowersItem
        :disallow-remove="viewOnly"
        :follower="item"
        @remove="handleRemove"
      />
    </template>
  </DrItemList>
</template>

<script setup lang="ts">
import { differenceBy } from "lodash-es";
import { computed } from "vue";
import { useI18n } from "vue-i18n";
import { DrItemList, DrItemListHeader } from "@shared/ui/dr-item-list";

import { insightTrack, TaskDetailsTrackingEvent } from "@app/insight";
import { DrStore } from "@app/vue";
import { FieldSchemaType } from "@drVue/components/client-dashboard/dynamic-form/types";
import { ParticipantsEdit } from "@drVue/components/room/tasks/shared/widgets/participants";
import { useTasksStore } from "@drVue/store/pinia/room/tasks";
import FollowersItem from "./FollowersItem.vue";

import type {
  ParticipantUserV1,
  ParticipantUserV2,
} from "@drVue/store/pinia/room/tasks";

interface Props {
  taskId: number;
  viewOnly: boolean;
  followerIds: ParticipantUserV1[];
}

const props = defineProps<Props>();

const tasksStore = useTasksStore();

const { t } = useI18n();

const followersV2 = computed<ParticipantUserV2[]>(() =>
  props.followerIds.map(({ user_id }) => {
    const user = DrStore.state.room.members.members[user_id];
    return { ...user, user_id: user.uid };
  }),
);

const editProps = computed(() => ({
  schema: {
    type: FieldSchemaType.Select,
    prop: "assignees",
    label: t("shared.followers"),
    extra: {
      categoryId: tasksStore.tasks[props.taskId]?.category_id,
    },
  },
  value: followersV2.value,
  veeField: {
    onBlur: () => {},
    onInput: () => {},
    onChange: async (add: { user_id: string }[]) => {
      const remove = differenceBy(followersV2.value, add, "user_id");
      await tasksStore.updateFollowers(props.taskId, add, remove);

      if (add.length) {
        insightTrack(TaskDetailsTrackingEvent.FollowersAdded);
      }
      if (remove.length) {
        insightTrack(TaskDetailsTrackingEvent.FollowersRemoved);
      }
    },
  },
}));

const handleRemove = async ({ user_id }: ParticipantUserV2) => {
  await tasksStore.updateFollowers(props.taskId, [], [{ user_id }]);
  insightTrack(TaskDetailsTrackingEvent.FollowersRemoved);
};
</script>
