import { t } from "@app/vue/i18n";
import { DrStore } from "@drVue";
import activity_activityRowTemplatesHtml from "./templates/activity/activity-row-templates.html?raw";
import activityContainer_containerHtml from "./templates/components/activity-container/container.html?raw";

(function () {
  "use strict";

  var drActivityContainer = {
    template: activityContainer_containerHtml,
    bindings: {
      activity: "=",
      isLast: "=",
      isTaskActivity: "=",
    },
  };

  var activity = angular
    .module("dealroom.activity", [
      "ui.bootstrap",
      "dealroom.date-fns",

      "dealroom.config",
      "dealroom.common",
    ])
    .component("drActivityContainer", drActivityContainer)
    .directive("activityRow", activityRowDirective);

  activityRowDirective.$inject = [
    "$compile",
    "$templateRequest",
    "$templateCache",
    "DocumentsService",
    "CategoriesService",
    "TasksService",
    "drLabelKey",
  ];

  function activityRowDirective(
    $compile,
    $templateRequest,
    $templateCache,
    DocumentsService,
    CategoriesService,
    TasksService,
    drLabelKey,
  ) {
    const templatesData = activity_activityRowTemplatesHtml;
    return {
      template: '<div ng-include="::templateUrl"></div>',
      // This scope is isolated
      scope: {
        activity: "=",
      },
      restrict: "E",
      link: function (scope) {
        // Set up t() function for this scope as isolated scope does not inherit it from the root scope
        scope.t = t
        scope.Categories = CategoriesService;
        scope.Tasks = TasksService;
        scope.getStatusNameById = getStatusNameById;
        scope.Documents = DocumentsService;
        scope.drLabelKey = drLabelKey;
        scope.templateUrl = undefined;
        // check that row template html is loaded
        if (!$templateCache.get("task_add.tmpl.html")) {
          // if it is not compiled (first load), compile it
          $compile(templatesData);
        }
        // finally set templateUrl, so ng-include will render row
        scope.templateUrl = scope.activity.verb + ".tmpl.html";

        function getStatusNameById(statusId) {
          const status = DrStore.getters["room/tasksStatuses/byId"](statusId);
          return status ? status.name : "";
        }
      },
    };
  }
})();
