<template>
  <div :class="$style.container">
    <template v-if="reviewed">
      <span>{{ t("requests.review_this_request") }}</span>
      <div
        :class="{
          [$style.greenButton]: true,
          [$style.greenButton_isDisabled]: disabled,
        }"
        @click="$emit('click')"
      >
        <DrIcon name="check" size="sm" />
        {{ t("requests.request_reviewed") }}
      </div>
    </template>
    <template v-else>
      <span>{{ t("requests.review_this_request") }}</span>
      <ElButton
        :disabled="disabled"
        size="small"
        type="primary"
        @click="$emit('click')"
      >
        {{ t("requests.mark_as_reviewed") }}
      </ElButton>
    </template>
  </div>
</template>

<script setup lang="ts">
import { useI18n } from "vue-i18n";
import DrIcon from "@shared/ui/dr-icon";

interface Props {
  reviewed: boolean;
  disabled: boolean;
}

defineProps<Props>();

interface Emits {
  (e: "click"): void;
}

defineEmits<Emits>();

const { t } = useI18n();
</script>

<style module lang="scss">
@use "@app/styles/scss/colors";

.container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 27px;
}

.greenButton {
  cursor: pointer;
  padding: 2px 8px;
  color: colors.$ad-3_5;
  border: 1px solid #{colors.$ad-3_5}10;
  border-radius: 8px;
  background: #{colors.$ad-3_5}10;
  &:hover {
    border: 1px solid colors.$ad-3_5;
    background: transparent;
  }
}

.greenButton_isDisabled {
  cursor: not-allowed;
  color: colors.$pr-300;
  background: colors.$pr-100;
  border: 1px solid colors.$pr-200;
  &:hover {
    border: 1px solid colors.$pr-200;
  }
}
</style>
