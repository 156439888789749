import type { State as CommonState } from "../module/state";
import type { PhaseItem } from "@drVue/api-service/client-dashboard";

type State = CommonState<PhaseItem>;

export type { State };

export const nullPhaseData = {
  // TODO: make PhaseItem and set id to -1 ?
  id: null,
  name: "Unknown",
  color: "#767676",
  order: Number.MAX_SAFE_INTEGER,
};

export type { PhaseItem } from "@drVue/api-service/client-dashboard";
export type { RootState } from "@drVue/store/state";
