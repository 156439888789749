<template>
  <ElPopover
    width="350"
    trigger="click"
    popper-class="el-popover--no-margin"
    @show="focusAutocomplete"
  >
    <ElAutocomplete
      class="el-autocomplete--full-width"
      clearable
      ref="autocompleteRef"
      :label="field.key"
      :fetch-suggestions="
        (query, cb) => textFieldAutoComplete(query, cb, field.key)
      "
      :model-value="fieldValue"
      :trigger-on-focus="false"
      :placeholder="field.label"
      @update:model-value="(value) => (fieldValue = value)"
    />

    <template #reference>
      <DrToolbarFilterButton
        :label="field.label"
        :is-active="isActive"
        @clear="fieldValue = undefined"
      />
    </template>
  </ElPopover>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { DrToolbarFilterButton } from "@shared/ui/dr-toolbar";

import { getTextFieldAutoComplete } from "../utils";

import type { FieldItem } from "@drVue/api-service/client-dashboard";
import type { CustomView } from "@setups/types";
import type { ElAutocomplete } from "element-plus";
import type { PropType } from "vue";

interface Data {
  textFieldAutoComplete: any;
}

export default defineComponent({
  name: "TextField",
  components: { DrToolbarFilterButton },
  props: {
    field: { required: true, type: Object as PropType<FieldItem> },
  },
  data(): Data {
    return {
      textFieldAutoComplete: getTextFieldAutoComplete(this.$store),
    };
  },
  computed: {
    isActive(): any {
      return !!this.fieldValue;
    },
    fieldValue: {
      get: function (): any {
        const current: CustomView =
          this.$store.state.common.customViews.current;
        return current.filters.fields[this.field.key]?.value;
      },
      set: function (value: any) {
        this.$store.commit("common/customViews/setCurrentFilterValue", {
          field: this.field.key,
          value,
          op: "CONTAINS",
        });
      },
    },
  },
  methods: {
    focusAutocomplete() {
      if (this.$refs?.autocompleteRef) {
        // Exposed API types are broken on options API
        (this.$refs.autocompleteRef as typeof ElAutocomplete).focus();
      }
    },
  },
});
</script>
