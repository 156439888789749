<template>
  <DrItemList :items="linkedValueDrivers">
    <template #header>
      <DrItemListHeader
        :title="t('shared.value_drivers')"
        :light-weight="!linkedValueDrivers.length"
      />
    </template>
    <template #item="{ item }">
      <DrItemListItem>
        {{ item.title }}
      </DrItemListItem>
    </template>
  </DrItemList>
</template>

<script setup lang="ts">
import { computed } from "vue";
import { useI18n } from "vue-i18n";
import {
  DrItemList,
  DrItemListHeader,
  DrItemListItem,
} from "@shared/ui/dr-item-list";

import { useValueDriversStore } from "@drVue/store/modules/room/synergies/value-drivers";
import { pinia } from "@drVue/store/pinia";

interface Props {
  taskUid: string;
  viewOnly: boolean;
}

const props = defineProps<Props>();

const { t } = useI18n();

const valueDriversStore = useValueDriversStore(pinia);

const linkedValueDrivers = computed(
  () => valueDriversStore.dictByTaskId[props.taskUid] || [],
);
</script>
