<template>
  <DrDrawer
    :shown="isVisible"
    :title="$t('room_updates.update_log')"
    size="xl"
    no-footer
    @close="close"
  >
    <DrNewLoader v-show="isLoading" />
    <ElAlert
      v-if="isError"
      :title="$t('room_updates.an_error_occured_while_loading')"
      type="error"
      show-icon
      :closable="false"
    />
    <div v-for="[room, logs] in logsByRoom" :key="room.id">
      <div :class="$style.logs">
        <h4>{{ room.title }}</h4>
        <div :class="$style.log" v-for="(log, i) in logs" :key="i">
          <span
            v-if="log.level !== 'info'"
            :class="{
              [$style.level]: true,
              [$style.error]: log.level === 'error',
              [$style.warning]: log.level === 'warning',
            }"
          >
            {{ log.level }}:
          </span>
          {{ log.message }}
        </div>
      </div>
    </div>
  </DrDrawer>
</template>

<script setup lang="ts">
import { computed, ref } from "vue";
import { DrDrawer } from "@shared/ui/dr-drawer";
import { DrNewLoader } from "@shared/ui/dr-loader";

import { DrStore } from "@app/vue";
import { MultiRoomUpdateService } from "./api";

import type {
  RoomUpdateBatch,
  RoomUpdateBatchLogItem,
  RoomUpdateWorkflow,
} from "./api";
import type { Room } from "@app/vue/store/modules/client-dashboard/deals/types";

const getRoomByUid = DrStore.getters["clientDashboard/deals/getRoomByUid"];

const api = new MultiRoomUpdateService();

const batch = ref<RoomUpdateBatch | null>(null);
const batchLogs = ref<RoomUpdateBatchLogItem[] | null>(null);
const batchLogsByWorkflowId = computed(() => {
  if (!batchLogs.value || !batchLogs.value.length) {
    return new Map<
      RoomUpdateBatchLogItem["workflow_id"],
      RoomUpdateBatchLogItem[]
    >();
  }

  return batchLogs.value.reduce((map, log) => {
    const list = map.get(log.workflow_id) ?? [];

    list.push(log);
    map.set(log.workflow_id, list);

    return map;
  }, new Map<RoomUpdateBatchLogItem["workflow_id"], RoomUpdateBatchLogItem[]>());
});

const roomIdToWorkflowIdMap = computed<
  Map<Room["uid"], RoomUpdateWorkflow["id"]>
>(() => {
  if (!batch.value || !batchLogs.value) return new Map();

  return batch.value.workflows.reduce((map, wf) => {
    map.set(wf.trg_room_id, wf.id);
    return map;
  }, new Map<Room["uid"], RoomUpdateWorkflow["id"]>());
});

const logsByRoom = computed(() => {
  const entries = Array.from(roomIdToWorkflowIdMap.value.entries());
  return entries.map<[Room, RoomUpdateBatchLogItem[]]>(
    ([roomId, workflowId]) => {
      return [
        getRoomByUid(roomId) as Room,
        batchLogsByWorkflowId.value.get(workflowId) ?? [],
      ];
    },
  );
});

const isVisible = ref(false);

const isLoading = ref(false);
const isError = ref(false);

const open = (pBatchId: RoomUpdateBatch["id"]) => {
  isVisible.value = true;

  isLoading.value = true;
  Promise.all([
    api.getBatch(pBatchId).then((b) => {
      batch.value = b;
    }),
    api.getBatchLogs(pBatchId).then((l) => {
      batchLogs.value = l;
    }),
  ])
    .then(
      () => {
        isError.value = false;
      },
      () => {
        isError.value = true;
      },
    )
    .finally(() => {
      isLoading.value = false;
    });
};

const close = () => {
  isVisible.value = false;

  batch.value = null;
  batchLogs.value = null;

  isLoading.value = false;
  isError.value = false;
};

defineExpose({
  open,
  close,
});
</script>

<style module lang="scss">
@use "@app/styles/scss/colors";

.logs {
  padding-bottom: 15px;
}

.log {
  margin-bottom: 10px;
}

.level {
  text-transform: capitalize;
}

.warning {
  color: colors.$ad-10_5;
}

.error {
  color: colors.$ad-3;
}
</style>
