import * as SentryBrowser from "@sentry/browser";
import * as SentryVue from "@sentry/vue";

import { APP_SETTINGS, USER_DATA } from "@setups/data";

import type { App } from "vue";
import type { Router } from "vue-router";

// Points to note:
//   1. Vue 3 lacks a Global Application concept.
//   2. ngVue creates a separate Application for each Vue component used on the Angular side.
//
// Based on the above:
//
//   If we are on the Angular side, then we initialize Sentry only for Angular.
//   We do not create a Vue application at all, referring to point 2.
//
//   If we are on the Vue side, then we initialize Sentry only for Vue.
//   We have migrated almost the entire codebase to be distinct from Angular.
//
// This method will be called twice on the Vue side:
//   1. First, by the create<Vue>App method (the app parameter will be passed).
//   2. In shared.js.
let isSentryInitialized = false;

export function initSentry(
  vueApp: App<Element> | null,
  vueRouter: Router | null,
) {
  if (isSentryInitialized) return;
  isSentryInitialized = true;

  type BrowswerOptions = Parameters<typeof SentryBrowser.init>[0];
  type VueOptions = Parameters<typeof SentryVue.init>[0];

  const options: BrowswerOptions = {
    dsn: APP_SETTINGS.SENTRY.dsn,
    enabled: !APP_SETTINGS.DEBUG,
    environment: APP_SETTINGS.WEBSITE.ENV,
    tracesSampleRate: 0.05,
    beforeBreadcrumb(bc, hint) {
      // Exclude breadcrumbs related to the "cinsight.dealroom.io" domain.
      // This domain is used for Pendo tracking and does not provide useful information for debugging.
      return bc.type === "http" &&
        bc.data?.url?.includes("cinsight.dealroom.io")
        ? null
        : bc;
    },
    beforeSend(e, hint) {
      return e;
    },
  };

  const isAngularSide = !!document.body.getAttribute("ng-app");
  if (isAngularSide) {
    SentryBrowser.init(options);
  } else {
    if (!vueApp) {
      throw new Error("Vue app is not provided for Sentry initialization");
    }

    const vueOptions: VueOptions = options;

    vueOptions.app = vueApp;

    if (vueRouter) {
      vueOptions.integrations = [
        SentryVue.browserTracingIntegration({ router: vueRouter }),
      ];
    }

    SentryVue.init(vueOptions);
  }

  if (USER_DATA.id) {
    const sentry = isAngularSide ? SentryBrowser : SentryVue;
    sentry.setUser({
      id: `${USER_DATA.id}`,
      email: USER_DATA.email,
    });
  }
}
