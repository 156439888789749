<template>
  <div :class="$style.nav">
    <RouterLink
      v-for="view in formattedViews"
      v-slot="{ navigate }"
      :key="view.slug"
      :to="'/deals/' + view.slug"
      custom
    >
      <ElDropdown
        v-if="view.isActive"
        trigger="click"
        @command="(command: DROPDOWN_COMMANDS) => handleCommand(command, view)"
      >
        <button :class="[$style.button, $style.button_isActive]">
          <span :class="$style.viewName">{{ view.name }}</span>
          <DrIcon name="caret-down" size="sm" :class="$style.icon" />
        </button>

        <template #dropdown>
          <ElDropdownMenu>
            <ElTooltip
              v-for="item in view.dropdown"
              :key="item.label"
              :content="item.disabledMsg"
              :disabled="!item.disabledMsg"
              :show-after="200"
            >
              <!-- extra span to make tooltip work with disable button -->
              <span>
                <ElDropdownItem
                  :disabled="!!item.disabledMsg"
                  :command="item.command"
                  data-testid="deals-nav-bar-view-menu-item"
                >
                  {{ item.label }}
                </ElDropdownItem>
              </span>
            </ElTooltip>
          </ElDropdownMenu>
        </template>
      </ElDropdown>

      <button v-else :class="$style.button" @click="navigate">
        <span :class="$style.viewName">{{ view.name }}</span>
      </button>
    </RouterLink>
  </div>
</template>

<script setup lang="ts">
import { computed } from "vue";
import { useRoute } from "vue-router";
import { useStore } from "vuex";
import DrIcon from "@shared/ui/dr-icon";

import { CustomViewObjectTypes } from "@setups/types";

import type { CustomView } from "@setups/types";

enum DROPDOWN_COMMANDS {
  pin,
  unpin,
  makeDefault,
}

interface DropdownItem {
  label: string;
  disabledMsg: string;
  command: DROPDOWN_COMMANDS;
}

interface FormattedView extends CustomView {
  isDefault: boolean;
  isPinned: boolean;
  isActive: boolean;
  dropdown: DropdownItem[];
}

const props = defineProps<{
  views: CustomView[];
}>();

const $store = useStore();
const $route = useRoute();
const MAX_PINNED_VIEWS = 10;

const pinnedViews = computed<CustomView[]>(
  () => $store.getters["common/customViews/dealsPinnedViews"],
);
const defaultView = computed(() =>
  $store.getters["common/customViews/defaultView"](CustomViewObjectTypes.Deal),
);

const getDropdownItems = (view: FormattedView): DropdownItem[] => {
  const items: DropdownItem[] = [];
  if (!view.isPinned) {
    items.push({
      label: "Pin view to keep open",
      disabledMsg:
        pinnedViews.value.length >= MAX_PINNED_VIEWS
          ? `You can't pin more than ${MAX_PINNED_VIEWS} custom-views`
          : "",
      command: DROPDOWN_COMMANDS.pin,
    });
  }

  if (view.isPinned) {
    items.push({
      label: "Unpin view",
      disabledMsg: view.isDefault
        ? "The default view can't be unpinned. Set another view as default to unpin this view."
        : "",
      command: DROPDOWN_COMMANDS.unpin,
    });
  }

  if (!view.isDefault) {
    items.push({
      label: "Make Default",
      disabledMsg: view.isPinned
        ? ""
        : "Only pinned custom-views can be set as default view",
      command: DROPDOWN_COMMANDS.makeDefault,
    });
  }
  return items;
};

const formattedViews = computed<FormattedView[]>(() => {
  const items: FormattedView[] = props.views.map((view) => {
    const formattedItem = {
      ...view,
      isDefault: defaultView.value.slug === view.slug,
      isPinned: !!pinnedViews.value.find((v) => v.slug == view.slug),
      isActive: $route.path.startsWith(`/deals/${view.slug}`),
      dropdown: [],
    };
    return {
      ...formattedItem,
      dropdown: getDropdownItems(formattedItem),
    };
  });
  const currentItem = items.find((item) => item.isActive);
  const currentItemIndex = items.findIndex((item) => item.isActive);

  if (!currentItem || currentItemIndex <= MAX_PINNED_VIEWS - 1) {
    return items.slice(0, MAX_PINNED_VIEWS);
  }
  return items.slice(0, MAX_PINNED_VIEWS - 1).concat([currentItem]);
});

const setPinnedDealsView = async (views: string[]): Promise<void> => {
  await $store.dispatch(
    "clientDashboard/preferences/setPinnedDealsView",
    views,
  );
};
const pinView = (view: FormattedView) => {
  const views = pinnedViews.value.map((v) => v.slug);
  if (views.includes(view.slug)) {
    return;
  }

  views.push(view.slug);

  if (views.length > MAX_PINNED_VIEWS) {
    return;
  }
  setPinnedDealsView(views);
};
const unpinView = (view: FormattedView) => {
  if (view.slug === defaultView.value.slug) {
    return;
  }
  const views = pinnedViews.value
    .map((v) => v.slug)
    .filter((slug) => slug !== view.slug);
  setPinnedDealsView(views);
};
const handleCommand = (command: DROPDOWN_COMMANDS, view: FormattedView) => {
  switch (command) {
    case DROPDOWN_COMMANDS.makeDefault:
      $store.dispatch(
        "clientDashboard/preferences/setDefaultDealsView",
        view.slug,
      );
      break;
    case DROPDOWN_COMMANDS.pin:
      pinView(view);
      break;
    case DROPDOWN_COMMANDS.unpin:
      unpinView(view);
      break;
  }
};
</script>

<style lang="scss" module>
@use "@app/styles/scss/colors";
@use "@app/styles/scss/typography" as typo;

.nav {
  display: grid;
  grid-auto-flow: column;
  align-items: stretch;
  gap: 20px;
  height: 100%;
}

.item {
  display: flex;
}

.icon {
  color: colors.$pr-400;
}

.button {
  display: grid;
  grid-auto-flow: column;
  align-items: center;
  gap: 6px;
  height: 100%;
  max-width: 168px;
  padding: 0 2px;
  border: none;
  border-radius: 0;
  font: typo.$body_regular;
  background-color: transparent;
  color: colors.$pr-500;
  border-bottom: solid 2px transparent;
  transition: all ease-out 200ms;

  &:focus,
  &:hover {
    color: colors.$pr-800;
  }
}

.button_isActive {
  font: typo.$body_semibold;

  &,
  &:focus,
  &:hover {
    color: colors.$pr-900;
    border-bottom-color: colors.$sc-600;
  }
}

.viewName {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
</style>
