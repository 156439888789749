<template>
  <div ref="containerRef" :class="$style.toolbar">
    <div
      v-if="isRecent && selectedItems.length === 0"
      :class="$style.toolbarLeft"
    >
      <DrToolbarFilterDatepicker
        :model-value="filterUploadDate"
        :label="$t('data_room.upload_date')"
        @update:model-value="$emit('update:filter-upload-date', $event)"
      />
    </div>

    <div v-if="selectedItems.length === 0" :class="$style.toolbarRight">
      <DrButtonMore>
        <ElDropdownItem
          v-if="ROOM_DATA.userPermissions.canCreateTasks"
          type="text"
          @click="createNewRequest"
        >
          {{ t("requests.new_request") }}
        </ElDropdownItem>

        <ElDropdownItem
          v-if="selectedFolder?.edit && docItems.length"
          type="text"
          @click="showMenuEditIndex"
        >
          {{ $t("data_room.change_index.modal_title") }}
        </ElDropdownItem>

        <ElDropdownItem
          v-if="selectedFolder"
          type="text"
          @click="exportIndexesPdf"
        >
          {{ t("requests.export_to_pdf") }}
        </ElDropdownItem>

        <ElDropdownItem
          v-if="selectedFolder"
          type="text"
          @click="exportIndexesXlsx"
        >
          {{ t("requests.export_to_excel") }}
        </ElDropdownItem>
      </DrButtonMore>

      <DrTooltip
        v-if="selectedFolder?.edit"
        :content="$t('data_room.add_folder')"
      >
        <ElButton :class="$style.iconBtn" @click="showMenuNewFolder">
          <DrIcon name="folder-plus" />
        </ElButton>
      </DrTooltip>

      <DocumentsTableOptionsButton />

      <template v-if="!hasSearch && selectedFolder">
        <DrUploadButton
          v-if="selectedFolder.edit"
          type="primary"
          @selected="$emit('selected-files-to-upload', $event)"
        />

        <DrUploadDisabledButton v-else />
      </template>

      <DrTooltip :content="getDownloadTooltipText(docItems)" placement="bottom">
        <ElButton
          type="primary"
          :disabled="getIsDownloadButtonDisabled(docItems)"
          :class="$style.downloadBtn"
          @click="downloadBulk(docItems)"
        >
          <DrIcon name="download" /> Download all
          <DrIcon v-if="getIsDownloadLimited(docItems)" name="info-circle" />
        </ElButton>
      </DrTooltip>
    </div>

    <DrToolbarBulkActions v-else :actions="bulkActions" slot-on-right>
      <DrToolbarFilterButton
        :label="$t('data_room.selected', { count_text: countText })"
        is-active
        @clear="clearSelected"
      />

      <template #actionsPrefix>
        <DrTooltip
          :content="getDownloadTooltipText(selectedItems)"
          placement="bottom"
        >
          <ElButton
            type="primary"
            :disabled="getIsDownloadButtonDisabled(selectedItems)"
            :class="$style.downloadBtn"
            @click="downloadBulk(selectedItems)"
          >
            <DrIcon name="download" /> Download selected

            <DrTooltip
              v-if="getIsDownloadLimited(selectedItems)"
              :content="$t('data_room.download.error_messages.limited')"
              placement="bottom"
            >
              <DrIcon name="info-circle" />
            </DrTooltip>
          </ElButton>
        </DrTooltip>
      </template>
    </DrToolbarBulkActions>
  </div>
</template>

<script lang="ts" setup>
import { ElButton } from "element-plus";
import { ElDropdownItem } from "element-plus";
import { computed, markRaw, ref, watch } from "vue";
import { useI18n } from "vue-i18n";

import { insightTrack, RoomDataroomBulkEvent } from "@app/insight";
import { ROOM_DATA } from "@app/setups";
import { ROOM_MEMBER_DATA } from "@app/setups/data";
import { AiAccess } from "@app/setups/enums";
import DrButtonMore from "@app/vue/shared/ui/dr-button/DrButtonMore.vue";
import DrIcon from "@app/vue/shared/ui/dr-icon/DrIcon.vue";
import DrToolbarBulkActions from "@app/vue/shared/ui/dr-toolbar/DrToolbarBulkActions.vue";
import DrToolbarFilterButton from "@app/vue/shared/ui/dr-toolbar/DrToolbarFilterButton.vue";
import DrToolbarFilterDatepicker from "@app/vue/shared/ui/dr-toolbar/DrToolbarFilterDatepicker.vue";
import DrTooltip from "@app/vue/shared/ui/dr-tooltip/DrTooltip.vue";
import DrUploadButton from "@app/vue/shared/ui/dr-upload-button/DrUploadButton.vue";
import DrUploadDisabledButton from "@app/vue/shared/ui/dr-upload-button/DrUploadDisabledButton.vue";
import {
  type Document,
  type Folder,
  isDocument,
  isFolder,
} from "@app/vue/store/modules/room/documents/DocumentsApiService";
import { useDocumentsStore } from "@app/vue/store/pinia/room/documents/documents";
import FolderMenuAiReport from "./components/FolderMenuAiReport.vue";
import FolderMenuChangeIndex from "./components/FolderMenuChangeIndex.vue";
import FolderMenuCopy from "./components/FolderMenuCopy.vue";
import FolderMenuMove from "./components/FolderMenuMove.vue";
import FolderMenuNewFolder from "./components/FolderMenuNewFolder.vue";
import FolderMenuNewRequest from "./components/FolderMenuNewRequest.vue";
import DocumentsTableOptionsButton from "./DocumentsTableOptionsButton.vue";
import IconAiAnalysis from "./svg/IconAiAnalysis.vue";
import { useFolderMenu } from "./use/useFolderMenu";
import {
  useFolderBulkMenuBus,
  useFolderMenuBus,
  useFolderMenuPersistedBus,
} from "./use/useFolderMenuBus";
import { getDocumentsCountText } from "./utils";

import type { ToolbarBulkActionsItem } from "@app/vue/shared/ui/dr-toolbar/types";

interface Props {
  docItems: (Folder | Document)[];
  selectedFolder: Folder | undefined;
  isBookmarks?: boolean;
  isArchive?: boolean;
  isRecent?: boolean;
  filterUploadDate: [Date, Date] | [];
  hasSearch?: boolean;
}

interface Events {
  (e: "checkbox-all"): void;
  (e: "update:filter-upload-date", value: [Date, Date] | []): void;
  (e: "selected-files-to-upload", files: File[]): void;
  (e: "update:list"): void;
  (e: "update-has-selected", value: boolean): void;
  (e: "download-bulk", folderIds: number[], documentIds: number[]): void;
}

const props = defineProps<Props>();

const emit = defineEmits<Events>();

const { t } = useI18n();

const documentsStore = useDocumentsStore();

const bulkActions = computed<ToolbarBulkActionsItem[]>(() => {
  const isOnlyDocumentsSelected =
    selectedItemsViewable.value.length === selectedItems.value.length;

  return [
    {
      id: "bookmark",
      name: t("data_room.bookmark_items.bookmark"),
      icon: "bookmark",
      getIsVisible: () => !props.isBookmarks && !props.isArchive,
      action: () => bookmarkItems(true),
    },
    {
      id: "unbookmark",
      name: t("data_room.bookmark_items.unbookmark"),
      icon: "bookmark",
      getIsVisible: () => props.isBookmarks && !props.isArchive,
      action: () => bookmarkItems(false),
    },
    {
      id: "new_request",
      name: t("nav.new_request"),
      icon: "calendar-plus",
      getIsVisible: () =>
        !props.isArchive && ROOM_DATA.userPermissions.canCreateTasks,
      action: (event) => createNewRequest(event),
    },
    {
      id: "move",
      name: t("shared.move"),
      icon: "arrows",
      getIsVisible: () =>
        !props.isBookmarks &&
        !props.isArchive &&
        selectedItemsEditable.value.length !== 0,
      action: (event) => showMenuMove(event),
    },
    {
      id: "copy",
      name: t("shared.copy"),
      icon: "clone",
      getIsVisible: () =>
        !props.isArchive && selectedItemsEditable.value.length !== 0,
      action: (event) => showMenuCopy(event),
    },
    {
      id: "archive",
      name: t("shared.delete"),
      icon: "archive",
      getIsVisible: () =>
        !props.isBookmarks &&
        !props.isArchive &&
        selectedItemsEditable.value.length !== 0,
      action: () => removeItems(),
    },
    {
      id: "restore",
      name: t("shared.restore"),
      icon: "undo",
      getIsVisible: () => props.isArchive,
      action: () => restoreItems(),
    },
    {
      id: "delete",
      name: t("data_room.delete_permanently.button"),
      icon: "archive",
      getIsVisible: () => props.isArchive,
      action: () => deletePermanentlyItems(),
    },
    {
      id: "ai_report",
      name: t("data_room.analyse_with_ai.button"),
      iconComponent: markRaw(IconAiAnalysis),
      getIsVisible: () =>
        !props.isArchive &&
        ROOM_DATA.isAiEnabled &&
        ROOM_MEMBER_DATA.group.ai_access === AiAccess.BASIC,
      action: (event) => showMenuAiReport(event),
      tooltip: !isOnlyDocumentsSelected
        ? t("data_room.analyse_with_ai.tooltip")
        : undefined,
      disabled: !isOnlyDocumentsSelected,
    },
  ];
});

const containerRef = ref<HTMLDivElement | undefined>();

const selectedItems = computed(() =>
  props.docItems.filter((item) => item._isChecked),
);

const selectedItemsViewable = computed(
  () =>
    selectedItems.value.filter(
      (item) => isDocument(item) && item.processing?.is_viewable,
    ) as Document[],
);

const selectedItemsEditable = computed(() =>
  selectedItems.value.filter((item) => item.edit),
);

const countText = computed(() => {
  return getDocumentsCountText({
    folders: selectedItems.value.filter(isFolder),
    documents: selectedItems.value.filter(isDocument),
  });
});

const isSingleDocument = (items: (Folder | Document)[]): boolean =>
  items.length === 1 && isDocument(items[0]);

const getDownloadTooltipText = (
  items: (Folder | Document)[],
): string | undefined => {
  if (props.isArchive || ROOM_DATA.userPermissions.administrator)
    return undefined;

  if (
    !ROOM_DATA.enableBulkDownload &&
    !isSingleDocument(items.filter((item) => item.download))
  )
    return t("data_room.download.error_messages.bulk_disabled");

  if (items.every((item) => !item.download))
    return t("data_room.download.error_messages.no_access");

  return undefined;
};

const getIsDownloadButtonDisabled = (items: (Folder | Document)[]): boolean => {
  if (props.isArchive || ROOM_DATA.userPermissions.administrator) return false;

  if (
    !ROOM_DATA.enableBulkDownload &&
    !isSingleDocument(items.filter((item) => item.download))
  )
    return true;

  if (items.every((item) => !item.download)) return true;

  return false;
};

const getIsDownloadLimited = (items: (Folder | Document)[]): boolean => {
  if (getIsDownloadButtonDisabled(items)) return false;

  return items.some((item) => !item.download);
};

const folderMenu = useFolderMenu();
const folderMenuBus = useFolderMenuBus();
const folderMenuPersistedBus = useFolderMenuPersistedBus();
const folderBulkMenuBus = useFolderBulkMenuBus();

const getItemsToDownload = (
  items: (Folder | Document)[],
): (Folder | Document)[] => {
  if (props.isArchive || ROOM_DATA.userPermissions.administrator) return items;

  if (!ROOM_DATA.enableBulkDownload)
    return items.filter((item) => isDocument(item) && item.download);

  return items.filter((item) => item.download);
};

const downloadBulk = (items: (Folder | Document)[]): void => {
  const itemsAllowedDownload = getItemsToDownload(items);

  emit(
    "download-bulk",
    itemsAllowedDownload.filter(isFolder).map((item) => item.id),
    itemsAllowedDownload.filter(isDocument).map((item) => item.id),
  );
};

const showMenuNewFolder = (event: MouseEvent) => {
  if (!(event.target instanceof HTMLElement) || !props.selectedFolder) return;

  insightTrack(RoomDataroomBulkEvent.NewFolder);

  folderMenuBus.emit("menu:show", {
    reference: event.target,
    params: {
      folders: [props.selectedFolder],
      documents: [],
    },
    placement: "bottom",
    menuComponent: markRaw(FolderMenuNewFolder),
  });
};

const showMenuEditIndex = (event: MouseEvent) => {
  if (!(event.target instanceof HTMLElement) || !props.docItems[0]) return;

  insightTrack(RoomDataroomBulkEvent.IndexChange);

  folderMenuBus.emit("menu:show", {
    reference: event.target,
    params: {
      folders: [],
      documents: [],
      [isFolder(props.docItems[0]) ? "folders" : "documents"]: [
        props.docItems[0],
      ],
    },
    menuComponent: markRaw(FolderMenuChangeIndex),
  });
};

const showMenuMove = (event?: MouseEvent) => {
  const reference = event?.target ?? containerRef.value;

  if (!(reference instanceof HTMLElement)) return;

  insightTrack(RoomDataroomBulkEvent.Move);

  folderMenuBus.emit("menu:show", {
    reference,
    params: {
      folders: selectedItemsEditable.value.filter(isFolder),
      documents: selectedItemsEditable.value.filter(isDocument),
    },
    placement: "bottom",
    menuComponent: markRaw(FolderMenuMove),
  });
};

const showMenuCopy = (event?: MouseEvent) => {
  const reference = event?.target ?? containerRef.value;

  if (!(reference instanceof HTMLElement)) return;

  insightTrack(RoomDataroomBulkEvent.Copy);

  folderMenuBus.emit("menu:show", {
    reference,
    params: {
      folders: selectedItems.value.filter(
        (item): item is Folder => isFolder(item) && item.download,
      ),
      documents: selectedItems.value.filter(
        (item): item is Document => isDocument(item) && item.download,
      ),
    },
    placement: "bottom",
    menuComponent: markRaw(FolderMenuCopy),
  });
};

const showMenuAiReport = (event?: MouseEvent) => {
  const reference = event?.target ?? containerRef.value;

  if (!(reference instanceof HTMLElement)) return;

  insightTrack(RoomDataroomBulkEvent.AiReportOpen);

  folderMenuBus.emit("menu:show", {
    reference,
    params: {
      folders: [],
      documents: selectedItemsViewable.value,
    },
    placement: "bottom",
    menuComponent: markRaw(FolderMenuAiReport),
  });
};

const bookmarkItems = async (set_bookmarked: boolean) => {
  insightTrack(RoomDataroomBulkEvent.Bookmark);

  documentsStore.bookmarkItems({
    folder_ids: selectedItems.value.filter(isFolder).map((item) => item.id),
    document_ids: selectedItems.value.filter(isDocument).map((item) => item.id),
    set_bookmarked,
  });
};

const createNewRequest = (event?: MouseEvent) => {
  const reference = event?.target ?? containerRef.value;

  if (!(reference instanceof HTMLElement)) return;

  insightTrack(RoomDataroomBulkEvent.NewRequest);

  folderMenuBus.emit("menu:show", {
    reference,
    params: {
      folders: selectedItems.value.filter(isFolder),
      documents: selectedItems.value.filter(isDocument),
    },
    menuComponent: markRaw(FolderMenuNewRequest),
  });
};

const removeItems = () => {
  folderMenuPersistedBus.emit("menu:persisted", true);

  insightTrack(RoomDataroomBulkEvent.Remove);

  documentsStore
    .archiveItems({
      folders: selectedItems.value.filter(isFolder),
      documents: selectedItems.value.filter(isDocument),
    })
    .finally(() => {
      folderMenuPersistedBus.emit("menu:persisted", false);
    });
};

const restoreItems = () => {
  folderMenuPersistedBus.emit("menu:persisted", true);

  insightTrack(RoomDataroomBulkEvent.Restore);

  folderMenu
    .restoreItems({
      folders: selectedItems.value.filter(isFolder),
      documents: selectedItems.value.filter(isDocument),
    })
    .then(() => {
      emit("update:list");
    })
    .finally(() => {
      folderMenuPersistedBus.emit("menu:persisted", false);
    });
};

const deletePermanentlyItems = () => {
  folderMenuPersistedBus.emit("menu:persisted", true);

  insightTrack(RoomDataroomBulkEvent.DeletePermanently);

  folderMenu
    .deleteItems({
      folders: selectedItems.value.filter(isFolder),
      documents: selectedItems.value.filter(isDocument),
    })
    .then(() => {
      emit("update:list");
    })
    .finally(() => {
      folderMenuPersistedBus.emit("menu:persisted", false);
    });
};

const clearSelected = () => {
  props.docItems.forEach((item) => {
    item._isChecked = false;
  });

  emit("checkbox-all");

  folderBulkMenuBus.emit("bulk:clear-selected");
};

const exportIndexesPdf = () => {
  if (!props.selectedFolder) return;

  insightTrack(RoomDataroomBulkEvent.ExportIndexPdf);

  folderMenu.exportIndexesPdf(props.selectedFolder);
};

const exportIndexesXlsx = () => {
  if (!props.selectedFolder) return;

  insightTrack(RoomDataroomBulkEvent.ExportIndexXlsx);

  folderMenu.exportIndexesXlsx(props.selectedFolder);
};

watch(
  selectedItems,
  (value) => {
    emit("update-has-selected", value.length !== 0);
  },
  { immediate: true },
);
</script>

<style lang="scss" module>
@use "@app/styles/scss/spacing";

$toolbar-space: 8px;

.iconCopy {
  font-size: 12px;
}

.iconBtn:global(.el-button) {
  width: 32px;
  margin-left: 0;
}

.downloadBtn:global(.el-button) {
  margin-left: 0;
  padding: spacing.$xxs spacing.$xs;

  > span {
    display: inline-grid;
    grid-auto-flow: column;
    gap: spacing.$xs;
    align-items: center;
  }
}

.toolbar {
  display: grid;
  grid-auto-flow: column;
  align-items: center;
  gap: $toolbar-space;
}

.toolbarRight {
  display: grid;
  grid-auto-flow: column;
  gap: $toolbar-space;
  justify-self: flex-end;
}

.toolbarLeft {
  display: grid;
  grid-auto-flow: column;
  gap: $toolbar-space;
  justify-self: flex-start;
  padding-left: $toolbar-space;
}
</style>
