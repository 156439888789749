import { CustomViewObjectTypes } from "@setups/types";
import { t } from "@app/vue/i18n";

import type {
  Document,
  Folder,
} from "@app/vue/store/modules/room/documents/DocumentsApiService";
import type { RootState } from "@drVue/store/state";
import type { CustomViewColumn } from "@setups/types";
import type { Store } from "vuex";

function updateColumns(columns: CustomViewColumn[], update: CustomViewColumn) {
  const col = columns.find((c) => c.field === update.field);
  if (col) {
    const i = columns.indexOf(col);
    columns.splice(i, 1);
  }
  columns.push({
    ...(col || {}),
    ...update,
  });
}

function handleUpdates(
  columns: CustomViewColumn[],
  updates: CustomViewColumn | CustomViewColumn[],
) {
  if (Array.isArray(updates)) {
    updates.forEach((u) => updateColumns(columns, u));
  } else {
    updateColumns(columns, updates);
  }

  return columns;
}

export function saveColumnsConfig(
  $store: Store<RootState>,
  updates: CustomViewColumn | CustomViewColumn[],
) {
  const view = $store.getters["common/customViews/defaultView"](
    CustomViewObjectTypes.DataRoom,
  );

  handleUpdates(view.settings.columns, updates);
  const method = view.is_default ? "create" : "update";
  $store.dispatch(`common/customViews/${method}`, view);
}

export type FolderMenuParams = { folders: Folder[]; documents: Document[] };

export const getDocumentsCountText = (params: FolderMenuParams) => {
  if (params.folders.length && params.documents.length === 0) {
    return t(
      "data_room.count_text.folders",
      { count: params.folders.length },
      params.folders.length,
    );
  }

  if (params.documents.length && params.folders.length === 0) {
    return t(
      "data_room.count_text.documents",
      { count: params.documents.length },
      params.documents.length,
    );
  }

  return t(
    "data_room.count_text.folders_documents",
    {
      count: params.documents.length + params.folders.length,
    },
    params.documents.length + params.folders.length,
  );
};

export const getDocumentsSelectedItemsText = (params: FolderMenuParams) => {
  if (params.folders.length && params.documents.length === 0) {
    return t(
      "data_room.selected_items.folders",
      { name: params.folders[0].name },
      params.folders.length,
    );
  }

  if (params.documents.length && params.folders.length === 0) {
    return t(
      "data_room.selected_items.documents",
      { name: params.documents[0].name },
      params.documents.length,
    );
  }

  return t("data_room.selected_items.folders_documents");
};
