<template>
  <div
    :class="{
      [$style.container]: true,
      [$style.container_isUnapprovedComment]: item.need_approve,
      [$style.container_isNewComment]: isNew,
    }"
  >
    <DrAvatar :name="sender.name" :url="sender.avatar.thumbnail" />
    <div>
      <div :class="$style.header">
        <UserInformation
          :class="$style.userInfo"
          :user="sender"
          :show-avatar="false"
        />
        <span :class="$style.timestamp">{{ timestamp }}</span>
        <CommentViewersTag
          :groups="DrStore.state.room.groups.pgroups"
          :comment="item"
        />
      </div>
      <DrEditor
        v-if="item.body"
        :class="$style.text"
        :model-value="item.body"
        :mentions="mentions"
        :editable="false"
      />
      <div v-if="isApproveBtnVisible" :class="$style.approveFooter">
        Approve comment so it be visible for non-admin groups.
        <ElButton size="small" @click="handleDelete">
          <template #icon>
            <DrIcon name="trash" />
          </template>
        </ElButton>
        <ElButton
          type="primary"
          size="small"
          :class="$style.approveBtn"
          @click="handleApprove"
        >
          Approve
        </ElButton>
      </div>
      <AttachmentsList :items="attachments" />
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed } from "vue";
import { DrAvatar } from "@shared/ui/dr-avatar";
import DrIcon from "@shared/ui/dr-icon";
import DrEditor from "@shared/ui/editor";
import { UserInformation } from "@shared/ui/users";

import { ORG_MEMBER_DATA, USER_DATA } from "@app/setups";
import { DrStore } from "@app/vue";
import { useDocumentsStore } from "@app/vue/store/pinia/room/documents/documents";
import { $notifyDanger, $notifySuccess } from "@drVue/common";
import AttachmentsList from "@drVue/components/room/tasks/Attachments/AttachmentsList.vue";
import CommentViewersTag from "@drVue/components/room/tasks/comments/CommentViewersTag.vue";
import { drUserTime } from "@drVue/filters/drUserTime";
import { TasksApiService, useTasksStore } from "@drVue/store/pinia/room/tasks";

import type { TaskComment } from "@drVue/store/modules/room/types";

interface Props {
  item: TaskComment;
  lastVisitDate: Date | null;
}
const props = defineProps<Props>();

const emit = defineEmits(["approved", "deleted"]);

const documentsStore = useDocumentsStore();

const isApproveBtnVisible = computed(() => {
  return ORG_MEMBER_DATA?.group?.is_administrator && props.item.need_approve;
});

const sender = computed(() => {
  const sender = {
    id: props.item.sender,
    name: "Anonymous",
    avatar: { thumbnail: "" },
    email: "",
    link: "",
    pending: false,
  };
  const member = DrStore.state.room.members.members[props.item.sender];
  if (member) {
    sender.name = member.name;
    sender.avatar.thumbnail = member.avatar.thumbnail;
    sender.email = member.email;
  }

  return sender;
});
const isNew = computed(() => {
  if (props.lastVisitDate === null) return false;
  return (
    sender.value.id !== USER_DATA.id &&
    props.item.date_added > props.lastVisitDate
  );
});
const timestamp = computed(() =>
  drUserTime(props.item.date_added, "short-time"),
);
const tasksStore = useTasksStore();
const mentions = computed(() => ({
  tasks: tasksStore.tasksList,
  users: DrStore.state.room.members.membersList,
}));

const attachments = computed(() =>
  [
    ...props.item.folders.map(
      ({ folder_id }) => documentsStore.folderByIdMap[folder_id],
    ),
    ...props.item.documents.map(
      ({ document_id }) => documentsStore.fileByIdMap[document_id],
    ),
  ].sort((a, b) => a.treePosition - b.treePosition),
);

const api = new TasksApiService();

const handleApprove = async () => {
  try {
    await api.approveComment(props.item.id, props.item.task_id, USER_DATA.id);
    emit("approved");
    $notifySuccess("A comment has been approved successfully");
  } catch {
    $notifyDanger("Something went wrong while approving a comment");
  }
};

const handleDelete = async () => {
  try {
    await api.deleteComment(props.item.id, props.item.task_id);
    emit("deleted");
    $notifySuccess("A comment has been deleted successfully");
  } catch {
    $notifyDanger("Something went wrong while deleting a comment");
  }
};
</script>

<style module lang="scss">
@use "@app/styles/scss/typography.scss";
@use "@app/styles/scss/colors";

.container {
  display: grid;
  grid-template-columns: 32px auto;
  padding: 16px 8px;
  border-radius: 8px;
}

.container_isUnapprovedComment {
  border: 1px solid colors.$pr-200;
  background-color: colors.$pr-50;
  margin-bottom: 8px;
}

.container_isNewComment {
  border: 1px solid colors.$sc-300;
  background-color: colors.$sc-50;
  margin-bottom: 8px;
}

.container_isNeedApprove {
  border: 1px solid colors.$pr-200;
  border-radius: 8px;
  background-color: colors.$pr-50;
}

.approveFooter {
  font: typography.$caption_regular;
  display: inline-flex;
  align-items: center;
  gap: 8px;
  margin: 0 -10px -10px 0;
}

.approveBtn {
  margin: unset !important;
  height: calc(var(--el-button-size) - 2px);
}

.header {
  display: inline-flex;
  gap: 8px;
}

.text p {
  font: typography.$body_regular;
  word-break: break-word;
  text-align: left;
  :global(.user-information__icon) {
    display: none;
  }
  :global(.user-information__name::before) {
    content: "@";
    font-size: 14px;
  }
}

.userInfo {
  font: typography.$caption_semibold;
  color: colors.$pr-800;
}

.timestamp {
  font: typography.$caption_regular;
  color: colors.$pr-500;
}
</style>
