<template>
  <DrLayoutContentCenteredSection title="Phases">
    <template #description>
      Customize the phases you use to move perspective deals through, from
      sourcing to integration.
    </template>

    <template #title-right>
      <ElButton
        :disabled="showAddPhaseForm"
        type="primary"
        @click="showAddPhaseForm = true"
      >
        <span>New phase</span>
        <i class="far fa-plus" />
      </ElButton>
    </template>

    <Draggable v-model="phasesList" item-key="id" :options="sortableOptions">
      <template #item="{ element: phase }">
        <PhaseRow :phase="phase" is-draggable />
      </template>
    </Draggable>

    <div v-if="showAddPhaseForm" class="client-settings-panel">
      <!-- @vue-expect-error -->
      <PhaseEditForm :phase="{}" @close="showAddPhaseForm = false" />
    </div>
  </DrLayoutContentCenteredSection>
</template>

<script lang="ts" setup>
import { computed, ref } from "vue";
import Draggable from "vuedraggable";
import { useStore } from "vuex";
import { DrLayoutContentCenteredSection } from "@shared/ui/dr-layouts";

import PhaseEditForm from "./EditForm";
import PhaseRow from "./Row";

import type {
  Item,
  Order,
  PhaseItem,
} from "@drVue/api-service/client-dashboard";

interface SortOrder {
  id: number;
  order: number;
}

const $store = useStore();

const showAddPhaseForm = ref(false);
const sortableOptions = {
  filter: ".el-form",
  preventOnFilter: false,
};

const setOrderByIndex = (items: Item[]): SortOrder[] => {
  return items.map(({ id }: Order, index: number) => {
    return { id, order: index + 1 };
  });
};

const phasesList = computed({
  get(): PhaseItem[] {
    return $store.state.clientDashboard.phases.items;
  },
  set(value: PhaseItem[]) {
    const data = setOrderByIndex(value);
    $store.dispatch("clientDashboard/phases/reorder", { data: data });
  },
});
</script>
