<template>
  <DrDrawer
    :shown="isVisible"
    :title="$t('room_updates.review_update')"
    :submit="$t('room_updates.apply_updates')"
    :no-footer="!tableRows.length"
    size="xl"
    @submit="handleFormSubmit"
    @cancel="close"
    @close="close"
  >
    <template #description>
      {{ $t("room_updates.select_description") }}
    </template>

    <DrVxeGrid
      :set-grid-ref="setGridRef"
      :class="$style.changesTable"
      :tree-config="{
        rowField: 'id',
        showIcon: false,
        expandAll: true,
      }"
      :data="tableRows"
      :columns="tableColumns.columns"
      :row-class-name="getRowClassName"
      :show-header="false"
      outlined
    >
      <template #empty>{{ $t("room_updates.no_changes_found") }}</template>
    </DrVxeGrid>

    <template #controls>
      <div v-if="batch && batchHasIssues" :class="$style.issuesMessage">
        <DrIcon name="exclamation-triangle" :class="$style.warning" size="sm" />
        {{ $t("room_updates.some_updates_missing") }}
        <a href="" @click.prevent="openSeeUpdateLogsPanel(batch.id)">{{
          $t("room_updates.see_log")
        }}</a>
      </div>
    </template>
  </DrDrawer>
</template>

<script setup lang="ts">
import { computed, inject, ref, useCssModule } from "vue";
import { useI18n } from "vue-i18n";
import { DrDrawer } from "@shared/ui/dr-drawer";
import DrIcon from "@shared/ui/dr-icon";
import DrVxeGrid from "@shared/ui/dr-vxe-grid";

import { $notifyDanger } from "@app/vue/common";
import {
  CHANGE_OBJECT_TYPE,
  isReadyForReview,
  MultiRoomUpdateService,
} from "./api";
import {
  type ChangeRow,
  type ChildChangeRow,
  isChildRow,
  isParentRow,
  type ParentChangeRow,
  TableColumns,
} from "./tableColumns";
import { seeUpdateLogsRefKey } from "./types";
import { type ChangeKey, getChangeKey, getChangeType } from "./utils";

import type {
  ChangeObjType,
  ChangeOperation,
  RoomUpdateBatch,
  RoomUpdateBatchLogItem,
  WorkflowToApply,
} from "./api";
import type { VxeGridInstance, VxeTablePropTypes } from "vxe-table";

interface Emits {
  (event: "applied", callback: () => void): void;
}

const emit = defineEmits<Emits>();

const style = useCssModule();

const { t } = useI18n();

const api = new MultiRoomUpdateService();
const tableColumns = new TableColumns();

const seeUpdateLogsPanelRef = inject(seeUpdateLogsRefKey);

const changesTableRef = ref<VxeGridInstance<ChangeRow> | null>(null);
const setGridRef = (ref: VxeGridInstance) => (changesTableRef.value = ref);
const getRowClassName: VxeTablePropTypes.RowClassName<ChangeRow> = ({ row }) =>
  isParentRow(row) ? style.parentRow : "";

const batch = ref<RoomUpdateBatch | null>(null);
const batchLogs = ref<RoomUpdateBatchLogItem[] | null>(null);

const batchHasIssues = computed(() => {
  return (
    batchLogs.value?.some(
      (log) => log.level === "warning" || log.level === "error",
    ) ?? false
  );
});

const openSeeUpdateLogsPanel = (batchId: RoomUpdateBatch["id"]) => {
  seeUpdateLogsPanelRef?.value?.open(batchId, null);
};

const isVisible = ref(false);

const handleFormSubmit = () => {
  api.applyBatch(batch.value!.id, workflowsToApply.value).then(
    () => {
      emit("applied", close);
    },
    () => {
      $notifyDanger("room_updates.failed_to_apply");
    },
  );
};

const workflowsToApply = computed(() => {
  if (!batch.value) return [];

  const checked = changesTableRef.value?.getCheckboxRecords();
  if (!checked) return [];

  const checkedSet = new Set(
    checked.filter(isChildRow).map((r) => getChangeKey(r.change)),
  );

  const toApply = batch.value.workflows
    .filter(isReadyForReview)
    .reduce<WorkflowToApply[]>((acc, workflow) => {
      const reviewedChanges = workflow.initial_changes.filter((change) =>
        checkedSet.has(getChangeKey(change)),
      );

      acc.push({
        workflow_id: workflow.id,
        reviewed_changes: reviewedChanges,
      });

      return acc;
    }, []);

  return toApply;
});

const OBJECT_TYPE_LABELS = computed(() => {
  return {
    [CHANGE_OBJECT_TYPE.CATEGORY]: t("shared.worklists"),
    [CHANGE_OBJECT_TYPE.TASK]: t("shared.requests"),
    [CHANGE_OBJECT_TYPE.DOCUMENT]: t("shared.documents"),
    [CHANGE_OBJECT_TYPE.FOLDER]: t("shared.folders"),
    [CHANGE_OBJECT_TYPE.GROUP_PERMISSION_MEMBER]: t("shared.users"),
    [CHANGE_OBJECT_TYPE.GROUP_PERMISSION]: t("shared.user_groups"),
  };
});

const changesMap = computed<
  Map<ChangeObjType, Map<ChangeKey, ChangeOperation>>
>(() => {
  if (!batch.value) return new Map();

  const map = new Map<ChangeObjType, Map<ChangeKey, ChangeOperation>>();

  batch.value.workflows.forEach((wf) => {
    wf.initial_changes?.forEach((ch) => {
      const changesMap = map.get(getChangeType(ch)) ?? new Map();
      const key: ChangeKey = getChangeKey(ch);

      // Multiple worklows can have the same change, so we need to deduplicate them.
      // Source Room -> Target Room 1 = Add "HR Team Group"
      // Source Room -> Target Room 2 = Add "HR Team Group"
      if (!changesMap.has(key)) changesMap.set(key, ch);

      map.set(getChangeType(ch), changesMap);
    });
  });

  return map;
});

const tableRows = computed(() => {
  if (!batch.value) return [];

  const rows: ParentChangeRow[] = [];

  for (const [key, map] of changesMap.value.entries()) {
    const label =
      OBJECT_TYPE_LABELS.value[key as keyof typeof OBJECT_TYPE_LABELS.value];

    rows.push({
      id: key,
      label,
      batchId: batch.value.id,
      children: Array.from(map.entries()).map(
        ([key, change]): ChildChangeRow => ({ id: key, change }),
      ),
    });
  }

  return rows;
});

const open = (batchId: RoomUpdateBatch["id"]) => {
  isVisible.value = true;

  api.getBatch(batchId).then((b) => {
    batch.value = b;
  });

  api.getBatchLogs(batchId).then((logs) => {
    batchLogs.value = logs;
  });
};

const close = () => {
  isVisible.value = false;

  batch.value = null;
};

defineExpose({
  open,
  close,
});
</script>

<style module lang="scss">
@use "@app/styles/scss/colors";

.changesTable {
  :global {
    .vxe-table--render-default {
      .vxe-tree-cell {
        padding-left: 0 !important;
      }

      .vxe-body--column {
        height: 36px !important;
      }
    }

    .vxe-cell--checkbox {
      justify-content: flex-start !important;
    }
  }
}

.parentRow {
  background-color: colors.$pr-50;
}

.warning {
  color: colors.$ad-10_5;
}

.issuesMessage {
  margin-right: auto;
}
</style>
