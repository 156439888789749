<template>
  <div v-loading="isLoading" class="task-participants-select">
    <SimpleTabNav
      :tabs="Tabs"
      :active-tab="activeTab"
      @tab-click="activeTab = $event"
    />
    <div class="task-participants-select__content">
      <ElInput v-if="false" placeholder="Search" size="small" />

      <small v-if="activeTab === Tabs.Groups">
        Assign task to groups instead of to individual users
      </small>

      <div
        v-if="activeTab === Tabs.Users"
        class="task-participants-select__users-list-container"
      >
        <div v-for="group in groupsWithMembers" :key="group.id">
          <div class="task-participants-select__users_group">
            {{ group.name }}
          </div>
          <div v-for="member in membersByGroup[group.id]" :key="member.user_id">
            <ElCheckbox
              class="task-participants-select__item-checkbox"
              :model-value="isItemSelected(selectedUsers, member.user_id)"
              @change="
                (value) =>
                  // @ts-expect-error: ...
                  toggleSelectedItem(selectedUsers, member.user_id, value)
              "
            >
              <UserInformation :user="member" />
            </ElCheckbox>
          </div>
        </div>
      </div>
      <div
        v-if="activeTab === Tabs.Groups"
        class="task-participants-select__groups-select"
      >
        <div v-for="group in allGroupsWithTaskAccess" :key="group.id">
          <ElCheckbox
            class="task-participants-select__item-checkbox"
            :model-value="isItemSelected(selectedGroups, group.id)"
            @change="
              // @ts-expect-error: ...
              (value) => toggleSelectedItem(selectedGroups, group.id, value)
            "
          >
            <GroupInformation :group="group" />
          </ElCheckbox>
        </div>
      </div>
    </div>
    <div class="task-participants-select__footer">
      <ElButton type="primary" size="small" @click="save">Save</ElButton>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { SimpleTabNav } from "@shared/ui";
import { UserInformation } from "@shared/ui/users";

import GroupInformation from "@drVue/components/room/groups/GroupInformation.vue";

import type { Group } from "@drVue/store/modules/room/groups/GroupsApiService";
import type { RoomMember } from "@drVue/store/modules/room/members/RoomMembersApiService";
import type { Dictionary } from "@drVue/types";
import type { PropType } from "vue";

export interface SelectedParticipants {
  users: number[];
  groups: number[];
}

enum Tabs {
  Users = "users",
  Groups = "groups",
}

interface Data {
  selectedGroups: number[];
  selectedUsers: number[];
  Tabs: any;
  activeTab: string;
}

export default defineComponent({
  name: "TaskParticipantsSelect",
  components: { GroupInformation, UserInformation, SimpleTabNav },
  props: {
    value: { required: true, type: Object as PropType<SelectedParticipants> },
    taskCategoryId: {
      required: false,
      default: null,
      type: Number as PropType<number | null>,
    },
  },
  emits: ["change", "update-content"],
  data(): Data {
    return {
      selectedGroups: [],
      selectedUsers: [],
      Tabs: Tabs,
      activeTab: Tabs.Users,
    };
  },
  computed: {
    isLoading(): any {
      return (
        this.$store.state.room.groups.isLoading ||
        this.$store.state.room.members.isLoading
      );
    },
    isLoadError(): any {
      return (
        this.$store.state.room.groups.isError ||
        this.$store.state.room.members.isError
      );
    },
    allGroupsWithTaskAccess(): any {
      let groups = this.$store.state.room.groups.pgroupsList;
      if (this.taskCategoryId) {
        groups = this.$store.getters["room/groups/groupsWithCategoryAccess"](
          this.taskCategoryId,
        );
      }
      return groups.filter((pg: Group) => pg.can_task_manage);
    },
    groupsWithMembers(): Group[] {
      return this.allGroupsWithTaskAccess.filter((pg: Group) => {
        return this.membersByGroup[pg.id]?.length;
      });
    },
    membersByGroup(): any {
      const membersByGroup: Dictionary<RoomMember[]> = {};
      for (const group of this.allGroupsWithTaskAccess) {
        membersByGroup[group.id] = [];
      }
      for (const member of this.$store.state.room.members
        .membersList as RoomMember[]) {
        if (membersByGroup[member.pgroup.id] !== undefined) {
          membersByGroup[member.pgroup.id].push(member);
        }
      }
      return membersByGroup;
    },
  },
  watch: {
    value: {
      immediate: true,
      handler(value: SelectedParticipants) {
        this.selectedGroups = [...value.groups];
        this.selectedUsers = [...value.users];
      },
    },
    activeTab() {
      this.$emit("update-content");
    },
  },
  methods: {
    isItemSelected(values: number[], itemId: number) {
      return values.includes(itemId);
    },
    toggleSelectedItem(values: number[], itemId: number, isSelected: boolean) {
      const index = values.indexOf(itemId);
      if (isSelected === index > -1) {
        return;
      }
      if (isSelected) {
        values.push(itemId);
      } else {
        values.splice(index, 1);
      }
    },
    save() {
      const value: SelectedParticipants = {
        groups: [...this.selectedGroups],
        users: [...this.selectedUsers],
      };
      this.$emit("change", value);
    },
  },
});
</script>
