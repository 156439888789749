<template>
  <component
    :is="hasLink ? 'a' : 'div'"
    v-bind="
      hasLink
        ? {
            href: attachmentUrl,
            target: '_blank',
          }
        : undefined
    "
    :class="$style.container"
  >
    <i
      v-if="itemValue.type === 'file'"
      class="mimetypes"
      :class="[$style.icon, getIconClass(itemValue.mimetype)]"
    />
    <DrIcon
      v-else-if="itemValue.type === 'folder'"
      :class="[$style.icon, $style.folderIcon]"
      name="folder"
    />
    <DrTruncatedTextTooltip :content="itemValue.name">
      <span :class="$style.name">
        <template v-if="hasIndex">{{ itemValue.tree_index }}</template>
        {{ itemValue.name }}
      </span>
    </DrTruncatedTextTooltip>
    <slot />
  </component>
</template>

<script lang="ts" setup generic="Item extends Folder | Document">
import { computed } from "vue";
import DrIcon from "@shared/ui/dr-icon";
import { DrTruncatedTextTooltip } from "@shared/ui/dr-tooltip";

import { ROOM_DATA } from "@setups/data";
import { documentViewUrl, folderUrl } from "@setups/room-urls";
import getIconClass from "@app/common/mimetype";
import {
  DOC_ITEM_TYPES,
  type Document,
  type Folder,
} from "@drVue/store/modules/room/documents/DocumentsApiService";

interface Props {
  item: Item;
  hasLink?: boolean;
  hasIndex?: boolean;
}

const props = defineProps<Props>();

const itemValue = computed(() => props.item);

const attachmentUrl = computed(() =>
  props.item.type === DOC_ITEM_TYPES.Folder
    ? folderUrl(ROOM_DATA.url, props.item.id)
    : documentViewUrl(ROOM_DATA.url, props.item.id),
);
</script>

<style module lang="scss">
@use "@app/styles/scss/colors";
@use "@app/styles/scss/typography";
@use "@app/styles/scss/spacing";

.container {
  text-decoration: none !important;
  height: 32px;
  width: 100%;
  display: grid;
  grid-template-columns: 32px auto min-content;
  align-items: center;
  margin-left: -6px;
}

.name {
  font: typography.$body_regular;
  color: colors.$pr-900;
}

.icon {
  scale: 0.8;
  place-self: center;
}

.folderIcon {
  scale: unset;
  color: colors.$pr-400;
}
</style>
