import getIconClass from "@app/common/mimetype";
import documents_documentInformationHtml from "../../../templates/components/documents/document-information.html?raw";

export default function drDocumentInformation() {
  return {
    template: documents_documentInformationHtml,
    bindings: {
      documentRaw: "<?document",
      documentId: "<",
      isLink: "<?",
      isHoverable: "<?",
      isStatic: "<?", // true by default, one time binding data if static
      showIndex: "<",
      hideThumbnail: "<?",
    },
    controller: [
      "$scope",
      "$sce",
      "DocumentsService",
      function ($scope, $sce, DocumentsService) {
        var $ctrl = this;
        $ctrl.Documents = DocumentsService;
        $ctrl.getIconClass = getIconClass;
        $ctrl.$onInit = function () {
          $ctrl.isStatic = angular.isDefined($ctrl.isStatic)
            ? $ctrl.isStatic
            : true;
          $ctrl.hideThumbnail = angular.isDefined($ctrl.hideThumbnail)
            ? $ctrl.hideThumbnail
            : false;

          var watch = $scope.$watchCollection(
            "[$ctrl.Documents.loading, $ctrl.documentRaw, $ctrl.documentId]",
            function () {
              if (!$ctrl.Documents.isLoaded() && !$ctrl.documentRaw) {
                return;
              }

              if ($ctrl.isStatic) {
                watch(); // unwatch
              }

              var item = $ctrl.documentRaw
                ? $ctrl.documentRaw
                : $ctrl.Documents.Files[$ctrl.documentId];
              if (!item) {
                return;
              }
              $ctrl.document = item;
              if ($ctrl.document.is_removed) {
                $ctrl.isLink = false;
                $ctrl.isHoverable = false;
              }
              if ($ctrl.document.thumbnailSmallUrl) {
                $ctrl.thumbnailTooltipHtml = $sce.trustAsHtml(
                  '<div class="preview-container"><span class="preview-wrapper">' +
                    '<img src="' +
                    $ctrl.document.thumbnailLargelUrl +
                    '">' +
                    "</span></div>",
                );
              } else {
                $ctrl.thumbnailTooltipHtml = undefined;
              }
            },
          );
        };
      },
    ],
  };
}
