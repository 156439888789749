<template>
  <ElForm
    ref="form"
    label-position="top"
    :rules="formRules"
    :model="formModel"
    :disabled="isFormSubmitting"
  >
    <ElFormItem
      label="Send me email notifications"
      :class="$style.noWrapElFormItem"
    >
      <ElSelect v-model="profile.notification_schedule">
        <ElOption
          v-for="(k, v) in scheduleOptions"
          :key="v"
          :label="k"
          :value="v"
        />
      </ElSelect>

      <ElSelect
        v-if="
          profile.notification_schedule === 'immediately' ||
          profile.notification_schedule === 'once a hour'
        "
        v-model="selectedTimeOption"
      >
        <ElOption
          v-for="(item, i) in timeOptions"
          :key="item"
          :label="item"
          :value="i"
        />
      </ElSelect>

      <!-- Once a Day -->
      <span v-if="profile.notification_schedule === 'once a day'">
        <span style="display: inline-block; margin-right: 10px">At</span>
        <ElSelect v-model="selectedHourStart">
          <ElOption
            v-for="item in hours"
            :key="item"
            :label="item"
            :value="item"
          />
        </ElSelect>
      </span>
      <!-- /Once a Day -->

      <!-- Weekly -->
      <span v-if="profile.notification_schedule === 'every week'">
        <span style="display: inline-block; margin-right: 10px">On</span>
        <ElSelect
          v-model="profile.notification_weekday"
          style="margin-right: 20px"
        >
          <ElOption
            v-for="(item, i) in weekdays"
            :key="item"
            :label="item"
            :value="i"
          />
        </ElSelect>

        <span style="display: inline-block; margin-right: 10px">At</span>
        <ElSelect v-model="selectedHourStart">
          <ElOption
            v-for="item in hours"
            :key="item"
            :label="item"
            :value="item"
          />
        </ElSelect>
      </span>
      <!-- /Weekly -->
    </ElFormItem>

    <template v-if="profile.notification_schedule !== 'off'">
      <ElFormItem>
        <ElCheckbox v-model="profile.notification_email_per_room">
          Send separate email for each room
        </ElCheckbox>
      </ElFormItem>

      <ElFormItem
        label="Allow notifications from"
        :class="roomTypeRadioGroupClass"
      >
        <ElRadioGroup
          v-model="profile.notifications_room_selection_type"
          class="ml-4"
        >
          <ElRadio label="all_rooms">All rooms</ElRadio>
          <ElRadio label="all_rooms_except_selected">All rooms except...</ElRadio>
          <ElRadio label="selected_rooms_only">Only selected</ElRadio>
        </ElRadioGroup>
      </ElFormItem>

      <ElFormItem v-if="isRoomsSelectorVisible" prop="selectedRoomsUids">
        <ElSelect
          v-model="formModel.selectedRoomsUids"
          :placeholder="roomsSelectorPlaceholder"
          :loading="isRoomsLoading"
          :disabled="isRoomsLoading"
          loading-text="Loading the rooms, please wait..."
          clearable
          filterable
          multiple
          :class="$style.roomsSelect"
        >
          <ElOption
            v-for="r in rooms"
            :key="r.id"
            :value="r.uid"
            :label="r.title"
          />
        </ElSelect>
      </ElFormItem>

      <!-- Immediately || Once a Hour && Use Range -->

      <ElFormItem
        v-if="
          (profile.notification_schedule === 'immediately' ||
            profile.notification_schedule === 'once a hour') &&
          useRange
        "
        label="Allowed interval"
        :class="$style.noWrapElFormItem"
      >
        <ElSelect v-model="selectedHourStart" @change="updateRange">
          <ElOption
            v-for="item in hoursLessThanEnd"
            :key="item"
            :label="`From ${item}`"
            :value="item"
          />
        </ElSelect>

        <ElSelect v-model="selectedHourEnd">
          <ElOption
            v-for="item in hoursGreaterThanStart"
            :key="item"
            :label="`to ${item}`"
            :value="item"
          />
        </ElSelect>
      </ElFormItem>
      <!-- /Immediately || Once a Hour && Use Range -->

      <ElFormItem label="Notify me about">
        <ElCheckbox v-model="profile.notification_new_task">
          New requests
        </ElCheckbox>

        <ElCheckbox v-model="profile.notification_document_upload">
          Documents uploading
        </ElCheckbox>

        <ElCheckbox v-model="profile.notification_due_date">
          Requests due dates
        </ElCheckbox>
      </ElFormItem>
    </template>

    <ElFormItem label="Timezone">
      <div class="detect-layout">
        <div class="detect-layout__col-left">
          <ElButton @click="detectTimezone">Detect</ElButton>
        </div>
        <div class="detect-layout__col-right">
          <ElSelect v-model="profile.timezone" filterable style="width: 100%">
            <ElOption v-for="tz of tzdb" :key="tz" :label="tz" :value="tz" />
          </ElSelect>
        </div>
      </div>
    </ElFormItem>

    <ElFormItem>
      <div class="text-right">
        <ElButton type="primary" :loading="isFormSubmitting" @click="save">
          Save changes
        </ElButton>
      </div>
    </ElFormItem>
  </ElForm>
</template>

<script lang="ts">
import { format, parse } from "date-fns";
import { defineComponent } from "vue";

import { USER_DATA } from "@setups/data";
import {
  NotificationsScheduleOption,
  ProfileApiService,
} from "@drVue/api-service/client-dashboard/profile";
import { RoomApiService } from "@drVue/api-service/common/rooms";
import { $notifyDanger, $notifySuccess, getTimezone } from "@drVue/common";
import { ProfileService } from "@drVue/components/client-dashboard/users/AccountSettings/ProfileService";
import { drUserTime } from "@drVue/filters/drUserTime";
import { tzdb } from "@drVue/tzdb";

import type { RoomListItem } from "@drVue/api-service/common/rooms";
import type { UserProfile } from "@setups/data";
import type { FormRules } from "element-plus";
import type { ElForm } from "element-plus/es";

/* Since we have to display time in "XX am" format -
   we convert 24-h-format to 12-h-format and use this value in frontend */
function full2half(hour: number): string {
  const now = new Date();
  return drUserTime(parse(`${hour}`, "H", now), "h a")!;
}

/* convert 12h frontend time to 24h  */
function half2full(str: string) {
  const now = new Date();
  return parseInt(format(parse(str, "h a", now), "H"));
}

const RANGED_SCHEDULES = ["immediately", "once a hour"];

enum RoomSelectionType {
  AllRooms = "all_rooms",
  AllRoomsExceptSelected = "all_rooms_except_selected",
  SelectedRoomsOnly = "selected_rooms_only",
}

interface Data {
  formRules: FormRules;
  formModel: {
    selectedRoomsUids: string[];
  };
  isFormSubmitting: boolean;
  profileApiService: ProfileApiService;
  roomsApiService: RoomApiService;
  profileService: ProfileService;
  rooms: RoomListItem[];
  isRoomsLoading: boolean;
  profile: UserProfile;
  weekdays: string[];
  hours: string[];
  timeOptions: string[];
  selectedTimeOption: number;
  selectedHourStart: string;
  selectedHourEnd: string;
}

export default defineComponent({
  name: "PersonalInfo",
  data(): Data {
    const profileApiService = new ProfileApiService();

    const p = USER_DATA.profile;
    return {
      formRules: {
        selectedRoomsUids: [
          {
            required: true,
            message: "Select at least one room",
          },
        ],
      },
      formModel: {
        selectedRoomsUids: [],
      },
      isFormSubmitting: false,
      profileApiService,
      roomsApiService: new RoomApiService(),
      profileService: new ProfileService(profileApiService),
      rooms: [],
      isRoomsLoading: false,
      profile: { ...USER_DATA.profile },
      weekdays: [
        "Monday",
        "Tuesday",
        "Wednesday",
        "Thursday",
        "Friday",
        "Saturday",
        "Sunday",
      ],
      hours: [
        "1 AM",
        "2 AM",
        "3 AM",
        "4 AM",
        "5 AM",
        "6 AM",
        "7 AM",
        "8 AM",
        "9 AM",
        "10 AM",
        "11 AM",
        "12 PM",
        "1 PM",
        "2 PM",
        "3 PM",
        "4 PM",
        "5 PM",
        "6 PM",
        "7 PM",
        "8 PM",
        "9 PM",
        "10 PM",
        "11 PM",
        "12 AM",
      ],
      timeOptions: ["Anytime", "Specific time range"],
      selectedTimeOption: 0,
      selectedHourStart: "",
      selectedHourEnd: "",
    };
  },
  computed: {
    scheduleOptions(): any {
      return NotificationsScheduleOption;
    },
    isRangedScheduleSelected(): any {
      return (
        RANGED_SCHEDULES.includes(this.profile.notification_schedule) &&
        this.selectedTimeOption === 1
      );
    },
    tzdb(): any {
      return tzdb;
    },
    useRange(): any {
      return this.selectedTimeOption === 1;
    },
    hoursLessThanEnd(): any {
      return this.hours.filter((h) => {
        if (h === "12 AM" && this.selectedHourEnd === "12 AM") return false;
        return true;
      });
    },
    hoursGreaterThanStart(): any {
      return this.hours.filter((h) => {
        if (h === "12 AM") return this.selectedHourStart !== "12 AM";

        const now = new Date();
        return parse(h, "h a", now) > parse(this.selectedHourStart, "h a", now);
      });
    },
    isRoomsSelectorVisible(): boolean {
      return (
        this.profile.notifications_room_selection_type ===
          RoomSelectionType.AllRoomsExceptSelected ||
        this.profile.notifications_room_selection_type ===
          RoomSelectionType.SelectedRoomsOnly
      );
    },
    roomsSelectorPlaceholder(): string {
      if (this.isRoomsLoading) return "Loading the rooms, please wait...";

      // ElSelect has a bug: when you change a placeholder with preselected
      // values it resets the placeholder and shows it under the selected
      // values. As a workaround we show an empty string for the case when
      // there are some values in the array.
      if (this.formModel.selectedRoomsUids.length > 0) return "";

      switch (this.profile.notifications_room_selection_type) {
        case RoomSelectionType.AllRoomsExceptSelected:
          return "Select rooms to mute notifications from";
        case RoomSelectionType.SelectedRoomsOnly:
          return "Select rooms to receive notifications from";
        default:
          return "";
      }
    },
    includedRooms(): string[] {
      if (
        this.profile.notifications_room_selection_type ===
        RoomSelectionType.SelectedRoomsOnly
      ) {
        return this.formModel.selectedRoomsUids;
      }

      return [];
    },
    excludedRooms(): string[] {
      if (
        this.profile.notifications_room_selection_type ===
        RoomSelectionType.AllRoomsExceptSelected
      ) {
        return this.formModel.selectedRoomsUids;
      }

      return [];
    },
    roomTypeRadioGroupClass(): string {
      // To be able to use thany.$style
      const thany = this as any;

      return this.profile.notifications_room_selection_type !==
        RoomSelectionType.AllRooms
        ? thany.$style.noMarginBottom
        : "";
    },
  },
  watch: {
    selectedTimeOption() {
      this.updateRange();
    },
    selectedHourStart() {
      this.updateRange();
    },
    "profile.notifications_room_selection_type": {
      handler(type: RoomSelectionType) {
        // The list will be loaded only once.
        if (
          !this.isRoomsLoading &&
          this.rooms.length === 0 &&
          type !== RoomSelectionType.AllRooms
        ) {
          this.isRoomsLoading = true;
          this.roomsApiService
            .list()
            .then((recentRooms) => {
              const p = this.profile;
              const fm = this.formModel;

              switch (type) {
                case RoomSelectionType.SelectedRoomsOnly:
                  fm.selectedRoomsUids = p.notifications_included_rooms;
                  break;
                case RoomSelectionType.AllRoomsExceptSelected:
                  fm.selectedRoomsUids = p.notifications_excluded_rooms;
                  break;
                default:
                  fm.selectedRoomsUids = [];
              }

              this.rooms = recentRooms;
            })
            .finally(() => (this.isRoomsLoading = false));
        }
      },
      immediate: true,
    },
  },
  beforeMount() {
    const p = this.profile;

    this.selectedHourStart = full2half(p.notification_hour_start);
    this.selectedHourEnd = full2half(p.notification_hour_end);

    this.selectedTimeOption =
      p.notification_hour_start === p.notification_hour_end ? 0 : 1;
  },
  methods: {
    updateRange() {
      if (this.isRangedScheduleSelected) {
        const start = half2full(this.selectedHourStart);
        const end = half2full(this.selectedHourEnd);

        if (start >= end) {
          if (start === 23) {
            this.selectedHourEnd = full2half(0);
          } else {
            // from = start when start > end
            // from = end when start === end
            const from = start > end ? start : end;
            this.selectedHourEnd = full2half(from + 1);
          }
        }
      }
    },
    detectTimezone() {
      this.profile.timezone = getTimezone();
    },
    save() {
      if (this.isFormSubmitting || this.isRoomsLoading) return;

      const form = this.$refs.form as InstanceType<typeof ElForm>;
      form.validate().then((isValid: boolean) => {
        if (!isValid) return;

        const p = { ...this.profile };

        // "11 AM" => 11, "11 PM" => 23
        p.notification_hour_start = half2full(this.selectedHourStart);
        p.notification_hour_end = half2full(this.selectedHourEnd);

        // Set (start = end) when not ranged schedule is selected.
        if (!this.isRangedScheduleSelected) {
          p.notification_hour_end = p.notification_hour_start;
        }

        p.notifications_included_rooms = this.includedRooms;
        p.notifications_excluded_rooms = this.excludedRooms;

        this.isFormSubmitting = true;
        this.profileService
          .update(p)
          .then(
            (r) => {
              this.profile = { ...r.data.profile };
              $notifySuccess(
                "Notifications settings have been successfully saved",
              );
            },
            () =>
              $notifyDanger("Failed to save the settings, please try again"),
          )
          .finally(() => (this.isFormSubmitting = false));
      });
    },
  },
});
</script>

<style lang="scss" module>
.roomsSelect {
  min-width: 423px;
}

.noMarginBottom {
  margin-bottom: 0 !important;
}

.noWrapElFormItem :global {
  .el-form-item__content {
    flex-wrap: nowrap;
    gap: 20px;

    & > * {
      flex-grow: 1;
      white-space: nowrap;
    }
  }

  .el-select {
    width: auto;
    min-width: 30%;
  }
}
</style>
