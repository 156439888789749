<template>
  <slot name="default" :open-dialog="openDialog">
    <ElButton type="primary" plain @click="openDialog()">{{ $t('shared.download') }}</ElButton>
  </slot>

  <ElDialog
    v-model="dialogVisible"
    :title="$t('file_viewer.topbar.download.watermarked')"
    @close="handleClose"
  >
    <a v-if="documentDownloadUrl" :href="documentDownloadUrl">{{ $t('shared.download') }}</a>
    <span v-else-if="isDownloadError">{{ $t("file_viewer.topbar.download.error") }}</span>
    <span v-else>{{ $t("file_viewer.topbar.download.processing") }}</span>
  </ElDialog>
</template>

<script setup lang="ts">
import { ref } from "vue";

import {
  DocumentsApiService,
  DOWNLOAD_QUEUE_STATUS,
} from "@drVue/store/modules/room/documents/DocumentsApiService";

const props = defineProps<{
  documentId: number;
  downloadOriginal: boolean;
}>();

const DOWNLOAD_STATUS_CHECK_DELAY = 1000; // ms
const documentsApi = new DocumentsApiService();
const documentDownloadUrl = ref<string | null>(null);
const isActive = ref(false);
const isDownloadError = ref(false);
const checkTimer = ref<number | null>(null);
const downloadTaskId = ref<null | string>(null);
const dialogVisible = ref(false);

function openDialog() {
  if (props.downloadOriginal) {
    const url = documentsApi.downloadWatermarkedDocUrl(props.documentId);

    const anchor = document.createElement("a");
    anchor.href = url;
    anchor.download = "";
    anchor.click();
  } else {
    requestDownloadWatermarked();
    dialogVisible.value = true;
  }
}

function reset(hasError: boolean) {
  isActive.value = false;
  isDownloadError.value = hasError;
  if (checkTimer.value) {
    clearInterval(checkTimer.value);
    checkTimer.value = null;
  }
  checkTimer.value = null;
  downloadTaskId.value = null;
}

async function checkDownloadTaskStatus() {
  try {
    const statusData = await documentsApi.getDownloadWatermarkedTaskStatus(
      props.documentId,
      downloadTaskId.value!,
    );

    if (statusData.status === DOWNLOAD_QUEUE_STATUS.Fail) {
      reset(true);
    } else if (statusData.status === DOWNLOAD_QUEUE_STATUS.Success) {
      documentDownloadUrl.value = documentsApi.downloadWatermarkedDocUrl(
        props.documentId,
        downloadTaskId.value ?? undefined,
      );
      reset(false);
    } else {
      checkTimer.value = window.setTimeout(() => {
        checkDownloadTaskStatus();
      }, DOWNLOAD_STATUS_CHECK_DELAY);
    }
  } catch (e) {
    reset(true);
    throw e;
  }
}

async function requestDownloadWatermarked() {
  if (isActive.value) {
    return;
  }
  reset(false);
  isActive.value = true;
  try {
    const downloadInfo = await documentsApi.createDownloadWatermarkedTask(
      props.documentId,
    );
    downloadTaskId.value = downloadInfo.task_id;
  } catch (e) {
    reset(true);
    throw e;
  }

  checkTimer.value = window.setTimeout(() => {
    checkDownloadTaskStatus();
  }, DOWNLOAD_STATUS_CHECK_DELAY);
}

const handleClose = () => {
  reset(false);
};
</script>
