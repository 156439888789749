<template>
  <div class="contacts">
    <div class="contacts__header">
      <h4 class="contacts__header-title">Contacts ({{ contacts.length }})</h4>

      <ElButton
        v-if="editable"
        class="contacts__header-add"
        type="text"
        size="default"
        @click="openAddContactPanel"
      >
        Add contact
        <i class="fas fa-user-plus" />
      </ElButton>
    </div>
    <div class="contacts__list">
      <div v-if="contacts.length === 0" class="contacts-empty">
        <div class="contacts-empty__col-avatar">
          <i class="far fa-user" />
        </div>
        <div>
          <div class="contacts-empty__text">
            Track the contacts associated with this deal
          </div>
          <ElButton v-if="editable" type="primary" @click="openAddContactPanel">
            Add contact to deal
          </ElButton>
        </div>
      </div>
      <div v-else>
        <div
          v-for="(c, index) of contacts"
          :key="c.id"
          class="contact contacts__single-contact"
          @click="openDetailsModal(c)"
        >
          <div class="contact__col-avatar">
            <UserAvatar :user="c" :size="'28px'" />
          </div>
          <div class="contact__col-info">
            <ContactInfo :contact="c" />

            <div
              v-if="editable"
              class="contacts-actions contact__actions-button"
              :class="{
                'contact__actions-button--hovered': openedMenuIndex === index,
              }"
              @click.stop.prevent
            >
              <ElDropdown
                class="contacts-actions__component"
                trigger="click"
                @command="(command) => handleMenuActions(command, c)"
                @visible-change="
                  (isOpened) => setOpenedMenuIndex(isOpened, index)
                "
              >
                <span><i class="fas fa-ellipsis-h" /></span>
                <template #dropdown>
                  <ElDropdownMenu>
                    <ElDropdownItem command="edit">
                      <div class="contacts-actions__action">
                        <i class="fas fa-edit" /> Edit
                      </div>
                    </ElDropdownItem>
                    <ElDropdownItem command="delete">
                      <div class="contacts-actions__action">
                        <i class="fas fa-trash" /> Delete
                      </div>
                    </ElDropdownItem>
                  </ElDropdownMenu>
                </template>
              </ElDropdown>
            </div>
          </div>
        </div>
      </div>
    </div>
    <AddContactPanel ref="addContactPanel" />
    <UserModal ref="userModal" />

    <ElDrawer
      v-model="isEditPanelOpened"
      :append-to-body="true"
      :with-header="false"
      class="el-drawer--narrow"
      size=""
      @close="isEditPanelOpened = false"
    >
      <ContentPanelClose @close="isEditPanelOpened = false" />

      <!-- @vue-expect-error: `IOrgUser` to `IInfoItem` -->
      <EditUserDetailsForm
        :user="editUser"
        :is-opened="isEditPanelOpened"
        @close="isEditPanelOpened = false"
      />
    </ElDrawer>
  </div>
</template>

<script lang="ts">
import { keyBy } from "lodash-es";
import { sortBy } from "lodash-es";
import { defineComponent } from "vue";
import ContentPanelClose from "@shared/ui/content-panel/close.vue";
import { UserAvatar } from "@shared/ui/users";

import AddContactPanel from "@drVue/components/client-dashboard/deals/Contacts/AddContactPanel.vue";
import ContactInfo from "@drVue/components/client-dashboard/deals/Contacts/ContactInfo.vue";
import EditUserDetailsForm from "@drVue/components/client-dashboard/users/UserDetails/EditUserDetailsForm.vue";
import UserModal from "@drVue/components/client-dashboard/users/UserDetails/index.vue";

import type { UpdateContactsPayload } from "@drVue/store/modules/client-dashboard/deals/DealsActions";
import type { OrgUser } from "@drVue/store/modules/client-dashboard/org-users/types";

interface Data {
  openedMenuIndex: number | null;
  isEditPanelOpened: boolean;
  editUserId: number | null;
}

export default defineComponent({
  name: "Contacts",
  components: {
    AddContactPanel,
    ContactInfo,
    ContentPanelClose,
    EditUserDetailsForm,
    UserAvatar,
    UserModal,
  },
  provide() {
    return {
      dealId: this.dealId,
    };
  },
  props: {
    dealId: {
      type: Number,
      required: true,
    },
    editable: {
      type: Boolean,
      default: false,
    },
  },
  data(): Data {
    return {
      openedMenuIndex: null,
      isEditPanelOpened: false,
      editUserId: null,
    };
  },

  computed: {
    editUser(): OrgUser {
      return this.contacts.find((item) => item.user_id === this.editUserId);
    },
    dealContactsIds(): string[] {
      return this.$store.getters["clientDashboard/deals/getContactsOfDeal"](
        this.dealId,
      );
    },
    contacts() {
      const usersDictionary = keyBy(
        this.$store.state.clientDashboard.orgUsers.items,
        "id",
      );

      const thany = this as any;
      const dealContactsArray = thany.dealContactsIds
        .map((id: string) => usersDictionary[id])
        .filter((u: OrgUser) => !!u);

      return sortBy(dealContactsArray, ["pending", "email"]);
    },
  },
  methods: {
    setOpenedMenuIndex(isOpened: boolean, index: number) {
      if (isOpened) {
        this.openedMenuIndex = index;
      } else {
        this.openedMenuIndex = null;
      }
    },

    handleMenuActions(command: string, contact: OrgUser) {
      if (command === "delete") {
        this.remove(contact);
      } else if (command === "edit") {
        this.editUserId = contact.user_id;
        this.isEditPanelOpened = true;
      }
    },
    getUserModal(): InstanceType<typeof UserModal> {
      return this.$refs.userModal as InstanceType<typeof UserModal>;
    },

    openAddContactPanel() {
      const addContactPanel = this.$refs.addContactPanel as any;
      addContactPanel.open();
    },

    openDetailsModal(contact: OrgUser) {
      this.getUserModal().show(contact);
    },

    remove(contact: OrgUser) {
      this.$confirm(
        `Are you sure you want to remove the contact?`,
        `Remove contact`,
        {
          confirmButtonClass: "el-button--warning",
          confirmButtonText: "Remove",
          cancelButtonText: "Cancel",
          type: "warning",
        },
      ).then(() => {
        this.$store.dispatch("clientDashboard/deals/updateContacts", {
          dealId: this.dealId,
          usersIdsToAdd: [],
          usersIdsToRemove: [contact.id],
        } as UpdateContactsPayload);
      });
    },
  },
});
</script>
