<template>
  <DrPanelWrapper :height="height" :width="width">
    <template #subtitle>
      <ElInput
        ref="searchFieldRef"
        v-model="searchFieldText"
        clearable
        :placeholder="t('shared.search_dots')"
        :disabled="loading"
      >
        <template #prefix>
          <DrIcon size="sm" name="search" />
        </template>
      </ElInput>
    </template>

    <DrTree
      show-checkbox
      check-on-click-node
      check-sign-on-right
      :check-strictly="checkStrictly"
      :expand-on-click-node="expandOnClickNode"
      :only-leaf-value="onlyTaskValue"
      node-key="uid"
      :data="treeData"
      :filter-text="searchFieldText"
      :filter-node-method="filterNodeMethod"
      :is-pending="isPending"
      :model-value="localValue"
      :default-expanded-keys="rootKeys"
      @update:model-value="updateLocalValue"
      @reset-filter="searchFieldText = ''"
    >
      <template #item-name="{ item, node }">
        <DrListRelatedItems
          :items="[item as TaskTreeItem]"
          :is-style-inherited="node.checked"
        >
          <template #prefix="{ item: listItem }">
            <span
              v-if="(listItem as TaskTreeItem).code"
              :style="
                node.checked
                  ? undefined
                  : { color: (listItem as TaskTreeItem).color }
              "
            >
              #{{ (listItem as TaskTreeItem).code }}
            </span>
          </template>
        </DrListRelatedItems>
      </template>
    </DrTree>

    <template v-if="!singleValue && !!localValue.length" #footer-left>
      <ElButton
        size="small"
        :class="$style.textBtn"
        :disabled="loading"
        @click="clearLocalValue()"
      >
        {{ t("shared.clear_selection_count", { count: localValue.length }) }}
        <template #icon>
          <DrIcon name="redo" size="sm" />
        </template>
      </ElButton>
    </template>

    <template #footer-right>
      <ElButton
        size="small"
        :disabled="loading"
        @click="
          resetLocalValue();
          $emit('reset');
        "
      >
        {{ t("shared.cancel") }}
      </ElButton>
      <ElButton
        size="small"
        type="primary"
        :disabled="!isValueChanged"
        :loading="loading"
        @click="submitModelValue()"
      >
        {{ submitText }}
      </ElButton>
    </template>
  </DrPanelWrapper>
</template>

<script lang="ts" setup>
import fuzzaldrinPlus from "fuzzaldrin-plus";
import { difference, xor } from "lodash-es";
import { computed, onMounted, ref, watch } from "vue";
import { useI18n } from "vue-i18n";
import { POPUP_SIZES } from "@shared/ui/constants";
import { DrIcon } from "@shared/ui/dr-icon";
import { DrListRelatedItems } from "@shared/ui/dr-list";
import { DrPanelWrapper } from "@shared/ui/dr-panels";
import { DrTree } from "@shared/ui/dr-tree";

import { DrStore } from "@app/vue";
import { delayedCall } from "@app/vue/common";
import { pinia } from "@drVue/store/pinia";
import { useCategoriesStore } from "@drVue/store/pinia/room/categories";
import { useTasksStore } from "@drVue/store/pinia/room/tasks";

import type { TaskStatus } from "@drVue/store/modules/room/tasks-statuses/types";
import type { Category } from "@drVue/store/pinia/room/categories/api";
import type { Task } from "@drVue/store/pinia/room/tasks";
import type { NavTreeItem } from "@shared/ui/dr-nav";
import type { TreeItem } from "@shared/ui/dr-tree";
import type { FilterNodeMethodFunction } from "element-plus/es/components/tree/src/tree.type";

type Ext = {
  uid: string;
  code: string;
  color?: string;
};

type TaskTreeItem = TreeItem<Ext>;
type TreeValue = (Task["uid"] | Category["uid"])[];

interface Props {
  expandOnClickNode?: boolean;
  onlyTaskValue?: boolean;
  checkStrictly?: boolean;
  filterMethod?: (task: Task) => boolean;
  height?: number;
  width?: number;
  singleValue?: boolean;
  submitText?: string;
  loading?: boolean;
  autofocus?: boolean | number;
}

interface Emits {
  (e: "reset"): void;
}

const props = withDefaults(defineProps<Props>(), {
  expandOnClickNode: false,
  onlyTaskValue: false,
  checkStrictly: false,
  filterMethod: undefined,
  height: POPUP_SIZES.wideTree.height,
  width: POPUP_SIZES.wideTree.width,
  nodeKey: "uid",
  submitText: "Save",
  autofocus: false,
});

defineEmits<Emits>();

const { t } = useI18n();

const model = defineModel<TreeValue>({ default: [] });

const categoriesStore = useCategoriesStore(pinia);
const tasksStore = useTasksStore(pinia);
const getStatusById = DrStore.getters["room/tasksStatuses/byId"];

const isPending = computed(
  () => categoriesStore.isLoading || tasksStore.isLoading,
);

const reformatAndFillCategory = (
  acc: TaskTreeItem[],
  cat: NavTreeItem<Pick<Category, "order" | "uid">>,
): any => {
  // there are no tasks in this category or its subcategories
  if (cat.progress?.total === 0) return acc;

  const children: TaskTreeItem[] = [];
  if (cat.children?.length) {
    children.push(...cat.children.reduce(reformatAndFillCategory, []));
  }

  const categoryTasks = tasksStore.tasksByCategoryId[cat.id];
  if (categoryTasks?.length) {
    const tasks = categoryTasks.reduce<TaskTreeItem[]>((acc, task) => {
      if (props.filterMethod && !props.filterMethod(task)) return acc;

      const taskStatus = getStatusById(task.status_id) as TaskStatus;
      acc.push({
        id: task.id,
        uid: task.uid,
        name: task.title,
        code: task.key,
        color: taskStatus?.color,
      });

      return acc;
    }, []);

    children.push(...tasks);
  }

  acc.push({
    ...cat,
    code: "",
    children,
    disabled: (props.singleValue && props.onlyTaskValue) || undefined,
  });

  return acc;
};

const treeData = computed<TaskTreeItem[]>(() =>
  categoriesStore.categoriesNavTree.reduce(reformatAndFillCategory, []),
);

const rootKeys = ref<Category["uid"][]>([]);
watch(
  () => !!treeData.value.length,
  (hasTreeData) => {
    if (hasTreeData && !rootKeys.value.length) {
      rootKeys.value = treeData.value.map((item) => item.uid);
    }
  },
  {
    immediate: true,
  },
);

const searchFieldRef = ref<HTMLInputElement | null>(null);

const searchFieldText = ref<string>("");
const filterNodeMethod: FilterNodeMethodFunction = (value, data, child) => {
  const query = value as string;
  const item = data as any;

  if (!query) return true;

  if (query.startsWith("#")) return item.code === query.slice(1);

  const score = fuzzaldrinPlus.score(`${item.code} ${item.name}`, query);
  return score > 0;
};

const localValue = ref<TreeValue>([]);
watch(
  model,
  (value) => {
    localValue.value = [...value];
  },
  { immediate: true },
);

const isValueChanged = computed(
  () => !!xor(localValue.value, model.value).length,
);

const updateLocalValue = (value: (string | number)[]) => {
  if (props.singleValue) {
    if (value.length === 1) localValue.value = value as string[];
    else {
      localValue.value = [difference(value as string[], localValue.value)[0]];
    }
  } else {
    localValue.value = value as string[];
  }
};

const clearLocalValue = () => {
  localValue.value = [];
};

const focusSearchField = () => {
  searchFieldRef.value?.focus();
};

const resetLocalValue = () => {
  searchFieldText.value = "";

  if (isValueChanged.value) {
    localValue.value = [...model.value];
  }
};

const submitModelValue = () => {
  if (isValueChanged.value) {
    model.value = [...localValue.value];
  }
};

if (!categoriesStore.categoriesList.length && !categoriesStore.isError) {
  categoriesStore.load();
}

if (!tasksStore.tasksList.length && !tasksStore.isError) {
  tasksStore.load();
}

onMounted(() => {
  if (props.autofocus) {
    delayedCall(focusSearchField, props.autofocus);
  }
});

defineExpose({
  focusSearchField,
  resetLocalValue,
});
</script>

<style lang="scss" module>
@use "@app/styles/scss/typography" as typo;

.textBtn:global(.el-button) {
  --el-button-bg-color: transparent;
  --el-button-border-color: transparent;
  --el-button-disabled-bg-color: transparent;
  --el-button-disabled-border-color: transparent;
  font: typo.$body_regular;
}
</style>
