<template>
  <DrPopup paddingless>
    <template #reference="{ isOpened }">
      <ElButton
        link
        :class="{ [$style.linkBtn]: true, [$style.linkBtn_isActive]: isOpened }"
      >
        {{ $t("permissions.invite_members.invite_from_other_room") }}
      </ElButton>
    </template>

    <template #default="{ hide }">
      <div :class="$style.content">
        <DrDynamicFormClassic
          :entity="form"
          :schema="schema"
          @update="handleUpdate"
          @submit="add(hide)"
        />

        <div :class="$style.footer">
          <ElButton size="small" @click="hide()">{{
            t("shared.cancel")
          }}</ElButton>
          <ElButton
            size="small"
            type="primary"
            :disabled="!form.groupId"
            :loading="isProcessing"
            @click="add(hide)"
          >
            {{ t("shared.add") }}
          </ElButton>
        </div>
      </div>
    </template>
  </DrPopup>
</template>

<script setup lang="ts">
import { computed, onBeforeMount, reactive, ref } from "vue";
import { useI18n } from "vue-i18n";
import { DrDynamicFormClassic } from "@shared/ui/dr-dynamic-form";
import { DrPopup } from "@shared/ui/dr-popups";

import { Urls } from "@setups/urls";
import { ApiService } from "@drVue/api-service";
import { $notifyDanger } from "@drVue/common";
import { FieldSchemaType } from "@drVue/components/client-dashboard/dynamic-form/types";

import type {
  Room,
  RoomGroup,
} from "@drVue/store/modules/client-dashboard/deals/types";
import type { OrgUser } from "@drVue/store/modules/client-dashboard/org-users/types";
import type { FormSchema } from "@shared/ui/dr-dynamic-form/types";

interface Props {
  roomId: Room["id"];
}

interface Emits {
  (event: "add", emails: string[]): void;
}

const { roomId } = defineProps<Props>();
const emit = defineEmits<Emits>();

const { t } = useI18n();
const api = new ApiService();

const rooms = ref<Room[]>([]);
const isProcessing = ref(false);
const form = reactive<{
  roomId: Room["id"] | undefined;
  groupId: RoomGroup["id"] | undefined;
}>({
  roomId: undefined,
  groupId: undefined,
});

const schema = computed<FormSchema>(() => [
  {
    type: FieldSchemaType.Select,
    isReadOnly: isProcessing.value,
    prop: "roomId",
    label: t("permissions.invite_members.from_group_popup.room.label"),
    placeholder: t(
      "permissions.invite_members.from_group_popup.room.placeholder",
    ),
    extra: {
      autofocus: 200,
      select_options: rooms.value.map(({ id, title }) => ({
        label: title,
        value: id,
      })),
      filterable: true,
      noTeleported: true,
    },
  },
  {
    type: FieldSchemaType.Select,
    isReadOnly: isProcessing.value,
    prop: "groupId",
    label: t("permissions.invite_members.from_group_popup.group.label"),
    placeholder: form.roomId
      ? t("permissions.invite_members.from_group_popup.group.placeholder")
      : t(
          "permissions.invite_members.from_group_popup.group.placeholder_room_first",
        ),
    extra: {
      select_options: groups.value.map(({ id, name }) => ({
        label: name,
        value: id,
      })),
      filterable: true,
      noTeleported: true,
    },
  },
]);

const handleUpdate = ({
  field,
  value,
}: {
  field: string;
  value: Room["id"] | RoomGroup["id"];
}) => {
  form[field as keyof typeof form] = value;
  if (field === "roomId") {
    form.groupId = undefined;
  }
};

const groups = computed(() => {
  const room = rooms.value.find((r) => r.id === form.roomId);
  return room ? room.groups : [];
});

onBeforeMount(() => {
  const url = Urls["api:client-dashboard:rooms-groups-info"]();
  api.get<Room[]>(url).then((roomsData) => {
    rooms.value = roomsData.filter((r) => r.id !== roomId);
  });
});

const add = (hide: () => void) => {
  if (!form.groupId) return;

  const url = Urls["api:client-dashboard:rooms-group-members-info"](
    form.groupId,
  );
  isProcessing.value = true;

  api
    .get<Partial<OrgUser>[]>(url)
    .then(
      (members) => {
        emit(
          "add",
          members.map((m) => m.email || "").filter((m) => m),
        );
        hide();
      },
      () => {
        $notifyDanger(
          t("permissions.invite_members.get_group_members_unexpected_error"),
        );
      },
    )
    .finally(() => {
      isProcessing.value = false;
    });
};
</script>

<style lang="scss" module>
@use "@app/styles/scss/colors";
@use "@app/styles/scss/spacing";
@use "@app/styles/scss/typography" as typo;
@use "@app/styles/scss/values";

.linkBtn:global(.el-button.is-link) {
  --el-button-text-color: #{colors.$sc-600};
  --el-button-hover-link-text-color: #{colors.$sc-600};
  --el-button-outline-color: transparent;
  --el-button-border-color: transparent;

  font-weight: 400;

  > span {
    display: inline-block;
    position: relative;
    overflow: hidden;

    &:after {
      content: "";
      position: absolute;
      bottom: 3px;
      left: 0;
      width: 100%;
      height: 1px;
      background-color: currentColor;
      opacity: 0;
      transition:
        opacity 200ms,
        transform 200ms;
      transform: translate3d(-100%, 0, 0);
    }
  }
}

.linkBtn:global(.el-button.is-link):hover,
.linkBtn_isActive:global(.el-button.is-link) {
  > span:after {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

.content {
  padding: spacing.$s;
}

.footer {
  display: flex;
  justify-content: flex-end;
  margin-top: 14px;

  :global(.el-button) {
    font-weight: 400;
  }
  :global(.el-button + .el-button) {
    margin-left: 8px;
  }
}
</style>
