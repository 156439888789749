<template>
  <DrPopup
    ref="popupRef"
    paddingless
    @show="isTreeVisible = true"
    @hide="isTreeVisible = false"
  >
    <template #reference>
      <div
        :class="{
          [$style.container]: true,
          [$style.container_isTreeVisible]: isTreeVisible,
        }"
      >
        <DrTooltip :content="tooltipText" placement="bottom">
          <div>
            <span v-if="!!parentsLabel" :class="$style.secondaryFont">
              {{ parentsLabel }}
            </span>
            <span :class="$style.primaryFont">{{ currentCat?.name }}</span>
          </div>
        </DrTooltip>
      </div>
    </template>
    <template #default>
      <TreeBrowser
        v-model:selected-node-ids="selectedCategory"
        :is-loading="isTreeBrowserLoading"
        :tree="catTree"
        :autofocus="true"
        disallow-reset
        disallow-empty
        @save="handleUpdateCategoryId"
        @create-node="handleCreateCategory"
        @cancel="popupRef?.hide()"
      />
    </template>
  </DrPopup>
</template>

<script setup lang="ts">
import { computed, ref, unref, watch } from "vue";
import DrPopup from "@shared/ui/dr-popups/DrPopup.vue";

import DrTooltip from "@app/vue/shared/ui/dr-tooltip/DrTooltip.vue";
import TreeBrowser from "@drVue/components/room/tasks/shared/tree-browser/TreeBrowser.vue";
import { useCategoriesStore } from "@drVue/store/pinia/room/categories";

import type { NodeId } from "@drVue/components/room/tasks/shared/tree-browser/types";
import type { Category } from "@drVue/store/pinia/room/categories";
import type { NavTreeItem } from "@shared/ui/dr-nav";
import type { TreeItem } from "@shared/ui/dr-tree";

interface Props {
  categoryId: number;
}

const props = defineProps<Props>();

interface Emits {
  (e: "change-category", payload: { category_id: number }): void;
}

const emit = defineEmits<Emits>();

const catStore = useCategoriesStore();

const currentCat = computed(() => catStore.categories[props.categoryId]);
const parentsLabel = computed(() => {
  const cat = unref(currentCat);

  if (!cat || cat.parents.length === 0) return "";
  if (cat.parents.length === 1) return `${cat.parents[0].name} / `;

  return `${cat.parents[0].name} / ... / `;
});

const tooltipText = computed<string | undefined>(() => {
  const cat = unref(currentCat);

  if (!cat || cat.parents.length < 2 || !currentCat.value) return undefined;

  return `${cat.parents.map((item) => item.name).join(" / ")} / ${currentCat.value.name}`;
});

const toNode = (
  cat: NavTreeItem<Pick<Category, "order" | "uid">>,
): TreeItem<Pick<Category, "order" | "uid"> & { canParent: true }> => {
  if (cat && cat.children) {
    const children = cat.children.map(toNode);
    return {
      ...cat,
      children,
      canParent: true,
    };
  }

  return {
    ...cat,
    canParent: true,
    children: (cat.children ?? []) as TreeItem<
      Pick<Category, "order" | "uid"> & { canParent: true }
    >[],
  };
};

const catTree = computed(() => catStore.categoriesNavTree.map(toNode));

const popupRef = ref<InstanceType<typeof DrPopup> | null>(null);

const isTreeVisible = ref(false);

const selectedCategory = ref<number[]>([]);

watch(
  () => props.categoryId,
  () => {
    selectedCategory.value = [props.categoryId];
  },
  { immediate: true },
);

const handleUpdateCategoryId = ([id]: (string | number)[]) => {
  emit("change-category", { category_id: id as number });
  popupRef.value?.hide();
};

const isTreeBrowserLoading = ref(false);

const handleCreateCategory = async ({
  parentId,
  name,
}: {
  parentId: NodeId;
  name: string;
}) => {
  isTreeBrowserLoading.value = true;
  await catStore.createCategory({ name, parent_id: parentId as number });
  isTreeBrowserLoading.value = false;
};
</script>

<style module lang="scss">
@use "@app/styles/scss/colors";
@use "@app/styles/scss/typography";

.container {
  cursor: pointer;
  padding: 2px 4px;
  border-radius: 6px;
  border: 1px solid transparent;
  transition: border-color 0.1s ease-in-out;
  display: flex;

  &:hover {
    border: 1px solid colors.$sc-400;
  }
}

.container_isTreeVisible {
  border: 1px solid colors.$sc-400;
}

.primaryFont {
  color: colors.$pr-900;
  font: typography.$body_semibold;
}

.secondaryFont {
  color: colors.$pr-600;
  font: typography.$body_regular;
}
</style>
