import type { RoomActions } from "./GroupsActions";
import type { Group } from "@drVue/store/modules/room/groups/GroupsApiService";
import type { Dictionary } from "@drVue/types";

export class GroupsState {
  public updatePromise: Promise<Group[]> | null = null;

  pgroups: Dictionary<Group> = {};
  pgroupsList: Group[] = [];
  archivedPgroupsList: Group[] = [];

  pgroupsActions: Dictionary<RoomActions> = {};

  public isLoading: boolean | null = null;
  public isError: boolean | null = null;
}
