<template>
  <BatchListItem
    v-for="(bvm, i) in batchViewModels"
    :class="$style.listItem"
    :model="bvm"
    :tmp-index="i + 1"
    :key="bvm.batchId"
  />
</template>

<script setup lang="ts">
import { computed } from "vue";
import { useI18n } from "vue-i18n";

import { DrStore } from "@app/vue";
import BatchListItem from "./BatchListItem.vue";

import type { BatchViewModel, RoomUpdateBatch } from "./api";
import type { OrgUser } from "@app/vue/store/modules/client-dashboard/org-users/types";

interface Props {
  batches: RoomUpdateBatch[];
}

const props = defineProps<Props>();

const { t } = useI18n();

const batchViewModels = computed<BatchViewModel[]>(() => {
  const getRoomByUid = DrStore.getters["clientDashboard/deals/getRoomByUid"];
  const getOrgUserById = DrStore.getters["clientDashboard/orgUsers/byId"];

  return props.batches.map<BatchViewModel>((b) => {
    const createdByOrgUser: OrgUser = getOrgUserById(b.created_by_id);

    return {
      batchId: b.id,
      batchState: b.state,
      workflows: [...b.workflows],
      date: b.created_at,
      createdBy: {
        name: createdByOrgUser.name ?? t("comments.anonymous"),
        email: createdByOrgUser.email,
        avatarUrl: createdByOrgUser.avatar?.reduced,
      },
      sourceRoomTitle: getRoomByUid(b.src_room_id)?.title ?? "",
      targetRooms: b.workflows.map((w) => {
        const room = getRoomByUid(w.trg_room_id);
        return {
          id: w.trg_room_id,
          title: room?.title ?? "",
        };
      }),
    };
  });
});
</script>

<style lang="scss" module>
.listItem {
  margin-bottom: 20px;
}
</style>
