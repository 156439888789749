<template>
  <div>
    <ElForm
      ref="reviewFormRef"
      :class="{
        [$style.formWrapper]: true,
        [$style.formWrapperLike]: reviewFormModel.status === 'LIKE',
        [$style.formWrapperDislike]: reviewFormModel.status === 'DISLIKE',
      }"
      label-position="top"
      require-asterisk-position="right"
      :model="reviewFormModel"
      :rules="formRules"
      :disabled="!!(newReviewIsLoading && reviewFormModel.comment.length)"
      @submit="submit(reviewFormRef)"
    >
      <div
        :class="{
          [$style.firstRow]: true,
          [$style.firstRowShowEdit]: !showEdit && props.review?.status,
        }"
        @click="props.review?.status && showEditForm()"
      >
        <div>
          <span v-if="showEdit || !props.review?.status">
            Was this response helpful?
          </span>
          <span v-else-if="props.review?.comment">
            {{ props.review.comment }}
          </span>
          <span v-else :class="$style.reviewCommentEmpty">
            {{
              props.review?.status === "DISLIKE"
                ? "What didn't meet your expectations?"
                : "What did you find helpful in this response?"
            }}
          </span>
        </div>
        <span :class="$style.reviewIcons">
          <ElButton
            text
            size="small"
            :type="reviewFormModel.status === 'LIKE' ? 'success' : ''"
            @click.prevent.stop="setStatus('LIKE')"
          >
            <i class="fas fa-thumbs-up" />
          </ElButton>
          <ElButton
            text
            size="small"
            :type="reviewFormModel.status === 'DISLIKE' ? 'danger' : ''"
            @click.prevent.stop="setStatus('DISLIKE')"
          >
            <i class="fas fa-thumbs-down" />
          </ElButton>
        </span>
      </div>
      <div
        v-if="reviewFormModel.status && showEdit"
        :class="$style.formSeparator"
      />
      <ElFormItem
        v-if="reviewFormModel.status && showEdit"
        :error="formErrors.comment"
        prop="comment"
        :class="$style.formComment"
      >
        <ElInput
          ref="commentInputRef"
          v-model="reviewFormModel.comment"
          type="textarea"
          :placeholder="
            reviewFormModel.status === 'LIKE'
              ? 'What did you find helpful in this response?'
              : 'What didn\'t meet your expectations?'
          "
        />
      </ElFormItem>

      <div v-if="reviewFormModel.status && showEdit" :class="$style.formSubmit">
        <ElButton size="small" @click="cancel()"> Cancel </ElButton>
        <ElButton
          type="primary"
          :loading="!!(newReviewIsLoading && reviewFormModel.comment.length)"
          size="small"
          @click="submit(reviewFormRef)"
        >
          Submit
        </ElButton>
      </div>
      <ElAlert v-if="formAlerts" type="error" :title="formAlerts" />
    </ElForm>
  </div>
</template>

<script lang="ts" setup>
import { cloneDeep } from "lodash-es";
import { computed, reactive, ref, watch } from "vue";
import { useFormHelper } from "@shared/ui/dr-form";
import { useAsyncState } from "@vueuse/core";

import { DocVerQaApiService } from "@drVue/components/room/ai/api";

import type { DocVerQA, DocVerQAReview } from "@drVue/components/room/ai/types";
import type { FormInstance, FormRules } from "element-plus";

interface Props {
  docUid: string;
  qaId: DocVerQA["id"];
  review: DocVerQA["review"];
}
interface Events {
  (
    e: "submitted",
    payload: { qa_id: DocVerQA["id"]; review: DocVerQA["review"] },
  ): void;
}

const props = defineProps<Props>();
const emit = defineEmits<Events>();

const api = new DocVerQaApiService();
const showEdit = ref(false);

interface ReviewFormModel {
  status: DocVerQAReview["status"] | null;
  comment: DocVerQAReview["comment"];
}

const reviewFormRef = ref<FormInstance>();
const reviewFormModel = reactive<ReviewFormModel>({
  status: props.review?.status || null,
  comment: props.review?.comment || "",
});
const formRules = reactive<FormRules>({
  status: [
    {
      required: false,
      message: "This field is required.",
    },
  ],
});

watch(
  () => props.review,
  (newVal) => {
    reviewFormModel.status = newVal?.status || null;
    reviewFormModel.comment = newVal?.comment || "";
  },
);

const { formErrors, hookFormSubmitPromise, isFormSubmitting } =
  useFormHelper<ReviewFormModel>();
const commentInputRef = ref<HTMLElement | null>(null);

const setStatus = (status: DocVerQAReview["status"]) => {
  reviewFormModel.status = status;
  if (reviewFormModel.comment || props.review?.comment) {
    showEditForm();
  }
  onReviewSubmit({ status, comment: reviewFormModel.comment });
};

const clearForm = () => {
  reviewFormRef.value?.resetFields();
};

const cancel = () => {
  clearForm();
  showEdit.value = false;
};
const showEditForm = () => {
  showEdit.value = true;
  if (props.review) {
    reviewFormModel.comment = props.review.comment;
  }
  setTimeout(() => {
    commentInputRef.value?.focus();
  }, 0);
};

const submit = async (formEl: FormInstance | undefined) => {
  if (!formEl) return;
  await formEl.validate((valid) => {
    if (valid) {
      const review = {
        status: reviewFormModel.status!,
        comment: reviewFormModel.comment,
      };
      onReviewSubmit(cloneDeep(review)).then(() => {
        cancel();
      });
    }
  });
};

const {
  error: newReviewLoadError,
  isLoading: newReviewIsLoading,
  execute: newReview,
} = useAsyncState(
  (review: DocVerQAReview) => api.review(props.docUid, props.qaId, review),
  undefined,
  { immediate: false },
);
const formAlerts = computed((): string => {
  return (
    (formErrors as unknown as any).non_field_errors ||
    (newReviewLoadError?.value as unknown as any)?.message ||
    ""
  );
});

const onReviewSubmit = async (review: DocVerQAReview) => {
  const res = await newReview(0, review);
  if (res) {
    emit("submitted", {
      qa_id: props.qaId,
      review: res.review,
    });
    clearForm();
  }
  return res;
};
</script>
<style lang="scss" module>
@use "sass:color";

@use "@app/styles/scss/colors";
@use "@app/styles/scss/spacing";
@use "@app/styles/scss/typography";

.formWrapper {
  background: colors.$pr-50;
  border: 1px solid colors.$pr-150;
  border-radius: 7px;
  padding: 2px spacing.$xs;
}

.formWrapperLike {
  border: 1px solid rgba(colors.$ad-3, 0.05);
  background: rgba(colors.$ad-3, 0.05);
  :global(i.fa-thumbs-up) {
    color: colors.$ad-3;
  }
}

.formWrapperDislike {
  border: 1px solid rgba(colors.$ad-12, 0.05);
  background: rgba(colors.$ad-12, 0.05);
  :global(i.fa-thumbs-down) {
    color: colors.$ad-12;
  }
}

.firstRow {
  width: 100%;
  display: flex;
  justify-content: space-between;
  gap: 5px;
  align-items: center;
  font: typography.$body_regular;
}

.firstRowShowEdit {
  cursor: pointer;
}

.formSeparator {
  border-top: 1px solid colors.$pr-150;
  height: 1px;
  width: 100%;
  margin: 4px 0 12px;
}

.formSubmit {
  display: flex;
  justify-content: flex-end;
  margin: spacing.$xs 0;
}

.formComment {
  margin-bottom: 0 !important;
}

.reviewIcons {
  width: 60px;
  display: flex;

  :global(.el-button) {
    margin: 0 !important;
  }
  :global(.el-button:hover) {
    background-color: transparent !important;
  }

  :global(.el-button:hover .fa-thumbs-up) {
    color: color.adjust(colors.$ad-3, $lightness: -10%);
  }

  :global(.el-button:hover .fa-thumbs-down) {
    color: color.adjust(colors.$ad-12, $lightness: -10%);
  }
}

.reviewInfo {
  border-radius: 7px;
  padding: 2px spacing.$xs;
  display: flex;
  align-items: center;
  gap: spacing.$xs;
  font: typography.$body_regular;
  justify-content: space-between;
}

.reviewCommentText {
  cursor: pointer;
}

.reviewCommentEmpty {
  color: colors.$pr-400;
}
</style>
