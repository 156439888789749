import { cloneDeep } from "lodash-es";

import {
  DateType,
  deserializeDrDate,
  serializeDrDate,
} from "@drVue/api-service/parse.ts";

import type { CustomView, CustomViewFiltersField } from "./types";

export function copyView(view: CustomView, resetSlug: boolean = false) {
  const cloned = cloneDeep(view);
  if (resetSlug) {
    (cloned as any).slug = undefined;
  }
  return cloned;
}

function isDateFieldFilter(fieldFilter: CustomViewFiltersField) {
  // currently we don't have filed type info stored, so it's the only way to check since RANGE is used for dates only
  return fieldFilter.op === "RANGE";
}

export function serializeView(view: Partial<CustomView>): Partial<CustomView> {
  const cloned = cloneDeep(view);
  if (!cloned?.filters?.fields) return cloned;

  Object.values(cloned.filters.fields).forEach(
    (fieldFilter: CustomViewFiltersField) => {
      if (isDateFieldFilter(fieldFilter)) {
        fieldFilter.value = [
          serializeDrDate(DateType.Date, fieldFilter.value[0]),
          serializeDrDate(DateType.Date, fieldFilter.value[1]),
        ];
      }
    },
  );
  return cloned;
}

export function deserializeView(view: CustomView) {
  if (!view?.filters?.fields) return view;

  Object.values(view.filters.fields).forEach(
    (fieldFilter: CustomViewFiltersField) => {
      if (isDateFieldFilter(fieldFilter)) {
        fieldFilter.value = [
          deserializeDrDate(DateType.Date, fieldFilter.value[0]),
          deserializeDrDate(DateType.Date, fieldFilter.value[1]),
        ];
      }
    },
  );
}
