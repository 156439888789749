<template>
  <DrTooltip
    :disabled="canManage && !isMilestoneWithoutDate"
    :content="date ?? ''"
  >
    <span
      class="truncate"
      :class="{
        [$style.date_isMilestoneWithoutDate]: isMilestoneWithoutDate,
        [$style.date_isDynamicDateNotEditable]: !canManage && isDynamicDate,
        [$style.date_isOverdue]: isOverdue && !isMilestoneWithoutDate,
      }"
    >
      {{ date ?? "-" }}
    </span>
  </DrTooltip>
  <DynamicDateIndicatorIcon v-if="isDynamicDate" />
</template>

<script setup lang="ts">
import { computed } from "vue";
import { DrTooltip } from "@shared/ui/dr-tooltip";

import { ORG_MEMBER_DATA } from "@app/setups";
import { drUserTime } from "@drVue/filters/drUserTime";
import $utils from "@drVue/utils";
import DynamicDateIndicatorIcon from "../../DynamicDateIndicatorIcon.vue";
import { getMilestoneLabel } from "../utils";

import type { ViewFieldProps } from "@drVue/components/client-dashboard/dynamic-form/Fields/types";
import type { Task } from "@drVue/store/pinia/room/tasks";

interface Props {
  viewProps: ViewFieldProps<Date, Task>;
}

const props = defineProps<Props>();

const isOverdue = computed(() => {
  if (!props.viewProps.entity) return false;
  return props.viewProps.entity.overdue;
});

const canManage = computed(() => ORG_MEMBER_DATA.group?.is_administrator);

const isDynamicDate = computed(
  () => props.viewProps.entity.is_due_date_dynamic,
);

const isMilestoneWithoutDate = computed(
  () => props.viewProps.entityValue === null && isDynamicDate.value,
);

const date = computed(() => {
  if (isMilestoneWithoutDate.value) {
    if (!canManage.value || !props.viewProps.entity.dynamic_due_date) {
      return null;
    }
    const deltaDays = props.viewProps.entity.dynamic_due_date?.delta_days;
    const daysCount = Math.abs(deltaDays);
    const timeMarker = deltaDays > 0 ? "after" : "before";
    const milestoneLabel = getMilestoneLabel(
      props.viewProps.entity.dynamic_due_date.deal_field_key,
    );
    return [
      daysCount,
      $utils.text.pluralize("day", daysCount),
      timeMarker,
      milestoneLabel,
    ].join(" ");
  }
  return drUserTime(props.viewProps.entityValue, "short-date");
});
</script>

<style module lang="scss">
@use "@app/styles/scss/colors";

.date_isOverdue {
  color: colors.$ad-error !important;
}

.date_isDynamicDateNotEditable {
  color: colors.$pr-400;
}

.date_isMilestoneWithoutDate {
  color: colors.$pr-400;
}
</style>
