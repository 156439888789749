<template>
  <!-- @vue-expect-error -->
  <ParticipantsEdit :edit-props="editPropsWithUids">
    <template #reference="{ show }">
      <div
        ref="triggerRef"
        :class="$style.triggerRef"
        @click="handleTriggerClick(show)"
      >
        <div v-if="!editProps.value.length" :class="$style.action">
          <DrIcon size="sm" name="user" />
          <DrIcon size="xs" name="plus" />
        </div>
        <DrAvatarStack v-else :avatars="assigneeAvatars" />
        <span v-if="assignees.length === 1">{{ assigneesNames }}</span>
      </div>
    </template>
  </ParticipantsEdit>
</template>

<script setup lang="ts">
import { differenceBy } from "lodash-es";
import { computed, onMounted, ref } from "vue";
import { DrAvatarStack } from "@shared/ui/dr-avatar";
import DrIcon from "@shared/ui/dr-icon";

import { insightTrack, TasksTableTrackingEvent } from "@app/insight";
import { DrStore } from "@app/vue";
import { ParticipantsEdit } from "@drVue/components/room/tasks/shared/widgets/participants";
import { useTasksStore } from "@drVue/store/pinia/room/tasks";

import type { EditFieldProps } from "@drVue/components/client-dashboard/dynamic-form/Fields/types";
import type { ParticipantUserV1, Task } from "@drVue/store/pinia/room/tasks";

interface Props {
  editProps: EditFieldProps<ParticipantUserV1[], Task>;
}

const props = defineProps<Props>();

const taskStore = useTasksStore();

const assignees = computed(() =>
  props.editProps.value.map(({ user_id }) => {
    const user = DrStore.state.room.members.members[user_id];
    return { ...user, user_id: user.uid };
  }),
);

const editPropsWithUids = computed(() => ({
  ...props.editProps,
  value: assignees.value,
  veeField: {
    ...props.editProps.veeField,
    onChange: async (add: { user_id: string }[]) => {
      const remove = differenceBy(assignees.value, add, "user_id");
      await taskStore.updateAssignees(props.editProps.entity.id, add, remove);
      insightTrack(TasksTableTrackingEvent.AssigneeSet);
    },
  },
}));

const assigneeAvatars = computed(() =>
  assignees.value.map(({ name, avatar }) => ({
    name,
    url: avatar.thumbnail,
  })),
);

const assigneesNames = computed(() =>
  assignees.value
    .map(({ name }) => {
      const [firstName, lastName] = name.split(" ");
      return `${firstName[0]}. ${lastName}`;
    })
    .join(", "),
);

const isMounted = ref(false);

const handleTriggerClick = (popupShowFn: () => void) => {
  if (isMounted.value) {
    props.editProps.quitEditMode();
  } else {
    popupShowFn();
  }
};

const triggerRef = ref<InstanceType<typeof HTMLDivElement> | null>(null);

onMounted(() => {
  triggerRef.value?.click();
  isMounted.value = true;
});
</script>

<style module lang="scss">
@use "@app/styles/scss/colors";
@use "@app/styles/scss/typography";

.triggerRef {
  display: flex;
  align-items: center;
  border-radius: 6px;
  border: 1px solid colors.$sc-400;
  height: 32px;
  padding: 2px 6px;
  user-select: none;
  width: 100%;
  gap: 6px;
}

.action {
  color: colors.$sc-600;
}
</style>
