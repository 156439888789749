// Patched: https://github.com/ueberdosis/tiptap/blob/d8f3404d3f5f4e02d808190c497955967ee4bbeb/packages/suggestion/src/suggestion.ts
import tippy from "tippy.js";
import { Plugin, PluginKey } from "@tiptap/pm/state";
import { Decoration, DecorationSet } from "@tiptap/pm/view";
import { VueRenderer } from "@tiptap/vue-3";

import { findSuggestionMatch as defaultFindSuggestionMatch } from "./findSuggestionMatch";
import MentionList from "./MentionList.vue";

import type { MentionProvider } from "./providers";
import type { Editor, Range } from "@tiptap/core";
import type { EditorState } from "@tiptap/pm/state";
import type { EditorView } from "@tiptap/pm/view";

export interface SuggestionOptions<I = any> {
  pluginKey?: PluginKey;
  editor: Editor;
  // char?: string;
  allowSpaces?: boolean;
  allowedPrefixes?: string[] | null;
  startOfLine?: boolean;
  decorationTag?: string;
  decorationClass?: string;
  command?: (props: { editor: Editor; range: Range; props: I }) => void;
  // items?: (props: { query: string; editor: Editor }) => I[] | Promise<I[]>
  // render?: () => {
  //   onBeforeStart?: (props: SuggestionProps<I>) => void
  //   onStart?: (props: SuggestionProps<I>) => void
  //   onBeforeUpdate?: (props: SuggestionProps<I>) => void
  //   onUpdate?: (props: SuggestionProps<I>) => void
  //   onExit?: (props: SuggestionProps<I>) => void
  //   onKeyDown?: (props: SuggestionKeyDownProps) => boolean
  // }
  allow?: (props: {
    editor: Editor;
    state: EditorState;
    range: Range;
  }) => boolean;
  findSuggestionMatch?: typeof defaultFindSuggestionMatch;

  provider: MentionProvider;
}

export interface SuggestionProps<I = any> {
  editor: Editor;
  range: Range;
  query: string;
  text: string;
  items: I[];
  command: (props: I) => void;
  decorationNode: Element | null;
  clientRect?: (() => DOMRect | null) | null;
}

export interface SuggestionKeyDownProps {
  view: EditorView;
  event: KeyboardEvent;
  range: Range;
}

export const SuggestionPluginKey = new PluginKey("dr_suggestion");
const render = () => {
  let component: VueRenderer;
  let popup: any;

  return {
    onBeforeStart(props: SuggestionProps) {},

    onStart(props: SuggestionProps) {
      component = new VueRenderer(MentionList, {
        props,
        editor: props.editor,
      });

      if (!props.clientRect) {
        return;
      }

      popup = tippy("body", {
        getReferenceClientRect: props.clientRect as () => DOMRect,
        appendTo: () => document.body,
        content: component.element ?? undefined,
        showOnCreate: true,
        interactive: true,
        trigger: "manual",
        placement: "bottom-start",
        theme: "light-border",
      });
    },

    onBeforeUpdate(props: SuggestionProps) {},

    onUpdate(props: SuggestionProps) {
      component.updateProps(props);

      if (!props.clientRect) return;

      popup[0].setProps({
        getReferenceClientRect: props.clientRect,
      });
    },

    onKeyDown(props: SuggestionKeyDownProps) {
      if (props.event.key === "Escape") {
        popup[0].hide();

        return true;
      }

      return component.ref?.onKeyDown(props);
    },

    onExit(props: SuggestionProps) {
      popup[0].destroy();
      component.destroy();
    },
  };
};

export function Suggestion({
  pluginKey = SuggestionPluginKey,
  editor,
  allowSpaces = false,
  allowedPrefixes = null,
  startOfLine = false,
  decorationTag = "span",
  decorationClass = "suggestion",
  command = () => null,
  allow = () => true,
  findSuggestionMatch = defaultFindSuggestionMatch,

  provider,
}: SuggestionOptions) {
  let props: SuggestionProps | undefined;
  const renderer = render();

  const plugin: Plugin = new Plugin({
    key: pluginKey,

    view() {
      return {
        update: async (view, prevState) => {
          const prev = this.key?.getState(prevState);
          const next = this.key?.getState(view.state);

          // See how the state changed
          const moved =
            prev.active && next.active && prev.range.from !== next.range.from;
          const started = !prev.active && next.active;
          const stopped = prev.active && !next.active;
          const changed = !started && !stopped && prev.query !== next.query;
          const handleStart = started || moved;
          const handleChange = changed && !moved;
          const handleExit = stopped || moved;

          // Cancel when suggestion isn't active
          if (!handleStart && !handleChange && !handleExit) {
            return;
          }

          const state = handleExit && !handleStart ? prev : next;
          const decorationNode = view.dom.querySelector(
            `[data-decoration-id="${state.decorationId}"]`,
          );

          props = {
            editor,
            range: state.range,
            query: state.query,
            text: state.text,
            items: [],
            command: (commandProps) => {
              return command({
                editor,
                range: state.range,
                props: commandProps,
              });
            },
            decorationNode,
            // virtual node for popper.js or tippy.js
            // this can be used for building popups without a DOM node
            clientRect: decorationNode
              ? () => {
                  // because of `items` can be asynchrounous we’ll search for the current decoration node
                  const { decorationId } = this.key?.getState(editor.state);
                  const currentDecorationNode = view.dom.querySelector(
                    `[data-decoration-id="${decorationId}"]`,
                  );

                  return currentDecorationNode?.getBoundingClientRect() || null;
                }
              : null,
          };

          if (handleStart) {
            renderer?.onBeforeStart?.(props);
          }

          if (handleChange) {
            renderer?.onBeforeUpdate?.(props);
          }

          if (handleChange || handleStart) {
            // props.items = await items({
            //   editor,
            //   query: state.query,
            // });
            props.items = provider.filterItems(state.query, state.text);
          }

          if (handleExit) {
            renderer?.onExit?.(props);
          }

          if (handleChange) {
            renderer?.onUpdate?.(props);
          }

          if (handleStart) {
            renderer?.onStart?.(props);
          }
        },

        destroy: () => {
          if (!props) {
            return;
          }

          renderer?.onExit?.(props);
        },
      };
    },

    state: {
      // Initialize the plugin's internal state.
      init() {
        const state: {
          active: boolean;
          range: Range;
          query: null | string;
          text: null | string;
          composing: boolean;
          decorationId?: string | null;
        } = {
          active: false,
          range: {
            from: 0,
            to: 0,
          },
          query: null,
          text: null,
          composing: false,
        };

        return state;
      },

      // Apply changes to the plugin state from a view transaction.
      apply(transaction, prev, oldState, state) {
        const { isEditable } = editor;
        const { composing } = editor.view;
        const { selection } = transaction;
        const { empty, from } = selection;
        const next = { ...prev };

        next.composing = composing;

        // We can only be suggesting if the view is editable, and:
        //   * there is no selection, or
        //   * a composition is active (see: https://github.com/ueberdosis/tiptap/issues/1449)
        if (isEditable && (empty || editor.view.composing)) {
          // Reset active state if we just left the previous suggestion range
          if (
            (from < prev.range.from || from > prev.range.to) &&
            !composing &&
            !prev.composing
          ) {
            next.active = false;
          }

          // Try to match against where our cursor currently is
          const match = findSuggestionMatch({
            // char,
            allowSpaces,
            allowedPrefixes,
            startOfLine,
            $position: selection.$from,
          });
          const decorationId = `id_${Math.floor(Math.random() * 0xffffffff)}`;

          // If we found a match, update the current state to show it
          if (
            match &&
            provider.isMatchActive(match.text) &&
            allow({ editor, state, range: match.range })
          ) {
            next.active = true;
            next.decorationId = prev.decorationId
              ? prev.decorationId
              : decorationId;
            next.range = match.range;
            next.query = match.query;
            next.text = match.text;
          } else {
            next.active = false;
          }
        } else {
          next.active = false;
        }

        // Make sure to empty the range if suggestion is inactive
        if (!next.active) {
          next.decorationId = null;
          next.range = { from: 0, to: 0 };
          next.query = null;
          next.text = null;
        }

        return next;
      },
    },

    props: {
      // Call the keydown hook if suggestion is active.
      handleKeyDown(view, event) {
        const { active, range } = plugin.getState(view.state);

        if (!active) {
          return false;
        }

        return renderer?.onKeyDown?.({ view, event, range }) || false;
      },

      // Setup decorator on the currently active suggestion.
      decorations(state) {
        const { active, range, decorationId } = plugin.getState(state);

        if (!active) {
          return null;
        }

        return DecorationSet.create(state.doc, [
          Decoration.inline(range.from, range.to, {
            nodeName: decorationTag,
            class: decorationClass,
            "data-decoration-id": decorationId,
          }),
        ]);
      },
    },
  });

  return plugin;
}
