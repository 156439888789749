<template>
  <!-- @vue-expect-error -->
  <DrDatePicker
    :model-value="value"
    type="daterange"
    unlink-panels
    :clearable="clearable"
    :size="size"
    :shortcuts="shortcuts"
    @update:model-value="onModelUpdate"
  />
</template>

<script lang="ts">
import { defineComponent } from "vue";
import DrDatePicker, {
  convertBtzToPtz,
} from "@shared/ui/dr-datepicker/DrDatepicker.vue";

import { convertPtzToBtz } from "@drVue/common";

import type { PropType } from "vue";

interface Data {
  value: Date[];
  shortcuts: any[];
}

function getShortcutValue(
  subtractFromStart: number,
  subtractFromEnd: number = 0,
) {
  // These dates are in BTZ. They will be converted to PTZ by <DrDatePicker />.
  const start = new Date();
  const end = new Date();

  start.setHours(0, 0, 0, 0);
  end.setHours(23, 59, 59, 999);

  start.setTime(start.getTime() - 3600 * 1000 * 24 * subtractFromStart);
  end.setTime(end.getTime() - 3600 * 1000 * 24 * subtractFromEnd);

  return [start, end];
}

export default defineComponent({
  name: "NgDatepicker",
  components: { DrDatePicker },
  props: {
    initialValue: { required: true, type: Array as PropType<Date[]> },
    size: {
      default: "large",
      type: String as PropType<"large" | "small" | "default">,
    },
    clearable: { default: true, type: Boolean as PropType<true | false> },
  },
  emits: ["change"],
  data(): Data {
    return {
      value: [],
      shortcuts: [
        {
          text: "Today",
          value: () => getShortcutValue(0),
        },
        {
          text: "Yesterday",
          value: () => getShortcutValue(1, 1),
        },
        {
          text: "Last week",
          value: () => getShortcutValue(6),
        },
        {
          text: "Last month",
          value: () => getShortcutValue(29),
        },
        {
          text: "Last 3 months",
          value: () => getShortcutValue(89),
        },
      ],
    };
  },
  watch: {
    initialValue: {
      immediate: true,
      handler() {
        this.value = this.initialValue;
      },
    },
  },
  methods: {
    onModelUpdate(range: [Date, Date]) {
      const start = range[0];
      let end = range[1];

      // Before `update:modelValue` event is emitted, <DrDatePicker> converts
      // dates to PTZ. We need to convert them back to BTZ, do some work within
      // browser timezone and convert them back to PTZ.
      end = convertPtzToBtz(end);
      end.setHours(23, 59, 59, 999);
      end = convertBtzToPtz(end);

      this.value = [start, end];
      this.$emit("change", this.value);
    },
  },
});
</script>
