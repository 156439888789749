<template>
  <DrTooltip v-if="allowDelete" placement="left" :content="$t('shared.remove')">
    <div
      class="dr-vxe-cell__content-row-hover-visible"
      :class="{
        [$style.container]: true,
        [$style.container_isPending]: isPending,
      }"
      @click.stop="deleteMember"
    >
      <DrIcon :class="$style.icon" name="user-delete" />
    </div>
  </DrTooltip>
</template>

<script setup lang="ts">
import { ElMessageBox } from "element-plus";
import { computed, ref } from "vue";
import { useI18n } from "vue-i18n";
import { DrIcon } from "@shared/ui/dr-icon";
import { DrTooltip } from "@shared/ui/dr-tooltip";

import { ROOM_DATA } from "@setups/data";
import { DrStore } from "@app/vue";

import type { MembersTableRow } from "../../types";

interface Props {
  userUid: string;
}

const { userUid } = defineProps<Props>();

const { t } = useI18n();
const userPerms = ROOM_DATA.userPermissions;

const isPending = ref(false);

const member = computed(
  () => DrStore.state.room.members.membersByUid[userUid] || null,
);
const invite = computed(
  () => DrStore.state.room.members.invitesByUid[userUid] || null,
);

const allowDelete = computed(() => {
  const currentGroupId = (invite.value || member.value)?.pgroup.id;

  const isRoomOwner = !!member.value?.room_owner;
  const canManageMembers =
    userPerms.administrator ||
    (userPerms.invite && userPerms.id === currentGroupId);

  return !isRoomOwner && canManageMembers;
});

const deleteMember = async () => {
  const payload: MembersTableRow[] = [];
  if (invite.value) {
    payload.push(invite.value);
  } else if (member.value) {
    payload.push(member.value);
  }

  if (!payload.length || isPending.value) return;

  const email = invite.value?.email || member.value?.email;
  const isInvite = !!invite.value;
  const title = t("permissions.remove_members.confirm_title");
  const text = isInvite
    ? t("permissions.remove_members.confirm_text_invite", { email })
    : t("permissions.remove_members.confirm_text_member", { email });

  try {
    await ElMessageBox.confirm(text, title, {
      confirmButtonText: t("shared.remove"),
      customClass: "el-message-box--warning",
    });
  } catch {
    return;
  }

  isPending.value = true;
  DrStore.dispatch("room/members/removeMembers", payload).finally(() => {
    isPending.value = false;
  });
};
</script>

<style module lang="scss">
@use "@app/styles/scss/colors";
@use "@app/styles/scss/spacing";
@use "@app/styles/scss/typography" as typo;

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.icon {
  color: colors.$pr-500;
}

.container {
  display: inline-block;
  visibility: hidden;
  cursor: pointer;
}

.container_isPending {
  cursor: not-allowed;

  .icon {
    display: none;
  }

  &:before {
    content: "";
    display: inline-block;
    width: 14px;
    height: 14px;
    border: 2px solid colors.$pr-400;
    border-bottom-color: transparent;
    border-radius: 50%;
    box-sizing: border-box;
    animation: rotation 800ms linear infinite;
  }
}
</style>
