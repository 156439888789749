<template>
  <TablePageLayout :search="searchPhrase" @search="handleSearch">
    <template v-if="hasSeletedGroups" #toolbar>
      <ElButton type="primary" @click="restoreSelected">
        <template #icon>
          <DrIcon name="rotate-right" size="sm" />
        </template>
        {{ $t("permissions.groups_archive.restore_selected") }}
      </ElButton>
    </template>

    <template v-if="hasSeletedGroups" #toolbar-right>
      <DrToolbarFilterButton
        :label="selectedGroupsTitle"
        is-active
        no-focus
        @clear="clearGroupsSelection"
      />
    </template>

    <DrOverlayEmpty
      v-if="noDisplayData.active"
      icon="users"
      :title="noDisplayData.title"
      :subtitle="noDisplayData.subtitle"
    >
      <template #action>
        <ElButton
          v-if="noDisplayData.btnClearSearch"
          @click="searchPhrase = ''"
        >
          {{ $t("filters.clear_search_query") }}
        </ElButton>
      </template>
    </DrOverlayEmpty>

    <GroupsArchiveTable
      :items="filteredGroups"
      @checkbox="toggleGroup"
      @checkbox-all="toggleAll"
    />
  </TablePageLayout>
</template>

<script setup lang="ts">
import { ElMessageBox } from "element-plus";
import { computed, ref } from "vue";
import { useI18n } from "vue-i18n";
import { DrIcon } from "@shared/ui/dr-icon";
import { DrOverlayEmpty } from "@shared/ui/dr-overlay";
import { DrToolbarFilterButton } from "@shared/ui/dr-toolbar";

import { isStringContains } from "@drVue/common";
import DrStore from "@drVue/store";
import { useGroupsArchiveTableStore } from "@drVue/store/pinia/room/groups/groupsArchiveTable";
import TablePageLayout from "../TablePageLayout.vue";
import GroupsArchiveTable from "./table/GroupsArchiveTable.vue";

import type { GroupsArchiveTableRow } from "./types";

const { t } = useI18n();
const tableStore = useGroupsArchiveTableStore();

const isDataLoading = computed(() => DrStore.state.room.groups.isLoading);
const groupsList = computed(
  () => DrStore.state.room.groups.archivedPgroupsList,
);

const isPending = ref(false);
const searchPhrase = ref("");
const selectedGroupsIds = computed(() => tableStore.selectedGroupsIdsList);

const hasSeletedGroups = computed(
  () => !!tableStore.selectedGroupsIdsList.length,
);
const selectedGroupsTitle = computed(() => {
  const count = selectedGroupsIds.value.length;
  return `${t("permissions.groups_archive.selected_count", { count })}`;
});

const clearGroupsSelection = () => tableStore.clearSelection();

const filteredGroups = computed(() => {
  if (searchPhrase.value) {
    return groupsList.value.filter((group) =>
      isStringContains(searchPhrase.value, group.name),
    );
  }

  return groupsList.value;
});

const noDisplayData = computed(() => {
  const data = {
    active: false,
    title: "",
    subtitle: "",
    btnClearSearch: false,
  };

  if (isDataLoading.value) {
    return data;
  }

  if (!filteredGroups.value.length) {
    data.active = true;
    data.title = t("permissions.groups_archive.no_groups_in_archive");

    if (searchPhrase.value) {
      data.btnClearSearch = true;
      data.title = t("shared.no_results_for", { query: searchPhrase.value });
    }
  }

  return data;
});

const handleSearch = (phrase: string) => {
  if (isPending.value) return;
  searchPhrase.value = phrase.trim();
};
const toggleGroup = (groupId: GroupsArchiveTableRow["id"]) => {
  if (isPending.value) return;
  tableStore.toggleGroup(groupId);
};
const toggleAll = () => {
  if (isPending.value) return;
  tableStore.toggleAll();
};

const restoreSelected = async () => {
  if (isPending.value) return;

  const groups = tableStore.selectedGroups;

  const title = t("permissions.group_restore_confirm_title", groups.length);
  const text = t("permissions.groups_restore_confirm_text");

  try {
    await ElMessageBox.confirm(text, title, {
      confirmButtonText: t("shared.restore"),
      customClass: "el-message-box--warning",
    });
  } catch {
    return;
  }

  isPending.value = true;
  try {
    await DrStore.dispatch("room/groups/restore", groups);
    clearGroupsSelection();
  } finally {
    isPending.value = false;
  }
};
</script>
