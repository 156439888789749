<template>
  <DrTooltip placement="left" :content="$t('shared.restore')">
    <div
      class="dr-vxe-cell__content-row-hover-visible"
      :class="{
        [$style.container]: true,
        [$style.container_isPending]: isPending,
      }"
      @click.stop="restoreGroup"
    >
      <DrIcon :class="$style.icon" name="trash-restore" />
    </div>
  </DrTooltip>
</template>

<script setup lang="ts">
import { ElMessageBox } from "element-plus";
import { computed, ref } from "vue";
import { useI18n } from "vue-i18n";
import { DrIcon } from "@shared/ui/dr-icon";
import { DrTooltip } from "@shared/ui/dr-tooltip";

import { DrStore } from "@app/vue";

import type { GroupsArchiveTableRow } from "../../types";

interface Props {
  groupId: GroupsArchiveTableRow["id"];
}

const { groupId } = defineProps<Props>();

const { t } = useI18n();

const isPending = ref(false);

const group = computed(
  () => DrStore.state.room.groups.pgroups[groupId] || null,
);

const restoreGroup = async () => {
  if (isPending.value) return;

  const groupName = group.value!.name;
  const title = t("permissions.group_restore_confirm_title", 1);
  const text = t("permissions.group_restore_confirm_text", {
    group: groupName,
  });

  try {
    await ElMessageBox.confirm(text, title, {
      confirmButtonText: t("shared.restore"),
      customClass: "el-message-box--warning",
    });
  } catch {
    return;
  }

  isPending.value = true;
  DrStore.dispatch("room/groups/restore", [group.value]).finally(() => {
    isPending.value = false;
  });
};
</script>

<style module lang="scss">
@use "@app/styles/scss/colors";
@use "@app/styles/scss/spacing";
@use "@app/styles/scss/typography" as typo;

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.icon {
  color: colors.$pr-500;
}

.container {
  display: inline-block;
  cursor: pointer;
}

.container_isPending {
  cursor: not-allowed;

  .icon {
    display: none;
  }

  &:before {
    content: "";
    display: inline-block;
    width: 14px;
    height: 14px;
    border: 2px solid colors.$pr-400;
    border-bottom-color: transparent;
    border-radius: 50%;
    box-sizing: border-box;
    animation: rotation 800ms linear infinite;
  }
}
</style>
