<template>
  <div
    :class="{
      [$style.wrapper]: true,
      [$style.wrapper_slotOnRight]: slotOnRight,
    }"
  >
    <div :class="$style.slot">
      <slot />
    </div>

    <div ref="actionsContainerRef" :class="$style.actions">
      <div :class="$style.actionsList">
        <slot name="actionsPrefix" />

        <template v-for="item in visibleActions" :key="item.id">
          <DrTooltip :content="item.tooltip">
            <ElButton
              :ref="(ref) => setActionRef(item.id, ref)"
              :class="{
                [$style.button]: true,
                [$style.button_isActive]: item.id === localActiveAction,
              }"
              :disabled="!!item.disabled"
              @click="handleAction(item, $event)"
            >
              <span :class="$style.icon">
                <slot name="icon" v-bind="item">
                  <DrIcon v-if="item.icon" :name="item.icon" size="sm" />
                  <component
                    v-else-if="item.iconComponent"
                    :is="item.iconComponent"
                    class="el-icon"
                  />
                </slot>
              </span>
              <span :class="$style.title">{{ item.name }}</span>
            </ElButton>
          </DrTooltip>
        </template>
      </div>
    </div>

    <div
      :class="{
        [$style.menu]: true,
        [$style.menu_isVisible]: displayMenu,
      }"
    >
      <DrPopup>
        <template #reference="{ isOpened }">
          <ElButton
            ref="actionsMenuButtonRef"
            :class="{
              [$style.button]: true,
              [$style.buttonSmall]: true,
              [$style.button_isActive]:
                isOpened || 'menu' === localActiveAction,
            }"
          >
            <DrIcon name="ellipsis-h" size="lg" />
          </ElButton>
        </template>
        <template #default="{ hide }">
          <DrPopupSelectOptions
            :items="menuItems"
            type="icons"
            @select="(id) => handleMenuAction(id, hide)"
          />
        </template>
      </DrPopup>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { ElButton } from "element-plus";
import { computed, onBeforeUnmount, ref, unref } from "vue";
import { useIntersectionObserver } from "@vueuse/core";

import { DrIcon } from "../dr-icon";
import { DrPopup, DrPopupSelectOptions } from "../dr-popups";
import { DrTooltip } from "../dr-tooltip";

import type { SelectOptionItem } from "../dr-popups";
import type { ToolbarBulkActionsItem } from "./types";
import type { MaybeElement } from "@vueuse/core";
import type { ComponentPublicInstance, ComputedRef, Ref } from "vue";

/**
 * @doc https://element-plus.org/en-US/component/button.html#button-exposes
 */
type ElButtonExposesType = {
  /** button html element	 */
  ref: Ref<HTMLButtonElement>;
  /** button size */
  size: ComputedRef<"" | "small" | "default" | "large">;
  /** button type */
  type: ComputedRef<
    | ""
    | "default"
    | "primary"
    | "success"
    | "warning"
    | "info"
    | "danger"
    | "text"
  >;
  /** button disabled */
  disabled: ComputedRef<boolean>;
  /** whether adding space */
  shouldAddSpace: ComputedRef<boolean>;
};

interface Props {
  actions: ToolbarBulkActionsItem[];
  slotOnRight?: boolean;
  activeAction?: ToolbarBulkActionsItem["id"];
}

interface Emits {
  (
    event: "action",
    actionId: ToolbarBulkActionsItem["id"],
    reference: HTMLElement,
  ): void;
}

type ActionsRefs = Record<
  ToolbarBulkActionsItem["id"],
  {
    /** Action's element (el-button) visible state */
    isVisible: boolean;
    /** Action's element "stop intersection observer" function */
    stop: () => void;
    reference: HTMLElement;
  }
>;

const props = withDefaults(defineProps<Props>(), {
  slotOnRight: false,
  activeAction: undefined,
});

const emit = defineEmits<Emits>();

const actionsContainerRef = ref<HTMLDivElement | null>(null);
const actionsRefs = ref<ActionsRefs>({});
const actionsMenuButtonRef = ref<InstanceType<typeof ElButton> | null>(null);

const actionsMap = computed(() => {
  return props.actions.reduce(
    (actionsMap, actionItem) => {
      actionsMap[actionItem.id] = actionItem;
      return actionsMap;
    },
    {} as Record<ToolbarBulkActionsItem["id"], ToolbarBulkActionsItem>,
  );
});

const visibleActions = computed(() => {
  return props.actions.filter((item) => item.getIsVisible?.() !== false);
});

const intersectionObserverOptions = {
  root: actionsContainerRef,
  threshold: 1,
};
const setActionRef = (
  id: ToolbarBulkActionsItem["id"],
  el: Element | ComponentPublicInstance | null,
) => {
  const btnInstance = el as unknown as ElButtonExposesType;

  if (btnInstance?.ref) {
    if (actionsRefs.value[id]) {
      return;
    }

    const refBtn = ref(el) as Ref<MaybeElement>;
    const { stop } = useIntersectionObserver(
      refBtn,
      ([{ isIntersecting }]) => {
        if (actionsRefs.value[id]) {
          actionsRefs.value[id].isVisible = isIntersecting;
        } else {
          stop();
        }
      },
      intersectionObserverOptions,
    );

    actionsRefs.value[id] = {
      isVisible: false,
      stop,
      reference: unref(btnInstance.ref),
    };
  } else if (actionsRefs.value[id]) {
    actionsRefs.value[id].stop();
    delete actionsRefs.value[id];
  }
};

const menuItems = computed<SelectOptionItem<string>[]>(() => {
  return Object.keys(actionsRefs.value)
    .filter((actionId) => !actionsRefs.value[actionId].isVisible)
    .map((actionId) => ({
      ...actionsMap.value[actionId],
      isSelected: false,
    }));
});

const displayMenu = computed(() => !!menuItems.value.length);

const localActiveAction = computed(() => {
  if (!props.activeAction) return "";
  if (menuItems.value.find((item) => item.id === props.activeAction)) {
    return "menu";
  }
  return props.activeAction;
});

const handleAction = (item: ToolbarBulkActionsItem, event?: MouseEvent) => {
  if (actionsRefs.value[item.id]?.reference) {
    emit("action", item.id, actionsRefs.value[item.id].reference); // already saved Button's reference
    item.action?.(event); // event?.target => span inside button
  }
};

const handleMenuAction = (id: string, hide: () => void) => {
  if (actionsMenuButtonRef.value?.$el) {
    hide();
    emit("action", id, actionsMenuButtonRef.value.$el);
  }
};

onBeforeUnmount(() => {
  Object.keys(actionsRefs.value).forEach((actionId) => {
    actionsRefs.value[actionId].stop();
  });
});
</script>

<style lang="scss" module>
@use "@app/styles/scss/colors";
@use "@app/styles/scss/spacing";
@use "@app/styles/scss/typography" as typo;
@use "@app/styles/scss/values";

.wrapper {
  height: values.$base-input-height;
  display: grid;
  grid-template-columns: max-content 1fr min-content;
  grid-template-areas: "slot actions menu";
  align-items: center;
}

.slot {
  grid-area: slot;
  position: relative;
  height: 100%;
}

.actions {
  grid-area: actions;
  position: relative;
  height: 100%;
  padding-left: spacing.$m;
  overflow: hidden;
}

.actionsList {
  display: flex;
  justify-content: flex-end;
  flex-wrap: wrap;

  .button + .button {
    margin-left: 0;
  }
}

.button:global(.el-button) {
  /**
   * For some reason, the radius customization `$allButtonsBorderRadius`
   * from `frontend\app\styles\libs\element-ui.scss` were not applied here.
  */
  --el-border-radius-base: 8px;

  --el-button-text-color: #{colors.$pr-800};
  --el-button-bg-color: transparent;
  --el-button-border-color: transparent;

  --el-button-hover-text-color: #{colors.$sc-600};
  --el-button-hover-bg-color: #{colors.$pr-0};
  --el-button-hover-border-color: #{colors.$sc-400};

  --el-button-active-text-color: #{colors.$sc-600};
  --el-button-active-bg-color: #{colors.$sc-200};
  --el-button-active-border-color: #{colors.$sc-300};

  --el-button-icon-color: #{colors.$pr-500};
  --el-button-icon-hover-color: #{colors.$sc-600};
  --el-button-icon-active-color: #{colors.$sc-600};
  --el-button-icon-focus-color: #{colors.$pr-500};
  --el-button-icon-disabled-color: #{colors.$pr-400};

  max-width: 100%;
  padding: spacing.$xxs spacing.$xs;

  &:focus {
    --el-button-text-color: #{colors.$pr-800};
    --el-button-border-color: transparent;

    outline: none !important;
  }

  &:focus:hover {
    color: var(--el-button-hover-text-color);
    border-color: var(--el-button-hover-border-color);
    background-color: var(--el-button-hover-bg-color);
  }

  > span {
    display: inline-grid;
    grid-auto-flow: column;
    gap: spacing.$xs;
    align-items: center;
  }
}

.button_isActive:global(.el-button) {
  &,
  &:hover,
  &:focus {
    --el-button-text-color: #{colors.$sc-600};
    --el-button-bg-color: #{colors.$pr-0};
    --el-button-border-color: #{colors.$sc-400};
    --el-button-icon-color: #{colors.$sc-600};
  }
}

.buttonSmall {
  width: 32px;
}

.icon {
  display: inline-flex;
  align-items: center;
}

.title {
  color: inherit;
}

.menu {
  grid-area: menu;
  overflow: hidden;

  .button {
    transition-duration: 200ms;
    transition-timing-function: ease-out;
    transition-property: opacity, margin-right;
    opacity: 0;
    margin-right: -60px;
  }
}

.menu_isVisible {
  .button {
    opacity: 1;
    margin-right: 0;
  }
}

.wrapper_slotOnRight {
  grid-template-columns: 1fr min-content max-content;
  grid-template-areas: "actions menu slot";

  .actions {
    padding-left: 0;
  }

  .actionsList {
    justify-content: flex-start;
  }

  .slot {
    margin-left: spacing.$s;
  }
}
</style>
