<template>
  <div :class="$style.container" data-testid="title-cell">
    <div :class="$style.containerIcon">
      <DrIcon
        v-if="isFolder(row)"
        :name="!hasSearch && expanded ? 'folder-open' : 'folder'"
        :class="$style.icon"
        size="md"
      />
      <i
        v-else
        :class="[
          isDocument(row) ? getIconClass(row.mimetype) : 'ico-unknown',
          $style.iconMime,
        ]"
      />
    </div>

    <ElTooltip
      placement="top"
      :visible="isToolipShown"
      :content="row.name"
      :popper-class="$style.titleTooltip"
    >
      <div ref="titleContainerRef" class="truncate">
        <span
          ref="titleRef"
          @mouseenter="checkAndShowTitleTooltip"
          @mouseleave="hideTitleTooltip"
        >
          {{ row.name }}
        </span>
      </div>
    </ElTooltip>
  </div>
</template>

<script lang="ts" setup>
import { computed, ref } from "vue";

import getIconClass from "@app/common/mimetype";
import DrIcon from "@app/vue/shared/ui/dr-icon/DrIcon.vue";
import {
  type DocsItem,
  isDocument,
  isFolder,
} from "@app/vue/store/modules/room/documents/DocumentsApiService";
import { useDocumentsStore } from "@app/vue/store/pinia/room/documents/documents";

interface Props {
  row: DocsItem;
  expanded: boolean;
  hasSearch?: boolean;
}

const props = defineProps<Props>();

const documentsStore = useDocumentsStore();

const titleRef = ref<HTMLElement | null>(null);
const titleContainerRef = ref<HTMLElement | null>(null);
const isToolipShown = ref(false);

const checkAndShowTitleTooltip = () => {
  if (titleContainerRef.value && titleRef.value) {
    const containerWidth = titleContainerRef.value.clientWidth;
    const titleWidth = titleRef.value.offsetWidth;

    if (containerWidth < titleWidth) {
      isToolipShown.value = true;
    }
  }
};

const hideTitleTooltip = () => {
  isToolipShown.value = false;
};

const isExpandable = computed(
  () =>
    isFolder(props.row) &&
    documentsStore.folderItemsByIdMap[props.row.id].length !== 0,
);
</script>

<style lang="scss" module>
@use "@app/styles/scss/colors";
@use "@app/styles/scss/spacing";

.container {
  display: grid;
  grid-template-columns: 24px 1fr auto;
  grid-template-rows: 26px auto;
  align-items: center;
  padding: spacing.$xxs 0;
}

.containerIcon {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: spacing.$xxs;
}

.titleTooltip {
  max-width: 400px;
}

.textSnippet {
  --line-clamp: 4;

  font-size: 12px;
  margin-top: 5px;
  text-wrap: wrap;
  line-height: 1.2;
  color: colors.$pr-500;
  word-break: break-word;
  grid-column: span 3;

  b {
    font-weight: 500;
    color: colors.$pr-900;
    background-color: colors.$ad-7;
    padding: 0 1px;
  }
}

.icon {
  color: colors.$pr-400;
}

.iconMime {
  height: 16px;
  width: 16px;
  background-repeat: no-repeat;
  background-size: contain;
}

.iconExpanded {
  transform: rotate(90deg);
}

.buttonAiReport {
  height: 26px;
  width: 26px;
  color: colors.$pr-400;
  margin-left: spacing.$xs;

  &:global(.el-button:hover) {
    color: colors.$sc-600;
  }

  &:global(.el-button:focus:not(:hover)) {
    color: colors.$pr-400;
  }

  :global(.vxe-body--row:not(.row--hover)) & {
    display: none;

    &.buttonAiReport_applied {
      border: transparent;
      color: colors.$pr-350;
      display: block;
    }
  }
}
</style>
