<template>
  <DrPopup ref="popupRef" paddingless @hide="$emit('hide')">
    <template #default="{ hide }">
      <DrPanelWrapper
        :height="512"
        :width="432"
        :title="t('requests.remind_assignees_and_reviewers')"
      >
        <template #subtitle>
          {{ t("requests.send_reminder_notice") }}
        </template>

        <template #default>
          <div
            :class="{
              [$style.wrapper]: true,
              [$style.wrapper_noData]: noData,
            }"
          >
            <template v-if="noData">
              <DrIcon name="users" size="xl" :class="$style.noData__icon" />
              <div :class="$style.noData__text">
                {{ t("requests.selected_requests_have_no_participants") }}
              </div>
            </template>

            <template v-else>
              <DrTree
                flat-view
                show-checkbox
                check-on-click-node
                only-leaf-value
                :data="assigneesTree"
                :is-pending="isPending"
                v-model="localValueAssignees"
              >
                <template #item-prefix="{ item, className, node }">
                  <DrAvatar
                    v-if="node.isLeaf"
                    :name="item.name"
                    :identifier="(item as UsersItem).email"
                    :url="(item as UsersItem).avatar"
                    :class="className"
                  />
                </template>
              </DrTree>

              <DrTree
                flat-view
                show-checkbox
                check-on-click-node
                only-leaf-value
                :data="reviewersTree"
                :is-pending="isPending"
                v-model="localValueReviewers"
              >
                <template #item-prefix="{ item, className, node }">
                  <DrAvatar
                    v-if="node.isLeaf"
                    :name="item.name"
                    :identifier="(item as UsersItem).email"
                    :url="(item as UsersItem).avatar"
                    :class="className"
                  />
                </template>
              </DrTree>
            </template>
          </div>
        </template>

        <template #footer-right>
          <ElButton size="small" @click="hide">
            {{ t("shared.cancel") }}
          </ElButton>
          <ElButton
            size="small"
            type="primary"
            :disabled="!isValueChanged"
            @click="submitModelValue(hide)"
          >
            {{ t("requests.send_reminder") }}
          </ElButton>
        </template>
      </DrPanelWrapper>
    </template>
  </DrPopup>
</template>

<script setup lang="ts">
import { cloneDeep, xor } from "lodash-es";
import { computed, ref } from "vue";
import { useI18n } from "vue-i18n";
import { DrAvatar } from "@shared/ui/dr-avatar";
import { DrIcon } from "@shared/ui/dr-icon";
import { DrPanelWrapper } from "@shared/ui/dr-panels";
import { DrPopup } from "@shared/ui/dr-popups";
import { DrTree } from "@shared/ui/dr-tree";

import { insightTrack, RoomRequestsBulkEvent } from "@app/insight";

import type { Tree, TreeItem } from "@shared/ui/dr-tree";

type UsersItem = TreeItem<{
  avatar: string;
  email: string;
  id: string;
}>;

type UsersTree = Tree<void, UsersItem>;

interface Props {
  assignees: UsersItem[];
  reviewers: UsersItem[];
  /** Preselected items */
  modelValue?: UsersItem["id"][];
  /** Data is loading/processing/preparing */
  isPending?: boolean;
}

interface Emits {
  (event: "submit", payload: UsersItem["id"][]): void;
  (e: "hide"): void;
}

const props = withDefaults(defineProps<Props>(), {
  modelValue: () => [],
  isPending: false,
});

const emit = defineEmits<Emits>();

const { t } = useI18n();

const popupRef = ref<InstanceType<typeof DrPopup>>();

const assigneesTree = computed<UsersTree>(() => {
  if (!props.assignees.length) return [];

  return [
    {
      id: "assignees",
      name: t("shared.assignees"),
      children: cloneDeep(props.assignees),
    },
  ];
});

const reviewersTree = computed<UsersTree>(() => {
  if (!props.reviewers.length) return [];

  return [
    {
      id: "reviewers",
      name: t("shared.reviewers"),
      children: cloneDeep(props.reviewers),
    },
  ];
});

const noData = computed(
  () => !assigneesTree.value.length && !reviewersTree.value.length,
);

const collectIds = (item: TreeItem): TreeItem["id"][] => {
  if (item.children && item.children.length) {
    const subItemIds = item.children.map(collectIds).flat();
    return [item.id].concat(subItemIds);
  }
  return [item.id];
};
const assigneesTreeIds = computed(() => props.assignees.map(collectIds).flat());
const reviewersTreeIds = computed(() => props.reviewers.map(collectIds).flat());

const localValueAssignees = ref<UsersItem["id"][]>(
  props.modelValue.filter((id) => assigneesTreeIds.value.includes(id)),
);
const localValueReviewers = ref<UsersItem["id"][]>(
  props.modelValue.filter((id) => reviewersTreeIds.value.includes(id)),
);

const localValue = computed(
  () => new Set([...localValueAssignees.value, ...localValueReviewers.value]),
);

const isValueChanged = computed(
  () => !!xor([...localValue.value], props.modelValue).length,
);

const submitModelValue = (hidePopup?: () => void) => {
  if (isValueChanged.value) {
    emit("submit", [...localValue.value]);

    insightTrack(RoomRequestsBulkEvent.RemindUsed, {
      assignees: String(localValueAssignees.value.length),
      reviewers: String(localValueReviewers.value.length),
    });
  }

  hidePopup?.();
};

const show = (reference: HTMLElement) => {
  popupRef.value?.show(reference);
};

defineExpose({ show });
</script>

<style module lang="scss">
@use "@app/styles/scss/colors";
@use "@app/styles/scss/spacing";
@use "@app/styles/scss/typography" as typo;

.wrapper {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(40%, 1fr));
  column-gap: spacing.$m;
  padding: 0 4px;
}

.wrapper_noData {
  height: 350px;
  grid-template-columns: 45%;
  align-content: center;
  justify-content: center;
  justify-items: center;
  gap: 9px;
}

.noData__icon {
  color: colors.$pr-400;
}

.noData__text {
  color: colors.$pr-500;
  font: typo.$caption_medium;
  line-height: 20px;
  text-align: center;
}
</style>
