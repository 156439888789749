import { keyBy } from "lodash-es";
import { h } from "vue";

import { TableInlineEditor } from "@drVue/shared/ui/table-inline-editor/TableInlineEditor";
import { TableColumnsBase } from "@drVue/TableColumnsBase";
import {
  type Group,
  GROUP_TO_CATEGORY_ACCESS,
  type GroupsRequestsTableColumn,
  type GroupsRequestsTableRow,
  type GroupsToCategoriesAccessMap,
} from "../types";
import AccessCell from "./cells/AccessCell.vue";
import NameHeaderCell from "./cells/NameHeaderCell.vue";

import type { CustomViewColumn } from "@setups/types";
import type { ComputedRef } from "vue";

interface Handlers {
  onChange: (
    group: Group,
    categoryId: GroupsRequestsTableRow["id"],
    newAccess: boolean,
  ) => void;
}

export default class TableColumns extends TableColumnsBase<GroupsRequestsTableRow> {
  constructor(
    private readonly viewColumns: ComputedRef<CustomViewColumn[]>,
    private readonly groups: ComputedRef<Group[]>,
    private readonly accessMap: ComputedRef<GroupsToCategoriesAccessMap>,
    private readonly handlers: Handlers,
  ) {
    super();
  }

  /** just to fix requires of inlineEditor in TableColumnsBase */
  inlineEditor = new TableInlineEditor(async () => {
    return Promise.resolve();
  }, "id");

  getUserColumns(): Record<string, CustomViewColumn> {
    const columns = this.viewColumns.value;
    return columns ? keyBy(columns, "field") : {};
  }

  getTableColumns(): GroupsRequestsTableColumn[] {
    const groups = this.groups.value;
    return [
      this.nameColumn(),
      ...groups.map((group) => ({
        sortable: false,
        resizable: false,
        showHeaderOverflow: false,
        field: `group_${group.id}` as `group_${number}`,
        title: group.name,
        width: 64,
        slots: {
          default: ({ row }: { row: GroupsRequestsTableRow }) => {
            const isIndeterminate =
              this.accessMap.value[group.id]?.[row.id as number] ===
              GROUP_TO_CATEGORY_ACCESS.PARTIAL;
            const isChecked =
              this.accessMap.value[group.id]?.[row.id as number] ===
                GROUP_TO_CATEGORY_ACCESS.SELECTED || group.is_administrator;

            return h(AccessCell, {
              isChecked,
              isIndeterminate,
              isDisabled: group.is_administrator,
              onChange: (newAccess: boolean) =>
                this.handlers.onChange(group, row.id, newAccess),
            });
          },
          header: () => {
            const isIndeterminate =
              this.accessMap.value[group.id]?.root ===
              GROUP_TO_CATEGORY_ACCESS.PARTIAL;
            const isChecked =
              this.accessMap.value[group.id]?.root ===
                GROUP_TO_CATEGORY_ACCESS.SELECTED || group.is_administrator;

            return h(AccessCell, {
              title: group.name,
              isChecked,
              isIndeterminate,
              isDisabled: group.is_administrator,
              onChange: (newAccess: boolean) =>
                this.handlers.onChange(group, "root", newAccess),
            });
          },
        },
      })),
    ];
  }

  private nameColumn(): GroupsRequestsTableColumn {
    return {
      field: "name",
      title: "",
      treeNode: true,
      minWidth: 300,
      sortable: true,
      showOverflow: true,
      showHeaderOverflow: false,
      slots: {
        header: () => h(NameHeaderCell),
      },
    };
  }
}
