<template>
  <DrPopup ref="popupRef" paddingless @hide="handleHide" @show="handleShow">
    <template #default="{ hide }">
      <TreeBrowser
        v-model:selected-node-ids="selectedCategory"
        :is-loading="isTreeBrowserLoading"
        :tree="catTree"
        disallow-reset
        disallow-empty
        :title="t('nav.move_worklist')"
        :submit-text="t('shared.move')"
        autofocus
        @save="submit($event, hide)"
        @create-node="handleCreateCategory"
        @cancel="hide"
      />
    </template>
  </DrPopup>
</template>

<script setup lang="ts">
import { computed, ref } from "vue";
import { useI18n } from "vue-i18n";

import { $notifyDanger } from "@drVue/common";
import TreeBrowser from "@drVue/components/room/tasks/shared/tree-browser/TreeBrowser.vue";
import { DrPopup } from "@drVue/shared/ui/dr-popups";
import { pinia } from "@drVue/store/pinia";
import { useCategoriesStore } from "@drVue/store/pinia/room/categories";

import type { NodeId } from "@drVue/components/room/tasks/shared/tree-browser/types";
import type { Category } from "@drVue/store/pinia/room/categories";
import type { NavTreeItem } from "@shared/ui/dr-nav";
import type { TreeItem } from "@shared/ui/dr-tree";

type OpenPopupParams = {
  openedCallback?: () => void;
  closedCallback?: () => void;
};

interface Emits {
  (event: "submit", category: Category, newParent: Category): void;
  (event: "hide"): void;
}

const emit = defineEmits<Emits>();

const { t } = useI18n();

const categoriesStore = useCategoriesStore(pinia);

const popupRef = ref<InstanceType<typeof DrPopup>>();
const categoryWhichMove = ref<Category | undefined>();
const selectedCategory = ref<Category["id"][]>([]);
const hideModalCallback = ref<() => void | undefined>();
const showModalCallback = ref<() => void | undefined>();

type CategoryTreeItem = TreeItem<
  Pick<Category, "order" | "uid"> & { canParent: boolean }
>;

const toNode = (
  cat: NavTreeItem<Pick<Category, "order" | "uid">>,
): CategoryTreeItem => {
  const disabled =
    categoryWhichMove.value?.descendants.includes(cat.id as number) ?? false;

  if (cat.children) {
    const children = cat.children.map(toNode);
    return {
      ...cat,
      children,
      canParent: true,
      disabled,
    };
  }

  return {
    ...cat,
    canParent: true,
    disabled,
    children: [],
  };
};

const catTree = computed(() => categoriesStore.categoriesNavTree.map(toNode));
const submit = (selectedNodeIds: NodeId[], hidePopup: () => void) => {
  if (selectedNodeIds.length) {
    const newParent = categoriesStore.categories[selectedNodeIds[0]];
    emit("submit", categoryWhichMove.value!, newParent);
    hidePopup();
  }
};

const isTreeBrowserLoading = ref(false);
const handleCreateCategory = async ({
  parentId,
  name,
}: {
  parentId: NodeId;
  name: string;
}) => {
  isTreeBrowserLoading.value = true;
  try {
    await categoriesStore.createCategory({
      name,
      parent_id: parentId as number,
    });
  } catch (error) {
    $notifyDanger(t("nav.failed_to_create_worklist"));
  } finally {
    isTreeBrowserLoading.value = false;
  }
};

const show = (
  reference: HTMLElement,
  payload: Category,
  params?: OpenPopupParams,
) => {
  categoryWhichMove.value = payload;
  selectedCategory.value = [];
  popupRef.value?.show(reference);
  hideModalCallback.value = params?.closedCallback;
  showModalCallback.value = params?.openedCallback;
};

const handleHide = () => {
  emit("hide");
  hideModalCallback.value?.();
};

const handleShow = () => {
  showModalCallback.value?.();
};

defineExpose({ show });
</script>
