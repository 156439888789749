<template>
  <DrVxeGridDragNDrop
    :class="$style.wrapper"
    :disabled="inTheArchive"
    row-id-prefix="task"
    @drop="(task, files) => $emit('files-dropped', task as Task, files)"
  >
    <template #default="{ gridRefSetter }">
      <DrVxeGrid
        :set-grid-ref="(value) => setGridRef(value) && gridRefSetter(value)"
        :checkbox-config="{
          highlight: true,
          range: false,
          checkField: '_isChecked',
        }"
        :row-config="{
          useKey: true,
          keyField: 'uid',
          isCurrent: true,
          height: 38,
        }"
        :columns="columns"
        :data="items"
        :row-class-name="getRowClassName"
        :cell-style="getCellStyle"
        :span-method="colspanMethod"
        :sort-config="{
          remote: true,
          trigger: 'cell',
        }"
        :menu-config="{
          className: $style.contextMenu,
          ...makeTableMenu({ inTheArchive }),
        }"
        :tooltip-config="{
          contentMethod: tooltipMethod,
        }"
        @scroll="updateHoverRow"
        @menu-click="handleMenuClick"
        @sort-change="emitSortChanged"
        @cell-click="emitClick"
        @checkbox-all="emitCheckboxAll"
        @checkbox-change="emitCheckbox"
        @resizable-change="onColumnResized"
      >
        <template #empty />
      </DrVxeGrid>
    </template>
  </DrVxeGridDragNDrop>

  <BulkMoveOrCopyPicker
    ref="movePickerRef"
    @submit="handleMovePickerSubmit($event)"
  />
</template>

<script setup lang="ts">
import { computed, nextTick, ref, unref, useCssModule, watch } from "vue";
import DrVxeGrid from "@shared/ui/dr-vxe-grid";
import { useBrowserLocation, useClipboard } from "@vueuse/core";

import { taskUrl } from "@setups/room-urls";
import { type CustomViewColumn, CustomViewObjectTypes } from "@setups/types";
import { insightTrack, TasksTableTrackingEvent } from "@app/insight";
import { TasksFilterServiceProxy } from "@app/ng/serviceProxies";
import { isCategory } from "@app/ng/tasks/services/helpers/getItemType";
import { ROOM_DATA, ROOM_MEMBER_DATA } from "@app/setups";
import { DrStore } from "@app/vue";
import { t } from "@app/vue/i18n";
import DrVxeGridDragNDrop from "@app/vue/shared/ui/dr-vxe-grid/DrVxeGridDragNDrop.vue";
import { $notifyDanger, $notifySuccess, createDictionary } from "@drVue/common";
import { useHoverTracker } from "@drVue/components/room/tasks/TasksTable/useHoverTracker";
import { pinia } from "@drVue/store/pinia";
import { useTasksStore } from "@drVue/store/pinia/room/tasks";
import { useTasksLabelsStore } from "@drVue/store/pinia/room/tasksLabels/tasksLabels";
import BulkMoveOrCopyPicker from "../shared/BulkMoveOrCopyPicker.vue";
import { saveColumnsConfig } from "../TaskOverviewPage/viewUtils";
import { makeTableMenu, TaskContextAction } from "./makeTableMenu";
import { useTaskTableBus } from "./tasksTableBus";
import { useGridVars } from "./useGridVars";
import { useTasksReorder } from "./useTasksReorder";

import type { TaskEvent } from "./tasksTableBus";
import type { UploadItem } from "@app/vue/utils/extractFiles";
import type { RequestsItem } from "@drVue/components/room/tasks/TasksTable/types";
import type { DrVxeTableColumn } from "@drVue/components/types";
import type { Category } from "@drVue/store/pinia/room/categories";
import type { Task } from "@drVue/store/pinia/room/tasks";
import type {
  VxeGridInstance,
  VxeTableDefines,
  VxeTableEvents,
  VxeTablePropTypes,
} from "vxe-table";

interface Props {
  items: RequestsItem[];
  selectedItems: RequestsItem[];
  columns: DrVxeTableColumn<RequestsItem>[];
}

interface Emits {
  (
    e: "add-task-below",
    payload: { categoryId: Task["category_id"]; order: number },
  ): void;
  (e: "cell-clicked", item: RequestsItem): void;
  (e: "checkbox-all"): void;
  (e: "checkbox-category", payload: { category: Category }): void;
  (e: "checkbox-task", payload: { task: Task }): void;
  (e: "duplicate", payload: { source: Task }): void;
  (e: "files-dropped", task: Task, files: UploadItem[]): void;
  (
    e: "sort-changed",
    payload: {
      field: string;
      order: VxeTablePropTypes.SortOrder;
    },
  ): void;
  (
    e: "task-reordered",
    taskToMoveId: number,
    prevItemId: number,
    isPrevCategory: boolean,
  ): void;
  (e: "archive", uids: Task["uid"][]): void;
  (e: "restore", uids: Task["uid"][]): void;
}

const $styles = useCssModule();
const tasksStore = useTasksStore(pinia);
const tasksLabelsStore = useTasksLabelsStore(pinia);

const props = withDefaults(defineProps<Props>(), {
  items: () => [],
  selectedItems: () => [],
});
const emit = defineEmits<Emits>();

const { copy: copyToClipboard, isSupported } = useClipboard();
const location = useBrowserLocation();

const inTheArchive = computed(() => {
  return !!unref(location).hash?.match(/archived/);
});

const tasksFilterService = new TasksFilterServiceProxy();

// When a user clicks on a checkbox, the following occurs:
// 1. VxeGrid sets the `_isChecked` property to `true` immediately (in the Task
//    object).
// 2. VxeGrid emits a `checkbox-change` event.
// 3. Either `toggleTaskSelection` or `toggleCategorySelection` is called.
// 4. TasksTableStore updates the `selectedItems` state.
// 5. The `selectedItems` watcher is called.
// 6. The watcher sets the `_isChecked` property to `false` for all.
// 7. The watcher then sets the `_isChecked` property to `true` for
//    `selectedItems` again.
//
// Although this may seem inefficient, it's a trade-off to keep the
// `tasksTableStore` state as the single source of truth.
//
// `selectedItems` are bound to both `tasksStore.items` and the
// `TasksFiltersService` state. Essentially, it's a subset of `tasksStore.items`
// that is built by filtering (in TasksFiltersService) `tasksStore.items`.
//
// If you navigate away and return, `updateData()` will be called in
// room-data.js, and `tasksStore.items` will be updated. This means we've just
// lost the `_isChecked` properties for all tasks. However, as was mentioned
// before, since `selectedItems` are bound to `tasksStore.items`, the watcher
// will be called after the update.
//
// Consider the following scenario:
// 1. A user selects some tasks.
// 2. The user clicks on another category.
// 3. The user navigates away and returns (with `onUpdate()` being called).
// 4. The user returns to the category where they selected the tasks.
// 5. The user notices that the tasks are not selected.
//
// We have the following structure:
// -- task-overview-table.html
// -- -- <vue-component name="VueTasksOverviewContent" />
// -- -- -- <TasksTable />
//
// <TasksTable /> resides within the Angular's page. When a user clicks on a
// category in the sidebar, we call the `navigate()` method in
// RoomTasksController.js which, in turn, calls $state.go() and reloads the
// entire structure. Consequently, <TaskTable /> is bootstrapped every time we
// `navigate()`.
//
// After the structure has reloaded, in TasksListController.js, we set the
// "current category" to the TasksFilterService.ts state, which is used in
// `tasksTableStore` to prepare `selectedItems` from `tasksStore.items` and the
// filter states.
//
// We've prepared `selectedItems`, and only after that, <TaskStore /> will be
// bootstrapped. `watch()` is by default eager, meaning that we don't call it
// for the first time, after <TasksTable /> was mounted. Herein lies the
// problem.
//
// 0. We have the current `selectedItems` state in `tasksTableStore`.
// 1. We've lost all the `_isChecked` properties.
// 2. We don't call `watch()` for the first time.
// 3. The state has diverged.
//
// To actually call it for the first time, we use an immediate watcher.
watch(
  () => props.selectedItems,
  (selectedItems) => {
    // This watcher is being called right after bootstrapping <TasksTable />
    // component. At this point `gridRef` is not initialized yet.
    // To wait for VxeGrid to initialize `gridRef` we use nextTick().
    nextTick(() => {
      const $grid = gridRef.value;

      $grid?.setAllCheckboxRow(false);
      if (selectedItems.length) $grid?.setCheckboxRow(selectedItems, true);
    });
  },
  {
    immediate: true,
  },
);

const getRowClassName: VxeTablePropTypes.RowClassName<RequestsItem> = ({
  row,
  rowIndex,
}) => {
  const classes = [];
  if (rowIndex === 0) classes.push("the-first");
  if ((row as unknown as { _isChecked: boolean })._isChecked) {
    classes.push("is-checked");
  }
  if (isCategory(row)) classes.push($styles.category);

  return classes.join(" ");
};

const getCellStyle: VxeTablePropTypes.CellStyle<RequestsItem> = (arg) => {
  if (arg.column.field === "_drag") return { lineHeight: 1 };
};

const gridRef = ref<VxeGridInstance | null>(null);
const setGridRef = (ref: VxeGridInstance) => (gridRef.value = ref);

const updateVars = useGridVars(gridRef);
watch(() => props.columns, updateVars);

// adjust view stuff
const onColumnResized = ({
  column,
}: {
  column: VxeTableDefines.ColumnInfo;
}) => {
  const update: CustomViewColumn = {
    field: column.field,
    width: column.resizeWidth,
  };

  updateVars();

  saveColumnsConfig(DrStore, [update], CustomViewObjectTypes.Task);
};

const emitClick: VxeTableEvents.CellClick<RequestsItem> = (params) => {
  const { $event, row } = params;
  const target = $event.target as HTMLElement;
  if (target.closest(".col--checkbox")) {
    // We use :row-config="{ isCurrent: true }" to be able to highlight current
    // row while dragging files over it. That setting also enables highlighting
    // by clicking on a row.
    //
    // The order:
    //   1. VXE highlights current row
    //   2. VXE calls this callback
    //   3. > We're clearing the current row (the actual line below)
    //   4. Browser repaints the row
    unref(gridRef)?.clearCurrentRow();

    // Do no emit 'cell-clicked' event if the click has happened within the
    // checkbox column.
    return;
  }

  emit("cell-clicked", row);

  insightTrack(TasksTableTrackingEvent.RequestModalOpened);
};

const emitCheckbox: VxeTableEvents.CheckboxChange<Task | Category> = (
  params,
) => {
  if (isCategory(params.row)) {
    emit("checkbox-category", { category: params.row });
  } else {
    emit("checkbox-task", { task: params.row });
  }
};

// SORT_COMPARATORS in TasksFilterService.js
const fieldToComparator = createDictionary({
  comments_count: "new_comments_count",
  documents: "files",
  status_id: "status",
});
const emitSortChanged: VxeTableEvents.SortChange = ({ field, order }) => {
  emit("sort-changed", {
    field: fieldToComparator[field] || field,
    order,
  });
};
const emitCheckboxAll = () => emit("checkbox-all");

const SPAN_FROM_INDEX = 1;
const colspanMethod: VxeTablePropTypes.SpanMethod<RequestsItem> = (params) => {
  const { columnIndex, row } = params;

  if (isCategory(row)) {
    if (columnIndex === SPAN_FROM_INDEX) {
      return {
        rowspan: 1,
        colspan:
          props.columns.filter((col) => col.visible).length - SPAN_FROM_INDEX,
      };
    }
    if (columnIndex > SPAN_FROM_INDEX) return { rowspan: 1, colspan: 0 };
  }

  return { rowspan: 1, colspan: 1 };
};

const tooltipMethod = (params: {
  column: { field: string };
  row: RequestsItem;
}) => {
  if (isCategory(params.row)) return null;

  if (params.column.field === "labels") {
    const labels = params.row?.labels ?? null;

    if (
      !tasksLabelsStore.list.length ||
      !Array.isArray(labels) ||
      !labels.length
    ) {
      return null;
    }

    return (
      params.row.labels
        .map((labelId) => tasksLabelsStore.dict[labelId]?.name ?? null)
        .filter(Boolean)
        .join(", ") ?? null
    );
  }

  return null;
};

const updateHoverRow = useHoverTracker(gridRef);
const tasksReorder = useTasksReorder(
  gridRef,
  (taskToMoveId, prevItemId, isPrevCategory) => {
    emit("task-reordered", taskToMoveId, prevItemId, isPrevCategory);

    // setTimeout to ensure that all SortableJS jobs are done.
    setTimeout(() => {
      gridRef.value?.updateData();
    });
  },
);
watch(
  () => tasksFilterService.isActive() || tasksFilterService.hasColumnSorting(),
  (isReorderDisabled) => {
    isReorderDisabled ? tasksReorder.disable() : tasksReorder.enable();
  },
);

const tasksTableBus = useTaskTableBus();

const handleMenuClick: VxeTableEvents.MenuClick<Task> = async ({
  menu,
  row,
  cell,
}) => {
  tasksTableBus.emit(menu.code as TaskEvent, { task: row, reference: cell });

  insightTrack(TasksTableTrackingEvent.RowContextMenuClicked, {
    menu: menu.code as TaskEvent,
  });
};

const copyTaskUrl = (task: Task) => {
  if (isSupported) {
    copyToClipboard(taskUrl(ROOM_DATA.url, task.key));
    $notifySuccess(t("requests.url_copied_success"));
  } else {
    $notifyDanger(t("requests.url_copied_failure"));
  }
};

const taskUnderContextMenu = ref<Task>();
const movePickerRef = ref<InstanceType<typeof BulkMoveOrCopyPicker>>();

const handleMovePickerSubmit = (categoryId: string) => {
  if (!taskUnderContextMenu.value) return;

  tasksStore.bulkMove([taskUnderContextMenu.value.uid], categoryId).then(
    () => $notifySuccess("Request successfully moved."),
    () => $notifyDanger("Failed to move request."),
  );
};

tasksTableBus.on((event, payload) => {
  if (!payload?.task) throw new Error("Task is not provided");

  const task = payload.task;
  taskUnderContextMenu.value = task;

  switch (event) {
    case TaskContextAction.FOLLOW: {
      return tasksStore
        .updateFollowers(task.id, [{ user_id: ROOM_MEMBER_DATA.user_id }], [])
        .then(
          () => $notifySuccess("Successfully followed the task."),
          () => $notifyDanger("Something went wrong while following the task."),
        );
    }
    case TaskContextAction.COPY_URL: {
      copyTaskUrl(payload.task);
      break;
    }
    case TaskContextAction.ADD_BELOW: {
      emit("add-task-below", {
        categoryId: task.category_id,
        order: task.order + 1,
      });
      break;
    }
    case TaskContextAction.MOVE_TO_TOP: {
      return tasksStore.moveTaskToTop(task.uid);
    }
    case TaskContextAction.MOVE: {
      movePickerRef.value?.show(payload.reference, "move-single");
      movePickerRef.value?.setSelected(task.category_id);
      break;
    }
    case TaskContextAction.DUPLICATE: {
      emit("duplicate", { source: task });
      break;
    }
    case TaskContextAction.RESTORE: {
      emit("restore", [task.uid]);
      break;
    }
    case TaskContextAction.DELETE: {
      emit("archive", [task.uid]);
      break;
    }
  }
});
</script>

<style module lang="scss">
@use "@app/styles/scss/colors";
@use "@app/styles/scss/vue-common/_vxe-table-menu.scss";

.wrapper {
  height: 100%;

  :global(.vxe-table.border--full .vxe-header--column) {
    background-color: white;
  }
}

.category {
  background-color: colors.$pr-50;
}
</style>
