<template>
  <DrDrawer
    :shown="true"
    size="large"
    :title="t('table.adjust_default_view')"
    :is-submitting="isSubmitting"
    :submit-btn="t('shared.save')"
    @close="closeModal"
    @cancel="closeModal"
    @submit="saveDefaultView"
  >
    <TableCustomizeFixed
      :columns="tableColumns.columns"
      @update="onTableCustomize"
    />
  </DrDrawer>
</template>

<script setup lang="ts">
import { cloneDeep } from "lodash-es";
import { computed, onMounted, ref, watchEffect } from "vue";
import { useI18n } from "vue-i18n";
import { DrDrawer } from "@shared/ui/dr-drawer";
import TableCustomizeFixed from "@shared/ui/table-customize/TableCustomizeFixed.vue";

import { TaskFieldAccessType } from "@setups/enums";
import { CustomViewLayouts, CustomViewObjectTypes } from "@setups/types";
import {
  insightTrack,
  TasksTableDefaultViewSettingsTrackingEvent,
} from "@app/insight";
import { ORG_MEMBER_DATA } from "@app/setups";
import { $notifyDanger } from "@drVue/common";
// TODO: move to view utils
import { handleUpdates } from "@drVue/components/room/tasks/TaskOverviewPage/viewUtils";
import TableColumns from "@drVue/components/room/tasks/TasksTable/tableColumns";
import DrStore from "@drVue/store";
import { DEFAULT_TASK_TABLE_VIEW } from "@drVue/store/modules/common/custom-views/default";

import type { FieldItem } from "@drVue/api-service/client-dashboard";
import type { CustomView, CustomViewColumn } from "@setups/types";

const emit = defineEmits(["close"]);

const { t } = useI18n();

const isSubmitting = ref(false);

const getDefaultTableView = computed(() => {
  const views: CustomView[] = DrStore.getters[
    "common/customViews/byObjectType"
  ](CustomViewObjectTypes.Task);
  const view = views.find(
    (v) => !v.is_personal && v.layout === CustomViewLayouts.Table,
  );
  if (view) {
    return view;
  }
  const newView = cloneDeep(DEFAULT_TASK_TABLE_VIEW);
  newView.is_personal = false;
  newView.layout = CustomViewLayouts.Table;
  return newView;
});

const viewColumns = ref<CustomViewColumn[]>([]);

watchEffect(() => {
  const view = getDefaultTableView.value;
  viewColumns.value = cloneDeep(view.settings.columns!);
});

const customFields = computed((): FieldItem[] => {
  return DrStore.getters["clientDashboard/customFields/byObjectType"]("task");
});

const tableColumns = new TableColumns(
  viewColumns,
  customFields,
  {
    canManageTasks: true,
    isFindingsAccessible: ORG_MEMBER_DATA.client.enable_findings,
    taskCustomFieldsAccess: TaskFieldAccessType.Edit,
  },
  false,
);

const onTableCustomize = (updates: CustomViewColumn[]) => {
  handleUpdates(viewColumns.value, updates);
};

const saveDefaultView = async () => {
  isSubmitting.value = true;

  const view = cloneDeep(getDefaultTableView.value);
  view.settings.columns = viewColumns.value;

  const method = view.slug ? "update" : "create";
  try {
    await DrStore.dispatch(`common/customViews/${method}`, view);
    closeModal();
    insightTrack(TasksTableDefaultViewSettingsTrackingEvent.Saved);
  } catch (e) {
    await $notifyDanger("Failed to save requests table default view");
  }
  isSubmitting.value = false;
};

const closeModal = () => {
  emit("close");
};

onMounted(() => {
  insightTrack(TasksTableDefaultViewSettingsTrackingEvent.Opened);
});
</script>

<style module lang="scss">
.content {
  min-height: 225px;
}
</style>
