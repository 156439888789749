<template>
  <DrViewBar :items="views" @select="selectView" />
</template>

<script setup lang="ts">
import { computed } from "vue";
import { useI18n } from "vue-i18n";
import { DrViewBar } from "@shared/ui/dr-view-bar";
import { useBrowserLocation } from "@vueuse/core";

import { ROOM_MEMBER_DATA } from "@setups/data";
import { TaskFieldAccessType } from "@setups/enums";

import type { ViewbarItem } from "@shared/ui/dr-view-bar";

const accessStartDueDatesFields =
  (ROOM_MEMBER_DATA.group.task_start_and_due_dates_access ||
    TaskFieldAccessType.NoAccess) !== TaskFieldAccessType.NoAccess;

const { t } = useI18n();

const $location = useBrowserLocation();
const views = computed<ViewbarItem[]>(() => {
  const currentPath = $location.value.hash?.slice(1) || "";
  const items = [
    {
      id: "overview",
      name: t("shared.overview"),
      isActive: currentPath.endsWith("/overview"),
    },
  ];

  if (accessStartDueDatesFields) {
    items.push({
      id: "timeline",
      name: t("shared.timeline"),
      isActive: currentPath.endsWith("/timeline"),
    });
  }

  return items;
});

const selectView = (viewId: string) => {
  location.hash = `#/tasks/list/${viewId}`;
};
</script>
