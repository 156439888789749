<template>
  <div v-if="withTitle && group" :class="$style.titleContainer">
    <div :class="$style.title">
      <div :class="$style.titleText">
        {{ group.name }}
      </div>
    </div>
  </div>

  <div :class="$style.container" data-testid="permission-cell">
    <component
      :is="short ? FilePermissionDropdown : FilePermission"
      :model-value="rowPermissionsValue"
      :propagated-list="propagatedList"
      :error-message="
        loadingError
          ? $t('permissions.file.error_download_watermarked')
          : undefined
      "
      :disabled="isAdmin"
      @update:model-value="submit"
    />
  </div>
</template>

<script lang="ts" setup>
import { computed, ref } from "vue";

import {
  type DocsItem,
  isFolder,
} from "@app/vue/store/modules/room/documents/DocumentsApiService";
import { useDocumentsStore } from "@app/vue/store/pinia/room/documents/documents";
import { useFilePermissionsStore } from "@app/vue/store/pinia/room/filePermissions/filePermissions";
import {
  filePermissionKeyList,
  type FilePermissionsShort,
} from "@app/vue/store/pinia/room/filePermissions/FilePermissionsApi";
import FilePermission from "../../components/FilePermission.vue";
import FilePermissionDropdown from "../../components/FilePermissionDropdown.vue";

import type { Group } from "@app/vue/store/modules/room/groups/GroupsApiService";
import type { FilePermissionKey } from "@app/vue/store/pinia/room/filePermissions/FilePermissionsApi";

interface Props {
  row: DocsItem | null;
  group: Group | null;
  short?: boolean;
  withTitle?: boolean;
  searchItems?: DocsItem[];
  isAdmin: boolean;
}

const props = defineProps<Props>();

const documentsStore = useDocumentsStore();
const filePermissionsStore = useFilePermissionsStore();

const loadingError = ref(false);

const rowPermissions = computed<FilePermissionsShort | undefined>(() => {
  if (!props.group || !props.row) return;

  return filePermissionsStore.getDocItemPermissions(props.group.id, props.row);
});

const rowPermissionsValue = computed<FilePermissionsShort>(() => {
  if (props.isAdmin) return [true, true, true, true];

  return rowPermissions.value ?? [false, false, false, false];
});

const getIsPermissionPropagated = (
  docsItem: DocsItem,
  permission: FilePermissionKey,
): boolean => {
  if (!rowPermissions.value) return false;

  return documentsStore.folderItemsByIdMap[docsItem.id].some((item) => {
    if (
      filePermissionsStore.getDocItemPermissions(props.group!.id, item)?.[
        permission
      ] !== rowPermissions.value?.[permission]
    )
      return true;

    if (isFolder(item)) return getIsPermissionPropagated(item, permission);

    return false;
  });
};

const propagatedList = computed<FilePermissionKey[]>(() => {
  if (
    !props.group ||
    !props.row ||
    !isFolder(props.row) ||
    !rowPermissions.value
  )
    return [];

  return filePermissionKeyList.filter((permission) =>
    getIsPermissionPropagated(props.row!, permission),
  );
});

const updatePermissions = (
  permissionsGroupId: number,
  value: FilePermissionsShort,
) => {
  return filePermissionsStore.updatePermissions(
    permissionsGroupId,
    value,
    props.searchItems && !props.row
      ? props.searchItems
      : props.row
        ? [props.row]
        : [],
  );
};

const submit = (value: FilePermissionsShort) => {
  if (!props.group) return;

  updatePermissions(props.group.id, value);
};
</script>

<style lang="scss" module>
@use "@app/styles/scss/colors";
@use "@app/styles/scss/spacing";

.container {
  display: flex;
  gap: 4px;
}

.titleContainer {
  height: 94px;
  display: grid;
  align-content: flex-end;
  position: relative;
  z-index: 1;
}

.title {
  transform: rotate(-34deg);
  transform-origin: left;
  width: 90px;
  height: 32px;
  display: grid;
  align-content: flex-end;
}

.titleText {
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  color: colors.$pr-900;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
}
</style>
