<template>
  <Teleport to="#documents-header">
    <DocumentsHeader
      :search-query="searchQuery"
      :selected-folder="selectedFolder"
      :is-archive="isArchive"
      :is-bookmarks="isBookmarks"
      :is-recent="isRecent"
      @select-folder="$emit('menu-open-folder', $event)"
    />
  </Teleport>

  <Teleport to="#documents-bulk-actions">
    <DocumentsBulkActions
      v-model:filter-upload-date="filterUploadDate"
      :doc-items="filteredItems"
      :selected-folder="selectedFolder"
      :is-archive="isArchive"
      :is-bookmarks="isBookmarks"
      :is-recent="isRecent"
      :has-search="!!searchQuery"
      @selected-files-to-upload="$emit('selected-files-to-upload', $event)"
      @update:list="loadArchivedItems"
      @update-has-selected="$emit('update-has-selected', $event)"
      @download-bulk="
        (folderIds: number[], documentIds: number[]) =>
          $emit('download-bulk', folderIds, documentIds)
      "
    />
  </Teleport>

  <DocumentsTable
    :doc-items="filteredItems"
    :selected-folder="selectedFolder"
    :categories-service="categoriesService"
    :tasks-service="tasksService"
    :loading="documentsStore.isLoading"
    :has-error="documentsStore.isError"
    :sort-config="sortConfig"
    :search-query="searchQuery"
    :has-filters="hasFilters"
    :is-archive="isArchive"
    :is-bookmarks="isBookmarks"
    :is-recent="isRecent"
    @files-dropped="(folder, files) => $emit('files-dropped', folder, files)"
    @drag-notify="
      (folder, isDragging) => $emit('drag-notify', folder, isDragging)
    "
    @reset-search="$emit('reset-search')"
    @reset-filters="resetFilters"
    @menu-view-doc="$emit('menu-view-doc', $event)"
    @menu-open-folder="$emit('menu-open-folder', $event)"
    @menu-download="$emit('menu-download', $event)"
    @menu-edit-custom-data="$emit('menu-edit-custom-data', $event)"
    @menu-activity="$emit('menu-activity', $event)"
  />
</template>

<script setup lang="ts">
import { computed, ref, watch } from "vue";

import {
  type Document,
  DocumentsApiService,
  type Folder,
  isDocument,
  type SearchItem,
} from "@app/vue/store/modules/room/documents/DocumentsApiService";
import {
  filterItemsByQuery,
  setTreePosition,
  useDocumentsStore,
} from "@app/vue/store/pinia/room/documents/documents";
import DocumentsBulkActions from "./DocumentsBulkActions.vue";
import DocumentsHeader from "./DocumentsHeader.vue";
import DocumentsTable from "./DocumentsTable.vue";

import type { UploadItem } from "@app/common/file-upload-helpers";
import type { VxeTablePropTypes } from "vxe-table";

interface Props {
  selectedFolderId?: number;
  categoriesService: any;
  tasksService: any;
  searchQuery: string | undefined;
  isRecent?: boolean;
  isBookmarks?: boolean;
  isArchive?: boolean;
}

const props = defineProps<Props>();

interface Emits {
  (e: "files-dropped", folder: Folder | undefined, files: UploadItem[]): void;
  (e: "drag-notify", folder: Folder | undefined, isDragging: boolean): void;
  (e: "reset-search"): void;
  (e: "selected-files-to-upload", files: File[]): void;
  (e: "update-has-selected", value: boolean): void;
  (e: "download-bulk", folderIds: number[], documentIds: number[]): void;

  (e: "menu-view-doc", params: Document): void;
  (e: "menu-open-folder", params: Folder): void;
  (e: "menu-download", params: Document | Folder): void;
  (e: "menu-edit-custom-data", params: Document | Folder): void;
  (e: "menu-activity", params: Document): void;
}

defineEmits<Emits>();

const api = new DocumentsApiService();

const documentsStore = useDocumentsStore();

const sortConfig = computed<VxeTablePropTypes.SortConfigDefaultSort>(() => ({
  field: props.isRecent
    ? "date_updated"
    : props.isBookmarks
      ? "bookmarked"
      : props.searchQuery
        ? (undefined as unknown as string) // do not sort (sorted in search)
        : "tree_index",
  order: (props.isBookmarks ? true : !!props.isRecent) ? "desc" : "asc",
}));

const archivedItems = ref<(Document | Folder)[]>([]);

const loadArchivedItems = () => {
  if (!props.isArchive) return;

  api.getArchived().then((response) => {
    setTreePosition(response);

    archivedItems.value = response;
  });
};

const docItems = computed(() => {
  if (!documentsStore.treeRaw) return [];

  if (props.isBookmarks) {
    const folders = documentsStore.treeRaw.Folders.filter(
      (folder) => folder.bookmarked,
    );

    const files = documentsStore.treeRaw.Files.filter(
      (file) => file.bookmarked,
    );

    return [...folders, ...files];
  }

  if (props.isRecent) {
    return documentsStore.treeRaw.Files;
  }

  if (props.isArchive) return archivedItems.value;

  if (!props.selectedFolderId || !selectedFolder.value) return [];

  if (props.searchQuery)
    return documentsStore.getDescendants(selectedFolder.value);

  return documentsStore.folderItemsByIdMap[props.selectedFolderId];
});

const searchItems = ref<SearchItem[]>([]);

const searchDocuments = () => {
  if (!props.searchQuery) {
    searchItems.value = [];
    return;
  }

  api.searchDocuments(props.searchQuery).then((items) => {
    searchItems.value = items;
  });
};

const selectedFolder = computed(() => {
  if (props.isRecent || props.isBookmarks || props.isArchive) return undefined;

  return documentsStore.treeRaw?.Folders.find(
    (folder) => folder.id === props.selectedFolderId,
  );
});

const filterUploadDate = ref<[Date, Date] | []>([]);

const hasFilters = computed(() => filterUploadDate.value.length !== 0);

const filterByUploadDate = (
  list: (Document | Folder)[],
): (Document | Folder)[] => {
  if (filterUploadDate.value.length === 0) return list;

  const dateStart = filterUploadDate.value[0];
  const dateEnd = filterUploadDate.value[1];

  return list.filter(
    (item) =>
      isDocument(item) &&
      item.date_updated >= dateStart &&
      item.date_updated <= dateEnd,
  );
};

const filterBySearchQuery = (
  list: (Document | Folder)[],
): (Document | Folder)[] => {
  if (props.isArchive) {
    if (props.searchQuery) {
      return filterItemsByQuery(list, props.searchQuery);
    }

    return list;
  }

  if (!props.searchQuery) return list;

  if (searchItems.value.length === 0) return [];

  return list
    .reduce<(Document | Folder)[]>((result, listItem) => {
      const searchItem = searchItems.value.find(
        (item) => item.id === listItem.id && item.type === listItem.type,
      );

      if (searchItem) {
        if (isDocument(listItem)) {
          result.push({
            ...listItem,
            rank: searchItem.rank,
            snippet: searchItem.snippet,
          });
        } else {
          result.push({
            ...listItem,
            rank: searchItem.rank,
          });
        }
      }

      return result;
    }, [])
    .sort((a, b) => a.rank - b.rank);
};

const filteredItems = computed(() => {
  return filterByUploadDate(filterBySearchQuery(docItems.value));
});

const resetFilters = () => {
  filterUploadDate.value = [];
};

watch(() => props.searchQuery, searchDocuments, { immediate: true });

watch(() => props.isArchive, loadArchivedItems, { immediate: true });
</script>
