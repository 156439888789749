<template>
  <DrOverlayEmpty icon="folder-open" :title="title">
    <template #action>
      <template v-if="searchText || hasFilters">
        <ElButton v-if="searchText" @click="$emit('click:reset-search')">
          {{ $t("filters.clear_search_query") }}
        </ElButton>
        <ElButton v-if="hasFilters" @click="$emit('click:reset-filters')">
          {{ $t("filters.reset_filters") }}
        </ElButton>
      </template>

      <slot v-else name="create" />
    </template>
  </DrOverlayEmpty>
</template>

<script setup lang="ts">
import { computed } from "vue";
import { useI18n } from "vue-i18n";

import DrOverlayEmpty from "@app/vue/shared/ui/dr-overlay/DrOverlayEmpty.vue";

interface Props {
  searchText?: string;
  hasFilters?: boolean;
  isBookmarks?: boolean;
  isRecent?: boolean;
  isArchive?: boolean;
}

interface Emits {
  (e: "click:reset-search"): void;
  (e: "click:reset-filters"): void;
}

const props = defineProps<Props>();

defineEmits<Emits>();

const { t } = useI18n();

const title = computed<string>(() => {
  if (props.hasFilters)
    return t("data_room.empty.no_results_matching_criteria");

  if (props.searchText)
    return t("data_room.empty.no_results_for_search_text", {
      search_text: props.searchText,
    });

  if (props.isBookmarks) return t("data_room.empty.bookmarked");

  if (props.isRecent) return t("data_room.empty.recent");

  if (props.isArchive) return t("data_room.empty.archive");

  return t("data_room.empty.documents");
});
</script>
