<template>
  <!-- @vue-expect-error -->
  <DrDatepicker
    ref="pickerRef"
    type="datetime"
    clearable
    style="width: 100%"
    popper-class="dr-popper--inline-control"
    :validate-event="false"
    :placeholder="editProps.schema.placeholder"
    :model-value="modelValue"
    :name="editProps.schema.prop"
    :readonly="editProps.schema.isReadOnly"
    format="MMM D, YYYY h:mm A"
    @visible-change="onVisibleChange"
    @update:model-value="onUpdate"
    @blur="editProps.veeField.onBlur"
  />
</template>

<script setup lang="ts">
import { onMounted, ref, unref } from "vue";
import { DrDatepicker } from "@shared/ui/dr-datepicker";

import type { EditFieldProps } from "@drVue/components/client-dashboard/dynamic-form/Fields/types";
import type CommonPicker from "element-plus/lib/components/time-picker/src/common/picker.vue";

interface Props {
  editProps: EditFieldProps;
}
const props = defineProps<Props>();

const pickerRef = ref<InstanceType<typeof CommonPicker> | null>(null);

const value =
  props.editProps.value instanceof Date
    ? new Date(props.editProps.value)
    : null;

const initialValue = ref(value);
const modelValue = ref(value);

const onUpdate = (value: Date | null) => {
  modelValue.value = value;
  props.editProps.veeField.onChange(value);
};

const onVisibleChange = (isVisible: boolean) => {
  if (!isVisible) {
    const initial = unref(initialValue);
    const model = unref(modelValue);

    if (model && initial && model.getTime() === initial.getTime()) {
      props.editProps.quitEditMode();
      return;
    }

    props.editProps.submitField();
  }
};

onMounted(() => {
  pickerRef.value?.handleOpen();
  pickerRef.value?.focus();
});
</script>
