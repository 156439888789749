<template>
  <DrPopup
    paddingless
    :trigger="popupTrigger"
    @show="focusSearchField"
    @hide="resetModelValue"
  >
    <template #reference="{ show }">
      <slot name="reference" v-bind="{ show }">
        <DrToolbarFilterButton
          v-if="label"
          :label="label"
          :counter="modelValueCount"
          :is-active="!!modelValueCount"
          @clear="clearModelValue"
        />
      </slot>
    </template>

    <template #default="{ hide }">
      <DrPanelWrapper
        :height="POPUP_SIZES.tree.height"
        :width="POPUP_SIZES.tree.width"
      >
        <template #subtitle>
          <ElInput
            ref="searchFieldRef"
            v-model="searchFieldText"
            clearable
            :placeholder="t('shared.search_dots')"
          >
            <template #prefix>
              <DrIcon size="sm" name="search" />
            </template>
          </ElInput>
        </template>

        <template v-if="favorites.length" #favorites>
          <DrTree
            show-checkbox
            check-on-click-node
            default-expand-all
            :data="favorites"
            :model-value="localValueFavorites"
            @update:model-value="updateFavorites"
          >
            <template #item-prefix="{ item, className }">
              <slot name="favorite-item-prefix" v-bind="{ item, className }" />
            </template>
          </DrTree>
        </template>

        <template #default>
          <DrTree
            show-checkbox
            check-on-click-node
            only-leaf-value
            default-expand-all
            :data="tree"
            :filter-text="searchFieldText"
            :is-pending="isPending"
            :model-value="localValueTree"
            @update:model-value="updateTree"
            @reset-filter="searchFieldText = ''"
          >
            <template #item-prefix="{ item, className, node }">
              <slot
                v-if="node.isLeaf"
                name="tree-item-prefix"
                v-bind="{ item, className }"
              />
            </template>
          </DrTree>
        </template>

        <template v-if="!!localValueCount" #footer-left>
          <ElButton
            size="small"
            :class="$style.textBtn"
            @click="clearLocalValue"
          >
            {{ t("shared.clear_selection") }} ({{ localValueCount }})
            <template #icon>
              <DrIcon name="redo" size="sm" />
            </template>
          </ElButton>
        </template>

        <template v-if="isConfirmable" #footer-right>
          <ElButton size="small" @click="resetModelValue(hide)">
            {{ t("shared.cancel") }}
          </ElButton>
          <ElButton
            size="small"
            type="primary"
            :disabled="!isValueChanged"
            @click="submitModelValue(hide)"
          >
            {{ t("shared.save") }}
          </ElButton>
        </template>
      </DrPanelWrapper>
    </template>
  </DrPopup>
</template>

<script lang="ts" setup>
import { ElButton, ElInput } from "element-plus"; // In timeline el-components are required to be explicitly imported
import { difference, without, xor } from "lodash-es";
import { computed, ref, unref, watch } from "vue";
import { POPUP_SIZES } from "@shared/ui/constants";

import { t } from "@app/vue/i18n";
import { DrIcon } from "../dr-icon";
import { DrPanelWrapper } from "../dr-panels";
import { DrPopup } from "../dr-popups";
import { DrTree } from "../dr-tree";
import DrToolbarFilterButton from "./DrToolbarFilterButton.vue";

import type { TreeItem } from "../dr-tree";

type TreeFavoritesItem = Omit<TreeItem, "children">;

interface Props {
  modelValue: TreeItem["id"][];
  label?: string;
  tree: TreeItem[];
  favorites?: TreeFavoritesItem[];
  /** Data is loading/processing/preparing */
  isPending?: boolean;
  isConfirmable?: boolean;
  popupTrigger?: "click" | "focus" | "hover" | "contextmenu";
}
const props = withDefaults(defineProps<Props>(), {
  label: "",
  favorites: () => [],
  isPending: false,
  isConfirmable: false,
  popupTrigger: "click",
});

const emit = defineEmits<{
  (event: "update:model-value", checked: Props["modelValue"]): void;
}>();

const collectIds = (item: TreeItem): TreeItem["id"][] => {
  if (item.children && item.children.length) {
    const subItemIds = item.children.map(collectIds).flat();
    return [item.id].concat(subItemIds);
  }
  return [item.id];
};

const searchFieldRef = ref<HTMLInputElement | null>(null);
const searchFieldText = ref<string>("");

const modelValueCount = computed(() => props.modelValue.length);
const favoritesIds = computed(() => props.favorites.map(({ id }) => id));
const treeIds = computed(() => props.tree.map(collectIds).flat());

const initialValue = ref<TreeItem["id"][]>([...props.modelValue]);

const localValueFavorites = ref<TreeItem["id"][]>(
  initialValue.value.filter((id) => favoritesIds.value.includes(id)),
);
const localValueTree = ref<TreeItem["id"][]>(
  initialValue.value.filter((id) => treeIds.value.includes(id)),
);
watch(
  () => props.modelValue,
  (value) => {
    localValueFavorites.value = value.filter((id) =>
      favoritesIds.value.includes(id),
    );
    localValueTree.value = value.filter((id) => treeIds.value.includes(id));
  },
);

const localValue = computed(
  () => new Set([...localValueFavorites.value, ...localValueTree.value]),
);
const localValueCount = computed(() => localValue.value.size);
const isValueChanged = computed(
  () => !!xor([...localValue.value], props.modelValue).length,
);
const updateFavorites = (value: TreeItem["id"][]) => {
  const oldValue = unref(localValueFavorites);
  localValueFavorites.value = value;

  if (value.length > oldValue.length) {
    const addedIds = difference(value, oldValue);
    const duplicateToTree = addedIds.filter((id) => treeIds.value.includes(id));

    localValueTree.value = [...localValueTree.value, ...duplicateToTree];
  } else {
    const removedIds = difference(oldValue, value);
    const excludeFromTree = removedIds.filter((id) =>
      favoritesIds.value.includes(id),
    );

    localValueTree.value = without(localValueTree.value, ...excludeFromTree);
  }

  if (!props.isConfirmable) {
    submitModelValue();
  }
};
const updateTree = (value: TreeItem["id"][]) => {
  const oldValue = unref(localValueTree);
  localValueTree.value = value;

  if (value.length > oldValue.length) {
    const addedIds = difference(value, oldValue);
    const duplicateToFavorites = addedIds.filter((id) =>
      favoritesIds.value.includes(id),
    );

    localValueFavorites.value = [
      ...localValueFavorites.value,
      ...duplicateToFavorites,
    ];
  } else {
    const removedIds = difference(oldValue, value);
    const excludeFromFavorites = removedIds.filter((id) =>
      favoritesIds.value.includes(id),
    );

    if (excludeFromFavorites.length) {
      localValueFavorites.value = without(
        localValueFavorites.value,
        ...excludeFromFavorites,
      );
    }
  }

  if (!props.isConfirmable) {
    submitModelValue();
  }
};

const focusSearchField = () => {
  searchFieldRef.value?.focus();
};

const resetModelValue = (hidePopup?: () => void) => {
  searchFieldText.value = "";

  if (isValueChanged.value) {
    localValueFavorites.value = props.modelValue.filter((id) =>
      favoritesIds.value.includes(id),
    );
    localValueTree.value = props.modelValue.filter(
      (id) => !favoritesIds.value.includes(id),
    );
  }

  if (typeof hidePopup === "function") {
    hidePopup();
  }
};

const clearModelValue = () => {
  emit("update:model-value", []);
};
const clearLocalValue = () => {
  localValueFavorites.value = [];
  localValueTree.value = [];
};

const submitModelValue = (hidePopup?: () => void) => {
  if (isValueChanged.value) {
    emit("update:model-value", [...localValue.value]);
  }

  if (hidePopup) hidePopup();
};
</script>

<style lang="scss" module>
@use "@app/styles/scss/typography" as typo;

.textBtn:global(.el-button) {
  --el-button-bg-color: transparent;
  --el-button-border-color: transparent;
  --el-button-disabled-bg-color: transparent;
  --el-button-disabled-border-color: transparent;
  font: typo.$body_regular;
}
</style>
