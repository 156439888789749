<template>
  <div :class="$style.wrapper">
    <div :class="$style.header">
      <DrAvatar
        :name="author.name"
        :class="$style.headerAvatar"
        :url="author.avatar.thumbnail"
        v-if="!saved"
      />
      {{ qa.question }}
      <div :class="$style.headerActions">
        <ElPopconfirm
          title="Are you sure to delete this question?"
          :hide-icon="true"
          :width="200"
          :confirm-button-type="'warning'"
          @confirm="onConfirmDelete"
        >
          <template #reference>
            <ElButton text size="small">
              <i class="fas fa-trash" />
            </ElButton>
          </template>
        </ElPopconfirm>
        <SaveQAPopover v-if="!saved" :qa="qa" />
      </div>
    </div>
    <div
      :class="$style.genAnswer"
      v-if="
        qa.status == DocVerQAStatuses.PENDING ||
        qa.status == DocVerQAStatuses.PROCESSING
      "
    >
      Generating answer ...
    </div>
    <div v-if="qa.status == DocVerQAStatuses.SUCCESS">
      <div :class="$style.shortAnswer">
        {{ parsedAnswer.shortAnswer }}
      </div>
      <ElLink
        v-if="
          !showAll &&
          (parsedAnswer.explanation || parsedAnswer.excerpts?.length)
        "
        :class="$style.toggleExtraBtn"
        @click="expand"
        :underline="false"
        type="primary"
      >
        Show more
      </ElLink>
      <div v-if="showAll" :class="$style.extraBlock">
        <div :class="$style.extraBlockTitle">Explanation:</div>
        {{ parsedAnswer.explanation }}
      </div>
      <div
        v-if="
          showAll && parsedAnswer.excerpts && parsedAnswer.excerpts.length !== 0
        "
        :class="$style.extraBlock"
      >
        <div :class="$style.extraBlockTitle">Excerpts:</div>
        <div v-for="(item, index) in parsedAnswer.excerpts" :key="index">
          • {{ item.snippet }}
          <a
            v-if="item.page"
            :href="getExcerptLink(item.page)"
            @click="(e) => emitPageNavigation(e, item.page)"
            >Page {{ item.page }}</a
          >
        </div>
      </div>
      <ElLink
        :class="$style.toggleExtraBtn"
        @click="showAll = false"
        :underline="false"
        v-if="showAll"
        type="primary"
      >
        Show less
      </ElLink>
      <Review
        :qa-id="qa.id"
        :doc-uid="props.docUid"
        :review="qa.review"
        v-if="!parsedAnswer.isError"
        @submitted="onReviewSubmitted"
      />
    </div>
  </div>
</template>

<script lang="ts" setup>
import { computed, ref } from "vue";
import { DrAvatar } from "@shared/ui/dr-avatar";

import { ROOM_DATA } from "@app/setups";
import { documentViewUrl } from "@app/setups/room-urls";
import { DrStore } from "@app/vue";
import Review from "./Review.vue";
import SaveQAPopover from "./SaveQAPopover.vue";
import { type AiValue, type DocVerQA, DocVerQAStatuses } from "./types";
import { usePageNavigationBus } from "./usePageNavigationBus";

interface Props {
  qa: DocVerQA;
  docUid: string;
  docId: number;
  saved: boolean;
}

interface Events {
  (
    e: "review-submitted",
    payload: { qa_id: DocVerQA["id"]; review: DocVerQA["review"] },
  ): void;
  (e: "expanded", payload: { qa_id: DocVerQA["id"] }): void;
  (e: "delete", payload: { qa_id: DocVerQA["id"] }): void;
}

const props = defineProps<Props>();
const emit = defineEmits<Events>();
const pageNavigationBus = usePageNavigationBus();

const showAll = ref(false);
const author = computed(() => {
  return (
    DrStore.state.room.members.membersByUid[props.qa.author_id] ?? {
      name: "Anonymous",
      avatar: { thumbnail: null },
    }
  );
});

const onReviewSubmitted = (payload: { review: DocVerQA["review"] }) => {
  emit("review-submitted", { qa_id: props.qa.id, review: payload.review });
};

const expand = () => {
  emit("expanded", { qa_id: props.qa.id });
  showAll.value = true;
};

const parsedAnswer = computed((): AiValue => {
  const parsed = props.qa.answer as unknown as AiValue;
  return parsed;
});

const emitPageNavigation = (e: MouseEvent, page: number) => {
  if ((e.metaKey || e.ctrlKey) && e.button === 0) return;
  if (e.button !== 0) return;

  e.preventDefault();

  pageNavigationBus.emit(undefined as never, {
    page,
    docId: props.docId,
  });
};

const onConfirmDelete = () => {
  emit("delete", { qa_id: props.qa.id });
};

const getExcerptLink = (page: number) =>
  documentViewUrl(ROOM_DATA.url, props.docId, undefined, page);
</script>

<style lang="scss" module>
@use "sass:color";

@use "@app/styles/scss/colors";
@use "@app/styles/scss/spacing";
@use "@app/styles/scss/typography";

.wrapper {
  width: 100%;
}

.header {
  font: typography.$body_semibold;
  display: flex;
  align-items: center;
  gap: spacing.$xs;
}

.headerActions {
  margin-left: auto;
  opacity: 0;
  display: flex;

  :global(.el-button:hover .fa-trash) {
    color: color.adjust(colors.$ad-12, $lightness: -10%);
  }
}

.toggleExtraBtn {
  padding-bottom: spacing.$s;
}

.wrapper:hover .headerActions {
  opacity: 1;
}

.headerAvatar {
  flex-shrink: 0;
}

.genAnswer {
  margin-top: spacing.$s;
}

.shortAnswer {
  padding-top: spacing.$xs;
  padding-bottom: spacing.$xxs;
  font: typography.$body_regular;
}
.extraBlock {
  padding-top: spacing.$xs;
  font: typography.$body_regular;
  white-space: pre-wrap;
}
.extraBlockTitle {
  font-weight: typography.$font-weight-medium;
}
</style>
