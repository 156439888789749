<template>
  <DrVxeGrid
    :loading="isLoading"
    :data="filteredUsers"
    :columns="tableColumns.columns"
    :scroll-y="{ enabled: true, gt: 50 }"
    @cell-click="onCellClick"
  >
    <template #empty>No members found</template>
  </DrVxeGrid>
  <UserModal ref="userModal" />
</template>

<script lang="ts">
import { orderBy } from "lodash-es";
import { defineComponent } from "vue";
import DrVxeGrid from "@shared/ui/dr-vxe-grid";

import { matchText } from "@drVue/common";
import UserModal from "@drVue/components/client-dashboard/users/UserDetails";
import DrStore from "@drVue/store";
import TableColumns from "./tableColumns";

import type {
  ContactInfo,
  MembershipInfo,
  UserViewModel,
} from "@drVue/components/client-dashboard/users/UsersTable/types";
import type {
  Deal,
  Room,
  RoomGroup,
} from "@drVue/store/modules/client-dashboard/deals/types";
import type {
  CustomDataType,
  ExtraFieldFormatted,
} from "@drVue/store/modules/client-dashboard/fields/types";
import type { UsersFilters } from "@drVue/store/modules/client-dashboard/filters/types";
import type { OrgUser } from "@drVue/store/modules/client-dashboard/org-users/types";
import type { Dictionary } from "@drVue/types";
import type { PropType } from "vue";

interface Data {
  tableColumns: any;
}

export default defineComponent({
  name: "UsersTable",
  components: {
    UserModal,
    DrVxeGrid,
  },
  props: {
    searchQuery: {
      required: false,
      default: null,
      type: String as PropType<string | null>,
    },
  },
  emits: ["counted"],
  data(): Data {
    return {
      tableColumns: new TableColumns(),
    };
  },
  computed: {
    usersFilters(): UsersFilters {
      return this.$store.state.clientDashboard.filters.users;
    },
    filteredUsers(): UserViewModel[] {
      return this._filterUsers(this.allUsers);
    },
    allUsers(): UserViewModel[] {
      const roomsOfOrgUser =
        this.$store.getters[
          "clientDashboard/deals/getMapForActiveRoomsOfOrgUser"
        ];

      const dealsByContactId =
        this.$store.getters["clientDashboard/deals/getMapForDealsOfContact"];

      const dealsByRoomId =
        this.$store.state.clientDashboard.deals.items.reduce((acc, deal) => {
          if (deal.room) acc[deal.room.id] = deal;
          return acc;
        }, {} as Dictionary<Deal>);

      const roomsById = this.$store.state.clientDashboard.deals.items.reduce(
        (acc, d) => {
          const room = d.room;
          if (!room || !room.members) return acc;

          acc[room.id] = {
            room: room,
            roomGroupByUserId: room.members.reduce((acc, m) => {
              acc[m.user_id] = room.groups.find((g) => g.id === m.group_id)!;
              return acc;
            }, {} as Dictionary<RoomGroup>),
          };
          return acc;
        },
        {} as Dictionary<{
          room: Room;
          roomGroupByUserId: Dictionary<RoomGroup>;
        }>,
      );

      const usersVm = this.$store.state.clientDashboard.orgUsers.items.map(
        (u: OrgUser): UserViewModel => {
          const userRooms: Room[] =
            (roomsOfOrgUser && roomsOfOrgUser[u.id]) || [];

          const membershipsInfo = orderBy(
            userRooms.map((room) => {
              const group = roomsById[room.id].roomGroupByUserId[u.id];
              const deal = dealsByRoomId[room.id];

              return {
                key: `${u.id}-member-in-room_${room.id}`,
                roomGroupId: group.id,
                roomGroupName: group.name,
                dealId: deal.id,
                dealUid: deal.uid,
                dealTitle: deal.title,
                dealLogo: deal.room?.logo,
              } as MembershipInfo;
            }),
            [
              (u) => u.dealTitle.toLowerCase(),
              (u) => u.roomGroupName.toLowerCase(),
            ],
          );

          const contactRooms: Deal[] = dealsByContactId[u.id] || [];

          const contactsInfo = contactRooms.map((deal): ContactInfo => {
            return {
              key: `${u.id}-contact-in-deal_${deal.id}`,
              dealId: deal.id,
              dealUid: deal.uid,
              dealTitle: deal.title,
              dealLogo: deal.room?.logo,
            };
          });

          return {
            key: !u.pending ? u.id : `invite-${u.id}`,
            user: u,
            custom_data: u.custom_data,
            customData: this._formatCustomData(u),
            membershipsInfo,
            contactsInfo,
          };
        },
      );
      return usersVm;
    },
    isLoading(): any {
      return (
        DrStore.state.clientDashboard.orgUsers.isLoading ||
        DrStore.state.clientDashboard.deals.isLoading
      );
    },
    customFields(): any {
      return this.$store.getters["clientDashboard/customFields/byObjectType"](
        "user",
      );
    },
  },
  methods: {
    _formatCustomData(user: OrgUser): CustomDataType {
      const customData: CustomDataType = {};
      const formatFields =
        this.$store.getters["clientDashboard/customFields/formattedFields"];

      formatFields("user", user.custom_data).forEach(
        (field: ExtraFieldFormatted) => {
          customData[field.key] = field.value;
        },
      );

      return customData;
    },
    _filterUsers(usersVm: UserViewModel[]): UserViewModel[] {
      //filter
      //todo: debounce/trigger loading spinner
      if (this.searchQuery) {
        usersVm = usersVm.filter((uvm) =>
          matchText(
            this.searchQuery || "",
            uvm.user,
            // todo: add searching by membershipsinfo
            ["name", "email", "title", "company", "office_number"],
          ),
        );
      }

      if (this.usersFilters.group) {
        usersVm = usersVm.filter((uvm) =>
          uvm.membershipsInfo.some(
            (mi) => mi.roomGroupId === this.usersFilters.group!.id,
          ),
        );
      }

      if (this.usersFilters.room) {
        const roomMembers = this.usersFilters.room.members;
        usersVm = usersVm.filter((uvm) =>
          roomMembers.some((rm) => rm.user_id === uvm.user.id),
        );
      }

      this.$emit("counted", usersVm.length);

      return usersVm;
    },
    onCellClick({ row, column }: { $event: any; row: any; column: any }) {
      const k = column.getKey();
      if (
        k === "rooms" ||
        k === "user.rooms" ||
        k === "email" ||
        k === "office_number"
      ) {
        return;
      }

      const modal = this.$refs.userModal as any;
      modal.show(row.user);
    },
  },
});
</script>
