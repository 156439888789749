<template>
  <div :class="$style.container">
    <ElCheckbox
      :model-value="isChecked"
      :indeterminate="isIndeterminate"
      :disabled="isDisabled"
      :class="$style.checkbox"
      @change="$emit('change', !isChecked)"
    />
    <div :class="$style.title">
      {{ title }}
    </div>
    <div
      ref="addCategoryRef"
      class="dr-vxe-cell__content-row-hover-visible"
      :class="$style.addCategoryLink"
      @click="handleAddCategory"
    >
      {{ $t("permissions.requests.add_worklist") }}
      <DrIcon size="xs" name="plus" :class="$style.addCategoryLinkIcon" />
    </div>
  </div>
</template>

<script lang="ts" setup>
import { ref } from "vue";
import { DrIcon } from "@shared/ui/dr-icon";

interface Props {
  title: string;
  isChecked: boolean;
  isIndeterminate: boolean;
  isDisabled?: boolean;
}

interface Emit {
  (event: "change", value: boolean): void;
  (event: "add-category", ref: HTMLElement): void;
}

defineProps<Props>();
const emit = defineEmits<Emit>();

const addCategoryRef = ref<HTMLElement | null>(null);

const handleAddCategory = () => {
  if (addCategoryRef.value) {
    emit("add-category", addCategoryRef.value);
  }
};
</script>

<style lang="scss" module>
@use "@app/styles/scss/colors";
@use "@app/styles/scss/typography" as typo;

.title {
  flex: 1 1 auto;
  font: typo.$body_regular;
  color: colors.$pr-900;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.container {
  display: flex;
  align-items: center;
  gap: 6px;
}

.checkbox :global {
  .el-checkbox__inner {
    width: 16px;
    height: 16px;
  }

  .el-checkbox__inner:after {
    height: 10px;
    top: 1px;
    width: 5px;
    left: 5px;
  }

  .el-checkbox__input.is-indeterminate .el-checkbox__inner::before {
    top: 6px;
  }
}

.addCategoryLink {
  display: flex;
  align-items: center;
  gap: 4px;
  font: typo.$body_medium;
  font-weight: 400;
  color: colors.$pr-900;
}

.addCategoryLinkIcon {
  color: colors.$pr-500;
}
</style>
