<template>
  <div :class="$style.layout">
    <div v-if="title" :class="$style.title">{{ title }}</div>

    <slot name="subtitle" />

    <div v-if="useSearch || action" :class="$style.toolbar">
      <div :class="$style.toolbarSection">
        <slot name="toolbar">
          <DrToolbarFilterSearch
            v-model="innerSearch"
            @update:model-value="emit('search', $event)"
          />
        </slot>
      </div>
      <div :class="$style.toolbarSection">
        <slot name="toolbar-right">
          <ElDropdown
            v-if="action?.menu?.length"
            trigger="click"
            @command="emit('action-menu', $event)"
          >
            <ElButton :class="$style.menuBtn">
              <DrIcon name="ellipsis-h" size="lg" />
            </ElButton>
            <template #dropdown>
              <ElDropdownMenu>
                <ElDropdownItem
                  v-for="item in action.menu"
                  :key="item.id"
                  :command="item.id"
                >
                  {{ item.label }}
                </ElDropdownItem>
              </ElDropdownMenu>
            </template>
          </ElDropdown>

          <ElButton
            v-if="action"
            ref="actionBtnRef"
            :type="action.type || 'primary'"
            icon-right
            :class="$style.action"
            @click="handleEmitAction"
          >
            {{ action.label }}
            <template v-if="action.icon" #icon>
              <DrIcon
                size="sm"
                :name="action.icon"
                :class="$style.actionIcon"
              />
            </template>
          </ElButton>
        </slot>
      </div>
    </div>

    <div
      v-if="flexBody"
      :class="{ [$style.body]: true, [$style.body_isFlex]: flexBody }"
    >
      <ElScrollbar max-height="100%">
        <slot name="default" />
      </ElScrollbar>
    </div>
    <div v-else :class="$style.body">
      <slot name="default" />
    </div>
  </div>
</template>

<script setup lang="ts">
import { ElButton } from "element-plus";
import { computed, ref, watch } from "vue";
import { DrIcon, type IconName } from "@shared/ui/dr-icon";
import { DrToolbarFilterSearch } from "@shared/ui/dr-toolbar";

export interface PageToolbarAction {
  label: string;
  icon?: IconName;
  type?: "default" | "primary";
  menu?: {
    id: string;
    label: string;
  }[];
}

interface Props {
  title?: string;
  search?: string;
  action?: PageToolbarAction;
  flexBody?: boolean;
}

interface Emits {
  (event: "search", phrase: string): void;
  (event: "action", reference: HTMLElement): void;
  (event: "action-menu", id: string): void;
}

const {
  title = "",
  search = undefined,
  flexBody = false,
} = defineProps<Props>();
const emit = defineEmits<Emits>();

const actionBtnRef = ref<InstanceType<typeof ElButton> | null>(null);

const useSearch = computed(() => search !== undefined);
const innerSearch = ref(search || "");
watch(
  () => search,
  (value?: string) => {
    if (innerSearch.value !== value) {
      innerSearch.value = value ?? "";
    }
  },
);

const handleEmitAction = () => {
  if (actionBtnRef.value?.$el) {
    emit("action", actionBtnRef.value.$el);
  }
};
</script>

<style lang="scss" module>
@use "@app/styles/scss/colors";
@use "@app/styles/scss/spacing";
@use "@app/styles/scss/typography" as typo;

.layout {
  display: flex;
  flex-direction: column;
  height: 100%;
  max-height: 100%;
}

.title {
  flex: 0 0 auto;
  font: typo.$title_bold;
}

.menuBtn:global(.el-button) {
  padding-left: 6px;
  padding-right: 6px;
  width: 32px;
}

.toolbar {
  flex: 0 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: spacing.$xs;
  padding: 0 0 spacing.$m;

  .title + & {
    padding-top: spacing.$s;
  }
}

.toolbarSection {
  position: relative;
  height: 100%;
  display: grid;
  grid-auto-flow: column;
  align-items: center;
  gap: spacing.$xs;
  justify-content: start;
  white-space: nowrap;
  // to display the outline correctly when the buttons have focus
  margin-left: -8px;
  padding-left: 8px;

  :global {
    .el-button + .el-button {
      margin-left: 0;
    }
  }

  & + & {
    // to display the outline correctly when the buttons have focus
    margin-left: 0;
    margin-right: -8px;
    padding-right: 8px;
  }
}

.actionIcon {
  color: colors.$pr-500;
}

.action:global(.el-button--primary) {
  .actionIcon {
    color: colors.$pr-0;
  }
}

.body {
  position: relative;
  height: 100%;
  width: 100%;
  min-width: 0;
  overflow: hidden;
  border: 1px solid colors.$pr-150;
  border-radius: 7px;

  .title + & {
    margin-top: spacing.$s;
  }
}

.body_isFlex {
  border: none;
  border-radius: 0;

  :global(.el-scrollbar__bar.is-vertical) {
    right: 0px;
  }
}
</style>
