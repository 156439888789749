<template>
  <template v-if="folder.parent_id">
    <FolderBreadcrumbsItem
      :folder="documentsStore.folderByIdMap[folder.parent_id]"
      :selectable="selectable"
      not-first
      @select-folder="$emit('select-folder', $event)"
    />
    <DrIcon name="angle-right" :class="$style.icon" />
  </template>
  <component
    :is="selectable && notFirst ? 'a' : 'span'"
    :class="{
      [$style.text]: true,
      [$style.text_isLight]: notFirst,
      [$style.text_isSelectable]: selectable && notFirst,
    }"
    @click="selectable && notFirst && $emit('select-folder', folder)"
  >
    {{ text }}
  </component>
</template>

<script lang="ts" setup>
import { computed } from "vue";

import DrIcon from "@app/vue/shared/ui/dr-icon/DrIcon.vue";
import { useDocumentsStore } from "@app/vue/store/pinia/room/documents/documents";

import type { Folder } from "@app/vue/store/modules/room/documents/DocumentsApiService";

interface Props {
  folder: Folder;
  notFirst?: boolean;
  selectable?: boolean;
}

interface Emits {
  (e: "select-folder", value: Folder): void;
}

const props = defineProps<Props>();

defineEmits<Emits>();

const documentsStore = useDocumentsStore();

const text = computed(() => {
  const { index, tree_index, name } = props.folder;
  return `${index ? tree_index + ". " : ""}${name}`;
});
</script>

<style lang="scss" module>
@use "@app/styles/scss/colors";

.text {
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  color: colors.$pr-900;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  &.text_isLight {
    font-weight: 400;
    color: colors.$pr-500;
  }

  &.text_isSelectable:hover {
    color: colors.$pr-700;
    cursor: pointer;
    user-select: none;
  }
}

.icon {
  color: colors.$pr-500;
  font-size: 14px;
}
</style>
