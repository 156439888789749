<template>
  <div :class="$style.container">
    <div :class="$style.content">
      <div :class="$style.textBox">
        <span :class="$style.header"> Business Intelligence reports </span>
        <span :class="$style.body">
          Experience a comprehensive reporting solution with our BI Reports,
          designed to deliver actionable insights and facilitate data-driven
          decision-making across all stages of your deals. Unlock the full
          potential of your pipeline, diligence, and integration data.
        </span>
        <div>
          <ElButton type="primary" @click="handleContactSupport">
            Contact Support
          </ElButton>
        </div>
      </div>
      <div :class="$style.tilesBox">
        <div :class="[$style.tileBase, $style.tile1]">
          <svg width="165" height="155" viewBox="0 0 165 155">
            <circle :class="$style.donut1" cx="77" cy="77" r="77" />
            <circle :class="$style.donut2" cx="77" cy="77" r="38" />
            <circle :class="$style.donut3" cx="77" cy="77" r="38" />
            <circle :class="$style.donut4" cx="77" cy="77" r="38" />
            <circle :class="$style.donutHole" cx="77" cy="77" r="38" />
          </svg>
          <div>
            <ul>
              <li :class="$style.listItem">
                <div :class="[$style.bulletBase, $style.bullet1]" />
                <span>"Acquisition" 10%</span>
              </li>
              <li :class="$style.listItem">
                <div :class="[$style.bulletBase, $style.bullet2]" />
                <span>"Alliance" 42%</span>
              </li>
              <li :class="$style.listItem">
                <div :class="[$style.bulletBase, $style.bullet3]" />
                <span>"JV" 23%</span>
              </li>
              <li :class="$style.listItem">
                <div :class="[$style.bulletBase, $style.bullet4]" />
                <span>"Divestiture" 25%</span>
              </li>
            </ul>
          </div>
        </div>
        <div :class="[$style.tileBase, $style.tile2]">
          <ul :class="$style.tile2List">
            <li>United State</li>
            <li>England</li>
            <li>Singapore</li>
            <li>France</li>
            <li>Japan</li>
          </ul>
          <div>
            <svg width="230" height="140" viewBox="0 0 230 140">
              <line :class="$style.tile2Line" x1="1" y1="10" x2="1" y2="140" />
              <line
                :class="$style.tile2Line"
                x1="40"
                y1="10"
                x2="40"
                y2="140"
              />
              <line
                :class="$style.tile2Line"
                x1="80"
                y1="10"
                x2="80"
                y2="140"
              />
              <line
                :class="$style.tile2Line"
                x1="120"
                y1="10"
                x2="120"
                y2="140"
              />
              <line
                :class="$style.tile2Line"
                x1="160"
                y1="10"
                x2="160"
                y2="140"
              />
              <line
                :class="$style.tile2Line"
                x1="200"
                y1="10"
                x2="200"
                y2="140"
              />
              <rect
                :class="$style.tile2Bar"
                x="1"
                y="10"
                width="230"
                height="20"
              />
              <rect
                :class="$style.tile2Bar"
                x="1"
                y="35"
                width="130"
                height="20"
              />
              <rect
                :class="$style.tile2Bar"
                x="1"
                y="60"
                width="93"
                height="20"
              />
              <rect
                :class="$style.tile2Bar"
                x="1"
                y="85"
                width="70"
                height="20"
              />
              <rect
                :class="$style.tile2Bar"
                x="1"
                y="110"
                width="17"
                height="20"
              />
            </svg>
          </div>
        </div>
        <div />
      </div>
      <svg
        :class="$style.svgViewBox"
        width="455"
        height="480"
        viewBox="0 0 455 480"
      >
        <polygon :class="$style.layer1" points="0,0 455,0 455,480 190,480" />
        <polygon :class="$style.layer2" points="131,0 455,0 455,480 283,480" />
        <polygon :class="$style.layer3" points="293,0 455,0 455,480 378,480" />
      </svg>
    </div>
  </div>
</template>

<script setup lang="ts">
import { APP_SETTINGS } from "@app/setups";

const handleContactSupport = () => {
  const a = document.createElement("a");
  a.href = "mailto:" + APP_SETTINGS.CONTACT_EMAIL;
  a.click();
};
</script>

<style module lang="scss">
@use "@app/styles/scss/colors";
@use "@app/styles/scss/typography.scss";

.container {
  display: grid;
  height: 100%;
  background: colors.$pr-50;
}

.content {
  display: grid;
  grid-template-columns: 533px auto;
  place-self: center;
  width: 988px;
  height: 480px;
  border-radius: 12px;
  background: colors.$pr-0;
  position: relative;
  filter: drop-shadow(0px 13px 177px rgba(0, 0, 0, 0.1));
}

.textBox {
  display: grid;
  padding: 96px 0 101px 69px;
  align-items: center;
}

.header {
  font: typography.$headline_3_medium;
}

.body {
  font: typography.$body_regular;
}

.tilesBox {
  position: absolute;
  left: 518px;
}

.tileBase {
  width: 360px;
  height: 200px;
  background: colors.$pr-0;
  border: 1px solid colors.$pr-150;
  border-radius: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  filter: drop-shadow(0px 13px 177px rgba(0, 0, 0, 0.1));
}

.tile1 {
  position: relative;
  top: 33px;
  display: grid;
  grid-template-columns: auto auto;
}

.donut1 {
  fill: colors.$sc-600;
}

.donut2 {
  fill: transparent;
  stroke: #25b5ca;
  stroke-width: 78;
  stroke-dasharray: 200;
  stroke-dashoffset: 25;
}

.donut3 {
  fill: transparent;
  stroke: #e32d92;
  stroke-width: 78;
  stroke-dasharray: 90 130;
  stroke-dashoffset: 25;
}

.donut4 {
  fill: transparent;
  stroke: #f7aa29;
  stroke-width: 78;
  stroke-dasharray: 90 180;
  stroke-dashoffset: 25;
}

.donutHole {
  fill: colors.$pr-0;
}

.listItem {
  display: flex;
  align-items: center;
}

.bulletBase {
  width: 10px;
  height: 10px;
  border-radius: 5px;
  margin-right: 5px;
}

.bullet1 {
  background: colors.$sc-600;
}

.bullet2 {
  background: #25b5ca;
}

.bullet3 {
  background: #e32d92;
}

.bullet4 {
  background: #f7aa29;
}

.tile2 {
  position: relative;
  top: 50px;
  left: 44px;
}

.tile2Bar {
  fill: colors.$sc-600;
}

.tile2Line {
  stroke: colors.$pr-200;
}

.tile2List {
  margin-right: 10px;
  li {
    height: 25px;
  }
}

.svgViewBox {
  border-top-right-radius: 12px;
  border-bottom-right-radius: 12px;
}

.layer1 {
  fill: colors.$sc-600;
}

.layer2 {
  fill: #3b75f0;
}

.layer3 {
  fill: #4a84fe;
}
</style>
