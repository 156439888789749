import { defineStore } from "pinia";
import { computed } from "vue";

import { useSelected } from "@app/vue/store/pinia/useSelected";
import DrStore from "@drVue/store";

import type { Group } from "@drVue/store/modules/room/groups/GroupsApiService";

export const useGroupsArchiveTableStore = defineStore(
  "groupsArchiveTable",
  () => {
    const groupsList = computed(
      () => DrStore.state.room.groups.archivedPgroupsList,
    );

    const {
      selectedDict: selectedGroupsIds,
      selectedList: selectedGroupsIdsList,
      toggle: toggleGroup,
      select: selectGroup,
      unselect: unselectGroup,
    } = useSelected<Group, "id">();

    const selectedGroups = computed(() =>
      groupsList.value.filter((group) => selectedGroupsIds[group.id]),
    );

    const toggleAll = () => {
      const totalSelectedCount = selectedGroupsIdsList.value.length;

      const toSelect =
        totalSelectedCount === 0 ||
        totalSelectedCount < groupsList.value.length;
      groupsList.value.forEach((group) => {
        toSelect ? selectGroup(group.id) : unselectGroup(group.id);
      });
    };

    const clearSelection = () => {
      // We have `checkField` set to `_isChecked` in `GroupsTable.vue`.
      // Vxe Table monkey-patches the data, so we need to remove the patch.
      // Sad, but true.
      for (const group of groupsList.value) {
        if ("_isChecked" in group) delete group._isChecked;
      }

      selectedGroupsIdsList.value.forEach((groupId) => unselectGroup(groupId));
    };

    return {
      selectedGroups,
      selectedGroupsIds,
      selectedGroupsIdsList,
      toggleGroup,
      selectGroup,
      unselectGroup,
      toggleAll,
      clearSelection,
    };
  },
);

export type GroupsArchiveTableStore = ReturnType<
  typeof useGroupsArchiveTableStore
>;
