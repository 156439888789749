<template>
  <EditFieldContainerWithButtons
    :is-form-submitting="editProps.isFormSubmitting"
    @save="editProps.submitField"
    @discard="editProps.quitEditModeConfirm"
  >
    <div
      :class="{
        [$style.wrapper]: true,
        [$style.wrapper_isFocused]: isFocused,
      }"
    >
      <DrEditor
        ref="editorRef"
        editable
        :model-value="editProps.value"
        :options="{
          showMenuOnFocus: false,
          placeholder: t('form.write'),
        }"
        @focus="handleFocus"
        @update:model-value="editProps.veeField.onInput"
      />
    </div>
  </EditFieldContainerWithButtons>
</template>

<script lang="ts" setup>
import { onMounted, ref } from "vue";
import { useI18n } from "vue-i18n";
import DrEditor from "@shared/ui/editor";

import EditFieldContainerWithButtons from "@drVue/components/client-dashboard/dynamic-form/Fields/Edit/EditFieldContainerWithButtons.vue";

import type { EditFieldProps } from "@drVue/components/client-dashboard/dynamic-form/Fields/types";
import type { Node as ProsemirrorNode } from "@tiptap/pm/model";

interface Props {
  editProps: EditFieldProps<ProsemirrorNode>;
}

defineProps<Props>();

const { t } = useI18n();

const isFocused = ref(false);
const editorRef = ref();

const handleFocus = () => {
  isFocused.value = true;
};

onMounted(() => {
  editorRef.value?.focus();
});
</script>

<style lang="scss" module>
@use "@app/styles/scss/colors";
@use "@app/styles/scss/typography";
@use "@app/styles/scss/values";

.wrapper {
  width: 100%;
  min-height: values.$base-input-height;
  border: 1px solid colors.$pr-300;
  border-radius: values.$base-border-radius;

  :global {
    .dr-editor__wrapper {
      border: none;
      border-radius: values.$base-border-radius;
    }

    .dr-editor__content > .ProseMirror {
      min-height: 24px;
    }
  }
}

.wrapper_isFocused {
  border-color: colors.$sc-500;
  box-shadow: 0 0 0 3px rgba(colors.$sc-200-rgb, 0.2);
}

.wrapper_isReadonly {
  :global(.dr-editor__wrapper) {
    padding: 6px 12px;
    color: colors.$pr-400;
  }
}
</style>
