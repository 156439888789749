<template>
  <DrVxeGrid
    :sort-config="{ trigger: 'cell', defaultSort }"
    :columns="tableColumns.columns"
    :data="tableItems"
    :tree-config="{
      indent: 18,
      expandAll: false,
      childrenField: 'items',
    }"
    :class="$style.table"
  />
</template>

<script setup lang="ts">
import { computed, onBeforeMount, ref, toRef } from "vue";
import DrVxeGrid from "@shared/ui/dr-vxe-grid";

import { DrStore } from "@app/vue";
import { type DocsItem } from "@app/vue/store/modules/room/documents/DocumentsApiService";
import {
  filterItemsByQuery,
  useDocumentsStore,
} from "@app/vue/store/pinia/room/documents/documents";
import { useFilePermissionsStore } from "@app/vue/store/pinia/room/filePermissions/filePermissions";
import TableColumns from "./tableColumns";

import type { CustomViewColumn } from "@app/setups/types";
import type { Group } from "@app/vue/store/modules/room/groups/GroupsApiService";
import type { VxeTablePropTypes } from "vxe-table";

interface Props {
  group: Group | null;
  search: string;
}

const props = defineProps<Props>();

const viewConfig = computed<CustomViewColumn[]>(() => []);

const defaultSort = ref<VxeTablePropTypes.SortConfigDefaultSort>({
  field: "tree_index",
  order: "asc",
});

const documentsStore = useDocumentsStore();
const filePermissionsStore = useFilePermissionsStore();

const hasSearch = computed<boolean>(() => props.search !== "");

const items = computed<DocsItem[]>(() => {
  if (!documentsStore.rootFolder) return [];

  return documentsStore.folderItemsByIdMap[documentsStore.rootFolder.id];
});

const itemsSearch = computed<DocsItem[]>(() => {
  if (!props.search || !documentsStore.treeRaw) return [];

  return filterItemsByQuery(
    [...documentsStore.treeRaw.Folders, ...documentsStore.treeRaw.Files],
    props.search,
  );
});

const tableItems = computed<DocsItem[]>(() => {
  if (props.search) return itemsSearch.value;
  else return items.value;
});

const groupsColumns = computed(() =>
  DrStore.state.room.groups.pgroupsList.slice().sort((groupA, groupB) => {
    if (groupA.builtin_admin_pgroup) return -1;
    if (groupB.builtin_admin_pgroup) return 1;
    if (groupA.is_administrator) return -1;
    if (groupB.is_administrator) return 1;
    return 0;
  }),
);

const tableColumns = new TableColumns(
  viewConfig,
  toRef(props, "group"),
  groupsColumns,
  toRef(documentsStore, "rootFolder"),
  hasSearch,
  itemsSearch,
);

onBeforeMount(() => {
  documentsStore.syncTree();
  filePermissionsStore.refetchPermissions();
});
</script>

<style lang="scss" module>
.table {
  --vxe-ui-table-column-padding-default: 0;
  --vxe-ui-table-row-height-default: 38px;

  :global {
    .vxe-table--header-wrapper,
    .vxe-header--column {
      background: none !important;
    }

    .vxe-header--column {
      vertical-align: bottom;

      .vxe-cell {
        padding-bottom: 4px !important;
      }
    }
  }
}
</style>
