<template>
  <div ref="dropZoneRef" :class="$style.dropZone">
    <div v-if="isUploading" :class="$style.dropZoneUploading">
      <span>{{ uploadingLabel }}</span>
    </div>

    <div v-else-if="isOverDropZone" :class="$style.dropZoneOverlay">
      <DrIcon name="download" size="lg" />
      <div v-for="label in highlightLabel" :key="label">
        {{ label }}
      </div>
    </div>

    <div
      :class="{
        [$style.content_isHidden]: isOverDropZone || isUploading,
      }"
    >
      <slot name="custom">
        <div :class="$style.content">
          <slot name="default">
            <span v-for="label in defaultLabel" :key="label">{{ label }}</span>
          </slot>
        </div>
      </slot>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { ref } from "vue";
import { DrIcon } from "@shared/ui/dr-icon";

import { useDropZone } from "@drVue/utils/useDropZone";

import type { UploadItem } from "@drVue/utils/useDropZone";

interface Props {
  /**
   * The default label (+ colored "dragging it here" area) is shown
   * if no content is passed to the default slot.
   * The string array allows for line-by-line output taking into account the design/context
   * (where this component will be used), without being tied to the drop area sizes. */
  defaultLabel?: string[];
  /**
   * The highlight label is displayed when you hover the cursor with files over the area.
   * Similarly, it allows for line-by-line output taking into account design/context. */
  highlightLabel?: string[];
  /**
   * The height of the drop zone in highlight ("drop here") mode.
   * And the size of area if no content is passed to the default slot */
  height?: `${number}px` | `${number}%`;
  /**
   * The flag indicates that the files are being uploaded.
   * It is used to display the overlay with label "Uploading...". */
  isUploading?: boolean;
  uploadingLabel?: string;
}

interface Emit {
  (e: "upload", files: UploadItem[]): void;
  (e: "enter"): void;
  (e: "leave"): void;
}

const {
  defaultLabel = ["Upload your file by dragging it here"],
  highlightLabel = ["Drop your file here to upload it"],
  height = "165px",
  isUploading = false,
  uploadingLabel = "Uploading...",
} = defineProps<Props>();

const emit = defineEmits<Emit>();

const dropZoneRef = ref<HTMLDivElement | null>(null);

const { isOverDropZone } = useDropZone(dropZoneRef, {
  onDrop: (promise) => {
    promise.then((items: UploadItem[]) => {
      emit("upload", items);
    });
  },
  onEnter: () => emit("enter"),
  onLeave: () => emit("leave"),
});
</script>

<style lang="scss" module>
@use "@app/styles/scss/colors";
@use "@app/styles/scss/typography";
@use "@app/styles/scss/spacing";

.dropZone {
  position: relative;
}

.content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: spacing.$s;
  background: colors.$pr-50;
  border-radius: 8px;
  border: 1px dashed colors.$pr-200;
  color: colors.$pr-500;
  font: typography.$body_regular;
}

.content_isHidden {
  opacity: 0;
}

.content,
.content_isHidden {
  min-height: 88px;
  height: v-bind(height);
  padding: spacing.$s;
}

.dropZoneUploading,
.dropZoneOverlay {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 100;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: spacing.$s;
  font: typography.$body_medium;
  border-radius: 8px;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes dots {
  0% {
    content: " ";
  }
  20% {
    content: ".";
  }
  50% {
    content: "..";
  }
  80% {
    content: "...";
  }
}

.dropZoneUploading {
  color: colors.$pr-900;
  background: colors.$pr-50;

  & > span {
    position: relative;

    &:before,
    &:after {
      content: "";
      display: inline-block;
      position: absolute;
    }

    &:before {
      left: -18px;
      top: 4px;
      width: 14px;
      height: 14px;
      border: 2px solid colors.$sc-600;
      border-bottom-color: transparent;
      border-radius: 50%;
      box-sizing: border-box;
      animation: rotation 800ms linear infinite;
    }

    &:after {
      left: 100%;
      width: 15px;
      display: inline-block;
      animation: dots 1.4s steps(3, end) infinite;
    }
  }
}

.dropZoneOverlay {
  background: colors.$sc-50;
  border: 1px dashed colors.$sc-400;
  color: colors.$sc-600;
}
</style>
