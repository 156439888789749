import { difference } from "lodash-es";
import { defineStore } from "pinia";
import { computed, ref, watch } from "vue";

import {
  getItemType,
  isCategory,
  isTask,
} from "@app/ng/tasks/services/helpers/getItemType";
import tasksFilterService from "@app/ng/tasks/services/ts/TasksFilterService";
import { createDictionary } from "@drVue/common";
import { useCategoriesStore } from "@drVue/store/pinia/room/categories";
import { useCategoriesArchivedStore } from "@drVue/store/pinia/room/categoriesArchived";
import { useSelected } from "@drVue/store/pinia/useSelected";
import { useTasksStore } from "../tasks";
import { useTasksArchivedStore } from "../tasksArchived/tasksArchived";

import type { Task } from "../tasks";
import type { Category } from "@drVue/store/pinia/room/categories";
import type { Dictionary } from "@drVue/types";

export const useTasksTableStore = defineStore("tasksTable", () => {
  const tasksStore = useTasksStore();
  const tasksArchivedStore = useTasksArchivedStore();
  const categoriesStore = useCategoriesStore();
  const categoriesArchivedStore = useCategoriesArchivedStore();

  const isActive = ref<boolean>(true);

  const tasksList = computed(() =>
    isActive.value ? tasksStore.tasksList : tasksArchivedStore.tasksList,
  );

  const categoriesList = computed(() =>
    isActive.value
      ? categoriesStore.categoriesList
      : categoriesArchivedStore.categoriesList,
  );

  const {
    selectedDict: selectedTasksIds,
    selectedList: selectedTasksIdsList,
    toggle: toggleTaskSelection,
    select: selectTask,
    unselect: unselectTask,
  } = useSelected<Task, "id">();

  const {
    selectedDict: selectedCatsIds,
    selectedList: selectedCatsIdsList,
    toggle: toggleCategorySelection,
    select: selectCat,
    unselect: unselectCat,
  } = useSelected<Category, "id">();

  watch(
    () => selectedCatsIdsList.value,
    (newIds, oldIds) => {
      if (newIds.length > oldIds.length) {
        difference(newIds, oldIds).forEach((catId) =>
          tasksStore.tasksByCategoryId[catId].forEach((task) =>
            selectTask(task.id),
          ),
        );
      } else {
        difference(oldIds, newIds).forEach((catId) =>
          tasksStore.tasksByCategoryId[catId].forEach((task) =>
            unselectTask(task.id),
          ),
        );
      }
    },
  );

  const visibleCats = computed(() => {
    if (isActive.value) {
      const isNotFiltered = tasksFilterService.filters.categories.length === 0;
      if (isNotFiltered) {
        return categoriesList.value.reduce((acc, cat) => {
          acc[cat.id] = true;
          return acc;
        }, createDictionary<boolean>());
      }

      return tasksFilterService.filters.categories.reduce((acc, catId) => {
        acc[catId] = true;
        return acc;
      }, createDictionary<boolean>());
    } else {
      return categoriesList.value.reduce((acc, cat) => {
        acc[cat.id] = true;
        return acc;
      }, createDictionary<boolean>());
    }
  });

  const items = computed(() => {
    let _items = tasksList.value.filter((t) => {
      if (isActive.value) {
        return (
          visibleCats.value[t.category_id] && tasksFilterService.filterTask(t)
        );
      } else {
        return tasksFilterService.filterTask(t);
      }
    });

    _items = tasksFilterService.searchInTasks(_items);

    if (isActive.value) {
      const tasksByCatId = _items.reduce((acc, task) => {
        const catId = task.category_id;

        if (!Array.isArray(acc[catId])) acc[catId] = [];
        acc[catId].push(task);

        return acc;
      }, createDictionary<Task[]>());

      return categoriesList.value.reduce<(Category | Task)[]>((acc, cat) => {
        if (!visibleCats.value[cat.id]) return acc;

        const catTasks = tasksByCatId[cat.id];

        // Do not add empty categories
        if (!Array.isArray(catTasks) || catTasks.length === 0) return acc;

        const sortedCatTasks = tasksFilterService.sort(catTasks);
        acc.push(cat);
        acc = acc.concat(sortedCatTasks);

        return acc;
      }, []);
    } else {
      const result: (Category | Task)[] = tasksFilterService.searchInCategories(
        categoriesArchivedStore.categoriesList,
      );

      return result.concat(tasksFilterService.sort(_items));
    }
  });

  const itemsDict = computed(() => {
    return items.value.reduce((acc, item) => {
      // TODO: use 'uid' when it will be merged to Categories
      acc[`${getItemType(item)}_${item.id}`] = item;
      return acc;
    }, createDictionary<Category | Task>());
  });

  const itemsSortOrderDict = computed(() => {
    return items.value.reduce((acc, item, index) => {
      acc[item.uid] = index;
      return acc;
    }, createDictionary<number>());
  });

  const selectedTasks = computed(() => {
    // Return only visible tasks
    return selectedTasksIdsList.value
      .reduce<Task[]>((acc, taskId) => {
        const task = itemsDict.value[`task_${taskId}`];
        if (isTask(task)) acc.push(task);

        return acc;
      }, [])
      .sort(
        (a, b) =>
          itemsSortOrderDict.value[a.uid] - itemsSortOrderDict.value[b.uid],
      );
  });

  const selectedCategories = computed(() => {
    const selectedIds = new Set<number>(selectedCatsIdsList.value);

    const counts = selectedTasks.value.reduce<Dictionary<number>>(
      (acc, task) => {
        const catId = task.category_id;
        if (acc[catId]) acc[catId]++;
        else acc[catId] = 1;
        return acc;
      },
      createDictionary(),
    );

    for (const catId of Object.keys(counts)) {
      if (!tasksStore.tasksByCategoryId[catId]) continue;

      if (counts[catId] === tasksStore.tasksByCategoryId[catId].length) {
        selectedIds.add(parseInt(catId));
      }
    }

    return Array.from(selectedIds)
      .reduce<Category[]>((acc, catId) => {
        const cat = itemsDict.value[`category_${catId}`];
        if (isCategory(cat)) acc.push(cat);

        return acc;
      }, [])
      .sort(
        (a, b) =>
          itemsSortOrderDict.value[a.uid] - itemsSortOrderDict.value[b.uid],
      );
  });

  const selectedItems = computed<(Task | Category)[]>(() => {
    let result: (Task | Category)[] = [];

    result = result
      .concat(selectedTasks.value)
      .concat(selectedCategories.value);

    return result;
  });

  const toggleAll = () => {
    const tasksCount = selectedTasks.value.length;
    const catsCount = selectedCategories.value.length;
    const totalCount = tasksCount + catsCount;

    if (totalCount === 0 || totalCount < items.value.length) {
      // The tasks within the categories will be selected in the watch above
      items.value.forEach((item) => {
        if (isCategory(item)) selectCat(item.id);
      });
    } else {
      // The tasks within the categories will be unselected in the watch above
      items.value.forEach((item) => {
        if (isCategory(item)) unselectCat(item.id);
      });
    }
  };

  const clearSelection = () => {
    // We have `checkField` set to `_isChecked` in `TasksTable.vue`.
    // Vxe Table monkey-patches the data, so we need to remove the patch.
    // Sad, but true.
    for (const task of tasksList.value) {
      if ("_isChecked" in task) delete task._isChecked;
    }
    for (const cat of categoriesList.value) {
      if ("_isChecked" in cat) delete cat._isChecked;
    }

    selectedTasksIdsList.value.forEach((taskId) => unselectTask(taskId));
    selectedCatsIdsList.value.forEach((catId) => unselectCat(catId));
  };

  return {
    items,
    itemsDict,
    isActive,
    selectedTasksIds,
    selectedTasksIdsList,
    selectedCatsIds,
    selectedCatsIdsList,
    selectedTasks,
    selectedCategories,
    selectedItems,
    toggleTaskSelection,
    toggleCategorySelection,
    toggleAll,
    clearSelection,
  };
});

export type TasksTableStore = ReturnType<typeof useTasksTableStore>;
