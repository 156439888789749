<template>
  <ElSelect
    ref="selectRef"
    style="width: 100%"
    :model-value="editProps.value"
    :placeholder="editProps.schema.placeholder"
    :persistent="false"
    clearable
    :popper-class="`dr-popper--inline-control ${$style.select}`"
    v-bind="editProps.veeField"
    @change="updateValue"
    @visible-change="emitIfHasBeenClosed"
  >
    <ElOption
      v-for="opt in editProps.extra.options"
      :key="opt"
      :label="opt"
      :value="opt"
    />
  </ElSelect>
</template>

<script setup lang="ts">
import { ElSelect } from "element-plus";
import { onMounted, ref } from "vue";

import type { EditFieldProps } from "@drVue/components/client-dashboard/dynamic-form/Fields/types";

interface Props {
  editProps: EditFieldProps;
}

const props = defineProps<Props>();

// eslint-disable vue/no-setup-props-destructure
const initialValue = props.editProps.value;
let currentValue = props.editProps.value;
// eslint-enable vue/no-setup-props-destructure

// @change event is fired before @visible-change, so we can update the current
// selected value and then decide what to do next.
const updateValue = (value: string) => {
  currentValue = value;
};

const emitIfHasBeenClosed = (visible: boolean) => {
  const hasBeenClosed = !visible;

  if (hasBeenClosed) {
    const hasValueChanged = currentValue !== initialValue;

    if (hasValueChanged) props.editProps.submitField();
    else props.editProps.quitEditModeConfirm();
  }
};

const selectRef = ref<InstanceType<typeof ElSelect> | null>(null);
onMounted(() => {
  if (!selectRef.value) return;
  selectRef.value.states.hoveringIndex = 0;
  selectRef.value.toggleMenu();
});
</script>

<style module>
.select {
  min-width: 150px;
  max-width: 250px;
}
</style>
