<template>
  <b v-if="isParentRow(row)" class="semibold">
    {{ parentRowLabel }}
  </b>
  <div v-else-if="isChildRow(row)">
    <AddRoomMemberRow
      v-if="row.change.op === 'add_room_member'"
      :change="row.change"
      :is-successful="undefined"
    />
    <CreateGroupRow
      v-else-if="row.change.op === 'create_group'"
      :change="row.change"
      :is-successful="undefined"
    />
    <CreateTaskRow
      v-else-if="row.change.op === 'create_task'"
      :change="row.change"
      :is-successful="undefined"
    />
    <div v-else>ERROR!</div>
  </div>
  <div v-else>ERROR!</div>
</template>

<script setup lang="ts">
import { computed } from "vue";

import AddRoomMemberRow from "./AddRoomMemberRow.vue";
import CreateGroupRow from "./CreateGroupRow.vue";
import CreateTaskRow from "./CreateTaskRow.vue";
import { type ChangeRow, isChildRow, isParentRow } from "./tableColumns";

interface Props {
  row: ChangeRow;
}

const props = defineProps<Props>();

const parentRowLabel = computed(() => {
  if (isParentRow(props.row)) return props.row.label;
  return "";
});
</script>
