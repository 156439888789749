<template>
  <div :class="$style.container">
    <DrLayoutContent
      destroy-aside-on-close
      :show-aside="isFilterPanelShown"
      :class="$style.container"
    >
      <template #panel>
        <TaskCategoriesTree
          :selected-category-id="selectedCategoryId"
          :is-archive="isInArchive"
          @navigate="navigate"
          @create-request="
            (categoryId) => openCreateRequestPanel({ categoryId })
          "
          @create-finding="(categoryUid) => openCreateFindingPanel(categoryUid)"
        />
      </template>

      <template #nav><TasksNavBar /></template>

      <template #toolbar>
        <TasksToolbarFilters>
          <template #append>
            <DrToolbarFilterButton
              :label="t('shared.filters')"
              icon="filter"
              :is-active="tasksFilterService.isActive()"
              :hide-dropdown="!tasksFilterService.isActive()"
              @click="toggleFilterPanel"
              @clear="tasksFilterService.clearListFilters()"
            />
          </template>
        </TasksToolbarFilters>
      </template>

      <template #toolbar-right>
        <TasksToolbarMenu
          :columns="tableColumns.columns"
          @create-request="() => openCreateRequestPanel()"
        />
      </template>

      <template v-if="showBulkActions" #toolbar-over>
        <TasksToolbarBulkActions
          :selected-count="tasksTableStore.selectedTasks.length"
          @copy="
            (taskId) =>
              createRequestPanelRef?.open({ copyFromRequestId: taskId })
          "
          @archive="archiveTasks"
          @restore="restoreTasks"
        />
      </template>

      <template #aside>
        <TaskFiltersForm @close="toggleFilterPanel" />
      </template>

      <DrNewLoader v-show="isLoading" overlay />

      <DrOverlayEmpty
        v-if="noDisplayData.active"
        icon="tasks"
        :title="noDisplayData.title"
      >
        <template #action>
          <ElButton
            v-if="noDisplayData.btnClearSearch"
            @click="tasksFilterService.clearListSearch()"
          >
            {{ t("filters.clear_search_query") }}
          </ElButton>
          <ElButton
            v-if="noDisplayData.btnClearFilters"
            @click="tasksFilterService.clearListFilters()"
          >
            {{ t("filters.reset_filters") }}
          </ElButton>
          <ElButton
            v-if="noDisplayData.btnNewTask"
            type="primary"
            @click="addNewRequest"
          >
            {{ t("requests.new_request") }}
          </ElButton>
        </template>
      </DrOverlayEmpty>

      <TasksTable
        :items="tasksTableStore.items"
        :selected-items="tasksTableStore.selectedItems"
        :columns="tableColumns.columns"
        @duplicate="
          ({ source }) =>
            openCreateRequestPanel({
              categoryId: source.category_id,
              copyFromRequestId: source.id,
            })
        "
        @add-task-below="
          ({ categoryId, order }) =>
            openCreateRequestPanel({
              categoryId,
              order,
            })
        "
        @checkbox-all="tasksTableStore.toggleAll()"
        @checkbox-task="
          ({ task }) => tasksTableStore.toggleTaskSelection(task.id)
        "
        @checkbox-category="
          ({ category }) => tasksTableStore.toggleCategorySelection(category.id)
        "
        @sort-changed="onSortChanged"
        @archive="archiveTasks"
        @restore="restoreTasks"
        @cell-clicked="(item) => emit('cell-clicked', item)"
        @task-reordered="reorderTask"
        @files-dropped="openUploadDialog"
      />

      <DrUploadDialog
        ref="uploadDialogRef"
        v-if="uploadDialogItems"
        :items="uploadDialogItems"
        :attach-to-request-id="uploadDialogAttachToRequestId"
        @closed="clearUploadDialogState"
      />

      <CreateRequestPanel
        ref="createRequestPanelRef"
        @closed="handleTaskCreated"
      />

      <FindingsCreatePanel
        v-if="isFindingsEnabled"
        ref="createFindingPanelRef"
      />
    </DrLayoutContent>
  </div>
</template>

<script setup lang="ts">
import { ElMessageBox } from "element-plus";
import { computed, ref, watch } from "vue";
import { useI18n } from "vue-i18n";
import { DrLayoutContent } from "@shared/ui/dr-layouts";
import { DrNewLoader } from "@shared/ui/dr-loader";
import { DrOverlayEmpty } from "@shared/ui/dr-overlay";
import { DrToolbarFilterButton } from "@shared/ui/dr-toolbar";
import { useBrowserLocation } from "@vueuse/core";

import { APP_SETTINGS, ROOM_DATA, ROOM_MEMBER_DATA } from "@setups/data";
import { TaskFieldAccessType } from "@setups/enums";
import { CustomViewObjectTypes } from "@setups/types";
import {
  insightTrack,
  RoomRequestsBulkEvent,
  RoomTasksFiltersEvent,
} from "@app/insight";
import tasksFilterService from "@app/ng/tasks/services/ts/TasksFilterService";
import DrUploadDialog from "@app/vue/shared/ui/dr-upload-dialog/DrUploadDialog.vue";
import { useCategoriesStore } from "@app/vue/store/pinia/room/categories";
import FindingsCreatePanel from "@drVue/components/room/findings/FindingsCreatePanel.vue";
import TaskCategoriesTree from "@drVue/components/room/tasks/TaskCategoriesTree.vue";
import TaskFiltersForm from "@drVue/components/room/tasks/TaskFiltersForm.vue";
import DrStore from "@drVue/store";
import { useValueDriversStore } from "@drVue/store/modules/room/synergies/value-drivers";
import { pinia } from "@drVue/store/pinia";
import { type Task, useTasksStore } from "@drVue/store/pinia/room/tasks";
import { useTasksTableStore } from "@drVue/store/pinia/room/tasksTable/tasksTable";
import TableColumns from "../TasksTable/tableColumns";
import TasksTable from "../TasksTable/TasksTable.vue";
import CreateRequestPanel from "./CreateRequestPanel.vue";
import TasksNavBar from "./TasksNavBar.vue";
import TasksToolbarBulkActions from "./TasksToolbarBulkActions.vue";
import TasksToolbarFilters from "./TasksToolbarFilters.vue";
import TasksToolbarMenu from "./TasksToolbarMenu.vue";

import type { RequestsItem } from "../TasksTable/types";
import type { UploadItem } from "@app/vue/utils/extractFiles";
import type { FieldItem } from "@drVue/api-service/client-dashboard";
import type { OpenParams } from "@drVue/components/room/tasks/TaskOverviewPage/CreateRequestPanel.vue";
import type { CustomViewColumn } from "@setups/types";
import type { VxeTablePropTypes } from "vxe-table";

interface Emits {
  (e: "cell-clicked", item: RequestsItem): void;
}

const emit = defineEmits<Emits>();

const isFindingsEnabled = ROOM_DATA.userPermissions.isFindingsAccessible;

const tasksStore = useTasksStore(pinia);
const tasksTableStore = useTasksTableStore(pinia);
const valueDriversStore = useValueDriversStore(pinia);
const categoriesStore = useCategoriesStore(pinia);

const $location = useBrowserLocation();

const isInArchive = computed(
  () => $location.value.hash?.startsWith("#/tasks/archived") ?? false,
);

const taskDetailsRegEx = /#\/tasks\/(\d+)/;
const isOnTaskDetailsRoute = computed(() =>
  taskDetailsRegEx.test($location.value.hash ?? ""),
);

watch(isInArchive, (isArchive) => {
  tasksTableStore.isActive = !isArchive;
});

const listIdRegEx = /#\/tasks\/list\/(\d+)/;
const selectedCategoryId = computed(() => {
  const hash = $location.value.hash;
  if (!hash || !hash.startsWith("#/tasks/list")) return null;

  const matches = hash.match(listIdRegEx);
  if (!matches) return null;

  const categoryId = Number(matches[1]);
  if (Number.isNaN(categoryId)) return null;

  return categoryId;
});

const { t } = useI18n();

const viewColumns = computed<CustomViewColumn[]>(() => {
  const view = DrStore.getters["common/customViews/defaultView"](
    CustomViewObjectTypes.Task,
  );

  return view.settings.columns;
});

const customFields = computed<FieldItem[]>(() => {
  return DrStore.getters["clientDashboard/customFields/byObjectType"]("task");
});

watch(
  () => customFields.value.length,
  (fieldCount) => {
    if (fieldCount) {
      tasksFilterService.setCustomFields(customFields.value);
    }
  },
  {
    immediate: true,
  },
);

const isLoading = computed(
  () => tasksStore.isLoading || categoriesStore.isLoading,
);

const tableColumns = new TableColumns(
  viewColumns,
  customFields,
  {
    canManageTasks: ROOM_DATA.userPermissions.canManageTasks,
    isFindingsAccessible: ROOM_DATA.userPermissions.isFindingsAccessible,
    taskCustomFieldsAccess: ROOM_MEMBER_DATA.group.task_custom_fields_access,
    taskStartDueDatesAccess:
      ROOM_MEMBER_DATA.group.task_start_and_due_dates_access,
  },
  isInArchive,
);

const canCreateTasks = !!ROOM_DATA?.userPermissions?.canCreateTasks;

const uploadDialogRef = ref<InstanceType<typeof DrUploadDialog>>();
const uploadDialogItems = ref<UploadItem[] | null>(null);
const uploadDialogAttachToRequestId = ref<number>();

const createRequestPanelRef = ref<InstanceType<
  typeof CreateRequestPanel
> | null>(null);
const createFindingPanelRef = ref<InstanceType<
  typeof FindingsCreatePanel
> | null>(null);
const isFilterPanelShown = ref(false);

const showBulkActions = computed(() => !!tasksTableStore.selectedTasks.length);

const navigate = ({ listId }: { listId: string | number | null }) => {
  const list = `#/tasks/list`;
  if (listId === null) {
    if (ROOM_DATA.enableListsTabs) {
      // We can't switch to "All worklists" item, when the feature is enabled,
      // therefore let's switch to the first one.
      if (categoriesStore.rootCategories.length) {
        const firstWorklist = categoriesStore.rootCategories[0];
        $location.value.hash = `${list}/${firstWorklist.id}/overview`;
      } else {
        $location.value.hash = `${list}/overview`;
      }
    } else {
      $location.value.hash = `${list}/overview`;
    }
  } else {
    $location.value.hash = `${list}/${listId}/overview`;
  }
};

const toggleFilterPanel = () => {
  isFilterPanelShown.value = !isFilterPanelShown.value;

  if (isFilterPanelShown.value) {
    insightTrack(RoomTasksFiltersEvent.ShownAllFilters);
  }
};

const openCreateRequestPanel = (params?: OpenParams) => {
  createRequestPanelRef.value?.open(params);
};

const addNewRequest = () =>
  openCreateRequestPanel({
    categoryId: selectedCategoryId.value ?? undefined,
  });

const openCreateFindingPanel = (categoryUid?: string) =>
  createFindingPanelRef.value?.open({ categoryUid });

const onSortChanged = ({
  field,
  order,
}: {
  field: string;
  order: VxeTablePropTypes.SortOrder;
}) => {
  const by = order === null ? "order" : field;
  const reversed = order === null ? false : order === "desc";

  tasksFilterService.setOrder({ by, reversed });
};

const noDisplayData = computed(() => {
  const data = {
    active: false,
    title: "",
    btnClearSearch: false,
    btnClearFilters: false,
    btnNewTask: false,
  };

  if (isLoading.value) {
    return data;
  }

  const searchText = tasksFilterService.filters.searchText;
  const hasCategory = !!tasksFilterService.filters.categories.length;
  const hasFilters = tasksFilterService.isActive();

  if (!isInArchive.value && canCreateTasks) {
    data.btnNewTask = true;
  }

  if (!tasksTableStore.items.length) {
    data.active = true;
    data.title = t("requests.no_requests_found");

    if (hasCategory) {
      data.title = t("requests.no_requests_in_worklist");
    }

    if (hasFilters || !!searchText) {
      data.btnNewTask = false;

      if (hasFilters) {
        data.title = t("requests.no_requests_matching_criteria");
        data.btnClearFilters = true;
        data.btnClearSearch = !!searchText;
      } else {
        data.title = t("requests.no_results_for_search_text", {
          search_text: searchText,
        });
        data.btnClearSearch = true;
      }
    }
  }

  return data;
});

if (
  APP_SETTINGS.WEBSITE.IS_DEALROOM &&
  (ROOM_DATA.synergySettings?.enable ?? false) &&
  ROOM_MEMBER_DATA.group.synergies_access !== TaskFieldAccessType.NoAccess
) {
  valueDriversStore.load();
}

/**
 *  @note when a new task was added by copying another one from the archive
 *        (bulk action "Copy" from archive with only one selected task),
 *        the new task will NOT be displayed in the archive.
 */
const handleTaskCreated = () => {
  if (isInArchive.value) navigate({ listId: null });
};

const archiveTasks = async (uids: string[]) => {
  try {
    await ElMessageBox.confirm(
      t("requests.delete_confirm_text"),
      t("requests.delete_confirm_title"),
      {
        confirmButtonText: t("shared.delete"),
        customClass: "el-message-box--warning",
      },
    );

    tasksStore.bulkArchive(uids);
    tasksTableStore.clearSelection();
    insightTrack(RoomRequestsBulkEvent.Delete, {
      number: String(uids.length),
    });
  } catch {
    return;
  }
};

const restoreTasks = async (uids: string[]) => {
  try {
    await ElMessageBox.confirm(
      t("requests.restore_confirm_text"),
      t("requests.restore_confirm_title"),
      {
        confirmButtonText: t("shared.restore"),
        customClass: "el-message-box--warning",
      },
    );

    tasksStore.bulkRestore(uids);
    tasksTableStore.clearSelection();
    insightTrack(RoomRequestsBulkEvent.Restore, {
      number: String(uids.length),
    });
  } catch {
    return;
  }
};

const reorderTask = (
  taskToMoveId: number,
  prevItemId: number,
  isPrevCategory: boolean,
) => {
  tasksStore.reorderTask(taskToMoveId, prevItemId, isPrevCategory);
};

const openUploadDialog = (task: Task, files: UploadItem[]) => {
  uploadDialogItems.value = files;
  uploadDialogAttachToRequestId.value = task.id;
};

const clearUploadDialogState = () => {
  uploadDialogItems.value = null;
  uploadDialogAttachToRequestId.value = undefined;
};

const selectedCategory = computed(() => {
  const categories = categoriesStore.categories;
  if (!selectedCategoryId.value) return null;
  return categories[selectedCategoryId.value];
});

watch(
  selectedCategory,
  (currCat, prevCat) => {
    if (currCat) {
      // There is no `prevCat` when we either:
      //
      // 1. Switch from "All worklists" to a specific worklist
      // 2. Close the Task details panel
      if (prevCat) tasksTableStore.clearSelection();

      const id = currCat.id;

      categoriesStore.expandCategory(id);

      tasksFilterService.filters.categories =
        categoriesStore.categories[id]?.descendants ?? [];
    } else if (!isOnTaskDetailsRoute.value) {
      // The current category is null, so we are either:
      //
      // 1. On the "All worklists" (root category)
      // 2. Viewing a task details page
      //
      // We excluded the second case with the `if` above to avoid clearing the
      // selection and filters when viewing a Task details panel.
      tasksTableStore.clearSelection();
      tasksFilterService.filters.categories = [];
    }
  },
  { immediate: true },
);
</script>

<style module lang="scss">
@use "@app/styles/scss/values";

.container {
  height: calc(100vh - #{values.$header-height});
}
</style>
