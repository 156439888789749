import { createI18n } from "vue-i18n";

import enUS from "@app/locales/en-US.json";
import frFR from "@app/locales/fr-FR.json";
import { getLangCookie } from "@app/vue/common";

export type I18nLocale = "en-US" | "fr-FR";

const i18n = createI18n({
  legacy: false,
  locale: getLangCookie() ?? "en-US",
  fallbackLocale: "en-US",
  messages: {
    "en-US": enUS,
    "fr-FR": frFR,
  },
});

// https://vue-i18n.intlify.dev/guide/advanced/composition.html#mapping-between-vuei18n-instance-and-composer-instance
const d = i18n.global.d;
const n = i18n.global.n;
const t = i18n.global.t;
const tc = i18n.global.t;
const te = i18n.global.te;
const tm = i18n.global.tm;

window.i18n = i18n.global;

// Please, use these exports ONLY in these contexts:
// 1. Pure TypeScript files
// 2. AngularJS Controllers such as AngularJS (also see initI18n in app.js), or Gantt
// 3. Whereever the Vue instance is not available and `useI18n` is not possible
export { i18n };
export { d, n, t, tc, te, tm };
