import { ApiService } from "../index";

import type { PaginatedData } from "../types";
import type { BasicRoomRow } from "@drVue/components/management/RoomsTable/types";
import type { UserRow } from "@drVue/components/management/UsersTable/types";

export interface AuditLogData {
  dt: string;
  user_email: string;
  user_name: string;
  action_label: string;
  item_type: string;
  item_title: string;
}

export interface NewTrialClient {
  email: string;
  copy_data_from_org: number | null;
  room_title: string;
}

export interface NewTrialClientResp {
  client_id: number;
  client_url: string | null;
  room_id: number;
  room_url: string;
}

interface OperationConfirmation {
  token: string;
}

export interface MFALoadRecoveryCodes extends OperationConfirmation {}

export interface MFALoadRecoveryCodesResp {
  codes: string[];
}

export interface MgmtOrganizationMember {
  id: number;
  email: string;
  user_id: number | null; // null === pending
  group_name: string;
  profile: { first_name: string | null; last_name: string | null };
  has_billing_access: boolean;
}

export interface AddOrgAdminRequest {
  email: string;
}
export interface AddOrgAdminConfirmation extends OperationConfirmation {}
export interface AddOrgAdminResponse extends MgmtOrganizationMember {}

export interface ChangeOwnerData {
  source_owner: string;
  target_owner: string;
}

export interface SubscriptionOverUsageInfo {
  client_id: number;
  client_name: string;
  subscription_id: string;
  subscription_status: string;
  current_usage: number;
  included_usage: number;
}

export interface ClientAiUsage {
  date: Date;
  applied_reports_count: number;
  answered_questions_count: number;
  processed_documents_count: number;
}

export class ManagementApiService extends ApiService {
  public auditLog() {
    const url = this.Urls["root-api:management:audit-log"]();

    return this.get<PaginatedData<AuditLogData>>(url);
  }
  public auditLogNext(nextUrl: string) {
    return this.get<PaginatedData<AuditLogData>>(nextUrl);
  }

  public createTrialClient(data: NewTrialClient) {
    const url = this.Urls["root-api:management:clients-trial"]();

    return this.post<NewTrialClientResp, NewTrialClient>(url, data);
  }

  public confirmMFARecoveryCodesRequest(userId: number) {
    const url =
      this.Urls["root-api:management:confirm-mfa-recovery-codes-request"](
        userId,
      );

    return this.post<MFALoadRecoveryCodes, null>(url, null);
  }

  public loadMFARecoveryCodes(userId: number, data: MFALoadRecoveryCodes) {
    const url =
      this.Urls["root-api:management:load-mfa-recovery-codes"](userId);

    return this.post<MFALoadRecoveryCodesResp, MFALoadRecoveryCodes>(url, data);
  }

  public confirmAddOrgAdmin(clientId: number, data: AddOrgAdminRequest) {
    const url =
      this.Urls["root-api:management:clients-detail-members-confirm-add-admin"](
        clientId,
      );

    return this.post<AddOrgAdminConfirmation, AddOrgAdminRequest>(url, data);
  }

  public addOrgAdmin(
    clientId: number,
    data: AddOrgAdminRequest & AddOrgAdminConfirmation,
  ) {
    const url =
      this.Urls["root-api:management:clients-detail-members-add-admin"](
        clientId,
      );

    return this.post<
      AddOrgAdminResponse,
      AddOrgAdminRequest & AddOrgAdminConfirmation
    >(url, data);
  }

  public confirmDeleteClient(clientId: number) {
    const url =
      this.Urls["root-api:management:client-confirm-full-delete"](clientId);

    return this.post<OperationConfirmation, unknown>(url, {});
  }

  public executeDeleteClient(clientId: number, data: OperationConfirmation) {
    const url =
      this.Urls["root-api:management:client-execute-full-delete"](clientId);

    return this.post<unknown, OperationConfirmation>(url, data);
  }

  public bulkUpdateOwner(clientId: number, data: ChangeOwnerData) {
    const url =
      this.Urls["root-api:management:client-bulk-update-rooms-owner"](clientId);

    return this.post<null, ChangeOwnerData>(url, data);
  }

  public getSubsWithOverUsage() {
    const url = this.Urls["root-api:management:over-usage"]();

    return this.get<SubscriptionOverUsageInfo[]>(url);
  }

  public searchUser(search: string): Promise<UserRow[]> {
    const url = this.Urls["root-api:management:users-list"]();
    return this.get<UserRow[]>(url, { params: { search } });
  }

  public searchRoom(search: string): Promise<BasicRoomRow[]> {
    const url = this.Urls["root-api:management:rooms-list"]();
    return this.get<BasicRoomRow[]>(url, { params: { search } });
  }

  public getClientAiUsage(
    clientId: number,
    fromDate: string,
    toDate: string,
  ): Promise<ClientAiUsage[]> {
    const url = this.Urls["root-api:management:client-ai-usage"](clientId);
    return this.get(url, {
      params: new URLSearchParams({ from_date: fromDate, to_date: toDate }),
    });
  }
}
