<template>
  <div :class="$style.container">
    {{ $t("shared.worklist") }}
  </div>
</template>

<script lang="ts" setup></script>

<style lang="scss" module>
@use "@app/styles/scss/colors";
@use "@app/styles/scss/spacing";
@use "@app/styles/scss/typography";

.container {
  height: 32px;
  display: inline-flex;
  align-items: center;
  padding: 0 spacing.$xs;
  color: colors.$pr-900;
  font: typography.$caption_semibold;

  &:before,
  &:after {
    content: "";
    display: block;
    position: absolute;
    z-index: 1;
  }

  &:before {
    left: 0;
    right: 0;
    border-top: solid 1px colors.$pr-100;
  }
  &:before {
    right: 0;
    bottom: 0;
    height: 32px;
    border-right: solid 1px colors.$pr-100;
  }
}
</style>
