import { toZonedTime } from "date-fns-tz";
import Cookies from "js-cookie";
import _compareAscending from "lodash/_compareAscending";
import { Toasts, ToastType } from "@shared/ui/dr-alert/service";

import { getCsrfToken, USER_DATA } from "@setups/data";

import type { I18nLocale } from "./i18n";
import type { RootState } from "@drVue/store/state";
import type { Dictionary } from "@drVue/types";
import type { ToastOptions } from "@shared/ui/dr-alert/service";
import type { Store } from "vuex";

export function copyToClipboard(text: string) {
  // http://stackoverflow.com/questions/400212/how-do-i-copy-to-the-clipboard-in-javascript
  const textArea = document.createElement("textarea");

  //
  // *** This styling is an extra step which is likely not required. ***
  //
  // Why is it here? To ensure:
  // 1. the element is able to have focus and selection.
  // 2. if element was to flash render it has minimal visual impact.
  // 3. less flakyness with selection and copying which **might** occur if
  //    the textarea element is not visible.
  //
  // The likelihood is the element won't even render, not even a flash,
  // so some of these are just precautions. However in IE the element
  // is visible whilst the popup box asking the user for permission for
  // the web page to copy to the clipboard.
  //

  // Place in top-left corner of screen regardless of scroll position.
  textArea.style.position = "fixed";
  textArea.style.top = "0";
  textArea.style.left = "0";

  // Ensure it has a small width and height. Setting to 1px / 1em
  // doesn't work as this gives a negative w/h on some browsers.
  textArea.style.width = "2em";
  textArea.style.height = "2em";

  // We don't need padding, reducing the size if it does flash render.
  textArea.style.padding = "0";

  // Clean up any borders.
  textArea.style.border = "none";
  textArea.style.outline = "none";
  textArea.style.boxShadow = "none";

  // Avoid flash of white box if rendered for any reason.
  textArea.style.background = "transparent";

  textArea.value = text;

  document.body.appendChild(textArea);

  textArea.select();

  let success;
  try {
    success = document.execCommand("copy");
  } catch (err) {
    // console.error('Oops, unable to copy');
  }

  document.body.removeChild(textArea);

  return success;
}

export function postAndGetFile(actionUrl: string, data: any) {
  const form = document.createElement("form");
  form.style.display = "none";
  form.target = "_self";
  form.method = "POST";
  form.action = actionUrl;

  Object.keys(data).forEach((key) => {
    const input = document.createElement("input");
    input.type = "hidden";
    input.name = key;
    let value = data[key];
    if (angular.isArray(value) || angular.isObject(value)) {
      value = JSON.stringify(value);
    }
    input.value = value;
    form.appendChild(input);
  });

  const csrf = document.createElement("input");
  csrf.type = "hidden";
  csrf.value = getCsrfToken();
  csrf.name = "csrfmiddlewaretoken";
  form.appendChild(csrf);

  // firefox requires to work
  document.body.appendChild(form);
  form.submit();
  document.body.removeChild(form);
}

export function dowloadFile(file: Blob, filename: string) {
  const link = document.createElement("a");
  link.href = window.URL.createObjectURL(file);
  link.download = filename;
  link.click();
}

export const isLocalStorageEnabled = (() => {
  // https://github.com/Modernizr/Modernizr/blob/master/feature-detects/storage/localstorage.js
  const mod = "modernizr";
  try {
    localStorage.setItem(mod, mod);
    localStorage.removeItem(mod);

    return true;
  } catch (e) {
    return false;
  }
})();

export function loadFromLocalStorage<T>(key: string): T | undefined {
  if (isLocalStorageEnabled) {
    const rawValue = localStorage.getItem(key);
    if (rawValue) {
      try {
        return JSON.parse(rawValue) || undefined;
      } catch (e) {
        return undefined;
      }
    }
  }
}

export function saveToLocalStorage<T>(key: string, value: T) {
  if (isLocalStorageEnabled) {
    const jsonValue = JSON.stringify(value);
    localStorage.setItem(key, jsonValue);
  }
}

export function isStringContains(query: string, value: string): boolean {
  return value.toLowerCase().includes(query.toLowerCase());
}

export function capitalize(str: string, onlyFirstWord = false) {
  if (onlyFirstWord) {
    return str.replace(/(?:^|\s)\S/, (a) => a.toUpperCase());
  }

  return str.replace(/(?:^|\s)\S/g, (a) => a.toUpperCase());
}

export function generateUUID() {
  // Public Domain/MIT
  let d = new Date().getTime();
  if (
    typeof performance !== "undefined" &&
    typeof performance.now === "function"
  ) {
    d += performance.now(); //use high-precision timer if available
  }
  return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (c) {
    const r = (d + Math.random() * 16) % 16 | 0;
    d = Math.floor(d / 16);
    return (c === "x" ? r : (r & 0x3) | 0x8).toString(16);
  });
}

export function compareAscending(value: any, other: any): number {
  return _compareAscending(value, other);
}

/**
 * Creates a Dictionary without chaining it to 'Object.prototype' (__proto__ is
 * null). It mitigates an issue related to dynamic access via
 * dict['<arbitrary_key>'] and check for .hasOwnProperty().
 * @param items initial items in the created Dictionary.
 */
export function createDictionary<T>(items: Dictionary<T> = {}): Dictionary<T> {
  return Object.assign(Object.create(null), items);
}

export function matchText(
  query: string,
  item: any,
  itemKeys: string[],
): boolean {
  for (const k of itemKeys) {
    const v = item[k];
    if (v === undefined || v === null) continue;
    if (v.toString().toLowerCase().includes(query.toLowerCase())) return true;
  }
  return false;
}

export function convertPtzToBtz(date: Date | number | string) {
  return toZonedTime(date, USER_DATA.profile.timezone);
}

type NumberOrStringKeysOf<T> = keyof {
  [K in keyof T as T[K] extends number | string ? K : never]: T[K];
};

export function groupBy<T, K extends NumberOrStringKeysOf<T>>(
  arr: T[],
  key: K,
): Map<T[K], T[]> {
  const map = new Map<T[K], T[]>();

  for (const item of arr) {
    const arr = map.get(item[key]);
    if (arr) arr.push(item);
    else map.set(item[key], [item]);
  }

  return map;
}

export function isDateValid(d: Date) {
  return (
    Object.prototype.toString.call(d) === "[object Date]" && !Number.isNaN(d)
  );
}

export function $notifySuccess(message: string, options?: ToastOptions) {
  Toasts.add(ToastType.Success, message, options);
}

export function $notifyWarning(message: string, options?: ToastOptions) {
  Toasts.add(ToastType.Warning, message, options);
}

export function $notifyInfo(message: string, options?: ToastOptions) {
  Toasts.add(ToastType.Info, message, options);
}

export function $notifyDanger(message: string, options?: ToastOptions) {
  Toasts.add(ToastType.Danger, message, options);
}

export const getTimezone = () =>
  Intl.DateTimeFormat().resolvedOptions().timeZone;

export const shrinkString = (s: string, maxLength: number): string => {
  if (s.length <= maxLength) return s;
  return s.substring(0, maxLength - 3) + "...";
};

export const delayedCall = (fn: () => void, delay: boolean | number) => {
  if (delay === true) fn();
  else if (typeof delay === "number") setTimeout(fn, delay);
};

export const sanitize = (input: string) => {
  const element = document.createElement("div");
  element.innerText = input;
  return element.innerHTML;
};

const ALLOWED_LOCALES: Record<I18nLocale, boolean> = {
  "en-US": true,
  "fr-FR": true,
} as const;
export const getLangCookie = (): I18nLocale | null => {
  const value = Cookies.get("lang");
  if (!value) return null;

  const normalizedValue = value.toLowerCase().replace("_", "-");

  if (normalizedValue === "en") return "en-US";
  if (normalizedValue === "fr") return "fr-FR";

  const parts = normalizedValue.split("-");
  if (parts.length === 2) {
    const lang = parts[0];
    const region = parts[1].toUpperCase();

    const locale = `${lang}-${region}` as I18nLocale;
    if (ALLOWED_LOCALES[locale]) {
      return locale as "en-US" | "fr-FR";
    }

    return null;
  }

  return null;
};

//TODO: add this functions to config object in app.
export default {
  install: function (vue: any) {
    vue.prototype.$notifySuccess = $notifySuccess;
    vue.prototype.$notifyWarning = $notifyWarning;
    vue.prototype.$notifyInfo = $notifyInfo;
    vue.prototype.$notifyDanger = $notifyDanger;
  },
};

declare module "vue" {
  interface ComponentCustomProperties {
    $notifySuccess: (message: string, tag?: string, duration?: number) => void;
    $notifyWarning: (message: string, tag?: string, duration?: number) => void;
    $notifyInfo: (message: string, tag?: string, duration?: number) => void;
    $notifyDanger: (message: string, tag?: string, duration?: number) => void;

    $store: Store<RootState>;

    // Defined in https://github.com/element-plus/element-plus/blob/dev/typings/global.d.ts
    // --
    // $message: typeof import('element-plus')['ElMessage']
    // $notify: typeof import('element-plus')['ElNotification']
    // $msgbox: typeof import('element-plus')['ElMessageBox']
    // $messageBox: typeof import('element-plus')['ElMessageBox']
    // $alert: typeof import('element-plus')['ElMessageBox']['alert']
    // $confirm: typeof import('element-plus')['ElMessageBox']['confirm']
    // $prompt: typeof import('element-plus')['ElMessageBox']['prompt']
    // $loading: typeof import('element-plus')['ElLoadingService']
    //
    //
    // Defined in https://github.com/vuejs/router/blob/main/src/globalExtensions.ts
    // --
    // $route: RouteLocationNormalizedLoaded
    // $router: Router;
  }
}
