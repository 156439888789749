<template>
  <Transition :name="$style.fadeIn">
    <div v-if="!currentPlaybook" :class="$style.container">
      <ElInput v-model="searchTerm" :placeholder="t('shared.search_dots')">
        <template #prefix>
          <DrIcon name="search" />
        </template>
      </ElInput>
      <CategoryTabs :selected-tags="selectedTags" @select="handleTagSelect" />
      <div :class="$style.grid">
        <TransitionGroup :name="$style.fadeInOut">
          <PlaybookCard
            v-for="{ id, title } in playbooksFiltered"
            :id="id"
            :key="id"
            :title="title"
            :is-selected="selectedPlaybook === id"
            @select="handleSelectPlaybook"
            @open="handleOpenPlaybookDetails"
          />
        </TransitionGroup>
      </div>
    </div>
  </Transition>
  <Transition :name="$style.fadeIn">
    <PlaybooksView
      v-if="currentPlaybook"
      :id="currentPlaybook.id"
      :title="currentPlaybook.title"
      :requests="currentPlaybook.requests"
      :resources="currentPlaybook.resources"
      :description="currentPlaybook.description"
      :is-selected="selectedPlaybook === currentPlaybook.id"
      @select="handleSelectPlaybook"
      @back="currentPlaybookId = null"
    />
  </Transition>
</template>

<script setup lang="ts">
import { intersection } from "lodash-es";
import { isEmpty } from "lodash-es";
import { computed, ref } from "vue";
import { useI18n } from "vue-i18n";
import DrIcon from "@shared/ui/dr-icon/DrIcon.vue";

import CategoryTabs from "@drVue/components/user-dashboard/playbooks-modern/TagsSelect.vue";
import playbooks from "../playbooks/data";
import PlaybookCard from "./PlaybookCard.vue";
import PlaybooksView from "./PlaybookView.vue";

import type {
  Playbook,
  Tags as PlaybookTag,
} from "@drVue/components/user-dashboard/playbooks/types";
import type { Dictionary } from "@drVue/types";

const emit = defineEmits(["select"]);

const { t } = useI18n();

const searchTerm = ref("");

const selectedPlaybook = ref("");
const handleSelectPlaybook = (playbookId: string) => {
  if (selectedPlaybook.value === playbookId) {
    selectedPlaybook.value = "";
    return;
  }
  selectedPlaybook.value = playbookId;
  emit("select", selectedPlaybook.value);
};

const playbooksDict: Dictionary<Playbook> = playbooks.reduce(
  (acc, el) => ({ ...acc, [el.id]: el }),
  {},
);
const currentPlaybookId = ref<string | null>(null);
const currentPlaybook = computed<Playbook>(
  () => playbooksDict[currentPlaybookId.value!],
);
const handleOpenPlaybookDetails = (playbookId: string) => {
  currentPlaybookId.value = playbookId;
};

const selectedTags = ref<Set<PlaybookTag>>(new Set());
const handleTagSelect = (tab: PlaybookTag) => {
  if (selectedTags.value.has(tab)) {
    selectedTags.value.delete(tab);
  } else selectedTags.value.add(tab);
};

const playbooksFiltered = computed(() => {
  return playbooks
    .filter(({ tags }) => {
      return isEmpty(selectedTags.value)
        ? true
        : intersection(tags, [...selectedTags.value]).length;
    })
    .filter(({ title }) => {
      return searchTerm.value === ""
        ? true
        : title.toLowerCase().includes(searchTerm.value);
    });
});
</script>

<style module lang="scss">
.container {
  margin-top: 20px;
  display: grid;
  gap: 8px;
}

.grid {
  margin-top: 12px;
  display: grid;
  grid-template-columns: repeat(2, 240px);
  gap: 2px;
}

.fadeIn {
  &:global(-enter-active) {
    transition: all 0.3s ease-in-out;
  }
  &:global(-enter-from),
  &:global(-leave-to) {
    opacity: 0;
  }
}

.fadeInOut {
  &:global(-enter-active),
  &:global(-leave-active) {
    transition: all 0.3s ease-in-out;
  }
  &:global(-enter-from),
  &:global(-leave-to) {
    opacity: 0;
  }
}
</style>
