<template>
  <DrLayoutPage
    page-title="Settings"
    page-icon="cogwheel"
    :nav-items="availableLinks"
  >
    <RouterView v-slot="{ Component, route }">
      <DrTransitionPage>
        <component :is="Component" :key="route.path" />
      </DrTransitionPage>
    </RouterView>
  </DrLayoutPage>
</template>

<script lang="ts" setup>
import { computed } from "vue";
import { DrLayoutPage } from "@shared/ui/dr-layouts";
import { DrTransitionPage } from "@shared/ui/dr-transitions";

import { ORG_MEMBER_DATA } from "@app/setups";
import { DrStore } from "@app/vue";
import { loadFromLocalStorage } from "@app/vue/common";
import { isBillingVisible } from "@drVue/components/client-dashboard/billing/utils";
import {
  getPipelineSettingsAccess,
  PIPELINE_SETTINGS_ACCESS_TYPES,
} from "@drVue/components/client-dashboard/utils";

import type { NavItem } from "@shared/ui/dr-nav";

type PageLink = NavItem & {
  shown: boolean;
};

const IS_MULTI_ROOM_UPDATES_ENABLED =
  !!loadFromLocalStorage("dr:enable_mru") ||
  ORG_MEMBER_DATA.client.enable_multiroom_updates;

const settingsAccess = getPipelineSettingsAccess(DrStore.state);

const links: PageLink[] = [
  {
    id: "general",
    name: "General",
    path: "/settings/general",
    shown: !!settingsAccess,
  },
  {
    id: "metadata",
    name: "Custom Fields",
    path: "/settings/metadata",
    shown: !!settingsAccess,
  },
  {
    id: "auth",
    name: "Authentication",
    path: "/settings/auth",
    shown: !!settingsAccess,
  },
  {
    id: "pipeline",
    name: "Pipeline access",
    path: "/settings/members",
    shown:
      settingsAccess === PIPELINE_SETTINGS_ACCESS_TYPES.PipelineOnly ||
      settingsAccess === PIPELINE_SETTINGS_ACCESS_TYPES.ExecutionSuit,
  },
  {
    id: "billing",
    name: "Billing",
    path: "/settings/billing",
    shown: isBillingVisible(),
  },
  {
    id: "multi-room-updates",
    name: "Multi-room updates",
    path: "/settings/multi-room-updates",
    shown: IS_MULTI_ROOM_UPDATES_ENABLED,
  },
  {
    id: "trash",
    name: "Deals trash",
    path: "/settings/deals-trash",
    shown:
      settingsAccess === PIPELINE_SETTINGS_ACCESS_TYPES.PipelineOnly ||
      settingsAccess === PIPELINE_SETTINGS_ACCESS_TYPES.ExecutionSuit,
  },
];

const availableLinks = computed(() => links.filter((link) => link.shown));
</script>
