<template>
  <div
    :class="{
      [$style.content]: true,
      [$style.content_isVertical]: activity.verb === 'task_description',
    }"
  >
    <div :class="$style.action">
      {{ content.action }}
    </div>

    <div :class="$style.value">
      <Component
        :is="content.component?.instance"
        v-if="content.component"
        v-bind="content.component.props"
      />

      <DrTruncatedTextTooltip
        v-else-if="content.value"
        :content="content.value"
      >
        <div :class="$style.valueBlock">
          {{ content.value }}
        </div>
      </DrTruncatedTextTooltip>
    </div>
  </div>
</template>

<script setup lang="ts">
import { capitalize } from "lodash-es";
import { computed } from "vue";
import { DrTruncatedTextTooltip } from "@shared/ui/dr-tooltip";
import { DrUserBox } from "@shared/ui/dr-user-box";
import DrEditor from "@shared/ui/editor";

import { DrStore } from "@app/vue";
import { useDocumentsStore } from "@app/vue/store/pinia/room/documents/documents";
import { drUserTime } from "@drVue/filters/drUserTime";
import { pinia } from "@drVue/store/pinia";
import { useFindingsStatusesStore } from "@drVue/store/pinia/pipeline/findings-statuses";
import { useFindingsTypesStore } from "@drVue/store/pinia/pipeline/findings-types";
import { useCategoriesStore } from "@drVue/store/pinia/room/categories";
import { useFindingsStore } from "@drVue/store/pinia/room/findings";
import { useTasksStore } from "@drVue/store/pinia/room/tasks";
import { useTasksLabelsStore } from "@drVue/store/pinia/room/tasksLabels/tasksLabels";
import AttachmentsList from "../../Attachments/AttachmentsList.vue";
import FindingItem from "../lists/findings/FindingItem.vue";
import TaskItem from "../lists/tasks/TaskItem.vue";

import type { TaskActivity } from "../use-task-comments";
import type { HEXColor } from "@drVue/utils/color";

interface Props {
  activity: TaskActivity;
}

const props = defineProps<Props>();

const tasksStore = useTasksStore(pinia);
const categoriesStore = useCategoriesStore(pinia);
const tasksLabels = useTasksLabelsStore(pinia);
const findingsStore = useFindingsStore(pinia);
const findingsStatusesStore = useFindingsStatusesStore(pinia);
const findingsTypesStore = useFindingsTypesStore(pinia);
const documentsStore = useDocumentsStore();

const findingsStatusesColorsDict = computed(() =>
  findingsStatusesStore.list.reduce(
    (acc, item) => {
      acc[item.id] = item.color;
      return acc;
    },
    {} as Record<string, HEXColor>,
  ),
);

const findingsTypesColorsDict = computed(() =>
  findingsTypesStore.list.reduce(
    (acc, item) => {
      acc[item.id] = item.color;
      return acc;
    },
    {} as Record<string, HEXColor>,
  ),
);

const getStatusNameById = (statusId: number) => {
  const status = DrStore.getters["room/tasksStatuses/byId"](statusId);
  return status ? status.name : "";
};

type ContentComponent =
  | {
      instance: typeof DrEditor;
      props: InstanceType<typeof DrEditor>["$props"];
    }
  | {
      instance: typeof DrUserBox;
      props: InstanceType<typeof DrUserBox>["$props"];
    }
  | {
      instance: typeof AttachmentsList;
      props: InstanceType<typeof AttachmentsList>["$props"];
    }
  | {
      instance: typeof TaskItem;
      props: InstanceType<typeof TaskItem>["$props"];
    }
  | {
      instance: typeof FindingItem;
      props: InstanceType<typeof FindingItem>["$props"];
    };

const content = computed(() => {
  let action: string = "";
  let value: string = "";
  let component: ContentComponent | undefined = undefined;

  const { verb, content, target, object } = props.activity;

  if (["task_category", "task_add"].includes(verb) && target) {
    const category = target.id ? categoriesStore.categories[target.id] : null;

    if (category) {
      switch (verb) {
        case "task_add":
          action = "Created a new request in category:";
          break;
        case "task_category":
          action = "Moved the request to category:";
          break;
      }

      component = {
        instance: TaskItem,
        props: {
          item: {
            category,
            tasks: [],
          },
          disallowRemove: true,
          singleMode: true,
          asLink: true,
        },
      };
    } else {
      switch (verb) {
        case "task_add":
          action = "Created a new request.";
          break;
        case "task_category":
          action = "Moved the request.";
          break;
      }
    }
  }

  if (verb === "task_due_date") {
    if (content.new_value) {
      action = "Updated the due date to:";
      value = drUserTime(content.new_value, "full-date") || "";
    } else {
      action = "Cleared the due date.";
    }
  }

  if (verb === "task_start_date") {
    if (content.new_value) {
      action = "Updated the start date to:";
      value = drUserTime(content.new_value, "full-date") || "";
    } else {
      action = "Cleared the start date.";
    }
  }

  if (verb === "task_body") {
    action = "Updated the description to:";
    value = content.new_value;
  }

  if (verb === "task_description") {
    action = "Updated the description to:";
    if (content.new_value) {
      component = {
        instance: DrEditor,
        props: {
          editable: false,
          modelValue: content.new_value,
        },
      };
    } else {
      value = "null";
    }
  }

  if (verb === "task_priority") {
    action = "Updated the priority to:";
    value = capitalize(content.new_value);
  }

  if (verb === "task_status") {
    action = "Updated the status to:";
    const statusName = getStatusNameById(content.new_value);
    value = statusName || "deleted status";
  }

  if (verb === "task_title") {
    action = "Updated the title to:";
    value = content.new_value;
  }

  if (verb === "task_is_archived") {
    action = "The request has been";
    value = content.new_value ? "archived" : "restored";
  }

  if (
    [
      "task_assignee_add",
      "task_assignee_delete",
      "task_follower_add",
      "task_follower_delete",
      "task_reviewer_add",
      "task_reviewer_delete",
    ].includes(verb) &&
    object
  ) {
    switch (verb) {
      case "task_assignee_add":
        action = "Added assignee:";
        break;
      case "task_assignee_delete":
        action = "Removed assignee:";
        break;
      case "task_follower_add":
        action = "Added follower:";
        break;
      case "task_follower_delete":
        action = "Removed follower:";
        break;
      case "task_reviewer_add":
        action = "Added reviewer:";
        break;
      case "task_reviewer_delete":
        action = "Removed reviewer:";
        break;
    }

    const user = DrStore.state.room.members.members[object.id];

    if (user) {
      component = {
        instance: DrUserBox,
        props: {
          users: [user],
        },
      };
    } else {
      value = "deleted user";
    }
  }

  if (
    ["task_attach_document", "task_detach_document"].includes(verb) &&
    object
  ) {
    switch (verb) {
      case "task_attach_document":
        action = "Attached document:";
        break;
      case "task_detach_document":
        action = "Removed document:";
        break;
    }

    const doc = object.id ? documentsStore.fileByIdMap[object.id] : null;

    if (doc) {
      component = {
        instance: AttachmentsList,
        props: {
          items: [doc],
          condensed: true,
        },
      };
    } else {
      value = "deleted document";
    }
  }

  if (["task_attach_folder", "task_detach_folder"].includes(verb) && object) {
    switch (verb) {
      case "task_attach_folder":
        action = "Attached folder:";
        break;
      case "task_detach_folder":
        action = "Removed folder:";
        break;
    }

    const folder = object.id ? documentsStore.fileByIdMap[object.id] : null;
    if (folder) {
      component = {
        instance: AttachmentsList,
        props: {
          items: [folder],
          condensed: true,
        },
      };
    } else {
      value = "deleted folder";
    }
  }

  if (verb === "task_attach_label") {
    action = "Attached label:";
    const label = tasksLabels.dict[object.id];
    value = label?.name || "deleted label";
  }

  if (verb === "task_detach_label") {
    action = "Removed label:";
    const label = tasksLabels.dict[object.id];
    value = label?.name || "deleted label";
  }

  if (verb === "task_reviewer_mark_complete") {
    action = content.new_value ? "Marked reviewed" : "Marked unreviewed";
  }

  if (
    ["task_dependency_add", "task_dependency_delete"].includes(verb) &&
    object
  ) {
    switch (verb) {
      case "task_dependency_add":
        action = "Added a new dependency:";
        break;
      case "task_dependency_delete":
        action = "Removed dependency:";
        break;
    }

    const task = object.id ? tasksStore.tasks[object.id] : null;
    if (task) {
      component = {
        instance: TaskItem,
        props: {
          item: {
            tasks: [task],
          },
          disallowRemove: true,
          singleMode: true,
          asLink: true,
        },
      };
    } else {
      value = "deleted task";
    }
  }

  if (["finding_attach_task", "finding_detach_task"].includes(verb) && target) {
    switch (verb) {
      case "finding_attach_task":
        action = "Finding is added from the request:";
        break;
      case "finding_detach_task":
        action = "Finding is removed from the request:";
        break;
    }

    const finding = target.id ? findingsStore.dict[target.id] : undefined;
    if (finding) {
      component = {
        instance: FindingItem,
        props: {
          id: finding.id,
          title: finding.title,
          keyString: finding.key,
          keyColor: findingsStatusesColorsDict.value[finding.status_id],
          typeColor: findingsTypesColorsDict.value[finding.type_id],
          assigneeUids: finding.assignees,
          disallowRemove: true,
          singleMode: true,
        },
      };
    } else {
      value = "deleted finding";
    }
  }

  return {
    action,
    value,
    component,
  };
});
</script>

<style lang="scss" module>
@use "@app/styles/scss/colors";
@use "@app/styles/scss/spacing";
@use "@app/styles/scss/typography";

.content {
  display: flex;
  max-width: 100%;
  overflow: hidden;
  column-gap: spacing.$xxs;
}

.content_isVertical {
  flex-direction: column;
}

.action {
  flex: 0;
  font: typography.$body_regular;
  line-height: 28px;
  color: colors.$pr-500;
  white-space: nowrap;
}

.value {
  flex: 0 1 auto;
  min-width: 0;
  min-height: 28px;
  display: flex;
  align-items: center;

  > * {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.valueBlock {
  padding: 0 spacing.$xs;
  font: typography.$caption_regular;
  border-radius: 8px;
  background-color: colors.$pr-50;
  color: colors.$pr-900;
}
</style>
