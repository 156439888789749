import type {
  RoomInvite,
  RoomMember,
} from "@drVue/store/modules/room/members/RoomMembersApiService";
import type { Dictionary } from "@drVue/types";

export class RoomMembersState {
  public updatePromise: Promise<RoomMember[]> | null = null;

  public members: Dictionary<RoomMember> = {}; // active and archived members (without invites)
  public membersByUid: Dictionary<RoomMember> = {}; // the same as "members" but with "uid" as key
  public membersList: RoomMember[] = []; // active members (without archived, invites)

  public invites: Dictionary<RoomInvite> = {};
  public invitesByUid: Dictionary<RoomInvite> = {};
  public invitesList: RoomInvite[] = []; // only invites
  public inviteResending: { [key: number]: boolean } = {};

  public activeMembersList: (RoomMember | RoomInvite)[] = []; // active members and invites (without archived users)

  public isLoading: boolean | null = null;
  public isError: boolean | null = null;
}
