<template>
  <ElForm
    v-if="contact"
    ref="addContactForm"
    label-position="top"
    :disabled="isSubmitting"
    :model="contact"
    :rules="rules"
    @validate="saveFormValidationState"
    @submit.prevent="createContact"
  >
    <ElRow :gutter="30">
      <ElCol :sm="12" :md="8" :lg="6">
        <ElFormItem
          label="Email"
          prop="email"
          :error="getBackendError(`email`)"
        >
          <ElAutocomplete
            v-model="contact.email"
            style="width: 100%"
            placeholder="Type to search..."
            value-key="email"
            autofocus
            :fetch-suggestions="queryContacts"
            :trigger-on-focus="false"
            @select="setupContact"
          />
        </ElFormItem>
      </ElCol>

      <ElCol :sm="12" :md="8" :lg="6">
        <ElFormItem
          label="First name"
          prop="first_name"
          :error="getBackendError(`first_name`)"
        >
          <ElInput v-model="contact.first_name" :disabled="isEditingDisabled" />
        </ElFormItem>
      </ElCol>

      <ElCol :sm="12" :md="8" :lg="6">
        <ElFormItem
          label="Last name"
          prop="last_name"
          :error="getBackendError(`last_name`)"
        >
          <ElInput v-model="contact.last_name" :disabled="isEditingDisabled" />
        </ElFormItem>
      </ElCol>

      <ElCol :sm="12" :md="8" :lg="6">
        <ElFormItem
          label="Company"
          prop="company"
          :error="getBackendError(`company`)"
        >
          <ElInput v-model="contact.company" :disabled="isEditingDisabled" />
        </ElFormItem>
      </ElCol>

      <ElCol :sm="12" :md="8" :lg="6">
        <ElFormItem label="Role" prop="title" :error="getBackendError(`title`)">
          <ElInput v-model="contact.title" :disabled="isEditingDisabled" />
        </ElFormItem>
      </ElCol>

      <ElCol :sm="12" :md="8" :lg="6">
        <ElFormItem
          label="Phone"
          prop="office_number"
          :error="getBackendError(`office_number`)"
        >
          <ElInput
            v-model="contact.office_number"
            :disabled="isEditingDisabled"
          />
        </ElFormItem>
      </ElCol>

      <ElCol
        v-for="field of customFields"
        :key="field.id"
        :sm="12"
        :md="8"
        :lg="6"
      >
        <ElFormItem
          :key="field.key"
          :label="field.label"
          :error="getBackendError(`custom_data.${field.key}`)"
        >
          <!-- @vue-skip: `custom_data` is possibly `undefined` -->
          <DrDatePicker
            v-if="field.field_type === FieldItemTypes.Date"
            v-model="contact.custom_data[field.key]"
            type="date"
            :disabled="isEditingDisabled"
            clearable
            style="width: 100%"
          />

          <!-- @vue-expect-error: `custom_data` is possibly `undefined` -->
          <ElSelect
            v-else-if="field.field_type === FieldItemTypes.Select"
            v-model="contact.custom_data[field.key]"
            :disabled="isEditingDisabled"
            style="width: 100%"
            clearable
            placeholder=""
          >
            <!-- @vue-expect-error: Property `options` does not exist on type of extra -->
            <ElOption
              v-for="item in field.extra.options"
              :key="item"
              :label="item"
              :value="item"
            />
          </ElSelect>

          <!-- @vue-expect-error: `custom_data` is possibly `undefined` -->
          <ElSelect
            v-else-if="field.field_type === FieldItemTypes.MultiSelect"
            v-model="contact.custom_data[field.key]"
            :disabled="isEditingDisabled"
            style="width: 100%"
            clearable
            multiple
            placeholder=""
          >
            <!-- @vue-expect-error: Property `options` does not exist on type of extra -->
            <ElOption
              v-for="item in field.extra.options"
              :key="item"
              :label="item"
              :value="item"
            />
          </ElSelect>

          <!-- @vue-expect-error: `custom_data` is possibly `undefined` -->
          <ElInput
            v-else
            v-model="contact.custom_data[field.key]"
            clearable
            autosize
            :disabled="isEditingDisabled"
            :type="
              field.field_type === FieldItemTypes.Number ? 'number' : 'textarea'
            "
          />
        </ElFormItem>
      </ElCol>
    </ElRow>

    <div v-if="isContactAlreadyAdded">
      <!-- eslint-disable-next-line vue/no-v-html -->
      <span v-html="addedDescription" />

      <div class="existing-contact">
        <div class="existing-contact__col-left">
          <!-- @vue-expect-error: `existingContact` is not assignable to IOrgUser -->
          <UserAvatar :user="existingContact" :size="'36px'" />
        </div>
        <div class="existing-contact__col-center">
          <!-- @vue-expect-error: `existingContact` is not assignable to IOrgUser -->
          <ContactInfo
            class="contact__info--existing"
            :contact="existingContact"
          />
        </div>
      </div>
    </div>

    <div class="text-right">
      <ElButton size="large" @click="cancel">Cancel</ElButton>
      <ElButton
        v-if="!existingContact"
        type="primary"
        size="large"
        :disabled="isSubmitting"
        :loading="isSubmitting"
        @click="createContact"
      >
        Create contact and add to deal
      </ElButton>
      <ElButton
        v-if="!!existingContact"
        type="primary"
        size="large"
        :disabled="isSubmitting || isContactAlreadyAdded"
        :loading="isSubmitting"
        @click="addContact(existingContact)"
      >
        Add contact to deal
      </ElButton>
    </div>
  </ElForm>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import DrDatePicker from "@shared/ui/dr-datepicker/DrDatepicker.vue";
import DrForm from "@shared/ui/dr-form";
import { UserAvatar } from "@shared/ui/users";

import { ORG_MEMBER_DATA } from "@setups/data";
import { FieldItemTypes } from "@drVue/api-service/client-dashboard";
import { deserializeCustomData } from "@drVue/api-service/parse";
import { $notifySuccess } from "@drVue/common";
import ContactInfo from "@drVue/components/client-dashboard/deals/Contacts/ContactInfo.vue";
import { OrgUsersApiService } from "@drVue/store/modules/client-dashboard/org-users/OrgUsersApiService";

import type { FieldItem } from "@drVue/api-service/client-dashboard";
import type { UpdateContactsPayload } from "@drVue/store/modules/client-dashboard/deals/DealsActions";
import type { GetByEmailResponse } from "@drVue/store/modules/client-dashboard/org-users/OrgUsersApiService";
import type { OrgUser } from "@drVue/store/modules/client-dashboard/org-users/types";
import type { ElForm, FormItemProp } from "element-plus";

interface Data {
  validationTimeoutId: number | null;
  rules: any;
  usersApiService: any;
  contact: Partial<OrgUser>;
  existingContact: null | OrgUser;
  getContactTimeoutId: null | number;
  isEmailInvalid: boolean;
}

export default defineComponent({
  name: "AddContactForm",
  components: {
    ContactInfo,
    DrDatePicker,
    UserAvatar,
  },
  extends: DrForm,
  inject: ["dealId"],
  emits: ["contact-added", "cancel"],
  data(): Data {
    const thany = this as any;

    return {
      validationTimeoutId: null,
      rules: {
        email: [
          {
            required: true,
            message: "This field may not be blank.",
            trigger: ["blur"],
          },
          {
            validator: (
              rule: any,
              value: string,
              callback: (error?: Error) => void,
            ) => {
              // Treat it as valid if there is no value.
              if (!value || value.length === 0) callback();

              if (thany.validationTimeoutId) {
                clearTimeout(thany.validationTimeoutId);
              }

              thany.validationTimeoutId = window.setTimeout(() => {
                thany.usersApiService.getByEmail(value).then(
                  (r: GetByEmailResponse) => {
                    if (r.email_invalid) {
                      callback(new Error("Enter a valid email address."));
                    }

                    callback();
                  },
                  () => callback(new Error("Can't validate the email.")),
                );
              }, 500);
            },
            trigger: ["blur", "change"],
          },
        ],
        first_name: [
          {
            required: true,
            message: "This field may not be blank.",
            trigger: ["blur"],
          },
        ],
        last_name: [
          {
            required: true,
            message: "This field may not be blank.",
            trigger: ["blur"],
          },
        ],
      },
      usersApiService: new OrgUsersApiService(),
      contact: {
        email: "",
        first_name: "",
        last_name: "",
        title: "",
        company: "",
        office_number: "",
        custom_data: {},
      },
      existingContact: null,
      getContactTimeoutId: null,
      isEmailInvalid: true,
    };
  },
  computed: {
    FieldItemTypes(): any {
      return FieldItemTypes;
    },
    dealContactsIds(): string[] {
      return this.$store.getters["clientDashboard/deals/getContactsOfDeal"](
        (this as any).dealId, // injected
      );
    },
    customFields(): FieldItem[] {
      return this.$store.getters["clientDashboard/customFields/byObjectType"](
        "user",
      );
    },
    addedDescription(): any {
      return `It seems like a contact with the email address <b>${this.existingContact?.email}</b> already exists and is associated with the deal.`;
    },
    isContactAlreadyAdded(): any {
      if (this.dealContactsIds.length === 0) return false;
      if (!this.existingContact) return false;

      return this.dealContactsIds.indexOf(this.existingContact.id) > -1;
    },
    addContactForm(): any {
      return this.$refs.addContactForm as typeof ElForm;
    },
    isEditingDisabled(): any {
      const hasPendingRequest = !!this.getContactTimeoutId;
      const isContactExists = !!this.existingContact;

      return hasPendingRequest || this.isEmailInvalid || isContactExists;
    },
  },
  watch: {
    "contact.email"(email: string) {
      email = email.trim();

      if (email.length === 0) {
        this.existingContact = null;
        this.resetControl();
        this.cancelPendingContactRequest();
        return;
      }

      if (this.existingContact) {
        if (this.existingContact.email !== email) {
          this.resetControl();
        }
      }

      this.existingContact = null;
      this.cancelPendingContactRequest();

      // TODO: rewrite with _.debounce and fix annoying bug for slow networks.
      this.getContactTimeoutId = window.setTimeout(
        () =>
          this.usersApiService
            .getByEmail(email)
            .then(
              (r: GetByEmailResponse) => {
                const user = r.user;

                if (!user) {
                  this.existingContact = null;
                  this.resetControl();
                  return;
                }

                // A request finished but user had changed the email while the
                // request was in progress.
                if (user.email !== this.contact.email) {
                  // We just ignore the request as it never happened at all.
                  return;
                }

                deserializeCustomData(user.custom_data, this.customFields);

                this.setupContact(user);
              },
              () => {},
            )
            .finally(() => (this.getContactTimeoutId = null)),
        500,
      );
    },
  },
  methods: {
    saveFormValidationState(
      prop: FormItemProp,
      isValid: boolean,
      message: string,
    ) {
      if (prop === "email") {
        this.isEmailInvalid = !isValid;
      }
    },
    resetControl() {
      this.contact.first_name = "";
      this.contact.last_name = "";
      this.contact.title = "";
      this.contact.company = "";
      this.contact.office_number = "";
      this.contact.custom_data = {};

      setTimeout(() => this.addContactForm.clearValidate());

      this.cancelPendingContactRequest();
    },
    setupContact(value: Record<string, any>) {
      const contact = value as OrgUser;

      this.existingContact = contact;

      this.contact.email = contact.email;
      this.contact.first_name = contact.first_name;
      this.contact.last_name = contact.last_name;
      this.contact.title = contact.title;
      this.contact.company = contact.company;
      this.contact.office_number = contact.office_number;
      this.contact.custom_data = contact.custom_data;

      setTimeout(() => this.addContactForm.clearValidate());
    },
    cancelPendingContactRequest() {
      if (this.getContactTimeoutId) {
        clearTimeout(this.getContactTimeoutId);
        this.getContactTimeoutId = null;
      }
    },
    queryContacts(query: string, cb: (results: OrgUser[]) => void) {
      const suggestedEmails =
        this.$store.state.clientDashboard.orgUsers.items.reduce(
          (acc: OrgUser[], user: OrgUser) => {
            const dealContactsIds = this.dealContactsIds;
            if (dealContactsIds.includes(user.id)) return acc;

            if (user.email.includes(query)) {
              acc.push(user);
            }

            return acc;
          },
          [],
        );

      cb(suggestedEmails);
    },
    addContact(contact: Pick<OrgUser, "id">) {
      if (!contact) return;

      this.$store
        .dispatch("clientDashboard/deals/updateContacts", {
          dealId: (this as any).dealId, // injected
          usersIdsToAdd: [contact.id],
          usersIdsToRemove: [],
        } as UpdateContactsPayload)
        .then(() => {
          this.existingContact = null;
          this.$emit("contact-added");

          $notifySuccess("The contact was successfully added");
        });
    },
    createContact() {
      if (this.existingContact || this.isSubmitting) return;

      this.addContactForm
        .validate()
        .then(() =>
          this.submitAction(
            "clientDashboard/orgUsers/create",
            this.contact,
            `Failed to create the contact.`,
          ),
        )
        .then((createdContact: OrgUser) =>
          this.$store.dispatch("clientDashboard/deals/updateContacts", {
            dealId: (this as any).dealId, // injected
            usersIdsToAdd: [createdContact.id],
            usersIdsToRemove: [],
          } as UpdateContactsPayload),
        )
        .then(
          () => {
            if (!ORG_MEMBER_DATA.group.is_administrator) {
              this.$store.dispatch("clientDashboard/orgUsers/load");
            }
            this.$emit("contact-added");

            $notifySuccess("The contact was successfully created");
          },
          () => {},
        );
    },
    cancel() {
      this.$emit("cancel");
    },
  },
});
</script>
