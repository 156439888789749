<template>
  <DrDrawer
    :shown="isVisible"
    :title="$t('room_updates.review_update')"
    :submit="$t('room_updates.apply_updates')"
    size="xl"
    no-footer
    @close="close"
  >
    <template #description>
      {{ $t("room_updates.review_applied_description") }}
    </template>

    <div :class="$style.container">
      <div v-if="hasUnappliedChanges" :class="$style.unappliedMessage">
        <DrIcon
          name="exclamation-triangle"
          :class="$style.triangle"
          size="sm"
        />
        {{ $t("room_updates.there_are_unapplied_updates") }}
      </div>

      <DrVxeGrid
        :set-grid-ref="setGridRef"
        :class="$style.changesTable"
        :tree-config="{
          rowField: 'id',
          showIcon: false,
          expandAll: true,
        }"
        :data="tableRows"
        :columns="tableColumns.columns"
        :row-class-name="getRowClassName"
        :show-header="false"
        outlined
      >
        <template #empty>{{ $t("room_updates.no_changes_found") }}</template>
      </DrVxeGrid>
    </div>
  </DrDrawer>
</template>

<script setup lang="ts">
import { computed, ref, useCssModule } from "vue";
import { useI18n } from "vue-i18n";
import { DrDrawer } from "@shared/ui/dr-drawer";
import DrIcon from "@shared/ui/dr-icon";
import DrVxeGrid from "@shared/ui/dr-vxe-grid";

import { DrStore } from "@app/vue";
import { CHANGE_OBJECT_TYPE, isCompleted, MultiRoomUpdateService } from "./api";
import {
  type ChangeRow,
  type ChildChangeRow,
  isParentRow,
  type ParentChangeRow,
  TableColumnsApplied,
} from "./tableColumns";
import { type ChangeKey, getChangeKey } from "./utils";

import type { ChangeOperation, RoomUpdateBatch } from "./api";
import type { Room } from "@app/vue/store/modules/client-dashboard/deals/types";
import type { VxeGridInstance, VxeTablePropTypes } from "vxe-table";

const style = useCssModule();

const { t } = useI18n();

const getRoomByUid = DrStore.getters["clientDashboard/deals/getRoomByUid"];

const api = new MultiRoomUpdateService();
const tableColumns = new TableColumnsApplied();

const changesTableRef = ref<VxeGridInstance<ChangeRow> | null>(null);
const setGridRef = (ref: VxeGridInstance) => (changesTableRef.value = ref);
const getRowClassName: VxeTablePropTypes.RowClassName<ChangeRow> = ({ row }) =>
  isParentRow(row) ? style.parentRow : "";

const isVisible = ref(false);

const batch = ref<RoomUpdateBatch | null>(null);

const hasUnappliedChanges = computed(() => {
  return (
    batch.value?.workflows.some(
      (wf) =>
        Array.isArray(wf.unapplied_changes) && wf.unapplied_changes.length,
    ) ?? false
  );
});

const OBJECT_TYPE_LABELS = computed(() => {
  return {
    [CHANGE_OBJECT_TYPE.CATEGORY]: t("shared.worklists"),
    [CHANGE_OBJECT_TYPE.TASK]: t("shared.requests"),
    [CHANGE_OBJECT_TYPE.DOCUMENT]: t("shared.documents"),
    [CHANGE_OBJECT_TYPE.FOLDER]: t("shared.folders"),
    [CHANGE_OBJECT_TYPE.GROUP_PERMISSION_MEMBER]: t("shared.users"),
    [CHANGE_OBJECT_TYPE.GROUP_PERMISSION]: t("shared.user_groups"),
  };
});

const unappliedChangesMapByRoomUidMap = computed<
  Map<Room["uid"], Map<ChangeKey, ChangeOperation>>
>(() => {
  if (!batch.value) return new Map();

  return batch.value.workflows.reduce((map, wf) => {
    if (!isCompleted(wf)) throw new Error("Workflow should be completed");

    wf.unapplied_changes.forEach((ch) => {
      const unappliedChangesMap =
        map.get(wf.trg_room_id) ?? new Map<ChangeKey, ChangeOperation>();
      const key: ChangeKey = getChangeKey(ch);

      if (!unappliedChangesMap.has(key)) unappliedChangesMap.set(key, ch);

      map.set(wf.trg_room_id, unappliedChangesMap);
    });

    return map;
  }, new Map<Room["uid"], Map<ChangeKey, ChangeOperation>>());
});

// We want to make a map: trg_room_id -> [changes]
const reviewedChangesByRoomUidMap = computed<
  Map<Room["uid"], ChangeOperation[]>
>(() => {
  if (!batch.value) return new Map();

  return batch.value.workflows.reduce((map, wf) => {
    if (!isCompleted(wf)) throw new Error("Workflow should be completed");

    map.set(wf.trg_room_id, [...wf.reviewed_changes]);

    return map;
  }, new Map<Room["uid"], ChangeOperation[]>());
});

const tableRows = computed(() => {
  const rows: ParentChangeRow[] = [];

  for (const [
    roomId,
    reviewedChanges,
  ] of reviewedChangesByRoomUidMap.value.entries()) {
    const room: Room = getRoomByUid(roomId);

    const unappliedChanges = unappliedChangesMapByRoomUidMap.value.get(roomId);

    rows.push({
      id: roomId,
      label: room?.title ?? "",
      batchId: batch.value?.id ?? "",
      children: reviewedChanges.map((change): ChildChangeRow => {
        const key = getChangeKey(change);
        return {
          id: key,
          isSuccessful: !unappliedChanges?.has(getChangeKey(change)),
          change,
        };
      }),
    });
  }

  return rows;
});

const open = (batchId: RoomUpdateBatch["id"]) => {
  isVisible.value = true;

  api.getBatch(batchId).then((b) => {
    batch.value = b;
  });
};

const close = () => {
  isVisible.value = false;

  batch.value = null;
};

defineExpose({
  open,
  close,
});
</script>

<style module lang="scss">
@use "@app/styles/scss/colors";

.container {
  display: flex;
  flex-direction: column;
  height: 100%;
  flex: 0 0;
}

.triangle {
  color: colors.$ad-10_5;
  margin-right: 5px;
}

.unappliedMessage {
  margin-left: 10px;
  margin-bottom: 25px;
}

.changesTable {
  :global {
    .vxe-table--render-default {
      .vxe-tree-cell {
        padding-left: 0 !important;
      }

      .vxe-body--column {
        height: 36px !important;
      }
    }

    .vxe-cell--checkbox {
      justify-content: flex-start !important;
    }
  }
}

.parentRow {
  background-color: colors.$pr-50;
}
</style>
