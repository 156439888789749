<template>
  <EditFieldContainer>
    <ElSelect
      ref="selectRef"
      style="width: 100%"
      :model-value="editProps.value"
      :placeholder="editProps.schema.placeholder"
      :persistent="false"
      clearable
      popper-class="dr-popper--inline-control"
      v-bind="editProps.veeField"
      @change="updateValue"
      @visible-change="emitIfHasBeenClosed"
    >
      <ElOption
        v-for="opt in editProps.extra.select_options"
        :key="opt.value ?? opt.label"
        :label="opt.label"
        :value="opt.value ?? opt.label"
        :disabled="opt.isDisabled"
      />
    </ElSelect>
  </EditFieldContainer>
</template>

<script setup lang="ts">
import { ElSelect } from "element-plus";
import { onMounted, ref } from "vue";

import EditFieldContainer from "./EditFieldContainer.vue";

import type { EditFieldProps } from "../types";

interface Props {
  editProps: EditFieldProps;
}

const props = defineProps<Props>();

const initialValue = props.editProps.value;
let currentValue = props.editProps.value;

// @change event is fired before @visible-change, so we can update the current
// selected value and then decide what to do next.
const updateValue = (value: string) => {
  currentValue = value;
};

const emitIfHasBeenClosed = (visible: boolean) => {
  const hasBeenClosed = !visible;

  if (hasBeenClosed) {
    const hasValueChanged = currentValue !== initialValue;

    if (hasValueChanged) props.editProps.submitField();
    else props.editProps.quitEditModeConfirm();
  }
};

const selectRef = ref<InstanceType<typeof ElSelect> | null>(null);
onMounted(() => {
  if (!selectRef.value) return;
  selectRef.value.states.hoveringIndex = 0;
  selectRef.value.toggleMenu();
});
</script>
