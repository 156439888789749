<template>
  <div
    class="dash-content-panel-info"
    :class="{
      'dash-content-panel-info--editing': isEditing,
      'dash-content-panel-info--editable cursor-pointer': !readOnly,
    }"
    @click="edit()"
  >
    <!-- View mode -->
    <div v-if="!isEditing" class="dash-content-panel-row">
      <div
        v-for="chunk in columns.viewFieldsColumns"
        :key="chunk.key"
        class="dash-content-panel-row__col"
      >
        <div
          v-for="field in chunk"
          :key="field.key"
          class="dash-content-panel-row__col-item"
        >
          <Component
            :is="field.component"
            v-if="field.component"
            :meta="field.meta"
            :item="item"
          />
          <div v-else>
            <div class="dash-content-panel-info__item-label">
              {{ field.label }}
            </div>
            <div
              class="dash-content-panel-info__item-value shave"
              style="--line-clamp: 3"
            >
              {{ field.value }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- /View mode -->

    <!-- Edit mode -->
    <ElForm
      v-if="isEditing"
      :model="editItem"
      :disabled="isSubmitting"
      label-position="top"
      size="default"
      class="dash-content-panel-row"
    >
      <div
        v-for="chunk in columns.editFieldsColumns"
        :key="chunk.key"
        class="dash-content-panel-row__col"
      >
        <div
          v-for="field in chunk"
          :key="field.key"
          class="dash-content-panel-row__col-item"
        >
          <Component
            :is="field.component"
            v-if="field.component"
            :meta="field.meta"
            :item="editItem"
            :get-backend-error="getBackendError"
          />
          <template v-else>
            <ElFormItem
              :key="field.key"
              :label="field.label"
              :error="getBackendError(`custom_data.${field.key}`)"
            >
              <DrDatePicker
                v-if="field.field_type === FieldItemTypes.Date"
                v-model="editItem.custom_data[field.key]"
                type="date"
                clearable
                style="width: 100%"
              />

              <ElSelect
                v-else-if="field.field_type === FieldItemTypes.Select"
                v-model="editItem.custom_data[field.key]"
                style="width: 100%"
                clearable
                placeholder=""
              >
                <ElOption
                  v-for="opt in field.extra.options"
                  :key="opt"
                  :label="opt"
                  :value="opt"
                />
              </ElSelect>

              <ElSelect
                v-else-if="field.field_type === FieldItemTypes.MultiSelect"
                v-model="editItem.custom_data[field.key]"
                style="width: 100%"
                clearable
                multiple
                placeholder=""
              >
                <ElOption
                  v-for="opt in field.extra.options"
                  :key="opt"
                  :label="opt"
                  :value="opt"
                />
              </ElSelect>

              <ElInput
                v-else
                v-model="editItem.custom_data[field.key]"
                clearable
                autosize
                :type="
                  field.field_type === FieldItemTypes.Number
                    ? 'number'
                    : 'textarea'
                "
                :class="{
                  'dash-content-panel-info__textarea':
                    field.field_type !== FieldItemTypes.Number,
                }"
              />
            </ElFormItem>
          </template>
        </div>
      </div>
    </ElForm>
    <!-- /Edit mode -->
  </div>
</template>

<script lang="ts">
import { cloneDeep } from "lodash-es";
import { throttle } from "lodash-es";
import { uniqueId } from "lodash-es";
import { defineComponent } from "vue";
import DrDatePicker from "@shared/ui/dr-datepicker/DrDatepicker.vue";
import DrForm from "@shared/ui/dr-form";

import { FieldItemTypes } from "@drVue/api-service/client-dashboard";
import { deserializeCustomData } from "@drVue/api-service/parse";

import type { CustomDataType } from "@drVue/store/modules/client-dashboard/fields/types";
import type { PropType } from "vue";

interface InfoItem {
  id: number;
  custom_data: CustomDataType;
}

interface Data {
  FieldItemTypes: any;
  throttledRedraw: (() => void) | null;
  isEditing: boolean;
  editItem: any;
  windowWidth: number;
}

export default defineComponent({
  name: "Info",
  components: { DrDatePicker },
  extends: DrForm,
  props: {
    item: { required: true, type: Object as PropType<InfoItem> },
    itemType: { required: true, type: String as PropType<string> },
    itemLabel: { required: true, type: String as PropType<string> },
    saveAction: { required: true, type: String as PropType<string> },
    isInitialEditState: {
      required: false,
      default: false,
      type: Boolean as PropType<boolean>,
    },
    readOnly: {
      required: false,
      default: false,
      type: Boolean as PropType<boolean>,
    },
    extraViewFields: {
      required: false,
      default: () => [],
      type: Array as PropType<any[]>,
    },
    extraEditFields: {
      required: false,
      default: () => [],
      type: Array as PropType<any[]>,
    },
  },
  emits: ["form-state-changed"],
  data(): Data {
    return {
      FieldItemTypes: FieldItemTypes,
      throttledRedraw: null,
      isEditing: false,
      editItem: null as any,
      windowWidth: 0,
    };
  },
  computed: {
    editFields(): any {
      return this.$store.getters["clientDashboard/customFields/byObjectType"](
        this.itemType,
      );
    },
    viewFields(): any {
      return this.$store.getters[
        "clientDashboard/customFields/formattedFields"
      ](this.itemType, this.item.custom_data);
    },
    columns(): any {
      const width = this.windowWidth;

      /*
        xs: 0,
        sm: 576px,
        md: 768px,
        lg: 992px,
        xl: 1200px
      */
      let columnsCount = 1;
      if (width >= 768 && width < 992) columnsCount = 2;
      if (width >= 992) columnsCount = 3;

      const viewFieldsColumns = this.splitFields(
        this.viewFields,
        this.extraViewFields,
        columnsCount,
      );

      const editFieldsColumns = this.splitFields(
        this.editFields,
        this.extraEditFields,
        columnsCount,
      );

      return {
        viewFieldsColumns,
        editFieldsColumns,
      };
    },
  },
  watch: {
    editItem: {
      deep: true,
      handler(newValue: any, oldValue: any) {
        if (newValue && oldValue) {
          this.setFormStateAndEmit(true, true, false, this.editItem);
        }
      },
    },
  },
  mounted() {
    if (this.isInitialEditState) {
      this.edit();
    }

    this.windowWidth = window.innerWidth;
    this.throttledRedraw = throttle(
      () => (this.windowWidth = window.innerWidth),
      500,
    );

    window.addEventListener("resize", this.throttledRedraw);
  },
  beforeUnmount() {
    if (typeof this.throttledRedraw === "function") {
      window.removeEventListener("resize", this.throttledRedraw);
    }
  },
  methods: {
    setFormStateAndEmit(
      isChanged: boolean,
      isEditing: boolean,
      isSubmitting: boolean,
      editItem: any,
    ) {
      this.isChanged = isChanged;
      this.isEditing = isEditing;

      this.$emit("form-state-changed", {
        isChanged,
        isEditing,
        isSubmitting,
        editItem,
      });
    },
    edit() {
      if (this.readOnly || this.isEditing) return;

      this.editItem = cloneDeep(this.item);
      deserializeCustomData(this.editItem.custom_data, this.editFields);

      this.setFormStateAndEmit(false, true, false, this.editItem);
    },
    cancel() {
      this.setFormStateAndEmit(false, false, false, this.editItem);
      this.editItem = null as any;
    },
    save() {
      if (this.isSubmitting) return;

      this.setFormStateAndEmit(true, true, true, this.editItem);

      return this.submitAction(
        this.saveAction,
        this.editItem,
        `Failed to update the ${this.itemLabel} data.`,
      )
        .then(() => {
          this.setFormStateAndEmit(false, false, false, this.editItem);

          this.cancel();
        })
        .catch(() =>
          this.setFormStateAndEmit(true, true, false, this.editItem),
        );
    },
    splitFields(fields: any[], extraFields: any[], columnsCount: number) {
      const chunks: any[][] = Array.from(Array(columnsCount), () => {
        const array: any = [];
        array.key = uniqueId("chunk");
        return array;
      });

      let c = 0;
      for (let i = 0; i < extraFields.length; i++) {
        chunks[c++].push(extraFields[i]);
        if (c >= chunks.length) c = 0;
      }

      for (let i = 0; i < fields.length; i++) {
        chunks[c++].push(fields[i]);
        if (c >= chunks.length) c = 0;
      }

      return chunks;
    },
  },
});
</script>
