import { h } from "vue";

import DynamicField from "@drVue/components/client-dashboard/dynamic-form/DynamicField.vue";
import NotImplementedEdit from "@drVue/components/client-dashboard/dynamic-form/Fields/Edit/NotImplementedEdit.vue";
import NotImplementedView from "@drVue/components/client-dashboard/dynamic-form/Fields/View/NotImplementedView.vue";
import { FieldSchemaType } from "@drVue/components/client-dashboard/dynamic-form/types";
import DateEdit from "./Fields/Edit/DateEdit.vue";
import DateTimeEdit from "./Fields/Edit/DateTimeEdit.vue";
import MultiSelectEdit from "./Fields/Edit/MultiSelectEdit.vue";
import NumberEdit from "./Fields/Edit/NumberEdit.vue";
import SelectEdit from "./Fields/Edit/SelectEdit.vue";
import TextareaEdit from "./Fields/Edit/TextareaEdit.vue";
import DateView from "./Fields/View/DateView.vue";
import NumberView from "./Fields/View/NumberView.vue";
import SelectView from "./Fields/View/SelectView.vue";
import TextView from "./Fields/View/TextView.vue";

import type {
  EditSlotProps,
  FieldSchema,
  FieldSchemaTypeDefaultComponents,
  ViewSlotProps,
} from "@drVue/components/client-dashboard/dynamic-form/types";
import type { VxeColumnSlotTypes } from "vxe-table";

type EditFieldsTypes =
  | typeof NumberEdit
  | typeof TextareaEdit
  | typeof DateEdit
  | typeof DateTimeEdit
  | typeof SelectEdit
  | typeof MultiSelectEdit;

export const editFieldComponents: FieldSchemaTypeDefaultComponents<EditFieldsTypes> =
  {
    [FieldSchemaType.Number]: NumberEdit,
    [FieldSchemaType.Text]: TextareaEdit,
    [FieldSchemaType.LinkifiedText]: TextareaEdit,
    [FieldSchemaType.Date]: DateEdit,
    [FieldSchemaType.DateTime]: DateTimeEdit,
    [FieldSchemaType.Select]: SelectEdit,
    [FieldSchemaType.MultiSelect]: MultiSelectEdit,

    [FieldSchemaType.Email]: TextareaEdit,
    [FieldSchemaType.Phone]: TextareaEdit,
    [FieldSchemaType.Richtext]: TextareaEdit,
    [FieldSchemaType.Textarea]: TextareaEdit,
    [FieldSchemaType.Switch]: TextareaEdit,
    [FieldSchemaType.Color]: NotImplementedEdit,
  };

type ViewFieldsTypes = typeof TextView | typeof SelectView | typeof NumberView;

export const viewFieldComponents: FieldSchemaTypeDefaultComponents<ViewFieldsTypes> =
  {
    [FieldSchemaType.Number]: NumberView,
    [FieldSchemaType.Text]: TextView,
    [FieldSchemaType.LinkifiedText]: TextView,
    [FieldSchemaType.Textarea]: TextView,
    [FieldSchemaType.Email]: TextView,
    [FieldSchemaType.Phone]: TextView,
    [FieldSchemaType.Date]: DateView,
    [FieldSchemaType.DateTime]: DateView,
    [FieldSchemaType.Select]: SelectView,
    [FieldSchemaType.MultiSelect]: SelectView,

    [FieldSchemaType.Richtext]: NotImplementedView,
    [FieldSchemaType.Switch]: NotImplementedView,
    [FieldSchemaType.Color]: NotImplementedView,
  };

export class TableInlineEditor {
  constructor(
    private submitFn: (value: any, row: any) => Promise<any>,
    private idProp: string,
  ) {}

  fieldRefsMap: Map<string, InstanceType<typeof DynamicField>> = new Map();

  editingFieldKey: string | null = null;

  private getFieldKey = (prop: string, row: any) => {
    return `${row[this.idProp]}_${prop}`;
  };

  setEditingFieldKey = (prop: string | null, row: any) => {
    this.editingFieldKey = prop === null ? null : this.getFieldKey(prop, row);
  };

  closeEditingFieldThenGrant = (grant: () => void) => {
    if (this.editingFieldKey === null) {
      grant();
    } else {
      const currentEditingField = this.fieldRefsMap.get(this.editingFieldKey);
      if (!currentEditingField) return;

      const typedCurrentEditingField = currentEditingField as InstanceType<
        typeof DynamicField
      >;

      typedCurrentEditingField
        .quitEditModeConfirm("Do you want to save your changes?")
        .then(grant);
    }
  };

  renderInlineField = (
    schema: FieldSchema,
    params: VxeColumnSlotTypes.DefaultSlotParams,
  ) => {
    const { $table, row } = params;

    return h(
      DynamicField,
      {
        ref: (instance) => {
          this.fieldRefsMap.set(
            this.getFieldKey(schema.prop, row),
            instance as InstanceType<typeof DynamicField>,
          );
        },
        schema,
        mode: "table",
        entity: row,
        showLabel: false,
        onToggleEdit: (prop: string) => this.setEditingFieldKey(prop, row),
        onRequestEdit: this.closeEditingFieldThenGrant,
        submitFn: (value: any) => this.submitFn(value, row),
        viewComponentsGetter: (schema: FieldSchema) => {
          if (schema.type === FieldSchemaType.Custom) {
            return schema.viewComponent;
          }

          return viewFieldComponents[schema.type];
        },
        editComponentsGetter: (schema: FieldSchema) => {
          if (schema.type === FieldSchemaType.Custom) {
            return schema.editComponent;
          }

          return editFieldComponents[schema.type];
        },
      },
      {
        view: (slotProps: ViewSlotProps) => {
          if (schema.type === FieldSchemaType.Custom) {
            return h(schema.viewComponent, {
              "data-test-field-prop": schema.prop,
              viewProps: {
                ...slotProps.viewProps,
                enterEditMode: (e?: Event) => {
                  if (e instanceof Event) {
                    e.preventDefault();
                    e.stopPropagation();
                  }

                  $table.closeTooltip();
                  slotProps.viewProps.enterEditMode();
                },
              },
            });
          }

          return null;
        },
        edit: (slotProps: EditSlotProps) => {
          if (schema.type === FieldSchemaType.Custom) {
            return h(schema.editComponent, {
              editProps: slotProps.editProps,
            });
          }

          return null;
        },
      },
    );
  };
}
