import VueDrUploadButton from "@shared/ui/dr-upload-button/DrUploadButton.vue";
import VueDrUploadDisabledButton from "@shared/ui/dr-upload-button/DrUploadDisabledButton.vue";
import VueDrUploadDialog from "@shared/ui/dr-upload-dialog/DrUploadDialog.vue";

import VueDocVerQaDialog from "@drVue/components/room/ai/DocVerQaDialog.vue";
import VueAiReportsPageContent from "@drVue/components/room/ai/ReportsPage/AiReportsPageContent.vue";
import VueDocumentsFolderTree from "@drVue/components/room/documents/DocumentsFolderTree.vue";
import VueDocumentsPage from "@drVue/components/room/documents/DocumentsPage.vue";
import VueDocumentsTableOptionsButton from "@drVue/components/room/documents/DocumentsTableOptionsButton.vue";
import documentsSearchCompletionBox from "./ng/documents/components/documentsSearchCompletionBox";
import drDocumentInformation from "./ng/documents/components/drDocumentInformation";
import drDocumentsMatchText from "./ng/documents/components/drDocumentsMatchText";
import drDownloadDisallowedInfo from "./ng/documents/components/drDownloadDisallowedInfo";
import drFolderInformation from "./ng/documents/components/drFolderInformation";
import drFolderSelect from "./ng/documents/components/drFolderSelect";
import RoomDocumentsAiReportsController from "./ng/documents/controllers/RoomDocumentsAiReportsController.js";
import RoomDocumentsArchiveListController from "./ng/documents/controllers/RoomDocumentsArchiveListController";
import RoomDocumentsController from "./ng/documents/controllers/RoomDocumentsController";
import RoomDocumentsSearchListController from "./ng/documents/controllers/RoomDocumentsSearchListController";
import DocumentsApiService from "./ng/documents/services/DocumentsApiService";
import DocumentsService from "./ng/documents/services/DocumentsService";

angular
  .module("dealroom.documents", [
    "ui.tree",
    "ui.indeterminate",
    "ui.bootstrap",
    "tableSort",
    "ngFileUpload", // used for ng-drop
    "ngSanitize", // for cleaning up search engine's highlights
    "vs-repeat",
    "ui.bootstrap.contextMenu",

    "dealroom.config",
    "dealroom.common",
    "dealroom.room",
    "dealroom.uploads",
  ])
  .factory("DocumentsService", DocumentsService)
  .factory("DocumentsApiService", DocumentsApiService)
  .controller("RoomDocumentsController", RoomDocumentsController)
  .controller(
    "RoomDocumentsArchiveListController",
    RoomDocumentsArchiveListController,
  )
  .controller(
    "RoomDocumentsSearchListController",
    RoomDocumentsSearchListController,
  )
  .controller(
    "RoomDocumentsAiReportsController",
    RoomDocumentsAiReportsController,
  )
  .component("documentsSearchCompletionBox", documentsSearchCompletionBox())
  .component("drDocumentInformation", drDocumentInformation())
  .component("drDocumentsMatchText", drDocumentsMatchText())
  .component("drDownloadDisallowedInfo", drDownloadDisallowedInfo())
  .component("drFolderInformation", drFolderInformation())
  .component("drFolderSelect", drFolderSelect())
  .value("VueDocumentsPage", VueDocumentsPage)
  .value("VueDocumentsTableOptionsButton", VueDocumentsTableOptionsButton)
  .value("VueDocumentsFolderTree", VueDocumentsFolderTree)
  .value("VueDocVerQaDialog", VueDocVerQaDialog)
  .value("VueAiReportsPageContent", VueAiReportsPageContent)
  .value("VueDrUploadDialog", VueDrUploadDialog)
  .value("VueDrUploadButton", VueDrUploadButton)
  .value("VueDrUploadDisabledButton", VueDrUploadDisabledButton);
