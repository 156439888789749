<template>
  <DrTooltip :content="viewProps.schema.extra?.tooltip" placement="top">
    <div :class="$style.container">
      <div :class="$style.label" data-testid="dform-label">
        <slot name="label" />
      </div>
      <div
        :class="{
          [$style.wrapper]: true,
          [$style.wrapper_isHoverable]: !viewProps.schema.isReadOnly,
        }"
        @click.stop="enterEditMode"
      >
        <div
          :class="$style.content"
          :style="coloredStyle"
          data-testid="dform-value"
        >
          <slot name="control" />
        </div>
      </div>
    </div>
  </DrTooltip>
</template>

<script setup lang="ts">
import { computed } from "vue";

import DrTooltip from "@app/vue/shared/ui/dr-tooltip/DrTooltip.vue";
import { hexToRgb, isDarkColor } from "@drVue/utils/color";

import type { SelectFieldOption } from "@drVue/api-service/client-dashboard/types";
import type { ViewFieldProps } from "@drVue/components/client-dashboard/dynamic-form/Fields/types";
import type { HEXColor } from "@drVue/utils/color";

interface Props {
  viewProps: ViewFieldProps;
}

const props = defineProps<Props>();

const backgroundColor = computed<HEXColor | null>(() => {
  return props.viewProps.schema.extra?.select_options?.find(
    ({ label }: SelectFieldOption) => label === props.viewProps.entityValue,
  )?.color;
});

const coloredStyle = computed<Record<string, any>>(() => {
  if (backgroundColor.value) {
    return {
      backgroundColor: backgroundColor.value,
      color: isDarkColor(hexToRgb(backgroundColor.value)) ? "white" : "black",
      marginLeft: "6px",
    };
  }
  return {};
});

const enterEditMode = (e: MouseEvent) => {
  if (e.target && e.target instanceof HTMLAnchorElement) return;

  props.viewProps.enterEditMode();
};
</script>

<style module lang="scss">
@use "@app/styles/scss/colors";
@use "@app/styles/scss/typography";

.container {
  width: 100%;
}

.label {
  font: typography.$caption_regular;
  color: colors.$pr-600;
}

.wrapper {
  display: flex;
  align-items: center;
  margin-left: -6px;
  border-radius: 8px;
  min-height: 32px;
}

.wrapper_isHoverable {
  transition: background-color 0.1s ease-in-out;

  &:hover {
    background-color: colors.$pr-100;
    cursor: pointer;
  }
}

.content {
  display: inline-block;
  font: typography.$body_regular;
  color: colors.$pr-900;
  transition: background-color 100ms ease-out;
  padding: 2px 6px;
  border-radius: 6px;
}
</style>
