<template>
  <!-- @vue-skip -->
  <ElForm
    ref="itemForm"
    :model="itemForm"
    :disabled="isSubmitting"
    :rules="rules"
    :label-position="formAttributes.labelPosition"
    :size="formAttributes.size"
    class="form-container"
    @submit.prevent="formSubmit"
  >
    <slot
      :get-errors="getBackendError"
      :reset-errors="resetBackendError"
      :form="itemForm"
      :update-item="updateItem"
    />
    <div class="form-container__buttons-row">
      <ElButton v-if="itemForm.id" type="danger" @click="deleteItem">
        <template #icon><i class="fa fa-trash" /></template>
      </ElButton>
      <div class="buttons-row__buttons-pair">
        <ElButton type="default" data-test-cancel @click="close">
          Cancel
        </ElButton>
        <ElButton
          type="primary"
          :disabled="!isChanged"
          :loading="isSubmitting"
          @click="formSubmit"
        >
          {{ itemForm.id ? "Update" : "Create" }}
        </ElButton>
      </div>
    </div>
  </ElForm>
</template>

<script lang="ts">
import { cloneDeep } from "lodash-es";
import { defineComponent } from "vue";
import DrForm from "@shared/ui/dr-form";

import type { Item } from "@drVue/api-service/client-dashboard";
import type { Dictionary } from "@drVue/types";
import type { PropType } from "vue";

interface Data {
  itemForm: Item | null;
  formAttributes: any;
}

export default defineComponent({
  name: "ItemEdit",
  extends: DrForm,
  inject: ["deletedCallback"],
  props: {
    item: { required: true, type: Object as PropType<Item> },
    itemType: { required: true, type: String as PropType<string> },
    labelField: { required: true, type: String as PropType<string> },
    actionPath: { required: true, type: String as PropType<string> },
    rules: { required: true, type: Object as PropType<Dictionary<any[]>> },
  },
  emits: ["close", "added", "updated"],
  data(): Data {
    return {
      itemForm: null,
      formAttributes: {
        size: "default",
        labelPosition: "top",
      },
    };
  },
  watch: {
    itemForm: {
      deep: true,
      handler() {
        this.isChanged = true;
      },
    },
  },
  beforeMount() {
    this.itemForm = cloneDeep(this.item);
  },
  methods: {
    close() {
      this.$emit("close");
    },
    formSubmit() {
      (this.$refs["itemForm"] as any).validate((valid: boolean) => {
        if (valid) {
          const action = this.itemForm?.id ? "editItem" : "addItem";
          const actionType = this.actionPath + action;

          return this.submitAction(
            actionType,
            { item: this.itemForm },
            `Failed to update ${this.itemType}`,
          ).then((payload) => {
            if (action === "addItem") {
              this.$emit("added", payload);
            } else {
              this.$emit("updated", payload);
            }

            this.close();
          });
        } else {
          return false;
        }
      });
    },
    deleteItem() {
      const itemLabel: string = (this.itemForm as any)[this.labelField];
      const deletingFor: string =
        (this.itemForm as any).object_type === "deal" ? "deals" : "rooms";
      this.$confirm(
        `This ${this.itemType} will be removed for all ${deletingFor}.`,
        `Remove "${itemLabel}" ${this.itemType}`,
        {
          confirmButtonText: "Remove",
          cancelButtonText: "Cancel",
          type: "warning",
        },
      )
        .then(() =>
          this.submitAction(this.actionPath + "deleteItem", {
            item: this.itemForm,
          }),
        )
        .then(() => {
          const thany = this as any;
          if ("key" in this.item) {
            thany.deletedCallback(this.item.key);
          } else {
            thany.deletedCallback(this.item.name);
          }
        });
    },
    updateItem(value: Item) {
      this.itemForm = cloneDeep(value);
    },
  },
});
</script>

<style scoped lang="scss">
// Form container
.form-container {
  padding-bottom: 15px;

  &__buttons-row {
    padding-top: 15px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__buttons-pair {
    display: flex;
    align-items: center;
  }
}
</style>
