<template>
  <ElDialog
    v-model="isModalVisible"
    :title="'Import deals from Excel'"
    width="600px"
    append-to-body
    @close="close"
  >
    <input
      ref="file"
      type="file"
      accept=".xlsx"
      data-testid="deal-import-file-button"
    />

    <div
      v-if="nonFieldErrors.length || fieldErrors.length"
      class="deals-import__errors"
    >
      <h4 class="color-red">Failed to import deals:</h4>
      <ul v-if="nonFieldErrors.length">
        <li v-for="e in nonFieldErrors" :key="e">{{ e }}</li>
      </ul>

      <div v-for="e in fieldErrors" :key="e.row_number">
        <b
          >Row number {{ e.row_number }} has
          {{ e.errors.length > 1 ? "errors" : "error" }}:</b
        >
        <ul>
          <li v-for="field in e.errors" :key="field.field">
            <b>{{ field.label }}</b
            >: {{ field.errors.join(" ") }}
          </li>
        </ul>
      </div>
    </div>

    <template #footer>
      <a :href="downloadTemplateUrl" class="deals-import__download-link">
        Download template
      </a>
      <ElButton type="primary" :loading="isImporting" @click="uploadFile">
        Import
      </ElButton>
    </template>
  </ElDialog>
</template>

<script lang="ts">
import { capitalize } from "lodash-es";
import { keyBy } from "lodash-es";
import { defineComponent } from "vue";

import { Urls } from "@setups/urls";
import { http } from "@drVue/api-service/http";
import { $notifyDanger, $notifySuccess } from "@drVue/common";

interface Data {
  isModalVisible: boolean;
  isImporting: boolean;
  downloadTemplateUrl: string;
  nonFieldErrors: any[];
  fieldErrors: any[];
}

export default defineComponent({
  name: "DealsImportModal",
  data(): Data {
    return {
      isModalVisible: false,
      isImporting: false,
      downloadTemplateUrl: Urls["api:client-dashboard:deals-import-example"](),
      nonFieldErrors: [],
      fieldErrors: [],
    };
  },
  methods: {
    open() {
      this.isModalVisible = true;
      this.resetErrors();
      const fileInput = this.getFileInput();
      if (fileInput) {
        fileInput.value = "";
      }
    },
    close() {
      this.isModalVisible = false;
    },
    getFileInput() {
      return this.$refs.file as HTMLInputElement;
    },
    resetErrors() {
      this.fieldErrors = [];
      this.nonFieldErrors = [];
    },
    setNonFieldErrors(data: any) {
      if (!data) return;
      this.nonFieldErrors = data.non_field_errors || [];
      delete data.non_field_errors;
    },
    setFieldErrors(data: any) {
      if (!data) return;

      const fieldLabels = keyBy(
        this.$store.getters["clientDashboard/customFields/byObjectType"](
          "deal",
        ),
        "key",
      );
      this.fieldErrors = [];

      const rows = Object.keys(data);
      rows.forEach((row) => {
        const custom_data = data[row].custom_data || null;
        if (custom_data) delete data[row].custom_data;

        const errors: any[] = [];
        const fields = Object.keys(data[row]);
        fields.forEach((field) => {
          errors.push({
            label: capitalize(field),
            errors: data[row][field],
          });
        });

        if (custom_data) {
          const keys = Object.keys(custom_data);
          keys.forEach((k) => {
            errors.push({
              label: fieldLabels[k].label,
              errors: custom_data[k],
            });
          });
        }

        this.fieldErrors.push({
          row_number: parseInt(row),
          errors: errors,
        });
      });

      this.fieldErrors = this.fieldErrors.sort(
        (a, b) => a.row_number - b.row_number,
      );
    },
    uploadFile() {
      if (this.isImporting) {
        return;
      }
      const files = this.getFileInput().files;
      if (!files || files.length === 0) return;

      const formData = new FormData();
      formData.append("file", files[0]);

      const config = {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      };

      this.resetErrors();
      this.isImporting = true;
      const uploadUrl = Urls["api:client-dashboard:deals-import"]();
      http
        .post(uploadUrl, formData, config)
        .then(
          (r) => {
            $notifySuccess("Deals were successfully imported");
            this.$store.dispatch("clientDashboard/deals/load");
            this.close();
          },
          (r) => {
            $notifyDanger("Something went wrong while importing the deals");
            this.setNonFieldErrors(r.response.data);
            this.setFieldErrors(r.response.data);
          },
        )
        .finally(() => {
          this.isImporting = false;
        });
    },
  },
});
</script>
