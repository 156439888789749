<template>
  <DrVxeGrid
    :row-config="{
      useKey: true,
      keyField: 'id',
      isCurrent: true,
      height: 38,
    }"
    :checkbox-config="{
      highlight: true,
      range: false,
      checkField: '_isChecked',
    }"
    :sort-config="tableSortConfig"
    :columns="tableColumns.columns"
    :data="items"
    @sort-change="handleColumnSortChanged"
    @resizable-change="handleColumnSizeChanged"
    @checkbox-all="emitCheckboxAll"
    @checkbox-change="emitCheckbox"
  />
</template>

<script setup lang="ts">
import { orderBy } from "lodash-es";
import { computed } from "vue";
import DrVxeGrid from "@shared/ui/dr-vxe-grid";
import { useStorage } from "@vueuse/core";

import TableColumns from "./tableColumns";

import type { GroupsArchiveTableField, GroupsArchiveTableRow } from "../types";
import type { CustomViewColumn } from "@setups/types";
import type {
  VxeTableDefines,
  VxeTableEvents,
  VxeTablePropTypes,
} from "vxe-table";

type SortOrder = "asc" | "desc";
type StoredSettingsColumn = CustomViewColumn & {
  sort?: SortOrder;
};
type StoredSettings = Record<GroupsArchiveTableField, StoredSettingsColumn>;

const GROUPS_ARCHIVE_TABLE_STORED_SETTINGS_KEY =
  "dr:groups_archive_table_stored_settings";
const GROUPS_ARCHIVE_TABLE_STORED_SORTED_KEY =
  "dr:groups_archive_table_stored_sorted_field";

interface Props {
  items: GroupsArchiveTableRow[];
}

interface Emits {
  (event: "checkbox", groupId: GroupsArchiveTableRow["id"]): void;
  (event: "checkbox-all"): void;
}

defineProps<Props>();
const emit = defineEmits<Emits>();

const generateSettingsColumn = (key: GroupsArchiveTableField) => ({
  field: key,
  width: undefined,
  order: undefined,
  hidden: false,
});

const currentTableSortedField = useStorage<GroupsArchiveTableField>(
  GROUPS_ARCHIVE_TABLE_STORED_SORTED_KEY,
  "name",
);
const columnsStoredSettings = useStorage<StoredSettings>(
  GROUPS_ARCHIVE_TABLE_STORED_SETTINGS_KEY,
  {
    name: generateSettingsColumn("name"),
  },
  localStorage,
  { mergeDefaults: true },
);

const handleColumnSortChanged = (
  params: VxeTableDefines.SortChangeEventParams,
) => {
  const field = params.field as GroupsArchiveTableField;
  columnsStoredSettings.value[field].sort = params.order || undefined;
  currentTableSortedField.value = field;
};

const handleColumnSizeChanged = (
  params: VxeTableDefines.ResizableChangeEventParams,
) => {
  const field = params.column.field as GroupsArchiveTableField;
  columnsStoredSettings.value[field].width = params.resizeWidth;
};

const columnsItems = computed(() => {
  return Object.keys(columnsStoredSettings.value).map(
    (column) => columnsStoredSettings.value[column as GroupsArchiveTableField],
  );
});

const tableColumns = new TableColumns(columnsItems);

const emitCheckboxAll = () => emit("checkbox-all");
const emitCheckbox: VxeTableEvents.CheckboxChange<GroupsArchiveTableRow> = (
  params,
) => {
  emit("checkbox", params.row.id);
};

const sortTableData = (
  data: GroupsArchiveTableRow[],
  field?: GroupsArchiveTableField,
  order?: VxeTablePropTypes.SortOrder,
) => {
  if (!field || !order) return data;

  let list = [...data];

  if (order === "asc" || order === "desc") {
    list = orderBy(list, [field], [order]);
  }

  return list;
};

const tableSortConfig: VxeTablePropTypes.SortConfig<GroupsArchiveTableRow> = {
  defaultSort: {
    field: currentTableSortedField.value,
    order:
      columnsStoredSettings.value[currentTableSortedField.value].sort ?? "asc",
  },
  trigger: "cell",
  sortMethod: ({ data, sortList }) => {
    const sortItem = sortList[0];
    const { field, order } = sortItem;

    return sortTableData(data, field as GroupsArchiveTableField, order);
  },
};

defineExpose({
  sortTableData,
});
</script>
