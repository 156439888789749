import { h } from "vue";

import { TableInlineEditor } from "@drVue/shared/ui/table-inline-editor/TableInlineEditor";
import { TableColumnsBase } from "@drVue/TableColumnsBase";
import {
  type Group,
  GROUP_TO_CATEGORY_ACCESS,
  type GroupCategories,
  type GroupsRequestsTableColumn,
  type GroupsRequestsTableRow,
  type GroupToCategoriesAccessMap,
} from "../types";
import SingleGroupAccessCell from "./cells/SingleGroupAccessCell.vue";
import SingleGroupHeaderCell from "./cells/SingleGroupHeaderCell.vue";

import type { CustomViewColumn } from "@setups/types";
import type { ComputedRef } from "vue";

interface Handlers {
  onChange: (
    group: Group,
    categoryId: GroupsRequestsTableRow["id"],
    newAccess: boolean,
  ) => void;
  onAddCategory: (
    reference: HTMLElement,
    parentId: GroupCategories[number]["parent_id"],
  ) => void;
}

export default class TableColumns extends TableColumnsBase<GroupsRequestsTableRow> {
  constructor(
    private readonly group: Group,
    private readonly accessMap: ComputedRef<GroupToCategoriesAccessMap>,
    private readonly handlers: Handlers,
  ) {
    super();
  }

  /** just to fix requires of inlineEditor in TableColumnsBase */
  inlineEditor = new TableInlineEditor(async () => {
    return Promise.resolve();
  }, "id");

  getUserColumns(): Record<string, CustomViewColumn> {
    return {};
  }

  getTableColumns(): GroupsRequestsTableColumn[] {
    return [this.nameColumn()];
  }

  private nameColumn(): GroupsRequestsTableColumn {
    return {
      field: "name",
      title: "",
      treeNode: true,
      sortable: true,
      showOverflow: true,
      resizable: false,
      showHeaderOverflow: false,
      slots: {
        header: () => {
          const group = this.group;

          const isIndeterminate =
            this.accessMap.value.root === GROUP_TO_CATEGORY_ACCESS.PARTIAL;
          const isChecked =
            this.accessMap.value.root === GROUP_TO_CATEGORY_ACCESS.SELECTED ||
            group.is_administrator;

          return h(SingleGroupHeaderCell, {
            isChecked,
            isIndeterminate,
            isDisabled: group.is_administrator,
            onChange: (newAccess: boolean) =>
              this.handlers.onChange(group, "root", newAccess),
          });
        },
        default: ({ row }: { row: GroupsRequestsTableRow }) => {
          const group = this.group;

          const isIndeterminate =
            this.accessMap.value[row.id as number] ===
            GROUP_TO_CATEGORY_ACCESS.PARTIAL;
          const isChecked =
            this.accessMap.value[row.id as number] ===
              GROUP_TO_CATEGORY_ACCESS.SELECTED || group.is_administrator;

          return h(SingleGroupAccessCell, {
            title: row.name,
            isChecked,
            isIndeterminate,
            isDisabled: group.is_administrator,
            onChange: (newAccess: boolean) =>
              this.handlers.onChange(group, row.id, newAccess),
            onAddCategory: (ref: HTMLElement) =>
              this.handlers.onAddCategory(ref, row.id as number),
          });
        },
      },
    };
  }
}
