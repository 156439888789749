<template>
  <DrPopup :visible="visibility">
    <template #reference>
      <CellWrapper @exit="editProps.quitEditMode">
        <DrTruncatedTextTooltip :content="editProps.schema.extra!.displayValue">
          {{ editProps.schema.extra!.displayValue }}
        </DrTruncatedTextTooltip>
      </CellWrapper>
    </template>
    <template #default>
      <DrPopupSelectOptions
        type="checkboxRight"
        :items="groups"
        @select="updateValue"
      />
    </template>
  </DrPopup>
</template>

<script setup lang="ts">
import { ElMessageBox } from "element-plus";
import { computed } from "vue";
import { useI18n } from "vue-i18n";
import { DrPopup, DrPopupSelectOptions } from "@shared/ui/dr-popups";
import { DrTruncatedTextTooltip } from "@shared/ui/dr-tooltip";

import CellWrapper from "@app/vue/components/client-dashboard/dynamic-form/Fields/Edit/TableCellEditWrapper.vue";
import { useVisibility } from "@drVue/components/room/findings/cells/use-visibilty";
import DrStore from "@drVue/store";

import type { MembersTableRow } from "../../../types.ts";
import type { EditFieldProps } from "@drVue/components/client-dashboard/dynamic-form/Fields/types";

interface Props {
  editProps: EditFieldProps<
    MembersTableRow["pgroup"]["id"],
    MembersTableRow,
    { displayValue: string }
  >;
}

const { editProps } = defineProps<Props>();

const { t } = useI18n();
const visibility = useVisibility();

const initialValue = editProps.value;

const groups = computed(() =>
  DrStore.state.room.groups.pgroupsList.map((group) => {
    return {
      id: group.id,
      name: group.name,
      isSelected: group.id === editProps.value,
    };
  }),
);

const updateValue = async (value: MembersTableRow["pgroup"]["id"]) => {
  if (initialValue === value) {
    editProps.quitEditMode();
    return;
  }

  const { email } = editProps.entity;
  const group = DrStore.state.room.groups.pgroups[value].name;

  const title = t("permissions.move_members.confirm_title");
  const text = t("permissions.move_members.confirm_text", { email, group });

  try {
    await ElMessageBox.confirm(text, title, {
      confirmButtonText: t("shared.move"),
      customClass: "el-message-box--warning",
    });
  } catch {
    editProps.quitEditMode();
    return;
  }

  editProps.veeField.onChange(value);
  editProps.submitField();
};
</script>
