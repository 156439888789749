<template>
  <CellWrapper
    :is-read-only="!!viewProps.schema.isReadOnly"
    @edit="viewProps.enterEditMode"
  >
    <DrTruncatedTextTooltip :content="groupName">
      {{ groupName }}
    </DrTruncatedTextTooltip>
  </CellWrapper>
</template>

<script lang="ts" setup>
import { computed } from "vue";
import { DrTruncatedTextTooltip } from "@shared/ui/dr-tooltip";

import CellWrapper from "@app/vue/components/client-dashboard/dynamic-form/Fields/View/TableCellViewWrapper.vue";

import type { MembersTableRow } from "../../../types.ts";
import type { ViewFieldProps } from "@drVue/components/client-dashboard/dynamic-form/Fields/types";

interface Props {
  viewProps: ViewFieldProps<
    MembersTableRow["pgroup"]["id"],
    MembersTableRow,
    { displayValue: string }
  >;
}

const { viewProps } = defineProps<Props>();

const groupName = computed(() => viewProps.schema.extra!.displayValue);
</script>
