import type ReviewAppliedUpdatesPanel from "./ReviewAppliedUpdatesPanel.vue";
import type ReviewUpdatesPanel from "./ReviewUpdatesPanel.vue";
import type SeeUpdateLogsByRoomPanel from "./SeeUpdateLogsByRoomPanel.vue";
import type SeeUpdateLogsPanel from "./SeeUpdateLogsPanel.vue";
import type { InjectionKey, ShallowRef } from "vue";

export const reviewAppliedUpdatesRefKey = Symbol() as InjectionKey<
  Readonly<ShallowRef<typeof ReviewAppliedUpdatesPanel | null>>
>;
export const reviewUpdatesRefKey = Symbol() as InjectionKey<
  Readonly<ShallowRef<typeof ReviewUpdatesPanel | null>>
>;
export const seeUpdateLogsByRoomRefKey = Symbol() as InjectionKey<
  Readonly<ShallowRef<typeof SeeUpdateLogsByRoomPanel | null>>
>;
export const seeUpdateLogsRefKey = Symbol() as InjectionKey<
  Readonly<ShallowRef<typeof SeeUpdateLogsPanel | null>>
>;
