<template>
  <DrModalForm
    :shown="isVisible"
    :is-submitting="isSubmitting"
    title="Room access"
    submit-btn-text="Add yourself to room"
    @submit="addMyselfToGroupAndOpenTheRoom"
    @close="close"
    @cancel="close"
  >
    <template #default>
      <!-- @vue-skip: deal.room is possibly undefined -->
      <div>
        You are not a member of this room. To gain access please, select a group
        in the room to add yourself to <b>{{ deal.room.title }}</b>
      </div>
    </template>
    <template #additional>
      <ElFormItem label="Group">
        <ElSelect v-model="selectedRoomGroupId" style="width: 100%">
          <!-- @vue-expect-error: deal.room is possibly undefined -->
          <ElOption
            v-for="g in deal.room.groups"
            :key="g.id"
            :value="g.id"
            :label="g.name"
          />
        </ElSelect>
      </ElFormItem>
    </template>
  </DrModalForm>
</template>

<script setup lang="ts">
import { ref } from "vue";
import { DrModalForm } from "@shared/ui/dr-modals";

import { USER_DATA } from "@setups/data";
import { UsersApiService } from "@drVue/api-service/client-dashboard";

import type { Deal } from "@drVue/store/modules/client-dashboard/deals/types";

const usersApi = new UsersApiService();

interface Props {
  deal: Deal;
}

const props = defineProps<Props>();

const isVisible = ref<boolean>(false);
const isSubmitting = ref<boolean>(false);

// TODO: We should do it better.
// This is safe because this code is executed within a dialog context,
// and the user has clicked the "Open room" button, ensuring the room is defined.
const adminGroup = props.deal.room!.groups!.find(
  (g) => g.name === "Administrator",
)!;
const selectedRoomGroupId = ref<number>(adminGroup.id);

const addMyselfToGroupAndOpenTheRoom = () => {
  isSubmitting.value = true;
  usersApi
    .invite(selectedRoomGroupId.value, {
      members: [{ email: USER_DATA.email }],
      message: "You have added yourself to the room.",
    })
    .then(
      () => {
        // @ts-expect-error: deal.room is possibly undefined
        if (!props.deal.room.permalink) return;

        // @ts-expect-error: deal.room is possibly undefined
        location.href = props.deal.room.permalink;
      },
      () => (isSubmitting.value = false),
    );
};

const open = () => (isVisible.value = true);
const close = () => (isVisible.value = false);

defineExpose({
  open,
  close,
});
</script>

<style scoped lang="scss"></style>
