<template>
  <!-- eslint-disable vue/no-mutating-props -->
  <div>
    <span :class="$style.selectLabel">Select room to copy from</span>
    <ElFormItem
      prop="copy_options.source_room"
      :error="getError('copy_options.source_room')"
    >
      <!-- @vue-expect-error -->
      <ElSelect
        v-model="roomData.copy_options.source_room"
        clearable
        filterable
        placeholder="Select source room"
        class="el-select--full-width"
        :loading="isLoadingRooms"
        @change="onRoomChange"
      >
        <ElOption
          v-for="room in selectedClientRooms"
          :key="room.id"
          :label="room.title"
          :value="room.id"
        />
      </ElSelect>
    </ElFormItem>
    <CopyOptions :room-data="roomData" :get-error="getError" />
  </div>
</template>
<script lang="ts">
/* eslint-disable vue/no-mutating-props */

import { defineComponent } from "vue";
import DrForm from "@shared/ui/dr-form";

import { RoomApiService } from "@drVue/api-service/common/rooms";
import { $notifyDanger } from "@drVue/common";
import CopyOptions from "./CopyOptions.vue";

import type {
  RoomCreateData,
  RoomGroupListItem,
  RoomListItem,
} from "@drVue/api-service/common/rooms";
import type { PropType } from "vue";

const roomService = new RoomApiService();

interface Data {
  allAdminRooms: null | RoomListItem[];
  selectedRoomGroups: null | RoomGroupListItem[];
  isLoadingRooms: boolean;
  isLoadingGroups: boolean;
}

export default defineComponent({
  name: "SubForm",
  components: { CopyOptions },
  extends: DrForm,
  props: {
    roomData: { required: true, type: Object as PropType<RoomCreateData> },
    getError: { required: true, type: Function as PropType<any> },
    resetError: { required: true, type: Function as PropType<any> },
  },
  data(): Data {
    return {
      allAdminRooms: null,
      selectedRoomGroups: null,
      isLoadingRooms: false,
      isLoadingGroups: false,
    };
  },
  computed: {
    selectedClientRooms(): null | RoomListItem[] {
      if (!this.allAdminRooms || !this.roomData.client) return null;
      return this.allAdminRooms.filter(
        (r) => r.client_id === this.roomData.client,
      );
    },
  },
  watch: {
    "roomData.client"() {
      this.roomData.copy_options.source_room = null;
      this.onRoomChange();
    },
  },
  mounted() {
    this.loadAllAdminRooms();
  },
  methods: {
    loadAllAdminRooms() {
      this.isLoadingRooms = true;
      roomService.list().then(
        (items) => {
          this.allAdminRooms = items.filter((i) => i.role === "admin");
          this.isLoadingRooms = false;
        },
        (error) => $notifyDanger("Failed to retrieve rooms"),
      );
    },
    onRoomChange() {
      this.resetError("copy_options.source_room");
      this.isLoadingGroups = true;
      this.roomData.copy_options.copy_groups = [];
      const roomId = this.roomData.copy_options.source_room;
      if (!roomId) {
        this.selectedRoomGroups = null;
        return;
      }
      const selectedRoom = this.selectedClientRooms!.find(
        (r) => r.id === roomId,
      )!;
      roomService.roomGroups(selectedRoom.uid).then(
        (items) => {
          if (this.roomData.copy_options.source_room === roomId) {
            this.selectedRoomGroups = items;
            this.isLoadingGroups = false;
          }
        },
        (error) => $notifyDanger("Failed to retrieve groups"),
      );
    },
  },
});
</script>

<style module lang="scss">
@use "@app/styles/scss/typography";

.selectLabel {
  font: typography.$subtitle_semibold;
  margin: 16px 0;
  display: block;
}
</style>
