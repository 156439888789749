import { h } from "vue";

import { t } from "@app/vue/i18n";
import PermissionsCell from "./cells/PermissionsCell.vue";

import type { PermissionTableRow } from "../types";
import type { DrVxeTableColumn } from "@app/vue/components/types";

export default class TableColumns {
  constructor() {}

  get columns(): DrVxeTableColumn<PermissionTableRow>[] {
    return this.getTableColumns();
  }

  getTableColumns(): DrVxeTableColumn<PermissionTableRow>[] {
    return [this.groupColumn(), this.permissionsColumns(), this.notifyColumn()];
  }

  private groupColumn(): DrVxeTableColumn<PermissionTableRow> {
    return {
      field: "group_name",
      title: t("shared.group"),
      resizable: false,
    };
  }

  private permissionsColumns(): DrVxeTableColumn<PermissionTableRow> {
    return {
      field: "_",
      title: t("shared.permissions"),
      width: 140,
      resizable: false,
      slots: {
        default: ({ row }) => {
          return h(PermissionsCell, { row });
        },
      },
    };
  }

  private notifyColumn(): DrVxeTableColumn<PermissionTableRow> {
    return {
      type: "checkbox",
      field: "_",
      title: t("shared.notify"),
      width: 80,
      resizable: false,
    };
  }
}
