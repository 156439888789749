import { reactive } from "vue";

TasksService.$inject = [
  "$uibModal",
  "$state",
  "$stateParams",
  "$location",
  "TrackActivity",
  "MembersService",
  "NG_APP",
];

import { DrStore } from "@drVue";
import { pinia } from "@drVue/store/pinia";
import { useTasksStore } from "@drVue/store/pinia/room/tasks";
import { useTasksArchivedStore } from "@drVue/store/pinia/room/tasksArchived/tasksArchived";
import updateTaskAttributes from "./helpers/updateTaskAttributes";

export default function TasksService(
  $uibModal,
  $state,
  $stateParams,
  $location,
  TrackActivity,
  MembersService,
  NG_APP,
) {
  const tasksStore = useTasksStore(pinia);
  const tasksArchivedStore = useTasksArchivedStore(pinia);

  var service = {
    // Moved to Tasks Store, see below...
    // tasks: {},
    // tasksList: undefined,
    // tasksLoading: undefined,
    // tasksLoadError: undefined,
    // loadingPromise: undefined,
    // isLoaded: isLoaded,
    // archived tasks info
    // archivedTasks: {},
    // archivedTasksList: undefined,
    // archivedTasksLoading: false,
    // archivedTasksLoadError: false,

    isLoaded: () => {
      return tasksStore.isLoading === false && tasksStore.isError === false;
    },

    // get data
    loadTasks: (skipErrorAlert) => tasksStore.load(skipErrorAlert),
    loadArchivedTasks: (skipErrorAlert) =>
      tasksArchivedStore.load(skipErrorAlert),
    patchTaskV2: (taskId, changes) => tasksStore.patchTaskV2(taskId, changes),

    // task detail actions
    openTaskDetails: openTaskDetails,
  };

  Object.defineProperties(service, {
    tasks: {
      get: () => tasksStore.tasks,
    },
    tasksByUid: {
      get: () => tasksStore.tasksByUid,
    },
    tasksList: {
      get: () => tasksStore.tasksList,
    },
    tasksLoading: {
      get: () => tasksStore.isLoading,
    },
    tasksLoadError: {
      get: () => tasksStore.isError,
    },
    loadingPromise: {
      get: () => tasksStore.loadingPromise,
    },
    archivedTasks: {
      get: () => tasksArchivedStore.tasks,
    },
    archivedTasksList: {
      get: () => tasksArchivedStore.tasksList,
    },
    archivedTasksLoading: {
      get: () => tasksArchivedStore.isLoading,
    },
    archivedTasksLoadError: {
      get: () => tasksArchivedStore.isError,
    },
  });

  return service;

  function openTaskDetails(taskKey) {
    const listTask = service.tasksList?.find((t) => t.key === taskKey);

    // We call `loadTaskDetails` every time to get the latest data. Internally,
    // it will update the `tasksList` within the store even if the task is
    // already loaded, and we `Promise.resolve(listTask)`.
    const loadTaskPromsie = tasksStore.loadTaskDetails(taskKey);

    const promises = [
      listTask ? Promise.resolve(listTask) : loadTaskPromsie,
      MembersService.isLoaded()
        ? Promise.resolve(MembersService.membersList)
        : DrStore.dispatch("room/members/load", true),
    ];

    Promise.all(promises).then(([task, _members]) => openModal(task));

    function openModal(task) {
      updateTaskAttributes(task);

      const modalInstance = $uibModal.open({
        controller: [
          "$scope",
          "task",
          ($scope, task) => {
            $scope.task = reactive(task);
            $scope.onClose = () => {
              // $scope.$close will resolve modalInstance.result promise in TasksService.js
              $scope.$close();
            };
          },
        ],
        template: `<vue-component id="room_TaskDetails" name="VueTaskDetails" v-props="{ task: task, onClose: onClose }"></vue-component>`,
        keyboard: false,
        backdrop: false,
        windowClass: "modal--hidden",
        animation: false,
        resolve: {
          task: () => task,
        },
      });

      const uiRouterEnabled = $location.url(); // there is no # part in fileviewer

      modalInstance.rendered.then(() => {
        if (NG_APP === "dealroom") {
          if (uiRouterEnabled) {
            $state.go(
              "tasks.details",
              { taskId: task.key },
              {
                notify: false,
                inherit: false,
              },
            );
          }

          TrackActivity.setObject({ type: "task", id: task.id });
        }
      });

      const lastState = $state.$current.name;
      const lastParams = angular.copy($stateParams);

      modalInstance.result.finally(() => {
        if (uiRouterEnabled) {
          $state.go(lastState, lastParams, {
            notify: false,
            inherit: false,
          });
        }

        TrackActivity.setObject(undefined);
      });
    }
  } /* End openTaskDetails */
}
