import { cloneDeep } from "lodash";
import { defineStore } from "pinia";
import { computed, ref } from "vue";

import { $notifyDanger } from "@app/vue/common";
import { t } from "@app/vue/i18n";
import {
  type DocsItem,
  isDocument,
  isFolder,
} from "@app/vue/store/modules/room/documents/DocumentsApiService";
import { useDocumentsStore } from "../documents/documents";
import {
  type DocumentPermissionsUpdate,
  FilePermissionKey,
  FilePermissionsApi,
  type FilePermissionsData,
  type FilePermissionsShort,
  type FolderPermissionsUpdate,
  getFilePermissions,
} from "./FilePermissionsApi";

const api = new FilePermissionsApi();

const getObjectKeys = <T extends object>(data: T) => {
  return Object.keys(data) as (keyof T)[];
};

export const useFilePermissionsStore = defineStore(
  "filePermissionsStore",
  () => {
    const documentsStore = useDocumentsStore();

    const permissionsOriginal = ref<FilePermissionsData | undefined>();
    const permissions = ref<FilePermissionsData | undefined>();
    const isSubmitting = ref(false);

    const hasChanges = computed<boolean>(() => {
      const value = permissions.value;

      if (!value) return false;

      return getObjectKeys(value).some((key) => {
        return getObjectKeys(value[key]).some((groupId) => {
          return getObjectKeys(value[key][groupId]).some((itemId) => {
            return permissionsOriginal.value?.[key][groupId][itemId].some(
              (permission, permissionKey) => {
                return (
                  value[key][groupId][itemId][permissionKey] !== permission
                );
              },
            );
          });
        });
      });
    });

    const getPermissions = () => {
      api
        .getPermissions()
        .then((response) => {
          permissionsOriginal.value = response.permissions;
          permissions.value = cloneDeep(response.permissions);
        })
        .catch(() => {
          $notifyDanger(t("permissions.file.load_failed"));
        });
    };

    const refetchPermissions = () => {
      getPermissions();
    };

    const refetchPermissionsIfNeeded = () => {
      if (!permissionsOriginal.value) return;

      getPermissions();
    };

    const updatePermissions = (
      permissionGroupId: number,
      value: FilePermissionsShort,
      items: DocsItem[],
    ) => {
      if (!permissions.value) return;

      items.forEach((item) => {
        if (isFolder(item)) {
          permissions.value!.folders[permissionGroupId][item.id] = value;

          documentsStore.getDescendants(item).forEach((item) => {
            if (isFolder(item)) {
              permissions.value!.folders[permissionGroupId][item.id] = value;
            } else {
              permissions.value!.documents[permissionGroupId][item.id] = value;
            }
          });
        } else if (isDocument(item)) {
          permissions.value!.documents[permissionGroupId][item.id] = value;
        }

        if (value[FilePermissionKey.VIEW]) {
          let folderId = item.id;

          if (isDocument(item)) {
            folderId = documentsStore.folderByIdMap[item.folder_id].id;

            permissions.value!.folders[permissionGroupId][folderId][0] = true;
          }

          documentsStore.parentsByIdMap[folderId].forEach((parent) => {
            if (
              items.some((inner) => isFolder(inner) && inner.id === parent.id)
            )
              return;

            permissions.value!.folders[permissionGroupId][parent.id][0] = true;
          });
        }
      });
    };

    const getPermissionsUpdate = (permissionGroupId: number) => {
      if (!permissions.value || !permissionsOriginal.value)
        return { folders: [], documents: [] };

      const folders: FolderPermissionsUpdate[] = [];
      const documents: DocumentPermissionsUpdate[] = [];

      getObjectKeys(permissions.value.folders[permissionGroupId]).forEach(
        (folder_id) => {
          const current =
            permissions.value!.folders[permissionGroupId][folder_id];
          const original =
            permissionsOriginal.value!.folders[permissionGroupId][folder_id];

          if (original?.every((permission, key) => current[key] === permission))
            return;

          folders.push({ folder_id, ...getFilePermissions(current) });
        },
      );

      getObjectKeys(permissions.value.documents[permissionGroupId]).forEach(
        (document_id) => {
          const current =
            permissions.value!.documents[permissionGroupId][document_id];
          const original =
            permissionsOriginal.value!.documents[permissionGroupId][
              document_id
            ];

          if (original?.every((permission, key) => current[key] === permission))
            return;

          documents.push({ document_id, ...getFilePermissions(current) });
        },
      );

      return {
        folders,
        documents,
      };
    };

    const savePermissions = () => {
      if (!permissions.value || isSubmitting.value) return;

      isSubmitting.value = true;

      const promises: Promise<void>[] = [];

      getObjectKeys(permissions.value.documents).forEach(
        (permissionGroupId) => {
          const { documents, folders } =
            getPermissionsUpdate(permissionGroupId);

          if (!documents.length && !folders.length) return;

          promises.push(
            api.updatePermissions(permissionGroupId, folders, documents),
          );
        },
      );

      return Promise.all(promises)
        .then(() => {
          refetchPermissions();
        })
        .catch((error) => {
          $notifyDanger(
            error.data?.detail ?? t("permissions.file.update_failed"),
          );
        })
        .finally(() => {
          isSubmitting.value = false;
        });
    };

    const getDocItemPermissions = (
      permissionGroupId: number,
      item: DocsItem,
    ): FilePermissionsShort | undefined => {
      return isFolder(item)
        ? permissions.value?.folders[permissionGroupId]?.[item.id]
        : permissions.value?.documents[permissionGroupId]?.[item.id];
    };

    return {
      permissions,
      hasChanges,
      isSubmitting,
      refetchPermissions,
      refetchPermissionsIfNeeded,
      updatePermissions,
      savePermissions,
      getDocItemPermissions,
    };
  },
);
