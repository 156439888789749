<template>
  <EditFieldContainer>
    <ElSelect
      ref="selectRef"
      style="width: 100%"
      popper-class="dr-popper--inline-control"
      :model-value="editProps.value"
      :persistent="false"
      @visible-change="submitIfClosedAndChanged"
      @change="updateValue"
    >
      <ElOption
        v-for="type in types"
        :key="type.id"
        :label="type.name"
        :value="type.id"
      >
        <FindingType :type-uid="type.id" />
      </ElOption>
    </ElSelect>
  </EditFieldContainer>
</template>

<script setup lang="ts">
import { ElSelect } from "element-plus";
import { computed, onMounted, ref } from "vue";

import EditFieldContainer from "@drVue/components/client-dashboard/dynamic-form/Fields/Edit/EditFieldContainer.vue";
import { pinia } from "@drVue/store/pinia";
import { useFindingsTypesStore } from "@drVue/store/pinia/pipeline/findings-types";
import FindingType from "../../components/FindingType.vue";

import type { EditFieldProps } from "@drVue/components/client-dashboard/dynamic-form/Fields/types";

const findingsTypesStore = useFindingsTypesStore(pinia);

interface Props {
  editProps: EditFieldProps;
}
const props = defineProps<Props>();

const types = computed(() => findingsTypesStore.list);

const initialValue = props.editProps.value;
let currentValue = props.editProps.value;

// @change event is fired before @visible-change, so we can update the current
// selected value and then decide what to do next.
const updateValue = (value: string) => {
  props.editProps.veeField.onChange(value);
  currentValue = value;
};

const submitIfClosedAndChanged = (visible: boolean) => {
  const hasBeenClosed = !visible;

  if (hasBeenClosed) {
    const hasValueChanged = currentValue !== initialValue;

    if (hasValueChanged) props.editProps.submitField();
    else props.editProps.quitEditModeConfirm();
  }
};

const selectRef = ref<InstanceType<typeof ElSelect> | null>(null);
onMounted(() => {
  if (!selectRef.value) return;
  selectRef.value.states.hoveringIndex = 0;
  selectRef.value.toggleMenu();
});
</script>

<style scoped lang="scss"></style>
