import { t } from "@app/vue/i18n";

export const PriorityValues = ["low", "medium", "high"] as const;
export type PriorityValue = (typeof PriorityValues)[number];

export const priorityLocale: Record<PriorityValue, string> = {
  low: t("priority.low"),
  medium: t("priority.medium"),
  high: t("priority.high"),
} as const;
