<template>
  <EditFieldContainer>
    <ElSelect
      ref="selectRef"
      style="width: 100%"
      :model-value="editProps.value"
      :placeholder="editProps.schema.placeholder"
      :persistent="false"
      popper-class="dr-popper--inline-control"
      @change="updateValue"
      @visible-change="submitIfClosedAndChanged"
    >
      <ElOption v-for="s in statuses" :key="s.id" :label="s.name" :value="s.id">
        <FindingStatus :status-id="s.id" />
      </ElOption>
    </ElSelect>
  </EditFieldContainer>
</template>

<script setup lang="ts">
import { ElSelect } from "element-plus";
import { computed, onMounted, ref } from "vue";

import EditFieldContainer from "@drVue/components/client-dashboard/dynamic-form/Fields/Edit/EditFieldContainer.vue";
import { pinia } from "@drVue/store/pinia";
import { useFindingsStatusesStore } from "@drVue/store/pinia/pipeline/findings-statuses";
import FindingStatus from "../../components/FindingStatus.vue";

import type { EditFieldProps } from "@drVue/components/client-dashboard/dynamic-form/Fields/types";

interface Props {
  editProps: EditFieldProps;
}

const props = defineProps<Props>();

const findingsStatusesStore = useFindingsStatusesStore(pinia);

const initialValue = props.editProps.value;
let currentValue = props.editProps.value;

const statuses = computed(() => findingsStatusesStore.list);

// @change event is fired before @visible-change, so we can update the current
// selected value and then decide what to do next.
const updateValue = (value: string) => {
  props.editProps.veeField.onChange(value);
  currentValue = value;
};

const submitIfClosedAndChanged = (visible: boolean) => {
  const hasBeenClosed = !visible;

  if (hasBeenClosed) {
    const hasValueChanged = currentValue !== initialValue;

    if (hasValueChanged) props.editProps.submitField();
    else props.editProps.quitEditModeConfirm();
  }
};

const selectRef = ref<InstanceType<typeof ElSelect> | null>(null);
onMounted(() => {
  if (!selectRef.value) return;
  selectRef.value.states.hoveringIndex = 0;
  selectRef.value.toggleMenu();
});
</script>
