<template>
  <div :class="$style.container">
    <AiReportsWrapper v-loading="isLoading" :class="$style.wrapper">
      <template v-if="!isLoading" #actions="{ buttonIconClass }">
        <div :class="$style.title">
          <template v-if="aiReportsStore.list.length === 0">
            Choose a summary template from our library or create a new one.
          </template>

          <template v-else>{{
            `${docReportsCount} ${docReportsCount === 1 ? "summary" : "summaries"}`
          }}</template>
        </div>

        <ElButton @click="isOpenedManageReports = true">
          <span>Manage summaries</span>
          <DrIcon name="cogwheel" :class="buttonIconClass" size="sm" />
        </ElButton>
      </template>

      <template v-if="!isLoading" #list>
        <AiReportRow
          v-for="report in aiReportsStore.list"
          :key="report.id"
          :doc-id="docId"
          :doc-uid="docUid"
          :report="report"
          :report-doc="docReportMap[report.id]"
          @reload-reports="loadDocReports"
          @update:report-doc="updateDocReports"
          @edit-report="editReport = report"
        />
      </template>
    </AiReportsWrapper>

    <AiReportsManage
      v-if="isOpenedManageReports"
      @close="isOpenedManageReports = false"
      @edit-report="editReport = $event"
    />

    <EditReportPanel
      v-if="editReport"
      :report="editReport"
      @close="editReport = null"
    />
  </div>
</template>

<script lang="ts" setup>
import { computed, onMounted, onUnmounted, ref, watch } from "vue";

import DrIcon from "@app/vue/shared/ui/dr-icon/DrIcon.vue";
import { AsyncOperationStatuses } from "@app/vue/store/modules/room/user-async-operation/AsyncOperationApiService";
import { AiReportsApiService } from "@drVue/components/room/ai/api";
import { pinia } from "@drVue/store/pinia";
import AiReportRow from "./AiReportRow.vue";
import AiReportsManage from "./AiReportsManage.vue";
import { useAiReportsStore } from "./AiReportsStore";
import AiReportsWrapper from "./AiReportsWrapper.vue";
import EditReportPanel from "./forms/EditReportPanel.vue";

import type { AiDocReport, AiReport } from "./types";

const REPORT_REFETCH_INTERVAL = 3000;

interface Props {
  docId: number;
  docUid: string;
}

const props = defineProps<Props>();

const api = new AiReportsApiService();
const aiReportsStore = useAiReportsStore(pinia);

const docReports = ref<AiDocReport[]>([]);
const isOpenedManageReports = ref(false);
const editReport = ref<AiReport | null>(null);
const isDocReportsLoading = ref(false);

const isLoading = computed<boolean>(
  () => aiReportsStore.isLoading || isDocReportsLoading.value,
);

const isRefetchEnabled = computed(() => {
  return docReports.value.some((report) =>
    report.fields.some(
      (field) =>
        field.status === AsyncOperationStatuses.Processing ||
        field.status === AsyncOperationStatuses.Pending,
    ),
  );
});

const docReportMap = computed(() => {
  return docReports.value.reduce<Record<string, AiDocReport>>(
    (result, item) => {
      result[item.report_type_template_id] = item;
      return result;
    },
    {},
  );
});

const docReportsCount = computed(
  () =>
    aiReportsStore.list.filter((report) => docReportMap.value[report.id])
      .length,
);

const loadDocReports = async () => {
  docReports.value = await api.loadDocReports(props.docUid);
};

const updateDocReports = (docReport: AiDocReport) => {
  const index = docReports.value.findIndex((item) => item.id === docReport.id);

  if (index === -1) return;

  docReports.value.splice(index, 1, docReport);
};

onMounted(() => {
  isDocReportsLoading.value = true;

  loadDocReports().finally(() => {
    isDocReportsLoading.value = false;
  });
});

onUnmounted(() => {
  if (refetchTimeout) clearTimeout(refetchTimeout);
});

let refetchTimeout: ReturnType<typeof setTimeout> | null = null;

const refetchDocReportsWithInterval = (): void => {
  if (refetchTimeout) clearTimeout(refetchTimeout);

  refetchTimeout = setTimeout(() => {
    refetchTimeout = null;

    if (!isRefetchEnabled.value) return;

    loadDocReports().then(refetchDocReportsWithInterval);
  }, REPORT_REFETCH_INTERVAL);
};

watch(isRefetchEnabled, refetchDocReportsWithInterval);
watch(
  () => props.docId,
  () => {
    loadDocReports();
  },
);
</script>

<style lang="scss" module>
@use "@app/styles/scss/colors";
@use "@app/styles/scss/spacing";
@use "@app/styles/scss/typography";

.container {
  height: 100%;
  overflow-y: auto;
}

.wrapper {
  padding: spacing.$m;
}

.title {
  margin-right: auto;
  padding: spacing.$xxs 0;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: colors.$pr-900;
}
</style>
