<template>
  <ElForm ref="roomForm" status-icon label-position="top" :model="roomData">
    <!-- Title -->
    <ElFormItem
      v-if="!deal"
      ref="roomFormTitle"
      :label="`New ${canCreateDeal ? 'deal' : 'room'} name`"
      prop="title"
      :error="getError('title')"
    >
      <!-- @vue-expect-error -->
      <ElInput
        ref="titleInput"
        v-model="roomData.title"
        @input="onTitleInput($event)"
      />
    </ElFormItem>

    <!-- Create a room -->
    <ElFormItem v-if="canCreateDeal">
      <ElCheckbox
        label="Create a room"
        :checked="createRoom"
        @change="$emit('update:createRoom', $event)"
      />
    </ElFormItem>
    <!-- /Create a room -->
    <!-- /Title -->

    <!-- Subdomain -->
    <ElFormItem
      v-if="showSubdomain && isCreatingRoom"
      label="Subdomain"
      prop="subdomain"
      :error="getError('subdomain')"
    >
      <ElInput v-model="roomData.subdomain" @input="onSubdomainInput($event)">
        <template #append>
          {{ rootDomain }}
        </template>
      </ElInput>
      <ElLink
        v-if="suggestedSubdomain"
        type="primary"
        class="create-room-form__suggested-domain"
        @click="onSubdomainInput(suggestedSubdomain)"
      >
        Use {{ suggestedSubdomain }}
      </ElLink>
    </ElFormItem>
    <!-- /Subdomain -->

    <!-- Client -->
    <ElFormItem
      v-if="userClients.length > 1 && isCreatingRoom && !deal"
      label="Client"
      prop="client"
      :error="getError('client')"
    >
      <ElSelect
        v-model="roomData.client"
        class="el-select--full-width"
        @change="validate()"
      >
        <ElOption
          v-for="client in userClients"
          :key="client.id"
          :label="client.public_name"
          :value="client.id"
        />
      </ElSelect>
    </ElFormItem>
    <!-- /Client -->
    <slot />

    <CopySubForm
      v-if="dataType === INITIAL_DATA_TYPES.CopyRoom && isCreatingRoom"
      :room-data="roomData"
      :get-error="getError"
      :reset-error="resetError"
    />
    <br v-if="dataType === INITIAL_DATA_TYPES.CopyRoom" />

    <!-- submit -->
    <FormAlert :text="getError('non_field_errors')" />
    <ElFormItem class="text-right">
      <ElButton
        type="primary"
        :loading="!!lastValidationRequest || isSubmitting"
        @click="submitForm()"
        >Create
      </ElButton>
    </ElFormItem>
  </ElForm>
</template>
<script lang="ts">
import AsyncValidator from "async-validator";
import { cloneDeep } from "lodash-es";
import { debounce } from "lodash-es";
import { defineComponent } from "vue";
import { FormAlert } from "@shared/ui";
import DrForm from "@shared/ui/dr-form";

import {
  APP_SETTINGS,
  CLIENT_DASHBOARD_DATA,
  ORG_MEMBER_DATA,
} from "@setups/data";
import { PaymentTypes } from "@setups/enums";
import { DrStore } from "@app/vue";
import { RoomApiService } from "@drVue/api-service/common/rooms";
import { $notifyDanger, $notifySuccess } from "@drVue/common";
import { IS_PIPELINE_ENABLED } from "@drVue/components/navigation/utils";
import { DealsApiService } from "@drVue/store/modules/client-dashboard/deals/DealsApiService";
import CopySubForm, * as CopyData from "./copy_room";
import { getCreateAllowedClients, INITIAL_DATA_TYPES } from "./utils";

import type {
  RoomCreateData,
  RoomCreateResp,
  SubdomainValidateResp,
} from "@drVue/api-service/common/rooms";
import type { Playbook } from "@drVue/components/user-dashboard/playbooks/types";
import type { Deal } from "@drVue/store/modules/client-dashboard/deals/types";
import type { ClientData } from "@setups/data";
import type { PropType } from "vue";

const isPipelineOnly =
  CLIENT_DASHBOARD_DATA.payment_type &&
  CLIENT_DASHBOARD_DATA.payment_type == PaymentTypes.PipelineOnly;

function getDefaultClient() {
  const createAllowedClients = getCreateAllowedClients();
  const currentClientId =
    ORG_MEMBER_DATA && ORG_MEMBER_DATA.client && ORG_MEMBER_DATA.client.id;
  if (
    currentClientId &&
    createAllowedClients.some((client) => client.id === currentClientId)
  ) {
    return currentClientId;
  }
  return createAllowedClients.length ? createAllowedClients[0].id : undefined;
}

function getValidator() {
  const descriptor: { [key: string]: any[] } = {
    title: [
      { required: true, message: "Please input room name" },
      {
        min: 3,
        message: "Name must have at least 3 characters.",
      },
      {
        max: 70,
        message: "Name must have at most 70 characters.",
      },
    ],
    subdomain: [
      { required: true, message: "Subdomain is required" },
      {
        min: 3,
        message: "Subdomain must have at least 3 characters.",
      },
      {
        max: 63,
        message: "Subdomain must have at most 63 characters.",
      },
      {
        pattern: /[A-Za-z0-9](?:[A-Za-z0-9-]{0,61}[A-Za-z0-9])?/,
        message: "Subdomain must contain only letters, digits and hyphens",
      },
    ],
  };
  if (getCreateAllowedClients().length) {
    descriptor.client = [{ required: true, message: "Client is required" }];
  }
  return new AsyncValidator(descriptor);
}

interface Data {
  INITIAL_DATA_TYPES: any;
  roomData: RoomCreateData;
  isSubdomainEditedManually: boolean;
  suggestedSubdomain: string;
  roomService: RoomApiService;
  dealsService: DealsApiService;
  lastValidationRequest: Promise<any> | null;
  validationErrors: { [key: string]: string };
  frontendValidator: any;
  IS_PIPELINE_ENABLED: boolean;
  isPipelineOnly: boolean;
}

export default defineComponent({
  name: "RoomCreateForm",
  components: { FormAlert, CopySubForm },
  extends: DrForm,
  props: {
    playbook: {
      required: false,
      type: Object as PropType<Playbook>,
      default: null,
    },
    dataType: {
      required: false,
      default: INITIAL_DATA_TYPES.Blank,
      type: String as PropType<INITIAL_DATA_TYPES>,
    },
    createRoom: {
      required: true,
      default: false,
      type: Boolean as PropType<boolean>,
    },
    deal: {
      required: false,
      default: undefined,
      type: Object as PropType<Deal>,
    },
  },
  emits: ["created", "update:createRoom"],
  data(): Data {
    return {
      INITIAL_DATA_TYPES: INITIAL_DATA_TYPES,
      roomData: {
        title: "",
        subdomain: "",
        client: getDefaultClient(),
        copy_options: cloneDeep(CopyData.DEFAULT_COPY_OPTIONS),
      },
      isSubdomainEditedManually: false,
      suggestedSubdomain: "",
      roomService: new RoomApiService(),
      dealsService: new DealsApiService(),
      lastValidationRequest: null,
      validationErrors: {},
      frontendValidator: getValidator(),
      IS_PIPELINE_ENABLED,
      isPipelineOnly,
    };
  },
  computed: {
    canCreateDeal() {
      return !this.deal && IS_PIPELINE_ENABLED;
    },
    isCreatingRoom() {
      return !this.canCreateDeal || this.createRoom;
    },
    showSubdomain(): any {
      const client: ClientData | undefined = this.roomData.client
        ? this.userClients.find(
            (cl: ClientData) => cl.id == this.roomData.client,
          )
        : undefined;
      return !!client && !client.enable_domain;
    },
    userClients(): ClientData[] {
      return getCreateAllowedClients();
    },
    rootDomain(): any {
      return "." + APP_SETTINGS.WEBSITE.HOST;
    },
  },
  watch: {
    dataType: {
      immediate: true,
      handler(newValue: INITIAL_DATA_TYPES) {
        // reset existing data
        if (newValue !== INITIAL_DATA_TYPES.CopyRoom) {
          delete (this.roomData as any)["copy_options"];
        }

        if (newValue !== INITIAL_DATA_TYPES.Template) {
          delete this.roomData["template"];
        }

        // setup default values
        if (newValue === INITIAL_DATA_TYPES.CopyRoom) {
          this.roomData["copy_options"] = cloneDeep(
            CopyData.DEFAULT_COPY_OPTIONS,
          );
        }

        this.focusOnTitleInput();
      },
    },
    playbook: {
      immediate: true,
      handler(newValue: Playbook) {
        this.roomData.template = newValue ? newValue.id : undefined;
      },
    },
  },
  beforeMount() {
    if (this.deal) {
      this.roomData.title = this.deal.title;
    }
  },
  mounted() {
    this.focusOnTitleInput();
  },
  methods: {
    onTitleInput: debounce(function (this: any) {
      this.resetError("title");
    }, 200),
    onSubdomainInput: debounce(function (this: any, subdomain: string) {
      this.roomData.subdomain = subdomain;
      this.isSubdomainEditedManually = true;
      this.resetError("subdomain");
    }, 200),
    getError(field: string) {
      if (this.validationErrors[field]) {
        return this.validationErrors[field];
      }
      return this.getBackendError(field);
    },
    resetError(field: string) {
      this.resetBackendError(field);
      this.validate();
    },
    validate() {
      // old version of AsyncValidation is used because of element-ui
      // so callback is wrapper to promise
      return new Promise<void>((resolve, reject) => {
        this.frontendValidator.validate(
          this.roomData,
          { firstFields: true },
          (errors: any) => {
            this.validationErrors = {};

            if (errors && !this.isSubdomainEditedManually) {
              errors = errors.filter(
                (error: any) => error.field !== "subdomain",
              );
            }
            if (errors && errors.length) {
              errors.forEach((error: any) => {
                this.validationErrors[error.field] = error.message;
              });
              reject(errors);
            } else if (this.showSubdomain) {
              this.backendSubdomainValidate().then(
                () => {
                  this.setBackendErrors({});
                  resolve();
                },
                (errors) => {
                  this.setBackendErrors(errors);
                  reject(errors);
                },
              );
            } else {
              resolve();
            }
          },
        );
      });
    },
    backendSubdomainValidate() {
      const validationData = {
        title: this.roomData.title,
        subdomain: this.roomData.subdomain,
      };
      if (!this.isSubdomainEditedManually) {
        validationData.subdomain = "";
      }
      const currentReq = this.roomService
        .validateSubdomain(validationData)
        .then(
          (data: SubdomainValidateResp) => {
            if (this.lastValidationRequest !== currentReq) {
              return;
            }
            this.lastValidationRequest = null;

            this.suggestedSubdomain = "";
            return data;
          },
          (error: any) => {
            if (this.lastValidationRequest !== currentReq) {
              return;
            }
            this.lastValidationRequest = null;

            if (
              error.response &&
              this.validationErrorCodes.includes(error.response.status)
            ) {
              const errorData = error.response.data;

              if (this.isSubdomainEditedManually) {
                this.suggestedSubdomain = errorData.__suggested_subdomain__;
                return Promise.reject(errorData);
              } else {
                // simply set suggest domain as current and pass validation
                this.roomData.subdomain =
                  errorData.__suggested_subdomain__ || "";
                return Promise.resolve();
              }
            }

            return Promise.reject({
              non_field_errors: ["Failed to validate subdomain"],
            });
          },
        );
      this.lastValidationRequest = currentReq;
      return currentReq;
    },
    submitForm() {
      const req = this.validate();
      req.then(
        () => {
          let promise: Promise<Deal | RoomCreateResp | void>;
          if (this.deal) {
            promise = this.createRoomForADeal();
          } else if (this.createRoom) {
            promise = this.createDealWithRoom();
          } else {
            promise = this.createDealWithoutRoom();
          }

          promise.then((roomData) => {
            this.$emit("created", roomData);
          });
          this.submitPromise(promise, "Failed to create room");
        },
        (err) => {},
      );
    },
    createDealWithRoom(): Promise<RoomCreateResp> {
      return this.roomService.create(cloneDeep(this.roomData));
    },
    createDealWithoutRoom(): Promise<Deal> {
      return this.dealsService.createDeal({
        title: this.roomData.title,
        phase: DrStore.state.clientDashboard.phases.items[0].id,
        custom_data: {},
        start_date: new Date(),
      });
    },
    async createRoomForADeal(): Promise<void> {
      try {
        await this.dealsService.createRoomForADeal(
          this.deal!.id,
          this.roomData,
        );
        $notifySuccess("Room was successfully created");
      } catch {
        $notifyDanger("Something went wrong while creating a room");
      }
    },
    focusOnTitleInput() {
      const $titleInput = this.$refs["titleInput"] as HTMLElement;
      if ($titleInput) {
        $titleInput.focus();
      }
    },
  },
});
</script>
