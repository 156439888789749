<template>
  <ElDropdown trigger="click" @command="handleCommand">
    <ElButton :type="type" :size="size">
      {{ t("shared.upload") }}
    </ElButton>
    <template #dropdown>
      <ElDropdownMenu>
        <ElDropdownItem command="upload_files">
          {{ t("shared.upload_files") }}
        </ElDropdownItem>
        <ElDropdownItem command="upload_folder">
          {{ t("shared.upload_folder") }}
        </ElDropdownItem>
      </ElDropdownMenu>
    </template>
  </ElDropdown>
</template>

<script setup lang="ts">
import { ElDropdown } from "element-plus";
import { useI18n } from "vue-i18n";

import { openFilesDialog } from "./utils";

const { t } = useI18n();

interface Props {
  size?: "" | "large" | "default" | "small";
  type?: "" | "default" | "primary";
}

interface Emits {
  /**
   * Fires when files are selected.
   * @param {"change"} event Event name.
   * @param {File[]} files Files to upload.
   */
  (event: "selected", files: File[]): void;
}

withDefaults(defineProps<Props>(), {
  size: "default",
  type: "default",
});

const emit = defineEmits<Emits>();

const handleCommand = (command: "upload_files" | "upload_folder") => {
  switch (command) {
    case "upload_files": {
      openFilesDialog(false).then(
        (files) => emit("selected", files),
        () => {},
      );
      break;
    }
    case "upload_folder": {
      openFilesDialog(true).then(
        (files) => emit("selected", files),
        () => {},
      );
      break;
    }
  }
};
</script>

<style lang="scss" module></style>
