import { Urls } from "@setups/urls";
import { http } from "./http";

import type { UrlsGetters } from "./types";
import type { FileHandledServerResponse } from "./types";
import type { AxiosRequestConfig, AxiosResponse } from "axios";

export type { UrlsGetters } from "./types";

const AttachmentFilenameRegexp = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;

export class ApiService {
  public Urls: UrlsGetters = Urls;

  public get<Response>(url: string, config?: AxiosRequestConfig) {
    return http.get<Response>(url, config).then(({ data }) => data);
  }

  public post<Response, Data = any>(
    url: string,
    data?: Data,
    config?: AxiosRequestConfig,
  ) {
    return http.post<Response>(url, data, config).then(({ data }) => data);
  }

  public postAndGetFile<Response = ArrayBuffer, Data = any>(
    url: string,
    data: Data,
    config?: AxiosRequestConfig,
  ): Promise<FileHandledServerResponse> {
    return http
      .post(url, data, {
        responseType: "arraybuffer",
        ...(config ?? {}),
      })
      .then((resp: AxiosResponse<Response>) => {
        const disposition = resp.headers["content-disposition"];
        let filename = "unknown_file";

        if (disposition && disposition.indexOf("attachment") !== -1) {
          const matches = AttachmentFilenameRegexp.exec(disposition);

          if (matches !== null && matches[1])
            filename = matches[1].replace(/['"]/g, "");
        }

        const file = new Blob([resp.data as BlobPart], {
          type: resp.headers["content-type"],
        });

        return {
          filename,
          file,
        };
      });
  }

  public getAndGetFile<Response = ArrayBuffer>(
    url: string,
    config?: AxiosRequestConfig,
  ): Promise<FileHandledServerResponse> {
    return http
      .get(url, {
        responseType: "arraybuffer",
        ...(config ?? {}),
      })
      .then((resp: AxiosResponse<Response>) => {
        const disposition = resp.headers["content-disposition"];
        let filename = "unknown_file";

        if (disposition && disposition.indexOf("attachment") !== -1) {
          const matches = AttachmentFilenameRegexp.exec(disposition);

          if (matches !== null && matches[1])
            filename = matches[1].replace(/['"]/g, "");
        }

        const file = new Blob([resp.data as BlobPart], {
          type: resp.headers["content-type"],
        });

        return {
          filename,
          file,
        };
      });
  }

  public put<Response, Data = any>(
    url: string,
    item: Data,
    config?: AxiosRequestConfig,
  ) {
    return http.put<Response>(url, item, config).then(({ data }) => data);
  }

  public patch<Response, Data = any>(
    url: string,
    item: Partial<Data>,
    config?: AxiosRequestConfig,
  ) {
    return http.patch<Response>(url, item, config).then(({ data }) => data);
  }

  public delete<Response>(url: string, config?: AxiosRequestConfig) {
    return http.delete<Response>(url, config).then(({ data }) => data);
  }
}
