<template>
  <ElInput
    ref="inputRef"
    type="number"
    :validate-event="false"
    :model-value="editProps.value"
    :placeholder="editProps.schema.placeholder"
    v-bind="editProps.veeField"
    @wheel.prevent
  >
    <template v-if="editProps.schema.extra.symbol" #prepend>
      {{ editProps.schema.extra.symbol }}
    </template>
  </ElInput>
</template>

<script setup lang="ts">
import { ElInput } from "element-plus";
import { onMounted, ref } from "vue";

import type { EditFieldProps } from "@drVue/components/client-dashboard/dynamic-form/Fields/types";

interface Props {
  editProps: EditFieldProps;
}

defineProps<Props>();

const inputRef = ref<InstanceType<typeof ElInput> | null>(null);
onMounted(() => inputRef.value?.focus());
</script>
