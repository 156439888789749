CreateNewFolderModalController.$inject = [
  "$scope",
  "DocumentsService",
  "DocumentsApiService",
  "AlertService",
  "parentFolderId",
];
import { useDocumentsStore } from "@app/vue/store/pinia/room/documents/documents";
import { LeftSidebarDocumentsTree } from "../services/DocumentsTreeService.ts";

export default function CreateNewFolderModalController(
  $scope,
  DocumentsService,
  DocumentsApiService,
  AlertService,
  parentFolderId,
) {
  var self = this;
  self.Documents = DocumentsService;
  self.parentFolderId = parentFolderId;
  self.createNewForm = undefined;

  const documentsStore = useDocumentsStore();

  self.submit = function () {
    self.createNewForm.$setSubmitted();
    if (self.createNewForm.$valid) {
      DocumentsApiService.createFolder(self.parentFolderId, self.name).then(
        function successCallback(response) {
          return documentsStore.syncTree().then(function () {
            var parent = DocumentsService.Folders[self.parentFolderId];
            if (parent) {
              LeftSidebarDocumentsTree.expandFolder(parent);
            }
            $scope.$close(response.data);
          });
        },
        function errorCallback(resp) {
          if (resp.data.name) {
            self.createNewForm.$setPristine();
            AlertService.danger(resp.data.name);
          } else {
            AlertService.danger("Failed to create a new folder.");
            $scope.$close();
          }
        },
      );
    }
  };
}
