import { ApiService } from "@drVue/api-service";

import type {
  AiDocReport,
  AiDocReportField,
  AiDocReportSummary,
  AiReport,
  AiReportEditable,
  AiReportField,
  AiReportSuggestion,
  AiTemplate,
  DocVerQA,
  DocVerQaID,
  DocVerQAReview,
} from "./types";
import type { FileHandledServerResponse } from "@app/vue/api-service/types";
import type { Document } from "@app/vue/store/modules/room/documents/DocumentsApiService";

export class DocVerQaApiService extends ApiService {
  public async loadQA(doc_uid: string) {
    const url: string = this.Urls["api:room:doc-ver-qa"](doc_uid);
    return this.get<DocVerQA[]>(url);
  }

  public async newQA(doc_uid: string, data: Pick<DocVerQA, "question">) {
    const url: string = this.Urls["api:room:doc-ver-qa"](doc_uid);
    return this.post<DocVerQA, typeof data>(url, data);
  }

  public async newBulkQA(doc_uid: string, data: Pick<DocVerQA, "question">[]) {
    const url: string = this.Urls["api:room:doc-ver-qa-bulk"](doc_uid);
    return this.post<DocVerQA, typeof data>(url, data);
  }

  public async deleteQA(doc_uid: string, doc_ver_qa_id: DocVerQaID) {
    const url: string = this.Urls["api:room:doc-ver-qa-details"](
      doc_uid,
      doc_ver_qa_id,
    );
    return this.delete(url);
  }

  public async review(
    doc_uid: string,
    doc_ver_qa_id: DocVerQaID,
    data: DocVerQAReview,
  ) {
    const url: string = this.Urls["api:room:doc-ver-qa-review"](
      doc_uid,
      doc_ver_qa_id,
    );
    return this.post<DocVerQA, typeof data>(url, data);
  }
}

export class AiTemplatesApiService extends ApiService {
  public loadTemplates() {
    const url: string = this.Urls["api:room:question-templates-list"]();
    return this.get<AiTemplate[]>(url);
  }

  public createTemplate(payload: Pick<AiTemplate, "question">) {
    const url: string = this.Urls["api:room:question-templates-list"]();
    return this.post<AiTemplate, Pick<AiTemplate, "question">>(url, payload);
  }

  public deleteTemplate(templateId: AiTemplate["id"]) {
    const url: string =
      this.Urls["api:room:question-templates-detail"](templateId);
    return this.delete<void>(url);
  }
}

export class AiReportsApiService extends ApiService {
  public async loadReports() {
    const url: string = this.Urls["api:room:report-type-templates-list"]();
    return this.get<AiReport[]>(url);
  }

  public async createReport(payload: AiReportEditable) {
    const url: string = this.Urls["api:room:report-type-templates-list"]();
    return this.post<AiReport>(url, payload);
  }

  public deleteReport(reportId: AiReport["id"]) {
    const url: string =
      this.Urls["api:room:report-type-templates-detail"](reportId);
    return this.delete<void>(url);
  }

  public updateReport(reportId: AiReport["id"], payload: AiReportEditable) {
    const url: string =
      this.Urls["api:room:report-type-templates-detail"](reportId);
    return this.patch<AiReport>(url, payload);
  }

  public bulkLoadDocReports(reportId: AiReport["id"]) {
    const url: string =
      this.Urls["api:room:report-type-templates-bulk-docs"](reportId);
    return this.get<AiDocReport[]>(url);
  }

  public loadDocReportSummary(reportId: AiReport["id"]) {
    const url: string =
      this.Urls["api:room:report-type-templates-summary"](reportId);
    return this.get<AiDocReportSummary[]>(url);
  }

  public loadSuggestedFields(reportId: AiReport["id"]) {
    const url: string =
      this.Urls["api:room:report-type-templates-detail-fields-suggest"](
        reportId,
      );
    return this.get<string[]>(url);
  }
  public async createReportField(
    reportId: AiReport["id"],
    payload: Pick<AiReportField, "label" | "prompt_template">,
  ) {
    const url: string =
      this.Urls["api:room:report-type-templates-detail-fields-list"](reportId);
    return this.post<AiReportField>(url, payload);
  }

  public async generateAndCreateReportField(
    reportId: AiReport["id"],
    payload: Pick<AiReportField, "label">,
  ) {
    const url: string =
      this.Urls["api:room:report-type-templates-detail-fields-generate"](
        reportId,
      );
    return this.post<AiReportField>(url, payload);
  }

  public async deleteReportField(
    reportId: AiReport["id"],
    fieldId: AiReportField["id"],
  ) {
    const url: string = this.Urls[
      "api:room:report-type-templates-detail-fields-detail"
    ](reportId, fieldId);
    return this.delete<void>(url);
  }

  public async loadDocReports(docId: string) {
    const url: string = this.Urls["api:room:doc-ver-reports-list"](docId);
    return this.get<AiDocReport[]>(url);
  }

  public async generateDocReport(docId: string, reportTypeTempalteId: string) {
    const url: string = this.Urls["api:room:doc-ver-reports-generate"](docId);
    return this.post<AiDocReport>(url, {
      report_type_template_id: reportTypeTempalteId,
    });
  }

  public async exportReports(reportTypeTempalteId: string) {
    const url =
      this.Urls["api:room:report-type-templates-reports-export"](
        reportTypeTempalteId,
      );
    return this.getAndGetFile<FileHandledServerResponse>(url);
  }

  public revokeReport(documentUid: Document["uid"], reportId: AiReport["id"]) {
    const url: string = this.Urls["api:room:doc-ver-reports-detail"](
      documentUid,
      reportId,
    );
    return this.delete<void>(url);
  }

  public loadReportsSuggestion() {
    const url: string = this.Urls["api:room:report-type-templates-builtin"]();
    return this.get<AiReportSuggestion[]>(url);
  }

  public importReportSuggested(
    builtin_report_template_key: AiReportSuggestion["key"],
  ) {
    const url: string = this.Urls["api:room:report-type-templates-import"]();
    return this.post<AiReport>(url, { builtin_report_template_key });
  }

  public updateReportDocField(
    docUid: Document["uid"],
    reportId: AiDocReport["id"],
    fieldId: AiDocReportField["id"],
    payload: Partial<Omit<AiDocReportField, "id">>,
  ) {
    const url: string = this.Urls[
      "api:room:doc-ver-reports-detail-fields-detail"
    ](docUid, reportId, fieldId);
    return this.patch<AiDocReportField>(url, payload);
  }
}
