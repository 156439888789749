import { keyBy } from "lodash-es";
import { h } from "vue";

import { t } from "@app/vue/i18n";
import { TableInlineEditor } from "@app/vue/shared/ui/table-inline-editor/TableInlineEditor";
import {
  type DocsItem,
  type Folder,
} from "@app/vue/store/modules/room/documents/DocumentsApiService";
import { TableColumnsBase } from "@drVue/TableColumnsBase";
import PermissionCell from "./cells/PermissionCell.vue";
import TitleCell from "./cells/TitleCell.vue";

import type { DrVxeTableColumn } from "@app/vue/components/types";
import type { Group } from "@app/vue/store/modules/room/groups/GroupsApiService";
import type { CustomViewColumn } from "@setups/types";
import type { ComputedRef, Ref } from "vue";

export default class TableColumns extends TableColumnsBase<DocsItem> {
  constructor(
    private readonly viewColumns: ComputedRef<CustomViewColumn[]>,
    private readonly group: Ref<Group | null>,
    private readonly groups: Ref<Group[]>,
    private readonly rootFolder: Ref<Folder | undefined>,
    private readonly hasSearch: Ref<boolean>,
    private readonly searchItems: Ref<DocsItem[]>,
  ) {
    super();
  }

  inlineEditor = new TableInlineEditor(async () => {
    // TODO: Implement editing
  }, "id");

  getUserColumns(): Record<string, CustomViewColumn> {
    const columns = this.viewColumns.value;
    return columns ? keyBy(columns, "field") : {};
  }

  getTableColumns(): DrVxeTableColumn<DocsItem>[] {
    const result = [this.indexColumn(), this.titleColumn()];

    if (this.group.value) result.push(this.permissionColumn(this.group.value));
    else {
      this.groups.value.forEach((group, index, array) => {
        result.push(
          this.permissionColumn(
            group,
            true,
            index === array.length - 1,
            group.builtin_admin_pgroup,
          ),
        );
      });
    }

    return result;
  }

  private indexColumn(): DrVxeTableColumn<DocsItem> {
    return {
      sortable: true,
      field: "tree_index",
      title: t("permissions.file.columns.index"),
      minWidth: 100,
      width: 100,
      sortBy: ({ row }) => row.treePosition,
    };
  }

  private titleColumn(): DrVxeTableColumn<DocsItem> {
    return {
      field: "name",
      title: t("permissions.file.columns.name"),
      minWidth: 300,

      sortable: true,
      showOverflow: false,
      treeNode: !this.hasSearch.value,
      slots: {
        default: ({ row, $grid }) => [
          h(TitleCell, {
            row,
            expanded: $grid?.isTreeExpandByRow(row) ?? false,
            hasSearch: this.hasSearch.value,
          }),
        ],
      },
    };
  }

  private permissionColumn(
    group: Group,
    short?: boolean,
    last?: boolean,
    isAdmin: boolean = false,
  ): DrVxeTableColumn<DocsItem> {
    return {
      field: "permissions" + group.id,
      title: t("permissions.file.columns.permissions"),
      width: short ? (last ? 96 : 64) : 160,
      sortable: false,
      showOverflow: false,
      showHeaderOverflow: false,
      slots: {
        default: ({ row }) => [
          h(PermissionCell, { row, group, short, isAdmin }),
        ],
        header: () => [
          h(PermissionCell, {
            row: this.hasSearch.value ? null : (this.rootFolder.value ?? null),
            group,
            short,
            withTitle: short,
            searchItems: this.searchItems.value,
            isAdmin,
          }),
        ],
      },
    };
  }
}
