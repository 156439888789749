import { Document } from "flexsearch";
import { cloneDeep } from "lodash-es";
import { keyBy } from "lodash-es";
import { orderBy } from "lodash-es";
import { reactive } from "vue";

import { USER_DATA } from "@setups/data";
import { flexSearchAllResultsArray } from "@app/flex";
import {
  assignees,
  creators,
  dueDateRange,
  followers,
  labels,
  overdue,
  priorities,
  reviewers,
  statuses,
  updatedRange,
} from "@app/ng/tasks/services/ts/predicates";
import { createDictionary } from "@drVue/common";
import { getSortValue } from "@drVue/store/modules/client-dashboard/deals/utils";
import { filterCustomFields } from "@drVue/store/modules/client-dashboard/fields/utils";
import {
  due_date,
  files,
  key,
  last_updated,
  new_comments_count,
  order,
  priority,
  start_date,
  status,
  title,
} from "./comparators";

import type { Filters } from "./types/Filters";
import type { Order } from "./types/Order";
import type { TaskComparator } from "./types/TaskComparator";
import type { TaskPredicate } from "./types/TaskPredicate";
import type { FieldItem } from "@drVue/store/modules/client-dashboard/fields/types";
import type { Category } from "@drVue/store/pinia/room/categories";
import type { Task } from "@drVue/store/pinia/room/tasks";
import type { Dictionary } from "@drVue/types";

const EMPTY_FILTERS: Filters = {
  searchText: "",
  showOverdueOnly: false,
  needReviewOnly: false,

  categories: [],

  assignees: [],
  showUnassigned: false,
  creators: [],
  followers: [],
  reviewers: [],
  showNoReviewers: false,

  statuses: [],
  labels: [],
  priorities: [],

  updatedRange: ["", ""],
  dueDateRange: ["", ""],

  custom_data: {},
};

export class TasksFilterService {
  constructor(private currentUserId: number) {}

  private readonly comparators: Dictionary<TaskComparator> = createDictionary({
    due_date,
    files,
    key,
    last_updated,
    new_comments_count,
    order,
    priority,
    start_date,
    status,
    title,
  });

  private readonly predicates: TaskPredicate[] = [
    assignees,
    creators,
    followers,
    reviewers,

    labels,
    priorities,
    statuses,

    dueDateRange,
    overdue,
    updatedRange,
  ];

  private customFields: FieldItem[] = [];

  filters: Filters = cloneDeep(EMPTY_FILTERS);

  private filtersKeys = Object.keys(this.filters);

  order: Order = {
    by: "order",
    reversed: true,
  };

  setOrder(order: Order) {
    this.order.by = order.by;
    this.order.reversed = order.reversed;
  }

  setCustomFields(fields: FieldItem[]) {
    this.customFields = fields;
  }

  isActive() {
    return this.filtersKeys.some((key) => {
      // We filter by categories but don't treat it as a filter
      if (key === "categories") return false;
      // We filter by searchText but don't treat it as a filter
      if (key === "searchText") return false;

      const filter = this.filters[key];
      if (key === "custom_data") {
        const customDataFilter = filter as Filters["custom_data"];
        return Object.keys(customDataFilter).some((cdKey) => {
          const customDataFilterValue = customDataFilter[cdKey];
          if (Array.isArray(customDataFilterValue)) {
            /** @note empty date range may have `["", ""]` as value */
            if (!customDataFilterValue[0] && !customDataFilterValue[1]) {
              return false;
            }
            return !!customDataFilterValue.length;
          }
          return !!customDataFilterValue;
        });
      }

      if (Array.isArray(filter)) {
        /** @note empty date range may have `["", ""]` as value */
        if (!filter[0] && !filter[1]) {
          return false;
        }

        return filter.length > 0;
      }

      return !!filter;
    });
  }

  clearFilterSelection(key: string) {
    // We filter by categories but don't treat it as a filter
    if (key === "categories") return;

    // We filter by searchText but don't treat it as a filter
    if (key === "searchText") return;

    this.filters[key] = cloneDeep(EMPTY_FILTERS[key]);
  }

  clearListFilters() {
    return this.filtersKeys.forEach((k) => this.clearFilterSelection(k));
  }

  clearListSearch() {
    return (this.filters.searchText = "");
  }

  filterTask(task: Task) {
    for (const predicate of this.predicates) {
      const result = predicate(task, this.filters);
      if (!result) return false;
    }

    if (Object.keys(this.filters.custom_data).length) {
      const filterCustomData = filterCustomFields(
        this.customFields,
        this.filters.custom_data,
        task.custom_data ?? {},
      );
      return filterCustomData;
    }

    return true;
  }

  isNeedMyReviewSelected() {
    return (
      this.filters.needReviewOnly &&
      this.filters.reviewers.length === 1 &&
      this.filters.reviewers[0] === this.currentUserId
    );
  }

  isAssignedToMeSelected() {
    return (
      this.filters.assignees.length === 1 &&
      this.filters.assignees[0] === this.currentUserId
    );
  }

  sort(items: Task[]) {
    if (items.length <= 1) return items;

    if (this.order.by.startsWith("custom_data.")) {
      const field = this.customFields.find((field) => {
        return `custom_data.${field.key}` === this.order.by;
      });

      if (!field) {
        throw `Invalid sorting order ${this.order.by}`;
      }

      return orderBy(
        items,
        [
          (task: Task) =>
            getSortValue(field, (task.custom_data || {})[field.key]),
        ],
        [this.order.reversed ? "desc" : "asc"],
      );
    }

    const comparator = this.comparators[this.order.by];
    if (!comparator) throw `Invalid sorting order ${this.order.by}`;

    const isReversed = this.order.reversed;
    items.sort((a, b) => comparator(a, b, isReversed));

    return items;
  }

  searchInTasks(tasks: Task[]) {
    if (!this.filters.searchText) return tasks;

    const flexIndex = new Document({
      document: {
        id: "id",
        index: [
          {
            field: "key",
            // https://github.com/nextapps-de/flexsearch#encoders
            charset: false as unknown as string,
            tokenize: "full",
          },
          {
            field: "title",
            charset: "latin:advanced",
            tokenize: "full",
          },
          {
            field: "body",
            charset: "latin:advanced",
            tokenize: "reverse",
          },
        ],
      },
    });
    tasks.forEach((t) => flexIndex.add(t));

    const foundSortedIds = flexSearchAllResultsArray(
      flexIndex,
      this.filters.searchText,
    );
    const tasksDictionary = keyBy(tasks, "id");
    return foundSortedIds.map((id) => tasksDictionary[id]);
  }

  searchInCategories(categories: Category[]) {
    if (!this.filters.searchText) {
      return categories;
    }

    const flexIndex = new Document({
      tokenize: "full",
      charset: "latin:advanced",
      document: {
        id: "id",
        index: ["name"],
      },
    });
    categories.forEach((c) => flexIndex.add(c));

    const foundSortedIds = flexSearchAllResultsArray(
      flexIndex,
      this.filters.searchText,
    );
    const categoriesDict = keyBy(categories, "id");

    return foundSortedIds.map((id) => categoriesDict[id]);
  }
}

export default reactive(new TasksFilterService(USER_DATA.id));
