RoomDocumentsSearchListController.$inject = [
  "$scope",
  "$stateParams",
  "$rootScope",
  "RoomConfig",
  "DocumentsService",
  "TasksService",
  "CategoriesService",
  "FileUploadService",
];

export default function RoomDocumentsSearchListController(
  $scope,
  $stateParams,
  $rootScope,
  RoomConfig,
  DocumentsService,
  TasksService,
  CategoriesService,
  FileUploadService,
) {
  var self = this;
  var docsCtrl = $scope.docsCtrl;

  docsCtrl.recent = $stateParams.recent ?? false;
  docsCtrl.bookmarks = $stateParams.bookmarks ?? false;
  docsCtrl.archive = $stateParams.archive ?? false;
  docsCtrl.isAiReports = false;

  self.recent = $stateParams.recent;
  self.bookmarks = $stateParams.bookmarks;

  self.Tasks = TasksService;
  self.Categories = CategoriesService;
  self.Documents = DocumentsService;
  self.dataService = undefined;

  self.folderId = parseInt($stateParams.folderId);
  docsCtrl.selectedFolderId = self.folderId;
  self.FileUploadService = FileUploadService;

  self.uploadAllowedFolders = [];

  self.allowDocVerQA =
    RoomConfig.userPermissions.administrator && RoomConfig.isAiEnabled;

  self.searchQuery = $stateParams.query;

  self.hasSelected = false;

  self.toggleItemSelected = function (item, $event) {
    self.dataService.toggleItemSelected(item, $event);
  };

  self.toggleSelectAllCheckbox = function () {
    self.dataService.update();
  };

  self.updateHasSelected = function (value) {
    self.hasSelected = value;
  };
}
