<template>
  <DrDrawer
    fixed-footer
    size="custom"
    title="New deal"
    :shown="shown"
    :is-submitting="isPending"
    @close="closeDrawer"
  >
    <template #custom>
      <DrLayout2ColumnPanel>
        <template #left>
          <PanelSection>
            <div :class="$style.header">
              <DrCopy :text="findingUrl">
                ID {{ findingEntity?.key ?? "" }}
              </DrCopy>

              <DrIcon
                v-if="findingEntity && findingEntity.is_archived"
                size="sm"
                name="trash-restore"
                title="Restore"
                :class="$style.actionIcon"
                @click="handleRestore(findingEntity.id)"
              />
              <DrIcon
                v-if="findingEntity && !findingEntity.is_archived"
                size="sm"
                name="trash"
                title="Archive"
                :class="$style.actionIcon"
                @click="handleArchive(findingEntity.id)"
              />
            </div>
          </PanelSection>
          <ElDivider :class="$style.dividerEmphasized" />

          <DynamicForm
            v-if="findingEntity"
            :entity="findingEntity"
            :schema="findingSchemaTitle"
            :submit-fn="handleFindingEntityUpdates"
          />

          <div ref="dropZoneWrapperRef" :class="$style.dropZone">
            <div v-show="isOverDropZone" :class="$style.dropZoneOverlay">
              <DrIcon name="download" size="lg" />
              <span>Drop your files here to attach them to this finding.</span>
            </div>

            <div :class="{ [$style.dropZoneContent_isHidden]: isOverDropZone }">
              <PanelSection>
                <DynamicForm
                  v-if="findingEntity"
                  :entity="findingEntity"
                  :schema="findingSchemaDescription"
                  :submit-fn="handleFindingEntityUpdates"
                />
              </PanelSection>
              <ElDivider :class="$style.dividerLarge" />

              <PanelSection title="Mitigation plan">
                <DynamicForm
                  v-if="findingEntity"
                  :entity="findingEntity"
                  :schema="findingSchemaMitigationPlan"
                  :submit-fn="handleFindingEntityUpdates"
                />
              </PanelSection>
              <ElDivider :class="$style.divider" />

              <PanelSection title="Actual mitigation">
                <DynamicForm
                  v-if="findingEntity"
                  :entity="findingEntity"
                  :schema="findingSchemaActualMitigation"
                  :submit-fn="handleFindingEntityUpdates"
                />
              </PanelSection>
              <ElDivider :class="$style.divider" />

              <PanelSection title="Ties to" :is-pending="isTiesToSubmitting">
                <template #title-action>
                  <FindingTiesToSelect :edit-props="editPropsTiesTo">
                    <template #reference="{ show }">
                      <DrIcon
                        v-if="!isViewOnly"
                        size="xs"
                        name="plus"
                        :class="$style.plusIcon"
                        @click="show()"
                      />
                    </template>
                  </FindingTiesToSelect>
                </template>

                <FindingTiesToList
                  show-empty
                  :view-only="isViewOnly"
                  :items="tiesToItems"
                  @remove="handleRemoveTiesToItem"
                />
              </PanelSection>
              <ElDivider :class="$style.divider" />

              <AttachmentsList
                v-if="findingEntity"
                :finding="findingEntity"
                :view-only="isViewOnly"
                @upload="uploadItems = $event"
              />
            </div>
          </div>

          <ElDivider
            v-if="findingEntity && !findingEntity.is_archived"
            :class="$style.dividerLarge"
          />

          <PanelSection
            v-if="findingEntity && !findingEntity.is_archived"
            title="Comments"
          >
            <FindingsComments :finding-id="findingId" :view-only="isViewOnly" />
          </PanelSection>
        </template>

        <template #right>
          <PanelSection
            v-if="findingEntity"
            underlined
            is-spaced-content
            :is-pending="isParticipantsSubmitting"
          >
            <FindingParticipantsList
              is-read-only
              :items="[{ user_id: findingEntity!.added_by_id }]"
            >
              <template #header>
                <DrItemListHeader title="Identified by" />
              </template>
            </FindingParticipantsList>

            <FindingParticipantsList
              :items="findingEntity!.assignees || []"
              @remove="handleRemoveAssigneesItem"
            >
              <template #header>
                <ParticipantsEdit
                  :edit-props="editPropsAssignees"
                  :filter-group="filterAssigneeGroup"
                >
                  <template #reference="{ show }">
                    <DrItemListHeader
                      title="Assignee"
                      :light-weight="!findingEntity!.assignees.length"
                      :allow-action="!isViewOnly"
                      @action="show"
                    />
                  </template>
                </ParticipantsEdit>
              </template>
            </FindingParticipantsList>

            <FindingParticipantsList
              :items="findingEntity!.followers || []"
              @remove="handleRemoveFollowersItem"
            >
              <template #header>
                <ParticipantsEdit
                  :edit-props="editPropsFollowers"
                  :filter-group="filterAssigneeGroup"
                >
                  <template #reference="{ show }">
                    <DrItemListHeader
                      title="Followers"
                      :light-weight="!findingEntity!.followers.length"
                      :allow-action="!isViewOnly"
                      @action="show"
                    />
                  </template>
                </ParticipantsEdit>
              </template>
            </FindingParticipantsList>
          </PanelSection>

          <div>
            <DynamicForm
              v-if="findingEntity"
              :entity="findingEntity"
              :schema="findingSchemaStatusFields"
              :submit-fn="handleFindingEntityUpdates"
            />
          </div>
        </template>
      </DrLayout2ColumnPanel>
    </template>
  </DrDrawer>

  <DrUploadDialog
    v-if="findingEntity && uploadItems?.length"
    :items="uploadItems"
    :attach-to-finding-id="findingEntity.id"
    @closed="clearUploadItems"
  />
</template>

<script setup lang="ts">
import { ElMessageBox } from "element-plus";
import { cloneDeep } from "lodash-es";
import { computed, ref, useCssModule, watch, watchEffect } from "vue";
import { useI18n } from "vue-i18n";
import { DrCopy } from "@shared/ui/dr-copy";
import { DrDrawer } from "@shared/ui/dr-drawer";
import { validateString } from "@shared/ui/dr-dynamic-form/utils";
import { useFormHelper } from "@shared/ui/dr-form";
import { DrIcon } from "@shared/ui/dr-icon";
import { DrItemListHeader } from "@shared/ui/dr-item-list";
import {
  DrLayout2ColumnPanel,
  DrLayoutColumnPanelSection as PanelSection,
} from "@shared/ui/dr-layouts";

import { ROOM_DATA, ROOM_MEMBER_DATA } from "@setups/data";
import { findingArchiveDetailsUrl, findingDetailsUrl } from "@setups/room-urls";
import {
  insightTrack,
  RoomFindingsCustomFieldsTrackingEvent,
  RoomFindingsDetailsEvent,
} from "@app/insight";
import {
  FindingLikelihoodList,
  FindingSeverityList,
} from "@drVue/api-service/modules/findings/types";
import { serializeCustomData } from "@drVue/api-service/parse";
import { $notifyDanger, $notifySuccess, $notifyWarning } from "@drVue/common";
import DynamicForm from "@drVue/components/client-dashboard/dynamic-form/DynamicForm.vue";
import EditText from "@drVue/components/client-dashboard/dynamic-form/Fields/Edit/Text.vue";
import { FieldSchemaType } from "@drVue/components/client-dashboard/dynamic-form/types";
import { mapCustomFieldToSchema } from "@drVue/components/client-dashboard/dynamic-form/utils";
import { ParticipantsEdit } from "@drVue/components/room/tasks/shared/widgets/participants";
import DrUploadDialog from "@drVue/shared/ui/dr-upload-dialog/DrUploadDialog.vue";
import DrStore from "@drVue/store";
import { pinia } from "@drVue/store/pinia";
import { useFindingsStatusesStore } from "@drVue/store/pinia/pipeline/findings-statuses";
import { useFindingsTypesStore } from "@drVue/store/pinia/pipeline/findings-types";
import { useCategoriesStore } from "@drVue/store/pinia/room/categories";
import { useFindingsStore } from "@drVue/store/pinia/room/findings";
import { useFindingsArchiveStore } from "@drVue/store/pinia/room/findingsArchived";
import { useTasksStore } from "@drVue/store/pinia/room/tasks";
import { useDropZone } from "@drVue/utils/useDropZone";
import FindingParticipantsList from "./components/FindingParticipantsList.vue";
import FindingTiesToList from "./components/FindingTiesToList.vue";
import FindingTiesToSelect from "./components/FindingTiesToSelect.vue";
import RichTextEditField from "./components/RichTextEditField.vue";
import RichTextViewField from "./components/RichTextViewField.vue";
import TitleViewField from "./components/TitleViewField.vue";
import {
  LikelihoodEditField,
  LikelihoodViewField,
} from "./fields/LikelihoodField";
import { SeverityEditField, SeverityViewField } from "./fields/SeverityField";
import { StatusEditField, StatusViewField } from "./fields/StatusField";
import { TypeEditField, TypeViewField } from "./fields/TypeField";
import FindingsComments from "./FindingsComments.vue";
import AttachmentsList from "./list/attachments/AttachmentList.vue";

import type {
  FindingDetails,
  FindingUpdatePayload,
  ParticipantsControlProps,
  TiesToControlProps,
} from "./types";
import type { FieldItem } from "@drVue/api-service/client-dashboard";
import type { Finding } from "@drVue/api-service/modules/findings/types";
import type { FormSchema } from "@drVue/components/client-dashboard/dynamic-form/types";
import type { CustomDataType } from "@drVue/store/modules/client-dashboard/fields/types";
import type { Group } from "@drVue/store/modules/room/groups/GroupsApiService";
import type { UploadItem } from "@drVue/utils/useDropZone";

const $style = useCssModule();

interface Props {
  findingId: FindingDetails["id"];
  shown: boolean;
}
const props = defineProps<Props>();
const emit = defineEmits(["close"]);

const { t } = useI18n();

const uploadItems = ref<any[] | null>(null);
const clearUploadItems = () => {
  uploadItems.value = null;
};

const dropZoneWrapperRef = ref<HTMLDivElement | null>(null);
const { isOverDropZone } = useDropZone(dropZoneWrapperRef, {
  onDrop: (promise) => {
    promise.then((items: UploadItem[]) => {
      uploadItems.value = items;
      insightTrack(RoomFindingsDetailsEvent.AttachmentDrop);
    });
  },
});

const findingsStore = useFindingsStore(pinia);
const findingsArchiveStore = useFindingsArchiveStore(pinia);
const findingsStatusesStore = useFindingsStatusesStore(pinia);
const findingsTypesStore = useFindingsTypesStore(pinia);
const categoriesStore = useCategoriesStore(pinia);
const tasksStore = useTasksStore(pinia);

const isAdministrator = ROOM_MEMBER_DATA?.group?.is_administrator ?? false;
const isPending = computed(() => !findingEntity.value);

const isViewOnly = computed(() => {
  if (findingEntity.value === null) return true;
  return findingEntity.value.is_archived;
});

const findingUrl = computed(() => {
  if (!findingEntity.value) return "";

  return findingEntity.value.is_archived
    ? findingArchiveDetailsUrl(ROOM_DATA.url, findingEntity.value.key)
    : findingDetailsUrl(ROOM_DATA.url, findingEntity.value.key);
});

const findingEntity = ref<Finding | null>(null);
watchEffect(() => {
  // We should touch all the dependencies to inform Vue to subscribe for them.
  const fromActive = findingsStore.dict[props.findingId];
  const fromArchive = findingsArchiveStore.dict[props.findingId];

  findingEntity.value = fromActive ?? fromArchive ?? findingEntity.value;
});

type SelectExtra = {
  select_options: ReadonlyArray<{ label: string; value?: string | number }>;
};

const isFindingsCustomFieldsEnabled = ROOM_DATA.enableFindingCustomFields;

const customFields = computed<FieldItem[]>(() => {
  return DrStore.getters["clientDashboard/customFields/byObjectType"](
    "finding",
  );
});

const findingsCustomFieldsSchema = computed(() =>
  isFindingsCustomFieldsEnabled
    ? customFields.value.map((field) => ({
        ...mapCustomFieldToSchema(field),
        isReadOnly: isViewOnly.value,
      }))
    : [],
);

const findingSchemaStatusFields = computed<FormSchema<SelectExtra>>(() => ({
  fields: [
    {
      type: FieldSchemaType.Custom,
      prop: "type_id",
      label: "Type",
      placeholder: "Select type",
      extra: {
        select_options: findingsTypesStore.list.map((type) => ({
          label: type.name,
          value: type.id,
        })),
      },
      rules: validateString().required(`"Type" is required`),
      viewComponent: TypeViewField,
      editComponent: TypeEditField,
      isReadOnly: isViewOnly.value,
    },
    {
      type: FieldSchemaType.Custom,
      prop: "severity",
      label: "Severity",
      placeholder: "Specify severity",
      extra: {
        select_options: FindingSeverityList,
      },
      viewComponent: SeverityViewField,
      editComponent: SeverityEditField,
      isReadOnly: isViewOnly.value,
    },
    {
      type: FieldSchemaType.Custom,
      prop: "likelihood",
      label: "Likelihood",
      placeholder: "Specify likelihood",
      extra: {
        select_options: FindingLikelihoodList,
      },
      viewComponent: LikelihoodViewField,
      editComponent: LikelihoodEditField,
      isReadOnly: isViewOnly.value,
    },
    {
      type: FieldSchemaType.Custom,
      prop: "status_id",
      label: "Status",
      placeholder: "Specify status",
      extra: {
        select_options: findingsStatusesStore.list.map((status) => ({
          label: status.name,
          value: status.id,
        })),
      },
      rules: validateString().required(`"Status" is required`),
      viewComponent: StatusViewField,
      editComponent: StatusEditField,
      isReadOnly: isViewOnly.value,
    },
    {
      type: FieldSchemaType.Date,
      prop: "date_added",
      label: "Identified",
      isReadOnly: true,
    },
    {
      type: FieldSchemaType.Date,
      prop: "date_resolved",
      label: "Resolved",
      isReadOnly: true,
    },
    ...findingsCustomFieldsSchema.value,
  ],
}));

const EMPTY_PLACEHOLDER = "-";

const findingSchemaTitle = computed<FormSchema>(() => ({
  fields: [
    {
      type: FieldSchemaType.Custom,
      prop: "title",
      placeholder: "Specify title",
      rules: validateString()
        .required(`"Title" is required`)
        .min(8, `"Title" must be at least 8 characters`),
      viewComponent: TitleViewField,
      editComponent: EditText,
      isReadOnly: isViewOnly.value,
    },
  ],
}));

const findingSchemaDescription = computed<FormSchema>(() => ({
  fields: [
    {
      type: FieldSchemaType.Custom,
      prop: "description",
      placeholder: isViewOnly.value
        ? EMPTY_PLACEHOLDER
        : "Click to add an actual description",
      viewComponent: RichTextViewField,
      editComponent: RichTextEditField,
      isReadOnly: isViewOnly.value,
    },
  ],
}));

const findingSchemaMitigationPlan = computed<FormSchema>(() => ({
  fields: [
    {
      type: FieldSchemaType.Custom,
      prop: "mitigation_plan",
      placeholder: isViewOnly.value
        ? EMPTY_PLACEHOLDER
        : "Click to add an mitigation plan",
      viewComponent: RichTextViewField,
      editComponent: RichTextEditField,
      isReadOnly: isViewOnly.value,
    },
  ],
}));

const findingSchemaActualMitigation = computed<FormSchema>(() => ({
  fields: [
    {
      type: FieldSchemaType.Custom,
      prop: "actual_mitigation",
      placeholder: isViewOnly.value
        ? EMPTY_PLACEHOLDER
        : "Click to add an actual mitigation",
      viewComponent: RichTextViewField,
      editComponent: RichTextEditField,
      isReadOnly: isViewOnly.value,
    },
  ],
}));

const tiesToItems = computed(() =>
  findingEntity.value
    ? [
        ...findingEntity.value.tasks.slice(0),
        ...findingEntity.value.categories.slice(0),
      ]
    : [],
);

const tiesToCategoriesIds = computed(() =>
  (findingEntity.value?.categories || [])
    .map((cat) => categoriesStore.categoriesByUid[cat.category_uid]?.id)
    .filter(Boolean),
);

const tiesToRequestCategoriesIds = computed(() =>
  (findingEntity.value?.tasks || [])
    .map((task) => tasksStore.tasksByUid[task.task_uid]?.category_id)
    .filter(Boolean),
);

const filterAssigneeGroup = (group: Group) => {
  const allowByCategories = tiesToCategoriesIds.value.some((categoryId) =>
    group.viewable_categories_ids.includes(categoryId),
  );
  const allowByTasks = tiesToRequestCategoriesIds.value.some((categoryId) =>
    group.viewable_categories_ids.includes(categoryId),
  );

  if (group.is_finding_managers) {
    if (tiesToCategoriesIds.value.length && allowByCategories) {
      return true;
    }
    if (tiesToRequestCategoriesIds.value.length && allowByTasks) {
      return true;
    }
    if (
      !tiesToCategoriesIds.value.length &&
      !tiesToRequestCategoriesIds.value.length
    ) {
      return true;
    }
  }
  return false;
};

const checkAssigneesGroupPermissions = () => {
  if (!findingEntity.value) return;

  const assignees = findingEntity.value.assignees.map(
    (assignee) => DrStore.state.room.members.membersByUid[assignee.user_id],
  );
  const followers = findingEntity.value.followers.map(
    (follower) => DrStore.state.room.members.membersByUid[follower.user_id],
  );

  const assigneesGroups = Object.values(
    assignees.reduce<Record<Group["id"], Group>>((acc, assignee) => {
      const group = DrStore.state.room.groups.pgroups[assignee.pgroup.id];
      if (group && !acc[group.id]) {
        acc[group.id] = group;
      }
      return acc;
    }, {}),
  );
  const followersGroups = Object.values(
    followers.reduce<Record<Group["id"], Group>>((acc, follower) => {
      const group = DrStore.state.room.groups.pgroups[follower.pgroup.id];
      if (group && !acc[group.id]) {
        acc[group.id] = group;
      }
      return acc;
    }, {}),
  );

  const allowedAssigneesGroupsIds = assigneesGroups
    .filter(filterAssigneeGroup)
    .map((group) => group.id);
  const allowedFollowersGroupsIds = followersGroups
    .filter(filterAssigneeGroup)
    .map((group) => group.id);

  const needUpdateAssignees =
    assigneesGroups.length > allowedAssigneesGroupsIds.length;
  const needUpdateFollowers =
    followersGroups.length > allowedFollowersGroupsIds.length;

  if (needUpdateAssignees || needUpdateFollowers) {
    if (needUpdateAssignees) {
      findingEntity.value.assignees = assignees
        .filter((assignee) =>
          allowedAssigneesGroupsIds.includes(assignee.pgroup.id),
        )
        .map((assignee) => ({ user_id: assignee.uid }));

      handleFindingEntityAssigneesChanges(findingEntity.value.assignees);
      $notifyWarning(t("findings.assignees_filtered_out_by_perms"));
    }
    if (needUpdateFollowers) {
      findingEntity.value.followers = followers
        .filter((follower) =>
          allowedFollowersGroupsIds.includes(follower.pgroup.id),
        )
        .map((follower) => ({ user_id: follower.uid }));

      handleFindingEntityFollowersChanges(findingEntity.value.followers);
      $notifyWarning(t("findings.followers_filtered_out_by_perms"));
    }
  }
};

const closeDrawer = () => {
  emit("close");
};

const handleFindingEntityUpdates = async (updates: FindingUpdatePayload) => {
  if ("severity" in updates && !updates.severity) {
    updates.severity = null;
  }
  if ("likelihood" in updates && !updates.likelihood) {
    updates.likelihood = null;
  }

  let eventName: RoomFindingsDetailsEvent | null = null;
  if ("type_id" in updates) {
    eventName = RoomFindingsDetailsEvent.TypeChanged;
  }
  if ("status_id" in updates) {
    eventName = RoomFindingsDetailsEvent.StatusChanged;
  }
  if ("severity" in updates) {
    eventName = RoomFindingsDetailsEvent.SeverityChanged;
  }
  if ("likelihood" in updates) {
    eventName = RoomFindingsDetailsEvent.LikelihoodChanged;
  }
  if ("title" in updates) {
    eventName = RoomFindingsDetailsEvent.TitleChanged;
  }
  if ("description" in updates) {
    eventName = RoomFindingsDetailsEvent.DescriptionChanged;
  }
  if ("mitigation_plan" in updates) {
    eventName = RoomFindingsDetailsEvent.MitigationPlanChanged;
  }
  if ("actual_mitigation" in updates) {
    eventName = RoomFindingsDetailsEvent.ActualMitigationChanged;
  }

  if (typeof updates.custom_data === "object") {
    serializeCustomData(
      updates.custom_data as CustomDataType,
      customFields.value,
    );

    const customFieldKey = Object.keys(
      updates.custom_data as CustomDataType,
    )[0];
    const customFieldInfo = customFields.value.find(
      (field) => field.key === customFieldKey,
    );

    if (customFieldInfo) {
      insightTrack(RoomFindingsCustomFieldsTrackingEvent.FieldValueChanged, {
        source: "details",
        type: customFieldInfo.field_type,
        name: customFieldInfo.label,
      });
    }
  }

  if (eventName) {
    insightTrack(eventName);
  }

  return findingsStore.update(findingEntity.value!.id, updates);
};

const {
  hookFormSubmitPromise: participantsSubmitPromise,
  isFormSubmitting: isParticipantsSubmitting,
} = useFormHelper<FindingDetails>();
const {
  hookFormSubmitPromise: tiesToSubmitPromise,
  isFormSubmitting: isTiesToSubmitting,
} = useFormHelper<FindingDetails>();

const handleFindingEntityAssigneesChanges = (
  assignees: FindingDetails["assignees"],
) => {
  participantsSubmitPromise(
    findingsStore.updateAssignees(
      findingEntity.value!.id,
      cloneDeep(assignees),
    ),
  ).then(() => insightTrack(RoomFindingsDetailsEvent.AssigneesChanged));
};

const handleFindingEntityFollowersChanges = (
  followers: FindingDetails["followers"],
) => {
  participantsSubmitPromise(
    findingsStore.updateFollowers(
      findingEntity.value!.id,
      cloneDeep(followers),
    ),
  ).then(() => insightTrack(RoomFindingsDetailsEvent.FollowersChanged));
};

const handleFindingEntityTiesToChanges = (tiesTo: {
  tasks?: FindingDetails["ties_to"]["tasks"];
  categories?: FindingDetails["ties_to"]["categories"];
}) => {
  tiesToSubmitPromise(
    findingsStore.updateTiesTo(findingEntity.value!.id, cloneDeep(tiesTo)),
  ).then(() => {
    insightTrack(RoomFindingsDetailsEvent.TiesToChanged);
    checkAssigneesGroupPermissions();
  });
};

const editPropsAssignees = computed<ParticipantsControlProps["editProps"]>(
  () => {
    return {
      schema: {
        type: FieldSchemaType.Select,
        prop: "assignees",
        label: "Assignees",
        isReadOnly: isViewOnly.value,
      },
      value: findingEntity.value?.assignees || [],
      veeField: {
        onBlur: () => {},
        onInput: handleFindingEntityAssigneesChanges,
        onChange: handleFindingEntityAssigneesChanges,
      },
    };
  },
);

const editPropsFollowers = computed<ParticipantsControlProps["editProps"]>(
  () => {
    return {
      schema: {
        type: FieldSchemaType.Select,
        prop: "followers",
        label: "Followers",
        isReadOnly: isViewOnly.value,
      },
      value: findingEntity.value?.followers || [],
      veeField: {
        onBlur: () => {},
        onInput: handleFindingEntityFollowersChanges,
        onChange: handleFindingEntityFollowersChanges,
      },
    };
  },
);

const editPropsTiesTo = computed<TiesToControlProps["editProps"]>(() => {
  return {
    schema: {
      type: FieldSchemaType.Select,
      prop: "ties_to",
      label: "Ties to",
      isReadOnly: isViewOnly.value,
    },
    value: {
      tasks: findingEntity.value?.tasks || [],
      categories: findingEntity.value?.categories || [],
    },
    veeField: {
      onBlur: () => {},
      onInput: handleFindingEntityTiesToChanges,
      onChange: handleFindingEntityTiesToChanges,
    },
  };
});

const handleRemoveAssigneesItem = ({
  user_id,
}: FindingDetails["assignees"][number]) => {
  handleFindingEntityAssigneesChanges(
    findingEntity.value!.assignees.filter(
      (assignee) => assignee.user_id !== user_id,
    ),
  );
};

const handleRemoveFollowersItem = ({
  user_id,
}: FindingDetails["followers"][number]) => {
  handleFindingEntityFollowersChanges(
    findingEntity.value!.followers.filter(
      (follower) => follower.user_id !== user_id,
    ),
  );
};

const handleRemoveTiesToItem = (
  item:
    | FindingDetails["ties_to"]["tasks"][number]
    | FindingDetails["ties_to"]["categories"][number],
) => {
  if (tiesToItems.value.length === 1 && !isAdministrator) {
    ElMessageBox.alert(
      "Finding should be tied at least to one request or worklist.",
      "This item cannot be disassociated.",
      {
        confirmButtonText: "Ok",
        customClass: $style.warnMessageBox,
        showClose: false,
      },
    );
  } else {
    if ("task_uid" in item && item.task_uid) {
      handleFindingEntityTiesToChanges({
        tasks: findingEntity.value!.tasks.filter(
          ({ task_uid }) => task_uid !== item.task_uid,
        ),
      });
    }
    if ("category_uid" in item && item.category_uid) {
      handleFindingEntityTiesToChanges({
        categories: findingEntity.value!.categories.filter(
          ({ category_uid }) => category_uid !== item.category_uid,
        ),
      });
    }
  }
};

const handleArchive = (id: FindingDetails["id"]) => {
  ElMessageBox.alert(
    "Are you sure you want to archive this finding?",
    "Archive finding",
    {
      type: "warning",
      confirmButtonClass: "el-button--danger",
      confirmButtonText: "Archive",
      showCancelButton: true,
      showClose: false,
      cancelButtonText: "No",
      closeOnHashChange: false,
      closeOnPressEscape: true,
      beforeClose: async (action, instance, done) => {
        if (action === "confirm") {
          instance.showCancelButton = false;
          instance.confirmButtonLoading = true;

          findingsStore
            .remove(id)
            .then(
              () => {
                if (ROOM_MEMBER_DATA.group.is_administrator) {
                  findingsArchiveStore.load();
                }

                $notifySuccess("Finding has been archived");

                closeDrawer();
              },
              () => {
                $notifyDanger("Failed to archive finding");
              },
            )
            .finally(() => {
              done();
            });
        } else {
          done();
        }
      },
    },
  );
};

const handleRestore = (id: FindingDetails["id"]) => {
  findingsArchiveStore
    .restore(id)
    .then(
      () => {
        $notifySuccess("Finding has been restored");
        return findingsStore.load();
      },
      () => {
        $notifyDanger("Failed to restore finding");
      },
    )
    .then(() => {
      closeDrawer();
    });
};

watch(
  () => props.shown,
  (isShown) => {
    if (isShown) {
      insightTrack(RoomFindingsDetailsEvent.ModalOpened);
    }
  },
);
</script>

<style lang="scss" module>
@use "@app/styles/scss/colors";
@use "@app/styles/scss/spacing";
@use "@app/styles/scss/typography";

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: spacing.$m;
  overflow: hidden;
}

.actionIcon {
  width: 24px;
  height: 24px;
  cursor: pointer;
  color: colors.$pr-400;
  border: solid 2px transparent;
  border-radius: 6px;
  transition: color 0.1s ease-out;

  &:hover {
    color: colors.$pr-500;
  }

  &:active {
    color: colors.$pr-600;
  }
}

.plusIcon {
  --size: 16px;
  color: colors.$pr-400;
  border: solid 1px colors.$pr-150;
  border-radius: 4px;
  cursor: pointer;

  &:hover {
    color: colors.$sc-600;
    border-color: colors.$sc-400;
  }
}

.warnMessageBox {
  &:global(.el-message-box) {
    max-width: 448px;
    border-left: 2px solid colors.$ad-10;
    padding: spacing.$l spacing.$xl spacing.$xl;
  }

  :global {
    .el-message-box__header {
      padding: 0 0 spacing.$xs;
    }

    .el-message-box__title {
      color: colors.$pr-900;
      font: typography.$title_bold;
      border-top: none;
      border-bottom: none;
    }

    .el-message-box__content {
      padding: 0 0 spacing.$xl;
      color: colors.$pr-900;
      font: typography.$body_regular;
    }

    .el-message-box__btns {
      padding: 0;

      .el-button.el-button--primary {
        --el-button-bg-color: #{colors.$ad-10};
        --el-button-border-color: #{colors.$ad-10};
        --el-button-hover-bg-color: #{colors.$ad-10_5};
        --el-button-hover-border-color: #{colors.$ad-10_5};
        --el-button-active-bg-color: #{colors.$ad-10_5};
        --el-button-active-border-color: #{colors.$ad-10_5};
        --el-button-outline-color: transparent;
      }
    }
  }
}

.dropZone {
  position: relative;
}

.dropZoneContent_isHidden {
  opacity: 0;
  height: 604px;
}

.dropZoneOverlay {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 100;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 12px;
  background: colors.$sc-50;
  border-radius: 8px;
  border: 1px dashed colors.$sc-400;
  color: colors.$sc-600;
  font: typography.$body_medium;

  span {
    text-align: center;
    width: 243px;
  }
}

.divider:global(.el-divider--horizontal) {
  border-color: colors.$pr-100;
  margin: spacing.$s 0 spacing.$xs;
}

.dividerLarge:global(.el-divider--horizontal) {
  border-color: colors.$pr-100;
  width: calc(100% + 48px);
  margin: spacing.$s -24px;
}

.dividerEmphasized:global(.el-divider--horizontal) {
  border-color: colors.$pr-150;
  margin: spacing.$m 0 spacing.$s;
}
</style>
