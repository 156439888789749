<template>
  <DrLayoutContent>
    <template #nav>
      <DrViewBar :items="views" @select="selectView" />
    </template>

    <template #toolbar>
      <YourWorkDealTasksToolbarFilters
        :search="toolbarSearch"
        :filters="toolbarFilters"
        @update:filter="handleToolbarFilterUpdate"
        @update:search="handleToolbarSearchUpdate"
      />
    </template>

    <template #toolbar-right>
      <DrPopup>
        <DrFormWrapper :width="POPUP_SIZES.form.width">
          <ElFormItem label="Columns">
            <DrSorter
              :items="sorterItems"
              @update-order="handleColumnsReordering"
              @update-visible="handleColumnsReordering"
            />
          </ElFormItem>
        </DrFormWrapper>

        <template #reference>
          <ElButton icon-right>
            Adjust View
            <template #icon>
              <DrIcon name="columns" size="sm" />
            </template>
          </ElButton>
        </template>
      </DrPopup>
    </template>

    <DrOverlayEmpty
      v-if="noDisplayData.active"
      icon="tasks"
      :title="noDisplayData.title"
      :subtitle="noDisplayData.subtitle"
    >
      <template #action>
        <ElButton
          v-if="noDisplayData.btnClearSearch"
          @click="toolbarSearch = ''"
        >
          Clear search query
        </ElButton>
        <ElButton
          v-if="noDisplayData.btnClearFilters"
          @click="currentFilter = {}"
        >
          Reset filters
        </ElButton>
      </template>
    </DrOverlayEmpty>
    <DrVxeGrid
      :row-config="{
        useKey: true,
        keyField: 'id',
        height: 38,
      }"
      :columns="tableColumns"
      :data="searchedTasks"
      :sort-config="tableSortConfig"
      @resizable-change="handleColumnSizeChanged"
      @sort-change="handleColumnSortChanged"
    >
      <template #empty>No deal tasks found</template>
    </DrVxeGrid>

    <DrNewLoader v-show="isLoading" overlay />
  </DrLayoutContent>
</template>

<script lang="ts" setup>
import { cloneDeep } from "lodash-es";
import { intersection } from "lodash-es";
import { isEqual } from "lodash-es";
import { orderBy } from "lodash-es";
import { computed, onBeforeMount, ref, watch } from "vue";
import { POPUP_SIZES } from "@shared/ui/constants";
import { DrFormWrapper } from "@shared/ui/dr-form";
import { DrIcon } from "@shared/ui/dr-icon";
import { DrLayoutContent } from "@shared/ui/dr-layouts";
import { DrNewLoader } from "@shared/ui/dr-loader";
import { DrOverlayEmpty } from "@shared/ui/dr-overlay";
import { DrPopup } from "@shared/ui/dr-popups";
import { DrSorter } from "@shared/ui/dr-sorter";
import { DrViewBar } from "@shared/ui/dr-view-bar";
import DrVxeGrid from "@shared/ui/dr-vxe-grid";
import { useStorage } from "@vueuse/core";

import { Document, flexSearchAllResultsArray } from "@app/flex";
import { DealTasksEvent, insightTrack } from "@app/insight";
import { $notifyDanger } from "@drVue/common";
import DrStore from "@drVue/store";
import { pinia } from "@drVue/store/pinia";
import { useYourWorkDealTasksStore } from "@drVue/store/pinia/your-work/deal-tasks";
import TableColumns from "./tableColumns";
import { mapYourWorkDealTasksFieldToMatchingFunction } from "./types";
import YourWorkDealTasksToolbarFilters from "./YourWorkDealTasksToolbarFilters.vue";

import type {
  YourWorkDealTasksCustomViewsFilters,
  YourWorkDealTasksFilters,
  YourWorkDealTasksTableColumn,
  YourWorkDealTasksTableField,
  YourWorkDealTasksTableRow,
  YourWorkDealTasksToolbarFiltersUpdate,
} from "./types";
import type { CustomViewColumn } from "@setups/types";
import type { SorterItem } from "@shared/ui/dr-sorter";
import type { Ref } from "vue";
import type { VxeTableDefines, VxeTablePropTypes } from "vxe-table";

/** @todo: move to store */
type TaskView = {
  id: string;
  name: string;
  filter: YourWorkDealTasksCustomViewsFilters;
};

type SortOrder = "asc" | "desc";
type StoredSettingsColumn = CustomViewColumn & {
  sort?: SortOrder;
};
type StoredSettings = Record<YourWorkDealTasksTableField, StoredSettingsColumn>;

const generateSettingsColumn = (key: YourWorkDealTasksTableField) => ({
  field: key,
  width: undefined,
  order: undefined,
  hidden: false,
});

const STORED_COLUMNS_SETTINGS_KEY =
  "dr:your_work_deal_tasks_table_stored_columns_settings";
const STORED_COLUMNS_CURRENT_SORTED_KEY =
  "dr:your_work_deal_tasks_table_stored_sorted_field";
const STORED_SELECTED_VIEW_KEY = "dr:your_work_deal_tasks_stored_view_key";

const columnsStoredSettings = useStorage<StoredSettings>(
  STORED_COLUMNS_SETTINGS_KEY,
  {
    due_datetime: generateSettingsColumn("due_datetime"),
    title: generateSettingsColumn("title"),
    deal_uid: generateSettingsColumn("deal_uid"),
    date_resolved: generateSettingsColumn("date_resolved"),
    is_resolved: generateSettingsColumn("is_resolved"),
  },
);
const currentTableSortedField = useStorage<YourWorkDealTasksTableField>(
  STORED_COLUMNS_CURRENT_SORTED_KEY,
  "due_datetime",
);

const yourWorkDealTasks = useYourWorkDealTasksStore(pinia);

const columnsConfig = new TableColumns();

const toolbarSearch = ref("");
const toolbarFilters = computed(() => {
  return {
    due_datetime: extractToolbarFieldValueFromFilter("due_datetime"),
  } as YourWorkDealTasksFilters;
});

const getDealByUid = DrStore.getters["clientDashboard/deals/getDealByUid"];

const sortTableData = (
  data: YourWorkDealTasksTableRow[],
  field: YourWorkDealTasksTableField,
  order: SortOrder | null,
) => {
  let list = [...data];

  if (order === "asc" || order === "desc") {
    const lastDateValue =
      new Date(3000, 0, 1).getTime() * (order === "asc" ? 1 : -1);

    switch (field) {
      case "due_datetime":
        list = orderBy(list, [(task) => task.due_datetime.getTime()], [order]);
        break;
      case "date_resolved":
        list = orderBy(
          list,
          // to sort tasks without "date resolved" always below
          [(task) => task.date_resolved?.getTime() ?? lastDateValue],
          [order],
        );
        break;
      case "title":
        list = orderBy(list, [field], [order]);
        break;
      case "deal_uid":
        list = orderBy(list, (task) => task.deal.title, [order]);
        break;
    }
  }

  return list;
};

const tableSortConfig: VxeTablePropTypes.SortConfig<YourWorkDealTasksTableRow> =
  {
    defaultSort: {
      field: currentTableSortedField.value,
      order:
        columnsStoredSettings.value[currentTableSortedField.value].sort ??
        "asc",
    },
    trigger: "cell",
    sortMethod: ({ data, sortList }) => {
      const sortItem = sortList[0];
      const { field, order } = sortItem;

      return sortTableData(
        data as YourWorkDealTasksTableRow[],
        field as YourWorkDealTasksTableField,
        // @ts-expect-error: ...
        order,
      );
    },
  };

const columnsItems = computed(() => {
  return columnsConfig.columns
    .map((column, index) => {
      const fieldSettings = columnsStoredSettings.value[column.field];

      return {
        id: column.field,
        order: fieldSettings?.order ?? index,
        fixed: !!column.fixed,
        visible: fieldSettings ? !fieldSettings.hidden : true,
        title: column.title || "",
      };
    })
    .sort((a, b) => a.order - b.order);
});
const sorterItems = computed(() => {
  return columnsItems.value.filter((c) => !!c.title);
});
const tableColumns = computed(() => {
  return columnsItems.value.map((columnItem) => {
    const column =
      columnsConfig.columns.find(
        (configItem) => configItem.field === columnItem.id,
      ) || {};

    return {
      ...column,
      ...columnItem,
    } as unknown as YourWorkDealTasksTableColumn;
  });
});

const currentFilter: Ref<TaskView["filter"]> = ref({});

const tasks = computed<YourWorkDealTasksTableRow[]>(() => {
  return yourWorkDealTasks.list.map((task) => {
    const dealData = getDealByUid(task.deal_uid);
    const deal = {
      ...(dealData ? dealData : { title: "unknown deal" }),
      url: `/deals/details/${task.deal_uid}`,
    };

    return {
      ...task,
      deal,
    };
  });
});

const tasksMap = computed(() => {
  return tasks.value.reduce(
    (acc, task) => {
      acc[task.id] = task;
      return acc;
    },
    {} as Record<YourWorkDealTasksTableRow["id"], YourWorkDealTasksTableRow>,
  );
});

watch(
  () => yourWorkDealTasks.isLoadError,
  (error) => {
    if (error) {
      $notifyDanger("Failed to load user deal tasks information");
    }
  },
);

const extractToolbarFieldValueFromFilter = (
  field: keyof YourWorkDealTasksFilters,
) => {
  return currentFilter.value[field]?.value ?? [];
};

const isLoading = computed(() => yourWorkDealTasks.isLoading);

const predefinedViews: TaskView[] = [
  {
    id: "todo",
    name: "To do",
    filter: {
      is_resolved: {
        op: "eq",
        value: [false],
      },
    },
  },
  {
    id: "done",
    name: "Completed",
    filter: {
      is_resolved: {
        op: "eq",
        value: [true],
      },
    },
  },
  {
    id: "all",
    name: "All tasks",
    filter: {},
  },
];

const currentSelectedView = useStorage<TaskView["id"]>(
  STORED_SELECTED_VIEW_KEY,
  "todo",
);

const views = computed(() =>
  predefinedViews.map((item) => {
    const filteredTasks = getTasksByFilter(item.filter);

    return {
      ...item,
      counter: isLoading.value ? "" : `${filteredTasks.length}`,
      isActive: isLoading.value ? false : item.id === currentViewId.value,
    };
  }),
);

const currentViewId = computed(() => {
  return (
    predefinedViews.find((view) => isEqual(view.filter, currentFilter.value))
      ?.id || ""
  );
});

const filteredTasks = computed(() => {
  return getTasksByFilter(currentFilter.value);
});

const searchedTasks = computed(() => {
  const isTitleVisible = !columnsStoredSettings.value.title.hidden;

  if (toolbarSearch.value && isTitleVisible) {
    const flexIndex = new Document({
      document: {
        id: "id",
        index: [
          {
            field: "title",
            charset: "latin:advanced",
            tokenize: "full",
          },
          {
            field: "deal:title",
            charset: "latin:advanced",
            tokenize: "full",
          },
        ],
      },
    });

    filteredTasks.value.forEach((task) => flexIndex.add(task));

    const foundSortedIds = flexSearchAllResultsArray(
      flexIndex,
      toolbarSearch.value,
    );

    return foundSortedIds.map((id) => tasksMap.value[id]);
  }

  return filteredTasks.value;
});

const hasActiveFilter = computed(
  () => !!Object.keys(currentFilter.value).length,
);

const noDisplayData = computed(() => {
  const data = {
    active: false,
    title: "",
    subtitle: "",
    btnClearSearch: false,
    btnClearFilters: false,
  };

  if (isLoading.value) {
    return data;
  }

  if (!tasks.value.length) {
    data.active = true;
    data.title = "You’re all done!";
    data.subtitle =
      "Seems like there are no deal tasks found or no deal tasks to work on";
    return data;
  }

  if (!searchedTasks.value.length) {
    data.active = true;

    if (hasActiveFilter.value) {
      data.title = "No deal tasks were found matching your criterias.";
      data.btnClearFilters = true;
      data.btnClearSearch = !!toolbarSearch.value;
    } else {
      data.title = `No results for "${toolbarSearch.value}".`;
      data.btnClearSearch = true;
    }
  }

  return data;
});

const selectView = (id: TaskView["id"]) => {
  const view = predefinedViews.find((view) => view.id === id);

  if (view) {
    currentFilter.value = cloneDeep(view.filter);
    currentSelectedView.value = view.id;
  }
};

/**
 * We assume as a rule that the value in "object" properties lies in the "id" field
 */
const getTasksByFilter = (
  viewFilter: TaskView["filter"],
): YourWorkDealTasksTableRow[] => {
  if (!Object.keys(viewFilter).length) {
    return tasks.value;
  }

  return tasks.value.filter((taskItem) => {
    return Object.entries(viewFilter).every(
      ([field, { op, value: filterFieldValue }]) => {
        let taskFieldValue: any =
          taskItem[field as keyof YourWorkDealTasksTableRow];

        if (taskFieldValue instanceof Date) {
          taskFieldValue = taskFieldValue.getTime();
        }

        if (
          taskFieldValue &&
          typeof taskFieldValue === "object" &&
          "id" in taskFieldValue
        ) {
          taskFieldValue = taskFieldValue.id;
        }

        if (Array.isArray(taskFieldValue)) {
          if (op === "in") {
            return !!intersection(taskFieldValue, filterFieldValue).length;
          }
          return (
            intersection(taskFieldValue, filterFieldValue).length ===
            filterFieldValue.length
          );
        } else {
          if (op === "bt") {
            if (
              typeof taskFieldValue === "number" &&
              typeof filterFieldValue[0] === "number" &&
              typeof filterFieldValue[1] === "number"
            ) {
              return (
                taskFieldValue >= filterFieldValue[0] &&
                taskFieldValue <= filterFieldValue[1]
              );
            }
            return false;
          }

          /** just for type correctness, there shouldn't be such a case */
          if (typeof taskFieldValue === "object") {
            return false;
          }

          return filterFieldValue.includes(taskFieldValue);
        }
      },
    );
  });
};

const handleToolbarSearchUpdate = (searchValue: string) => {
  toolbarSearch.value = searchValue;
};

const handleToolbarFilterUpdate = (
  payload: YourWorkDealTasksToolbarFiltersUpdate,
) => {
  if (!payload.value.length && currentFilter.value[payload.field]) {
    delete currentFilter.value[payload.field];
    return;
  }

  const parsedFieldValue = payload.value.map((valueItem) => {
    if (valueItem instanceof Date) {
      return valueItem.getTime();
    }
    return valueItem;
  });

  currentFilter.value[payload.field] = {
    op: mapYourWorkDealTasksFieldToMatchingFunction[payload.field] || "eq",
    value: parsedFieldValue,
  };
};

const handleColumnsReordering = (updatedColumns: SorterItem[]) => {
  updatedColumns.forEach((column) => {
    columnsStoredSettings.value[
      column.id as YourWorkDealTasksTableField
    ].order = column.order;
    columnsStoredSettings.value[
      column.id as YourWorkDealTasksTableField
    ].hidden = !column.visible;
  });
};
const handleColumnSortChanged = (
  params: VxeTableDefines.SortChangeEventParams,
) => {
  columnsStoredSettings.value[
    params.field as YourWorkDealTasksTableField
  ].sort = params.order || undefined;

  currentTableSortedField.value = params.field as YourWorkDealTasksTableField;
};

const handleColumnSizeChanged = (
  params: VxeTableDefines.ResizableChangeEventParams,
) => {
  columnsStoredSettings.value[
    params.column.field as YourWorkDealTasksTableField
  ].width = params.resizeWidth;
};

onBeforeMount(async () => {
  selectView(currentSelectedView.value);
  yourWorkDealTasks.load();
  insightTrack(DealTasksEvent.PageOpened);
});
</script>
