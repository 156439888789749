<template>
  <DrLayoutContentCentered>
    <DrLayoutContentCenteredSection
      :title="$t('room_updates.multi_room_updates')"
    >
      <template #title-right>
        <ElButton type="primary" @click="openCreatePanel">
          {{ $t("room_updates.new_update") }}
        </ElButton>
      </template>

      <ElAlert
        v-if="hasBatchesInProgress"
        :class="$style.alert"
        :title="$t('room_updates.batches_are_updated')"
        :closable="false"
        type="info"
        show-icon
      />

      <div :class="$style.loader">
        <BatchList :batches="batches" />
        <DrNewLoader v-if="isLoading" overlay />
      </div>
    </DrLayoutContentCenteredSection>
  </DrLayoutContentCentered>

  <CreateMultiRoomUpdatePanel
    ref="createMultiRoomUpdatePanelRef"
    @created="onBatchAction"
  />
  <ReviewUpdatesPanel ref="reviewUpdatesPanelRef" @applied="onBatchAction" />
  <ReviewAppliedUpdatesPanel ref="reviewAppliedUpdatesPanelRef" />
  <SeeUpdateLogsPanel ref="seeUpdateLogsPanelRef" />
  <SeeUpdateLogsByRoomPanel ref="seeUpdateLogsByRoomPanelRef" />
</template>

<script lang="ts" setup>
import { computed, onMounted, provide, ref, useTemplateRef } from "vue";
import {
  DrLayoutContentCentered,
  DrLayoutContentCenteredSection,
} from "@shared/ui/dr-layouts";
import { DrNewLoader } from "@shared/ui/dr-loader";
import { useIntervalFn } from "@vueuse/core";

import { $notifyDanger } from "@app/vue/common";
import {
  BATCH_STATE,
  MultiRoomUpdateService,
  type RoomUpdateBatch,
} from "./api";
import BatchList from "./BatchList.vue";
import CreateMultiRoomUpdatePanel from "./CreateMultiRoomUpdatePanel.vue";
import ReviewAppliedUpdatesPanel from "./ReviewAppliedUpdatesPanel.vue";
import ReviewUpdatesPanel from "./ReviewUpdatesPanel.vue";
import SeeUpdateLogsByRoomPanel from "./SeeUpdateLogsByRoomPanel.vue";
import SeeUpdateLogsPanel from "./SeeUpdateLogsPanel.vue";
import {
  reviewAppliedUpdatesRefKey,
  reviewUpdatesRefKey,
  seeUpdateLogsByRoomRefKey,
  seeUpdateLogsRefKey,
} from "./types";

const api = new MultiRoomUpdateService();

const createMultiRoomUpdatePanelRef = useTemplateRef(
  "createMultiRoomUpdatePanelRef",
);
const reviewUpdatesPanelRef = useTemplateRef("reviewUpdatesPanelRef");
const reviewAppliedUpdatesPanelRef = useTemplateRef(
  "reviewAppliedUpdatesPanelRef",
);
const seeUpdateLogsPanelRef = useTemplateRef("seeUpdateLogsPanelRef");
const seeUpdateLogsByRoomPanelRef = useTemplateRef(
  "seeUpdateLogsByRoomPanelRef",
);

provide(reviewUpdatesRefKey, reviewUpdatesPanelRef as any);
provide(reviewAppliedUpdatesRefKey, reviewAppliedUpdatesPanelRef as any);
provide(seeUpdateLogsRefKey, seeUpdateLogsPanelRef as any);
provide(seeUpdateLogsByRoomRefKey, seeUpdateLogsByRoomPanelRef as any);

const isLoading = ref(false);

const batches = ref<RoomUpdateBatch[]>([]);

const openCreatePanel = () => {
  createMultiRoomUpdatePanelRef.value?.open();
};

const onBatchAction = (callback: () => void) => {
  getBatches().then(callback);
};

const getBatches = () => {
  isLoading.value = true;
  return api
    .getBatches()
    .then(
      (b) => {
        batches.value = b;
        return b;
      },
      () => {
        $notifyDanger("room_updates.failed_to_load_batches");
      },
    )
    .finally(() => {
      isLoading.value = false;
    });
};

const hasBatchesInProgress = computed(() =>
  batches.value.some(
    (b) =>
      b.state === BATCH_STATE.COMPARING || b.state === BATCH_STATE.APPLYING,
  ),
);

useIntervalFn(() => {
  if (hasBatchesInProgress.value) getBatches();
}, 15000);

onMounted(getBatches);
</script>

<style module lang="scss">
.alert {
  margin-bottom: 20px;
}

.loader {
  position: relative;
}
</style>
