<template>
  <div :class="$style.container">
    <DrLoader v-if="categoriesStore.isLoading" overlay />

    <!-- @vue-expect-error -->
    <DrNavTree
      ref="navTreeRef"
      :title="t('shared.worklists')"
      :root-title="t('nav.all_worklists')"
      has-progress
      trigger-action-slot-by-contextmenu
      :add-tooltip="t('nav.create_worklist')"
      :tree="treeData"
      :current-id="currentCategoryId"
      :draggable="isAdmin"
      :disallow-add="!canCreateTasks"
      :no-data-text="t('nav.no_worklists')"
      :footer="treeFooter"
      @select="handleCategorySelect"
      @add="addCategory"
      @move="handleCategoryDragAndDrop"
      @click-footer="showArchive"
    >
      <template #item-action="{ item, setActive, ref: itemRef }">
        <DrTooltip :content="getItemTooltip((item as NavTreeItem).counter)">
          <ElDropdown
            trigger="click"
            @command="(cmd) => handleItemMenu(cmd, item.id, itemRef, setActive)"
            @visible-change="setActive(item.id, $event)"
          >
            <DrIcon name="ellipsis-h" size="sm" />
            <template #dropdown>
              <ElDropdownMenu>
                <ElDropdownItem
                  v-for="menu_item in itemMenu"
                  :key="menu_item.id"
                  :command="menu_item.id"
                  :divided="menu_item.divided"
                >
                  {{ menu_item.label }}
                </ElDropdownItem>
              </ElDropdownMenu>
            </template>
          </ElDropdown>
        </DrTooltip>
      </template>

      <template #root-action="{ setActive, ref: rootRef }">
        <ElDropdown
          trigger="click"
          @command="(cmd) => handleRootMenu(cmd, rootRef)"
          @visible-change="setActive($event)"
        >
          <DrIcon name="ellipsis-h" size="sm" />
          <template #dropdown>
            <ElDropdownMenu>
              <ElDropdownItem
                v-for="menu_item in rootMenu"
                :key="menu_item.id"
                :command="menu_item.id"
                :divided="menu_item.divided"
              >
                {{ menu_item.label }}
              </ElDropdownItem>
            </ElDropdownMenu>
          </template>
        </ElDropdown>
      </template>
    </DrNavTree>
  </div>

  <CategoryCreateOrRenameDialog ref="CategoryCreateOrRenameDialogRef" />
  <CategoryMoveDialog
    ref="CategoryMoveDialogRef"
    @submit="handleSubmitCategoryMoveDialog"
  />
</template>

<script setup lang="ts">
import { ElMessageBox } from "element-plus";
import { computed, ref } from "vue";
import { useI18n } from "vue-i18n";
import { DrIcon, type IconName } from "@shared/ui/dr-icon";
import { DrNewLoader as DrLoader } from "@shared/ui/dr-loader";
import { DrNavTree, type NavTreeItem } from "@shared/ui/dr-nav";
import { DrTooltip } from "@shared/ui/dr-tooltip";

import { ROOM_DATA } from "@setups/data";
import { tasksArchiveUrl } from "@setups/room-urls";
import {
  insightTrack,
  RoomTasksCategoriesEvent,
  RoomTasksNavTreeMenuEvent,
} from "@app/insight";
import { $notifySuccess, $notifyWarning } from "@drVue/common";
import { pinia } from "@drVue/store/pinia";
import { useCategoriesStore } from "@drVue/store/pinia/room/categories";
import { useTasksStore } from "@drVue/store/pinia/room/tasks";
import CategoryCreateOrRenameDialog from "./shared/CategoryCreateOrRenameDialog.vue";
import CategoryMoveDialog from "./shared/CategoryMoveDialog.vue";

import type { Category } from "@drVue/store/pinia/room/categories";
import type { NavTreeEmitMoveParams } from "@shared/ui/dr-nav";

interface Props {
  selectedCategoryId?: number | null;
  isArchive: boolean;
}

type MenuItem = {
  id: string;
  label: string;
  divided: boolean;
  shown: boolean;
};

type OpenPopupParams = {
  openedCallback?: () => void;
  closedCallback?: () => void;
};

const IS_ADMIN = ROOM_DATA.userPermissions.administrator;

export type NavCategoryParams = NavTreeItem & { order: number };

export type NavParams = {
  listId: NavCategoryParams["id"] | null;
};

const props = withDefaults(defineProps<Props>(), {
  selectedCategoryId: null,
});
const emit = defineEmits<{
  (event: "navigate", params: NavParams): void;
  (event: "create-request", categoryId?: number): void;
  (event: "create-finding", categoryUid?: Category["uid"]): void;
}>();

const { t } = useI18n();

const navTreeRef = ref<InstanceType<typeof DrNavTree>>();
const CategoryCreateOrRenameDialogRef =
  ref<InstanceType<typeof CategoryCreateOrRenameDialog>>();
const CategoryMoveDialogRef = ref<InstanceType<typeof CategoryMoveDialog>>();

const canCreateTasks = !!ROOM_DATA?.userPermissions?.canCreateTasks;
const canDeleteTasks = !!ROOM_DATA?.userPermissions?.canDeleteTasks;
const canCreateFinding = !!ROOM_DATA?.userPermissions?.isFindingsAccessible;
const isAdmin = !!ROOM_DATA?.userPermissions?.administrator;
const categoriesStore = useCategoriesStore(pinia);
const tasksStore = useTasksStore(pinia);

const treeData = computed<NavTreeItem<{ order: number }>[]>(
  () => categoriesStore.categoriesNavTree,
);

const treeFooter = computed(() => {
  if (!IS_ADMIN) return undefined;

  return {
    text: t("shared.archive"),
    icon: "trash" as IconName,
    isActive: props.isArchive,
  };
});

const currentCategoryId = computed<NavTreeItem["id"]>(() => {
  if (props.isArchive) return "";

  if (props.selectedCategoryId) return props.selectedCategoryId;
  return "root";
});

const handleCategorySelect = (itemId: NavTreeItem["id"]) => {
  const listId = itemId === "root" ? null : itemId;
  emit("navigate", { listId });
};

const addCategory = (
  reference: HTMLElement,
  parent?: Category,
  params?: OpenPopupParams,
) => {
  CategoryCreateOrRenameDialogRef.value?.show(
    reference,
    {
      type: "create",
      parentId: parent?.id,
    },
    params,
  );
};

const renameCategory = (
  reference: HTMLElement,
  { id, name }: Category,
  params?: OpenPopupParams,
) => {
  CategoryCreateOrRenameDialogRef.value?.show(
    reference,
    {
      type: "rename",
      id,
      name,
    },
    params,
  );
};

const showMoveCategoryDialog = (
  reference: HTMLElement,
  category: Category,
  params?: OpenPopupParams,
) => {
  CategoryMoveDialogRef.value?.show(reference, category, params);
};

const handleSubmitCategoryMoveDialog = (
  category: Category,
  newParent: Category,
) => {
  moveCategory(category, {
    parent_id: newParent.id,
    order: newParent.order + 1,
  });
};

const handleExportCategory = (category?: Category) => {
  tasksStore.exportCategory("xlsx", category).then(() => {
    insightTrack(RoomTasksCategoriesEvent.Exported, {
      format: "xlsx",
      id: category ? `${category.id}` : "",
      name: category ? category.name : "",
    });
  });
};

const moveCategory = (
  category: Category,
  params: {
    parent_id?: Category["parent_id"] | null;
    order: Category["order"];
  },
) => {
  const oldParent = category.parent?.name ?? "";
  const oldOrder = category.order;

  categoriesStore.moveCategory(category, params).then((updatedCategory) => {
    if (!updatedCategory) return;

    insightTrack(
      category.parent_id !== updatedCategory.parent_id
        ? RoomTasksCategoriesEvent.Moved
        : RoomTasksCategoriesEvent.Reordered,
      {
        id: `${updatedCategory.id}`,
        name: updatedCategory.name,
        parent: updatedCategory.parent?.name ?? "",
        order: `${updatedCategory.order}`,
        old_parent: oldParent,
        old_order: `${oldOrder}`,
      },
    );
    const newParentName = params.parent_id
      ? categoriesStore.categories[params.parent_id]?.name
      : "root";

    if (category.parent_id !== updatedCategory.parent_id) {
      $notifySuccess(
        t("nav.worklist_has_been_moved", {
          source_name: updatedCategory.name,
          dest_name: newParentName,
        }),
      );
    }
  });
};

const handleExpandCategory = (category?: Category) => {
  navTreeRef.value?.expandNode(category?.id);
};

const handleCollapseCategory = (category?: Category) => {
  navTreeRef.value?.collapseNode(category?.id);
};

const handleDeleteCategory = (category: Category) => {
  let removeCategoryFromCurrentNavChain = false;

  if (currentCategoryId.value === category.id) {
    removeCategoryFromCurrentNavChain = true;
  } else {
    const currentCatId = Number(currentCategoryId.value);
    if (!isNaN(currentCatId)) {
      removeCategoryFromCurrentNavChain = categoriesStore
        .getCategoryParents(currentCatId)
        .map((cat) => cat.id)
        .includes(category.id);
    }
  }

  ElMessageBox.confirm(
    t("nav.delete_worklist_confirmation"),
    t("nav.delete_worklist"),
    {
      customClass: "el-message-box--warning",
      confirmButtonText: t("shared.delete"),
    },
  )
    .then(() => {
      categoriesStore.archiveCategory(category).then(() => {
        insightTrack(RoomTasksCategoriesEvent.Deleted, {
          id: `${category.id}`,
          parent: category.parent?.name ?? "",
          name: category.name,
        });
      });

      if (removeCategoryFromCurrentNavChain) {
        emit("navigate", { listId: null });
      }
    })
    .catch(() => {});
};

const getRawCategoryData = (categoryId: NavTreeItem["id"]) => {
  return categoriesStore.categories![categoryId] || null;
};

const handleCategoryDragAndDrop = ({
  index,
  source,
  target,
}: NavTreeEmitMoveParams<{ order: number }>) => {
  if (!categoriesStore.categoriesList.length) return;

  const sourceCategory = getRawCategoryData(source.id);
  if (!sourceCategory) {
    $notifyWarning("Unexpected error: unable to get moved worklist data.");
    return;
  }

  // set "undefined" as new parent id to not change parent
  let newParentId: number | null | undefined = target
    ? Number(target.id)
    : undefined;
  const isSameParent = source.parent?.id === newParentId;
  const siblings = target ? target.children || [] : treeData.value;
  let newOrder = 0;

  if (isSameParent) {
    // just reordering
    newOrder = siblings[index].order;
  } else {
    // moving into category without sub categories
    // or to the top of parent category
    if (target && (!siblings.length || index === 0)) {
      newOrder = target.order + 1;
    } else {
      const targetItem = siblings[index];

      if (targetItem) {
        // moving in place of an existing element
        newOrder = targetItem.order;
        // set "null" as new parent id to move to the root
        newParentId = null;
      } else {
        // moving to the end
        let lowestPrevItem = siblings[siblings.length - 1];
        let lowestPrevOrder = lowestPrevItem.order;

        while (lowestPrevItem.children && lowestPrevItem.children.length) {
          lowestPrevItem =
            lowestPrevItem.children[lowestPrevItem.children.length - 1];
          lowestPrevOrder = lowestPrevItem.order;
        }

        newOrder = lowestPrevOrder + 1;
      }
    }
  }

  moveCategory(sourceCategory, {
    parent_id: newParentId,
    order: newOrder,
  });
};

const showArchive = () => {
  window.open(tasksArchiveUrl(ROOM_DATA.url), "_self");
};

const rootMenu = (() => {
  const menu: MenuItem[] = [
    {
      id: "add",
      label: t("nav.add_worklist"),
      divided: false,
      shown: canCreateTasks,
    },
    {
      id: "new",
      label: t("nav.new_request"),
      divided: false,
      shown: canCreateTasks,
    },
    {
      id: "new_finding",
      label: t("nav.new_finding"),
      divided: false,
      shown: canCreateFinding,
    },
    {
      id: "expand_all",
      label: t("nav.expand_all_worklists"),
      divided: canCreateTasks,
      shown: true,
    },
    {
      id: "collapse_all",
      label: t("nav.collapse_all_worklists"),
      divided: false,
      shown: true,
    },
    {
      id: "export",
      label: t("shared.export"),
      divided: true,
      shown: true,
    },
  ];

  return menu.filter((item) => item.shown);
})();

const handleRootMenu = (cmd: MenuItem["id"], ref?: HTMLElement) => {
  switch (cmd) {
    case "add":
      ref && addCategory(ref);
      break;
    case "new":
      emit("create-request");
      break;
    case "new_finding":
      emit("create-finding");
      break;
    case "export":
      handleExportCategory();
      break;
    case "expand_all":
      handleExpandCategory();
      break;
    case "collapse_all":
      handleCollapseCategory();
      break;
  }
};

const itemMenu = (() => {
  const menu: MenuItem[] = [
    {
      id: "add",
      label: t("nav.add_worklist"),
      divided: false,
      shown: canCreateTasks,
    },
    {
      id: "new_request",
      label: t("nav.new_request"),
      divided: false,
      shown: canCreateTasks,
    },
    {
      id: "new_finding",
      label: t("nav.new_finding"),
      divided: false,
      shown: canCreateFinding,
    },
    {
      id: "expand_all",
      label: t("nav.expand_all_worklists"),
      divided: canCreateTasks,
      shown: true,
    },
    {
      id: "collapse_all",
      label: t("nav.collapse_all_worklists"),
      divided: false,
      shown: true,
    },
    {
      id: "rename",
      label: t("shared.rename"),
      divided: true,
      shown: true,
    },
    {
      id: "export",
      label: t("shared.export"),
      divided: false,
      shown: true,
    },
    {
      id: "move",
      label: t("shared.move"),
      divided: true,
      shown: isAdmin,
    },
    {
      id: "delete",
      label: t("shared.delete"),
      divided: false,
      shown: canDeleteTasks,
    },
  ];

  return menu.filter((item) => item.shown);
})();

const handleItemMenu = (
  cmd: string,
  itemId: NavTreeItem["id"],
  ref: HTMLElement,
  setTreeItemActive: (id: NavTreeItem["id"], active: boolean) => void,
) => {
  const category = categoriesStore.categories![itemId];
  if (!category) {
    $notifyWarning("Unexpected error: unable to get worklist data.");
    return;
  }

  switch (cmd) {
    case "add": {
      insightTrack(RoomTasksNavTreeMenuEvent.NewCategory);
      addCategory(ref, category, {
        openedCallback: () => setTreeItemActive(itemId, true),
        closedCallback: () => setTreeItemActive(itemId, false),
      });
      break;
    }
    case "new_request": {
      insightTrack(RoomTasksNavTreeMenuEvent.NewRequest);
      emit("create-request", category.id);
      break;
    }
    case "new_finding": {
      insightTrack(RoomTasksNavTreeMenuEvent.NewFinding);
      emit("create-finding", category.uid);
      break;
    }
    case "rename": {
      insightTrack(RoomTasksNavTreeMenuEvent.Rename);
      renameCategory(ref, category, {
        openedCallback: () => setTreeItemActive(itemId, true),
        closedCallback: () => setTreeItemActive(itemId, false),
      });
      break;
    }
    case "move": {
      insightTrack(RoomTasksNavTreeMenuEvent.Move);
      showMoveCategoryDialog(ref, category, {
        openedCallback: () => setTreeItemActive(itemId, true),
        closedCallback: () => setTreeItemActive(itemId, false),
      });
      break;
    }
    case "delete": {
      insightTrack(RoomTasksNavTreeMenuEvent.Delete);
      handleDeleteCategory(category);
      break;
    }
    case "expand_all": {
      insightTrack(RoomTasksNavTreeMenuEvent.ExpandAll);
      handleExpandCategory(category);
      break;
    }
    case "collapse_all": {
      insightTrack(RoomTasksNavTreeMenuEvent.CollapseAll);
      handleCollapseCategory(category);
      break;
    }
  }

  if (cmd === "export") {
    if (tasksStore.canExportCategory(category)) {
      handleExportCategory(category);
    } else {
      $notifyWarning(t("nav.worklist_has_no_data_to_export"));
    }
  }
};

const getItemTooltip = (counter?: string): string => {
  if (!counter) return "";
  const [completed_count, total] = counter.split("/").map(parseFloat);
  return t("nav.worklist_requests_completed", { completed_count, total });
};
</script>

<style module lang="scss">
.container {
  height: 100%;
  display: flex;
  flex-direction: column;
}
</style>
