// Use this code as helper for generating imports.
// Don't forget to "npm init -y && npm i inflected glob"
//
// const fs = require("fs");
// const glob = require("glob");
// const inflected = require("inflected");
//
// const elRegex = /<el-([\w-]+)\s*/gi;
// const elements = new Map([
//   ["DatePicker", true],
//   ["MessageBox", true],
// ]);
//
// const paths = glob.sync("../frontend/app/**/*.vue");
// for (const filepath of paths) {
//   const content = fs.readFileSync(filepath, "utf-8");
//
//   const matches = content.matchAll(elRegex);
//   for (const match of matches) {
//     const elName = inflected.camelize(match[1].replace("-", "_"));
//     if (!elements.has(elName)) {
//       elements.set(elName, true);
//     }
//   }
// }
//
// const arr = Array.from(elements.keys());
//
// console.log(arr.length, "\n");
//
// console.log(
//   arr
//     .sort()
//     .map(
//       (el) =>
//         `import { El${el} } from "element-plus"`,
//     )
//     .join(";\n"),
//   "\n",
// );
//
// console.log(arr.map((el) => `app.use(El${el});`).join("\n"), "\n");

// Plugins
import {
  ElAlert,
  ElAutocomplete,
  ElButton,
  ElButtonGroup,
  ElCheckbox,
  ElCol,
  ElCollapse,
  ElCollapseItem,
  ElCollapseTransition,
  ElColorPicker,
  ElContainer,
  ElDatePicker,
  ElDialog,
  ElDivider,
  ElDrawer,
  ElDropdown,
  ElDropdownItem,
  ElDropdownMenu,
  ElForm,
  ElFormItem,
  ElIcon,
  ElImage,
  ElInput,
  ElInputNumber,
  ElLink,
  ElLoading,
  ElMessage,
  ElMessageBox,
  ElNotification,
  ElOption,
  ElOptionGroup,
  ElPopconfirm,
  ElPopover,
  ElPopoverDirective,
  ElProgress,
  ElRadio,
  ElRadioButton,
  ElRadioGroup,
  ElRow,
  ElScrollbar,
  ElSelect,
  ElSwitch,
  ElTabPane,
  ElTabs,
  ElTag,
  ElTooltip,
  ElTree,
  ElTreeSelect,
  makeInstaller,
} from "element-plus";
import enUS from "element-plus/es/locale/lang/en";
import frFR from "element-plus/es/locale/lang/fr";

import { getLangCookie } from "@app/vue/common";

import type { I18nLocale } from "./i18n";
import type { App } from "vue";

type ElementLocale = typeof enUS | typeof frFR;
const LOCALE_MAP: Record<I18nLocale, ElementLocale> = {
  "en-US": enUS,
  "fr-FR": frFR,
} as const;

export function installElementUi(app: App<Element>) {
  const installer = makeInstaller([
    // Components
    ElAlert,
    ElAutocomplete,
    ElButton,
    ElButtonGroup,
    ElCheckbox,
    ElCol,
    ElCollapse,
    ElCollapseItem,
    ElColorPicker,
    ElContainer,
    ElDatePicker,
    ElDialog,
    ElDivider,
    ElDrawer,
    ElDropdown,
    ElDropdownItem,
    ElDropdownMenu,
    ElForm,
    ElFormItem,
    ElIcon,
    ElImage,
    ElInput,
    ElInputNumber,
    ElLink,
    ElOption,
    ElOptionGroup,
    ElPopover,
    ElPopconfirm,
    ElProgress,
    ElRadio,
    ElRadioButton,
    ElRadioGroup,
    ElRow,
    ElScrollbar,
    ElSelect,
    ElSwitch,
    ElTabs,
    ElTabPane,
    ElTag,
    ElTooltip,
    ElTree,
    ElTreeSelect,
    ElCollapseTransition,

    // Plugins
    ElLoading,
    ElMessage,
    ElMessageBox,
    ElNotification,
    ElPopoverDirective,
  ]);

  const lang = getLangCookie();

  installer.install(app, {
    locale: lang ? LOCALE_MAP[lang] : enUS,
  });
}
