<template>
  <DrPopup ref="popupRef" paddingless @hide="handleHide" @show="handleShow">
    <template #default="{ hide }">
      <DrPanelWrapper :width="354">
        <DrDynamicFormClassic
          ref="form"
          :entity="model"
          :schema="nameSchema"
          :errors="formErrors"
          :disabled="isFormSubmitting"
          @update="handleUpdateForm"
          @submit="submit"
        />

        <div :class="$style.footer">
          <ElButton size="small" @click="hide">{{
            t("shared.cancel")
          }}</ElButton>
          <ElButton
            size="small"
            type="primary"
            :disabled="isSubmitDisabled"
            :loading="isFormSubmitting"
            @click="submit"
          >
            {{ labels.submitText }}
          </ElButton>
        </div>
      </DrPanelWrapper>
    </template>
  </DrPopup>
</template>

<script setup lang="ts">
import { computed, ref } from "vue";
import { useI18n } from "vue-i18n";

import { ROOM_DATA } from "@setups/data";
import { insightTrack, RoomTasksCategoriesEvent } from "@app/insight";
import { $notifySuccess } from "@drVue/common";
import { FieldSchemaType } from "@drVue/components/client-dashboard/dynamic-form/types";
import { DrDynamicFormClassic } from "@drVue/shared/ui/dr-dynamic-form";
import { validateString } from "@drVue/shared/ui/dr-dynamic-form/utils";
import { useFormHelper } from "@drVue/shared/ui/dr-form";
import { DrPanelWrapper } from "@drVue/shared/ui/dr-panels";
import { DrPopup } from "@drVue/shared/ui/dr-popups";
import { pinia } from "@drVue/store/pinia";
import { useCategoriesStore } from "@drVue/store/pinia/room/categories";

import type { Category } from "@drVue/store/pinia/room/categories";
import type { FormSchema } from "@shared/ui/dr-dynamic-form/types";

type OpenPopupPayload =
  | {
      type: "create";
      parentId?: Category["parent_id"];
      id?: undefined;
      name?: undefined;
    }
  | {
      type: "rename";
      id: Category["id"];
      name: Category["name"];
      parentId?: undefined;
    };

type OpenPopupParams = {
  openedCallback?: () => void;
  closedCallback?: () => void;
};

interface Emits {
  (event: "added", category: Category): void;
  (event: "renamed", category: Category, oldName: string): void;
  (event: "hide"): void;
}

const emit = defineEmits<Emits>();

const { t } = useI18n();

const categoriesStore = useCategoriesStore(pinia);

const { formErrors, hookFormSubmitPromise, isFormSubmitting, resetError } =
  useFormHelper<{ name: string; key?: string }>();

const popupRef = ref<InstanceType<typeof DrPopup>>();
const form = ref<InstanceType<typeof DrDynamicFormClassic> | undefined>();

const actionType = ref<OpenPopupPayload["type"]>("create");
const initialModelValue = ref("");
const modelValue = ref("");
const modelValueKey = ref("");
const categoryId = ref<Category["id"] | undefined>();
const parentId = ref<Category["parent_id"] | undefined>();
const hideModalCallback = ref<() => void | undefined>();
const showModalCallback = ref<() => void | undefined>();

const keyFieldRegex = new RegExp("^[A-Z]{2,6}$");

const haveToEnterKey = computed(() => {
  return (
    actionType.value === "create" &&
    ROOM_DATA.enableTaskListKey &&
    !parentId.value
  );
});

const model = computed(() => ({
  name: modelValue.value,
  key: modelValueKey.value,
}));

const isSubmitDisabled = computed(() => {
  const name = modelValue.value.trim();
  const key = modelValueKey.value.trim();

  let disable =
    !name.length ||
    name.length > 64 ||
    name === initialModelValue.value ||
    !!formErrors.value.name;

  if (haveToEnterKey.value) {
    disable = disable || !keyFieldRegex.test(key) || !!formErrors.value.key;
  }

  return disable;
});

const labels = computed(() => {
  return {
    title: t("shared.name"),
    placeholder: t("nav.worklist_name"),
    submitText:
      actionType.value === "create" ? t("shared.create") : t("shared.rename"),
  };
});

const nameSchema = computed(() => {
  const schema: FormSchema = [
    {
      type: FieldSchemaType.Text,
      isReadOnly: isFormSubmitting.value,
      prop: "name",
      label: labels.value.title,
      placeholder: labels.value.placeholder,
      rules: validateString()
        .required(t("form.is_required", { field: "name" }))
        .max(64, t("form.max_length", { max: 64 })),
      extra: {
        autofocus: 200,
      },
    },
  ];

  if (haveToEnterKey.value) {
    schema.push({
      type: FieldSchemaType.Text,
      isReadOnly: isFormSubmitting.value,
      prop: "key",
      label: "Key",
      placeholder: "Requests list key",
      rules: validateString()
        .required(t("form.is_required", { field: "key" }))
        .matches(keyFieldRegex, t("form.request_key")),
    });
  }

  return schema;
});

const handleUpdateForm = ({
  field,
  value,
}: {
  field: string;
  value: string;
}) => {
  resetError(field);
  if (field === "name") modelValue.value = value;
  if (field === "key") modelValueKey.value = value;
};

const submit = async () => {
  if (isFormSubmitting.value) return;

  const result = await form.value?.validate().catch((error) => error);

  if (result !== true) return;

  let promise: Promise<void>;

  if (actionType.value === "create") {
    const payload = {
      name: modelValue.value.trim(),
      key: modelValueKey.value.trim() || undefined,
      parent_id: parentId.value,
    };
    promise = categoriesStore.createCategory(payload).then((addedCategory) => {
      emit("added", addedCategory);
      $notifySuccess(t("nav.worklist_has_been_created"));
      insightTrack(RoomTasksCategoriesEvent.Added, {
        id: `${addedCategory.id}`,
        name: addedCategory.name,
        parent: addedCategory.parent?.name ?? "",
        order: `${addedCategory.order}`,
      });
    });
  } else {
    promise = categoriesStore
      .editCategory(categoryId.value!, {
        name: modelValue.value,
      })
      .then((updatedCategory) => {
        emit("renamed", updatedCategory, initialModelValue.value);
        $notifySuccess(
          t("nav.worklist_has_been_renamed", {
            old_name: initialModelValue.value,
            new_name: updatedCategory.name,
          }),
        );
        insightTrack(RoomTasksCategoriesEvent.Renamed, {
          id: `${updatedCategory.id}`,
          name: updatedCategory.name,
          old_name: initialModelValue.value,
        });
      });
  }

  hookFormSubmitPromise(
    promise,
    actionType.value === "create"
      ? t("nav.failed_to_create_worklist")
      : t("nav.failed_to_rename_worklist"),
  ).then(() => popupRef.value?.hide());
};

const show = (
  reference: HTMLElement,
  payload: OpenPopupPayload,
  params?: OpenPopupParams,
) => {
  actionType.value = payload.type;
  initialModelValue.value = payload.name ?? "";
  modelValue.value = payload.name ?? "";
  modelValueKey.value = "";
  categoryId.value = payload.id;
  parentId.value = payload.parentId;
  popupRef.value?.show(reference);
  hideModalCallback.value = params?.closedCallback;
  showModalCallback.value = params?.openedCallback;
};

const handleHide = () => {
  emit("hide");
  hideModalCallback.value?.();
};

const handleShow = () => {
  showModalCallback.value?.();
};

defineExpose({ show });
</script>

<style lang="scss" module>
.footer {
  display: flex;
  justify-content: flex-end;
  margin-top: 14px;

  :global(.el-button + .el-button) {
    margin-left: 8px;
  }
}
</style>
