import { format, parseISO } from "date-fns";
import { toDate, toZonedTime } from "date-fns-tz";
import { isArrayLikeObject, isPlainObject, keyBy } from "lodash-es";

import { USER_DATA } from "@setups/data";
import { FieldItemTypes } from "./client-dashboard/enums";

import type { FieldItem } from "./client-dashboard/types";
import type { CustomDataType } from "@drVue/store/modules/client-dashboard/fields/types";

export enum DateType {
  Date = "Date",
  DateTime = "DateTime",
}

const DATE_TYPE: { [key: string]: DateType } = {
  date_resolved: DateType.DateTime,
  date_completed: DateType.DateTime,
  actual_start_date: DateType.Date,
  bookmarked: DateType.DateTime,
  date: DateType.Date,
  date_added: DateType.DateTime,
  date_created: DateType.DateTime,
  date_modified: DateType.DateTime,
  date_synced: DateType.DateTime,
  date_updated: DateType.DateTime,
  due_date: DateType.Date,
  due_datetime: DateType.DateTime,
  last_updated: DateType.DateTime,
  last_view_date: DateType.DateTime,
  last_visit_date: DateType.DateTime,
  planned_start_date: DateType.Date,
  start_date: DateType.Date,
  timestamp: DateType.DateTime,
  trial_end_date: DateType.Date,
  created_at: DateType.DateTime,
  reviewed_at: DateType.DateTime,
  finalized_at: DateType.DateTime,
};

const DATE_FORMAT = "yyyy-MM-dd";

export function deserializeDrDate(parseAs: DateType, date: Date | string) {
  if (date === undefined || date === null) return date;
  if (date === "") throw new Error("Invalid string.");

  const tz = USER_DATA.profile.timezone;
  if (typeof date === "string") {
    switch (parseAs) {
      case DateType.Date:
        // 2020-01-01 will turn to 2020-01-01T00:00:00PTZ
        return toDate(date, { timeZone: tz });
      case DateType.DateTime:
        return new Date(date);
      default:
        throw "Unknown DateType";
    }
  }

  return date;
}

export function serializeDrDate(parseAs: DateType, date: Date | string) {
  if (date === undefined || date === null) return date;
  if (date === "") throw new Error("Invalid string.");
  if (typeof date === "string") {
    date = parseISO(date);

    console.warn("A string was passed to serializeDrDate method.");
  }

  if (date instanceof Date) {
    switch (parseAs) {
      case DateType.Date:
        return format(
          toZonedTime(date, USER_DATA.profile.timezone),
          DATE_FORMAT,
        );
      case DateType.DateTime:
        return date.toISOString();
    }
  }

  return date;
}

function recursively(
  fn: (dateType: DateType, date: Date | string) => Date | string,
  data: any,
) {
  if (isArrayLikeObject(data)) {
    for (let i = 0; i < data.length; i++) {
      recursively(fn, data[i]);
    }
  } else if (isPlainObject(data)) {
    for (const key of Object.keys(data)) {
      const value = data[key];

      if (isArrayLikeObject(value) || isPlainObject(value)) {
        recursively(fn, value);
      } else if (key in DATE_TYPE) {
        data[key] = fn(DATE_TYPE[key], value);
      }
    }
  }
}

export function deserializeDates(data: any) {
  recursively(deserializeDrDate, data);
}

export function serializeDates(data: any) {
  recursively(serializeDrDate, data);
}

export function serializeCustomData(data: CustomDataType, fields: FieldItem[]) {
  if (!isPlainObject(data)) return data;

  const config = keyBy(fields, "key");

  for (const key of Object.keys(data)) {
    if (config[key]?.field_type === FieldItemTypes.Date) {
      data[key] = serializeDrDate(DateType.Date, data[key]);
    }
    // fix the problem of the browser cutting out "undefined" values
    // when clearing a field in ui
    if (config[key]?.field_type === FieldItemTypes.Select) {
      if (data[key] === undefined) data[key] = null;
    }
  }

  return data;
}

export function deserializeCustomData(
  data: CustomDataType,
  fields: FieldItem[],
) {
  if (!isPlainObject(data)) return data;

  const config = keyBy(fields, "key");

  for (const key of Object.keys(data)) {
    if (config[key]?.field_type === FieldItemTypes.Date) {
      data[key] = deserializeDrDate(DateType.Date, data[key]);
    }
  }

  return data;
}
