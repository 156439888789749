<template>
  <ElTooltip
    ref="tooltipRef"
    v-model:visible="isPopoverVisible"
    placement="bottom"
    effect="light"
    trigger="click"
    transition="no-transition"
    :persistent="false"
    :show-arrow="false"
    :popper-class="$style.popover"
    :teleported="false"
    width="100%"
  >
    <ElInput
      ref="inputRef"
      :placeholder="placeholder"
      :model-value="inputText"
      @input="onInput"
      @click="onClick"
      @focus="onFocus"
      @blur="onBlur"
    >
      <template #prefix v-if="model">
        <DrIcon name="list" />
      </template>
    </ElInput>

    <template #content>
      <div :class="$style.tree" data-tree-container>
        <DrTree
          :model-value="model"
          :filter-text="filterText"
          :data="tree"
          :default-expanded-keys="selectedPathIds"
          check-strictly
          check-on-click-node
          check-sign-on-right
          @update:model-value="updateModelValue"
          @reset-filter="onResetFilter"
        >
          <template #item="{ item }">
            <span
              v-if="item.code"
              :class="$style.code"
              :style="{
                color: item.color,
              }"
              >#{{ item.code }}</span
            >
            <span>
              {{ item.name }}
            </span>
          </template>
        </DrTree>
      </div>
    </template>
  </ElTooltip>
</template>

<script setup lang="ts">
import { difference } from "lodash-es";
import { computed, nextTick, onMounted, ref } from "vue";
import { useI18n } from "vue-i18n";
import { DrTree } from "@shared/ui/dr-tree";

import { useTasksTreeData } from "@app/vue/components/room/tasks/use/useTasksTreeData";
import { pinia } from "@app/vue/store/pinia";
import {
  type Category,
  useCategoriesStore,
} from "@app/vue/store/pinia/room/categories";
import { useTasksStore } from "@app/vue/store/pinia/room/tasks";
import DrIcon from "@drVue/shared/ui/dr-icon";

import type { ElInput, ElTooltip } from "element-plus";

const model = defineModel<(string | number)[]>({
  required: true,
  default: () => [],
});

const { t } = useI18n()

const tooltipRef = ref<InstanceType<typeof ElTooltip> | null>(null);
const inputRef = ref<InstanceType<typeof ElInput> | null>(null);

const inputText = ref<string>("");
const filterText = ref<string>("");
const isPopoverVisible = ref(false);

const tasksStore = useTasksStore(pinia);
const categoriesStore = useCategoriesStore(pinia);

const selectedRequest = computed(() => {
  if (!model.value || model.value.length === 0) return undefined;

  return tasksStore.tasks[model.value[0]];
});
const selectedRequestName = computed(() => selectedRequest.value?.title ?? "");

const selectedPathIds = computed(() => {
  const res: number[] = [];

  const taskCatId = selectedRequest.value?.category_id;
  if (!taskCatId) return res;

  let cursor: Category | undefined = categoriesStore.categories[taskCatId];
  while (cursor) {
    res.push(cursor.id);
    cursor = cursor.parent_id
      ? categoriesStore.categories[cursor.parent_id]
      : undefined;
  }

  return res;
});

const placeholder = computed(
  () => selectedRequestName.value || t("shared.type_to_search"),
);

const updateModelValue = (ids: (string | number)[]) => {
  isPopoverVisible.value = false;

  if (ids.length === 0) {
    model.value = [];
  } else if (ids.length === 1) {
    model.value = ids;
  } else {
    model.value = difference(ids, model.value);
  }

  nextTick(() => {
    inputText.value = selectedRequestName.value;
  });

  setTimeout(() => {
    filterText.value = "";
  }, 300);
};

const tree = useTasksTreeData({
  disableCategories: true,
});

const onInput = (e: string) => {
  inputText.value = e;
  filterText.value = e;

  isPopoverVisible.value = true;
};

const onFocus = (e: FocusEvent) => {
  // We are in `focus`, `relatedTarget` is the element that is losing focus.
  const relatedTarget = e.relatedTarget as HTMLElement;
  if (relatedTarget && !relatedTarget.closest("[data-tree-container]")) {
    inputText.value = "";
    filterText.value = "";
  }
};

const onClick = () => {
  inputText.value = "";
  filterText.value = "";
};

const onBlur = (e: FocusEvent) => {
  // We are in `blur`, `relatedTarget` is the element that is gaining focus.
  const relatedTarget = e.relatedTarget as HTMLElement;
  if (!relatedTarget || !relatedTarget.closest("[data-tree-container]")) {
    if (!model.value) {
      inputText.value = "";
    } else {
      inputText.value = selectedRequestName.value;
    }

    isPopoverVisible.value = false;
  }
};

const onResetFilter = () => {
  filterText.value = "";
  inputText.value = "";
  inputRef.value?.focus();
};

onMounted(() => {
  filterText.value = selectedRequestName.value;
  inputText.value = selectedRequestName.value;
});
</script>

<style lang="scss" module>
.popover {
  width: 100%;
  padding: 0;
  overflow: hidden;
}

.tree {
  max-height: 300px;
  overflow-y: auto;
}

.code {
  margin-right: 5px;
}
</style>
