<template>
  <EditFieldContainer>
    <slot name="default" />
  </EditFieldContainer>
  <div :class="$style.actions">
    <ElButton size="small" @click.stop="$emit('discard')">
      {{ t("shared.discard") }}
    </ElButton>
    <ElButton
      :loading="isFormSubmitting"
      size="small"
      type="primary"
      @click.stop="$emit('save')"
    >
      {{ t("shared.save") }}
    </ElButton>
  </div>
</template>

<script setup lang="ts">
import { ElButton } from "element-plus";
import { useI18n } from "vue-i18n";

import EditFieldContainer from "./EditFieldContainer.vue";

interface Props {
  isFormSubmitting: boolean;
}

interface Emits {
  (e: "save"): void;
  (e: "discard"): void;
}

defineEmits<Emits>();

defineProps<Props>();

const { t } = useI18n();
</script>

<style lang="scss" module>
.actions {
  display: flex !important;
  justify-content: flex-end;
  width: 100%;
  padding: 6px 6px 6px 0;
}
</style>
