<template>
  <ElTooltip
    :raw-content="rawContent"
    :content="content"
    :disabled="disabled || !content || hideTooltip"
    :placement="placement"
    :show-after="showAfter"
    :persistent="false"
    popper-class="dr-tooltip dont-break-out"
  >
    <slot />

    <template #content><slot name="content" /></template>
  </ElTooltip>
</template>

<script lang="ts">
import { ElTooltip } from "element-plus";
import { defineComponent } from "vue";

import type { PropType } from "vue";

interface Data {
  hideTooltip: boolean;
}

export default defineComponent({
  name: "DrTooltip",
  components: {
    ElTooltip,
  },
  props: {
    rawContent: {
      required: false,
      type: Boolean as PropType<boolean>,
      default: false,
    },
    content: { required: false, type: String as PropType<string>, default: "" },
    disabled: {
      required: false,
      default: null,
      type: Boolean as PropType<boolean | null>,
    },
    placement: {
      required: false,
      default: "top-start",
      type: String as PropType<InstanceType<typeof ElTooltip>["placement"]>,
    },
    showAfter: {
      required: false,
      default: 200,
      type: Number as PropType<number>,
    },
  },
  data(): Data {
    return {
      hideTooltip: false,
    };
  },
  watch: {
    content() {
      this.$nextTick(this.updateHideTooltip);
    },
  },
  mounted() {
    this.updateHideTooltip();
  },
  methods: {
    updateHideTooltip() {
      const el = this.$el as HTMLElement;
      this.hideTooltip = el.offsetWidth >= el.scrollWidth;
    },
  },
});
</script>

<style lang="scss">
.dr-tooltip {
  border-radius: 2px;
  font-size: 11px;
  max-width: 400px;
  white-space: normal;
}
</style>
