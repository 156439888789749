RoomDocumentsSearchListController.$inject = [
  "$scope",
  "$stateParams",
  "$rootScope",
  "RoomConfig",
  "DocumentsService",
  "TasksService",
  "CategoriesService",
  "FileUploadService",
];

export default function RoomDocumentsSearchListController(
  $scope,
  $stateParams,
  $rootScope,
  RoomConfig,
  DocumentsService,
  TasksService,
  CategoriesService,
  FileUploadService,
) {
  var self = this;
  var docsCtrl = $scope.docsCtrl;

  docsCtrl.recent = false;
  docsCtrl.bookmarks = false;
  docsCtrl.archive = false;
  docsCtrl.isAiReports = true;
}
