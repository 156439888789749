TaskListController.$inject = [
  "$scope",
  "$state",
  "$stateParams",
  "RoomConfig",
  "CategoriesService",
  "previousState",
  "resolvedWhenTasksAndCategoriesLoaded",
];

export default function TaskListController(
  $scope,
  $state,
  $stateParams,
  RoomConfig,
  CategoriesService,
  previousState,
) {
  // open child state if it is not specified
  if ($state.current.name === "tasks.list") {
    // task.list is kinda abstract state, so go to child
    // if user was in progress view, keep it open
    var goToPrevious = previousState === "tasks.list.timeline";
    var nextState = goToPrevious ? previousState : "tasks.list.overview";
    $state.go(nextState, $stateParams);
  }

  if (RoomConfig.enableListsTabs && !$stateParams.listId) {
    $state.go($state.current.name, {
      listId: CategoriesService.rootCategories[0]?.id,
    });
    return;
  }
}
