<template>
  <DrLayoutPage page-title="Pipeline" page-icon="layer-group">
    <template #root>
      <DetailModal @create-room="triggerCreateRoom" @closed="detailsClosed" />
      <DealsImportModal ref="dealsImportModal" />
      <CreateRoom ref="createRoomRef" />
      <CreateDeal ref="createDealRef" />
    </template>

    <template #nav>
      <DealsViewNav :views="views" />
      <div :class="$style.divider" />
      <AllDealsViewsPopover />
    </template>

    <template #nav-right>
      <SaveView />
    </template>

    <DrLayoutContent :show-aside="isAsideOpened">
      <template #toolbar>
        <DealsFilterBtn
          :is-expanded="isAsideOpened"
          @expand="isAsideOpened = !isAsideOpened"
        />
      </template>

      <template #toolbar-right>
        <ElDropdown trigger="click" @command="handleAction">
          <ElButton
            :class="$style.iconBtn"
            data-testid="deals-export-import-menu-button"
          >
            <DrIcon name="ellipsis-h" size="lg" :class="$style.icon" />
          </ElButton>
          <template #dropdown>
            <ElDropdownMenu>
              <ElDropdownItem command="exportToExcelAll">
                Export all deals to Excel
              </ElDropdownItem>
              <ElDropdownItem command="exportToExceFiltered">
                Export filtered deals to Excel
              </ElDropdownItem>
              <ElDropdownItem v-if="canImportDeals" command="importDeals">
                Import deals from Excel
              </ElDropdownItem>
            </ElDropdownMenu>
          </template>
        </ElDropdown>

        <CurrentDealsViewOptions />

        <ElButton
          v-if="newDealButtonVisibility"
          type="primary"
          data-testid="new-deal"
          @click="triggerCreateDeal"
        >
          New deal
        </ElButton>
      </template>

      <template #aside>
        <DealsFilterForm
          @hide="isAsideOpened = false"
          data-testid="board-filter-toolbar-right"
      /></template>

      <Component :is="viewComponent" />
    </DrLayoutContent>
  </DrLayoutPage>
</template>

<script lang="ts">
import { ElMessageBox } from "element-plus";
import { defineComponent } from "vue";
import DrIcon from "@shared/ui/dr-icon";
import { DrLayoutContent, DrLayoutPage } from "@shared/ui/dr-layouts";
import SearchInput from "@shared/ui/search-input";

import { ORG_MEMBER_DATA } from "@setups/data";
import { OrganizationGroupDealAccessType } from "@setups/enums";
import { CustomViewLayouts, CustomViewObjectTypes } from "@setups/types";
import CreateDeal from "@drVue/components/client-dashboard/deals/CreateDeal/CreateDeal.vue";
import CreateRoom from "@drVue/components/client-dashboard/deals/CreateRoom/CreateRoom.vue";
import GroupsToCopy from "@drVue/components/client-dashboard/deals/GroupsToCopy/GroupsToCopy.vue";
import DrStore from "@drVue/store";
import { DealsApiService } from "@drVue/store/modules/client-dashboard/deals/DealsApiService";
import { exportDealsToExcel } from "@drVue/store/modules/client-dashboard/deals/reporting";
import DealsBoard from "./DealsBoard";
import DealsImportModal from "./DealsImportModal/DealsImportModal.vue";
import DealsVxeTable from "./DealsTable";
import DealsTimeline from "./DealsTimeline";
import AllDealsViewsPopover from "./DealsViews/AllDealsViewsPopover.vue";
import CurrentDealsViewOptions from "./DealsViews/CurrentDealsViewOptions.vue";
import DealsViewNav from "./DealsViews/DealsViewNav.vue";
import SaveView from "./DealsViews/SaveView.vue";
import DetailModal from "./DetailModal/DetailModal.vue";
import DealsFilterBtn from "./Filter/FilterBtn.vue";
import DealsFilterForm from "./Filter/Form.vue";

import type {
  Deal,
  DealDialogState,
} from "@drVue/store/modules/client-dashboard/deals/types";
import type { CustomView } from "@setups/types";

let lastActiveUnpinned: string | null = null;

function handleViewChange(
  params: {
    viewSlug?: string;
    dealUid?: string;
  },
  callback: any,
) {
  const { viewSlug } = params;
  if (viewSlug === "details") {
    return callback();
  }

  /** case: return to current view from DetailModal */
  const currentSlug = DrStore.state.common.customViews.current.slug;
  if (currentSlug === viewSlug) {
    return callback();
  }

  const view = DrStore.getters["common/customViews/bySlug"](viewSlug);
  if (view) {
    DrStore.commit("common/customViews/setCurrent", view);
    const pinnedViews =
      DrStore.state.clientDashboard.preferences.pinned_deals_views;
    if (!pinnedViews.includes(view.slug)) {
      lastActiveUnpinned = view.slug;
    }
    return callback();
  }

  const defaultView = DrStore.getters["common/customViews/defaultView"](
    CustomViewObjectTypes.Deal,
  );

  return callback(`/deals/${defaultView.slug}`);
}

const LAYOUT_VIEW_COMPONENT = {
  [CustomViewLayouts.Table]: DealsVxeTable,
  [CustomViewLayouts.Timeline]: DealsTimeline,
  [CustomViewLayouts.Board]: DealsBoard,
};

interface Data {
  showDealsImport: boolean;
  canImportDeals: boolean;
  canCreateDeals: boolean;
  canCreateRooms: any;
  dealsAccessType: OrganizationGroupDealAccessType;
  bySlug: (v: string) => CustomView | undefined;
  isAsideOpened: boolean;
  dealsApiService: DealsApiService;
}

export default defineComponent({
  name: "Deals",
  components: {
    CreateDeal,
    CreateRoom,
    DrLayoutPage,
    DrLayoutContent,
    GroupsToCopyControl: GroupsToCopy,
    DealsFilterBtn,
    DealsFilterForm,
    SearchInput,
    DetailModal,
    CurrentDealsViewOptions,
    AllDealsViewsPopover,
    DealsViewNav,
    SaveView,
    DealsImportModal,
    DrIcon,
  },
  beforeRouteEnter(to, _from, next): void {
    handleViewChange(to.params, next);
  },
  beforeRouteUpdate(to, _from, next): void {
    handleViewChange(to.params, next);
  },
  data(): Data {
    return {
      showDealsImport: false,
      canImportDeals: ORG_MEMBER_DATA.group.is_administrator,
      canCreateRooms: ORG_MEMBER_DATA.group.can_create_rooms,
      canCreateDeals: ORG_MEMBER_DATA.group.can_create_deals,
      dealsAccessType: ORG_MEMBER_DATA.group.deals_access_type,
      bySlug: this.$store.getters["common/customViews/bySlug"],
      isAsideOpened: false,
      dealsApiService: new DealsApiService(),
    };
  },
  computed: {
    current(): CustomView {
      return this.$store.state.common.customViews.current;
    },
    items(): CustomView[] {
      return this.$store.state.common.customViews.items;
    },
    viewComponent(): any {
      return LAYOUT_VIEW_COMPONENT[this.current.layout];
    },
    views(): CustomView[] {
      const pinnedDealsViews: string[] =
        this.$store.state.clientDashboard.preferences.pinned_deals_views;
      const views = pinnedDealsViews
        .map((slug) => this.bySlug(slug) as CustomView)
        .filter((v) => !!v);

      if (pinnedDealsViews.includes(this.current.slug)) {
        if (
          lastActiveUnpinned &&
          !pinnedDealsViews.includes(lastActiveUnpinned)
        ) {
          const lastUnpinnedView = this.bySlug(
            lastActiveUnpinned,
          ) as CustomView;
          if (lastUnpinnedView) {
            views.push(lastUnpinnedView);
          }
        }
      } else {
        views.push(this.current);
      }
      return views;
    },
    isDealsLoading(): any {
      return this.$store.state.clientDashboard.deals.isLoading;
    },
    dealsItems(): Deal[] {
      return this.$store.state.clientDashboard.deals.items;
    },
    newDealButtonVisibility() {
      const canCreateDealWithRoom = this.canCreateDeals && this.canCreateRooms;
      const isForcedToCreateRoom =
        this.dealsAccessType === OrganizationGroupDealAccessType.RoomAdmin;
      return (
        (!isForcedToCreateRoom && this.canCreateDeals) ||
        (isForcedToCreateRoom && canCreateDealWithRoom)
      );
    },
    readyShowDeatilsModal() {
      return (
        this.$route.params.viewSlug === "details" && !!this.dealsItems.length
      );
    },
  },
  watch: {
    items() {
      handleViewChange({ viewSlug: this.current.slug }, (newUrl?: string) => {
        if (newUrl) {
          this.$router.push(newUrl);
        }
      });
    },
    isDealsLoading(isDealsLoading: boolean) {
      if (isDealsLoading) return;

      const dealsCount = this.$store.state.clientDashboard.deals.items.length;
      if (dealsCount === 0 && this.canCreateRooms) {
        this.triggerCreateDeal();
      }
    },
    readyShowDeatilsModal: {
      async handler(isReady: boolean) {
        if (isReady) {
          const dealUid = this.$route.params.dealUid as string;
          const deal =
            DrStore.getters["clientDashboard/deals/getDealByUid"](dealUid);

          if (deal) {
            const payload: Partial<DealDialogState> = {
              visible: true,
              dealUid,
            };

            if (this.$route.query.tab) {
              const tab = this.$route.query.tab;
              if (typeof tab === "string") {
                payload.tab = tab;
              } else if (Array.isArray(tab) && typeof tab[0] === "string") {
                payload.tab = tab[0];
              }
            }

            DrStore.commit("clientDashboard/deals/detailsDialog", payload);
          } else {
            await ElMessageBox.alert(
              "The URL value is incorrect or you do not have access rights to this deal.",
              "It is impossible to open deal details.",
              {
                confirmButtonText: "Ok",
                showClose: false,
                // @ts-expect-error: ...
                customClass: this.$style.warnMessageBox,
              },
            );

            const slug = this.$store.state.common.customViews.current.slug;
            this.$router.replace(`/deals/${slug}/`);
          }
        }
      },
      immediate: true,
    },
  },
  methods: {
    exportToExcel(filtered: boolean) {
      let deals = this.$store.state.clientDashboard.deals.items;
      if (filtered) {
        deals =
          this.$store.getters["clientDashboard/filters/filterDeals"](deals);
      }
      const params = {
        deals,
        viewSettings:
          this.current.layout == CustomViewLayouts.Table
            ? this.current.settings
            : undefined,
      };
      exportDealsToExcel(this.$store, params);
    },
    importDeals() {
      (
        this.$refs.dealsImportModal as InstanceType<typeof DealsImportModal>
      ).open();
    },
    triggerCreateRoom(deal: Deal) {
      (this.$refs.createRoomRef as InstanceType<typeof CreateRoom>).open(deal);
    },
    triggerCreateDeal() {
      (this.$refs.createDealRef as InstanceType<typeof CreateDeal>).open();
    },
    handleAction(action: string): void {
      switch (action) {
        case "exportToExcelAll":
          this.exportToExcel(false);
          break;
        case "exportToExceFiltered":
          this.exportToExcel(true);
          break;
        case "importDeals":
          this.importDeals();
      }
    },
    detailsClosed() {
      DrStore.commit("clientDashboard/deals/detailsDialog", {
        visible: false,
        dealUid: undefined,
      });

      const slug = this.$store.state.common.customViews.current.slug;
      this.$router.push(`/deals/${slug}/`);
    },
  },
});
</script>

<style lang="scss" module>
@use "@app/styles/scss/colors";
@use "@app/styles/scss/spacing";
@use "@app/styles/scss/typography";

.divider {
  display: flex;
  align-items: center;
  padding: 0 12px;

  &:before {
    content: "";
    display: inline-block;
    height: 22px;
    border-left: solid 1px colors.$pr-200;
  }
}

.iconBtn:global(.el-button) {
  padding-left: 6px;
  padding-right: 6px;
}

.icon {
  color: colors.$pr-400;
}

.warnMessageBox {
  &:global(.el-message-box) {
    max-width: 448px;
    border-left: 2px solid colors.$ad-10;
    padding: spacing.$l spacing.$xl spacing.$xl;
  }

  :global {
    .el-message-box__header {
      padding: 0 0 spacing.$xs;
    }

    .el-message-box__title {
      color: colors.$pr-900;
      font: typography.$title_bold;
      border-top: none;
      border-bottom: none;
    }

    .el-message-box__content {
      padding: 0 0 spacing.$xl;
      color: colors.$pr-900;
      font: typography.$body_regular;
    }

    .el-message-box__btns {
      padding: 0;

      .el-button.el-button--primary {
        --el-button-bg-color: #{colors.$ad-10};
        --el-button-border-color: #{colors.$ad-10};
        --el-button-hover-bg-color: #{colors.$ad-10_5};
        --el-button-hover-border-color: #{colors.$ad-10_5};
        --el-button-active-bg-color: #{colors.$ad-10_5};
        --el-button-active-border-color: #{colors.$ad-10_5};
        --el-button-outline-color: transparent;
      }
    }
  }
}
</style>
