<template>
  <div>
    <h4>Tokens</h4>

    <div class="alert alert-info">
      API tokens allow you to perform actions against the DealRoom API on behalf
      of your account. For more information on how to use the API, contact us
      <a href="mailto:support@dealroom.net">support@dealroom.net</a>
    </div>

    <DrVxeGrid
      :data="tokens"
      :columns="tokensTableColumns.columns"
      max-height="200px"
    >
      <template #empty>No tokens</template>
    </DrVxeGrid>

    <AddTokenModal ref="addTokenModalRef" @created="pushToken" />

    <div class="text-right">
      <ElButton type="primary" @click="addToken">Create new token</ElButton>
    </div>
  </div>
</template>

<script setup lang="ts">
import { onBeforeMount, ref } from "vue";
import DrVxeGrid from "@shared/ui/dr-vxe-grid";

import AddTokenModal from "@drVue/components/client-dashboard/settings/Integrations/AddTokenModal.vue";
import TableColumns from "@drVue/components/client-dashboard/settings/Integrations/tableColumns";
import { TokensApiService } from "@drVue/store/modules/client-dashboard/intergrations/TokensApiService";
import { useRevokeCellBus } from "./cells/useRevokeCellBus";

import type { ApiToken } from "@drVue/store/modules/client-dashboard/intergrations/TokensApiService";

const addTokenModalRef = ref<InstanceType<typeof AddTokenModal> | null>(null);

const tokensTableColumns = new TableColumns();
const tokensApi = new TokensApiService();
const tokens = ref<ApiToken[]>([]);
const isTokensLoading = ref(false);

const revokeCellBus = useRevokeCellBus();

const loadIntegrationTokens = () => {
  tokens.value = [];

  isTokensLoading.value = true;
  tokensApi
    .list()
    .then((ts) => (tokens.value = ts))
    .finally(() => (isTokensLoading.value = false));
};

onBeforeMount(() => {
  loadIntegrationTokens();
});

const addToken = () => {
  addTokenModalRef.value?.open();
};

const pushToken = (token: ApiToken) => {
  tokens.value.push(token);
};

revokeCellBus.on((_event, tokenId) => {
  if (!tokenId) return;

  tokensApi.revoke(tokenId).then((revokedToken) => {
    const i = tokens.value.findIndex((t) => t.id === tokenId);
    tokens.value.splice(i, 1, revokedToken);
    tokens.value = [...tokens.value];
  });
});
</script>
